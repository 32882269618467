<div class="search-form-container">
    <form class="d-flex align-items-center search-form" [formGroup]="searchStocksAndEtfs">
        <input #searchInput type="text" placeholder="Search Stocks & ETFs"
            class="input w-100 border-0 shadow-none form-control" formControlName="stockName" [ngbTypeahead]="search"
            [resultTemplate]="rt" [inputFormatter]="inputFormatter" [resultFormatter]="resultFormatter"
            (selectItem)="onSelect($event)">
        <div class="search-loader ">
            <div class="spinner-border mx-auto spinner-border-sm" role="status" *ngIf="searching">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <!-- <i class="bi icon-search"></i> -->
        <img src="/assets/icons/search-blue.svg" alt="Search Icon" style="width: 16px; height: 16px; margin: 0 16px;">
    </form>

    <ng-template #rt let-r="result" let-t="term">
        <div class="search-result-template">
            <h6 class="mb-0 line-clamp-1 stock-name">{{ r.stockName }}</h6>
            <p class="mb-0 line-clamp-1 company-name">{{ r.companyName }}</p>
            <div class="d-flex align-items-center gap-1 stock-exchange">
                <img [appLazyLoadImage]="'/assets/flags/1x1/' + r.flag + '.svg'"
                    placeholder="/assets/images/dashboard/stock-lowres.png" [alt]="r.flag"
                    class="img-fluid country-flag rounded-circle">
                <span>{{ r.exchange }}</span>
            </div>
        </div>
    </ng-template>

    <div class="not-found-wrapper" *ngIf="searchFailed && !searching">
        <p class="text-center mb-0 py-2 fs-6">
            No Stocks or ETFs found
        </p>
    </div>
</div>
