import { Component, Input, OnInit } from '@angular/core';
import { SuperInvestor } from 'src/app/models/superInvestor.model';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WatchlistService } from 'src/app/shared/services/watchlist.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { Router } from '@angular/router';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { lastValueFrom, Subscription } from 'rxjs';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-super-investor-detailed-info-card',
   templateUrl: './super-investor-detailed-info-card.component.html',
   styleUrls: ['./super-investor-detailed-info-card.component.scss'],
})
export class SuperInvestorDetailedInfoCardComponent implements OnInit {
   private subscriptions: Subscription[];
   userRole: string | null = null;
   @Input() investor: SuperInvestor | undefined;
   @Input() loader: boolean = false;
   displayedDescription: string | undefined;
   copyWatchlistForm!: FormGroup;
   isCollapsed: boolean = true;
   showButton: boolean = false;
   isLoggedIn: boolean = false;
   dateQuarter: number = 0;
   halalPercentModalRef!: NgbModalRef;
   copyToWatchListModalRef!: NgbModalRef;
   copyWatchlistLoader: boolean = false;
   copyWatchlistError: string = '';
   watchlistItems: number = 0;
   public watchlistItemsAccess: boolean = false;
   public maxWatchlistCount: number = 0;
   public maxWatchlistItemsCount: number = 0;

   isCopiedToWatchlist: boolean = false;

   constructor(
      private modalService: NgbModal,
      private formBuilder: FormBuilder,
      private watchlistService: WatchlistService,
      public subscriptionService: SubscriptionService,
      private router: Router,
      private authService: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService
   ) {
      this.subscriptions = [];
   }

   async ngOnInit() {
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
               if (this.userRole !== 'ANONYMOUS') {
                  this.fetchAllWatchList();
               }
            }
         )
      );

      this.subscriptions.push(
         this.permissionStore
            .select(({ permissions }) => permissions.permissions)
            .subscribe((permissions) => {
               this.isLoggedIn = !!this.authService.getUserId();
               this.maxWatchlistCount = permissions.watchlist_count;
               this.maxWatchlistItemsCount = permissions.watchlist_items_count;
               this.watchlistItemsAccess = permissions.watchlist_items;
            })
      );

      this.displayedDescription = this.investor?.profile.slice(0, 200) + '...';

      this.copyWatchlistForm = this.formBuilder.group({
         name: ['', Validators.required],
         onlyHalal: [true, Validators.required],
      });

      this.watchlistService.watchListCount$.subscribe((count: number) => {
         this.watchlistItems = count;
      });
   }

   ngOnChanges() {
      this.checkButtonVisibility();
      if (this.investor?.report_date) {
         this.dateQuarter = this.getQuarter(this.investor?.report_date);
      }
   }

   async fetchAllWatchList() {
      let res: any = await lastValueFrom(
         this.watchlistService.fetchAllWatchlist()
      );
      if (res.status) {
         this.watchlistService.setWatchlistCount(res.data.length);
      }
   }

   toggleReadMore() {
      this.isCollapsed = !this.isCollapsed;
      this.updateDisplayedDescription();
   }

   checkButtonVisibility() {
      if (this.investor?.profile && this.investor?.profile.length > 200) {
         this.showButton = true;
      } else {
         this.showButton = false;
      }
      this.updateDisplayedDescription();
   }

   updateDisplayedDescription() {
      if (this.isCollapsed) {
         this.displayedDescription =
            this.investor?.profile.slice(0, 200) + '...';
      } else {
         this.displayedDescription = this.investor?.profile;
      }
   }

   getQuarter(date: string) {
      const d = new Date(date);
      return Math.floor(d.getMonth() / 3 + 1);
   }

   openHalalPercentModal(content: any) {
      this.halalPercentModalRef = this.modalService.open(content, {
         centered: true,
         // scrollable: true,
      });
   }

   openCopyToHalalWatchListModal(content: any) {
      if (this.isCopiedToWatchlist) {
         this.isCopiedToWatchlist = false;
      }

      if (
         this.userRole === 'PREMIUM'
         // &&
         // this.watchlistItems < this.maxWatchlistCount
      ) {
         this.copyWatchlistForm.controls['onlyHalal'].setValue(true);
         this.copyWatchlistForm.controls['name'].setValue(
            this.investor?.manager + "'s Portfolio"
         );

         this.copyToWatchListModalRef = this.modalService.open(content, {
            centered: true,
         });

         document.getElementById('watchlist-name')?.focus();
      } else {
         this.accessControlService.accessDenied();
      }
   }

   closeCopyToWatchList() {
      this.copyWatchlistError = '';
      if (this.copyToWatchListModalRef) {
         this.copyToWatchListModalRef.dismiss();
      }
   }

   closeHalalPercentModal() {
      if (this.halalPercentModalRef) {
         this.halalPercentModalRef.dismiss();
      }
   }

   async copyWatchlistSubmit() {
      this.copyWatchlistError = '';
      this.copyWatchlistLoader = true;
      let watchlistName = this.copyWatchlistForm.controls['name'].value;
      let onlyHalal = this.copyWatchlistForm.controls['onlyHalal'].value;

      try {
         const res = await this.watchlistService.copyToWatchlist(
            this.investor?.id,
            watchlistName,
            onlyHalal
         );

         if ((res as any)?.status) {
            this.isCopiedToWatchlist = true;
            // this.closeCopyToWatchList();
         } else {
            this.copyWatchlistError = (res as any)?.message;
         }
      } catch (error) {
         console.log(error);
      } finally {
         this.copyWatchlistLoader = false;
      }
   }

   navigateToWatchlist() {
      this.closeCopyToWatchList();
      this.router.navigate(['/watchlist-lists']);
   }
}
