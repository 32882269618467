import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})

export class MarketService {
   url: string = environment.apiUrl;

   private marketStatusSubject = new Subject<any>()
   marketStatusData$ = this.marketStatusSubject.asObservable()

   constructor(
      private http: HttpClient
   ) {}

   setMarketStatus(data: any) {
      this.marketStatusSubject.next(data)
   }

   fetchMarketStatus<T>(countries: string[]): Observable<T>  {
      return this.http.post<T>(this.url + '/api/market-status', { countries })
   }

   fetchMarketHolidays<T>(exchanges: string[]): Observable<T>  {
      return this.http.post<T>(this.url + '/api/market-holidays', { exchanges })
   }

}