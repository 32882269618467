import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, shareReplay, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryFilterPopupComponent } from '../components/country-filter-popup/country-filter-popup.component';
import { SectorIndustries, SectorIndustriesCollectionResponse, SectorIndustriesHits,TypesenseDocument, TypesenseHit, TypesenseResponse } from 'src/app/models/sector-industries/sector-industries.model';
import { TYPESENSE_COLLECTIONS } from 'src/app/shared/constants/typesense-collections';
import { StockData, StockDataApiResponse } from '../models/stocks-api-response.model';
import { FormattedStock } from '../models/formatted-stocks.model';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { roleSequence } from 'src/app/utils/roles';
import { filtersConfig } from 'src/app/utils/stock.filters-config';
import { FilterService } from 'src/app/shared/services/filter.service';
import axios from 'axios';

@Injectable({
   providedIn: 'root'
})

export class StockFilterService {
   // advance filter offcanvas
   private showAdvanceFilterOffcanvasSource = new Subject<void>();
   showOffcanvas$ = this.showAdvanceFilterOffcanvasSource.asObservable();

   // fount stocks count
   private filteredStocksCountSubject = new BehaviorSubject<number | string>(0);
   filteredStocksCount$ = this.filteredStocksCountSubject.asObservable();

   // countries modal
   countriesModalRef!: NgbModalRef;

   private sectorIndustries$!: Observable<SectorIndustries[]>;
   userRole: string = '';

   private roleSequence: string[] = roleSequence;



   constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      private typesenseService: TypesenseService
   ) {
      SubscriptionService.subscriptionData.subscribe((data) => {
         this.userRole = data.newRole ?? '';
      })
   }

   showAdvanceFilter() {
      this.showAdvanceFilterOffcanvasSource.next();
   }

   setFilteredStocksCount(count: string | number): void {
      this.filteredStocksCountSubject.next(count);
   }

   getSectorIndustries(): Observable<SectorIndustries[]> {
      this.sectorIndustries$ = this.sectorIndustries$ ?? this.fetchSectorIndustries().pipe(shareReplay());
      return this.sectorIndustries$;
   }

   getFinancialData(value: string): Observable<any> {
      return this.http.get<any>(`${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.FINANCIAL_PER_SHARE_COLLECTION}/documents/${value}`);
    }

    // Create interfaces for type safety


    async getChartData(ticker: string, periods: string[], name: string, time_period: string): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedName = encodeURIComponent(name);
      const encodedTimePeriod = encodeURIComponent(time_period);

      const periodFilter = periods.map(period => `period:${encodeURIComponent(period)}`).join('||');

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&name:${encodedName}&&time_period:${encodedTimePeriod}&&(${periodFilter})&per_page=5`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.CHART_COLLECTION}/documents/search?${query}`;

      try {
        const response = await axios.get(url, { headers });
      //   console.log('API response:', response.data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }

    async getRatAnnualData(ticker: string, periods: string[], name: string, time_period: string): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedName = encodeURIComponent(name);
      const encodedTimePeriod = encodeURIComponent(time_period);



      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&name:${encodedName}&&time_period:${encodedTimePeriod}&per_page=5&sort_by=period:desc`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.CHART_COLLECTION}/documents/search?${query}`;

      try {
        const response = await axios.get(url, { headers });

        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }
    async getRatAnnualData2(
      ticker: string,
      names: string[],  // Changed from `name` to `names` (array)
      time_period: string,
      per_page: number,
      page: number
  ): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedTimePeriod = encodeURIComponent(time_period);

      // Encode the array of names properly for API query
      const encodedNames = encodeURIComponent(`[${names.join(',')}]`);

      const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      // Corrected `page=${page}` instead of `page=${per_page}`
      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&name:${encodedNames}&&time_period:${encodedTimePeriod}&per_page=${per_page}&sort_by=period:desc&page=${page}`;

      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.CHART_COLLECTION}/documents/search?${query}`;

      try {
          const response = await axios.get(url, { headers });
          return response.data;
      } catch (error) {
          console.error('API Error:', error);
          throw error;
      }
  }

    async getRatQuarterlyData(ticker: string, periods: string[], name: string, time_period: string): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedName = encodeURIComponent(name);
      const encodedTimePeriod = encodeURIComponent(time_period);



      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&name:${encodedName}&&time_period:${encodedTimePeriod}&per_page=4&sort_by=period:desc`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.CHART_COLLECTION}/documents/search?${query}`;

      try {
        const response = await axios.get(url, { headers });

        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }
    async getRatQuarterlyData2(ticker: string, periods: string[],names: string[], time_period: string,
      per_page: number,
      page: number): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedNames = encodeURIComponent(`[${names.join(',')}]`);
      const encodedTimePeriod = encodeURIComponent(time_period);



      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&name:${encodedNames}&&time_period:${encodedTimePeriod}&per_page=${per_page}&sort_by=period:desc&page=${page}`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.CHART_COLLECTION}/documents/search?${query}`;

      try {
        const response = await axios.get(url, { headers });

        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }

    async perShareData(ticker: string): Promise<any> {

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.FINANCIAL_PER_SHARE_COLLECTION}/documents/${ticker}`;

      try {
        const response = await axios.get(url, { headers });
      //   console.log('API response:', response.data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }

    async getRatData(ticker: string, periods: string[], name: string, time_period: string): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedName = encodeURIComponent(name);
      const encodedTimePeriod = encodeURIComponent(time_period);

      const periodFilter = periods.map(period => `period:${encodeURIComponent(period)}`).join('||');

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&name:${encodedName}&&time_period:${encodedTimePeriod}&&(${periodFilter})&per_page=4`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.CHART_COLLECTION}/documents/search?${query}`;

      try {
        const response = await axios.get(url, { headers });
      //   console.log('API response:', response.data);
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }




    async getAnnualIncomeStatement(
      ticker: string,
      freq: string,
      period: number[],
      page_name: string,
      page: number,
      asdes:string
    ): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedFreq = encodeURIComponent(freq);
      const encodedPageName = encodeURIComponent(page_name);
      const encodedPeriod = period.map((p) => encodeURIComponent(p)).join('%2C'); // Encoding array elements

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&freq:${encodedFreq}&&report:${encodedPageName}&&year:[${encodedPeriod}]&sort_by=year:${asdes}&page=${page}&per_page=250`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.FINANCIAL_REPORT_COLLECTION}/documents/search?${query}`;

      try {
         // console.log("Url is",environment.nove_infomavan_v2.url)
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }

    async getNewIncomeData(
      ticker: string,
      freq: string,
      page: number
    ): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedFreq = encodeURIComponent(freq);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-TYPESENSE-API-KEY': environment.new_statement_data.searchApiKey,
      };

      // Here, we add a filter_by for the frequency
      const query = `q=*&page=${page}&per_page=250&filter_by=company_symbol:=${encodedTicker} && freq:${encodedFreq}`;
      const url = `${environment.new_statement_data.url}/collections/financial_statements_1/documents/search?${query}`;

      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }

    async getAnnualStatementChartData(
      ticker: string,
      freq: string,
      period: number[],
      page_name: string,
      page: number,
      asdes:string
    ): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedFreq = encodeURIComponent(freq);
      const encodedPageName = encodeURIComponent(page_name);
      const encodedPeriod = period.map((p) => encodeURIComponent(p)).join('%2C'); // Encoding array elements

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&freq:${encodedFreq}&&report:${encodedPageName}&&year:[${encodedPeriod}]&sort_by=year:${asdes}&page=${page}&per_page=250`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.FINANCIAL_REPORT_COLLECTION}/documents/search?${query}`;

      try {
         // console.log("Url is",environment.nove_infomavan_v2.url)
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }
    async getStatementChartData(
      ticker: string,
      freq: string,
      period: number[],
      page_name: string,
      page: number,
      asdes:string,
      label:string
    ): Promise<any> {
      const encodedTicker = encodeURIComponent(ticker);
      const encodedFreq = encodeURIComponent(freq);
      const encodedPageName = encodeURIComponent(page_name);
      const encodedPeriod = period.map((p) => encodeURIComponent(p)).join('%2C'); // Encoding array elements
      const encodedLabel = encodeURIComponent(`label:'${label}'`);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Typesense-Api-Key': environment.nove_infomavan_v2.searchApiKey,
      };

      const query = `q=*&filter_by=company_symbol:${encodedTicker}&&freq:${encodedFreq}&&report:${encodedPageName}&&year:[${encodedPeriod}]%20%26%26%20${encodedLabel}&sort_by=year:${asdes}&page=${page}&per_page=250`;
      const url = `${environment.nove_infomavan_v2.url}/collections/${TYPESENSE_COLLECTIONS.FINANCIAL_REPORT_COLLECTION}/documents/search?${query}`;

      try {

        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    }

   private fetchSectorIndustries(): Observable<SectorIndustries[]> {
      const queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return this.http.get<SectorIndustriesCollectionResponse>(`${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.MUSAFFA_SECTOR}/documents/search`, { params: queryParams }).pipe(
         map((response) => response.hits.map((hits: SectorIndustriesHits) => hits.document) as SectorIndustries[])
      );
   }

   getPermittedFilters(filters: any) {
      const roleIndex = this.roleSequence.indexOf(this.userRole);
      const filterNames = filters.map((filter: any) => filter.name);
      const filteredFilters = filtersConfig.filter((filter: any) => {
         return filter.name.includes(filterNames) && this.roleSequence.indexOf(filter.requiredRole) <= roleIndex;
      });
      return filteredFilters;
   }

   async getAnalystRatings() {
      let analystRatingsJson: { key: string, display_value: string }[] = [
         { key: 'Strong buy', display_value: 'Strong Buy' },
         { key: 'Buy', display_value: 'Buy' },
         { key: 'Hold', display_value: 'Hold' },
         { key: 'Sell', display_value: 'Sell' },
         { key: 'Strong sell', display_value: 'Strong Sell' },
      ];
      return analystRatingsJson;
   }

   async getComplianceData() {
      let complianceJson: { key: string, display_value: string, isAll?: boolean }[] = [
         // { key: '-', display_value: 'All', isAll: true },
         { key: 'COMPLIANT', display_value: 'Halal' },
         { key: 'NON_COMPLIANT', display_value: 'Not Halal' },
         { key: 'QUESTIONABLE', display_value: 'Doubtful' },
      ];
      return complianceJson;
   }

   async getHalalScoreData() {
      let halalScoreJson: { key: string, display_value: string }[] = [
         { key: '1', display_value: '1', },
         { key: '2', display_value: '2', },
         { key: '3', display_value: '3', },
         { key: '4', display_value: '4', },
         { key: '5', display_value: '5', },
      ];
      return halalScoreJson;
   }

   openCountriesPopup() {
      this.countriesModalRef = this.modalService.open(CountryFilterPopupComponent, {
         centered: true,
         scrollable: true,
         backdropClass: 'global-modal-backdrop',
         windowClass: 'global-modal',
         size: 'lg'
      });
   }

   closeCountriesPopup() {
      if (this.countriesModalRef) {
         this.countriesModalRef.close()
      }
   }

   buildFilterConditions(filters: any, type: string = 'stock'): any {
      const filterBy: string[] = [];
      const processFilters = (filters: any) => {
         return filters
            .filter((filter: any) => filter?.value?.length > 0)
            .map((filter: any) => {
               const field = filter.field;
               const value = filter.value;

               // In-array filter
               if (Array.isArray(value)) {
                  if (value.length > 0) {
                     const values = value.map((v: string | number) => (typeof v === 'number') ? v : `\`${v}\``).join(',');
                     return `${field}:=[${values}]`;
                  } else {
                     return '';
                  }
               }

               // Range filter
               const [min, max] = value.split(':');
               if (min && max) {
                  // if (field === 'ipoDate') {
                  //    return `${field}:[<${min}]`;
                  // }
                  if (min === '*' && max !== '*') {
                     return `${field}:<=${max}`;
                  }
                  if (min !== '*' && max === '*') {
                     return `${field}:>=${min}`;
                  }
                  if (min !== '*' && max !== '*') {
                     return `${field}:>=${min}&&${field}:<=${max}`;
                  }
               }

               // Not filter
               if (value.includes('!')) {
                  return `${field}:!=${value.replace('!', '')}`;
               }
               if (value === '[]') {
                  return '';
               }
               return `${field}:=${value}`;
            });
      };

      if (filters.length > 0) {
         const exchangeIndex = filters.findIndex(
            (filter: any) => filter.field === 'exchange'
         );
         const countryIndex = filters.findIndex(
            (filter: any) => type === 'stock' ? filter.field === 'country' : filter.field === 'domicile'
         );
         const complianceStatusIndex = filters.findIndex(
            (filter: any) => type === 'stock' ? filter.field === 'sharia_compliance' : filter.field === 'shariahCompliantStatus'
         );
         if (
            exchangeIndex === -1 ||
            (exchangeIndex > -1 && !filters[exchangeIndex]?.value?.length)
         ) {
            if (filters[countryIndex].value?.toUpperCase() === 'US') {
               filters.push({
                  field: 'exchange',
                  value: ['NYSE', 'NASDAQ'],
               });
            }
         }
         if (complianceStatusIndex !== -1) {
            if (!filters[complianceStatusIndex]?.value?.length) {
               filters[complianceStatusIndex].value.push(
                  'COMPLIANT',
                  'NON_COMPLIANT',
                  'QUESTIONABLE'
               );
            }
         } else {
            filters.push({
               field: type === 'stock' ? 'sharia_compliance' : 'shariahCompliantStatus',
               value: ['COMPLIANT', 'NON_COMPLIANT', 'QUESTIONABLE'],
            });
         }
         filterBy.push(...processFilters(filters));
      }
      return filterBy;
   }

   public async getStocksData(
      page: number,
      perPage: number,
      filters: any,
      sortColumn: string,
      sortOrder: string
   ): Promise<any> {

      const fixedFilters = [
         {
            field: 'status',
            value: 'PUBLISH',
         },
         {
            field: 'isMainTicker',
            value: '1',
         },
      ];

      const filterBy = this.buildFilterConditions(
         [...fixedFilters, ...filters]
      );

      const sortBy = [];
      if (sortColumn && sortOrder) {
         sortBy.unshift(`${sortColumn}:${sortOrder}`);
      }

      const queryParams = new HttpParams()
         .set('page', page)
         .set('per_page', perPage ?? 15)
         .set('q', '*')
         .set('filter_by', filterBy.filter((filter: any) => filter !== '').join('&&'))
         .set('sort_by', sortBy.join(','));

      return await lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.STOCKS_DATA}/documents/search`,
               { params: queryParams }
            )
            .pipe(
               map((response) => {
                  const data = response.hits;
                  const items: any = {};
                  data.forEach((x: any) => {
                     const doc = x.document;
                     items[doc.id] = this.typesenseService.mapPricesData(doc);
                  });
                  const number_of_pages = Math.ceil(
                     response.found / response.request_params.per_page
                  );
                  return {
                     currentPage: response.page,
                     hasNext: response.page < number_of_pages,
                     hasPrevious: response.page > 1,
                     itemCount: response.request_params.per_page,
                     pageQuantity: number_of_pages,
                     totalCount: response.found,
                     items: items,
                  };
               })
            )
      );
   }

   private handleError(error: any): Observable<never> {
      console.error('API error occurred:', error);
      return throwError(() => new Error('An error occurred while fetching data.'));
   }

   getOverviewColumns() {
      return [
         { header: 'Country', key: 'country', sortable: false, type: 'country'},
         { header: 'Sector', key: 'musaffaSector', type:'musaffaSector' },
         { header: 'Industry', key: 'musaffaIndustry',type:'musaffaIndustry' },
         { header: 'Market Cap', key: 'marketcap', type: 'marketcap'},
         { header: 'Price', key: 'currentPrice', type: 'amount'},
         { header: 'Price Change', type: 'amount', key: 'priceChange1D', showPositiveNegative: true},
         { header: 'Price Change (%)', type: 'percent', key: 'priceChange1DPercent', showPositiveNegative: true },
         { header: '52-Week High', type: 'amount', key: '52WeekHigh'},
         { header: '52-Week Low', type: 'amount', key: '52WeekLow'},
         { header: 'Analyst Rating', key: 'analyst_recommendation_weighted_avg',type:'analyst_recommendation_weighted_avg' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getComplianceColumns() {
      return [
         // { header: 'Purification per Share', key: '-' },
         { header: 'Halal Revenue %', key: 'halal_revenue_percent', type: 'percent' },
         { header: 'Not Halal Revenue %', key: 'nothalal_revenue_percent', type: 'percent' },
         { header: 'Doubtful Revenue %', key: 'doubtful_revenue_percent', type: 'percent' },
         { header: 'IB Assets %', key: 'intrestbearing_asset_percent', type: 'percent' },
         { header: 'IB Debt %', key: 'interestbearing_debt_percent', type: 'percent' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getPriceColumns() {
      return [
         { header: 'Stock Price', key: 'currentPrice', type: 'amount' },
         { header: 'Open Price', key: 'open', type: 'amount' },
         // { header: 'Previous Close', key: 'close', type: 'amount' },
         { header: 'Low Price', key: 'low', type: 'amount' },
         { header: 'High Price', key: 'high', type: 'amount' },
         { header: '52-Week High', key: '52WeekHigh', type: 'amount' },
         { header: '52-Week Low', key: '52WeekLow', type: 'amount' },
         { header: 'Price Change', type: 'amount', key: 'priceChange1D', showPositiveNegative: true},
         { header: 'Price Change (%)', type: 'percent', key: 'priceChange1DPercent', showPositiveNegative: true },
         // { header: 'Price Change 1W', key: '-', type: 'amount', showPositiveNegative: true },
         // { header: 'Price Change 1M (%)', key: 'priceChange1MPercent', type: 'percent', showPositiveNegative: true },
         // { header: 'Price Change YTD', key: '-', type: 'amount', showPositiveNegative: true },
         // { header: 'Price Change 1Y (%)', key: 'priceChange1YPercent', type: 'percent', showPositiveNegative: true },
         // { header: 'Price Change 3Y (%)', key: 'priceChange3YPercent', type: 'percent', showPositiveNegative: true },
         // { header: 'Price Change 5Y (%)', key: 'priceChange5YPercent', type: 'percent', showPositiveNegative: true },
         { header: 'Volume', key: 'volume', type: 'million' },
         { header: 'Beta', key: 'beta', type: 'number' },
         // { header: 'Sharpe Ratio', key: '-' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getValuationColumns() {
      return [
         { header: 'Market Cap', key: 'marketcap', type: 'marketcap' },
         { header: 'Enterprise Value', key: 'enterpriseValue', type: 'marketcap' },
         // { header: 'Expected Annual Return', key: 'epsAnnual', type: 'amount' },
         // { header: 'Target Price', key: 'priceTargetMedian', type: 'amount' },
         // { header: 'Price Target Upside', key: '-', type: 'amount' },
         { header: 'P/E Ratio', key: 'peAnnual', type: 'number' },
         { header: 'P/B Ratio', key: 'pbAnnual', type: 'number' },
         // { header: 'Book Value Per Share', key: 'bookValuePerShareAnnual', type: 'amount' },
         { header: 'P/S Ratio', key: 'psAnnual', type: 'number' },
         // { header: 'EV/EBITDA', key: 'ebitda_annual' },
         { header: 'P/FCF Ratio', key: 'pfcfShareAnnual', type: 'number' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getPerformanceColumns() {
      return [
         { header: 'Revenue', key: 'revenue_annual', type: 'marketcap' },
         // { header: 'EBITDA', key: 'ebitda_annual', type: 'amount' },
         { header: 'Net Income', key: 'net_income_annual', type: 'marketcap' },
         // { header: 'Gross Margin', key: 'gross_margin_annual', type: 'percent' },
         { header: 'EPS', key: 'epsAnnual' },
         // { header: 'EBITDA Margin', key: 'ebitda_margin', type: 'percent' },
         { header: 'Operating Margin', key: 'operatingMarginAnnual', type: 'percent' },
         // { header: 'ROA', key: '-', type: 'amount' },
         { header: 'Net Profit Margin', key: 'netProfitMarginAnnual', type: 'percent' },
         // { header: 'ROE', key: '-', type: 'amount' },
         // { header: 'ROA 5Y', key: 'roa5Y', type: 'amount' },
         { header: 'ROI', key: 'roiAnnual', type: 'number' },
         // { header: 'Return on Equity', key: '-' },
         // { header: 'ROE 5Y', key: '-' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getRatiosColumns() {
      return [
         { header: 'Current Ratio', key: 'currentRatioAnnual', type: 'number' },
         { header: 'Quick Ratio', key: 'quickRatioAnnual', type: 'number' },
         // { header: 'Cash Ratio', key: '-', type: 'number' },
         // { header: 'Debt to EBITDA', key: '-', type: 'number' },
         { header: 'Debt to Equity', key: 'totalDebt_totalEquityAnnual', type: 'number' },
         { header: 'Asset Turnover', key: 'assetTurnoverAnnual', type: 'number' },
         { header: 'Net Interest Coverage', key: 'netInterestCoverageAnnual', type: 'number' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getGrowthColumns() {
      return [
         { header: 'Revenue Growth 1Y', key: 'revenueGrowth1Y', type: 'percent' },
         { header: 'Revenue Growth 5Y', key: 'revenueGrowth5Y', type: 'percent' },
         // { header: 'EBITDA Growth 1Y', key: 'ebitda_growth_1y', type: 'percent' },
         // { header: 'EBITDA Growth 5Y', key: 'ebitda_growth_5y', type: 'percent' },
         // { header: 'Operating Income Growth 1Y', key: '-', type: 'percent' },
         // { header: 'Operating Income Growth 5Y', key: '-', type: 'percent' },
         // { header: 'EPS Growth 1Y', key: 'epsGrowth1Y', type: 'percent' },
         { header: 'EPS Growth 5Y', key: 'epsGrowth5Y', type: 'percent' },
         // { header: 'Asset Growth 1Y', key: 'assets_growth_1y', type: 'percent' },
         // { header: 'Asset Growth 5Y', key: 'assets_growth_5y', type: 'percent' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   // getEstimatesColumns() {
   //    return [
   //       { header: 'Analyst Recommendation', key: 'recommendationWeightedAverage'},
   //       { header: 'Price Target', key: 'priceTargetMedian', type: 'amount' },
   //       { header: 'Price Target Upside', key: '-' },
   //       { header: 'Revenue Estimate', key: '-' },
   //       { header: 'EPS Estimate', key: '-' },
   //       { header: 'EBITDA Estimate', key: '-' },
   //       { header: 'EBIT Estimate', key: '-' },
   //       { header: 'EPS Surprises', key: '-' },
   //    ] as Array<{ header: string; key: string, type?: string }>;
   // }

   // getReturnColumns() {
   //    return [
   //       { header: 'Total Return 1W', key: '-' },
   //       { header: 'Total Return 1M', key: '-' },
   //       { header: 'Total Return YTD', key: '-' },
   //       { header: 'Total Return 1Y', key: '-' },
   //       { header: 'Total Return 3Y', key: '-' },
   //       { header: 'Total Return 5Y', key: '-' },
   //    ] as Array<{ header: string; key: string, type?: string }>;
   // }

   // getInvestorAndFundOwnershipColumns() {
   //    return [
   //       { header: 'Institutional Ownership', key: '-' },
   //       { header: 'Indices', key: '-' },
   //       { header: 'Super Investor Owners', key: '-' },
   //       { header: 'Portfolio Weight', key: '-' },
   //       { header: 'ETF Owners', key: '-' },
   //       { header: 'ETF Weight', key: '-' },
   //    ] as Array<{ header: string; key: string, type?: string }>;
   // }

   // getInsiderTransactionsColumns() {
   //    return [
   //       { header: 'Insider Ownership', key: '-' },
   //       { header: 'Insider Buys', key: '-' },
   //       { header: 'Insider Sells', key: '-' },
   //    ] as Array<{ header: string; key: string, type?: string }>;
   // }

}
