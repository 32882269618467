import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MarketStatusData, MarketStatusResponse } from 'src/app/models/market/market-status.model';
import { MarketService } from 'src/app/shared/services/market.service';

@Component({
  selector: 'app-market-status',
  templateUrl: './market-status.component.html',
  styleUrls: ['./market-status.component.scss']
})

export class MarketStatusComponent implements OnInit {
   private destroy$ = new Subject<void>();
   selectedCountry!: string;
   marketStatusData: MarketStatusData[] = [];
   marketStatusLoader: boolean = false;
   countries: string[] = ['US', 'IN', 'UK']

   constructor(
      private marketService: MarketService
   ) {}

   ngOnInit(): void {
      this.getMarketStatus(this.countries);
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByMarketStatusId(index: number, marketStatus: MarketStatusData) { 
      return marketStatus.id
   }

   getMarketStatus(countries: string[]) {
      this.marketStatusLoader = true;
      this.marketService.fetchMarketStatus<MarketStatusResponse>(countries).subscribe({
         next: (response) => {
            if(response.status) {
               this.marketStatusData = response.data;
               const marketExchanges = this.marketStatusData.map((market: MarketStatusData) => market.code)
               this.marketService.setMarketStatus(marketExchanges)
            }
            this.marketStatusLoader = false;
         },
         error: (error) => {
            console.error(error);
            this.marketStatusLoader = false;
         },
         complete: () => {
            this.marketStatusLoader = false;
         }
      })
   }

   getMarketStatusClasses(status: string) {
      switch (status) {
         case 'open':
            return 'opened';
         case 'closed':
            return 'closed';
         case 'pre-market':
            return 'pre-market';
         case 'after-hours':
            return 'after-hours';
         case 'overnight':
            return 'overnight';
         default:
            return '';
      }
   }
}


