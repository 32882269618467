import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserApiService } from '../../services/user-api.service';
import { MarketUtilsService } from '../../services/market-utils.service';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';


@Component({
   selector: 'app-request-coverage-popup',
   templateUrl: './request-coverage-popup.component.html',
   styleUrls: ['./request-coverage-popup.component.scss'],
})
export class RequestCoveragePopupComponent implements OnInit {
   @Input() ticker!: string;
   @Input() countryCode!: string;
   // @Input() error: boolean = false;
   // @Input() isLimitReached: boolean = false;
   @Output() stockRequested: EventEmitter<void> = new EventEmitter();
   //   confirm: boolean = true;
   isCountryCovered: boolean = false;
   isLimitReached: boolean = false;
   loading: boolean = false;
   coverageRequestLimit: number = 3;

   constructor(
      public coverageModal: NgbActiveModal,
      private userApiService: UserApiService,
      private marketUtilsService: MarketUtilsService,
      private toastr: ToastrService,
      private analyticsUtils:AnalyticsUtils,
   ) {}

   ngOnInit(): void {
      this.loading = true;
      Promise.all(
         [
            new Promise<void>(async (resolve) => {await this.setCountryCoverage(); resolve()}),
            this.stockRequested.emit(),
            new Promise<void>(async (resolve) => {await this.setTodaysCoverageLimitCheck(); resolve()})
         ]
      ).finally(() => {
         this.loading = false;
      })
   }

   async setTodaysCoverageLimitCheck() {
      const myRequests: any = await this.userApiService.myCoverageRequests(
         new Date(),
         undefined,
         true
      );
      this.isLimitReached = myRequests.data.length >= this.coverageRequestLimit;
   }

   async setCountryCoverage() {
      const countryInfo = await this.marketUtilsService.fetchByCountryCode(
         this.countryCode
      );
      this.isCountryCovered = countryInfo?.stocksCovered ?? false;
   }

   async requestCoverage() {
      await this.setTodaysCoverageLimitCheck();
      if (!this.isLimitReached) {
         this.loading = true;
         this.userApiService
            .requestStockCoverage(
               this.ticker,
               this.countryCode,
               this.isCountryCovered
            )
            .then((data: any) => {
               if (this.isCountryCovered) {
                  this.toastr.success('Stock coverage request sent!');
                  this.stockRequested.emit();
               }
               this.coverageModal.dismiss();
            })
            .catch(() => {
               // if (this.isCountryCovered)
                  // this.toastr.error('Something went wrong');
            })
            .finally(() => {
               this.loading = false;
            });
      } else {
         this.toastr.error('Request limit reached for the day');
         this.analyticsUtils.request_coverage_limit_shown(
            this.countryCode, this.ticker)
         this.coverageModal.dismiss();
      }
   }
}
