import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatValue'
})
export class FormatValuePipe implements PipeTransform {
  transform(value: number | string | undefined): string {
    if (value === undefined || value === null || value === '') {
      return '-';
    }

    // Convert string values to numbers
    let numericValue = typeof value === 'string' ? parseFloat(value) : value;

    // Handle NaN cases (e.g., invalid strings)
    if (isNaN(numericValue)) {
      return '-';
    }

    // Multiply by 10,00,000
    numericValue *= 1_000_000;

    const absValue = Math.abs(numericValue);
    const formattedValue = (val: number) =>
      val.toFixed(2).replace(/\.?0+$/, '');

    let result: string;

    // Handle billions
    if (absValue >= 1_000_000_000) {
      result = formattedValue(absValue / 1_000_000_000) + ' B';
    }
    // Handle millions
    else if (absValue >= 1_000_000) {
      result = formattedValue(absValue / 1_000_000) + ' M';
    }
    // Handle decimal numbers
    else {
      result = formattedValue(absValue);
    }

    // Show negative values in parentheses without a negative sign
    return numericValue < 0 ? `(${result})` : result;
  }
}
