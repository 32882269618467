import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FirestoreService } from '../../services/firestore.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-mobile-only-header',
  templateUrl: './mobile-only-header.component.html',
  styleUrls: ['./mobile-only-header.component.scss']
})
export class MobileOnlyHeaderComponent implements OnInit {
  @Input() companyName!: string;
  @Input() companyTicker!: string;
  @Input() isAddedInWatchlist: boolean = false;
  @Input() isShown: boolean = false;
  @Input() id!: string;

  @Output() openAddLists = new EventEmitter<void>();

  constructor(
    private auth: AuthService,
    private firestoreService: FirestoreService,
  ) { }

  ngOnInit(): void {
  }

  openAddListsModal() {
    this.openAddLists.emit();
 }

  checkRole(role: string): boolean {
    return this.auth.checkRole(role);
  }

  async chkIsAddedInWatchlist(){
    this.isAddedInWatchlist = await lastValueFrom(this.firestoreService.isSymbolExistInAnyWatchlist(this.id, true))
  }

  closeWatchListUi(){
    this.isShown = false
    this.chkIsAddedInWatchlist()
  }

}
