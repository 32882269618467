<section class="content-padding pt-0" *ngIf="userRole === 'ANONYMOUS'">
   <div class="container">
      <div class="watchlist-section-container px-lg-4">
         <header class="watchlist-section-header py-lg-4">
            <h2 class="mb-0 text-center flex-lg-grow-1 text-lg-start">Create a Customised Watchlist to track your favorite stocks</h2>
            <ul role="list" class="d-flex flex-column gap-2">
               <li class="d-flex align-items-center gap-2 gap-lg-3">
                  <img src="/assets/icons/personalised-watchlist.svg" alt="personalised-watchlist" class="img-fluid">
                  <p class="mb-0">Create unlimited personalised watchlists</p>
               </li>
               <li class="d-flex align-items-center gap-2 gap-lg-3">
                  <img src="/assets/icons/track-stocks.svg" alt="personalised-watchlist" class="img-fluid">
                  <p class="mb-0">Track your favorite stocks</p>
               </li>
               <li class="d-flex align-items-center gap-2 gap-lg-3">
                  <img src="/assets/icons/copy-watchlist.svg" alt="personalised-watchlist" class="img-fluid">
                  <p class="mb-0">Copy your watchlists to Public List</p>
               </li>
            </ul>
            <button class="btn shadow-none rounded-3 text-no-wrap text-white py-2 px-3 mx-auto gradient-btn" (click)="openLoginPopup()">
               Create Watchlist
            </button>
         </header>
         <div class="image-wrapper pt-4 px-md-3 pt-md-3 px-lg-4 pt-lg-4">
            <img src="/assets/images/dashboard/table-image.png" alt="Table Image" class="img-fluid">
         </div>
      </div>
   </div>
</section>
