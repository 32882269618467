import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ForgotPasswordResponse } from 'src/app/models/user/forgot-password.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { SEOService } from 'src/app/shared/services/seo.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
   forgotPasswordForm!: FormGroup;
   resetLoader: boolean = false;
   @Input() isInsidePopup: boolean = false
   public errorMessage: string = '';

   constructor(
      private fb: FormBuilder,
      private seoService: SEOService,
      private userApiService: UserApiService,
		private analyticsUtils:AnalyticsUtils,
      private toaster: ToastrService,
      private router: Router,
   ) { }

   ngOnInit(): void {
      this.seoService.updateTitle('Musaffa | Forgot-password');
      this.seoService.updateDescription('');
      this.forgotPasswordForm = this.fb.group({
         email: ['', [
            Validators.email,
            Validators.required,
            Validators.pattern(
               /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-z]{1,5})\b/
            ),
         ]],
      })
   }

   async sendResetInstructions() {
      if(!this.forgotPasswordForm.valid) return;

      try {
         this.resetLoader = true
         let userData = {
            email: this.forgotPasswordForm.value.email,
         }

         this.analyticsUtils.ForgotPassword_Submit_Tapped(userData.email);
         let resp = await this.userApiService.sendResetInstructions<ForgotPasswordResponse>(userData)
         if(resp.status) {
            this.errorMessage = ''
            this.toaster.success('Instructions sent to email!');
            this.router.navigate(['/']);
            this.resetLoader = false
         } else {
            this.errorMessage = resp.message
         }

      } catch (error) {
         console.error(error);
         this.resetLoader = false
      } finally {
         this.resetLoader = false
      }
   }

   @HostBinding('class')
   get formStyling(): string {
      if (this.isInsidePopup) {
         return 'form-styling';
      } else {
         return '';
      }
   }

}
