import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PortfolioService } from '../../services/portfolio.service';
import { SupportedBrokerages } from 'src/app/models/supported-institution.model';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { ConnectIframeModalComponent } from '../connect-iframe-modal/connect-iframe-modal.component';

import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import {
   BrokerageCoverageApiResponse,
   CoverageBrokerage,
} from 'src/app/features/pricing/models/brokerage-coverage.model';
import { PricingService } from 'src/app/features/pricing/services/pricing.service';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from '../../services/access-control.service';

@Component({
   selector: 'app-supported-institutions',
   templateUrl: './supported-institutions.component.html',
   styleUrls: ['./supported-institutions.component.scss'],
})
export class SupportedInstitutionsComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   features: string[] = [
      'Portfolio Tracking',
      'Trading',
      'Automated Purification',
   ];
   activeFeature: string = this.features[0];
   allSupportedBrokerages: CoverageBrokerage[] = [];
   supportedBrokerages: CoverageBrokerage[] = [];
   filteredBrokerages: CoverageBrokerage[] = [];
   loading: boolean = false;
   private subscriptions: Subscription[];
   userRole: string | null = null;
   redirectUrl: string | null = null;
   connectIframeModalRef: any;
   isConnectLoading: boolean = false;
   currBrokerageId: any;
   paywallModalRef: any;
   searchText: string = '';
   sectionLoader: boolean = true;

   portfolioAccounts: PortfolioAccount[] = [];

   @Input() view: boolean = true;
   @Input() showTitle: boolean = true;
   @Input() perRow: number = 3;

   maxManualPortfoliosCount: number = 0;
   maxBrokeragePortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;
   currentBrokeragePortfoliosCount: number = 0;
   userNewRole: Role = null;
   private messagePopupRef: NgbModalRef | null = null;
   portfolioType!: 'broker' | 'manual';

   constructor(
      private portfolioServices: PortfolioService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private router: Router,
      private authService: AuthService,
      private sharedService: SharedService,
      private subscriptionService: SubscriptionService,
      private pricingService: PricingService,
      private portfolioService: PortfolioService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService
   ) {
      this.subscriptions = [];
   }

   async ngOnInit() {
      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .pipe(takeUntil(this.destroy$))
         .subscribe((permissions) => {
            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;
            this.maxBrokeragePortfoliosCount = permissions.brokerage_connected_portfolios_count;

            // console.log(permissions)
         })

      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userNewRole = data.newRole;
            // console.log(this.userNewRole)
         });

      await this.getPortfolioList();
      await this.fetchBrokerageCoverages() 
      // this.getAccountsCount()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   private async getPortfolioList() {
      this.sectionLoader = true;
      this.portfolioService
         .getPortfoliosList()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.portfolioAccounts = response;
               this.sectionLoader = false;
            },
            error: (error) => {
               console.error(error);
               this.sectionLoader = false;
            },
         });
   }

   private async fetchBrokerageCoverages() {
      this.loading = true;
      try {
         const res = await lastValueFrom(
            this.pricingService.fetchBrokeragesCoverage<BrokerageCoverageApiResponse>(
               'all'
            )
         );
         this.allSupportedBrokerages = res.data;
         this.filterBrokerageByFeature(this.activeFeature);
      } catch (err: any) {
         console.log(err);
      } finally {
         this.loading = false;
      }
   }

   filterBrokerageByFeature(feature: string) {
      this.activeFeature = feature;
      switch (feature) {
         case 'Portfolio Tracking': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.is_portfolio_linking_allowed
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
         case 'Trading': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.is_trading_allowed
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
         case 'Automated Purification': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.automated_purification
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
      }
   }

   searchBrokerage() {
      const lowerCaseText = this.searchText.toLowerCase();
      this.filteredBrokerages = this.supportedBrokerages.filter(
         (item: CoverageBrokerage) =>
            item.name.toLowerCase().includes(lowerCaseText)
      );
   }

   openConnectIframeModal() {
      this.connectIframeModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
      this.connectIframeModalRef.componentInstance.module = 'portfolio';
   }

   initiateBrokerage(brokerage: any) {
      if(this.currentBrokeragePortfoliosCount < this.maxBrokeragePortfoliosCount) {
         this.currBrokerageId = brokerage.brokerage_id;
         this.isConnectLoading = true;
         this.portfolioServices
            .getConnectUrl(brokerage.brokerage_id, false)
            .then((resp: any) => {
               if (resp.status) {
                  this.isConnectLoading = false;
                  this.activeModal.dismiss();
                  this.redirectUrl = resp.data.redirect_url;
                  this.openConnectIframeModal();
               }
            })
            .catch((err: any) => {
               console.log(err);
               this.isConnectLoading = false;
            });
      } else {
         this.openMessagePopup('broker');
      }
   }

   navigateToPricingPage() {
      this.activeModal.dismiss()
      this.router.navigate(['/pricing'])
   }

   openMessagePopup(type: 'broker' | 'manual') {
      this.portfolioService.openMessagePopup(type)
   }

   getAccountsCount() {
      this.portfolioService.getAccountCounts().pipe(takeUntil(this.destroy$)).subscribe((data) => {
         // console.log(data);
         this.currentManualPortfoliosCount = data.manual_connected_count;
         this.currentBrokeragePortfoliosCount = data.brokerage_connected_count;
      })
   }

   canCreateManualPortfolio(): boolean {
      return this.currentManualPortfoliosCount < this.maxManualPortfoliosCount;
   }

   canConnectBrokeragePortfolio(): boolean {
      return this.currentBrokeragePortfoliosCount < this.maxBrokeragePortfoliosCount
   }

}
