import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, tap, throwError } from "rxjs";
import { FirstPrompterResponse } from "src/app/models/firstPrompter.model";
import { environment } from "src/environments/environment";

@Injectable({
   providedIn: 'root'
})

export class ReferralService {
   private url: string = environment.apiUrl;

   constructor(
      private http: HttpClient
   ) { }

   checkFirstprompter(): Observable<any> {
      return this.http.post<any>(`${this.url}/api/promoter/check-promoter-exists`, {})
   }

   fetchFirstprompter(): Observable<FirstPrompterResponse> {
      return this.http.post<FirstPrompterResponse>(`${this.url}/api/promoter/fetch-promoter`, {}).pipe(
         tap(response => response),
         catchError(error => {
            return throwError(() => new Error(error));
         })
      )
   }

   // getTokenFromApi(): Observable<string> {
   //    return this.http.get<{ token: string }>('https://api.example.com/get-token').pipe(
   //      tap(response => console.log('API Response:', response)),
   //      catchError(error => {
   //        console.error('API Call Failed:', error);
   //        return throwError(() => new Error('API call failed. Please check your connection.'));
   //      })
   //    );
   //  }
}
