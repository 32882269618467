import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StripeFreeServices } from '../../services/stripe-free.service';

@Component({
   selector: 'app-stripe-free',
   templateUrl: './stripe-free.component.html',
   styleUrls: ['./stripe-free.component.scss'],
})
export class StripeFreeComponent implements OnInit {
   public subscriptions: Subscription[] = [];

   public showList: boolean = false;
   public status: number = 0;

   constructor(private stripeFree: StripeFreeServices, private router:Router) {}

   ngOnInit(): void {
      let subscription = this.stripeFree.StripeFreeStatus.subscribe(
         (status: number) => {
            if(status!=null)
            {
               this.showList = true;
               this.status = status;
            }
            else
            {
               this.showList = false;
            }
         }
      );

      this.subscriptions.push(subscription);
   }

   hide(){
      this.stripeFree.hide();
   }

   goToStripe()
   {
      this.hide();
      this.router.navigate(['/pages/stripe-premium-mobile']);
   }
}
