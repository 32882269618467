<div class="px-3 px-lg-0" *ngIf="complianceStatus !== 'NOT_UNDER_COVERAGE'">
   <article class="grey-box card-wrapper purification-article py-4 px-3 p-lg-4">
      <h4 class="heading-h4">Purification</h4>
      <p class="mb-3">
         <Strong class="fw-600">Did you know:</Strong> Even Halal stocks have some non-halal business revenue which needs to be purified?
      </p>
      <p class="mb-2">
         Purify your investments in <strong class="fw-600">{{ stockName}}</strong>
      </p>
      <a class="text-decoration-none text-center d-block bordered-btn d-block w-100 py-2 px-4 rounded-3" [routerLink]="['/purification']" [queryParams]="{ symbol: stockTicker }" queryParamsHandling="merge">
         Calculate Purification Amount
      </a>
   </article>
</div>
