import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

interface Stat {
   image_path: string,
   title: string | number,
   description: string
}

@Component({
   selector: 'app-musaffa-stats',
   templateUrl: './musaffa-stats.component.html',
   styleUrls: ['./musaffa-stats.component.scss']
})

export class MusaffaStatsComponent implements OnInit {
   private destroy$ = new Subject<void>();
   userRole: string | null = null;
   statsLoader: boolean = false;
   userCount: any
   stockCount: any
   etfsCount: any
   
   // stats: Stat[] = [
   //    {
   //       image_path: '/assets/images/dashboard/search-engine.svg',
   //       title: '#2',
   //       description: 'Islamic Fintech in the USA'
   //    },
   //    {
   //       image_path: '/assets/images/dashboard/world.svg',
   //       title: '410,000+',
   //       description: 'Muslim investors use our platform from 195 countries'
   //    },
   //    {
   //       image_path: '/assets/images/dashboard/review.svg',
   //       title: '4.7',
   //       description: 'Average rating from 5000+ reviews'
   //    },
   //    {
   //       image_path: '/assets/images/dashboard/monitor-stocks.svg',
   //       title: '90,000+',
   //       description: 'Stocks are screened for Shariah compliance.'
   //    },
   //    {
   //       image_path: '/assets/images/dashboard/monitor-etfs.svg',
   //       title: '3,000+',
   //       description: 'ETFs are screened for Shariah compliance.'
   //    },
   // ]

   constructor(
      private typesenseService: TypesenseService
   ) { }

   ngOnInit(): void {
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;
         });

      this.getMusaffaStats()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getMusaffaStats() {
      this.statsLoader = true
      this.typesenseService.getWebsiteCollectionData().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            this.userCount = response.user_count
            this.stockCount = response.stockCount
            this.etfsCount = response.etfCount
            // this.stats[1].title = +response.user_count;
            // this.stats[3].title = response.stockCount;
            // this.stats[4].title = response.etfCount;
            this.statsLoader = false;
         },
         error: (error) => {
            console.error(error);
            this.statsLoader = false;
         },
         complete: () => {
            this.statsLoader = false;
         },
      })
   }

   isNumber(value: any): boolean {
      return typeof value === 'number' && !isNaN(value);
   }

}
