<section class="px-3 px-lg-0">
   <div class="card-inner-container py-4 px-3 p-lg-4">
      <header class="mb-4">
         <h4 class="mb-1 header-h4">Shariah Compliance</h4>
         <p class="mb-0 header-para">Screening Methodology: <span class="fw-600">AAOIFI</span></p>
      </header>

      <ng-container *ngIf="complianceStatus">
         <div>
            <!-- locked status start -->
            <ng-container *ngIf="complianceStatus === 'LOCKED'">
               <div class="d-flex align-items-center justify-content-center py-3 mb-4 compliance-chip locked-chip" >
                  <img src="/assets/images/complanise-locked.svg" alt="lock image" class="lock-image me-3" />
                  <h5 class="mb-0 status-text">LOCKED</h5>
               </div>
            </ng-container>
            <!-- locked status end -->

            <!-- halal status start -->
            <ng-container *ngIf="complianceStatus === 'COMPLIANT'">
               <div class="d-flex align-items-center justify-content-center compliance-chip halal-chip" >
                  <h5 class="mb-0 status-text">HALAL</h5>
               </div>
            </ng-container>
            <!-- halal status end -->

            <!-- not-halal status start -->
            <ng-container *ngIf="complianceStatus === 'NON_COMPLIANT'">
               <div class="d-flex align-items-center justify-content-center mb-4 compliance-chip not-halal-chip" >
                  <h5 class="mb-0 status-text">NOT HALAL</h5>
               </div>
            </ng-container>
            <!-- not-halal status end -->

            <!-- doubtful status start -->
            <ng-container *ngIf="complianceStatus === 'QUESTIONABLE'">
               <div class="d-flex align-items-center justify-content-center mb-4 compliance-chip doubtful-chip" >
                  <h5 class="mb-0 status-text">DOUBTFUL</h5>
               </div>
            </ng-container>
            <!-- doubtful status end -->

            <!-- not-covered status start -->
            <ng-container *ngIf="complianceStatus === 'NOT_UNDER_COVERAGE'">
               <div class="d-flex align-items-center justify-content-center mb-4 compliance-chip not-covered-chip" >
                  <h5 class="mb-0 status-text">NOT COVERED</h5>
               </div>
            </ng-container>
            <!-- not-covered status end -->
         </div>

         <div class="d-flex align-items-center justify-content-center ratings-container" *ngIf="complianceStatus == 'COMPLIANT'">
            <div class="d-flex align-items-center justify-content-center gap-3" *ngIf="showRanking; else notRanking">
               <div class="stars-container d-flex align-items-center">
                  <div class="stars" [ngClass]="{ active: complianceRanking >= 1 }" ></div>
                  <div class="stars" [ngClass]="{ active: complianceRanking >= 2 }" ></div>
                  <div class="stars" [ngClass]="{ active: complianceRanking >= 3 }" ></div>
                  <div class="stars" [ngClass]="{ active: complianceRanking >= 4 }" ></div>
                  <div class="stars" [ngClass]="{ active: complianceRanking >= 5 }" ></div>
               </div>

               <button class="bg-transparent border-0 rounded-circle" [ngbTooltip]="tipContent" placement="left-top">
                  <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 24 24" width="20" height="20" color="#7e7e7e" fill="none">
                     <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                     <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M11.992 8H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </button>
               <ng-template #tipContent>
                  <ng-container>
                     Musaffa Halal Rating is a proprietary ranking metric that compares all ETFs in a given country from lowest to highest Shariah compliance standing.
                  </ng-container>
               </ng-template>
            </div>

            <ng-template #notRanking>
               <div class="skeleton-loader py-3 rounded-3 w-100" *ngIf="ratingLoader"></div>

               <ng-container *ngIf="!ratingLoader">
                  <button class="btn p-0 d-flex shadow-none align-items-center mx-auto card-colored-btn gap-2" (click)="handleRatingPopup()">
                     <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.845 6.627H11.2v-2.27A4.36 4.36 0 0 0 6.843 0a4.36 4.36 0 0 0-4.356 4.357v2.27h-.645a.923.923 0 0 0-.923.923v7.527c0 .51.414.923.923.923h10.002c.51 0 .924-.413.924-.923V7.55a.923.923 0 0 0-.923-.923m-4.02 6.61H5.862l.352-1.675a1.176 1.176 0 0 1 .63-2.172 1.176 1.176 0 0 1 .631 2.172zm1.82-6.61H4.043v-2.27a2.805 2.805 0 0 1 2.802-2.803 2.805 2.805 0 0 1 2.802 2.803z" fill="#26A79C"/>
                     </svg>
                     <span class="fw-500">Musaffa Halal Ratings</span>
                  </button>
               </ng-container>
            </ng-template>
         </div>

         <ng-container *ngIf="etfAssetClass === 'Fixed Income' && isUserLoggedIn">
            <p class="mb-0 mx-auto request-text">
               The inherent guarantee of returns in fixed-income ETFs is considered non-compliant with Shariah's emphasis on shared profit and risk.
            </p>
         </ng-container>

         <ng-container *ngIf="handleComplianceReportTabVisibility()">
            <button class="btn d-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3" (click)="handleButtonClick()" >
               <span>View Detailed Report</span>
            </button>
         </ng-container>

         <ng-container *ngIf="!handleComplianceReportTabVisibility() && !isUserLoggedIn">
            <button (click)="accessControlService.accessDenied()" *ngIf="etfAssetClass !== 'Fixed Income'"  class="btn d-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3"  >
               Sign up for free & Unlock!
            </button>
         </ng-container>


         <ng-container *ngIf="!(etfAssetClass === 'Fixed Income' || (etfAssetClass === 'Equity' && complianceStatus === 'NOT_UNDER_COVERAGE')) && getLastUpdated()">
            <hr class="my-4">

            <p class="last-update-para mb-1">Last Updated: <span class="fw-600"> {{ getLastUpdated() }}</span></p>
         </ng-container>

      </ng-container>


   </div>
</section>

<!-- <ng-template #showCompliantHistory let-modal>
   <app-compliance-history
      (closeModalEmitter)="closeComplianceHistoryModal()"
      [complianceHistorydata]="complianceHistorydata"
      [isLoading]="isComplianceHistoryLoading"
   ></app-compliance-history>
</ng-template> -->

<!-- <ng-template #updateRequestConfirmationPopup let-modal>
   <section class="p-3 p-lg-4 m-0">
      <h5 class="mb-4 header-h4 modal-h4 fw-500 text-center">
         Send a request to update Compliance Report of <strong class="fw-700">{{ ticker }}</strong>?
      </h5>

      <div class="">
         <div class="d-flex align-items-center gap-3 justify-content-end btns-container" >
            <button type="button" class="btn py-2 px-4 text-white fw-500 shadow-none modal-btn bg-danger rounded-3" (click)="closeConfirmationPopup()" >
               <span>Cancel</span>
            </button>
            <button type="button" class="btn py-2 px-4 text-white fw-500 shadow-none modal-btn bg-success rounded-3" (click)="reportRequest()" >
               <span>Send Request</span>
            </button>
         </div>
      </div>
   </section>
</ng-template> -->



