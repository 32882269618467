import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
   catchError,
   debounceTime,
   distinctUntilChanged,
   lastValueFrom,
   map,
   Observable,
   OperatorFunction,
   switchMap,
} from 'rxjs';
import { ApiService } from '../../services/api.service';
import { TypesenseService } from '../../services/typesense.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { GeneralInfoPopupComponent } from '../general-info-popup/general-info-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';

@Component({
   selector: 'app-purification-calculator',
   templateUrl: './purification-calculator.component.html',
   styleUrls: ['./purification-calculator.component.scss'],
})
export class PurificationCalculatorComponent implements OnInit {
   loading = false;
   @Output() calculationFetched: EventEmitter<any> = new EventEmitter();
   @Output() companyProfileFetched: EventEmitter<any> = new EventEmitter();
   @Input() symbol: any;
   companyProfile?: any;
   isMobile = false;
   maxDate;

   private subscriptions: Subscription[];
   userRole: string | null = null;
   @Input() isSample: boolean = false;
   selectedStock: any = '';
   limitReachedContent: any = {};
   notHalalPurificationContent: any = {};
   isStockSelected: boolean = false;
   showErrorMsg: boolean = false;
   disableButton: boolean = false;
   @Input() showSampleBtn: boolean = false;

   constructor(
      private typesenseService: TypesenseService,
      private apiService: ApiService,
      private fb: FormBuilder,
      private toaster: ToastrService,
      private observer: BreakpointObserver,
      private firebaseConfigService: FirebaseRemoteConfigService,
      private router: Router,
      private title: Title,
      private auth: AuthService,
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils
   ) {
      this.subscriptions = [];
      this.maxDate = new Date().toISOString().split('T')[0];
      this.observer.observe('(max-width: 991px)').subscribe((result) => {
         this.isMobile = result.matches;
         // Do something with the result
      });
   }

   calculateForm: FormGroup = new FormGroup({});

   formatSymbol(value: any) {
      return value.stockName;
   }

   inputFormatBandListValue(value: any) {
      return value.stockName;
   }

   search: any = (text$: Observable<string>) => {
      return text$.pipe(
         debounceTime(200),
         distinctUntilChanged(),
         // switchMap allows returning an observable rather than maps array
         switchMap((searchText) =>
            this.typesenseService.searchOnlyStockTypeSense(searchText)
         )

         // catchError(new ErrorInfo().parseObservableResponseError)
      );
   };

   async fetchCompanyProfile() {
      try {
         this.companyProfile = await lastValueFrom(
            this.typesenseService.getCompanyProfile(this.symbol)
         );
         this.companyProfileFetched.emit({
            companyProfile: this.companyProfile,
         });
      } catch (e) {}
   }

   ngOnInit(): void {
      this.title.setTitle('Musaffa | Purification Calculator');
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );
      let symbolData = {
         stockName: '',
      };
      if (this.symbol) symbolData['stockName'] = this.symbol;
      this.fetchCompanyProfile();

      // this.calculateForm = this.fb.group({
      //   symbol:[symbolData,[Validators.required]],
      //   count: [2, [Validators.required]],
      //   start:['2022-01-01', [Validators.required]],
      //   end:['2022-10-01', [Validators.required]],
      // })

      this.calculateForm = this.fb.group({
         symbol: [symbolData, [Validators.required]],
         count: [this.isSample ? 50 : '', [Validators.required]],
         start: [this.isSample ? '2023-09-01' : '', [Validators.required]],
         end: [this.isSample ? '2023-09-30' : '', [Validators.required]],
      });

      if (this.isSample) {
         this.calculate();
      }

      // this.calculateForm = this.fb.group({
      //   symbol:[symbolData,[Validators.required]],
      //   count: ['', [Validators.required]],
      //   start:['', [Validators.required]],
      //   end:['', [Validators.required]],
      // })

      const countInput = document.getElementById('count') as HTMLInputElement;
      countInput.addEventListener('input', this.onCountInput.bind(this));

      this.setConfigDefaults();
      this.setPurificationDefaults();
   }

   async setConfigDefaults() {
      var config = await this.firebaseConfigService.getConfigDefault();
      this.limitReachedContent.title = config?.purification_limit_text?.title;
      this.limitReachedContent.description =
         config?.purification_limit_text?.description;
   }

   async setPurificationDefaults() {
      var config = await this.firebaseConfigService.getPurificationDefaults();
      this.notHalalPurificationContent.title =
         config?.not_halal_item_text?.title;
      // config?.not_halal_item_text?.title
      this.notHalalPurificationContent.description =
         config?.not_halal_item_text?.description;
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   onStockSelected(selectedItem: any) {
      this.selectedStock = selectedItem;
      // console.log(selectedItem.item.currency);
      this.apiService.setSelectedCurrency(selectedItem.item.currency);

      if (this.selectedStock.item.status === 'NOT_UNDER_COVERAGE') {
         const modal = this.modalService.open(GeneralInfoPopupComponent, {
            centered: true,
         });
         modal.componentInstance['title'] = this.notHalalPurificationContent
            ?.title
            ? this.notHalalPurificationContent.title.replaceAll(
                 /{{name}}/gi,
                 this.selectedStock.item?.companyName ?? ''
              )
            : '';
         modal.componentInstance['description'] =
            this.notHalalPurificationContent?.description ?? '';
         modal.componentInstance['showUpgradeButton'] = false;

         // this.calculateForm.controls['symbol'].setValue('')
         // this.toaster.error("Stock Purification is only applicable to Halal stocks. Not-Halal and Doubtful Stocks are not advisable to invest in");
         this.showErrorMsg = true;
         this.isStockSelected = false;
         this.disableButton = true;
      } else {
         this.showErrorMsg = false;
         this.disableButton = false;
      }
   }

   onFocus() {
      this.isStockSelected = false;
   }

   onCountInput(event: Event) {
      const input = event.target as HTMLInputElement;
      const inputValue = input.value;

      if (inputValue.length > 9) {
         input.value = inputValue.slice(0, 9);
      }
   }

   getTickerFromInput() {
      let req_data = { ...this.calculateForm.value };
      return req_data['symbol']['stockName'];
   }

   async calculate() {
      let limitStatus = 'LIMIT_NOT_REACHED';
      if (!this.isSample) {
         this.disableButton = true;
         const limitData = await this.apiService.purificationLimitCheck();
         limitStatus = limitData.status_code;
         this.disableButton = false;
      }

      if (limitStatus === 'LIMIT_REACHED') {
         this.purificationLimitError();
      } else {
         this.calculationFetched.emit(null);

         let req_data = { ...this.calculateForm.value };

         if (req_data['start'] > req_data['end']) {
            this.toaster.error('Buy date cannot be greater than sell date');
            return;
         }

         if (this.validateDate(req_data['start'])) {
            return;
         }

         if (this.validateDate(req_data['end'])) {
            return;
         }

         this.loading = true;
         this.symbol = this.getTickerFromInput();
         console.log(this.symbol);
         

         try {
            let data: any;
            if (this.isSample) {
               data = await this.apiService.purificationSampleCalculation();
            } else {
               data = await this.apiService.purificationCalculation(
                  req_data['symbol']['stockName'],
                  req_data['count'],
                  req_data['start'],
                  req_data['end']
               );
            }
            if (data.status_code !== 'LIMIT_REACHED') {
               var dividendErr = data?.dividend?.error;
               var interestErr = data?.interest?.error;
               var haramErr = data?.haram?.error;
               if (dividendErr || interestErr || haramErr) {
                  var errorArr = [];
                  if (dividendErr) errorArr.push(dividendErr);
                  if (interestErr) errorArr.push(interestErr);
                  if (haramErr) errorArr.push(haramErr);

                  var message = errorArr.join('\n');
                  this.toaster.error(message);
                  this.loading = false;
               }
               // else{

               // if(this.isMobile)
               //   this.router.navigate(['purification','options'],{})
               // else
               this.calculationFetched.emit({
                  data: data,
                  isMobile: this.isMobile,
               });
               this.analyticsUtils.Purification_Calculate_Success(this.symbol);
               await this.fetchCompanyProfile();
            } else {
               this.purificationLimitError();
            }
            // }
         } catch (e) {
            if (e instanceof HttpErrorResponse) {
               if (e?.error?.error) {
                  this.toaster.error(e.error.error);
                  this.analyticsUtils.Purification_Calculate_Failed(
                     this.symbol
                  );
               }
            }
            // this.toaster.error("Stock Purification is only applicable to Halal stocks. Not-Halal and Doubtful Stocks are not advisable to invest in");
            console.log(e);
         }
         this.loading = false;
      }
   }

   purificationLimitError() {
      const modal = this.modalService.open(GeneralInfoPopupComponent, {
         centered: true,
      });
      modal.componentInstance['title'] = this.limitReachedContent?.title ?? '';
      modal.componentInstance['description'] =
         this.limitReachedContent?.description ?? '';
      modal.componentInstance['showUpgradeButton'] = true;
   }

   checkRole(role: string): boolean {
      return this.auth.checkRole(role);
   }

   validateDate(date: any): boolean {
      const selectedDate: Date = new Date(date);
      const minimumDate: Date = new Date('1990-01-01');
      const currentDate: Date = new Date();

      if (selectedDate < minimumDate) {
         this.toaster.error('Buy date cannot be less than 1990');
         return true;
      }

      if (selectedDate > currentDate) {
         this.toaster.error('Sell date cannot be greater than today');
         return true;
      }

      return false;
   }

   convertComplianceStatus(status: string) {
      if (status === 'QUESTIONABLE') return 'Doubtful';
      else if (status === 'COMPLIANT') return 'Halal';
      else if (status === 'NON_COMPLIANT') return 'Not Halal';
      else return 'Not Covered';
   }

   getStatusClasses(status: string) {
      return {
         halal: status === 'COMPLIANT',
         'not-halal': status === 'NON_COMPLIANT',
         doubtful: status === 'QUESTIONABLE',
      };
   }

   purificationCalculateTapped() {
      if (!this.checkRole('ANONYMOUS')) {
         this.analyticsUtils.Purification_Calculate_Tapped(this.symbol);
      }
   }
}
