import { BreakpointObserver } from '@angular/cdk/layout';
import { DecimalPipe, Location, PlatformLocation } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { screaningMillionPipe } from '../../pipes/screaning-million.pipe';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TypesenseService } from '../../services/typesense.service';

import {
   NgbModal,
   ModalDismissReasons,
   NgbPanelChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-etf-screener-report',
   templateUrl: './etf-screener-report.component.html',
   styleUrls: ['./etf-screener-report.component.scss'],
})
export class EtfScreenerReportComponent implements OnInit {
   id: any;
   cbaStatusDisclaimer: any;
   @Input() storedResponse: any;
   isLoading: boolean = false;
   isLimitReached: boolean = false;
   subTicker!: string;
   rate: number = 1;
   usdRate: number = 1;
   currency!: string;
   closeResult!: string;
   revenueChartData = {
      halal: null,
      notHalal: null,
      questionable: null,
   };
   @Input() isSample: boolean = false;
   @Output() responseLoaded: EventEmitter<any> = new EventEmitter<any>();

   constructor(
      private screaningMillionPipe: screaningMillionPipe,
      private decimalPipe: DecimalPipe,
      private observer: BreakpointObserver,
      private apiService: ApiService,
      private datePipe: DatePipe,
      private route: ActivatedRoute,
      private typesenseService: TypesenseService,
      private router: Router,
      private toaster: ToastrService,
      private modalService: NgbModal,
      private location: PlatformLocation,
      private configService: FirebaseRemoteConfigService
   ) {
      router.events.forEach((event) => {
         if (event instanceof NavigationStart) {
            if (event.navigationTrigger === 'popstate') {
               /* Do something here */
               this.modalService.dismissAll();
            }
         }
      });
   }
   isManualSet: boolean = false;
   active = 1;
   isExpanded = false;
   showPercent = true;
   @Input() data?: any = null;
   @Input() etfProfile?: any = null;
   isMobile = false;
   panelStatus = {
      businessPanel: false,
      businessPanel2: false,
      interestCalculationPanel: false,
      debtCalculationPanel: false,
      detailedReportPanel: false,
   };
   businessObj = {
      showPercent: true,
   };
   securitiesObj = {
      showPercent: true,
   };
   debtObj = {
      showPercent: true,
   };

   grossIncomeObj = {
      halal: {
         items: [],
      },
      notHalal: {
         items: [],
      },
      doubtful: {
         items: [],
      },
   };

   otherIncomeObj = {
      halal: {
         items: [],
      },
      notHalal: {
         items: [],
      },
      doubtful: {
         items: [],
      },
   };

   async ngOnInit(): Promise<void> {
      // this.data.debtStatus = 'Pass'
      // this.data.revenueBreakdownStatus = 'Pass'
      // this.data.securitiesAndAssetsStatus = 'Pass'
      // this.isManualSet = true;
      this.isManualSet = this.etfProfile?.is_manual_set == 1;
      this.route.paramMap.subscribe((params) => {
         this.id = params.get('id');
      });
      if(this.etfProfile?.shariahCompliantStatus === 'NOT_UNDER_COVERAGE') {
         this.data = {
            actualComplianceStatus: this.etfProfile?.shariahCompliantStatus
         }
      } else {
         if (!this.data) {
            let response: any;
            if (this.storedResponse) {
               response = this.storedResponse
            } else {
               this.isLoading = true;
               if (this.isSample) {
                  response = await this.apiService.getSampleEtfComplianceInfo()
               } else {
                  response = await this.apiService.getEtfComplianceInfo(this.id)
               }
               this.responseLoaded.emit(response)
               this.isLoading = false;
            }
            if (response?.status_code === 'SUCCESS') {
               const data: any = response?.data?.compliance_report
               this.processData(data)
            } else if (response?.status_code === 'LIMIT_REACHED') {
               this.isLimitReached = true
            } else if (response?.status_code === 'NOT_COVERED') {
               this.data = {
                  actualComplianceStatus: 'NOT_UNDER_COVERAGE'
               }
            } else {
               // this.toaster.error('Something went wrong')
            }
         } else {
            this.processData(this.data)
         }
      }
   }

   processData(data: any) {
      this.data = data;
      this.currency = this.etfProfile?.navCurrency;

      this.revenueChartData = {
         halal: this.data?.totalHalalRatio,
         notHalal: this.data?.totalNotHalalRatio,
         questionable: this.data?.totalDoubtfulRatio,
      };
      this.configService.getcbaStatusDisclaimer().then((data) => {
         this.cbaStatusDisclaimer = data;
      });
      // this.getSubTickers(this.id)

      this.usdRate = this.data?.usdRate;
      this.observer.observe('(max-width: 768px)').subscribe((result) => {
         this.isMobile = result.matches;
         // Do something with the result
      });

      if (this.isBusinessActivityAvailable()) this.active = 1;
      else if (this.isSecuritiesAvailable()) this.active = 2;
      else if (this.isDebtAvailable()) this.active = 3;

      // this.parseTree(this.data['revenueBreakdownJson.items'], 0, this.grossIncomeObj)
      // this.parseTree(this.data['interestIncomeJson.items'], 0, this.otherIncomeObj)
   }

   parseTree(array: any, level: number, bucket: any) {
      level += 1;
      for (var i = 0, len = array.length; i < len; i++) {
         var obj = array[i];
         if (obj.items.length > 0) {
            this.parseTree(obj.items, level, bucket);
         } else {
            if (obj.selector == 'COMPLIANT') {
               bucket.halal.items.push(obj);
               bucket.halal.percent += obj.percentage;
            } else if (obj.selector == 'NON_COMPLIANT') {
               bucket.notHalal.items.push(obj);
               bucket.notHalal.percent += obj.percentage;
            } else if (obj.selector == 'QUESTIONABLE') {
               bucket.doubtful.items.push(obj);
               bucket.doubtful.percent += obj.percentage;
            }
         }
      }
   }

   isBusinessActivityAvailable() {
      return this.data?.['revenueBreakdownStatus'];
   }

   isSecuritiesAvailable() {
      return this.data?.['securitiesAndAssetsStatus'];
   }

   isDebtAvailable() {
      return this.data?.['debtStatus'];
   }

   getStatusDisplayValue() {
      switch (this.data?.actualComplianceStatus) {
         case 'COMPLIANT':
            return 'Halal';
         case 'NON_COMPLIANT':
            return 'Not Halal';
         case 'QUESTIONABLE':
            return 'Doubtful';
         case 'NOT_UNDER_COVERAGE':
            return 'Coming Soon';
         default:
            return '';
      }
   }

   changeTitle($event: NgbPanelChangeEvent, acc: any) {
      if (acc.isExpanded($event.panelId)) {
         this.isExpanded = true;
      } else {
         this.isExpanded = false;
      }
   }

   getPassFailText() { }

   isActive(currentId: any) {
      if (currentId == this.active) {
      }
   }

   getSubTickers(id: string) {
      let objsArr = this.data.subTickersInfo;

      for (const obj of objsArr) {
         if (this.id === obj.subTicker) {
            this.rate = obj.rate ? obj.rate : 1;
         }
      }
   }

   // getRate() {

   // }

   getLastUpdated() {
      return this.datePipe.transform(
         this.data?.lastUpdate * 1000,
         'MMMM dd, yyyy'
      );
   }

   getSecuritiesItemVal(obj: any) {
      if (!this.securitiesObj.showPercent)
         return this.screaningMillionPipe.transform(obj?.amountInOnes);
      else return this.decimalPipe.transform(obj?.percentage, '1.2-2') + '%';
   }

   getBusinessValueOrPercent(value: any, percent: any) {
      if (this.businessObj.showPercent)
         return this.decimalPipe.transform(percent, '1.2-2') + '%';
      // return this.screaningMillionPipe.transform(this.getConvertedAmount(value))
      else return this.screaningMillionPipe.transform(value);
   }

   getInterestDebtValueOrPercent(value: any, percent: any) {
      if (this.debtObj.showPercent)
         return this.decimalPipe.transform(percent, '1.2-2') + '%';
      // return this.screaningMillionPipe.transform(this.getConvertedAmount(value))
      else return this.screaningMillionPipe.transform(value);
   }

   getSecuritiesAndAssetsDebtValueOrPercent(value: any, percent: any) {
      if (this.securitiesObj.showPercent)
         return this.decimalPipe.transform(percent, '1.2-2') + '%';
      // return this.screaningMillionPipe.transform(this.getConvertedAmount(value))
      else return this.screaningMillionPipe.transform(value);
   }

   getDebtItemSubtitleVal(index: number) {
      let subtitles = [
         'Due to be paid within a 12-month period',
         'Due to be paid in more than a 12-month period',
      ];
      if (index < subtitles.length) return subtitles[index];
      return '';
   }
   getDebtItemVal(obj: any) {
      if (!this.debtObj.showPercent)
         return this.screaningMillionPipe.transform(obj?.amountInOnes);
      else return this.decimalPipe.transform(obj?.percentage, '1.2-2') + '%';
   }

   getTotalBusinessAct() {
      if (!this.businessObj.showPercent)
         // return this.screaningMillionPipe.transform(this.getConvertedAmount(this.data?.etfTotalRevenue))
         return this.screaningMillionPipe.transform(this.data?.etfTotalRevenue);
      else return '100%';
   }

   getConvertedAmount(amt: any) {
      return (amt / this.usdRate) * this.rate;
   }

   getTotalInterestBearingDebt() {
      if (!this.debtObj.showPercent)
         // return this.screaningMillionPipe.transform(this.getConvertedAmount(this.data?.['totalIbDebtRevenue'] ?? 0) * this.data?.units)
         return this.screaningMillionPipe.transform(
            this.data?.['totalIbDebtRevenue'] ?? 0 * this.data?.units
         );
      else
         return (
            this.decimalPipe.transform(
               this.data?.['totalDebtRatio'] ?? 0,
               '1.2-2'
            ) + '%'
         );
   }

   getTotalSecuritiesAndAssets() {
      if (!this.securitiesObj.showPercent)
         // return this.screaningMillionPipe.transform(this.getConvertedAmount(this.data?.['totalIbSecAssetRevenue'] ?? 0) * this.data?.units)
         return this.screaningMillionPipe.transform(
            this.data?.['totalIbSecAssetRevenue'] ?? 0 * this.data?.units
         );
      else
         return (
            this.decimalPipe.transform(
               this.data?.['totalIbSecAssetRatio'] ?? 0,
               '1.2-2'
            ) + '%'
         );
   }

   getQuarterText() {
      var quarter = '';
      if (['Annual', 'ANNUAL'].includes(this.data?.reportTypeSection)) {
         var text = `${this.data?.reportTypeYear} Annual Report`;
         return text;
      } else {
         switch (this.data?.reportTypeSection) {
            case 'firstQuarter':
            case 'FIRST_QUARTER':
               quarter = '1st';
               break;
            case 'secondQuarter':
            case 'SECOND_QUARTER':
               quarter = '2nd';
               break;
            case 'thirdQuarter':
            case 'THIRD_QUARTER':
               quarter = '3rd';
               break;
            case 'fourthQuarter':
            case 'FOURTH_QUARTER':
               quarter = '4th';
               break;
            default:
               quarter = '';
         }

         var text = `${this.data?.reportTypeYear} ${quarter} Quarter Report`;
         return text;
      }
   }

   onPanelShown(event: any) {
      switch (event) {
         case 'business-panel':
            this.panelStatus.businessPanel = true;
            break;
         case 'business-panel2':
            this.panelStatus.businessPanel2 = true;
            break;
         case 'debt-calculation-panel':
            this.panelStatus.debtCalculationPanel = true;
            break;
         case 'interest-calculation-panel':
            this.panelStatus.interestCalculationPanel = true;
            break;
         case 'detailed-report-panel':
            this.panelStatus.detailedReportPanel = true;
            break;
      }
   }

   onPanelHidden(event: any) {
      switch (event) {
         case 'business-panel':
            this.panelStatus.businessPanel = false;
            break;
         case 'business-panel2':
            this.panelStatus.businessPanel2 = false;
            break;
         case 'debt-calculation-panel':
            this.panelStatus.debtCalculationPanel = false;
            break;
         case 'interest-calculation-panel':
            this.panelStatus.interestCalculationPanel = false;
            break;
         case 'detailed-report-panel':
            this.panelStatus.detailedReportPanel = false;
            break;
      }
   }

   setActive(no: number) {
      this.active = no;
      this.tabChange();
   }
   tabChange() {
      this.panelStatus = {
         businessPanel: false,
         businessPanel2: false,
         interestCalculationPanel: false,
         debtCalculationPanel: false,
         detailedReportPanel: false,
      };
   }
   getCardClass(isOpen: boolean, status: string) {
      var cardClass = 'screening-report-panel-card';
      if (status == 'PASS') cardClass += ' pass';
      if (status == 'FAIL') cardClass += ' fail';
      if (isOpen) cardClass += ' active';
      return cardClass;
   }

   filterGrossSaleItems(status: string) {
      return this.data?.['revenueBreakdownJson.items'].filter((item: any) => {
         return item['selector'] == status;
      });
   }

   filterOtherIncomeItems(status: string) {
      return this.data?.['interestIncomeJson.items'].filter((item: any) => {
         return item['selector'] == status;
      });
   }

   canShowCbaComment() {
      if (
         this.data?.cbaStatus == 'FAIL' &&
         this.data?.revenueBreakdownStatus == 'PASS' &&
         this.data?.securitiesAndAssetsStatus == 'PASS' &&
         this.data?.debtStatus == 'PASS'
      ) {
         return true;
      } else {
         return false;
      }
   }
}
