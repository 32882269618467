import { Component, Input, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-forecast-revenue-chart',
  templateUrl: './forecast-revenue-chart.component.html',
  styleUrls: ['./forecast-revenue-chart.component.scss']
})
export class ForecastRevenueChartComponent implements OnInit, AfterViewInit {
  @Input() chartData: any;
  @Input() chartId!: string;
  barChart: any;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.generateChart();
  }

  private generateChart(): void {
    this.barChart = new Chart(this.chartId, {
      type: 'bar',
      data: this.chartData,
      options: {
        legend: {
          display: false
        },
        maintainAspectRatio: true,
        scales: {
          xAxes: [{ stacked: false }],
          yAxes: [{ stacked: false }]
        }
      }
    });
  }
}












// import { Component, OnInit } from '@angular/core';
// import { Chart } from 'chart.js';

// @Component({
//   selector: 'app-forecast-revenue-chart',
//   templateUrl: './forecast-revenue-chart.component.html',
//   styleUrls: ['./forecast-revenue-chart.component.scss']
// })
// export class ForecastRevenueChartComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//     var myChart = new Chart("revenueChart", {
//       type: 'bar',
//       data: {
//         labels: ['Q3, 2022', 'Q4, 2022', 'Q1, 2023', 'Q2, 2023', 'Q3, 2023', 'Q4, 2023'],
//         datasets: [{
//           data: [12, 19, 3, 5, 5, 8],
//           barThickness: 30,
//           backgroundColor: [ '#D9D9D9', '#D9D9D9', '#D9D9D9', '#B3B3B3', '#ACE8DC', '#EAC4D5'],
//         },
//         {
//           data: [0, 0, 0, 0, 6, 7],
//           barThickness: 30,
//           backgroundColor: ['#D9D9D9', '#D9D9D9', '#D9D9D9', '#B3B3B3', '#ACE8DC', '#D6EADF'],
//         },
//         {
//           data: [0, 0, 0, 0, 8, 10],
//           barThickness: 30,
//           backgroundColor: [ '#D9D9D9', '#D9D9D9', '#D9D9D9', '#B3B3B3', '#ACE8DC', '#95B8D1'],
//         }]
//       },
//       options: {
//         legend: {
//           display: false
//         },
//         maintainAspectRatio: true,
//         scales: {
//           xAxes: [{
//               stacked: true
//           }],
//           yAxes: [{
//               stacked: true
//           }]
//         }
//       }
//     });
//   }

// }
