<div class="confirmation-popup-container rounded-2">
    <header class="header p-3 bg-light d-flex align-items-center justify-content-between">
        <h5 class="mb-0 fw-bold fs-5 text-center">
            <span *ngIf="selectedAccount.is_manual">Delete</span><span *ngIf="!selectedAccount.is_manual">Unlink</span>
            Brokerage
        </h5>
        <button class="btn lh-1 p-0 shadow-none" (click)="closeUnlinkConfirmationModal()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512">
                <path
                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
        </button>
    </header>
    <div class="content-container p-3 px-lg-4 pb-lg-4">
        <div class="mb-4">
            <p class="mb-3 confirmation-text text-center">
                Are you sure you want to
                <span *ngIf="selectedAccount.is_manual">delete</span><span
                    *ngIf="!selectedAccount.is_manual">unlink</span>
                this
                brokerage?
            </p>
            <div class="d-flex align-items-center justify-content-center gap-2 w-100">
                <div class="d-flex align-items-center justify-content-center px-2 selected-account-logo-wrapper">
                    <ng-container *ngIf="!selectedAccount.is_manual">
                        <img *ngIf="selectedAccount?.brokerage_logo" src="{{ selectedAccount.brokerage_logo }}"
                            class="rounded-2 bg-light radio-image" alt="{{ selectedAccount.name }}" />
                        <span *ngIf="!selectedAccount?.brokerage_logo"
                            class="fw-bold text-uppercase d-inline-block text-nowrap">{{
                            selectedAccount.name.charAt(0) }}</span>
                    </ng-container>
                    <ng-container *ngIf="selectedAccount.is_manual">
                        <span class="fw-semibold d-inline-block text-nowrap">Manual</span>
                    </ng-container>
                </div>
                <div class="account-text">
                    <span class="d-block account-name fw-bold lh-1">{{
                        selectedAccount.name
                        }}</span>
                    <span *ngIf="selectedAccount.number" class="d-block mt-2 lh-1 account-number">{{
                        selectedAccount.number }}</span>
                </div>
            </div>
        </div>
        <div class="modal-btns-container">
            <button class="btn cancel-btn py-2" (click)="closeUnlinkConfirmationModal()" [disabled]="unlinkLoader">
                Cancel
            </button>
            <button class="btn confirm-btn py-2" (click)="unlinkBrokerage(selectedAccount.brokerage_id,selectedAccount.id)"
                [disabled]="unlinkLoader">
                <span *ngIf="!unlinkLoader">Confirm</span>
                <div class="d-flex justify-content-center py-1 px-4" *ngIf="unlinkLoader">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>