import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'textToCut'
})

export class textToCutPipe implements PipeTransform {
    transform(value: string): any {
        if (value) {
            value = value.trim();
            if(value.length > 17){
                value = value.slice(0,17);
                value += '...';
            }
          
            return value;
        } else {
            return ''
        }
    }
}
