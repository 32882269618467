import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-chart',
  templateUrl: './tooltip-chart.component.html',
  styleUrls: ['./tooltip-chart.component.scss']
})
export class TooltipChartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
