<div class="list-container">
   <div class="d-flex align-items-center justify-content-between align-items-lg-start mb-3 mb-lg-0">
      <div>
         <h3 class="section-heading">Super Investors Portfolio</h3>
         <p class="mb-0 section-para">
            Explore the portfolios of renowned investors. Track their Buys and Sells to get inspired ✨
         </p>
      </div>
      <img src="./assets/images/investor-portfolio-img.svg" class="img-fluid chart-img" alt="">
   </div>
   <ul class="loaders-wrapper gap-3 gap-lg-4 p-0 mx-0" *ngIf="investorsCardLoading">
      <li *ngFor="let i of [1,2,3]" class="loader-card rounded-3 skeleton-loader"></li>
   </ul> 
   <ul class="card-list p-0 mx-0" *ngIf="!investorsCardLoading">
      <li class="" *ngFor="let investor of investors">
         <a class="d-flex flex-column align-items-center text-decoration-none gap-2 investor-card" routerLink="/cabinet/super-investor-portfolio/{{investor.cik}}">
            <img src="{{investor.profileImg}}" class="img-fluid" alt="{{investor.manager}}">
            <span class="text-black lh-1">{{investor.manager}}</span>
         </a>
      </li>
   </ul>
   <a class="text-decoration-none shadow-none btn explore-btn py-2 px-4" routerLink="/cabinet/super-investors">
      Explore All Super Investors
   </a>
</div>

