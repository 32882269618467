<section class="bg-white">
   <div class="container content-padding">
      <header class="d-flex align-items-center justify-content-center gap-3 section-header">
         <h2 class="mb-0 section-main-heading">Covered Countries</h2>
      </header>
      
      <div class="d-flex align-items-center justify-content-center flex-wrap gap-2 gap-md-3">
         <ng-container *ngIf="coveredCountriesLoader">
            <div class="rounded-pill pt-5 pb-2 skeleton-loader pill-loader" *ngFor="let i of [1,2,3,4,5,6,8,9,10]"></div>
         </ng-container>

         <ng-container *ngIf="!coveredCountriesLoader">
            <a class="d-flex text-decoration-none align-items-center gap-2 country-pill-card" [routerLink]="['/stock-screener']" [queryParams]="{ country: country.countryCode }" queryParamsHandling="merge"  *ngFor="let country of coveredCountries">
               <img [src]="'/assets/flags/1x1/' + country.countryCode.toLowerCase() + '.svg'" [alt]="country.country" class="d-block rounded-circle img-fluid">
               <div class="flex-shrink-0">
                  <h6 class="mb-0 fw-500 text-nowrap">{{ country.country }}</h6>
                  <p class="mb-0 text-nowrap">{{ country.stockExchanges.join(', ') }}</p>
               </div>
            </a>
         </ng-container>
      </div>
         
   </div>
</section>
