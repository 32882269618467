import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil, timer } from 'rxjs';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ComplianceAccessCheckService } from 'src/app/shared/services/compliance-access-check.service';
import { FirebaseRemoteConfigService } from 'src/app/shared/services/firebase-remote-config.service';
import { LogOutFreeServices } from 'src/app/shared/services/log-out.service ';
import { RemainingServices } from 'src/app/shared/services/remaining.service ';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';
import { User } from 'src/app/models/user/user.model';
import { CountryService } from 'src/app/shared/services/country.service';

export interface LinkItem {
   title: string;
   path: string;
   linkStyle: string;
   icon?: string;
   moreLinks?: LinkItem[];
}

@Component({
   selector: 'app-header',
   templateUrl: './header.component.html',
   styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   userRole: string | null = null;
   isMobile: boolean = false;
   isMobileMenuOpen: boolean = false;
   headerCtaText: string = '';
   remaining: any;
   userRoleLoader: boolean = true
   loader: boolean = false
   user!: User;
   fullUserName: string = '';
   headerLoader: boolean = true;
   isSearchBottomSheetOpen: boolean = false;
   usersDefaultCountry!: string;
   pageNo: number = 1
   shouldShowCountryDropdown!: boolean;
   isLoggedIn: boolean = false;
   userNewRole: Role = null;
   routes: string[] = [
      '/',
      '/trending-halal-stocks/most_active',
      '/trending-halal-stocks/most_popular',
      '/trending-halal-stocks/top_gainers',
      '/trending-halal-stocks/top_losers',
      '/trending-halal-stocks/52_week_high',
      '/trending-halal-stocks/52_week_low',
      '/halal-stock-collections/information_technology',
      '/halal-stock-collections/health_care',
      '/halal-stock-collections/consumer_discretionary',
      '/halal-stock-collections/mega_cap',
      '/halal-stock-collections/large_cap',
      '/halal-stock-collections/mid_cap',
      '/halal-stock-collections/small_cap',
   ]

   primaryNavLinks: LinkItem[] = [
      { title: 'Stocks', path: '/onboarding', linkStyle: '' },
      { title: 'ETFs', path: '/etf-screener', linkStyle: '' },
      { title: 'Purification', path: '/purification', linkStyle: '' },
      { title: 'Super Investors', path: '/super-investors', linkStyle: '' },
      { title: 'News', path: '', linkStyle: '' },
      { title: 'Academy', path: '/academy-articles', linkStyle: '' },
      { title: 'Zakat', path: '', linkStyle: '/zakat-calculator' },
   ]

   otherLinks: LinkItem[] = [
      { title: 'Portfolio', path: '/portfolio-dashboard', linkStyle: ''  },
      { title: 'Watchlist', path: '/watchlist-lists', linkStyle: ''  },
      { title: 'Go Premium', path: '', icon: '/assets/icons/premium-gold.svg', linkStyle: ''  },
   ]

   constructor(
      private observer: BreakpointObserver,
      private router: Router,
      private route: ActivatedRoute,
      private logoutService: LogOutFreeServices,
      private firebaseConfigService: FirebaseRemoteConfigService,
      private auth: AuthService,
      private complianceAccessCheckService: ComplianceAccessCheckService,
      private userApiService: UserApiService,
      private remainingService: RemainingServices,
      private subscriptionService: SubscriptionService,
      private countryService: CountryService,
   ) {
      router.events.subscribe((val) => {
         if (val instanceof NavigationEnd) {
            if (this.routes.includes(val.url)) {
               this.shouldShowCountryDropdown = true;
            } else {
               this.shouldShowCountryDropdown = false;
            }
         }
      });
   }

   ngOnInit(): void {
      SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
         this.userRole = data.role
         this.userRoleLoader = false
         this.userNewRole = data.newRole;
         this.isLoggedIn = !!this.auth.getUserId()
         if (this.isLoggedIn) {
            this.getUser();
         }
      })

      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.countryService.selectedCountry$.pipe(takeUntil(this.destroy$)).subscribe(country => {
         this.usersDefaultCountry = country.countryCode;
      });

      // if (this.auth.isLoggedIn()) {
      //    this.getUser();
      // }

      this.remainingService.remainingStatus.pipe(takeUntil(this.destroy$)).subscribe(() => {
         this.startedRemaining();
      });
      this.remainingService.update();
      this.setHeaderCtaText();
      setTimeout(() => this.headerLoader = false, 500);
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   openMobileMenu() {
      this.isMobileMenuOpen = true
   }

   closeMobileMenu() {
      this.isMobileMenuOpen = false
   }

   navigateToStockScreener() {
      const paramsToNavigate = {
         country: this.usersDefaultCountry,
         page: this.pageNo
      };

      this.router.navigate(['/stock-screener'], {
         queryParams: paramsToNavigate ,
      });
   }

   navigateTo(path: string) {
      if(this.isMobile) {
         this.closeMobileMenu();
      }
      this.router.navigate([path]);
   }

   openLoginOrNavigate(url: string) {
      if(!this.userNewRole) {
         this.auth.openAuthPopup()
      } else {
         this.router.navigate([url])
      }
   }

   logout() {
      if(this.isMobile) {
         this.closeMobileMenu();
      }
      this.logoutService.show();
   }

   async getUser() {
      this.loader = true;
      let userRes: any = await this.userApiService.fetchUserDetailsAndSave();
      this.user = userRes;
      this.fullUserName = `${this.user.firstname}  ${this.user.lastname}`

      this.loader = false;
   }

   async startedRemaining() {
      if (this.auth.checkRole('FREEMIUM')) {
         this.remaining = await this.complianceAccessCheckService.remainingSearches();
      }
   }

   checkRole(role: string): boolean {
      return this.auth.checkRole(role);
   }

   async setHeaderCtaText() {
      let config = await this.firebaseConfigService.getConfigDefault();
      this.headerCtaText = await config['web_header_cta'];
   }

   openPaywallPopup() {
      this.subscriptionService.openPaywallModal()
   }

   openSearchBottomSheet() {
      this.isSearchBottomSheetOpen = true;
   }

   closeSearchBottomSheet() {
      this.isSearchBottomSheetOpen = false;
   }
}

