<section class="sample-container position-relative">
   <header class="heading">
      <div class="d-flex align-items-center gap-3 gap-lg-4 px-3 py-2 ps-lg-4">
         <button type="button" class="btn p-2 rounded-circle shadow-none d-lg-none close-btn" (click)="sampleModal.dismiss()" >
            <svg xmlns="http://www.w3.org/2000/svg" height="1.125rem" fill="#5E5E5E" viewBox="0 0 448 512" >
               <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
         </button>
         <ng-container *ngIf="componentType$ | async as componentType">
            <h4 class="mb-0 sample-heading" *ngIf="componentType !== 'etfReport'">
               Sample: {{ title }} for {{ stockName }}({{ stockTicker }})
            </h4>
            <h4 class="mb-0 sample-heading" *ngIf="componentType === 'etfReport'">
               Sample: {{ title }} for {{ etfStockName }}({{ etfId }})
            </h4>
         </ng-container>
         <button type="button" class="btn p-2 rounded-circle shadow-none ms-auto d-none d-lg-block close-btn" (click)="sampleModal.dismiss()" >
            <svg xmlns="http://www.w3.org/2000/svg" height="2rem" fill="#5E5E5E" viewBox="0 0 384 512" >
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
         </button>
      </div>
      <!-- <article class="p-3 p-lg-4 article">
         <div class="d-flex align-items-center justify-content-between gap-3 mb-3 mb-lg-4" >
            <img src="/assets/images/musaffa-premium-logo.svg" alt="Musaffa Premium Logo" class="premium-logo img-fluid" />
            <img src="/assets/images/crown-icon.svg" alt="Crown Icon" class="crown-img img-fluid" />
         </div>
         <h2 class="mb-0 article-title">
            <span class="feature">{{ title }}</span> <br class="d-md-none" /> for <span class="stock-name">{{ stockName }}</span> ({{ stockTicker }})
         </h2>
      </article> -->
   </header>
   <div class="sample-content">
      <div *ngIf="componentType$ | async as componentType">
         <ng-container *ngIf="componentType === 'forecast'">
            <div class="px-3 px-lg-4">
               <!-- <app-old-forecasts
                  [isSample]="true"
                  [ticker]="'AAPL'"
                  [fundamentals]="fundamentals">
               </app-old-forecasts> -->
               <app-forecasts
                  *ngIf="fundamentals?.currentPrice"
                  [isSample]="true"
                  [ticker]="'AAPL'"
                  [currency]="'USD'"
                  [fundamentals]="fundamentals"
               >
               </app-forecasts>
            </div>
         </ng-container>

         <ng-container *ngIf="componentType === 'checklist'">
            <div class="px-3 px-lg-4">
               <app-investment-checklist
                  [isSample]="true"
                  [ticker]="'AAPL'"
                  [countryCode]="'US'"
                  [currency]="'USD'"
               >
               </app-investment-checklist>
            </div>
         </ng-container>

         <ng-container *ngIf="componentType === 'purification'">
            <div class="container-fluid px-0 px-lg-4 d-none">
               <app-purification-calculator
                  [isSample]="true"
                  [symbol]="'AAPL'"
                  (companyProfileFetched)="(stock.companyProfile)"
                  (calculationFetched)="calculationFetched($event)"
               >
               </app-purification-calculator>
            </div>
            <div class="container-fluid px-0 px-lg-4">
               <app-purification-options
                  [isSample]="true"
                  [companyProfile]="this.stock?.companyProfile"
                  [data]="calculationData"
               >
               </app-purification-options>
            </div>
         </ng-container>

         <ng-container *ngIf="componentType === 'report'">
            <div class="container-xxl px-3 px-lg-4">
               <!-- <ng-container *ngIf="reportLoader">
                  <div class="row">
                     <div class="col-12 col-md-4">
                        <ngx-skeleton-loader
                           count="1" appearance="line" animation="progress"
                           [theme]="{ height: '200px', width: '100%' }" >
                        </ngx-skeleton-loader>
                     </div>
                     <div class="col-12 col-md-8">
                        <ngx-skeleton-loader
                           count="1" appearance="line" animation="progress"
                           [theme]="{ height: '400px', width: '100%' }">
                        </ngx-skeleton-loader>
                     </div>
                  </div>
               </ng-container> -->
               <!-- <ng-container *ngIf="!reportLoader"> -->
                  <app-screener-report
                     [storedResponse]="storedScreenerResponse"
                     (responseLoaded)="screenerResponseLoaded($event)"
                     [isSample]="true"
                     [companyProfile]="stock?.companyProfile"
                     (closeModal)="closeActiveModal()"
                  >
                  </app-screener-report>
               <!-- </ng-container> -->
            </div>
         </ng-container>

         <ng-container *ngIf="componentType === 'etfReport'">
            <div class="container-xxl px-3 px-lg-4">
               <ng-container *ngIf="etfReportLoader">
                  <div class="row">
                     <div class="col-12 col-md-4">
                        <ngx-skeleton-loader
                           count="1" appearance="line" animation="progress"
                           [theme]="{ height: '200px', width: '100%'}">
                        </ngx-skeleton-loader>
                     </div>
                     <div class="col-12 col-md-8">
                        <ngx-skeleton-loader
                           count="1" appearance="line" animation="progress"
                           [theme]="{ height: '400px', width: '100%'}">
                        </ngx-skeleton-loader>
                     </div>
                  </div>
               </ng-container>
               <ng-container *ngIf="!etfReportLoader">
                  <app-etf-screener-report
                     [isSample]="true"
                     [data]="etf.compliance"
                     [etfProfile]="etf.etfProfileInfo">
                  </app-etf-screener-report>
               </ng-container>
            </div>
         </ng-container>
      </div>

      <div class="btn-wrapper">
         <!-- <button type="button" class="btn shadow-none rounded-pill mx-auto card-btn card-btn-filled d-flex align-items-center justify-content-center gap-3" *ngIf="userRole === 'ANONYMOUS'" (click)="goToLoginPage()" >
            <img src="/assets/images/premium-btn-icon.svg" alt="Premium icon" class="premium-icon" />
            <span class="text-nowrap">Unlock All Premium Features</span>
         </button> -->
         <button type="button" class="btn shadow-none rounded-pill mx-auto card-btn card-btn-filled d-flex align-items-center justify-content-center gap-3" *ngIf="userRole === 'FREEMIUM' || userRole === 'ANONYMOUS'" (click)="accessControlService.accessDenied()" >
            <img src="/assets/images/premium-btn-icon.svg" alt="Premium icon" class="premium-icon" />
            <span class="text-nowrap">Unlock All Premium Features</span>
         </button>
      </div>
   </div>
</section>
