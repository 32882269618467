import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, Renderer2, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ComplianceHistoryService } from '../../services/compliance-history.service';
import { ComplianceHistoryApiResponse, ComplianceHistory } from 'src/app/models/compliance-history/compliance-history.model';
import { AccessControlService } from '../../services/access-control.service';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';

@Component({
   selector: 'app-compliance-history',
   templateUrl: './compliance-history.component.html',
   styleUrls: ['./compliance-history.component.scss'],
})
export class ComplianceHistoryComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   compliances: ComplianceHistory[] = [];
   historyLoader: boolean = false
   @Input() ticker!: string;
   @Input() lastUpdated: string | null = null;
   @Input() reportSource: string | null = null;
   @Input() currentComplianceStatus!: string
   @Input() currentComplianceRating!: number

   constructor(
      private renderer: Renderer2, 
      private el: ElementRef,
      private complianceHistoryService: ComplianceHistoryService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService
   ) { }

   ngOnInit(): void {
      this.complianceHistoryService.showComplianceHistoryOffcanvas$
         .pipe(takeUntil(this.destroy$))
         .subscribe(() => this.openComplianceHistory());

      this.permissionStore.select(({permissions}) => permissions.permissions)
         .pipe(takeUntil(this.destroy$))
         .subscribe((permissions) => {
            if(permissions.stock_shariah_compliance_history) {
               this.getComplianceHistory(this.ticker)
            }
         })

   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   openComplianceHistory() {
      const offcanvas = this.el.nativeElement.querySelector('.compliance-history-container');
      this.renderer.addClass(offcanvas, 'show-compliance-history');

      const backdrop = this.el.nativeElement.querySelector('.compliance-history-backdrop');
      this.renderer.addClass(backdrop, 'show-compliance-history-backdrop');
   }

   closeComplianceHistory() {
      const offcanvas = this.el.nativeElement.querySelector('.compliance-history-container');
      this.renderer.removeClass(offcanvas, 'show-compliance-history');

      const backdrop = this.el.nativeElement.querySelector('.compliance-history-backdrop');
      this.renderer.removeClass(backdrop, 'show-compliance-history-backdrop');
   }

   getComplianceHistory(ticker: string) {
      this.historyLoader = true
      this.complianceHistoryService.fetchComplianceHistory<ComplianceHistoryApiResponse>(ticker).pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            if(response.status) {
               this.compliances = response.data;
               this.historyLoader = false
            }
            this.historyLoader = false
         },
         error: (error) => {
            console.error(error);
            this.historyLoader = false
         }
      })
   }

   getComplianceClasses(status: string) {
      switch (status) {
         case 'COMPLIANT':
            return 'halal';
         case 'NON_COMPLIANT':
            return 'not-halal';
         case 'QUESTIONABLE':
            return 'doubtful';
         default:
            return '';
      }
   }

   getComplianceText(status: string) {
      switch (status) {
         case 'COMPLIANT':
            return 'HALAL';
         case 'NON_COMPLIANT':
            return 'NOT HALAL';
         case 'QUESTIONABLE':
            return 'DOUBTFUL';
         default:
            return '';
      }
   }

   getQuarterText(text: string) {
      switch (text) {
         case 'ANNUAL':
            return 'Annual';
         case 'FIRST_QUARTER':
            return '1st Quarter';
         case 'SECOND_QUARTER':
            return '2nd Quarter';
         case 'THIRD_QUARTER':
            return '3rd Quarter';
         case 'FOURTH_QUARTER':
            return '4th Quarter';
         default:
            return '';
      }
   }

   replaceUnderscore(text: string): string {
      return text?.replace(/_/g, ' ');
   }

}

