<section class="bg-white">
   <div class="container content-padding">
      <header class="d-flex flex-wrap px-3 px-md-4 px-xxl-0 align-items-center justify-content-between gap-2 section-header">
         <h2 class="mb-0 section-main-heading">Trending Halal Stocks</h2>
         <a class="btn d-flex align-items-center shadow-none gap-2 text-nowrap p-0 view-all-btn" [routerLink]="['/trending-halal-stocks/most_active']">
            <span>View All</span>
            <i class="bi bi-arrow-right"></i>
         </a>
      </header>

      <div class="table-grid-container">
         <app-stocks-table
            [loading]="stocksLoading.mostActive"
            [tableId]="'most_active'"
            [title]="'Most Active'"
            [data]="mostActive"
            [redirectUrl]="'/trending-halal-stocks/most_active'">
         </app-stocks-table>

         <app-stocks-table
            [loading]="stocksLoading.mostPopular"
            [tableId]="'most_popular'"
            [title]="'Most Popular'"
            [data]="mostPopular"
            [redirectUrl]="'/trending-halal-stocks/most_popular'">
         </app-stocks-table>

         <app-stocks-table
            [loading]="stocksLoading.topGainers"
            [tableId]="'top_gainers'"
            [title]="'Top Gainers'"
            [data]="topGainers"
            [redirectUrl]="'/trending-halal-stocks/top_gainers'">
         </app-stocks-table>

         <app-stocks-table
            [loading]="stocksLoading.topLosers"
            [tableId]="'top_losers'"
            [title]="'Top Losers'"
            [data]="topLosers"
            [redirectUrl]="'/trending-halal-stocks/top_losers'">
         </app-stocks-table>

         <app-stocks-table
            [loading]="stocksLoading.weekHigh"
            [tableId]="'52_week_high'"
            [title]="'52 Week High'"
            [data]="weekHigh"
            [redirectUrl]="'/trending-halal-stocks/52_week_high'">
         </app-stocks-table>

         <app-stocks-table
            [loading]="stocksLoading.weekLow"
            [tableId]="'52_week_low'"
            [title]="'52 Week Low'"
            [data]="weekLow"
            [redirectUrl]="'/trending-halal-stocks/52_week_low'">
         </app-stocks-table>
      </div>
   </div>
</section>
