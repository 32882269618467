import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { screaningMillionPipe } from '../../pipes/screaning-million.pipe';

@Component({
   selector: 'app-income-breakdown',
   templateUrl: './income-breakdown.component.html',
   styleUrls: ['./income-breakdown.component.scss'],
})
export class IncomeBreakdownComponent implements OnInit {
   @Input() type = '';
   @Input() showPercent = false;
   @Input() data?: any = null;
   @Input() currency?: any = '';

   @Input() title: string = '';
   @Input() items: any = [];
   @Input() overallAmount: any = '';
   @Input() overallPercent: any = '';

   @Input() id: any;
   @Input() subTicker!: string;
   @Input() rate!: number;
   @Input() usdRate!: number;

   constructor(
      private screaningMillionPipe: screaningMillionPipe,
      private decimalPipe: DecimalPipe
   ) {}

   ngOnInit(): void {
      // this.getSubTickers(this.id)
   }

   getOverallValue() {
      if (!this.showPercent) {
         if (this.overallAmount != null)
            return this.screaningMillionPipe.transform(
               this.getConvertedAmount(this.overallAmount)
            );
         else return '';
      } else if (this.overallPercent != null) {
         return this.decimalPipe.transform(this.overallPercent, '1.2-2') + '%';
      } else {
         return ''
      };
   }

   getItemValue(obj: any) {
      if (!this.showPercent) {
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(obj?.amountInOnes)
         );
      } else return this.decimalPipe.transform(obj?.percentage, '1.2-2') + '%';
   }

   getComments(obj: any) {
      if (!Array.isArray(obj?.comment)) return [];

      if (obj?.comment) return obj?.comment;
      else return [];
   }

   getConvertedAmount(amt: any) {
      return (amt / this.usdRate) * this.rate;
   }
}
