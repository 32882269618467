<div class="bg-white d-block min-w-0 p-3 p-lg-4 rounded-3">
   <header class="d-flex align-items-center justify-content-between mb-2">
      <h6 class="text-uppercase mb-0 table-title">Market Holidays</h6>
   </header>

   <!-- exchange tabs start -->
   <div class="d-flex align-items-center gap-2 mb-3" *ngIf="exchangeLoader">
      <ngx-skeleton-loader
         *ngFor="let i of [1,2,3]"
         count="1" appearance="line" animation="progress"
         [theme]="{ height: '32px', width: '86px', 'border-radius': '4px', 'margin-bottom': '0px'}">
      </ngx-skeleton-loader>
   </div>

   <div class="tab-btns d-flex gap-4 mb-3" *ngIf="!exchangeLoader">
      <ng-container *ngFor="let exchange of exchangeTabs">
         <button class="btn tab-btn shadow-none rounded-3 text-uppercase" [disabled]="marketHolidaysLoader" [ngClass]="activeExchange === exchange ? 'active-tab' : ''" (click)="getMarketHolidaysForSelectedExchange(exchange)">
            {{ exchange }}
         </button>
      </ng-container>
   </div>
   <!-- exchange tabs end -->

   <!-- holidays table start -->
   <div class="px-2" *ngIf="marketHolidaysLoader">
      <ngx-skeleton-loader
         count="5" appearance="line" animation="progress"
         [theme]="{ height: '40px', width: '100%', 'border-radius': '4px', 'margin-bottom': '0px'}">
      </ngx-skeleton-loader>
   </div>

   <div class="table-responsive" *ngIf="!marketHolidaysLoader">
      <table class="table mb-0">
         <thead>
            <tr>
               <th class="text-uppercase" scope="col">Holiday</th>
               <th class="text-uppercase text-center" scope="col">Date</th>
               <th class="text-uppercase text-center" scope="col">Trading Hours</th>
            </tr>
         </thead>
         <tbody>
            <ng-container *ngIf="marketHolidays && marketHolidays.length > 0; else noMarketHolidays">
               <tr *ngFor="let holiday of marketHolidays; trackBy: trackByMarketHolidaysId">
                  <td class="align-middle p-0">
                     <a class="d-grid align-items-center text-decoration-none stock-exchange">
                        <p class="mb-0 fw-500 line-clamp-1"> {{ holiday.event_name }} </p>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-center text-decoration-none stock-time">
                        <p class="mb-0 line-clamp-1">
                           {{ holiday.date | date : "mediumDate" }}
                        </p>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center text-decoration-none stock-status ">
                        <span class="d-block text-center status-chip w-100 rounded-3 py-1 px-2 open" *ngIf="holiday.partial_trading_hours_st && holiday.partial_trading_hours_et; else tradingClosed">
                           {{ holiday.partial_trading_hours_st }} - {{ holiday.partial_trading_hours_et }}
                        </span>
                        <ng-template #tradingClosed>
                           <span class="d-block text-center status-chip w-100 rounded-3 py-1 px-2 closed">
                              Closed
                           </span>
                        </ng-template>
                     </a>
                  </td>
               </tr>
            </ng-container>
            
            <ng-template #noMarketHolidays>
               <tr>
                  <td colspan="3" class="align-middle p-0">
                     <p class="mb-0 text-center p-3"> No market Holidays for Selected Exchange</p>
                  </td>
               </tr>
            </ng-template>

         </tbody>
      </table>
   </div>
   <!-- holidays table end -->
      
</div>



