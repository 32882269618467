import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

interface Client {
   id: string,
   imageUrl: string,
   name: string,
   city: string,
   country: string,
   testimonial: string,
   rating: number,
   date: string
}

@Component({
   selector: 'app-testimonials',
   templateUrl: './testimonials.component.html',
   styleUrls: ['./testimonials.component.scss']
})

export class TestimonialsComponent implements OnInit {
   private destroy$ = new Subject<void>();
   userRole: string | null = null;

   clients: Client[] = []
   loading: boolean = false;
   @Input() isPricingPage: boolean = false
   @Input() isForBusinessPage: boolean = false

   constructor() { }

   ngOnInit(): void {
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;
         });


      this.clients = [
         {
            id: '1',
            imageUrl: 'https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            name: 'Wael Shoukry',
            city: '',
            country: 'India',
            testimonial: `I am truly grateful for the effort you put into this app. I've been trying hard to find a way to ensure my portfolio is halal but found it so hard that I was about to exit the stock market out of confusion. Until I found your website, app, and academy. I am now filtering my portfolio and have found a few non halal stocks which I'm happy to discard. One of the best things about your service is you explain WHY it is/isn't. Jazakum Allah Khairan for every halal cent I make! 🙏`,
            rating: 5,
            date: '26 March 2023'
         },
         {
            id: '2',
            imageUrl: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            name: 'Ibrahim Yildiz',
            city: '',
            country: 'Netherlands',
            testimonial: 'Professional and dedicated team! I have experienced some technical issues with the app where some of my stocks were not visible in my portfolio. Something to do with a specific exchange (TDG) they were bought from. The team took my note serious and within a few weeks they solved the problem. Fantastic. Other than that, very useful and convenient app.',
            rating: 5,
            date: '4 July 2024'
         },
         {
            id: '3',
            imageUrl: 'https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            name: 'Mohammed Afroz Afroz',
            city: 'Hyderabad',
            country: 'India',
            testimonial: 'This Side Mohammed Afroz From Hyderabad, India “Musaffa" has quickly become one of my favorite apps, thanks to its sleek design and powerful features. The app’s versatility allows me to stay organized, informed, and connected, all from the convenience of my mobile device. I appreciate the attention to detail and dedication to user satisfaction that the developers have put into Musaffa. It’s truly a game-changer and a must-have app for anyone looking to improve their overall digital experience.',
            rating: 5,
            date: '30 May 2024'
         }
      ]
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }


   trackByClientId(index: number, client: Client) {
      return client.id;
   }

   @HostBinding('class')

   get pricingPageStyles(): string {
      if (this.isPricingPage) {
         return 'pricing-styling';
      } else {
         return '';
      }
   }

}
