import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SuperInvestor } from 'src/app/models/superInvestor.model';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

@Component({
   selector: 'app-super-investors-list',
   templateUrl: './super-investors-list.component.html',
   styleUrls: ['./super-investors-list.component.scss']
})

export class SuperInvestorsListComponent implements OnInit {
   private destroy$ = new Subject<void>();
   superInvestors: SuperInvestor[] = [];
   loading: boolean = false;
   isMobile: boolean = false;
   perPage!: number

   constructor(
      private typesenseService: TypesenseService,
      private observer: BreakpointObserver,
   ) { }

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });
      this.fetchSuperInvestors(10)
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackBySuperInvestorsId(index: number, list: SuperInvestor): number | string {
      return list.id;
   }

   async fetchSuperInvestors(perPage: number): Promise<void> {
      try {
         this.loading = true
         await this.typesenseService.getSuperInvestors("total_amount:desc", perPage, 1).then((data) => {
            this.superInvestors = data.hits.map((hit: any) => hit.document)
         })
      } catch (error) {
         console.log(error);
         this.loading = false
      } finally {
         this.loading = false
      }
   } 

}
