<div class="forecast-card-container p-3 p-lg-4">
   <h4 class="forecast-card-heading">{{ heading }}</h4>
   <div class="d-block">
      <ng-content></ng-content>
   </div>
   <!-- <h5 class="forecast-card-subheading">{{subheading}}</h5> -->
   <!-- <p class="forecast-card-subheading-para my-2 my-lg-3"><i class="bi bi-info-circle-fill me-2"></i>{{subheadingPara}}</p> -->
   <div class="table-responsive mt-4">
      <table class="table mb-0 forecast-table">
         <thead>
            <tr>
               <th scope="col" class="text-nowrap align-middle">
                  Currency {{ "(" + currency + ")" }}
               </th>
               <th
                  scope="col"
                  class="text-nowrap text-center align-middle"
                  *ngFor="let data of tableData"
               >
                  {{ data.label }}
               </th>
               <!-- <th scope="col" class="text-nowrap text-center align-middle">Q4, 2022</th>
              <th scope="col" class="text-nowrap text-center align-middle">Q1, 2023</th>
              <th scope="col" class="text-nowrap text-center align-middle">Q2, 2023</th>
              <th scope="col" class="text-nowrap text-center align-middle">Q3, 2023</th>
              <th scope="col" class="text-nowrap text-center align-middle">Q4, 2023</th>
              <th scope="col" class="text-nowrap text-center align-middle">Q1, 2024</th>
              <th scope="col" class="text-nowrap text-center align-middle">Q2, 2024</th> -->
            </tr>
         </thead>
         <tbody>
            <tr>
               <td class="text-start align-middle">Actual</td>
               <td
                  class="text-center align-middle"
                  *ngFor="let data of tableData"
               >
                  {{ data.actual ? (data.actual | millionPipe) : "-" }}
               </td>
            </tr>
            <tr>
               <td class="text-start align-middle">Estimated</td>
               <td
                  class="text-center align-middle"
                  *ngFor="let data of tableData"
               >
                  {{ data.estimate ? (data.estimate | millionPipe) : "-" }}
               </td>
            </tr>
            <tr>
               <td class="text-start align-middle">Surprise</td>
               <td
                  class="text-center align-middle"
                  *ngFor="let data of tableData"
               >
                  {{
                     data.surprisePercent
                        ? (data.surprisePercent | number : "1.2-2") + "%"
                        : "-"
                  }}
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</div>

<!-- <div class="forecast-cards-container">
   <div class="forest-chart-wrapper">
      <h3 class="forecast-card-heading d-lg-none">{{title}}</h3>
      <div>
         <ng-content></ng-content>
         <app-forecast-price-chart [priceChartData]="priceChartData"></app-forecast-price-chart>
      </div>
   </div>
   <article class="forest-data-wrapper">
      <h3 class="forecast-card-heading d-none d-lg-block">{{title}}</h3>
      <div class="forecast-card-grid">
         <h5 class="mb-0 subheading left-subheading">{{currentSectionHeading}}</h5>
         <h5 class="mb-0 subheading right-subheading">{{forecastSectionHeading}}</h5>
         <div class="price-div current-price-div d-flex flex-column justify-content-between">
            <h6 class="mb-0 d-flex gap-2 align-items-start">
               <span class="clr-box curr-price-box"></span>
               <span class="box-text">Current</span>
            </h6>
            <p class="mb-0 price-text">{{currentValue}}</p>
         </div>

         <div class="price-div d-flex flex-column justify-content-between">
            <h6 class="mb-0 d-flex gap-2 align-items-center">
               <span class="clr-box high-box"></span>
               <span class="box-text">High</span>
            </h6>
            <p class="mb-0 price-text">{{forecastHighValue}}</p>
         </div>
         <div class="price-div d-flex flex-column justify-content-between">
            <h6 class="mb-0 d-flex gap-2 align-items-center">
               <span class="clr-box median-box"></span>
               <span class="box-text">Median</span>
            </h6>
            <p class="mb-0 price-text">{{forecastMedianValue}}</p>
         </div>
         <div class="price-div d-flex flex-column justify-content-between">
            <h6 class="mb-0 d-flex gap-2 align-items-center">
               <span class="clr-box low-box"></span>
               <span class="box-text">Low</span>
            </h6>
            <p class="mb-0 price-text">{{forecastLowValue}}</p>
         </div>
      </div>
   </article>
</div> -->
