import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
   selector: 'app-list-search',
   templateUrl: './list-search.component.html',
   styleUrls: ['./list-search.component.scss']
})

export class ListSearchComponent implements OnInit {
   @Output() searchEvent = new EventEmitter<string>();
   listSearchForm!: FormGroup;
   private unsubscribe$ = new Subject<void>();
   listType: string = ''

   constructor(
      private fb: FormBuilder,
      private route: ActivatedRoute
   ) { }

   ngOnInit(): void {
      this.listSearchForm = this.fb.group({
         listName: [''],
      });

      this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
         this.listType = params['lists'];
      });

      this.listSearchForm.get('listName')?.valueChanges
         .pipe(
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe$)
         )
         .subscribe(value => {
            this.onSearch(value);
         });
   }

   ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
   }

   onSearch(name: string) {
      this.searchEvent.emit(name);
   }

}
