import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { PortfolioDetails } from 'src/app/models/portfolio/portfolio-details.model';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';

@Component({
   selector: 'app-portfolio-settings',
   templateUrl: './portfolio-settings.component.html',
   styleUrls: ['./portfolio-settings.component.scss'],
})
export class PortfolioSettingsComponent implements OnInit {
   @Input() selectedAccount: PortfolioAccount | null = null;
   @Input() selectedPortfolioDetails: PortfolioDetails | null = null;
   @Input() isTransactionAvailable: boolean = false;
   @Input() refreshData!: Function;
   @Input() closeSettingPopup!: Function;
   @Input() openUnlinkConfirmation!: Function;
   syncPortfolioLoader: boolean = false;
   deleteLoader: boolean = false;

   constructor(
      private router: Router,
      public portfolioService: PortfolioService,

      private route: ActivatedRoute,
      private analyticsUtils: AnalyticsUtils,
      private toaster: ToastrService
   ) { }

   ngOnInit(): void { }

   async syncPortfolio() {
      if (this.syncPortfolioLoader) return;
      this.analyticsUtils.portfolio_sync_tapped();
      this.syncPortfolioLoader = true;
      var resp: any = '';
      try {
         if (!this.selectedPortfolioDetails?.is_manual) {
            resp = await lastValueFrom(
               this.portfolioService.syncPortfolio(
                  this.selectedPortfolioDetails?.id
               )
            );
         } else {
            resp = await lastValueFrom(
               this.portfolioService.syncManualPortfolio(
                  this.selectedPortfolioDetails?.id
               )
            );
         }

         if (resp.status && resp.status_code === 'SUCCESS') {
            this.toaster.success(resp.message);
            this.portfolioService.updateDate(resp.data.last_sync_at);
            this.syncPortfolioLoader = false;
            this.refreshData();
            this.closeSettingPopup();
         } else {
         }
      } catch (error) {
         this.syncPortfolioLoader = false;
         this.toaster.error('Sync failed');
      }
   }

   openConfirmation() {
      this.openUnlinkConfirmation();
      this.closeSettingPopup();
   }

   naviagetToAddHolding() {
      this.closeSettingPopup();
      this.router.navigate([
         `/portfolio-dashboard/manual/${this.selectedAccount?.id}`,
      ]);
   }
}
