import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class ConditionalRenderingService {
   private componentTypeSubject = new BehaviorSubject<string | null>(null);
   componentType$ = this.componentTypeSubject.asObservable();

   setComponentType(componentType: string) {
      this.componentTypeSubject.next(componentType);
   }
}