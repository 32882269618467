import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})

export class DurationPipe implements PipeTransform {

   transform(date1: string, date2: string | null): string | null {
      if (date2 === null) return null;

      const requestedAt = new Date(date1);
      const completedAt = new Date(date2);

      const diff = completedAt.getTime() - requestedAt.getTime();

      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      let durationString = 'Requested completed within';

      if (days > 0) {
         durationString += ` ${days} day${days > 1 ? 's' : ''} and ${hours % 24} hour${(hours % 24) > 1 ? 's' : ''}`;
         if (days < 3) {
            durationString += ' 🎉';
         }
      } else if (hours > 0) {
         durationString += ` ${hours} hour${hours > 1 ? 's' : ''} and ${minutes % 60} minute${(minutes % 60) > 1 ? 's' : ''}`;
      } else if (minutes > 0) {
         durationString += ` ${minutes} minute${minutes > 1 ? 's' : ''}`;
      } else {
         durationString += ` less than a minute`;
      }

      return durationString;
   }
}
