<div class="stripe-free" *ngIf="showList">
    <div class="container">
        <div class="model-liner">
           <div class="cancel-img text-end">
               <img src="../../../../assets/images/group-343.svg" alt="" (click)="hide()">
           </div>
           <div class="stripe-main">
               <h2 *ngIf="status == 402">You have reached monthly Starter plan limit</h2>
               <h2 *ngIf="status == 410 ">You have reached Starter plan limit</h2>
               <h2 *ngIf="status == 406 ">You have reached Starter plan limit</h2>
               <p *ngIf="status == 402">You have reached your Halal Stock Search limit. If you wish to search additional stocks, please upgrade to our Premium plan.</p>
               <p *ngIf="status == 410">You cannot add more than 2 Watchlist with your Starter plan. If you wish to add more Watchlists, please upgrade to our premium plan.</p>
               <p *ngIf="status == 406">You cannot add more than 10 stocks in a Watchlist with your Starter plan. If you wish to add more stocks, please upgrade to our premium plan.</p>
               <div class="stripe-buttons">
                   <button type="button" class="btn cancel" (click)="hide()">Cancel</button>
                   <button type="button" class="btn"  (click)="goToStripe()">Upgrade Now!</button>
               </div>
           </div>

        </div>
    </div>
</div>
