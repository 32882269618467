import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil, Observable, debounceTime, distinctUntilChanged, tap, switchMap, of, catchError, map } from 'rxjs';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';
@Component({
  selector: 'app-header-searchbar',
  templateUrl: './header-searchbar.component.html',
  styleUrls: ['./header-searchbar.component.scss']
})
export class HeaderSearchbarComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  searchStocksAndEtfs!: FormGroup;
  isMobile: boolean = false;
  symbol: string = '';
  searching = false;
  searchFailed = false;
  private stockCountries: string[] = [];
  private etfCountries: string[] = [];
  private configInitDone = false;
  userCountry: any = '';
  @ViewChild('searchInput') searchInput!: ElementRef;
  @Input() isSearchBottomSheetVisible!: boolean
  @Output() closeBottomSheetEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(
    private fb: FormBuilder,
    private typesenseService: TypesenseService,
    private marketUtilsService: MarketUtilsService,
    private userApiService: UserApiService,
    private router: Router,
    private renderer: Renderer2,
    private observer: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.searchStocksAndEtfs = this.fb.group({
      stockName: [''],
    });

    this.init();
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search: any = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(term => {
        this.searching = true;
        this.searchFailed = false;
        if (!term) {
          this.searching = false;
          this.searchFailed = false;
        }
      }),
      switchMap(term => {
        if (!term) {
          return of([]);
        }
        return this.performSearch(term).pipe(
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        );
      }),
      tap(() => { this.searching = false; })
    );

  private performSearch(term: string): Observable<any> {
    if (this.stockCountries && !this.stockCountries.includes(this.userCountry)) {
      this.userCountry = '';
    }

    return this.typesenseService.searchStocksAndEtfs(
      term,
      this.stockCountries,
      this.etfCountries,
      this.userCountry
    ).pipe(
      map((results: any) => {
        if (results.length === 0) {
          this.searchFailed = true;
        } else {
          this.searchFailed = false;
          if (this.userCountry) {
            results = this.sortData(results);
          }
        }
        return results;
      })
    );
  }

  async getUserDetails() {
    const Data = await this.userApiService.fetchUserDetails();
    if (Data) {
      this.userCountry = Data.geo_ip_country;
    }
  }

  async init() {
    try {
      const countriesData: any = await this.marketUtilsService.fetchCountries();
      this.stockCountries = countriesData.filter((obj: any) => obj.stockEnabled).map((obj: any) => obj.countryCode);
      this.etfCountries = countriesData.filter((obj: any) => obj.etfEnabled).map((obj: any) => obj.countryCode);
      this.configInitDone = true;
    } catch (e) {
      console.log(e);
    }
  }

  sortData(data: any) {
    const topCountry = data.filter((obj: any) => obj.countryName === this.userCountry);
    const notTheCountry = data.filter((obj: any) => obj.countryName !== this.userCountry);
    const myArray = topCountry.concat(notTheCountry);
    return myArray.filter((value: any, index: any, self: any) => index === self.findIndex((t: any) => t.stockName === value.stockName));
  }

  inputFormatter = (result: any) => result.stockName;
  resultFormatter = (result: any) => result.stockName;

  onSelect($event: any) {
    const item = $event.item;

    if (this.isSearchBottomSheetVisible) {
      this.closeBottomSheetEmitter.emit(false)
    }

    setTimeout(() => {
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.blur()
    }, 0);

    if (item.type === 'ETF') {
      this.router.navigate(['/etf/', item.stockName]);
    } else {
      this.router.navigate(['/stock/', item.stockName]);
    }
  }

}
