import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-business-assets',
  templateUrl: './business-assets.component.html',
  styleUrls: ['./business-assets.component.scss']
})
export class BusinessAssetsComponent implements OnInit {
  form!: FormGroup;
  selectedCurrency!: string | null

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.selectedCurrency = localStorage.getItem('selectedCurrency');
    if(this.selectedCurrency) {
      this.selectedCurrency = this.selectedCurrency?.replace(/^"|"$/g, '')
    }
    this.form = this.rootFormGroup.control as FormGroup;
  }

}
