import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PlaceOrder } from '../../../models/placeOrder.model';
import { ToastrService } from 'ngx-toastr';
import { TradeService } from '../../services/trade.service';
import { TypesenseService } from '../../services/typesense.service';
import { BreakpointObserver } from '@angular/cdk/layout';

import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
   Account,
   AccountHolding,
   MarketData,
   MarketSatus,
   SymbolData,
   TradeImpact,
   TradeImpactData,
   TradeImpactForm,
   TradingAccounts,
} from 'src/app/models/trading.model';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ConnectIframeModalComponent } from '../connect-iframe-modal/connect-iframe-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from '../../services/portfolio.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
   selector: 'app-place-order',
   templateUrl: './place-order.component.html',
   styleUrls: ['./place-order.component.scss'],
})
export class PlaceOrderComponent implements OnInit, OnDestroy {
   // Incoming Data
   @Input() action: any;
   @Input() exchangeCode: any;
   @Input() symbol: any;
   @Input() userRole: any;
   @Input() status?: string;

   // data variables
   symbolData: any;
   tradeImpactForm!: FormGroup;
   estimated: number = 0;
   stockPrice: any;
   accountBalance: any;
   accountCurrency: any;
   placeOrderData: any;
   tradeImpactData: TradeImpactData | undefined;
   shareUnits: number | null = null; // only for 'SELL' action
   marketCloseReason: string = '';

   public accounts: any = [];
   public accountsData: any = [];
   public selectedAccount: Account | null = null;

   // loaders
   isLoading: boolean = true;
   isStockInfoLoading: boolean = true;
   isMarketStatusLoading: boolean = true;
   isDisabled: boolean = true;
   isReviewLoading: boolean = false;
   isAccountFetching: boolean = true;
   isPlaceOrderLoading: boolean = false;

   // flags
   isReview: boolean = false;
   isPlaceOrder: boolean = false;
   isTradingSupported: boolean | undefined = false;
   isMarketOpen: boolean = false;
   isTickerExistsForSell: boolean = false;
   isMobile: boolean = false;
   isNotionalValueSupports = true;
   isConnectLoading: boolean = false;

   // extra
   connectIframeModalRef: any;
   redirectUrl: string | null = null;
   complianceText: string = '';

   private destroy$ = new Subject<void>();

   constructor(
      private toaster: ToastrService,
      private tradeApiService: TradeService,
      private typesenseApiService: TypesenseService,
      private router: Router,
      private formBuilder: FormBuilder,
      private observer: BreakpointObserver,
      private modalService: NgbModal,
      private portfolioService: PortfolioService
   ) {}

   async ngOnInit() {
      this.observer
         .observe('(max-width: 991px)')
         .pipe(takeUntil(this.destroy$))
         .subscribe((result) => {
            this.isMobile = result.matches;
         });
      await this.isUserCanTrade();
      this.complianceText = this.getComplianceText(this.status!);
      if (this.accounts.length) await this.getStockInfo();

      if (this.accounts.length && this.selectedAccount?.is_trading_supported) {
         await this.checkMarketStatus();
      }

      this.tradeImpactForm = this.formBuilder.group({
         action: [this.action, Validators.required],
         type: ['Market', Validators.required], // 1.Market 2.Limit
         tif: ['Day', Validators.required], // 1.GTC - Gool till cancel 2.DAY - Day  3.FOK-Fill or kill
         price: [this.symbolData?.price], // if type is limit
         buyIn: ['units', Validators.required], //1.units 2.amount
         units: [null], //if buy in is units
         notional_value: [null], //if buy in is amount
      });

      this.tradeImpactForm
         .get('units')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            this.setEstimateValue('units', value);
         });

      this.tradeImpactForm
         .get('notional_value')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            this.setEstimateValue('notional_value', value);
         });

      this.tradeImpactForm
         .get('buyIn')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            if (value === 'units') {
               let innerValue = this.tradeImpactForm.get('units')?.value;
               this.setEstimateValue('units', innerValue);
               this.tradeImpactForm.controls['notional_value'].setValue(null);
            } else {
               let innerValue =
                  this.tradeImpactForm.get('notional_value')?.value;
               this.setEstimateValue('notional_value', innerValue);
               this.tradeImpactForm.controls['units'].setValue(null);
            }
         });

      this.tradeImpactForm
         .get('tif')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            this.setNotionalValueSupport(value);
         });

      this.tradeImpactForm
         .get('price')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            let innerValue = this.tradeImpactForm.get('units')?.value;
            this.setEstimateValue('units', innerValue);
         });
   }

   // setNotionalValueSupport(value: string) {
   //    if (
   //       this.tradeImpactForm.get('type')?.value === 'Market' &&
   //       value === 'Day'
   //    ) {
   //       this.isNotionalValueSupports = true;
   //    } else {
   //       this.tradeImpactForm.controls['buyIn'].setValue('units');
   //       this.tradeImpactForm.controls['notional_value'].setValue(null);
   //       this.isNotionalValueSupports = false;
   //    }
   // }

   setNotionalValueSupport(value: string) {
      if (
         this.tradeImpactForm.get('type')?.value === 'Market' &&
         value === 'Day'
      ) {
         this.isNotionalValueSupports = true;
      } else {
         if (this.tradeImpactForm.get('buyIn')?.value !== 'units') {
            this.tradeImpactForm.controls['buyIn'].setValue('units');
            this.tradeImpactForm.controls['notional_value'].setValue(null);
         }
         this.isNotionalValueSupports = false;
      }
   }

   // <---- Api calls ---->
   public assignPreselectedAccount() {
      this.accounts.forEach((item: Account) => {
         if (item.is_preselected && item.is_trading_supported) {
            this.selectedAccount = item;
         }
      });

      if (!this.selectedAccount) {
         this.selectedAccount = this.accounts[0];
      }

      this.checkisTickerExistsForSell();
      this.isTradingSupported = this.selectedAccount?.is_trading_supported;

      // this.isAccountLoader = false;
   }

   public checkisTickerExistsForSell() {
      if (this.action !== 'SELL') return;

      this.shareUnits = 0;

      this.selectedAccount?.holdings.forEach((item: AccountHolding) => {
         if (item.symbol === this.symbol) {
            this.isTickerExistsForSell = true;
            this.shareUnits = item.units;
         }
      });
   }

   async isUserCanTrade() {
      try {
         const res =
            await this.tradeApiService.getTradingAccounts<TradingAccounts>(
               this.exchangeCode,
               this.symbol
            );
         if (res.status) {
            this.accounts = res.data;
            if (!this.accounts.length) {
               this.openSupportedBrokeragesModal();
               return;
            }
            this.assignPreselectedAccount();
         }
      } catch (err) {
         console.log('something went wrong ', err);
         // this.toaster.error('Something went wrong');
      } finally {
         this.isLoading = false;
      }
   }

   async getStockInfo() {
      this.isStockInfoLoading = true;
      try {
         const res = await this.tradeApiService.getStockInfo<SymbolData>(
            this.symbol,
            this.selectedAccount?.portfolio_account_id,
            this.action
         );
         if (res.status) {
            this.symbolData = res.data;
         }
      } catch (err) {
         console.log('something went wrong ', err);
         // this.toaster.error('Something went wrong');
      } finally {
         this.isStockInfoLoading = false;
      }
   }

   async checkMarketStatus() {
      this.isMarketStatusLoading = true;
      try {
         const res = await this.tradeApiService.checkMarketStatus<MarketSatus>(
            this.exchangeCode
         );
         if (res.status) {
            this.isMarketOpen = res.data.is_market_open;
            // this.isMarketOpen = true;
            this.marketCloseReason = !res.data.is_market_open
               ? (this.marketCloseReason = res.message)
               : '';
         }
      } catch (err) {
         console.log(err);
         // this.toaster.error('Something went wrong');
      } finally {
         this.isMarketStatusLoading = false;
      }
   }

   async selectAccount(account: Account) {
      if (
         this.selectedAccount?.portfolio_account_id ===
            account.portfolio_account_id ||
         this.isStockInfoLoading
      )
         return;

      this.selectedAccount = account;
      // if (this.accounts.length && this.selectedAccount?.is_trading_supported) {
      this.checkisTickerExistsForSell();
      // }

      await this.getStockInfo();

      if (this.accounts.length && this.selectedAccount?.is_trading_supported) {
         this.isMarketOpen = false;
         await this.checkMarketStatus();
      }
   }

   public async submitForm() {
      if (this.checkConditions()) {
         return;
      }

      this.isReviewLoading = true;
      let formData = {
         action: this.tradeImpactForm.get('action')?.value,
         orderType: this.tradeImpactForm.get('type')?.value,
         tif: this.tradeImpactForm.get('tif')?.value,
         buyIn: this.tradeImpactForm.get('buyIn')?.value,
         price: this.tradeImpactForm.get('price')?.value,
         units: this.tradeImpactForm.get('units')?.value ?? this.estimated,
         notional_value:
            this.tradeImpactForm.get('notional_value')?.value ?? this.estimated,
         symbolId: this.symbolData.universal_symbol_id,
         account_id: this.selectedAccount?.portfolio_account_id,
      };

      try {
         const res = await this.tradeApiService.getTradeImpact<TradeImpact>(
            formData
         );
         if (res.status) {
            this.tradeImpactData = res.data;
            this.isReview = true;
         } else {
            if (res.snaptrade_status_code === 403) {
               await this.reconnectBrokenBrokerage(
                  this.selectedAccount?.user_portfolio_brokerage_id
               );
               this.toaster.error(res.message);
            }
         }
      } catch (err) {
         console.log(err);
         this.toaster.error('Something went wrong');
      } finally {
         this.isReviewLoading = false;
      }
   }

   openConnectIframeModal() {
      this.connectIframeModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
      this.connectIframeModalRef.componentInstance.module = 'trade';
   }

   // async initiateBrokerage(brokerage_id: any) {
   //    this.isConnectLoading = true;
   //    try {
   //       let resp: any = await this.portfolioService.getConnectUrl(
   //          brokerage_id,
   //          true
   //       );
   //       if (resp.status) {
   //          this.isConnectLoading = false;
   //          this.redirectUrl = resp.data.redirect_url;
   //          this.tradeApiService.closeBuySellPopup();
   //          this.openConnectIframeModal();
   //       }
   //    } catch (err: any) {
   //       console.log(err);
   //       this.isConnectLoading = false;
   //    } finally {
   //       this.isConnectLoading = false;
   //    }
   // }

   async reconnectBrokenBrokerage(brokerage_id: any) {
      this.isConnectLoading = true;
      try {
         let resp: any = await lastValueFrom(
            this.portfolioService.getReconnectUrl(brokerage_id, true)
         );
         if (resp.status) {
            this.isConnectLoading = false;
            this.redirectUrl = resp.data.redirect_url;
            this.tradeApiService.closeBuySellPopup();
            this.openConnectIframeModal();
         }
      } catch (err: any) {
         console.log(err);
         this.isConnectLoading = false;
      } finally {
         this.isConnectLoading = false;
      }

      // this.portfolioService
      //    .getReconnectUrl(brokerage_id)
      //    .subscribe((resp: any) => {
      //       if (resp.status) {
      //          this.isConnectLoading = false;
      //          this.portfolioService.openConnectBrokerageIframeModal(
      //             resp.data.redirect_url
      //          );
      //       }
      //       this.isConnectLoading = false;
      //    }),
      //    (error: HttpErrorResponse) => {
      //       console.log(error);
      //       this.isConnectLoading = false;
      //    };
   }

   // change type of response data
   public async placeOrder() {
      this.isPlaceOrderLoading = true;
      try {
         const res: any = await this.tradeApiService.placeOrder(
            this.tradeImpactData?.provider_trade_id
         );
         if (res?.status) {
            this.isReview = false;
            this.placeOrderData = res?.data;
            this.isPlaceOrder = true;
         } else {
            this.toaster.error(res.message);
         }
      } catch (err) {
         console.log(err);
         // this.toaster.error('Something went wrong');
      } finally {
         this.isPlaceOrderLoading = false;
      }

      // setTimeout(() => {
      //    this.isReview = false;
      //    this.placeOrderData = {
      //       is_trade_placed: true,
      //       order_status: 'EXECUTED',
      //    };
      //    this.isPlaceOrderLoading = true;
      //    this.isPlaceOrder = true;
      // }, 2000);
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   // <---- Helper functions ---->
   openSupportedBrokeragesModal() {
      this.tradeApiService.closeBuySellPopup();
      this.tradeApiService.openSupportedBrokeragesModal(this.exchangeCode);
   }

   public changeTypeButton(type: string) {
      this.tradeImpactForm.get('type')?.setValue(type);
      let tif = this.tradeImpactForm.get('tif')?.value;
      this.setNotionalValueSupport(tif);
      if (type === 'Market') {
         this.tradeImpactForm.controls['tif'].setValue('Day');
      } else {
         this.tradeImpactForm.controls['tif'].setValue('GTC');
      }
      // this.setEstimateValue()
      this.tradeImpactForm.controls['notional_value'].setValue(null);
      this.tradeImpactForm.controls['units'].setValue(null);
   }

   public setEstimateValue(buyIn: string, value: number | null) {
      if (buyIn === 'units') {
         if (value) {
            if (this.tradeImpactForm.get('type')?.value === 'Market')
               this.estimated = value * this.symbolData?.price;
            else
               this.estimated =
                  value * this.tradeImpactForm.get('price')?.value;
         } else {
            this.estimated = 0;
         }
      } else {
         if (value) {
            this.estimated = value / this.symbolData?.price;
         } else {
            this.estimated = 0;
         }
      }
   }

   public isFormNotValid() {
      return (
         !this.tradeImpactForm.valid ||
         (this.tradeImpactForm.get('type')?.value === 'Limit' &&
            !this.tradeImpactForm.get('price')?.value) ||
         (this.tradeImpactForm.get('buyIn')?.value === 'units' &&
            !this.tradeImpactForm.get('units')?.value) ||
         (this.tradeImpactForm.get('buyIn')?.value === 'amount' &&
            !this.tradeImpactForm.get('notional_value')?.value)
      );
   }

   checkConditions() {
      let total_balance = +this.selectedAccount?.total_balance!;
      if (this.action === 'BUY') {
         if (
            this.tradeImpactForm.get('buyIn')?.value === 'units' &&
            this.estimated > total_balance
         ) {
            this.toaster.error('Not enough cash.');
            return true;
         }

         if (
            this.tradeImpactForm.get('buyIn')?.value === 'amount' &&
            +this.tradeImpactForm.get('notional_value')?.value >
               this.selectedAccount?.total_balance!
         ) {
            this.toaster.error('Not enough cash.');
            return true;
         }
      }

      if (this.action === 'SELL') {
         if (
            this.tradeImpactForm.get('buyIn')?.value === 'units' &&
            +this.tradeImpactForm.get('units')?.value > +this.shareUnits!
         ) {
            this.toaster.error('Not enough shares.');
            return true;
         }

         if (
            this.tradeImpactForm.get('buyIn')?.value === 'amount' &&
            this.estimated > this.shareUnits!
         ) {
            this.toaster.error('Not enough shares.');
            return true;
         }
      }

      return false;
   }

   showErrorBox() {
      if (!this.accounts.length || !this.selectedAccount?.is_trading_supported)
         return true;
      // if(!this.isMarketOpen) return false;
      if (!this.symbolData?.price) return true;
      if (this.action === 'SELL') return !this.isTickerExistsForSell;
      else return false;
   }

   showBrokerageError() {
      return (
         !this.accounts.length || !this.selectedAccount?.is_trading_supported
      );
   }

   showHoldingError() {
      return (
         this.symbolData?.price &&
         this.selectedAccount?.is_trading_supported &&
         !this.isTickerExistsForSell
      );
   }

   showSymbolError() {
      return (
         this.accounts.length &&
         this.selectedAccount?.is_trading_supported &&
         !this.symbolData?.price
      );
   }

   showWarningBox() {
      if (!this.symbolData?.price) return false;
      if (this.action === 'SELL')
         return (
            this.selectedAccount?.is_trading_supported &&
            this.isTickerExistsForSell &&
            !this.isMarketOpen
         );
      else
         return (
            this.selectedAccount?.is_trading_supported &&
            !this.isMarketOpen
         );
   }

   showForm() {
      if (this.action === 'SELL')
         return (
            this.selectedAccount?.is_trading_supported &&
            this.isMarketOpen &&
            this.isTickerExistsForSell &&
            this.symbolData?.price
         );
      else
         return (
            this.selectedAccount?.is_trading_supported &&
            this.isMarketOpen &&
            this.symbolData?.price
         );
   }

   closePopup() {
      this.tradeApiService.closeBuySellPopup();
   }

   getComplianceText(status: string) {
      switch (status) {
         case 'COMPLIANT':
            return 'HALAL';
         case 'NON_COMPLIANT':
            return 'NOT HALAL';
         case 'QUESTIONABLE':
            return 'DOUBTFUL';
         default:
            return '';
      }
   }

   openComplianceReportTab() {
      this.tradeApiService.setOverviewTab('details-tab');
      this.tradeApiService.closeBuySellPopup();
   }

   // Disable buuton if required fields are empty
   // checkIsDisabled(): boolean {
   //    // Check conditions for enabling/disabling the button
   //    if (
   //       !this.formData.action ||
   //       !this.formData.account_id ||
   //       !this.formData.orderType ||
   //       (this.formData.orderType === 'limit' && !this.formData.price) ||
   //       !this.formData.buyIn ||
   //       (this.formData.buyIn === 'units' && !this.formData.units) ||
   //       (this.formData.buyIn === 'amount' && !this.formData.notionalValue)
   //    ) {
   //       this.isDisabled = true;
   //       return true;
   //    }

   //    this.isDisabled = false;
   //    return false;
   // }

   // setFormData(key: string, event: any, isString: boolean) {
   //    if (!isString)
   //       this.formData = { ...this.formData, [key]: event.target.value };
   //    else this.formData = { ...this.formData, [key]: event };

   //    if (key === 'orderType' && event === 'Limit') {
   //       this.formData.buyIn = 'units';
   //    }

   //    if (key === 'units') {
   //       this.estimated = this.formData.units
   //          ? this.formData.units * this.stockPrice
   //          : 0;
   //    }

   //    if (key === 'amount') {
   //       this.estimated = this.formData.notionalValue
   //          ? this.formData.notionalValue / this.stockPrice
   //          : 0;
   //    }

   //    if (key === 'buyIn') this.estimated = 0;
   //    // console.log(this.formData);
   // }

   // Calling Impact Trade Api
   // setReview(value: boolean) {
   //    // this.isReview = value;
   //    if (value) {
   //       this.isReviewLoading = true;
   //       this.tradeApiService
   //          .getTradeImpact(this.formData, this.selectedAccount.account_id)
   //          .then((res) => {
   //             if ((res as any).data.status >= 400)
   //                this.toaster.error((res as any).data.message.detail);
   //             if ((res as any).data.combined_remaining_balance.cash) {
   //                this.tradeImpact = (res as any).data;
   //                this.isReview = true;
   //                //console.log('this is tradeImpact object', this.tradeImpact);
   //             }
   //          })
   //          .catch((err) => {
   //             console.log(err);
   //          })
   //          .finally(() => {
   //             this.isReviewLoading = false;
   //             // this.isLoading = false;
   //          });
   //    } else {
   //       this.isReview = value;
   //    }
   // }

   // placeOrder() {
   //    console.log('Order Placing ...');
   //    if (this.tradeImpact?.trade?.id) {
   //       this.isPlaceOrderFetching = true;
   //       this.isReviewLoading = true;
   //       this.tradeApiService
   //          .placeOrder(
   //             this.tradeImpact?.trade?.id,
   //             this.selectedAccount.account_id
   //          )
   //          .then((res) => {
   //             // console.log(res);
   //             if ((res as any).data.status >= 400) {
   //                this.toaster.error((res as any).data.message.detail);
   //                this.isReview = false;
   //                this.isReviewLoading = false;
   //                this.isPlaceOrder = false;
   //                this.isLoading = false;
   //                return;
   //             }

   //             if (
   //                (res as any).data.status == 'PENDING' ||
   //                (res as any).data.status == 'ACCEPTED' ||
   //                (res as any).data.status == 'EXECUTED' ||
   //                (res as any).data.status == 'FILLED'
   //             ) {
   //                this.placeOrderData = (res as any).data;
   //                this.toaster.success(
   //                   'Order Status : ' + this.placeOrderData.status
   //                );
   //                this.isReview = false;
   //                this.isReviewLoading = false;
   //                this.isPlaceOrder = true;
   //                this.isLoading = false;
   //                setTimeout(() => {
   //                   this.router.navigate(['/portfolio-dashboard']);
   //                }, 3000);
   //                return;
   //             } else {
   //                this.placeOrderData = (res as any).data;
   //                this.toaster.error(
   //                   'Order Status : ' + this.placeOrderData.status
   //                );
   //                this.isPlaceOrder = false;
   //                this.isLoading = false;
   //                this.isReviewLoading = false;
   //                return;
   //             }
   //          })
   //          .catch((err) => {
   //             console.log(err);
   //          })
   //          .finally(() => {
   //             this.isReviewLoading = false;
   //          });
   //    }
   // }

   // async getInitialData() {
   //    try {
   //       const marketStatusRes = await this.tradeApiService.marketStatus(
   //          this.exchangeCode
   //       );
   //       this.isMarketOpen = (marketStatusRes as any).data.is_open;
   //       if (!this.isMarketOpen) {
   //          const symbolIdRes = await this.tradeApiService.getSymbolId(
   //             this.symbol,
   //             this.exchangeCode,
   //             this.countryCode,
   //             this.selectedAccount.account_id
   //          );

   //          this.symbolData = (symbolIdRes as any).data;
   //          console.log(this.symbolData);
   //          if ((symbolIdRes as any).priceData[0])
   //             this.stockPrice = (
   //                symbolIdRes as any
   //             ).priceData[0]?.last_trade_price;
   //          this.formData.symbolId = (symbolIdRes as any)?.data?.id;
   //          const accountRes = await this.tradeApiService.getAccountBalance(
   //             this.selectedAccount.account_id
   //          );
   //          if ((accountRes as any).data && (accountRes as any).data[0]) {
   //             this.accountBalance = (accountRes as any).data[0]?.cash;
   //             this.accountCurrency = (
   //                accountRes as any
   //             ).data[0]?.currency?.code;
   //          }

   //          const stockRes = await this.typesenseApiService.getStockFromSymbols(
   //             [this.symbol]
   //          );

   //          this.isHalal =
   //             stockRes[this.symbol].shariahCompliantStatus == 'COMPLIANT';
   //          const marketTimeRes = await this.tradeApiService.marketTime(
   //             this.exchangeCode
   //          );
   //          this.marketCloseReason = (marketTimeRes as any).msg;
   //       }
   //    } catch (err) {
   //       console.log(err);
   //    } finally {
   //       this.isLoading = false;
   //    }
   // }
}
