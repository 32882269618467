import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';
import { ConnectIframeModalComponent } from '../../connect-iframe-modal/connect-iframe-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { TradeService } from 'src/app/shared/services/trade.service';

@Component({
   selector: 'app-calculate-portfolio-card',
   templateUrl: './calculate-portfolio-card.component.html',
   styleUrls: ['./calculate-portfolio-card.component.scss'],
})
export class CalculatePortfolioCardComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   linkLoader: boolean = false;
   connectAccountModalRef!: NgbModalRef;
   redirectUrl: string = '';
   // isPremiumUser: boolean = false;
   // maxBrokergaeAccountCount: number = 0;
   @Input() imageUrl!: string;
   @Input() heading!: string;
   @Input() content!: string;

   maxManualPortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;

   constructor(
      private portfolioService: PortfolioService,
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils,
      private sharedService: SharedService,
      private authService: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private tradeApiService: TradeService
   ) {}

   ngOnInit(): void {
      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .subscribe((permissions) => {

            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;

            // this.isPremiumUser = permission;
            // this.maxBrokergaeAccountCount =
            //    permissions.brokerage_connected_portfolios_count;

            // console.log(this.maxBrokergaeAccountCount)
         });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   linkPortfolioAccount() {
      this.linkLoader = true;
      this.portfolioService
         .getAuthUrl()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response: any) => {
               if (response.status) {
                  this.analyticsUtils.portfolio_link_account_tapped();
                  this.redirectUrl = response.data.redirect_url;
                  this.linkLoader = false;
                  this.openConnectIframeModal();
               }
            },
            error: (error) => {
               console.log(error);
               this.linkLoader = false;
            },
         });
   }

   openConnectIframeModal() {
      this.connectAccountModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectAccountModalRef.componentInstance.iframeUrl =
         this.redirectUrl;
   }

   closeConnectIframeModal() {
      if (this.connectAccountModalRef) {
         this.connectAccountModalRef.dismiss();
      }
   }

   openSupportedBrokerages() {
      this.portfolioService.openSupportedBrokeragesModal(true);
   }

   scrollToViewBrokerges() {
      const element = document.getElementById('brokerages');
      console.log(element);
      element?.scrollIntoView({
         behavior: 'smooth',
         block: 'start',
      });
   }

   openUpgradePopup() {
      this.sharedService.openUpgradePopup('portfolio');
   }

   openAuthPopup() {
      this.authService.openAuthPopup();
   }

   handleConnectBtn() {
      if (!this.authService.getUserId()) {
         this.openAuthPopup();
      } else {
         this.portfolioService.openConnectPortfolioPopup()
         // this.openSupportedBrokerages();
      }
   }
   // handleConnectBtn() {
   //    if (!this.authService.getUserId()) {
   //       this.openAuthPopup();
   //    } else if (!this.maxBrokergaeAccountCount) {
   //       this.openUpgradePopup();
   //    } else {
   //       this.openSupportedBrokerages();
   //    }
   // }
}
