import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class RemainingServices {

  remainingStatus: EventEmitter<void>

  constructor() {
    this.remainingStatus = new EventEmitter()
  }

  update() {
    this.remainingStatus.emit()
  }


}
