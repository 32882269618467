<article class="d-flex align-items-center justify-content-center flex-column locked-card-container">
   <div class="article-inner-wrapper mx">
      <div class="lock-img-wrapper mx-auto rounded-circle">
         <img src="/assets/images/lock.png" alt="Lock image" class="d-block mx-auto img-fluid lock-image">
      </div>
      <div class="d-flex align-items-center justify-content-center gap-3 flex-wrap mb-3">
         <img src="/assets/images/crown-icon.svg" alt="crown-icon" class="crown-image">
         <h3 class="mb-0 card-heading lh-1">{{ heading }}</h3>
      </div>
      <p class="text-center para-text"> {{text}} </p>
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 w-100">
         <button class="btn shadow-none rounded-3 text-white py-2 px-4 gradient-btn" *ngIf="userRole === 'FREEMIUM' || userRole === 'ANONYMOUS'"  (click)="accessControlService.accessDenied()">
            <!-- Upgrade & Unlock! -->
            <img src="/assets/icons/lock-white.svg" width="16" height="16" alt="lock image" class="img-fluid">
            <span>Unlock</span>
         </button>
         <ng-container *ngIf="showSampleBtn">
            <button class="btn p-0 shadow-none btn-lined" *ngIf="userRole !== 'PREMIUM'" (click)="viewSample()">
               View Sample {{ heading }}
            </button>
         </ng-container>
         <button [disabled]="disableTryNow" class="btn p-0 shadow-none btn-lined" *ngIf="userRole !== 'PREMIUM' && heading == 'Stock Purification'" (click)="tryNow()">
            Try Purification Now
         </button>
      </div>
   </div>
</article>
