<section class="content-padding">
   <div class="office-content container mx-auto">
      <h3 class="text-center section-main-heading fw-600">Offices Globally</h3>
      <div class="offices-card-grid pb-0">
          <article class="d-flex align-items-center gap-3 justify-content-center">
              <img src="/assets/images/about-us/ny.svg" class="img-fluid flex-shrink-0" alt="">
              <div class="flex-grow-1">
                  <h6 class="mb-1 mb-lg-2 fw-semibold">New York, USA</h6>
                  <p class="mb-0 font-weight-500">
                      One World Trade Center, <br> 285 Fulton St, Suite 8500, <br> New York, NY 10007, USA
                  </p>
              </div>
          </article>
          <article class="d-flex align-items-center gap-3 justify-content-center">
              <img src="/assets/images/about-us/uae.svg" class="img-fluid flex-shrink-0" alt="">
              <div class="flex-grow-1">
                  <h6 class="mb-1 mb-lg-2 fw-semibold">Dubai, UAE</h6>
                  <p class="mb-0 font-weight-500">
                      Office 1802, Lake Central Tower, <br> Business Bay, Dubai, UAE
                  </p>
              </div>
          </article>
          <article class="d-flex align-items-center gap-3 justify-content-center">
              <img src="/assets/images/about-us/usb.svg" class="img-fluid flex-shrink-0" alt="">
              <div class="flex-grow-1">
                  <h6 class="mb-1 mb-lg-2 fw-semibold">Tashkent, Uzbekistan</h6>
                  <p class="mb-0 font-weight-500">
                      1A, Mahtumquli street, <br> 13th Floor, Yashnobod District, <br> Tashkent city, Uzbekistan
                  </p>
              </div>
          </article>
      </div>
  </div>
</section>
