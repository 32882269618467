import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalWrapperComponent } from '../../modal-wrapper/modal-wrapper.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { WatchliistSymbolsState } from 'src/app/stores/watchlist-symbols/watchlist-symbols.reducers';
import { WatchlistService } from 'src/app/shared/services/watchlist.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-watchlist-button',
   templateUrl: './watchlist-button.component.html',
   styleUrls: ['./watchlist-button.component.scss'],
})
export class WatchlistButtonComponent implements OnInit {
   @Input() stock: any;
   @Input() etf: any;
   watchlistPopupOpen: boolean = false;
   isAddedToWatchlist: boolean = false;
   symbols$: Observable<string[]> | undefined;
   loading$: Observable<boolean> | undefined;
   error$: Observable<any> | undefined;

   @ViewChild('wrapperModalContent') wrapperModal!: ModalWrapperComponent;

   constructor(
      private auth: AuthService,
      private store: Store<{ watchlistSymbols: WatchliistSymbolsState }>,
      private watchlistService: WatchlistService,
      public accessControlService: AccessControlService
   ) { }

   ngOnInit(): void {
      this.symbols$ = this.store.select(
         (state) => state.watchlistSymbols.symbols
      );
      this.symbols$.subscribe((symbols) => {
         this.isAddedToWatchlist = symbols.some(
            (symbol: string) =>
               (symbol === (this.stock?.ticker ?? this.stock?.symbol)) || (symbol === this.etf?.symbol)
         );
      });
      this.loading$ = this.store.select(
         (state) => state.watchlistSymbols.loading
      );
      this.error$ = this.store.select((state) => state.watchlistSymbols.error);
      // console.log("Button stock :",this.stock)
   }

   addToWatchlist() {
      if (this.auth.getUserId()) {
         // this.watchlistPopupOpen = true;
         this.watchlistService.openAddListsPopup(this.stock, this.etf)
      } else {
         // this.auth.openAuthPopup();
         this.accessControlService.accessDenied();
      }
   }
}
