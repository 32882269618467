import { Pipe, PipeTransform } from "@angular/core";
  
@Pipe({
  name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, field: string, direction:boolean): any[] {
    array.sort((a: any, b: any) => {
        if(direction)
        {
            if (a[field] < b[field]) {
                return -1;
              } else if (a[field] > b[field]) {
                return 1;
              } else {
                return 0;
              }
        }
        else
        {
            if (a[field] < b[field]) {
                return 1;
              } else if (a[field] > b[field]) {
                return -1;
              } else {
                return 0;
              }
        }
      
    });
    return array;
  }
}