import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PurificationMethod } from 'src/app/models/purification-method.model';
import { PortfolioService } from '../../services/portfolio.service';
import { Subscription } from 'rxjs';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';

@Component({
   selector: 'app-purification-methodology-popup',
   templateUrl: './purification-methodology-popup.component.html',
   styleUrls: ['./purification-methodology-popup.component.scss'],
})
export class PurificationMethodologyPopupComponent implements OnInit {
   private subscriptions: Subscription[] = [];
   @Input() methods: PurificationMethod[] = [];
   selectedPurificationMethod!: PurificationMethod;

   constructor(
      public activeModal: NgbActiveModal,
      private portfolioService: PortfolioService,
      private analyticsUtils: AnalyticsUtils
   ) {}

   ngOnInit(): void {
      this.methods = PortfolioService.purificationMethods;
      this.subscriptions.push(
         PortfolioService.selectedPurificationMethod.subscribe(
            (purificationMethod: PurificationMethod) => {
               this.selectedPurificationMethod = purificationMethod;
            }
         )
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   changeMethod(purificationMethodValue: string) {
      if (this.selectedPurificationMethod.value != purificationMethodValue) {
         this.portfolioService.setPurificationMethod(purificationMethodValue);
         //  this.portfolioService.setDefaultPurificationMethod('default_purification_method', purificationMethodValue)
         this.analyticsUtils.purification_method_changed(
            purificationMethodValue
         );
      }
      this.activeModal.dismiss();
   }
}
