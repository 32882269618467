import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-gold-and-silver-details',
  templateUrl: './gold-and-silver-details.component.html',
  styleUrls: ['./gold-and-silver-details.component.scss']
})
export class GoldAndSilverDetailsComponent implements OnInit {
  form!: FormGroup;
  selectedCurrency!: string | null

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.selectedCurrency = localStorage.getItem('selectedCurrency');
    if(this.selectedCurrency) {
      this.selectedCurrency = this.selectedCurrency?.replace(/^"|"$/g, '')
    }
    this.form = this.rootFormGroup.control as FormGroup;
  }
}
