import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { StockNews, StockNewsResponse } from 'src/app/models/news/stock-news.model';
import { NewsService } from 'src/app/shared/services/news.service';

@Component({
  selector: 'app-stocks-news',
  templateUrl: './stocks-news.component.html',
  styleUrls: ['./stocks-news.component.scss']
})
export class StocksNewsComponent implements OnInit {
   private destroy$ = new Subject<void>();
   isMobile: boolean = false;
   stocksLoader: boolean = false;
   allSymbols: string[] = [];
   newsData: StockNews[] = []

   constructor(
      private observer: BreakpointObserver,
      private newsService: NewsService
   ) {}

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.newsService.stockSymbols$.pipe(takeUntil(this.destroy$)).subscribe(data => {
         const symbols = data.map((stock: any) => stock.ticker)
         this.allSymbols = Array.from(new Set(symbols));

         if(this.allSymbols.length > 0) {
            this.fetchStockNews(this.allSymbols);
         }
      })
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByNewsId(index: number, news: StockNews) { 
      return news.Id;
   }

   async fetchStockNews(symbols: string[]) {
      try {
         this.stocksLoader = true
         const resp = await this.newsService.fetchStockNews<StockNewsResponse>(symbols)
         if(resp.status) {
            const allNews = resp.data.sort((a: StockNews, b: StockNews) => b.Datetime - a.Datetime);
            this.newsData = allNews.slice(0, 8)
         }
         
      } catch (error) {
         this.stocksLoader = false
      } finally {
         this.stocksLoader = false
      }
   }

   formattedDate(date: any): Date {
      return new Date(date * 1000);
   }
}
