<ng-container>
   <article class="w-100">
      <div class="portfolio-data-container" *ngIf="!loading; else chartLoadingTemplate">
         <div class="portfolio-percentage-container" *ngIf="chartType === 'purenessLevel'">
            <div class="percentage-data">
               <p class="mb-0">{{ halalData.value }}<span>%</span></p>
               <div class="mx-auto">{{ halalData.label }}</div>
            </div>
            <img [ngStyle]="{ bottom: -(100 - halalData.value) + '%' }"
               src="/assets/images/portfolio/portfolio-percentage.png" />
         </div>
         <div class="portfolio-percentage-container bg-white" *ngIf="chartType === 'donut'">
            <div class="chart-container" style="width: 250px; height: 250px;">
               <canvas #chartCanvas></canvas>
               <div class="custom-label">
                  <div class="label__chart">{{halalData.value}}%</div>
                  <div class="status halal_status">{{halalData.label}}</div>
               </div>
            </div>
         </div>
         <div class="compliance-grid d-flex flex-wrap align-items-center justify-content-center"
            *ngIf="!loading; else chartLoadingTemplate">
            <div class="compliance-percentage" *ngFor="let item of shariahData">
               <div [ngStyle]="{ 'background-color': item.color }" class="sharia-dot-badge me-2"></div>
               <p class="mb-0 sharia-text">{{ item.label }}</p>
               <p class="mb-0 ml-auto sharia-percentage">{{ item.value }}%</p>
            </div>
         </div>
      </div>
   </article>
</ng-container>
<ng-template #chartLoadingTemplate>
   <app-container-loader [width]="'100%'" [height]="'100%'"></app-container-loader>
</ng-template>
<ng-template #dataLoadingTemplate>
   <div class="compliance-grid" *ngIf="!loading; else chartLoadingTemplate">
      <div class="compliance-percentage" *ngFor="let item of [1,2,3,4]">
         <ngx-skeleton-loader count="1" appearance="line" animation="progress"
            [theme]="{ height: '30px', margin: '0 0 10px 0', display: 'block' }">
         </ngx-skeleton-loader>
      </div>
   </div>
</ng-template>