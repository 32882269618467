import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorNotFoundComponent } from './shared/components/error-not-found/error-not-found.component';
import { CabinetGuard } from './shared/guard/cabinet.guard';
import { PagesGuard } from './shared/guard/pages.guard';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { CheckMobileGuard } from './check-mobile.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./cabinet/cabinet.module').then((m) => m.CabinetModule),
        canActivate: [CabinetGuard, CheckMobileGuard],

      },
    ],
  },
  { path: 'thankyou', component: ThankyouComponent },

  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
    canActivate: [CheckMobileGuard],
  },
  {
     path: '',
     redirectTo: '',
     pathMatch: 'full',
     canActivate: [CheckMobileGuard],
  },
  {
    path: '',
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      },
    ]
  },
  {
   path: '',
   children: [
     {
       path: 'pages',
       loadChildren: () => import('./pages/pages.module').then(m => m.pagesModule),
       canActivate: [PagesGuard, CheckMobileGuard],
     },
   ]
 },

  //  {
  //   path: 'report',
  //   component: ScreenerReportComponent
  // },


  //  {
  //   path: 'report',
  //   component: ScreenerReportComponent
  // },
  { path: '**', component: ErrorNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled', // Prevent scrolling to top
      anchorScrolling: 'enabled',
   })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
