<section class="position-relative" *ngIf="showCrowdfundingBanner">
   <div class="container">
      <article class="crowdfunding-banner">
         <button class="btn p-0 shadow-none position-absolute x-mark-btn" (click)="closeCrowdfundingbanner()">
            <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                  <path fill="#D9D9D9" d="M0 0h32v32H0z" />
               </mask>
               <g mask="url(#a)">
                  <path
                     d="m16.001 17.867 3.867 3.867q.367.366.933.366.568 0 .934-.366.366-.367.366-.934t-.366-.933L17.868 16l3.867-3.866q.366-.367.366-.934 0-.566-.366-.933-.367-.367-.934-.367-.566 0-.933.367l-3.867 3.867-3.866-3.867Q11.768 9.9 11.2 9.9t-.933.367-.367.933q0 .568.367.934L14.135 16l-3.867 3.867q-.367.366-.367.933t.367.934.933.366.934-.366zm0 11.467q-2.766 0-5.2-1.05a13.5 13.5 0 0 1-4.233-2.85q-1.8-1.8-2.85-4.234-1.05-2.433-1.05-5.2t1.05-5.2 2.85-4.233 4.233-2.85 5.2-1.05 5.2 1.05 4.234 2.85 2.85 4.233 1.05 5.2-1.05 5.2a13.5 13.5 0 0 1-2.85 4.234q-1.8 1.8-4.234 2.85-2.433 1.05-5.2 1.05"
                     fill="#4D89C0" />
               </g>
            </svg>
         </button>

         <div>
            <h4 class="fw-600 text-center text-lg-start">
               <span>Invest In Musaffa</span> and become a Shareholder!
            </h4>
            <div class="bullet-list">
               <ul class="ps-3 mb-0">
                  <li class="mb-2"><strong class="fw-600">$4M+</strong> Raised to Date from 524 Investors.</li>
                  <li class="mb-2"><strong class="fw-600">$30 Trillion</strong> Halal & Ethical Investment Market
                     Opportunity.</li>
                  <li class="mb-0"><strong class="fw-600">625% Revenue Growth</strong> in the Last <strong
                        class="fw-600">12 Months</strong>.</li>
               </ul>
            </div>
         </div>

         <div class="left-sec">
            <div class="loader-wrap" *ngIf="loading$ | async">
               <div class="progress-loader">
                  <ngx-skeleton-loader count="1" appearance="line" animation="progress"
                     [theme]="{ height: '120px', width: '100%', margin: '0px' }">
                  </ngx-skeleton-loader>
               </div>
               <ngx-skeleton-loader count="1" appearance="line" animation="progress"
                  [theme]="{ height: '40px', width: '100%', margin: '0px' }">
               </ngx-skeleton-loader>
            </div>

            <div *ngIf="progressData$ | async as data">
               <div class="investment-container d-grid gap-2 br-10">
                  <header class="d-flex flex-wrap align-items-center justify-content-between">
                     <h5 class="mb-0 text-white fw-600 opening-soon-text">
                        Growth Equity Crowdfunding!
                     </h5>
                     <span class="fw-600 text-white goal-amount">{{ data.v2_amount_to_be_raised }}</span>
                  </header>
                  <div class="progress-wrapper rounded-pill flex-grow-1 overflow-hidden">
                     <div class="progress seed-progress rounded-pill overflow-hidden p-0">
                        <div class="progress-bar seed-progress-bar investment-progressbar" role="progressbar"
                           [style.width.%]="data.v2_progressbar_percent"
                           [attr.aria-valuenow]="data.v2_progressbar_percent" aria-valuemin="0" aria-valuemax="100">
                        </div>
                     </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between goal-amount">
                     <span class="fw-600 text-white goal-amount">{{ data.v2_amount_received }}</span>
                     <p class="mb-0 investors-text">Raised from <span
                           class="fw-600">{{data.v2_number_of_investors}}</span> investors</p>
                  </div>
               </div>
               <a href="https://invest.musaffa.com?utm_source=mainwebsite&utm_medium=referral&utm_campaign=cf-2025"
                  target="_blank"
                  class="btn d-block text-decoration-none fw-600 text-center gradient-btn text-uppercase text-white mx-auto shadow-none reserve-shares-btn">
                  Invest Halal Now
               </a>
            </div>
         </div>

      </article>
   </div>

</section>

<app-featured-in *ngIf="showCrowdfundingBanner"></app-featured-in>