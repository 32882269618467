import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
   selector: 'app-hero-banner',
   templateUrl: './hero-banner.component.html',
   styleUrls: ['./hero-banner.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeroBannerComponent implements OnInit {
   videoUrl = 'https://www.youtube.com/embed/QEjqwdJlO4M?si=DIwDMCKiz1JCa5r1?autoplay=1';
   videoTitle: string = 'Musaffa: Global Halal Investment Platform!';
   safeURL: SafeResourceUrl = '';

   constructor(
      private _sanitizer: DomSanitizer,
      private authService: AuthService
   ) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
   }

   ngOnInit(): void {
   }

   ngOnDestroy(): void {

   }

   openPopup() {
      this.authService.openAuthPopup()
   }
}
