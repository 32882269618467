<ng-container *ngIf="loader">
   <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="progress"
      [theme]="{
         height: '195px',
         width: '100%',
         margin: '0px 0px 0px 0px',
         'border-radius': '10px'
      }"
   ></ngx-skeleton-loader>
</ng-container>

<section
   class="section mt-4 mt-lg-0 portfolio-card"
   *ngIf="!loader"
>
   <article
      class="d-flex align-items-start justify-content-between gap-2 gap-lg-0"
   >
      <div>
         <h3 class="section-heading lh-1">My Portfolio</h3>
         <ng-container *ngIf="!hasBrokerages">
            <p class="mb-3 text-para">
               Get a Shariah compliance overview of your entire portfolio and
               view cumulated purification amount.
            </p>
            <a
               class="btn shadow-none px-3 connect-btn"
               routerLink="/portfolio-dashboard"
               (click)="homePortfolioTapped()"
            >
               Connect Account
            </a>
         </ng-container>
         <ng-container *ngIf="hasBrokerages">
            <p class="mb-3 mb-lg-4 pb-lg-1 text-para">
               Monitor the Shariah compliance score & automatically track the
               portfolio purification.
            </p>
            <a
               class="btn shadow-none px-3 connect-btn"
               routerLink="/portfolio-dashboard"
               (click)="homePortfolioTapped()"
            >
               View Portfolio
            </a>
         </ng-container>
      </div>
      <div>
         <img
            src="./assets/images/my-portfolio.svg"
            class="img-fluid image"
            alt=""
         />
      </div>
   </article>
</section>
