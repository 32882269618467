
<div class="coverage-popup-container">
   <!-- confirm request popup content start -->
   <ng-container *ngIf="!isLimitReached || !isCountryCovered">
      <header class="header">
         <h5 class="mb-0 text-center" *ngIf="isCountryCovered">Confirm Coverage Request</h5>
         <h5 class="mb-0 text-center" *ngIf="!isCountryCovered">Not under a covered country!</h5>
      </header>
      <div class="content-container">
         <p class="mb-0 text-center modal-text" *ngIf="!isCountryCovered"> <!-- if uncovered country -->
            This stock belongs to the country which is currently not covered under Musaffa.
            Inshallah we'll let you know once we cover this country.
         </p>
         <p class="mb-0 text-center modal-text" *ngIf="isCountryCovered">
            Confirm request coverage for this stock? <br>
            Our analyst team will review this stock and you'll be notified via email once this stock is covered.
         </p>
      </div>
      <div class="d-flex align-items-center justify-content-center btns-container">
         <button *ngIf="isCountryCovered" class="btn py-2 mx-auto shadow-none modal-btn modal-btn-cancel rounded" [disabled]="loading" (click)="coverageModal.dismiss()">
            <span>Cancel</span>
         </button>
         <button *ngIf="isCountryCovered" class="btn py-2 mx-auto shadow-none modal-btn modal-btn-confirm rounded" [disabled]="loading" (click)="requestCoverage()">
            <span *ngIf="!loading">Request Coverage</span>
            <div class="d-flex justify-content-center" *ngIf="loading">
               <div class="spinner-border" role="status">
                 <span class="visually-hidden">Loading...</span>
               </div>
           </div>
         </button>
         <button *ngIf="!isCountryCovered" class="btn py-2 mx-auto shadow-none modal-btn modal-btn-confirm rounded" (click)="requestCoverage(); coverageModal.dismiss()">
            <span>OK</span>
         </button>
      </div>
   </ng-container>
   <!-- confirm request popup content end -->

   <!-- Error popup content start -->
   <ng-container *ngIf="isLimitReached && isCountryCovered">
      <header class="header">
         <h5 class="mb-0 text-center" *ngIf="isLimitReached">Daily coverage request limit reached!</h5> <!-- if daily limit is exausted -->
      </header>
      <div class="content-container">
         <p class="mb-0 text-center modal-text" *ngIf="isLimitReached"> <!-- if daily limit is exausted -->
            We have currently kept a limit of 3 stock requests per day so as to get back to as many users as possible. Inshallah you can come back tomorrow and request for more.
         </p>
      </div>
      <div class="d-flex align-items-center justify-content-center btns-container">
         <button class="btn py-2 mx-auto shadow-none modal-btn modal-btn-confirm rounded" (click)="coverageModal.dismiss()">
            <span>OK</span>
         </button>
      </div>
   </ng-container>
   <!-- Error popup content end -->


</div>


