import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-counter',
  templateUrl: './steps-counter.component.html',
  styleUrls: ['./steps-counter.component.scss']
})
export class StepsCounterComponent implements OnInit {
  @Input() stepCounter!: number;
  @Input() totalSteps!: number;
  @Input() formId: string = ''

  constructor() { }

  ngOnInit(): void {
  }

}
