<section class="market-section">
   <div class="container content-padding market-container">
      <div class="news-section bg-white market__indice rounded-3 px-3 py-4 p-lg-4">
         <app-market-indices></app-market-indices>
      </div>
      <div class="market-status-container">
         <div class="market-grid">
            <app-market-status></app-market-status>
            <app-market-holidays></app-market-holidays>
         </div>
      </div>
   </div>
   <div class="container content-padding">
      <div class="news-section bg-white rounded-3 px-3 py-4 p-lg-4">
        <!-- <app-home-market-news></app-home-market-news> -->
      </div>
   </div>
</section>