import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil, timer } from 'rxjs';
import { CollectionComplianceService } from 'src/app/shared/services/collection-compliance.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import {
   trigger,
   state,
   style,
   transition,
   animate,
} from '@angular/animations';

@Component({
   selector: 'app-infinite-stocks',
   templateUrl: './infinite-stocks.component.html',
   styleUrls: ['./infinite-stocks.component.scss'],
   animations: [
      trigger('fadeInOut', [
         state('void', style({ opacity: 0 })),
         transition('void => *', [animate('1s ease-in')]),
      ]),
   ],
})
export class InfiniteStocksComponent implements OnInit {
   private destroy$ = new Subject<void>();
   userRole: string | null = null;
   selectedCountryCode!: string;
   stocksLoading: boolean = false;
   mostActive: any[] = [];
   perPage: string = '10';
   constructor(
      private countryService: CountryService,
      private typesenseService: TypesenseService,
      private collectionCompliance: CollectionComplianceService,
      private authService: AuthService
   ) {}

   ngOnInit(): void {
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;
         });

      this.countryService.selectedCountry$
         .pipe(takeUntil(this.destroy$))
         .subscribe((country) => {
            this.selectedCountryCode = country.countryCode;
            this.getStockCollectionData(this.selectedCountryCode);
         });
   }


   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByStockId(index: number, stock: any) {
      return stock.identifier;
   }

   getPercentChangeClasses(stock: any) {
      return {
         positive: stock.percentChange >= 0,
         negative: stock.percentChange < 0,
      };
   }

   getIconClasses(stock: any) {
      return {
         'bi-caret-up-fill positive': stock.percentChange >= 0,
         'bi-caret-down-fill negative': stock.percentChange < 0,
      };
   }

   async getStockCollectionData(countryCode: string) {
      try {
         this.stocksLoading = true;
         let apiResponse = await this.typesenseService.getMostActiveStocks(
            countryCode,
            this.perPage
         );

         let stocksData = [];
         if (apiResponse) {
            stocksData = await this.processApiResponse(apiResponse);
         }
         this.mostActive = stocksData;
      } catch (error) {
         console.error(error);
         this.stocksLoading = false;
      } finally {
         this.stocksLoading = false;
      }
   }

   async processApiResponse(apiResponse: any) {
      let stocksData: any[] = [];
      const resp = apiResponse;
      const stockSymbols = resp.hits.map((item: any) => item.document.ticker);

      const allData = stockSymbols.reduce((acc: any, ticker: any) => {
         const hit = resp.hits.find((h: any) => h.document.ticker === ticker);
         if (hit) {
            acc[ticker] = {
               change: hit.document.change,
               percentChange: hit.document.percentChange,
               lastClosingPrice: hit.document.lastClosingPrice,
               ticker: hit.document.ticker,
            };
         }
         return acc;
      }, {});

      if (stockSymbols.length) {
         const stockData = await this.typesenseService.getStockFromSymbols(
            stockSymbols
         );
         let processedData =
            await this.collectionCompliance.processComplianceForStocksEtfMap(
               stockData,
               'stocks'
            );
         for (const key in processedData) {
            if (allData[key]) {
               Object.assign(allData[key], processedData[key]);
            }
         }

         stocksData = Object.values(allData);
         await this.getPriceData(stocksData, stockSymbols);
      }
      return stocksData;
   }

   async getPriceData(stocks: any[], symbols: string[]) {
      const res = await this.typesenseService.getPrices(symbols);
      for (const key in res) {
         let priceValue = res[key];
         let stock = stocks.find(
            (item: any) => item.ticker == priceValue.id
         );
         if (stock) {
            stock.status = priceValue.status;
            if (stock.status != 'not found') {
               if (!stock.lastPrice) {
                  stock.lastPrice = priceValue.currentPrice;
               }
               stock.price = priceValue.currentPrice;
               stock.percentChange = priceValue.percentChange.toFixed(2);
            }
         }
      }
   }

}
