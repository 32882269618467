import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   TemplateRef,
   ViewChild,
   OnChanges,
   SimpleChanges,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { Subscription } from 'rxjs';
import { RequestCoveragePopupComponent } from '../request-coverage-popup/request-coverage-popup.component';
import { PaywallPopupComponent } from '../paywall-popup/paywall-popup.component';
import { UserApiService } from '../../services/user-api.service';
import { EtfSamplePopupComponent } from '../etf-sample-popup/etf-sample-popup.component';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { ApiService } from '../../services/api.service';
import { GeneralInfoPopupComponent } from '../general-info-popup/general-info-popup.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { AccessControlService } from '../../services/access-control.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ComplianceHistoryService } from '../../services/compliance-history.service';

@Component({
   selector: 'app-compliance-status-card',
   templateUrl: './compliance-status-card.component.html',
   styleUrls: ['./compliance-status-card.component.scss'],
})
export class ComplianceStatusCardComponent implements OnInit, OnChanges {
   @Input() stockType!: string;
   @Input() complianceStatus!: string;
   @Input() complianceRanking!: any;
   // @Input() resultScreeningInfo!: any;
   // @Input() resultSecuritiesAndAssets!: any;
   // @Input() resultDebt!: any;
   @Input() canViewScreeningReport!: boolean;
   @Input() isManualSet: boolean = false;

   // @Input() complianceData: any;
   @Input() lastUpdate: any;
   @Input() reportDate: any;
   @Input() reportTypeSection: any;
   @Input() reportTypeYear: any;

   @Input() ticker: any;
   @Input() isin: any;
   @Input() mainTicker: any;
   @Input() countryCode: any;
   @Input() etfAssetClass: any;
   // @Input() etfLastUpdate: any;
   // @Input() etfComplianceData: any;
   reportUpdateDate: any;

   @Output() openReportModal = new EventEmitter<void>();
   @Output() goToComplianceTab = new EventEmitter<void>();

   userRole: string | null = null;
   isUserLoggedIn: boolean = false;
   isUserPremium: boolean = false;
   isCoverageRequested: boolean = false;
   coverageRequestLimitReached: boolean = false;
   coverageRequestLimit: number = 3;
   private subscriptions: Subscription[] = [];
   coverageModalRef: any;
   paywallModal: any;
   etfSampleModalRef: any;
   showRanking: boolean = false;
   // complianceHistoryRef!: NgbModalRef;
   // @ViewChild('showCompliantHistory', { static: true })
   // showCompliantHistory!: TemplateRef<NgbModal>;
   complianceHistorydata: any;
   isComplianceHistoryLoading: boolean = false;
   confirmModalRef!: NgbModalRef;
   requestLoading: boolean = false;
   configRequestUpdationDefaults: any;
   canRequestStockCoverage: boolean = false;
   canRequestStockComplianceUpdate: boolean = false;
   canReadComplianceHistory: boolean = false;
   showComplianceReport: boolean = false;
   minDuration!: number;
   diffDays!: number;

   constructor(
      private auth: AuthService,
      private firebaseConfig: FirebaseRemoteConfigService,
      public subscriptionService: SubscriptionService,
      public userApiService: UserApiService,
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils,
      private apiService: ApiService,
      private toaster: ToastrService,
      private datePipe: DatePipe,
      private authService: AuthService,
      private router: Router,
      private complianceHistoryService: ComplianceHistoryService,
      private sharedService: SharedService,
      private configService: FirebaseRemoteConfigService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService
   ) { }

   async ngOnInit(): Promise<void> {
      this.isUserLoggedIn = !!this.auth.getUserId();
      // this.subscriptions.push(SubscriptionService.subscriptionData.subscribe(async (data: SubscriptionInfoModel) => {
      //    this.isUserPremium = data.isSubscribed
      //    this.userRole = data.role
      // if(this.userRole !== 'PREMIUM') {
      //    const unlockedSymbols: Array<string> = (await this.firebaseConfig.getFreeStocksList())
      //       .concat(await this.firebaseConfig.getFreeEtfList())
      //       .concat(await this.firebaseConfig.getBucketList())

      //    this.showRanking = unlockedSymbols.includes(this.stockType === 'etf' ? this.ticker : this.isin);
      //  } else {
      //    this.showRanking = true;
      //  }
      // }))
      this.subscriptions.push(
         this.permissionStore
            .select(({ permissions }) => permissions.permissions)
            .subscribe(async (permissions) => {
               this.canRequestStockCoverage = permissions.stock_coverage_request;
               this.canRequestStockComplianceUpdate = permissions.stock_compliance_update_request;
               this.canReadComplianceHistory = permissions.stock_shariah_compliance_history;
               this.showComplianceReport = permissions.stock_compliance_report

               const canReadStockHalalRating = permissions.stock_halal_rating;
               const canReadEtfHalalRating = permissions.etf_halal_rating;
               if (this.stockType === 'etf') {
                  if (canReadEtfHalalRating) {
                     this.showRanking = true;
                  } else {
                     const unlockedSymbols: Array<string> =
                        await this.firebaseConfig.getFreeEtfList();
                     this.showRanking = unlockedSymbols.includes(this.ticker);
                  }
               } else {
                  if (canReadStockHalalRating) {
                     this.showRanking = true;
                  } else {
                     const unlockedSymbols: Array<string> = (
                        await this.firebaseConfig.getFreeStocksList()
                     ).concat(await this.firebaseConfig.getBucketList());
                     // console.log(unlockedSymbols)
                     this.showRanking = unlockedSymbols.includes(this.mainTicker);
                  }
               }
            })
      );
      if (this.isUserLoggedIn) {
         this.setHasCoverageRequest();
         // this.setTodaysCoverageLimitCheck()
      }



      // if (this.userRole !== 'ANONYMOUS') {
      //    this.isComplianceHistoryLoading = true;
      //    this.apiService
      //       .getComlianceHistory((this.ticker || 'AAPL') as string)
      //       .then((res) => {
      //          this.complianceHistorydata = res.data;
      //          this.isComplianceHistoryLoading = false;
      //       });
      // }
   }

   async setHasCoverageRequest() {
      if (this.complianceStatus === 'NOT_UNDER_COVERAGE') {
         const hasRequest = await this.userApiService.hasCoverageRequestFor(
            this.ticker
         );
         this.isCoverageRequested =
            hasRequest?.data?.coverage_requested ?? false;
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.mainTicker?.currentValue) {
         this.handleReportBtnVisibility()
      }
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   async handleReportBtnVisibility() {
      if (this.reportDate) {
         this.configRequestUpdationDefaults = await this.configService.getRequestReportUpdationDefaults();
         let duration = this.configRequestUpdationDefaults.country_durations
            .find((duration: any) => duration.country_code === this.countryCode);
         if (!duration) {
            duration = this.configRequestUpdationDefaults.country_durations
               .find((duration: any) => duration.country_code === 'DEFAULT');
         }
         this.minDuration = duration.duration;
         const now = new Date();
         const reportDate = new Date(this.reportDate);
         const diffTime = Math.abs(now.getTime() - reportDate.getTime());
         this.diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }
   }

   // checkRole(role: string): boolean {
   //    return this.auth.checkRole(role);
   // }

   showReport() {
      this.openReportModal.emit();
   }

   // canViewScreeningReport() {
   //    if (
   // this.complianceStatus === 'NOT_UNDER_COVERAGE' ||
   // this.complianceStatus === 'LOCKED'
   //    )
   //       return false;
   //    if (
   // this.resultScreeningInfo ||
   // this.resultSecuritiesAndAssets ||
   // this.resultDebt
   //    )
   //       return true;
   //    else return false;
   // }

   async setTodaysCoverageLimitCheck() {
      const myRequests: any = await this.userApiService.myCoverageRequests(
         new Date(),
         undefined,
         true
      );
      this.coverageRequestLimitReached =
         myRequests.data.length >= this.coverageRequestLimit;
   }

   handleButtonClick() {
      this.goToComplianceTab.emit();
   }

   handleRatingPopup() {
      if (this.isUserLoggedIn) {
         this.openUpgradePopup()
      } else {
         this.accessControlService.accessDenied();
      }
   }


   openLoginPopup() {
      this.authService.openAuthPopup()
   }

   openUpgradePopup() {
      this.sharedService.openUpgradePopup('report')
   }

   openPaywallModal(upgradePlace: string) {
      this.analyticsUtils.feature_upgrade_tapped(upgradePlace);
      this.openUpgradePopup()
      // this.paywallModal = this.modalService.open(PaywallPopupComponent, {
      //    centered: true,
      //    modalDialogClass: 'paywall-popup-modal',
      //    size: 'xl',
      // });
   }

   openCoverageModal() {
      this.analyticsUtils.request_coverage_tapped(
         this.countryCode,
         this.ticker
      );
      this.coverageModalRef = this.modalService.open(
         RequestCoveragePopupComponent,
         {
            centered: true,
            backdrop: 'static',
         }
      );
      this.coverageModalRef.componentInstance.ticker = this.ticker;
      this.coverageModalRef.componentInstance.countryCode = this.countryCode;
      // this.coverageModalRef.componentInstance.error = !this.isCoverageRequested && this.coverageRequestLimitReached;
      // this.coverageModalRef.componentInstance.isLimitReached = this.coverageRequestLimitReached;
      this.coverageModalRef.componentInstance['stockRequested'].subscribe(
         () => {
            this.setHasCoverageRequest();
         }
      );
   }

   openEtfSampleModal() {
      this.etfSampleModalRef = this.modalService.open(EtfSamplePopupComponent, {
         centered: true,
         size: 'xl',
         windowClass: 'x-large-etf-modal-width',
      });
   }

   handleCoverageBtnStyling() {
      if (!this.isUserLoggedIn) {
         return 'p-0 d-flex fw-500 shadow-none align-items-center card-colored-btn gap-'
      } else {
         return 'd-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3'
      }
   }

   closeEtfSampleModal() {
      this.etfSampleModalRef.close();
   }

   featureUpgradeTapped(text: string) {
      this.analyticsUtils.feature_upgrade_tapped(text);
   }

   getQuarterText() {
      var quarter = '';
      if (
         ['Annual', 'ANNUAL'].includes(this.reportTypeSection)
      ) {
         var text = `${this.reportTypeYear} Annual Report`;
         return text;
      } else {
         switch (this.reportTypeSection) {
            case 'firstQuarter':
            case 'FIRST_QUARTER':
               quarter = '1st';
               break;
            case 'secondQuarter':
            case 'SECOND_QUARTER':
               quarter = '2nd';
               break;
            case 'thirdQuarter':
            case 'THIRD_QUARTER':
               quarter = '3rd';
               break;
            case 'fourthQuarter':
            case 'FOURTH_QUARTER':
               quarter = '4th';
               break;
            default:
               quarter = '';
         }

         var text = `${this.reportTypeYear} ${quarter} Quarter Report`;
         return text;
      }
   }

   handleETFComplianceReportBtnVisibility() {
      if (
         (this.etfAssetClass === 'Fixed Income') ||
         (this.etfAssetClass === 'Equity' && this.complianceStatus === 'NOT_UNDER_COVERAGE') ||
         (this.complianceStatus === 'LOCKED')
      ) {
         return false;
      }
      return true
   }

   openComplianceHistory() {
      this.complianceHistoryService.showComplianceHistory();
   }

   openConfirmationPopup(content: any) {
      if (this.canRequestStockComplianceUpdate) {
         this.confirmModalRef = this.modalService.open(content, {
            centered: true,
            backdrop: 'static'
         });
      } else {
         this.accessControlService.accessDenied();
      }
   }

   closeConfirmationPopup() {
      if (this.confirmModalRef) {
         this.confirmModalRef.dismiss();
      }
   }

   reportRequest() {
      if (this.ticker) {
         this.requestLoading = true;
         let modal: any;
         this.apiService
            .updateComplianceChangeRequest(this.ticker)
            .then((data) => {
               this.closeConfirmationPopup();
               if (data.status) {
                  modal = this.modalService.open(GeneralInfoPopupComponent, {
                     centered: true,
                  });
                  modal.componentInstance['title'] = 'Request Sent';
                  modal.componentInstance['description'] = this.configRequestUpdationDefaults['updation_requested_label'];
               } else {
                  switch (data.status_code) {
                     case 'upgrade_acc':
                        break;
                     case 'not_covered':
                        modal = this.modalService.open(GeneralInfoPopupComponent, {
                           centered: true,
                        });
                        modal.componentInstance['title'] = 'Stock is not covered';
                        modal.componentInstance['description'] = 'Stock has to be covered to make this request';
                        break;
                     case 'recently_analyzed':
                        this.toaster.error('Stock was analyzed recently');
                        break;
                     case 'limit_reached':
                        modal = this.modalService.open(GeneralInfoPopupComponent, {
                           centered: true,
                        });
                        modal.componentInstance['title'] = this.configRequestUpdationDefaults['limit_reached_label']?.title;
                        modal.componentInstance['description'] = this.configRequestUpdationDefaults['limit_reached_label']?.description;
                        break;
                     case 'error':
                        // this.toaster.error('Something went wrong');
                        break;
                     case 'request_exists':
                        modal = this.modalService.open(GeneralInfoPopupComponent, {
                           centered: true,
                        });
                        modal.componentInstance['title'] = 'Request Already Exists';
                        modal.componentInstance['description'] = 'Your request to update report of this stock already exists';
                        break;
                  }
               }
            })
            .catch((e) => {
               console.log(e);
               // this.toaster.error('Something went wrong');
            })
            .finally(() => {
               this.requestLoading = false;
            });
      }
   }

   getLastUpdated() {
      return this.datePipe.transform(
         this.lastUpdate * 1000,
         'MMMM dd, yyyy'
      );
   }

   // getETFLastUpdated() {
   //    return this.datePipe.transform(
   //       this.lastUpdate * 1000,
   //       'MMMM dd, yyyy'
   //    );
   // }
}
