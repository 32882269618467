<header
   class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between gap-3 py-3 px-0 py-lg-4"
>
   <h2 class="page-main-heading mb-0">
      Community-Curated Collections 
      <span class="text-secondary fw-500 fs-5" *ngIf="symbol">( With {{ symbol }} )</span>
   </h2>

   <ul role="list" class="d-flex bg-white align-items-center rounded-pill tab-btns-container" >
      <li class="btn shadow-none rounded-pill d-flex align-items-center justify-content-center px-2 px-lg-4 tab-btn" [ngClass]="{ active: selectedTab === 1 }" (click)="selectTab(1)" role="button" >
         <svg class="svg-1" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M9.165 16.5H2.75c-.55 0-.917-.366-.917-.916s.367-.917.917-.917h6.416c.55 0 .917.367.917.917s-.367.916-.917.916m10.083-.917h-5.5c-.55 0-.917-.366-.917-.916s.367-.917.917-.917h5.5c.55 0 .916.367.916.917s-.366.916-.916.916" />
            <path d="M16.499 18.333c-.55 0-.917-.366-.917-.916v-5.5c0-.55.367-.917.917-.917s.916.367.916.917v5.5c0 .55-.366.916-.916.916m-3.667-6.417H2.749c-.55 0-.917-.366-.917-.916s.367-.917.917-.917h10.083c.55 0 .917.367.917.917s-.367.916-.917.916m0-4.583H2.749c-.55 0-.917-.366-.917-.916s.367-.917.917-.917h10.083c.55 0 .917.367.917.917s-.367.916-.917.916" />
         </svg>
         <span class="text-nowrap">All Collections</span>
      </li>
      <li class="btn shadow-none rounded-pill d-flex align-items-center justify-content-center px-2 px-lg-4 tab-btn" [ngClass]="{ active: selectedTab === 2 }" (click)="selectTab(2)" role="button" >
         <svg class="svg-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M9.572.81c-2.91 0-5.283 2.382-5.283 5.292 0 1.779.89 3.354 2.244 4.313-3.159 1.266-5.4 4.355-5.4 7.959a.833.833 0 0 0 1.666 0 6.896 6.896 0 0 1 6.91-6.908c1.335 0 2.574.381 3.63 1.034a.835.835 0 0 0 1.254-.903.84.84 0 0 0-.376-.52 8.6 8.6 0 0 0-1.523-.722 5.28 5.28 0 0 0 2.17-4.253c0-2.91-2.382-5.291-5.292-5.291m0 1.669a3.614 3.614 0 0 1 3.625 3.623 3.61 3.61 0 0 1-3.625 3.616 3.6 3.6 0 0 1-3.617-3.616A3.607 3.607 0 0 1 9.572 2.48m8.434 10.001a.83.83 0 0 0-.677.383l-2.873 4.305-1.801-1.206a.836.836 0 1 0-.93 1.392l2.505 1.666a.835.835 0 0 0 1.156-.234l3.335-5.002a.834.834 0 0 0-.715-1.304" />
         </svg>
         <span class="text-nowrap">My Followed</span>
      </li>
      <li class="btn shadow-none rounded-pill d-flex align-items-center justify-content-center px-2 px-lg-4 tab-btn" [ngClass]="{ active: selectedTab === 3 }" (click)="selectTab(3)" role="button" >
         <svg class="svg-3" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M17.67 16.753H5.233a.928.928 0 1 1 0-1.857H17.67a.928.928 0 0 1 0 1.857m0-5.825H5.233a.928.928 0 1 1 0-1.856H17.67a.928.928 0 0 1 0 1.856m0-5.825H5.233a.928.928 0 1 1 0-1.856H17.67a.928.928 0 0 1 0 1.856m-16.424.39a1.246 1.246 0 1 0 0-2.493 1.246 1.246 0 0 0 0 2.493m0 5.754a1.246 1.246 0 1 0 0-2.493 1.246 1.246 0 0 0 0 2.493m0 5.753a1.246 1.246 0 1 0 0-2.493 1.246 1.246 0 0 0 0 2.493" />
         </svg>
         <span class="text-nowrap">My Created</span>
      </li>
   </ul>
</header>
