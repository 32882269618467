import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
   selector: 'app-modal-wrapper',
   templateUrl: './modal-wrapper.component.html',
   styleUrls: ['./modal-wrapper.component.scss']
})

export class ModalWrapperComponent implements OnInit {
   @Input() modalTitle: string = '';
   modalRef!: NgbModalRef;
   @ViewChild('content') content!: TemplateRef<any>;

   constructor(private modalService: NgbModal) { }

   ngOnInit(): void {
   }

   open() {
      this.modalRef = this.modalService.open(this.content, { 
         centered: true,
         scrollable: true,
         backdropClass: 'global-modal-backdrop',
         windowClass: 'global-modal'
      });
   }

}
