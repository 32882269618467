import { Component, Input, OnInit } from '@angular/core';
import { StockDetailsPopupComponent } from '../stock-details-popup/stock-details-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
   selector: 'app-super-investor-table',
   templateUrl: './super-investor-table.component.html',
   styleUrls: ['./super-investor-table.component.scss']
})

export class SuperInvestorTableComponent implements OnInit {
   private subscriptions: Subscription[];
   @Input() tableLoader: boolean = false
   // @Input() stock : any
   @Input() investorStocks: any = []
   stockDetailsPopupRef: any
   userRole: string | null = null
   isMobile = false;
   isLoggedIn = false;
   canReadSuperInvestorPortfolio = false;

   constructor(
      private modalService: NgbModal,
      private subscriptionService: SubscriptionService,
      private observer: BreakpointObserver,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService,
      public auth: AuthService,
      private sharedService: SharedService
   ) {
      this.subscriptions = [];
   }

   ngOnInit(): void {
      this.isLoggedIn = !!this.auth.getUserId()
      this.subscriptions.push(
         this.permissionStore.select(({permissions}) => permissions.permissions).subscribe(
            (permissions) => {
               this.isLoggedIn = !!this.auth.getUserId();
               this.canReadSuperInvestorPortfolio = permissions.super_investor_portfolio;
            }
         )
      )
      // this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      //    this.userRole = data.role
      // }))
      this.observer.observe('(max-width: 991px)').subscribe(result => {
         this.isMobile = result.matches
      });
   }

   openStockDetailsPopup(stock: any) {
      // this.analyticsUtils.portfolio_holding_item_tapped(holding.symbol);
      this.stockDetailsPopupRef = this.modalService.open(StockDetailsPopupComponent, {
            centered: true,
         }
      );
      this.stockDetailsPopupRef.componentInstance.logo = stock.logo;
      this.stockDetailsPopupRef.componentInstance.stockName = stock.name;
      this.stockDetailsPopupRef.componentInstance.stockSymbol = stock.symbol;
      this.stockDetailsPopupRef.componentInstance.complianceStatus = stock.complianceStatus;
      this.stockDetailsPopupRef.componentInstance.complianceRanking = stock.complianceRanking;
      this.stockDetailsPopupRef.componentInstance.sharesOwned = stock.shares;
      this.stockDetailsPopupRef.componentInstance.portfolioPercentage = stock.percentage;
      this.stockDetailsPopupRef.componentInstance.currentValue = stock.value;
      this.stockDetailsPopupRef.componentInstance.reportDate = stock.reportDate;
      this.stockDetailsPopupRef.componentInstance.stockType = stock.type
   }

   openPaywallModal() {
      this.sharedService.openUpgradePopup()
      // this.subscriptionService.openPaywallModal()
   }

   filtereredStocks(): any[] {
      if (!this.canReadSuperInvestorPortfolio) {
         return this.investorStocks.slice(0, 3);
      } else {
         return this.investorStocks;
      }
   }

   getPercentChangeClasses(stock: any) {
      return {
         'green' : stock.percentChange >= 0,
         'red' : stock.percentChange < 0
      }
   }

   getIconClasses(stock: any) {
      return {
         'bi-caret-up-fill green' : stock.percentChange >= 0,
         'bi-caret-down-fill red' : stock.percentChange < 0,
      }
   }

   getTextClasses(stock: any) {
      return {
         'green-text' : stock.percentageChange > 0,
         'red-text' : stock.percentageChange < 0
      }
    }
}
