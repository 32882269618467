<ng-container *ngIf="!isListCreated">
   <div
      class="modal-header border-0 p-3 p-lg-4 d-flex flex-column gap-1 gap-lg-2 align-items-stretch"
   >
      <div class="d-flex flex-grow-1 align-items-start justify-content-between">
         <h4 class="modal-title mb-0">
            {{ listDetails?.id ? "Edit list" : "Create a New List" }}
         </h4>
         <button
            type="button"
            class="btn close-popup-btn shadow-none p-1"
            aria-label="Close"
            (click)="activeModal.dismiss()"
         >
            <i class="bi bi-x-lg"></i>
         </button>
      </div>
      <p class="mb-0">
         Build a list to track your favourite companies & share with others.
      </p>
   </div>
   <div class="modal-body border-0">
      <form
         [formGroup]="newListForm"
         (ngSubmit)="createList()"
         (keydown)="preventEnterSubmit($event)"
      >
         <div class="form-controls-wrapper">
            <div class="list-input-group mb-3 mb-lg-4">
               <label for="listName" class="form-label">List Name</label>
               <input
                  appTitleCase
                  type="text"
                  class="form-control shadow-none rounded-0"
                  id="listName"
                  placeholder="Enter your list name"
                  formControlName="listName"
               />
               <!-- <small class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger" *ngIf="listNameExistsError">
                  This name already exists. Please enter a different name
               </small>
               <small class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger" *ngIf="submitted && formControls['listName'].hasError('required')">
                  List name is required
               </small> -->
            </div>
            <div class="mb-3 mb-lg-4">
               <app-chip-input
                  [inputLabel]="'Add Stocks'"
                  formControlName="stocks"
                  [multiple]="true"
                  [removable]="true"
                  [placeholder]="'Enter the symbol'"
               >
               </app-chip-input>
               <!-- <small *ngIf="submitted && formControls['stocks'].errors?.minChips" class="alert d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger">
                  Minimum {{ newListForm.controls['stocks']?.errors?.minChips?.requiredLength }} stocks required, but got {{ newListForm.controls['stocks']?.errors?.minChips?.actualLength }}.
               </small> -->
            </div>
            <div class="list-input-group">
               <label for="description" class="form-label">Description</label>
               <textarea
                  appTitleCase
                  type="text"
                  class="form-control shadow-none rounded-2"
                  id="description"
                  placeholder="Enter your description"
                  formControlName="description"
                  rows="2"
               ></textarea>
               <!-- <small class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger" *ngIf="submitted && formControls['description'].hasError('required')">
                  Description is required
               </small> -->
            </div>
         </div>

         <div
            class="d-flex align-items-center justify-content-center gap-3 gap-lg-4 form-btns-wrapper"
         >
            <button
               type="button"
               class="btn shadow-none d-flex align-items-center justify-content-center rounded-3 m-0 cancel-btn"
               (click)="activeModal.dismiss()"
            >
               Cancel
            </button>
            <button
               type="submit"
               class="btn shadow-none d-flex align-items-center justify-content-center gap-1 m-0 rounded-3 create-btn"
               [disabled]="createButtonLoader || !newListForm.valid"
            >
               <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  *ngIf="createButtonLoader"
               >
                  <span class="visually-hidden">Loading...</span>
               </div>
               <i
                  class="bi bi-plus fs-4 align-middle"
                  *ngIf="!createButtonLoader"
               ></i>
               <span *ngIf="!listDetails?.id">{{
                  createButtonLoader ? "Creating" : "Create"
               }}</span>
               <span *ngIf="listDetails?.id">{{
                  createButtonLoader ? "Updating" : "Update"
               }}</span>
            </button>
         </div>

         <small
            class="alert w-100 d-flex gap-2 py-1 px-2 mt-4 mb-0 alert-danger"
            *ngIf="errorMsg"
         >
            {{ errorMsg }}
         </small>
      </form>
   </div>
</ng-container>

<ng-container *ngIf="isListCreated">
   <div class="modal-body px-3 py-4 p-lg-5 border-0">
      <div
         class="d-flex align-items-center justify-content-center success-image"
      >
         <svg
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M50 0C22.4299 0 0 22.4299 0 50C0 77.5701 22.4299 100 50 100C77.5701 100 100 77.5701 100 50C100 22.4299 77.5701 0 50 0ZM50 96.875C24.1529 96.875 3.125 75.8469 3.125 50C3.125 24.1531 24.1529 3.125 50 3.125C75.8471 3.125 96.875 24.1531 96.875 50C96.875 75.8469 75.8471 96.875 50 96.875ZM75.0691 32.8617C75.3621 33.1547 75.5267 33.5521 75.5267 33.9665C75.5267 34.3809 75.3621 34.7783 75.0691 35.0713L45.1506 64.9898C45.0055 65.135 44.8332 65.2501 44.6436 65.3286C44.454 65.4072 44.2508 65.4476 44.0456 65.4476C43.8404 65.4476 43.6372 65.4072 43.4476 65.3286C43.258 65.2501 43.0857 65.135 42.9406 64.9898L27.0793 49.1285C26.7863 48.8355 26.6217 48.438 26.6217 48.0236C26.6218 47.6092 26.7864 47.2118 27.0795 46.9188C27.3726 46.6257 27.77 46.4612 28.1844 46.4612C28.5988 46.4612 28.9963 46.6259 29.2893 46.9189L44.0457 61.6754L72.8594 32.8617C73.0045 32.7166 73.1767 32.6015 73.3663 32.523C73.5559 32.4445 73.7591 32.404 73.9643 32.404C74.1695 32.404 74.3726 32.4445 74.5622 32.523C74.7518 32.6015 74.924 32.7166 75.0691 32.8617Z"
               fill="#1BAD4E"
            />
         </svg>
      </div>
      <div class="my-4">
         <h4 class="mb-1 lh-base text-center">List Successfully Created !</h4>
         <p class="mb-0 lh-base text-center">
            Share your list with your friends for them to follow or add in their
            watchlist.
         </p>
      </div>
      <div
         class="d-flex align-items-center justify-content-center gap-3 gap-lg-4 form-btns-wrapper"
      >
         <button
            type="button"
            class="btn shadow-none d-flex align-items-center justify-content-center rounded-3 m-0 cancel-btn"
            (click)="activeModal.dismiss()"
         >
            Close
         </button>
         <button
            type="submit"
            class="btn shadow-none d-flex align-items-center justify-content-center gap-1 m-0 rounded-3 create-btn"
         >
            <span>View List</span>
         </button>
      </div>
   </div>
</ng-container>

<!-- <ng-container *ngIf="!isListCreated">
   <div
      class="modal-header border-0 p-3 p-lg-4 d-flex flex-column gap-1 gap-lg-2 align-items-stretch"
   >
      <div class="d-flex flex-grow-1 align-items-start justify-content-between">
         <h4 class="modal-title mb-0">{{ popupHeading }}</h4>
         <button
            type="button"
            class="btn close-popup-btn shadow-none p-1"
            aria-label="Close"
            (click)="activeModal.dismiss()"
         >
            <i class="bi bi-x-lg"></i>
         </button>
      </div>
      <p class="mb-0">
         Build a list to track your favourite companies & share with others.
      </p>
   </div>
   <div class="modal-body border-0">
      // Create New List
      <form
         *ngIf="data.name === 'create-list'"
         [formGroup]="newListForm"
         (ngSubmit)="createList()"
         (keydown)="preventEnterSubmit($event)"
      >
         <div class="form-controls-wrapper">
            <div class="list-input-group mb-3 mb-lg-4">
               <label for="listName" class="form-label">List Name</label>
               <input
                  type="text"
                  class="form-control shadow-none rounded-0"
                  id="listName"
                  placeholder="Enter your list name"
                  formControlName="listName"
               />
               <small
                  class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger"
                  *ngIf="
                     submitted &&
                     formControls['listName'].hasError('listNameExists')
                  "
               >
                  This name already exists. Please enter a different name
               </small>
               <small
                  class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger"
                  *ngIf="
                     submitted && formControls['listName'].hasError('required')
                  "
               >
                  List name is required
               </small>
            </div>
            <div class="mb-3 mb-lg-4">
               <app-chip-input
                  [inputLabel]="'Add Stocks'"
                  formControlName="stocks"
                  [multiple]="true"
                  [removable]="true"
                  [placeholder]="'Enter the symbol'"
               >
               </app-chip-input>
               <small
                  *ngIf="submitted && formControls['stocks'].errors?.minChips"
                  class="alert d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger"
               >
                  Minimum
                  {{
                     newListForm.controls["stocks"]?.errors?.minChips
                        ?.requiredLength
                  }}
                  stocks required, but got
                  {{
                     newListForm.controls["stocks"]?.errors?.minChips
                        ?.actualLength
                  }}.
               </small>
            </div>
            <div class="list-input-group">
               <label for="description" class="form-label">Description</label>
               <input
                  type="text"
                  class="form-control shadow-none rounded-0"
                  id="description"
                  placeholder="Enter your description"
                  formControlName="description"
               />
            </div>
         </div>

         <div
            class="d-flex align-items-center justify-content-center gap-3 gap-lg-4 form-btns-wrapper"
         >
            <button
               type="button"
               class="btn shadow-none d-flex align-items-center justify-content-center rounded-3 m-0 cancel-btn"
               (click)="activeModal.dismiss()"
            >
               Cancel
            </button>
            <button
               type="submit"
               class="btn shadow-none d-flex align-items-center justify-content-center gap-1 m-0 rounded-3 create-btn"
               [disabled]="createButtonLoader"
            >
               <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  *ngIf="createButtonLoader"
               >
                  <span class="visually-hidden">Loading...</span>
               </div>
               <i
                  class="bi bi-plus fs-4 align-middle"
                  *ngIf="!createButtonLoader"
               ></i>
               <span>{{ createButtonLoader ? "Creating" : "Create" }}</span>
            </button>
         </div>
      </form>

      // Edit List Details
      <form
         *ngIf="data.name === 'update-list'"
         [formGroup]="updateListForm"
         (ngSubmit)="updateList()"
      >
         <div class="form-controls-wrapper">
            <div class="list-input-group mb-3 mb-lg-4">
               <label for="listName" class="form-label">List Name</label>
               <input
                  type="text"
                  class="form-control shadow-none rounded-0"
                  id="listName"
                  placeholder="Enter your list name"
                  formControlName="listName"
               />
               <small
                  class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger"
                  *ngIf="
                     submitted &&
                     updateListForm.controls['listName'].hasError(
                        'listNameExists'
                     )
                  "
               >
                  This name already exists. Please enter a different name
               </small>
               <small
                  class="alert w-100 d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger"
                  *ngIf="
                     submitted &&
                     updateListForm.controls['listName'].hasError('required')
                  "
               >
                  List name is required
               </small>
            </div>
            <div class="list-input-group">
               <label for="description" class="form-label">Description</label>
               <input
                  type="text"
                  class="form-control shadow-none rounded-0"
                  id="description"
                  placeholder="Enter your description"
                  formControlName="description"
               />
            </div>
         </div>

         <div
            class="d-flex align-items-center justify-content-center gap-3 gap-lg-4 form-btns-wrapper"
         >
            <button
               type="button"
               class="btn shadow-none d-flex align-items-center justify-content-center rounded-3 m-0 cancel-btn"
               (click)="activeModal.dismiss()"
            >
               Cancel
            </button>
            <button
               type="submit"
               class="btn shadow-none d-flex align-items-center justify-content-center gap-1 m-0 rounded-3 create-btn"
               [disabled]="createButtonLoader"
            >
               <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  *ngIf="createButtonLoader"
               >
                  <span class="visually-hidden">Loading...</span>
               </div>
               
               <span>{{ createButtonLoader ? "Updating" : "Update" }}</span>
            </button>
         </div>
      </form>

      //Update Holdings Form
      <form
         *ngIf="data.name === 'update-holdings'"
         [formGroup]="updateHoldingsForm"
         (ngSubmit)="updateHoldings()"
         (keydown)="preventEnterSubmit($event)"
      >
         <div class="form-controls-wrapper">
            <div class="mb-3 mb-lg-4">
               <app-chip-input
                  [inputLabel]="'Update Stocks'"
                  formControlName="stocks"
                  [multiple]="true"
                  [removable]="true"
                  [placeholder]="'Enter the symbol'"
               >
               </app-chip-input>
               <small
                  *ngIf="
                     submitted &&
                     updateHoldingsForm.controls['stocks']?.errors?.minChips
                  "
                  class="alert d-flex gap-2 py-1 px-2 mt-1 mb-0 alert-danger"
               >
                  Minimum
                  {{
                     updateHoldingsForm.controls["stocks"]?.errors?.minChips
                        ?.requiredLength
                  }}
                  stocks required, but got
                  {{
                     updateHoldingsForm.controls["stocks"]?.errors?.minChips
                        ?.actualLength
                  }}.
               </small>
            </div>
         </div>

         <div
            class="d-flex align-items-center justify-content-center gap-3 gap-lg-4 form-btns-wrapper"
         >
            <button
               type="button"
               class="btn shadow-none d-flex align-items-center justify-content-center rounded-3 m-0 cancel-btn"
               (click)="activeModal.dismiss()"
            >
               Cancel
            </button>
            <button
               type="submit"
               class="btn shadow-none d-flex align-items-center justify-content-center gap-1 m-0 rounded-3 create-btn"
               [disabled]="createButtonLoader"
            >
               <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  *ngIf="createButtonLoader"
               >
                  <span class="visually-hidden">Loading...</span>
               </div>
               
               <span>{{ createButtonLoader ? "Updating" : "Update" }}</span>
            </button>
         </div>
      </form>
   </div>
</ng-container>

<ng-container *ngIf="isListCreated">
   <div class="modal-body px-3 py-4 p-lg-5 border-0">
      <div
         class="d-flex align-items-center justify-content-center success-image"
      >
         <svg
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M50 0C22.4299 0 0 22.4299 0 50C0 77.5701 22.4299 100 50 100C77.5701 100 100 77.5701 100 50C100 22.4299 77.5701 0 50 0ZM50 96.875C24.1529 96.875 3.125 75.8469 3.125 50C3.125 24.1531 24.1529 3.125 50 3.125C75.8471 3.125 96.875 24.1531 96.875 50C96.875 75.8469 75.8471 96.875 50 96.875ZM75.0691 32.8617C75.3621 33.1547 75.5267 33.5521 75.5267 33.9665C75.5267 34.3809 75.3621 34.7783 75.0691 35.0713L45.1506 64.9898C45.0055 65.135 44.8332 65.2501 44.6436 65.3286C44.454 65.4072 44.2508 65.4476 44.0456 65.4476C43.8404 65.4476 43.6372 65.4072 43.4476 65.3286C43.258 65.2501 43.0857 65.135 42.9406 64.9898L27.0793 49.1285C26.7863 48.8355 26.6217 48.438 26.6217 48.0236C26.6218 47.6092 26.7864 47.2118 27.0795 46.9188C27.3726 46.6257 27.77 46.4612 28.1844 46.4612C28.5988 46.4612 28.9963 46.6259 29.2893 46.9189L44.0457 61.6754L72.8594 32.8617C73.0045 32.7166 73.1767 32.6015 73.3663 32.523C73.5559 32.4445 73.7591 32.404 73.9643 32.404C74.1695 32.404 74.3726 32.4445 74.5622 32.523C74.7518 32.6015 74.924 32.7166 75.0691 32.8617Z"
               fill="#1BAD4E"
            />
         </svg>
      </div>
      <div class="my-4">
         <h4 class="mb-1 lh-base text-center">List Successfully Created !</h4>
         <p class="mb-0 lh-base text-center">
            Share your list with your friends for them to follow or add in their
            watchlist.
         </p>
      </div>
      <div
         class="d-flex align-items-center justify-content-center gap-3 gap-lg-4 form-btns-wrapper"
      >
         <button
            type="button"
            class="btn shadow-none d-flex align-items-center justify-content-center rounded-3 m-0 cancel-btn"
            (click)="activeModal.dismiss()"
         >
            Close
         </button>
         <button
            type="submit"
            class="btn shadow-none d-flex align-items-center justify-content-center gap-1 m-0 rounded-3 create-btn"
         >
            <span>View List</span>
         </button>
      </div>
   </div>
</ng-container> -->
