import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-form-wrapper',
  templateUrl: './steps-form-wrapper.component.html',
  styleUrls: ['./steps-form-wrapper.component.scss']
})
export class StepsFormWrapperComponent implements OnInit {
  @Input() formHeader: string = '';
  @Input() formDescription: string = '';
  

  constructor() { }

  ngOnInit(): void {
  }

  

}
