import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';


@Component({
  selector: 'app-footer-navigate',
  templateUrl: './footer-navigate.component.html',
  styleUrls: ['./footer-navigate.component.scss']
})
export class FooterNavigateComponent implements OnInit {

  public unclicked = true;
  public show: boolean = true;

  bannerTitle = ""
  bannerSubtitle = ""

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private router:Router,
    private firebaseConfigService: FirebaseRemoteConfigService,
  
 ) {
   
 }


 onBannerClick(){
  if(this.auth.checkRole('ANONYMOUS')){
    this.router.navigate(['authentication', 'sso-login'])
  }
  else if(this.auth.checkRole('FREEMIUM')){    
    this.router.navigate(['pages','stripe-premium-mobile'])
  }

  this.router.events.subscribe((val) => {
    this.updateBannerText()
  })
 }

  ngOnInit(): void {    
    this.updateBannerText()
  }

  async updateBannerText(){
    var config = await this.firebaseConfigService.getConfigDefault()

    if(this.auth.checkRole('ANONYMOUS')){
      this.bannerTitle = config['signup_banner_title']
      this.bannerSubtitle = config['signup_banner_text']
    }
    else if(this.auth.checkRole('FREEMIUM')){    
      this.bannerTitle = config['signup_banner_freemium_title']
      this.bannerSubtitle = config['signup_banner_freemium_text']
    }
  }

  checkRole(role:string):boolean
  {
     return this.auth.checkRole(role);
  }
}
