import { Component, Input, OnInit } from '@angular/core';
import { UsageLimitService } from '../../services/usage-limit.service';
import { Subject, takeUntil } from 'rxjs';
import { UsageInfoResponse } from 'src/app/models/user/usage-info.model';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-limit-reached-card',
  templateUrl: './limit-reached-card.component.html',
  styleUrls: ['./limit-reached-card.component.scss']
})
export class LimitReachedCardComponent implements OnInit {
   @Input() permissionKey: string = '';
   @Input() isCompInsidePopup: boolean = false;
   @Input() title: string = '';
   @Input() message: string = '';
   @Input() canUpgrade: boolean = true;

   private destroy$: Subject<void> = new Subject<void>();
   isLoading: boolean = false;
   usageCount: number = 0;
   limit: number = 0;
   displayName: string = '';
   accessDuration: string = '';

   constructor(
      private usageLimitService: UsageLimitService,
      private sharedService: SharedService,
      private router: Router
   ) { }

   ngOnInit(): void {
      this.fetchDetailsComplainceReport()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   closePopupAndNavigate(route: string) {
      this.closeLimitPopup();
      this.router.navigate([route]);
   }

   fetchDetailsComplainceReport() {
      this.isLoading = true;
      this.usageLimitService.getUsageInfo().pipe(takeUntil(this.destroy$)).subscribe({
         next: (res: any) => {
            if(res.status) {
               const data = res.data.find((feature: any) => feature.name === this.permissionKey)
               if(data) {
                  this.usageCount = data.count
                  this.limit = data.limit
                  this.displayName = data.display_name
                  this.accessDuration = data.duration
               }
            }
         },
         error: (error) => {
            console.error(error);
            this.isLoading = false;
         },
         complete: () => {
            this.isLoading = false;
         },
      })
   }

   closeLimitPopup() {
      this.sharedService.closeLimitReachedPopup();
   }

   fetchUsageInfo() {
      this.isLoading = true;
      this.usageLimitService.getUsageInfo<UsageInfoResponse>().pipe(takeUntil(this.destroy$)).subscribe({
         next: (res) => {
            if(res.status) {

            }
         },
         error: (error) => {
            console.error(error);
            this.isLoading = false;
         },
         complete: () => {
            this.isLoading = false;
         },
      })
   }

}
