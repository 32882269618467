import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
   selector: 'app-collections-section',
   templateUrl: './collections-section.component.html',
   styleUrls: ['./collections-section.component.scss']
})
export class CollectionsSectionComponent implements OnInit {
   private destroy$ = new Subject<void>();

   selectedCountry!: string;

   constructor(private countryService: CountryService) { }

   ngOnInit(): void {
      this.countryService.selectedCountry$
         .pipe(takeUntil(this.destroy$))
         .subscribe((country) => {
            this.selectedCountry = country.countryCode;
         });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

}
