import { createReducer, on } from '@ngrx/store';
import { PortfolioActions } from './portfolio.actions';
import { PortfolioAccount } from 'src/app/features/our-portfolio/models/account-list-response.model';


export interface PortfolioState {
  accounts: PortfolioAccount[];
  selectedAccount: string | null;
  loading: boolean;
  error: string | null;
}

export const initialState: PortfolioState = {
  accounts: [],
  selectedAccount: null,
  loading: true,
  error: null
};

export const portfolioReducer = createReducer(
  initialState,
  on(PortfolioActions.loadAccounts, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(PortfolioActions.loadAccountsSuccess, (state, { accounts }) => ({
    ...state,
    accounts,
    selectedAccount: state.selectedAccount || (accounts.length > 0 ? accounts[0].id : null),
    loading: false
  })),
  on(PortfolioActions.loadAccountsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false
  })),
  on(PortfolioActions.setSelectedAccount, (state, { accountId }) => ({
    ...state,
    selectedAccount: accountId
  }))
);
