import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";

@Injectable({
   providedIn: 'root'
})

export class UsageLimitService {
   url: string = environment.apiUrl

   constructor(private http: HttpClient) {}

   getDetailedCOmplianceReport<T>(ticker: string, type: string): Observable<T> {
      return this.http.post<T>(this.url + '/api/user/get-detailed-compliance-report', {
         ticker,
         type
      })
   }

   getUsageInfo<T>(): Observable<T> {
      return this.http.get<T>(environment.apiUrl + '/api/user/usage-info')
   }
}
