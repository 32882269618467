import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "etfHoldingBy"
})
export class EtfHoldingsByPipe implements PipeTransform {
  transform(array: any, field: string, direction: boolean, showAll: boolean): any[] {
    array.sort((a: any, b: any) => {
      if (direction) {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      }
      else {
        if (a[field] < b[field]) {
          return 1;
        } else if (a[field] > b[field]) {
          return -1;
        } else {
          return 0;
        }
      }

    });
    if (showAll) {
      return array;
    } else {
      return array.slice(0, 10)
    }


  }
}