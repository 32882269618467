import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PaywallPopupComponent } from '../paywall-popup/paywall-popup.component';
import { PremiumSamplePopupComponent } from '../premium-sample-popup/premium-sample-popup.component';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { Subscription } from 'rxjs';
import { ConditionalRenderingService } from '../../services/conditional-rendering.service';
import { Router } from '@angular/router';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { AccessControlService } from '../../services/access-control.service';

@Component({
  selector: 'app-feature-locked-card',
  templateUrl: './feature-locked-card.component.html',
  styleUrls: ['./feature-locked-card.component.scss']
})
export class FeatureLockedCardComponent implements OnInit {
  @Input() heading: string = '';
  @Input() text: string = '';
  @Output() tryNowClicked: EventEmitter<void> = new EventEmitter();;
  userRole: string|null = null;
  @Input() showSampleBtn: boolean = true;
  @Input() disableTryNow: boolean = false;
  @Input() componentPage:string = '';

  paywallModalRef: any;
  sampleModalRef: any;
  currentUrl: any;
  private subscriptions: Subscription[] = [];
  @Output() viewSampleClicked = new EventEmitter<void>();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private conditionalRenderingService: ConditionalRenderingService,
    private analyticsUtils:AnalyticsUtils,
    private sharedService: SharedService,
    private authService: AuthService,
    public accessControlService: AccessControlService
  ) {}


  ngOnInit(): void {
   this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      this.userRole = data.role
   }))
  }

  ngOnDestroy(): void {
   if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((item) => {
         item.unsubscribe();
      });
   }
}

  openPaywallModal() {
    this.analyticsUtils.feature_upgrade_tapped(this.componentPage)
    this.sharedService.openUpgradePopup('report')
    // this.paywallModalRef = this.modalService.open(PaywallPopupComponent, {
    //   centered: true,
    //   modalDialogClass: 'paywall-popup-modal',
    //   size: 'xl'
    // });
  }

  openLoginPopup() {
    this.authService.openAuthPopup()
  }

  closePaywallModal() {
    this.paywallModalRef.close();
  }

  openSampleModal() {
    this.sampleModalRef = this.modalService.open(PremiumSamplePopupComponent, {
      centered: true,
      size: 'lg',
      // windowClass : "x-large-modal-width"
    });
  }

  openReportSample() {
    this.sampleModalRef = this.modalService.open(PremiumSamplePopupComponent, {
      centered: true,
      size: 'xl',
      // windowClass : "x-large-modal-width"
    });
  }

  closeSampleModal() {
    this.sampleModalRef.close();
  }

  viewSample() {

    if(this.heading === 'Shariah Compliance Report'){
      this.analyticsUtils.sample_shariah_report_tapped()
    }
    if(this.heading === 'Stock Forecast'){
      this.analyticsUtils.sample_forecast_tapped()
    }
    if(this.heading === 'Investment Checklist'){
      this.analyticsUtils.sample_investment_checklist_tapped()
    }
    this.conditionalRenderingService.setComponentType('componentType');

    if(this.heading === 'Shariah Compliance Report') {
      this.openReportSample()
    } else {
      this.openSampleModal()
    }

    this.viewSampleClicked.emit();
  }

  tryNow() {
   if(this.userRole !== 'ANONYMOUS') {
      this.analyticsUtils.sample_purification_tapped()
      this.tryNowClicked.emit();
   } else {
      this.accessControlService.accessDenied();
   }
  }


  stockCollectionSignupTapped(){
    if(this.router.url === '/cabinet/dashboard'){
      this.analyticsUtils.stock_collection_signup_tapped('homescreen')
    }
    if(this.router.url === '/cabinet/overall-buckets'){
      this.analyticsUtils.stock_collection_signup_tapped('stock_collections')
    }

  }



}
