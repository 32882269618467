import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
   selector: 'app-share-links-popup',
   templateUrl: './share-links-popup.component.html',
   styleUrls: ['./share-links-popup.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareLinksPopupComponent implements OnInit {
   isShareLinksVisible = false;
   tooltipText = 'Copy Link';
   @Input() buttonType: 'icon' | 'outlined' | 'filled' = 'outlined';
   @Input() dropdownPosition: 'bottom-end' | 'bottom-start' | 'left-top' | 'left-bottom' | 'top-start' | 'top-end' | 'right-top' | 'right-bottom' = 'left-top';
   shareUrl = '';

   constructor(private router: Router) { }

   ngOnInit(): void {
      const url = new URL(window.location.origin + this.router.url);
      url.searchParams.append('utm_source', 'website');
      url.searchParams.append('utm_medium', 'plg');
      url.searchParams.append('utm_campaign', 'socialshares_feb12');
      this.shareUrl = url.toString();
   }

   toggleVisibility() {
      this.isShareLinksVisible = !this.isShareLinksVisible
   }

   open() {
      this.isShareLinksVisible = true;
   }

   close() {
      this.isShareLinksVisible = false;
   }

   @HostListener('document:click', ['$event'])
   onDocumentClick(event: MouseEvent) {
      this.close();
   }

   onCopySuccess(tooltip: any): void {
      this.tooltipText = 'Copied';
      tooltip.open();

      setTimeout(() => {
         tooltip.close();
         this.tooltipText = 'Copy Link';
      }, 2000);
   }

   @HostBinding('class')
   get type(): string {
      switch (this.buttonType) {
         case 'outlined':
            return 'outlined-btn';
         case 'filled':
            return 'filled-btn';
         default:
            return 'icon-btn';
      }
   }

   @HostBinding('class')
   get position(): string {
      switch (this.dropdownPosition) {
         case 'bottom-end':
            return 'on-bottom-end';
         case 'bottom-start':
            return 'on-bottom-start';
         case 'left-top':
            return 'on-left-top';
         case 'left-bottom':
            return 'on-left-bottom';
         case 'top-start':
            return 'on-top-start';
         case 'top-end':
            return 'on-top-end';
         case 'right-top':
            return 'on-right-top';
         case 'right-bottom':
            return 'on-right-bottom';
         default:
            return 'on-bottom-end';
      }
   }

}
