<article class="br-10 text-center">
   <ng-container *ngIf="isCompInsidePopup">
      <button class="close-popup-btn text-black p-1 rounded-3 btn shadow-none" (click)="closeLimitPopup()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
   </ng-container>

   <img src="/assets/images/limit-reach-icon.png" class="img-fluid mx-auto" alt="Icon">
   <h4 class="fw-600 text-black" *ngIf="title">
      {{title}}
   </h4>
   <h4 class="fw-600 text-black" *ngIf="!title">
      Plan limit reached! <br> Upgrade for unlimited access
   </h4>
   <p class="mb-0" *ngIf="message">
      {{message}}
   </p>
   <p class="mb-0" *ngIf="!message && (permissionKey && !isLoading)">
      <span class="fw-600">{{usageCount}}/{{limit}}</span> {{displayName}} accessed<span *ngIf="accessDuration"> in {{accessDuration === 'monthly' ? 'this month' : 'today'}}</span>. <br>
      Please upgrade your plan for unlimited access.
   </p>
   <div class="skeleton-wrapper bg-white p-3" *ngIf="isLoading && !title">
      <ngx-skeleton-loader count="2" appearance="line" animation="progress" [theme]="{ height: '20px', width: '50%', margin: '0px'}"></ngx-skeleton-loader>
   </div>
   <a *ngIf="canUpgrade" class="btn mx-auto upgrade-btn shadow-none gradient-btn text-white rounded-3 px-4 py-2" (click)="closePopupAndNavigate('/pricing')">
      <!-- Upgrade & Unlock! -->
      <img src="/assets/icons/lock-white.svg" width="16" height="16" alt="lock image" class="img-fluid">
      <span>Unlock</span>
   </a>
   <a *ngIf="canUpgrade" class="d-flex usage-link mx-auto align-items-center gap-2 cursor-pointer" (click)="closePopupAndNavigate('/user/usage-details')">
      <span>View Your Plan Limits & Current Usage</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
         <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
   </a>
</article>
