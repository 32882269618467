import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shariahCompliantStatus'
})
export class ShariahCompliantStatusPipe implements PipeTransform {
  transform(value: string): any {
    if (value === 'COMPLIANT') {
      return { className: 'compliant-status' };
    } else if (value === 'NON_COMPLIANT') {
      return { className: 'non-compliant-status' };
    }
    return { className: 'unknown-status' }; // Default class
  }
}
