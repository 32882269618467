import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, take } from 'rxjs';
import { ReferralService } from '../../services/referral.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SubscriptionService } from '../../services/subscription.service';
@Component({
   selector: 'app-join-referral-popup',
   templateUrl: './join-referral-popup.component.html',
   styleUrls: ['./join-referral-popup.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class JoinReferralPopupComponent implements OnInit {
   private destroy$ = new Subject<void>();
   isLoading: boolean = false;
   isLoggedIn: boolean = false;
   sanitizedIframeSrc!: SafeResourceUrl;
   iframeSrc: string = 'https://getnotionembed.com/embed/9c45edbc-d4ff-4505-a8ed-709d547ab171?mode=Light';
   alreadyJoined: boolean = false;

   constructor(
      private router: Router,
      private modalService: NgbModal,
      private referralService: ReferralService,
      private toaster: ToastrService,
      private authService: AuthService,
      private cd: ChangeDetectorRef,
      public sanitizer: DomSanitizer
   ) { }

   ngOnInit(): void {
      SubscriptionService.subscriptionData.subscribe((data) => {
         this.isLoggedIn = this.authService.isLoggedIn();
         if (this.isLoggedIn) {
            this.referralService.checkFirstprompter().pipe(take(1)).subscribe((response: any) => {
               this.alreadyJoined = response.data.status;
               this.cd.detectChanges();
            })
         }
      })
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   navigate() {
      this.router.navigate(['/ambassador-program'])
   }

   openJoinProgramPopup(content: TemplateRef<any>) {
      if (!this.isLoggedIn) {
         this.authService.openAuthPopup();
         return
      }

      this.modalService.open(content, {
         centered: true,
         backdrop: 'static',
         size: 'lg',
         windowClass: 'global-modal',
      });
      this.sanitizedIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
   }

   openConfirmationPopup(joinProgramConfirmation: TemplateRef<any>) {
      if (!this.isLoggedIn) {
         this.authService.openAuthPopup();
         return
      }
      this.modalService.open(joinProgramConfirmation, {
         centered: true,
         backdrop: 'static',
         size: 'lg',
         windowClass: 'global-modal',
      });
   }

   getFirstPrompter() {
      this.isLoading = true
      this.referralService.fetchFirstprompter().pipe(take(1)).subscribe({
         next: (response: any) => {
            if (response.status) {
               this.isLoading = false
               this.cd.detectChanges();

               this.modalService.dismissAll();
               setTimeout(() => {
                  const redirectUrl = response.iframe_login.iframe_login_url;
                  window.open(redirectUrl, '_blank');
               }, 100);
            } else {
               this.toaster.error('Something went wrong. Please try again!')
            }
         },
         error: (error) => {
            this.toaster.error('Something went wrong. Please try again!')
            console.error('API Error:', error);
            this.isLoading = false
            this.cd.detectChanges();
         }
      })
   }

}
