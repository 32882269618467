import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartData, ChartDataSets, ChartOptions, ChartTooltipCallback, ChartTooltipItem, TimeUnit } from 'chart.js';
import { BaseChartDirective, Label, PluginServiceGlobalRegistrationAndOptions, ThemeService } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SumPipe } from 'src/app/shared/pipes/sum.pipe';
import { ApiService } from 'src/app/shared/services/api.service';
// import { WebsoketService } from 'src/app/shared/services/websoket.service';

@Component({
   selector: 'app-progres-chart',
   templateUrl: './progres-chart.component.html',
   styleUrls: ['./progres-chart.component.scss']
})

export class ProgresChartComponent implements OnInit, OnDestroy, AfterViewInit {
   width: number
   height: number
   gradient: any

   private subscriptions: Subscription[]

   public chartsLoader: boolean;
   public selectedTab: string;
   public lastTime: Date;
   public currentPrice: number;
   public lastDate: Date;
   public priceChange: any = 0;
   public priceChangePercent: any = 0;
   public hasData: boolean = false;

   @ViewChild('chart', { read: ElementRef }) pieChart: ElementRef;
   @ViewChild(BaseChartDirective) chart: BaseChartDirective;

   @Input() set screeningInfo(value: any) {
      if (value) {


         this.chartData[0].data = [value.halal, value.questionable, value.notHalal];
         this.hasData = true;
      }
   }



   public chartData: ChartDataSets[] = [
      {
         data: [],
         backgroundColor: [
            '#39A939',
            '#F6AF0D',
            '#C70707'
         ]
         // borderColor: '#39a939',
         // borderWidth: 2,
         // lineTension: 0.3,
         // backgroundColor: 'transparent',
         // pointRadius: 10,
         // pointBorderColor: 'transparent',
         // pointBackgroundColor: 'transparent',
         // pointHoverBorderColor: '#39a939'
      }
   ]

   public chartLabels: Label[] = ['Halal','Doubtful','Not Halal']

   public chartOptions: ChartOptions = {
      // responsive: true
   } as ChartOptions


   constructor(
      private apiService: ApiService,
      private toaster: ToastrService,
      // private ws: WebsoketService,
      private datePipe: DatePipe,
      private sumPipe: SumPipe
   ) {
      this.subscriptions = []
      this.width = 0
      this.height = 0
      this.gradient = null
      this.chartsLoader = false
      this.selectedTab = '5Y'
      this.lastTime = new Date()
      this.currentPrice = 0
      this.lastDate = new Date()

      this.pieChart = new ElementRef({ static: false })
      this.chart = new BaseChartDirective(this.pieChart, {} as ThemeService)
   }
   ngAfterViewInit(): void {
   }



   ngOnInit(): void {

   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach(item => {
            item.unsubscribe()
         })
      }

   }
}
