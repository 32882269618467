<ng-container>
   <div [ngClass]="allHoldings.length > 0 ? '' : 'p-top'">
      <label class="heading-text mb-2">Type of Investment:</label>
      <div
         class="d-flex align-items-center justify-content-start gap-2 gap-lg-3"
      >
         <div class="radio-wrapper">
            <input
               class="investment-radio long-radio rounded shadow-none"
               type="radio"
               name="investmentType"
               id="shortTerm"
               value="short"
               [(ngModel)]="selectedInvestmentType"
               (change)="updateSelectedInvestmentType('shortTerm')"
            />
            <label class="radio-label" for="shortTerm">Short Term</label>
         </div>
         <div class="radio-wrapper">
            <input
               class="investment-radio long-radio rounded shadow-none"
               type="radio"
               name="investmentType"
               id="longTerm"
               value="long"
               [(ngModel)]="selectedInvestmentType"
               (change)="updateSelectedInvestmentType('longTerm')"
            />
            <label class="radio-label" for="longTerm">Long Term</label>
         </div>
         <div class="radio-wrapper">
            <input
               class="investment-radio long-radio rounded shadow-none"
               type="radio"
               name="investmentType"
               id="mixed"
               value="mixed"
               [(ngModel)]="selectedInvestmentType"
               (change)="updateSelectedInvestmentType('mixed')"
            />
            <label class="radio-label" for="mixed">Mixed</label>
         </div>
      </div>
      <div
         class="d-flex align-items-center gap-2 step-info-wrapper"
         (click)="openZakatInvestmentInfoModal()"
      >
         <svg
            class="flex-shrink-0 info-svg"
            width="16"
            height="16"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M10.5 15C10.7833 15 11.0208 14.9042 11.2125 14.7125C11.4042 14.5208 11.5 14.2833 11.5 14V10C11.5 9.71667 11.4042 9.47917 11.2125 9.2875C11.0208 9.09583 10.7833 9 10.5 9C10.2167 9 9.97917 9.09583 9.7875 9.2875C9.59583 9.47917 9.5 9.71667 9.5 10V14C9.5 14.2833 9.59583 14.5208 9.7875 14.7125C9.97917 14.9042 10.2167 15 10.5 15ZM10.5 7C10.7833 7 11.0208 6.90417 11.2125 6.7125C11.4042 6.52083 11.5 6.28333 11.5 6C11.5 5.71667 11.4042 5.47917 11.2125 5.2875C11.0208 5.09583 10.7833 5 10.5 5C10.2167 5 9.97917 5.09583 9.7875 5.2875C9.59583 5.47917 9.5 5.71667 9.5 6C9.5 6.28333 9.59583 6.52083 9.7875 6.7125C9.97917 6.90417 10.2167 7 10.5 7ZM10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM10.5 18C12.7333 18 14.625 17.225 16.175 15.675C17.725 14.125 18.5 12.2333 18.5 10C18.5 7.76667 17.725 5.875 16.175 4.325C14.625 2.775 12.7333 2 10.5 2C8.26667 2 6.375 2.775 4.825 4.325C3.275 5.875 2.5 7.76667 2.5 10C2.5 12.2333 3.275 14.125 4.825 15.675C6.375 17.225 8.26667 18 10.5 18Z"
            />
         </svg>
         <div>
            <p class="mb-0 step-info-text">
               How this impacts your Zakat calculation?
            </p>
         </div>
      </div>
      <div class="holdings-container">
         <label class="heading-text mb-2">Your Holdings:</label>
         <div class="holdings-btns-wrapper">
            <button
               class="btn text-white shadow-none rounded border-0 justify-content-between holding-btn"
               type="button"
               [disabled]="importHoldingsLoader"
               (click)="confirmImportUserHoldings()"
            >
               <div
                  class="d-flex justify-content-center"
                  *ngIf="importHoldingsLoader"
               >
                  <div class="spinner-border text-white" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
               </div>
               <div
                  class="d-flex align-items-stretch"
                  *ngIf="!importHoldingsLoader"
               >
                  <div class="btn-text text-start">
                     Import <br />
                     Your Holdings
                  </div>
                  <svg
                     width="20"
                     height="16"
                     viewBox="0 0 22 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M5.5 16C3.98333 16 2.6875 15.475 1.6125 14.425C0.5375 13.375 0 12.0916 0 10.575C0 9.27495 0.391667 8.11662 1.175 7.09995C1.95833 6.08328 2.98333 5.43328 4.25 5.14995C4.63333 3.79995 5.34583 2.66662 6.3875 1.74995C7.42917 0.833285 8.63333 0.274951 10 0.0749512V8.14995L9.1 7.27495C8.91667 7.09162 8.6875 6.99995 8.4125 6.99995C8.1375 6.99995 7.9 7.09995 7.7 7.29995C7.51667 7.48328 7.425 7.71662 7.425 7.99995C7.425 8.28329 7.51667 8.51662 7.7 8.69995L10.3 11.3C10.5 11.5 10.7333 11.6 11 11.6C11.2667 11.6 11.5 11.5 11.7 11.3L14.3 8.69995C14.4833 8.51662 14.5792 8.28745 14.5875 8.01245C14.5958 7.73745 14.5 7.49995 14.3 7.29995C14.1167 7.11662 13.8875 7.02078 13.6125 7.01245C13.3375 7.00412 13.1 7.09162 12.9 7.27495L12 8.14995V0.0749512C13.7167 0.308285 15.1458 1.07912 16.2875 2.38745C17.4292 3.69578 18 5.23328 18 6.99995C19.15 7.13328 20.1042 7.62912 20.8625 8.48745C21.6208 9.34579 22 10.35 22 11.5C22 12.75 21.5625 13.8125 20.6875 14.6875C19.8125 15.5625 18.75 16 17.5 16H5.5Z"
                        fill="white"
                     />
                  </svg>
               </div>
            </button>
            <button
               class="btn d-flex align-items-stretch text-black shadow-none rounded justify-content-between stock-btn"
               type="button"
               (click)="openAddHoldingModal()"
            >
               <div class="btn-text text-start">Add <br />Stock / Fund</div>
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M9 11V14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14V11H14C14.2833 11 14.5208 10.9042 14.7125 10.7125C14.9042 10.5208 15 10.2833 15 10C15 9.71667 14.9042 9.47917 14.7125 9.2875C14.5208 9.09583 14.2833 9 14 9H11V6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6V9H6C5.71667 9 5.47917 9.09583 5.2875 9.2875C5.09583 9.47917 5 9.71667 5 10C5 10.2833 5.09583 10.5208 5.2875 10.7125C5.47917 10.9042 5.71667 11 6 11H9ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
                     fill="#53877C"
                  />
               </svg>
            </button>
         </div>
      </div>
      <ul
         class="holdings-list-wrapper ps-0 mb-0"
         *ngIf="allHoldings.length > 0"
      >
         <li
            class="single-holding-item d-block"
            *ngFor="let stock of allHoldings"
         >
            <div class="d-flex align-items-center justify-content-between mb-2">
               <h6 class="mb-0 holding-name">{{ stock.ticker }}</h6>
               <button
                  class="holding-remove-btn btn lh-1 p-0"
                  (click)="removeStock(stock)"
               >
                  Remove
               </button>
            </div>
            <div class="holdings-inputs-wrapper">
               <input
                  type="text"
                  inputmode="numeric"
                  autocomplete="off"
                  class="form-control p-2 shadow-none"
                  id=""
                  [(ngModel)]="stock.no_of_shares"
                  placeholder=""
                  onlyNumbersAndDot
               />
               <div class="d-flex align-items-center currency-input">
                  <span class="d-inline-block lh-1 currency">{{
                     selectedCurrency
                  }}</span>
                  <input
                     type="text"
                     inputmode="numeric"
                     autocomplete="off"
                     class="form-control py-2 pe-2 ps-0 shadow-none"
                     id="shares"
                     [(ngModel)]="stock.current_market_value"
                     placeholder=""
                     onlyNumbersAndDot
                  />
               </div>
               <ng-select
                  class="custom-stock-select"
                  labelForId="investment"
                  [searchable]="false"
                  [clearable]="false"
                  [(ngModel)]="stock.investment_type"
               >
                  <ng-option
                     *ngFor="let investment of investments"
                     [value]="investment.id"
                     >{{ investment.name }}</ng-option
                  >
               </ng-select>
            </div>
         </li>
      </ul>
      <!-- <h5 *ngIf="otherHoldings.length > 0">Other Investments</h5>
      <ul class="holdings-list-wrapper ps-0 mb-0" *ngIf="otherHoldings.length > 0">
         <li class="single-holding-item d-block" *ngFor="let other of otherHoldings">
            <div class="d-flex align-items-center justify-content-between mb-2">
               <h6 class="mb-0 holding-name">{{other.name}}</h6>
               <button class="holding-remove-btn btn lh-1 p-0" (click)="removeOther(other)">Remove</button>
            </div>
            <div class="holdings-inputs-wrapper">
               <div class="d-flex align-items-center currency-input">
                  <span class="d-inline-block lh-1 currency">{{ selectedCurrency }}</span>
                  <input type="text" inputmode="numeric" autocomplete="off" class="form-control py-2 pe-2 ps-0 shadow-none" id="shares" [(ngModel)]="other.amount" placeholder="" onlyNumbersAndDot>
               </div>
               <ng-select class="custom-stock-select" labelForId="investment" [searchable]="false" [clearable]="false" [ngModel]="other.investment_type">
                  <ng-option *ngFor="let investment of investments" [value]="investment.id">{{investment.name}}</ng-option>
               </ng-select>
            </div>
         </li>
      </ul> -->
   </div>
</ng-container>

<ng-template #addStocksHoldingContent let-modal>
   <form
      class="modal-wrapper"
      [formGroup]="addStocksForm"
      (ngSubmit)="addStock()"
   >
      <div
         class="investment-modal-header bg-white d-flex align-items-center justify-content-between"
      >
         <h5 class="mb-0 text-start">Add Holding</h5>
         <button
            class="btn lh-1 p-0 shadow-none"
            (click)="closeAddHoldingModal()"
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               height="24"
               width="24"
               viewBox="0 0 384 512"
            >
               <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
               />
            </svg>
         </button>
      </div>
      <div class="investment-modal-body p-0 bg-transparent">
         <div class="form-input-wrapper">
            <label for="symbol" class="form-label mb-2">Select Symbol:</label>
            <div class="d-flex align-items-center px-0 form-control-container">
               <input
                  type="text"
                  id="symbol"
                  class="form-control bg-transparent search-control shadow-none"
                  [ngClass]="{
                     'invalid-class':
                        addStocksForm.get('symbol')?.invalid && showTickerError
                  }"
                  formControlName="symbol"
                  [(ngModel)]="symbol"
                  [resultTemplate]="rt"
                  [ngbTypeahead]="search"
                  [resultFormatter]="formatSymbol"
                  [inputFormatter]="inputFormatBandListValue"
                  #instance="ngbTypeahead"
                  [editable]="false"
                  placeholder=""
               />
            </div>

            <div *ngIf="showTickerError">
               <div
                  class="invalid-text"
                  *ngIf="addStocksForm.get('symbol')?.errors?.required"
               >
                  <span>Symbol is required</span>
               </div>
            </div>
         </div>
         <div class="form-input-wrapper">
            <label for="sharesAmount" class="form-label mb-2"
               >Number of Shares:</label
            >
            <div
               class="d-flex align-items-center p-0 form-control-container"
               [ngClass]="{
                  'invalid-class':
                     addStocksForm.get('numberOfShares')?.invalid &&
                     showSharesError
               }"
            >
               <input
                  type="text"
                  inputmode="numeric"
                  autocomplete="off"
                  class="form-control bg-transparent shadow-none"
                  id="sharesAmount"
                  formControlName="numberOfShares"
                  placeholder=""
                  onlyNumbersAndDot
               />
            </div>

            <div *ngIf="showSharesError">
               <div
                  class="invalid-text"
                  *ngIf="addStocksForm.get('numberOfShares')?.errors?.required"
               >
                  <span>Shares are required</span>
               </div>
            </div>
         </div>
         <div class="form-input-wrapper mb-4">
            <label for="totalSharesAmount" class="form-label mb-2"
               >Total Value of Shares:</label
            >
            <div class="d-flex align-items-center form-control-container">
               <span class="d-inline-block lh-1 currency">{{
                  selectedCurrency
               }}</span>
               <input
                  type="text"
                  inputmode="numeric"
                  autocomplete="off"
                  class="form-control bg-transparent ps-0 shadow-none"
                  id="totalSharesAmount"
                  formControlName="totalValueOfShares"
                  placeholder=""
                  onlyNumbersAndDot
               />

               <div
                  *ngIf="autoFetchLoading"
                  class="spinner-border spinner-border-sm text-success autofetch-spinner"
                  role="status"
               >
                  <span class="sr-only">Loading...</span>
               </div>
            </div>
            <div
               *ngIf="
                  isStockUnavailable &&
                  addStocksForm.get('totalValueOfShares')?.errors?.required
               "
            >
               <div class="invalid-text">
                  <span>{{ isStockUnavailable }}</span>
               </div>
            </div>
            <small *ngIf="isPriceAutoFetched">Price auto-fetched based on latest market value</small>
            <!-- <p (click)="autofetchPrice()" class="text-autofetch">
               Auto fetch from current market price (1-day delayed)
            </p> -->
         </div>
         <div class="form-input-wrapper mb-4">
            <label for="totalSharesAmount" class="form-label mb-2"
               >Type of Investment:</label
            >
            <ng-select
               class="custom-stock-select"
               labelForId="totalSharesAmount"
               [searchable]="false"
               [clearable]="false"
               formControlName="investmentType"
               [ngModel]="formInvestmentType"
            >
               <ng-option
                  *ngFor="let investment of investments"
                  [value]="investment.id"
                  >{{ investment.name }}</ng-option
               >
            </ng-select>
         </div>
      </div>
      <div class="investment-modal-btns">
         <button
            class="btn shadow-none modal-btn cancel-btn"
            (click)="closeAddHoldingModal()"
         >
            CANCEL
         </button>
         <button
            class="btn shadow-none modal-btn add-btn"
            [disabled]="!addStocksForm.valid"
         >
            ADD HOLDING
         </button>
      </div>
   </form>
</ng-template>

<ng-template #rt let-r="result" let-t="term">
   <p
      class="mb-0 stock-symbol lh-sm"
      style="font-size: 0.875rem; font-weight: 600"
      [innerHTML]="r.stockName | highlightSearch : r.stockName"
   ></p>
   <p
      class="mb-0 stock-name lh-sm"
      style="font-size: 0.75rem; font-weight: 400"
   >
      {{ r.companyName }}
   </p>
</ng-template>

<ng-template #zakatInvestmentInfoModalContent let-modal>
   <div class="modal-wrapper">
      <div
         class="investment-modal-header bg-white d-flex align-items-center justify-content-between"
      >
         <h5 class="mb-0 text-start">
            Short-Term / Long-Term Calculation Methodology
         </h5>
         <button
            class="btn lh-1 p-0 shadow-none"
            (click)="closeZakatInvestmentInfoModal()"
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               height="24"
               width="24"
               viewBox="0 0 384 512"
            >
               <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
               />
            </svg>
         </button>
      </div>
      <div class="investment-modal-body">
         <div class="text-container">
            <h4 class="text-black">Short-Term (For Trade)</h4>
            <h6 class="text-black">Current market value of shares * 2.5%</h6>
            <p class="mb-0">
               These investments are the one which you do with the intent of
               trading. Usually this are the one's which one holds for less than
               1 year. These holdings are treated as liquid/cash and zakat is to
               be paid as 2.5% on the total amount.
            </p>
         </div>
         <div>
            <h4 class="text-black">Long-Term (For Capital Appreciation)</h4>
            <h6 class="text-black">
               Current assets of the company / Shares outstanding of the company
               * Number of long term shares held
            </h6>
            <p class="mb-0">
               These investments are the one which you do with the intent of
               long-term capital appreciation. Usually this are the one’s which
               one holds for more than 1 year. Zakat on these shares are based
               on the underlying Zakatable assets of the company.
            </p>
         </div>
      </div>
   </div>
</ng-template>

<ng-template #otherHoldingsContent let-modal>
   <div class="modal-wrapper">
      <div
         class="investment-modal-header pb-3 bg-white d-flex align-items-center justify-content-center"
      >
         <h5 class="mb-0 text-start">Lorem ipsum dolor site</h5>
         <!-- <button class="btn lh-1 p-0 shadow-none" (click)="closeOtherHoldingsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512">
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
            </svg>
         </button> -->
      </div>
      <div class="investment-modal-body p-0 bg-transparent">
         <div class="text-container text-center mb-4">
            <p class="mb-0">
               Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
               vel, unde dolores harum veniam earum!
            </p>
         </div>
      </div>
      <div class="other-modal-btns">
         <button
            class="btn shadow-none mx-auto modal-btn add-btn"
            (click)="closeOtherHoldingsModal()"
         >
            OK
         </button>
      </div>
   </div>
</ng-template>

<ng-template #importHoldingsConfirmation let-modal>
   <div class="modal-wrapper">
      <div
         class="investment-modal-header pb-3 bg-white d-flex align-items-center justify-content-center"
      >
         <h5 class="mb-0 text-start">Import Confirmation</h5>
         <!-- <button class="btn lh-1 p-0 shadow-none" (click)="closeOtherHoldingsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512">
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
            </svg>
         </button> -->
      </div>
      <div class="investment-modal-body p-0 bg-transparent">
         <div class="text-container text-center mb-4">
            <p class="mb-0">Import holdings from linked portfolio(s)?</p>
         </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
         <button
            class="btn shadow-none mx-1 modal-btn cancel-btn"
            (click)="closeConfirmImportUserHoldings()"
         >
            No
         </button>
         <button
            class="btn shadow-none mx-1 modal-btn success-btn"
            (click)="importUserHoldings()"
         >
            Yes
         </button>
      </div>
   </div>
</ng-template>
