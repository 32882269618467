import { Component, OnInit, Input, HostBinding, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AuthService } from '../../services/auth.service';
import { AccessControlService } from '../../services/access-control.service';
import { CollectionComplianceService } from '../../services/collection-compliance.service';

@Component({
   selector: 'app-compliance-chip',
   templateUrl: './compliance-chip.component.html',
   styleUrls: ['./compliance-chip.component.scss'],
})
export class ComplianceChipComponent implements OnInit {
   @Input() showCompliance?: boolean;
   @Input() symbol!: string;
   @Input() complianceStatus!: string;
   @Input() complianceRanking!: number;
   @Input() preloaded: boolean | null = null;
   @Input() align!: string;
   @Input() onlyRanking: boolean = false;
   @Input() onlyCompliance: boolean = false;
   @Input() isAccessibleComplianceSymbol?: boolean;
   @Input() complianceRankingV2: any;

   private subscriptions: Subscription[];
   userRole: string | null = null;
   showRanking: boolean = false;
   isLoggedIn: boolean = false;
   accessibleComplianceSymbols: string[] = [];
   //   rankingLocked: boolean = false;

   constructor(
      private firebaseConfig: FirebaseRemoteConfigService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private authService: AuthService,
      public accessControlService: AccessControlService,
      private collectionCompliance: CollectionComplianceService
   ) {
      this.subscriptions = [];
   }

   ngOnInit(): void {

      // console.log("compliance status : ",this.complianceStatus)

      // console.log('Child init complianceRankingV2:', this.complianceRankingV2);

      // console.log('Received complianceRankingV2 in child:', this.complianceRankingV2);
      this.isLoggedIn = !!this.authService.getUserId();
      // this.subscriptions.push(
      //    SubscriptionService.subscriptionData.subscribe(
      //       async (data: SubscriptionInfoModel) => {
      //          this.userRole = data.role;
      //          if (this.userRole !== 'PREMIUM') {
      //             if (this.showCompliance !== undefined) {
      //                this.showRanking = this.showCompliance;
      //             } else {
      //                const unlockedSymbols: Array<string> = (
      //                   await this.firebaseConfig.getFreeStocksList()
      //                ).concat(await this.firebaseConfig.getFreeEtfList());
      //                // .concat(await this.firebaseConfig.getBucketList())
      //                this.showRanking = unlockedSymbols.includes(this.symbol);
      //             }
      //          } else {
      //             this.showRanking = true;
      //          }
      //       }
      //    )
      // );
      // if (this.isAccessibleComplianceSymbol !== undefined) {
      //    this.showRanking = this.isAccessibleComplianceSymbol;
      // } else {
         this.subscriptions.push(
            this.permissionStore
               .select(
                  ({ permissions }) =>
                     permissions.permissions.stock_halal_rating
               )
               .subscribe(async (permission) => {
                  if (!permission) {
                     if (this.showCompliance !== undefined) {
                        this.showRanking = this.showCompliance;
                     } else {
                        if (this.isAccessibleComplianceSymbol !== undefined) {
                           this.showRanking = this.isAccessibleComplianceSymbol;
                        } else {
                           const unlockedSymbols: Array<string> = (
                              await this.firebaseConfig.getFreeStocksList()
                           ).concat(await this.firebaseConfig.getFreeEtfList());
                           this.showRanking = unlockedSymbols.includes(
                              this.symbol
                           );
                        }
                     }
                  } else {
                     this.showRanking = true;
                  }
               })
         );
      // }
   }

   ngOnChanges(changes: SimpleChanges) {
      // console.log('Child changes:', changes);
      if (changes.complianceRankingV2) {
      //   console.log('New complianceRankingV2 value:', changes.complianceRankingV2.currentValue);
      }
    }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   @HostBinding('class')
   get alignment(): string {
      if (this.align === 'left') {
         return 'left-align';
      } else if (this.align === 'right') {
         return 'right-align';
      } else {
         return 'center-align'; // Default class
      }
   }
}
