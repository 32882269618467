<div class="price-chart-component-container">
   <div class="chart-container">
      <canvas id="priceChart">{{priceChart}}</canvas>
      <div class="timeline-section">
         <div>Last 12 months</div>
         <div>Next 12 months</div>
      </div>
   </div>
   <div class="chart-projection-values py-2">
      <div class="projection">
         <span class="title">High</span>
         <span class="value">{{forecastHigh | currencyMilion: currency}}</span>
         <span class="percent-change">
            <span class="positive" *ngIf="(diffHigh && current) && diffHigh >= 0">+{{((diffHigh/current) * 100) | number: "1.2-2"}}%</span>
            <span class="negative" *ngIf="(diffHigh && current) && diffHigh < 0">{{((diffHigh/current) * 100) | number: "1.2-2"}}%</span>
         </span>
      </div>
      <div class="projection">
         <span class="title">Median</span>
         <span class="value">{{forecastMedian | currencyMilion: currency}}</span>
         <span class="percent-change">
            <span class="positive" *ngIf="(diffMedian && current) && diffMedian >= 0">+{{((diffMedian/current) * 100) | number: "1.2-2"}}%</span>
            <span class="negative" *ngIf="(diffMedian && current) && diffMedian < 0">{{((diffMedian/current) * 100) | number: "1.2-2"}}%</span>
         </span>
      </div>
      <div class="projection">
         <span class="title">Low</span>
         <span class="value">{{forecastLow | currencyMilion: currency}}</span>
         <span class="percent-change">
            <span class="positive" *ngIf="(diffLow && current) && diffLow >= 0">+{{((diffLow/current) * 100) | number: "1.2-2"}}%</span>
            <span class="negative" *ngIf="(diffLow && current) && diffLow < 0">{{((diffLow/current) * 100) | number: "1.2-2"}}%</span>
         </span>
      </div>
   </div>
</div>

