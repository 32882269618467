<div class="portfolio-grid-container mx-auto" [ngClass]="isForPortfolio?'container portfolio-padding':'py-xxl-3'">
   <div>
      <h2 *ngIf="!isForPortfolio" class="text-center text-lg-start section-main-heading">
         <span>{{ accountsConnected ?? '1,486' }}</span> Users trust us with their Portfolios worth <span>{{
            assetsLinked ?? '$32,300,548' }}</span> in total.
      </h2>
      <h2 *ngIf="isForPortfolio" class="text-center text-lg-start section-main-heading curated-heading">
         Curated features to make your portfolio <span>Shariah Compliant.</span>
      </h2>
      <ul class="d-grid gap-3 portfolio-points-list" role="list">
         <li class="d-block rounded-3 overflow-hidden portfolio-points-item"
            [ngClass]="imageIndex === 1 ? 'active-item' : ''" (mouseover)="setImageIndex(1)" (click)="setImageIndex(1)">
            <p class="mb-0">
               Connect and view overall shariah compliance of your portfolio
            </p>
         </li>
         <li class="d-block rounded-3 overflow-hidden portfolio-points-item"
            [ngClass]="imageIndex === 2 ? 'active-item' : ''" (mouseover)="setImageIndex(2)" (click)="setImageIndex(2)">
            <p class="mb-0">
               Automated purification calculation for your sell transactions
            </p>
         </li>
         <li class="d-block rounded-3 overflow-hidden portfolio-points-item"
            [ngClass]="imageIndex === 3 ? 'active-item' : ''" (mouseover)="setImageIndex(3)" (click)="setImageIndex(3)">
            <p class="mb-0">
               Get alerts when compliance changes and take necessary actions
            </p>
         </li>
      </ul>
      <!-- <ng-container *ngIf="userRole === 'ANONYMOUS'">
         <button class="btn rounded-3 shadow-none text-white py-2 px-3 mx-auto ms-lg-0 gradient-btn link-portfolio-btn"
            (click)="openAuthPopup()">
            <img src="/assets/icons/lock-white.svg" alt="Lock" class="img-fluid">
            <span>Link My Portfolio</span>
         </button>
      </ng-container> -->
      <ng-container *ngIf="!isForPortfolio">
         <a class=" btn rounded-3 text-decoration-none shadow-none text-white py-2 px-3
            mx-auto ms-lg-0 gradient-btn link-portfolio-btn" routerLink="/portfolio-dashboard">
            <!-- <img src="/assets/icons/lock-white.svg" alt="Lock" class="img-fluid"> -->
            <span>Link My Portfolio</span>
         </a>
      </ng-container>

      <ng-container *ngIf="isForPortfolio">
         <div class="d-flex justify-content-lg-start justify-content-center">
            <button
               class="btn gradient-btn d-flex align-items-center gap-3 text-white rounded-3 shadow-none link-portfolio-btn py-lg-3 py-2 lh-1 px-4 card-btn fw-500"
               (click)="handleConnectBtn()">
               <div class="text-center d-flex gap-3 align-items-center" *ngIf="linkLoader">
                  <div class="spinner-border spinner-border-sm" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
                  <span class="block py-1">Connecting...</span>
               </div>

               <span *ngIf="!linkLoader">Connect Your Account</span>
               <svg *ngIf="!linkLoader" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#138D72" />
                  <g clip-path="url(#clip0_2838_4854)">
                     <path
                        d="M9.81153 6.89562C9.6667 6.89477 9.5249 6.93708 9.40421 7.01714C9.28352 7.09721 9.1894 7.2114 9.13387 7.34516C9.07833 7.47892 9.06388 7.62619 9.09237 7.7682C9.12086 7.9102 9.19099 8.0405 9.29382 8.14249L13.1584 11.9998L9.29382 15.8571C9.15652 15.9944 9.07938 16.1806 9.07938 16.3748C9.07938 16.4709 9.09832 16.5661 9.13511 16.655C9.17191 16.7438 9.22584 16.8245 9.29382 16.8925C9.36181 16.9605 9.44252 17.0144 9.53135 17.0512C9.62018 17.088 9.71539 17.1069 9.81153 17.1069C10.0057 17.1069 10.1919 17.0298 10.3292 16.8925L14.7042 12.5175C14.84 12.3809 14.9163 12.1961 14.9163 12.0034C14.9163 11.8108 14.84 11.626 14.7042 11.4894L10.3292 7.11437C10.2617 7.04547 10.1812 6.99066 10.0923 6.95311C10.0034 6.91556 9.90801 6.89602 9.81153 6.89562Z"
                        fill="white" />
                  </g>
                  <defs>
                     <clipPath id="clip0_2838_4854">
                        <rect width="7" height="10.5" fill="white" transform="matrix(1 0 0 -1 8.5 17.25)" />
                     </clipPath>
                  </defs>
               </svg>
            </button>
         </div>
      </ng-container>
   </div>
   <div class="image-wrapper overflow-hidden">
      <img appLazyLoadImage="/assets/images/dashboard/portfolio-image-1.png"
         placeholder="/assets/images/dashboard/portfolio-image-1-lowres.png" alt="portfolio image"
         class="portoflio-image" *ngIf="imageIndex === 1" @fade>
      <img appLazyLoadImage="/assets/images/dashboard/portfolio-image-3.png"
         placeholder="/assets/images/dashboard/portfolio-image-3-lowres.png" alt="portfolio image"
         class="portoflio-image" *ngIf="imageIndex === 2" @fade>
      <img appLazyLoadImage="/assets/images/dashboard/portfolio-image-2.png"
         placeholder="/assets/images/dashboard/portfolio-image-2-lowres.png" alt="portfolio image"
         class="portoflio-image" *ngIf="imageIndex === 3" @fade>
   </div>
</div>