<div *ngIf="showList">
    <form class="d-grid gap-4" [formGroup]="form" (submit)="form.value && watchlistCreate()">
        <div class="watch-creates">
            <input 
                type="text" 
                class="list-name-input w-100 px-3 rounded-3" 
                maxlength="35" 
                (input)="preProcessText()" 
                id="exampleFormControlInput1" 
                placeholder="List Name"
                formControlName="name" 
                [ngClass]="{'active':form.valid}"
            >
            <small class="error-msg text-danger" *ngIf="showErrorMsg">
                <i class="bi bi-exclamation-triangle me-2"></i>This name already exists. Please, enter a different name.
            </small>
        </div>
        <div class="watchlist-button">
            <button type="submit" class="d-flex align-items-center justify-content-center gap-2 fw-600 w-100 rounded-3 create-btn border-0" *ngIf="checkAllowedToAdd else SignUpToAdd" [disabled]="!form.valid || !checkAllowedToAdd" [ngClass]="{'active2':form.valid || checkAllowedToAdd}">
                <div class="d-flex justify-content-center" *ngIf="loading">
                   <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                   </div>
                </div>
                <span> {{ loading ? 'Creating...' : 'Create List' }}</span>
            </button>
        </div>
    </form>
</div>

<ng-template #SignUpToAdd>
    <upgrade-now (close)="closeMe()"></upgrade-now>
</ng-template>

<!-- <div class="page" *ngIf="showList">
    <form [formGroup]="form"   (submit)="form.value && watchlistCreate()">
        <div class="watch-creates">
            <input type="text" class="form-control" maxlength="35" (input)="preProcessText()" id="exampleFormControlInput1" placeholder="List Name"
                formControlName="name" [ngClass]="{'active':form.valid}">
                <small class="error-msg text-danger" *ngIf="showErrorMsg"><i class="bi bi-exclamation-triangle me-2"></i>This name already exists. Please, enter a different name.</small>
        </div>
        <div class="watchlist-button">
            // <button type="button" class="btn cancel" (click)="closeMe()">Cancel</button>
            <mat-spinner *ngIf="loading" [diameter]="32" class="custom-spinner mx-auto"></mat-spinner>  
            <div *ngIf="!loading">
                <button style="margin: 0;" type="submit" class="btn list" *ngIf="checkAllowedToAdd else SignUpToAdd" [disabled]="!form.valid || !checkAllowedToAdd" [ngClass]="{'active2':form.valid || checkAllowedToAdd}">Create List</button>
            </div>
        </div>
    </form>
</div>
<ng-template #SignUpToAdd>
    <upgrade-now (close)="closeMe()"></upgrade-now>
 </ng-template> -->
