<div class="offer-card" *ngIf="this.offer.show_offer">
   <div class="d-flex align-items-center ramadan-offer-text-wrapper">
      <svg class="me-2 mb-2 tag-svg" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M11 15.5L14.5 12C14.6833 11.8167 14.8292 11.6 14.9375 11.35C15.0458 11.1 15.1 10.8333 15.1 10.55C15.1 9.98333 14.9 9.5 14.5 9.1C14.1 8.7 13.6167 8.5 13.05 8.5C12.7333 8.5 12.4208 8.59167 12.1125 8.775C11.8042 8.95833 11.4333 9.26667 11 9.7C10.5 9.23333 10.1083 8.91667 9.825 8.75C9.54167 8.58333 9.25 8.5 8.95 8.5C8.38333 8.5 7.9 8.7 7.5 9.1C7.1 9.5 6.9 9.98333 6.9 10.55C6.9 10.8333 6.95417 11.1 7.0625 11.35C7.17083 11.6 7.31667 11.8167 7.5 12L11 15.5ZM12.25 19.4C11.8667 19.7833 11.3917 19.975 10.825 19.975C10.2583 19.975 9.78333 19.7833 9.4 19.4L0.6 10.6C0.416667 10.4167 0.270833 10.2 0.1625 9.95C0.0541667 9.7 0 9.43333 0 9.15V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9.15C9.43333 0 9.7 0.0541667 9.95 0.1625C10.2 0.270833 10.4167 0.416667 10.6 0.6L19.4 9.425C19.7833 9.80833 19.975 10.2792 19.975 10.8375C19.975 11.3958 19.7833 11.8667 19.4 12.25L12.25 19.4ZM10.825 18L17.975 10.85L9.15 2H2V9.15L10.825 18ZM4.5 6C4.91667 6 5.27083 5.85417 5.5625 5.5625C5.85417 5.27083 6 4.91667 6 4.5C6 4.08333 5.85417 3.72917 5.5625 3.4375C5.27083 3.14583 4.91667 3 4.5 3C4.08333 3 3.72917 3.14583 3.4375 3.4375C3.14583 3.72917 3 4.08333 3 4.5C3 4.91667 3.14583 5.27083 3.4375 5.5625C3.72917 5.85417 4.08333 6 4.5 6Z" fill="#F2B225"/>
      </svg>
      <div>
         <h6 class="">{{offer.title}}</h6>
         <p class="mb-0" *ngIf="!isExpiring">{{offerDescription}}</p>
         <p class="mb-0" *ngIf="isExpiring">Expiring Soon</p>
      </div>
   </div>
   <div class="offer-discount-wrapper d-flex align-items-end p-1" *ngIf="!this.offer.offer_highlight_image">
      <svg viewBox="0 0 48 51" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6793 14.6538L17.0832 23.2564L23.9998 17.9167L30.8973 23.2564L28.3011 14.6538L35.1537 9.641H26.6153L23.9998 0.94875L21.3844 9.641H12.846L19.6793 14.6538ZM7.33317 28.5705V50.5385L23.9998 45.5385L40.6665 50.5385V28.5705C42.7776 26.4936 44.4165 24.062 45.5832 21.2757C46.7498 18.4893 47.3332 15.5086 47.3332 12.3333C47.3332 7.72577 46.1977 3.61466 43.9266 0L39.8068 1.07433e-07C42.6022 3.51211 43.9998 7.62322 43.9998 12.3333C43.9998 17.8889 42.0554 22.6111 38.1665 26.5C34.2776 30.3889 29.5554 32.3333 23.9998 32.3333C18.4443 32.3333 13.7221 30.3889 9.83317 26.5C5.94428 22.6111 3.99984 17.8889 3.99984 12.3333C3.99984 7.62322 5.3975 3.51211 8.19283 1.07433e-07L4.07303 3.39969e-08C1.80201 3.61466 0.666504 7.72577 0.666504 12.3333C0.666504 15.5086 1.24984 18.4893 2.4165 21.2757C3.58317 24.062 5.22206 26.4936 7.33317 28.5705ZM23.9998 42.0769L10.6665 45.8141V31.3782C12.5255 32.7458 14.5907 33.8024 16.862 34.5481C19.1334 35.2938 21.5127 35.6667 23.9998 35.6667C26.487 35.6667 28.8663 35.2938 31.1377 34.5481C33.409 33.8024 35.4742 32.7458 37.3332 31.3782V45.8141L23.9998 42.0769Z" fill="white" fill-opacity="0.3"/>
      </svg>
      <p class="mb-0"><span class="percent">{{offer.offer_highlight_number}}</span>{{offer.offer_highlight_text}}</p>
   </div>
   <div *ngIf="this.offer.offer_highlight_image">
      <img src="{{this.offer.offer_highlight_image}}" class="banner-image img-fluid" alt="">
   </div>
</div>
