<article class="d-grid gap-2 details-wrapper" *ngIf="!showTradingViewTicker">
   <div class="d-flex align-items-start justify-content-between gap-3 gap-lg-4" [ngClass]="{'mb-2': showPrices}">
      <div class="d-flex align-items-center gap-3">
         <div class="stock-logo-container flex-shrink-0 ratio ratio-1x1">
            <img [src]="logoUrl" alt="" class="img-fluid rounded-circle bg-light stock-image">
            <span *ngIf="!logoUrl" class="fw-bold bg-light rounded-circle d-flex w-100 align-items-center justify-content-center h-100 no-logo-text">
               {{ getFirstLetter(stockName) }}
            </span>
         </div>
         <div class="company-name-wrapper d-flex align-items-end flex-wrap">
            <h5 class="mb-0 stock-name lh-sm fw-600 me-2" *ngIf="stockName">
               {{ stockName }}
               <span class="mb-0 d-inline-block lh-1 fw-500 ticker-name" *ngIf="stockTicker">{{ stockTicker }}</span>
            </h5>
         </div>
      </div>
      <div class="watchlist-wrapper d-flex align-items-center flex-shrink-0">
         <div *ngIf="!checkRole('ANONYMOUS')">
            <app-watchlist-button [stock]="item" *ngIf="stockType === 'Stock'"></app-watchlist-button>
            <app-watchlist-button [etf]="item" *ngIf="stockType === 'ETF'"></app-watchlist-button>
         </div>
         <!-- <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="!checkRole('ANONYMOUS') && !isAddedInWatchlist" (click)="openAddListsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#9F9F9F" fill="none">
               <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button> -->
         <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="checkRole('ANONYMOUS')" (click)="openLoginPopup()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#9F9F9F" fill="none">
               <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button>
         <!-- <button class="btn shadow-none d-flex align-items-center watchlist-btn-fill" *ngIf="isAddedInWatchlist" (click)="openAddListsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#daa520" fill="#daa520">
               <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button> -->

         <div class="ms-3 flex-shrink-0" *ngIf="isMobile">
            <app-share-links-popup></app-share-links-popup>
         </div>
      </div>
   </div>
   <ng-container *ngIf="currentPrice">
      <div class="d-flex align-items-end flex-wrap stock-price-details" *ngIf="showPrices">
         <span class="stock-price lh-1 me-2">{{(currentPrice ? currentPrice : '-') | sumPipe: (currency || '')}}</span>
         <span class="d-flex align-items-center me-1" *ngIf="priceChange !== 0">
            <i class="bi bi-triangle-fill d-inline-block" [ngClass]="{ 'positive': priceChange >= 0, 'negative': priceChange < 0 }"></i>
            <span class="stock-price-changes lh-sm" [ngClass]="{ 'positive': priceChange >= 0, 'negative': priceChange < 0 }">
               {{ (priceChange ? ( priceChange | abs ) : '-') | sumPipe : (currency || '') }}
            </span>
         </span>
         <span class="d-flex align-items-center percent-change" *ngIf="percentChange">
            <span class="stock-price-changes lh-sm" [ngClass]="{ 'positive': priceChange >= 0, 'negative': priceChange < 0 }">
               <!-- ({{ (percentChange ? percentChange : '-') }}%) -->
                 ({{ percentChange | abs | number : "1.2-2"}}%)
            </span>
         </span>
         <span class="span-1d ms-2" *ngIf="percentChange">
            1D
         </span>
         <!-- <span class="stock-years lh-sm" *ngIf="longPeriodStatus === 'OK'">Past {{ longPeriodYears }} Years</span> -->
      </div>
   </ng-container>
   <p class="mb-0 d-flex update-date align-items-center py-2" *ngIf="lastDate">
      <span>Last Updated: {{ lastDate | date: "MMM dd, hh:mm a" }}</span> <span class="mx-2">·</span> <span>{{ stockExchange }}</span>
   </p>
   <ng-container *ngIf="priceDelayText">
      <p class="mb-0 d-flex pricing-delay-text align-items-start gap-2" *ngIf="showPrices && currentPrice">
         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m12.75 16.5 3-3h-3z" fill="#F3BB52"/>
            <path d="M15 1.5H3a.75.75 0 0 0-.75.75v13.5a.75.75 0 0 0 .75.75h8.25V12h4.5V2.25A.75.75 0 0 0 15 1.5m-6 9.75H6a.75.75 0 1 1 0-1.5h3a.75.75 0 0 1 0 1.5m3-3.75H6A.75.75 0 0 1 6 6h6a.75.75 0 1 1 0 1.5" fill="#F3BB52"/>
         </svg>
         <span>
            {{ priceDelayText }}
         </span>
      </p>
   </ng-container>
</article>

<article class="d-grid gap-2 details-wrapper ps-0 pe-2 ps-lg-2 pe-lg-3 pb-0 pt-3" *ngIf="showTradingViewTicker">
   <div class="d-flex align-items-center gap-3 ms-auto me-2">
      <div class="watchlist-wrapper">
         <div *ngIf="!checkRole('ANONYMOUS')">
            <app-watchlist-button [stock]="item" *ngIf="stockType === 'Stock'"></app-watchlist-button>
            <app-watchlist-button [etf]="item" *ngIf="stockType === 'ETF'"></app-watchlist-button>
         </div>
         <!-- <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="!checkRole('ANONYMOUS') && !isAddedInWatchlist" (click)="openAddListsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#9F9F9F" fill="none">
               <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button> -->
         <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="checkRole('ANONYMOUS')" (click)="openLoginPopup()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#9F9F9F" fill="none">
               <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button>
         <!-- <button class="btn shadow-none d-flex align-items-center watchlist-btn-fill" *ngIf="isAddedInWatchlist" (click)="openAddListsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#daa520" fill="#daa520">
               <path d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05143L20.0969 8.58575C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58575L7.08837 8.05143C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button> -->
      </div>
      <app-share-links-popup *ngIf="isMobile"></app-share-links-popup>
   </div>

   <div class="tradingview-widget-container">
      <div class="tradingview-widget-container__widget" id="ticker-widget"></div>
   </div>
</article>

