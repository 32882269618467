import {
   Component,
   EventEmitter,
   Input,
   OnInit,
   Output,
   TemplateRef,
   ViewChild,
} from '@angular/core';
import {
   FormGroup,
   FormControl,
   Validators,
   FormGroupDirective,
   FormBuilder,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
   lastValueFrom,
   Observable,
   of,
   OperatorFunction,
   Subject,
   Subscription,
} from 'rxjs';
import {
   catchError,
   debounceTime,
   distinctUntilChanged,
   map,
   tap,
   switchMap,
   takeUntil,
} from 'rxjs/operators';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import {
   Holding,
   OtherInvestments,
   Investment as OtherInvestment,
   InvestmentType,
} from 'src/app/models/zakat.model';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { ZakatService } from 'src/app/shared/services/zakat.service';
import { GeneralInfoPopupComponent } from 'src/app/shared/components/general-info-popup/general-info-popup.component';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';

@Component({
   selector: 'app-stock-fund-investments',
   templateUrl: './stock-fund-investments.component.html',
   styleUrls: ['./stock-fund-investments.component.scss'],
})
export class StockFundInvestmentsComponent implements OnInit {
   @Input() existingHoldings: Holding[] = [];

   private subscriptions: Subscription[] = [];
   // form!: FormGroup;
   addStocksForm!: FormGroup;

   investments: InvestmentType[] = [
      { id: 'short', name: 'Short Term' },
      { id: 'long', name: 'Long Term' },
   ];

   selectedInvestmentType: string | null = null;
   selectedCurrency!: string | null;

   currency: string = '';
   importedholdings: Array<any> = [];
   //   importedholdings: Holding[] = []
   formInvestmentType: string | null = null;
   allHoldings: Holding[] = [];
   otherHoldings: OtherInvestment[] = [];
   importHoldingsLoader: boolean = false;

   zakatInvestmentInfoModalRef!: NgbModalRef;
   @ViewChild('zakatInvestmentInfoModalContent', { static: true })
   zakatInvestmentInfoModalContent!: TemplateRef<NgbModal>;

   addStocksHoldingRef!: NgbModalRef;
   @ViewChild('addStocksHoldingContent', { static: true })
   addStocksHoldingContent!: TemplateRef<NgbModal>;

   otherHoldingsRef!: NgbModalRef;
   @ViewChild('otherHoldingsContent', { static: true })
   otherHoldingsContent!: TemplateRef<NgbModal>;

   importHoldingsConfirmationRef!: NgbModalRef;
   @ViewChild('importHoldingsConfirmation', { static: true })
   importHoldingsConfirmationContent!: TemplateRef<NgbModal>;

   userRole: string | null = null;

   showTickerError: boolean = false;
   showSharesError: boolean = false;
   isStockUnavailable: string = '';
   autoFetchLoading: boolean = false;
   isPriceAutoFetched: boolean = false

   symbol: any;
   isOther = true;
   searching = false;
   searchFailed = false;
   private stockCountries = [];
   private configInitDone = false;
   private unSubscribe = new Subject<void>();

   constructor(
      private modalService: NgbModal,
      private typesenseService: TypesenseService,
      private marketUtilsService: MarketUtilsService,
      private rootFormGroup: FormGroupDirective,
      private formBuilder: FormBuilder,
      private zakatService: ZakatService,
      public subscriptionService: SubscriptionService,
      private toaster: ToastrService,
      private portfolioService: PortfolioService
   ) {
      this.selectedInvestmentType = this.investments[0].id;
      this.formInvestmentType = this.investments[0].id;
   }

   ngOnInit(): void {
      const hasOpenedPrompt = sessionStorage.getItem(
         'isFirstVisitOnStockInvestments'
      );
      if (!hasOpenedPrompt) {
         this.openZakatInvestmentInfoModal();
         sessionStorage.setItem('isFirstVisitOnStockInvestments', 'true');
      }
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );
      this.selectedCurrency = localStorage.getItem('selectedCurrency') ?? 'USD';
      if (this.selectedCurrency) {
         this.selectedCurrency = this.selectedCurrency?.replace(/^"|"$/g, '');
      }

      this.currency =
         this.zakatService.getSelectedCurrencyAndFormsData().currency;
      this.init();
      this.addStocksForm = this.formBuilder.group({
         symbol: ['', Validators.required],
         numberOfShares: ['', Validators.required],
         totalValueOfShares: ['', Validators.required],
         investmentType: ['', Validators.required],
      });
      this.allHoldings = this.zakatService.getAllHoldings().holdings;

      this.addStocksForm.get('symbol')?.valueChanges.pipe().subscribe(() => {
         this.addStocksForm.controls['totalValueOfShares'].setValue('');
         this.isPriceAutoFetched = false
      });

      this.addStocksForm.get('numberOfShares')?.valueChanges.pipe(
         debounceTime(300),
         distinctUntilChanged(),
         takeUntil(this.unSubscribe)
      ).subscribe(() => {
         this.autofetchPrice();
      });
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
      this.zakatService.setAllHoldings(this.allHoldings);
      this.unSubscribe.next();
      this.unSubscribe.complete();
   }

   async init() {
      try {
         let countriesData: any =
            await this.marketUtilsService.fetchCountries();
         this.stockCountries = countriesData
            .filter((obj: any) => obj.stockEnabled)
            .map((obj: any) => obj.countryCode);
         this.configInitDone = true;
      } catch (e) {
         console.log(e);
      }
   }

   search: any = (text$: Observable<string>) => {
      if (!this.configInitDone) {
         return of([]);
      }

      return text$.pipe(
         debounceTime(200),
         distinctUntilChanged(),
         tap(() => (this.searching = true)),
         switchMap((searchText) =>
            this.typesenseService
               .searchOnlyStockSymbols(searchText, this.stockCountries)
               .pipe(
                  tap(() => (this.searchFailed = false)),
                  catchError(() => {
                     this.searchFailed = true;
                     return of([]);
                  })
               )
         ),
         tap(() => (this.searching = false))
      );
   };

   formatSymbol(value: any) {
      return value.stockName;
   }

   inputFormatBandListValue(value: any) {
      return value.stockName;
   }

   confirmImportUserHoldings() {
      this.importHoldingsConfirmationRef = this.modalService.open(
         this.importHoldingsConfirmationContent,
         {
            centered: true,
         }
      );
   }

   closeConfirmImportUserHoldings() {
      if (this.importHoldingsConfirmationRef) {
         this.importHoldingsConfirmationRef.dismiss();
      }
   }

   async importUserHoldings() {
      this.closeConfirmImportUserHoldings();
      this.importHoldingsLoader = true;

      try {
         if (this.userRole !== 'PREMIUM') {
            //   this.toaster.error('This feature is only available for the premium users');
            //   this.subscriptionService.openPaywallModal();
            const modal = this.modalService.open(GeneralInfoPopupComponent, {
               centered: true,
            });
            modal.componentInstance['title'] = 'Upgrade Required';
            modal.componentInstance['description'] =
               'This feature is only available for the premium users';
            modal.componentInstance['showUpgradeButton'] = true;
            return;
         }

         const accountsResponse = await lastValueFrom(
            this.portfolioService.getAccounts()
         );
         if (accountsResponse.status) {
            const allAccounts = accountsResponse.data;

            if (!allAccounts.length) {
               const modal = this.modalService.open(GeneralInfoPopupComponent, {
                  centered: true,
               });
               modal.componentInstance['title'] = 'No Brokerage Linked!';
               modal.componentInstance['description'] =
                  'Link Brokerage to your account to import your holdings';
               modal.componentInstance['showUpgradeButton'] = false;
               modal.componentInstance['goToOnConfirm'] = '/cabinet/portfolio';
               modal.componentInstance['showCancelBtn'] = true;
               modal.componentInstance['confirmationText'] = 'Connect';
            } else {
               const response = await this.zakatService.getUserHoldings(
                  this.currency
               );

               // console.log(response.holdings);
               if (response.holdings) {
                  this.importedholdings = [];

                  for (const holdingData of response.holdings) {
                     const newHolding: Holding = {
                        ticker: holdingData.symbol,
                        no_of_shares: +holdingData.units,
                        current_market_value: +(
                           holdingData.price * holdingData.units
                        ),
                        investment_type: this.selectedInvestmentType || '',
                     };
                     this.allHoldings.push(newHolding);
                     //  const i = this.allHoldings.findIndex((holding) => holding.ticker === newHolding.ticker)
                     //  if(i >= 0) {
                     //    this.allHoldings[i] = newHolding;
                     //  } else {
                     //     this.allHoldings.push(newHolding);
                     // }
                  }
                  for (const otherData of response.investments) {
                     const newHolding: OtherInvestment = {
                        name: otherData.name,
                        amount: otherData.amount,
                        investment_type: this.selectedInvestmentType || '',
                     };
                     this.otherHoldings.push(newHolding);
                     //  const i = this.otherHoldings.findIndex((holding) => holding.name === newHolding.name)
                     //  if(i >= 0) {
                     //    this.otherHoldings[i] = newHolding;
                     //  } else {
                     //     this.otherHoldings.push(newHolding);
                     // }
                  }
                  this.zakatService.setOtherInvestment(this.otherHoldings);
                  this.zakatService.setAllHoldings(this.allHoldings);
               }

               this.importHoldingsLoader = false;
            }
         } else {
            this.importHoldingsLoader = false;
         }
      } catch (error) {
         console.error(error);
         this.importHoldingsLoader = false;
      } finally {
         this.importHoldingsLoader = false;
      }
   }

   autofetchPrice() {
      this.isStockUnavailable = '';
      if (!this.addStocksForm.controls['symbol'].value) {
         this.showTickerError = true;
      }

      if (!this.addStocksForm.controls['numberOfShares'].value) {
         this.showSharesError = true;
         this.addStocksForm.controls['totalValueOfShares'].setValue('');
         this.isPriceAutoFetched = false
         return
      }

      if (
         !this.addStocksForm.controls['symbol'].value ||
         !this.addStocksForm.controls['numberOfShares'].value
      )
         return;
      this.autoFetchLoading = true;
      let symbols: string[] = [];
      let curretPrice: number;
      let currentTickerName: string =
         this.addStocksForm.controls['symbol'].value.stockName;
      symbols.push(currentTickerName);
      this.portfolioService
         .getConvertedPrice(currentTickerName, this.selectedCurrency as string)
         .then((res: any) => {
            if (res.status) {
               curretPrice = res.convertedAmount;

               this.addStocksForm.controls['totalValueOfShares'].setValue(
                  (
                     curretPrice *
                     this.addStocksForm.controls['numberOfShares'].value
                  ).toFixed(2)
               );
               this.isPriceAutoFetched = true
            } else {
               this.isStockUnavailable = res.message;
               this.addStocksForm.controls['totalValueOfShares'].setValue('');
               this.isPriceAutoFetched = false
            }
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            this.autoFetchLoading = false;
         });
   }

   addStock() {
      if (this.addStocksForm.valid) {
         const newHolding = {
            ticker: this.addStocksForm.controls['symbol'].value.stockName,
            no_of_shares: +this.addStocksForm.controls['numberOfShares'].value,
            current_market_value:
               +this.addStocksForm.controls['totalValueOfShares'].value,
            investment_type:
               this.addStocksForm.controls['investmentType'].value,
         };

         this.allHoldings.push(newHolding);
         this.zakatService.setAllHoldings(this.allHoldings);
         this.closeAddHoldingModal();
         this.resetFormControls();
      }
   }

   removeStock(stock: any) {
      const index = this.allHoldings.indexOf(stock);
      if (index !== -1) {
         this.allHoldings.splice(index, 1);
      }
      this.zakatService.setAllHoldings(this.allHoldings);
   }

   removeOther(other: any) {
      const index = this.otherHoldings.indexOf(other);
      if (index !== -1) {
         this.allHoldings.splice(index, 1);
      }
      this.zakatService.setOtherInvestment(this.otherHoldings);
   }

   private resetFormControls() {
      this.addStocksForm.controls['symbol'].setValue('');
      this.addStocksForm.controls['numberOfShares'].setValue('');
      this.addStocksForm.controls['totalValueOfShares'].setValue('');
   }

   updateSelectedInvestmentType(type: string) {
      if (type === 'longTerm') {
         this.addStocksForm.controls['investmentType'].setValue('long');
         this.formInvestmentType = 'long';
         if (this.allHoldings.length) {
            this.allHoldings.forEach((stock) => {
               stock.investment_type = 'long';
            });
         }
      } else if (type === 'shortTerm') {
         this.addStocksForm.controls['investmentType'].setValue('short');
         this.formInvestmentType = 'short';
         if (this.allHoldings.length) {
            this.allHoldings.forEach((stock) => {
               stock.investment_type = 'short';
            });
         }
      } else if (type === 'mixed') {
         this.addStocksForm.controls['investmentType'].setValue(null);
         this.selectedInvestmentType = 'mixed';
         this.formInvestmentType = null;
      }
   }

   openZakatInvestmentInfoModal() {
      this.zakatInvestmentInfoModalRef = this.modalService.open(
         this.zakatInvestmentInfoModalContent,
         {
            size: 'lg',
            centered: true,
         }
      );
   }

   closeZakatInvestmentInfoModal() {
      if (this.zakatInvestmentInfoModalRef) {
         this.zakatInvestmentInfoModalRef.dismiss();
      }
   }

   openAddHoldingModal() {
      this.addStocksHoldingRef = this.modalService.open(
         this.addStocksHoldingContent,
         {
            centered: true,
         }
      );
   }

   closeAddHoldingModal() {
      this.addStocksForm.reset();
      this.showSharesError = false;
      this.showTickerError = false;
      this.isStockUnavailable = '';
      if (this.addStocksHoldingRef) {
         this.addStocksHoldingRef.dismiss();
      }
   }

   openOtherHoldingsModal() {
      this.otherHoldingsRef = this.modalService.open(
         this.otherHoldingsContent,
         {
            centered: true,
            backdrop: 'static',
         }
      );
   }

   closeOtherHoldingsModal() {
      if (this.otherHoldingsRef) {
         this.otherHoldingsRef.dismiss();
      }
   }
}
