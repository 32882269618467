import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { UserApiService } from '../../services/user-api.service';
import { MarketUtilsService } from '../../services/market-utils.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';

@Component({
  selector: 'app-country-prompt-popup',
  templateUrl: './country-prompt-popup.component.html',
  styleUrls: ['./country-prompt-popup.component.scss']
})
export class CountryPromptPopupComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
   public configService: FirebaseRemoteConfigService,
   public marketUtilsService: MarketUtilsService,
   public userApiService: UserApiService,
   private analyticsUtils:AnalyticsUtils,
   ) { }

  @Input() type: string = 'check-country';

  @Output() continueClick: EventEmitter<void> = new EventEmitter();
  @Output() viewCoveredCountriesClick: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
   this.getPaywallDefaults()
  }

  public configDisplayValues: any = {};

  checkCoveredCountries() {
     this.viewCoveredCountriesClick.emit();
     this.activeModal.dismiss()
  }

  paywallCountryConfirmCancel(){
   this.analyticsUtils.paywall_country_confirm_cancel()  
   }

   paywallCountryConfirmProceed(){
      this.analyticsUtils.paywall_country_confirm_proceed()
   }

  getPaywallDefaults() {
   this.configService
      .getInAppSubscriptionDefaults()
      .then(async (data) => {
         this.configDisplayValues = data.paywall_defaults.uncovered_country_message;
         // var userObj = await this.userApiService.fetchUserDetails();
         const userData = await this.userApiService.fetchUserDetails();
         const userCountry = userData.geo_ip_country;
         const countries: any = await this.marketUtilsService.fetchCountries();
         let countryMapping: any = {};
         for(let country of countries) {
            countryMapping[country.countryCode] = country.country
         }
         for(let i in this.configDisplayValues) {
            this.configDisplayValues[i] = this.configDisplayValues[i].replaceAll(/{{COUNTRY}}/gi, countryMapping[userCountry] ?? userCountry)
         }
      })
      .catch((error) => {
         console.error(error);
      })
      .finally(() => {

      });
}

}
