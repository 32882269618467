import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-no-data-card',
  templateUrl: './no-data-card.component.html',
  styleUrls: ['./no-data-card.component.scss']
})
export class NoDataCardComponent implements OnInit {
  @Input() heading!: string;
  @Input() showHeader! : string;

  constructor() { }

  ngOnInit(): void {
  }

  // @HostBinding('class')

  // get btnType(): string {
  //   if (this.showHeader === 'show') {
  //     return 'showHeader';
  //   } else if (this.showHeader === 'hide') {
  //     return 'hideHeader';
  //   } else {
  //     return '';
  //   }
  // }

}
