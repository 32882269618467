<div class="d-inline-block btn mr-2 analyst-chip" [ngClass]="changeClasses()">
   <span>{{analystRating}}</span>
   <!-- <svg viewBox="0 0 24 24" class="info" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="analystRating === 'Buy' && complianceStatus === 'NON_COMPLIANT'">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier"> 
         <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"></circle> 
         <path d="M12 17V11" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> 
         <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#ffffff"></circle> 
      </g>
   </svg> -->
</div>
