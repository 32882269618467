<div class="scroll">

    <div class="skeleton-loader d-block w-100 loader-line" *ngIf="stocksLoading"></div>

    <ng-container *ngIf="!stocksLoading">
        <div class="scroll-container">
            <div class="scroll-item gap-2 stock-chip py-2 px-3"
                *ngFor="let stock of mostActive; trackBy: trackByStockId" routerLink="/stock/{{stock.ticker}}">
                <span class="stock-ticker">{{ stock.ticker }}</span>
                <span class="stock-price">{{ stock.currency ? ( stock.price | sumPipe: stock.currency ) : stock.price }}</span>
                <div class="d-flex align-items-center stock-percent" [ngClass]="getPercentChangeClasses(stock)">
                    <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                    <span>{{ stock.percentChange | abs | number : "1.2-2" }}%</span>
                </div>
            </div>
        </div>

        <div class="scroll-container">
            <div class="scroll-item gap-2 stock-chip py-2 px-3"
                *ngFor="let stock of mostActive; trackBy: trackByStockId" routerLink="/stock/{{stock.ticker}}">
                <span class="stock-ticker">{{ stock.ticker }}</span>
                <span class="stock-price">{{ stock.currency ? ( stock.price | sumPipe: stock.currency ) : stock.price }}</span>
                <div class="d-flex align-items-center stock-percent" [ngClass]="getPercentChangeClasses(stock)">
                    <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                    <span>{{ stock.percentChange | abs | number : "1.2-2" }}%</span>
                </div>
            </div>
        </div>
    </ng-container>

</div>