import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { SharedService } from '../../services/shared.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortfolioService } from '../../services/portfolio.service';
import { MyPortfolioService } from 'src/app/features/portfolio/services/my-portfolio.service';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
   selector: 'app-add-manual-card',
   templateUrl: './add-manual-card.component.html',
   styleUrls: ['./add-manual-card.component.scss'],
})
export class AddManualCardComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   @Input() portfolioAccounts: PortfolioAccount[] = [];
   @Input() portfolioType: 'broker' | 'manual' = 'manual';
   @Input() isInsideBrokeragesPopup!: boolean;
   maxManualPortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;

   constructor(
      private authService: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private sharedService: SharedService,
      private portfolioService: PortfolioService,
      private router: Router,
      private myPortfolio: MyPortfolioService
   ) {}

   isPremiumUser: boolean = false;
   // maxManualAccountCount: number = 0;
   // currManualAccounts: number = 0;

   ngOnInit(): void {
      this.getAccountsCount();
      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .subscribe((permissions) => {
            // this.isPremiumUser = permission;
            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;
         });

      // console.log(this.portfolioAccounts)

      // this.currManualAccounts = this.portfolioAccounts.filter(
      //    (item: PortfolioAccount) => item.is_manual === true
      // ).length;
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   openAuthPopup() {
      this.authService.openAuthPopup();
   }

   getAccountsCount() {
      if(!this.authService.getUserId()) return;
      
      this.portfolioService.getAccountCounts().pipe(takeUntil(this.destroy$)).subscribe((data) => {
         this.currentManualPortfoliosCount = data.manual_connected_count;
      })
   }

   handleConnectBtn() {
      if(this.isInsideBrokeragesPopup) {
         if(this.currentManualPortfoliosCount < this.maxManualPortfoliosCount) {
            this.portfolioService.closeSupportedBrokeragesModal();
            this.myPortfolio.openCreateManualPortfolioPopup();
         } else {
            this.portfolioService.openMessagePopup('manual')
         }
      } else {
         if(!this.authService.getUserId()) {
            this.openAuthPopup();
            return
         } 

         if(this.currentManualPortfoliosCount < this.maxManualPortfoliosCount) {
            this.portfolioService.closeSupportedBrokeragesModal();
            this.myPortfolio.openCreateManualPortfolioPopup();
         } else {
            this.portfolioService.closeSupportedBrokeragesModal();
            this.sharedService.openUpgradePopup('portfolio');
         }
      }

      // if (!this.authService.getUserId()) {
      //    this.openAuthPopup();
      // } else if (this.currentManualPortfoliosCount < this.maxManualPortfoliosCount) {
      //    this.portfolioService.closeSupportedBrokeragesModal();
      //    this.myPortfolio.openCreateManualPortfolioPopup();
      // } else {
      //    this.sharedService.openUpgradePopup('portfolio');
      //    this.portfolioService.closeSupportedBrokeragesModal();
      // }
   }
}
