import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarketHoliday, MarketHolidaysData, MarketHolidaysResponse } from 'src/app/models/market/market-holidays.model';
import { MarketService } from 'src/app/shared/services/market.service';

@Component({
  selector: 'app-market-holidays',
  templateUrl: './market-holidays.component.html',
  styleUrls: ['./market-holidays.component.scss']
})
export class MarketHolidaysComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   exchangeLoader: boolean = false;
   marketHolidaysLoader: boolean = false;
   exchangeTabs: string[] = []
   activeExchange!: string;
   marketHolidays: MarketHoliday[] = []

   constructor(
      private marketService: MarketService,
   ) { }

   ngOnInit(): void {
      this.exchangeLoader = true;
      this.marketService.marketStatusData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
         this.exchangeTabs = data
         this.exchangeLoader = false;
         this.getMarketHolidaysForSelectedExchange(this.exchangeTabs[0])
      });
   }
   
   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByMarketHolidaysId(index: number, marketHolidays: MarketHoliday) { 
      return marketHolidays.id
   }

   getMarketHolidays(exchanges: string[]) {
      this.marketHolidaysLoader = true;
      this.marketService.fetchMarketHolidays<MarketHolidaysResponse>(exchanges).subscribe({
         next: (response: MarketHolidaysResponse) => {
            if(response.status) {
               const data = response.data;
               const allHolidays: MarketHoliday[] = data[0].holidays
               const uniqueHolidays = this.getUniqueMarkatHolidays(allHolidays, 'event_name')
               this.marketHolidays = uniqueHolidays.slice(0, 5)
            }
            this.marketHolidaysLoader = false;
         },
         error: (error) => {
            console.error(error);
            this.marketHolidaysLoader = false;
         },
         complete: () => {
            this.marketHolidaysLoader = false;
         }
      })
   }

   getMarketHolidaysForSelectedExchange(exchange: string) {
      this.activeExchange = exchange;
      this.getMarketHolidays([this.activeExchange])
   }

   getUniqueMarkatHolidays<T>(array: T[], key: keyof T): T[] {
      const uniqueMap = new Map<T[keyof T], T>();
    
      array.forEach(obj => {
         if (!uniqueMap.has(obj[key])) {
            uniqueMap.set(obj[key], obj);
         }
      });
    
      return Array.from(uniqueMap.values());
   }

}



