import {
   Component,
   OnInit,
   Input,
   SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TypesenseService } from '../../services/typesense.service';
import { AuthService } from '../../services/auth.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { ConditionalRenderingService } from '../../services/conditional-rendering.service';
import { MarketUtilsService } from '../../services/market-utils.service';
import { UserApiService } from '../../services/user-api.service';
import { InvestmentChecklistService } from '../../services/investment-checklist.service';
import { InvestmentChecklistVariableModel } from 'src/app/models/investment-checklist-variables.model';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { AccessControlService } from '../../services/access-control.service';

@Component({
   selector: 'app-investment-checklist',
   templateUrl: './investment-checklist.component.html',
   styleUrls: ['./investment-checklist.component.scss'],
})
export class InvestmentChecklistComponent implements OnInit {
   public data: any;
   public marketDividendYield: any;

   @Input() ticker!: string;
   @Input() countryCode!: string;
   @Input() currency: any;
   @Input() status!: string;

   fundamentals: any;
   showRow1: boolean = false;
   showRow2: boolean = false;
   showRow3: boolean = false;
   showRow4: boolean = false;
   isLoggedIn: boolean = false;
   canReadInvestmentChecklist: boolean = false;
   showInvestmentChecklist: boolean = false;
   private subscriptions: Subscription[];
   loading: boolean = false;
   investmentBenchmark: InvestmentChecklistVariableModel|null = null;
   noData: string = 'NO-DATA';
   userRole: string|null = null
   @Input() isSample: boolean = false
   annualText: string = "Projected annual returns provide insight into the potential growth of your investments, helping you make informed decisions to build a more profitable portfolio.\n\nSuppose you invest $10,000 in a stock. The expected annual return indicates that you can anticipate a 10% increase in the value of your investment over the next year, potentially turning your $10,000 into $11,000"
   riskText: string = "Risk assessment against market indices allows you to gauge the stability and potential fluctuations in your investments, aiding you in managing and diversifying your portfolio.\n\nImagine you're considering two investment options. One has a risk score of 0.5, indicating moderate volatility, while the other has a risk score of 1.2, signifying higher volatility when compared to a stable index. This information helps you assess the potential ups and downs in each investment."
   sharpeRatioText: string = "The Sharpe Ratio is your compass for measuring investment performance relative to risk. It empowers you to identify opportunities with better risk-adjusted returns.\n\nLet's say you have two investment opportunities with identical returns. However, one has a Sharpe Ratio of 1.2, indicating better risk-adjusted returns, while the other has a Sharpe Ratio of 0.8, suggesting lower risk-adjusted returns. The higher Sharpe Ratio investment is the wiser choice, as it offers a better return relative to its risk."
   dividendYieldText: string = "Dividend yield data reveals the income generated by your investments. It's a key metric for income-focused investors, enabling better choices for consistent cash flow.\n\nConsider two dividend-paying stocks. Stock A offers an annual dividend of $2 per share, while Stock B provides $1 per share. If you own 100 shares of each, Stock A will yield $200 in dividends annually, whereas Stock B will yield $100. Dividend yield helps you identify which stocks can provide you with a steady income stream."

   constructor(
      private conditionalRenderingService: ConditionalRenderingService,
      private typesenseService: TypesenseService,
      private marketUtilsService: MarketUtilsService,
      private userApiService: UserApiService,
      private investmentChecklistService: InvestmentChecklistService,
      private auth: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService
   ) {
      this.subscriptions = [];
   }

   async ngOnInit(): Promise<void> {
      if(this.countryCode === 'US') {
         this.showInvestmentChecklist = true
      } else {
         const countries: any = await this.marketUtilsService.fetchCountries();
         const coveredCountryCodes = countries
            .filter((obj: any) => obj.detailedProfileAvailable)
            .map((obj: any) => obj.countryCode);
         this.showInvestmentChecklist = coveredCountryCodes.includes(this.countryCode) && this.status === 'COMPLIANT';
      }

      // this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      //    this.userRole = data.role
      // }))
      this.isLoggedIn = this.auth.isLoggedIn();
      this.subscriptions.push(
         this.permissionStore.select(({permissions}) => permissions.permissions).subscribe(
            (permissions) => {
               this.isLoggedIn = this.auth.isLoggedIn();
               this.canReadInvestmentChecklist = permissions.trending_halal_stocks;
            }
         )
      )
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (this.ticker) {
         this.investorChecklist(this.ticker);
      }
      this.getPriceData(this.ticker)
      this.updateUi();
   }

   updateUi() {
      this.handleRow1();
      this.handleRow2();
      this.handleRow3();
      this.handleRow4();
   }

   private async investorChecklist(ticker: string) {
      try {
         this.loading = true;
         let data = await this.typesenseService.getInvestorChecklist(ticker);
         this.data = data;

         this.typesenseService.setData(data);
         // this.marketDividendYield = data?.marketDividendYield;
         this.investmentBenchmark = await this.investmentChecklistService.getCountryStockBenchmark(this.countryCode);

         this.marketDividendYield = this.investmentBenchmark?.market_dividend_yield;

         if (!this.marketDividendYield) {
            this.marketDividendYield = 1.7;
         }
         this.updateUi();
         this.loading = false;
      } catch (e) {
         console.log(e);
         this.loading = false;
      }
   }

   handleRow1() {
      if (
         this.data &&
         this.fundamentals?.annualReturn &&
         this.fundamentals?.statusAnnualReturn != this.noData
      ) {
         this.showRow1 = true;
      } else {
         this.showRow1 = false;
      }
   }

   handleRow2() {
      if (this.data && this.data?.riskLevelDataStatus != this.noData) {
         this.showRow2 = true;
      } else this.showRow2 = false;
   }

   handleRow3() {
      if (
         this.data &&
         this.data?.sharpeRatioStatusDataStatus != this.noData &&
         this.data?.sharpeRatioValueDataStatus != this.noData
      ) {
         this.showRow3 = true;
      } else {
         this.showRow3 = false;
      }
   }

   handleRow4() {
      if (
         this.data &&
         this.fundamentals &&
         this.fundamentals?.dividendYield != null
      ) {
         this.showRow4 = true;
      } else this.showRow4 = false;
   }

   getPriceData(symbols:string){
      this.typesenseService.getPrices([symbols]).then((res)=>{
         if([symbols].length > 0) {
            for (const key in res) {
               let priceValue = res[key]
               if (this.ticker == priceValue.id) {
                  this.fundamentals = priceValue;
                  if(this.countryCode === 'GB') {
                     // console.log(this.fundamentals.dividendYield)
                     this.fundamentals.dividendYield *= 10000;
                     // console.log(this.fundamentals.dividendYield)
                  }
                  this.loading = false;
                  this.updateUi();
               }
            }
         }
      })
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   checkRole(role: string): boolean {
      return this.auth.checkRole(role);
   }

   onViewSampleClicked(componentName: string) {
      this.conditionalRenderingService.setComponentType(componentName);
   }

   getAnnualReturnClass(annualReturn: number) {
      return {
        'pos': annualReturn > 0,
        'neg': annualReturn < 0
      };
   }

   getRiskLevelStatusClass(riskLevelStatus: string ) {
      return {
        'pos': riskLevelStatus === 'Low risk' || riskLevelStatus === 'Very Low Risk',
        'mod': riskLevelStatus === 'Moderate Risk',
        'neg': riskLevelStatus === 'High Risk',
        'null': !riskLevelStatus
      };
   }

   getSharpieRatioStatusClass(sharpieRatio: string) {
      return {
         'pos': sharpieRatio == 'Good' || sharpieRatio == 'Very Good' || sharpieRatio == 'Excellent',
         'neg': sharpieRatio == 'Bad'
      }
   }

   getSharpeRatioClass(sharpeRatioStatus: string ) {
      return sharpeRatioStatus === "Good" ||
        sharpeRatioStatus === "Very Good" ||
        sharpeRatioStatus === "Excellent" ? "high" : "low";
   }

   getDividendYieldClass(dividendYield: number , marketDividendYield: number) {
      return {
        'pos': dividendYield >= marketDividendYield,
        'neg': dividendYield < marketDividendYield,
        'zero': dividendYield === 0
      };
   }
}
