import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges,
} from '@angular/core';
import { TradeService } from '../../services/trade.service';
import { PortfolioService } from '../../services/portfolio.service';
import { SharedService } from '../../services/shared.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Account, TradingAccounts } from 'src/app/models/trading.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from '../../services/access-control.service';

@Component({
   selector: 'app-buysell',
   templateUrl: './buysell.component.html',
   styleUrls: ['./buysell.component.scss'],
})
export class BuysellComponent implements OnInit {
   // @Input() country: any;
   // @Input() exchange: any;
   @Input() exchange: any;
   @Input() userRole: any;
   @Input() symbol: any;
   @Input() complianceStatus: any;
   isMobile = false;

   action: string | null = null;

   private subscriptions: Subscription[];

   public isPopupOpen: boolean = false;
   public isTradingEnabled: boolean = false;
   public isTradingAllowed: boolean = false;
   public isUserHoldSymbol: boolean = false;

   public isLoading: boolean = true;

   public accountsHasSymbol: any = [];
   public tradingAccounts: Account[] = [];

   constructor(
      private tradeApiService: TradeService,
      private portfolioApiService: PortfolioService,
      private auth: AuthService,
      private sharedService: SharedService,
      private observer: BreakpointObserver,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService
   ) {
      this.subscriptions = [];
   }

   setPopup() {
      // this.isPopupOpen = !this.isPopupOpen;
      this.tradeApiService.openBuySellPopup(
         this.exchange,
         this.userRole,
         this.symbol,
         this.action,
         this.complianceStatus
      );
   }

   openPremiumPopup() {
      this.sharedService.openUpgradePopup('buySell');
   }

   setAction(action: string) {
      this.action = action;
   }

   handlePopup(action: string) {
      if (this.auth.getUserId()) {
         if (this.isTradingAllowed) {
            this.setAction(action);
            this.setPopup();
         } else {
            this.accessControlService.accessDenied();
         }
      } else {
         this.accessControlService.accessDenied();
      }
   }

   ngOnInit(): void {
      this.observer.observe('(max-width: 1200px)').subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.subscriptions.push(
         this.permissionStore
            .select(
               ({ permissions }) => permissions.permissions.musaffa_trading
            )
            .subscribe((permission) => {
               this.isTradingAllowed = permission;
               // this.isTradingAllowed = true;
            })
      );

      // console.log('logging from init', this.complianceData);
      // if (this.auth.getUserId()) {
      //    this.isUserCanTrade();
      // } else {
      //    this.isLoading = false;
      // }
      // this.tradeApiService.openSupportedBrokeragesModal('NYSE');
   }
}
