<ng-container [formGroup]="form">
   <div class="p-top" formGroupName="property">
      <div class="form-input-wrapper">
         <label for="propertyAmount" class="form-label mb-2">Enter the total current market value of all your properties:</label>
         <div class="d-flex align-items-center form-control-container">
            <span class="d-inline-block lh-1 currency">{{ selectedCurrency }}</span>
            <input type="text" inputmode="numeric" autocomplete="off" class="form-control bg-transparent ps-0 shadow-none" formControlName="propertyAmount" id="propertyAmount" placeholder="" onlyNumbersAndDot>
         </div>
      </div>
   </div>
</ng-container>


