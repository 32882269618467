<button class="btn shadow-none px-1 d-flex align-items-center gap-2 countiries-dropdown-btn"
   (click)="openCountriesModal()">
   <div class="country-flag-wrapper rounded-circle flex-shrink-0 overflow-hidden bg-light">
      <img [src]="'/assets/flags/1x1/' + (selectedCountryCode.toLowerCase() || 'us') + '.svg'" class="img-fluid"
         width="20" height="20" [alt]="selectedCountryCode">
   </div>
   <span class="d-none d-lg-block" style="color: #314374;">{{ selectedCountryCode || 'US' }}</span>
</button>

<ng-template #countriesModalcontent let-modal>
   <div class="modal-header border-0 p-2 py-lg-3 px-lg-4">
      <h4 class="modal-title" id="modal-basic-title">Select Country</h4>
      <button type="button" class="btn shadow-none p-1 bg-light rounded-3 modal-header-close-btn" aria-label="Close"
         (click)="closeCountriesModal()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#000" fill="none">
            <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" stroke-width="2"
               stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
   </div>
   <div class="modal-body p-3 p-lg-4">
      <div>
         <div class="countries-grid" *ngIf="countriesLoader">
            <div class="skeleton-loader d-block pt-5 pb-3 w-100 rounded-3" *ngFor="let i of [].constructor(10)">
            </div>
         </div>

         <ul role="list" class="m-0 p-0 countries-grid" *ngIf="!countriesLoader">
            <li class="d-flex country-btns-wrapper p-0" *ngFor="let country of coveredCountries">
               <button class="btn w-100 d-flex shadow-none align-items-start gap-2 country-btn"
                  [ngClass]="selectedCountryCode === country.countryCode ? 'active-country' : ''"
                  (click)="onCountrySelect(country)">
                  <img [src]="'/assets/flags/1x1/' + country.countryCode.toLowerCase() + '.svg'" [alt]="country.country"
                     class="bg-light border flex-shrink-0 rounded-circle country-image" width="22px" height="22px">
                  <div class="d-grid ">
                     <h6 class="fw-500 mb-0 text-start">{{ country.country }}</h6>
                     <p class="mb-0 text-start">
                        {{ country.stockExchanges.join(', ') }}
                     </p>
                  </div>
               </button>
            </li>
         </ul>
      </div>
   </div>

</ng-template>






<!-- <div class="countries-dropdown-wrapper" (document:click)="onDocumentClick($event)">
   <button class="btn shadow-none px-1 d-flex align-items-center gap-2 countiries-dropdown-btn" (click)="openCountriesDropdown()">
      <div class="country-flag-wrapper rounded-circle flex-shrink-0 overflow-hidden bg-light">
         <img [src]="'/assets/flags/1x1/' + (selectedCountryCode.toLowerCase() || 'us') + '.svg'" class="img-fluid" [alt]="selectedCountryCode">
      </div>
      <span class="text-white d-none d-lg-block">{{ selectedCountryCode || 'US' }}</span>
   </button>
   <ng-container *ngIf="!isMobile && isCountriesMenuOpen">
      <div class="countries-dropdown-menu overflow-hidden">
         <h6 class="mb-0 bg-light">Select Country</h6>
         <ul role="list" class="p-2 countries-dropdown-list">
            <li class="d-block countries-dropdown-item" *ngFor="let country of coveredCountries">
               <button class="btn w-100 d-flex shadow-none align-items-center justify-content-between countries-dropdown-btn" [ngClass]="selectedCountryCode === country.countryCode ? 'active-country' : ''" (click)="onCountrySelect(country)">
                  <div class="d-flex align-items-center gap-2">
                     <div class="country-flag-wrapper rounded-circle flex-shrink-0 overflow-hidden border bg-light">
                        <img [appLazyLoadImage]="'/assets/flags/1x1/' + country.countryCode.toLowerCase() + '.svg'" placeholder="/assets/images/dashboard/stock-lowres.png" class="img-fluid" [alt]="country.countryCode">
                     </div>
                     <span class="country-name">{{ country.country }}</span>
                  </div>
                  <span class="fw-bold tick-icon" *ngIf="selectedCountryCode === country.countryCode">&#10003;</span>
               </button>
            </li>
         </ul>
      </div>
   </ng-container>
</div>

<ng-container *ngIf="isMobile">
   <div class="mobile-list-container" [ngClass]="isCountriesMenuOpen ? 'menu-open' : ''">
      <header class="d-flex p-3 align-items-center justify-content-between">
         <h5 class="mb-0">Select Country</h5>
         <button class="btn bg-light shadow-none rounded-3 p-2" (click)="closeCountriesMenu()">
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
               <path fill="#000" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
            </svg>
         </button>
      </header>
      <ul role="list" class="px-2 pt-2 pb-4 countries-dropdown-list">
         <li class="d-block countries-dropdown-item" *ngFor="let country of coveredCountries">
            <button class="btn w-100 px-2 d-flex shadow-none align-items-center justify-content-between countries-dropdown-btn" [ngClass]="selectedCountryCode === country.countryCode ? 'active-country' : ''" (click)="onCountrySelect(country)">
               <div class="d-flex align-items-center gap-2">
                  <div class="country-flag-wrapper rounded-circle flex-shrink-0 overflow-hidden border bg-light">
                     <img [appLazyLoadImage]="'/assets/flags/1x1/' + country.countryCode.toLowerCase() + '.svg'" placeholder="/assets/images/dashboard/stock-lowres.png" class="img-fluid" [alt]="country.countryCode">
                  </div>
                  <span class="country-name">{{ country.country }}</span>
               </div>
               <span class="fw-bold tick-icon" *ngIf="selectedCountryCode === country.countryCode">&#10003;</span>
            </button>
         </li>
      </ul>
   </div>
</ng-container>
 -->
