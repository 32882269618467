<header class="container d-flex align-items-center justify-content-between mobile-header">
   <div class="d-flex align-items-center">
      <i class="bi bi-arrow-left fs-5 me-3" [routerLink]="['/cabinet/onboarding', 'US']"></i>
      <!-- <ngx-skeleton-loader
         count="1" appearance="circle" animation="progress"
         [theme]="{ height: '20px', width: '100%', margin: '0px'}"
         *ngIf="!companyName">
      </ngx-skeleton-loader> -->
      <h6 class="mb-0 company-info" *ngIf="companyName">
         <span class="company-name me-1">{{(companyName ? companyName : '-') | textToCut}}</span>
         <span class="resp-slider-item company-ticker text-secondary">({{companyTicker ? companyTicker : '-'}})</span>
      </h6>
   </div>

   <div>
      <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="!checkRole('ANONYMOUS') && !isAddedInWatchlist" (click)="openAddListsModal()">
         <i class="bi bi-star"></i>
      </button>

      <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="checkRole('ANONYMOUS')" [routerLink]="['/authentication/sso-login']">
         <i class="bi bi-star"></i>
      </button>

      <button class="btn shadow-none d-flex align-items-center watchlist-btn" *ngIf="isAddedInWatchlist" (click)="openAddListsModal()">
         <i class="bi bi-star-fill"></i>
      </button>
   </div>

   <!-- <app-add-to-watchlist-btn [buttonType]="'icon'" (click)="openWatchListUi()"></app-add-to-watchlist-btn> -->

</header>
