<div class="bg-white d-block min-w-0 p-3 p-lg-4 rounded-3">
   <header class="d-flex align-items-center justify-content-between mb-3">
      <h6 class="text-uppercase mb-0 table-title">Market Status</h6>
   </header>
   <ng-container *ngIf="marketStatusLoader">
      <div class="px-2">
         <ngx-skeleton-loader
            count="5" appearance="line" animation="progress"
            [theme]="{ height: '40px', width: '100%', 'border-radius': '4px', 'margin-bottom': '0px'}">
         </ngx-skeleton-loader>
      </div>
   </ng-container>
   <div class="table-responsive" *ngIf="!marketStatusLoader">
      <table class="table mb-0">
         <thead>
            <tr>
               <th class="text-uppercase" scope="col">Country/Exchange</th>
               <th class="text-uppercase text-center" scope="col">Time</th>
               <th class="text-uppercase text-center" scope="col">Status</th>
            </tr>
         </thead>
         <tbody>
            <ng-container *ngIf="marketStatusData && marketStatusData.length > 0; else noMarketStatusData">
               <tr *ngFor="let market of marketStatusData; trackBy: trackByMarketStatusId">
                  <td class="align-middle p-0">
                     <a class="d-grid align-items-center text-decoration-none stock-exchange">
                        <p class="mb-0 line-clamp-1">{{ market.code }}</p>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-center text-decoration-none stock-time">
                        {{ market.trading_st }} - {{ market.trading_et }}
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center text-decoration-none stock-status ">
                        <span class="d-block text-center status-chip w-100 rounded-3 py-1 px-2" [ngClass]="market.market_status.is_open ? 'open' : 'closed'">
                           {{ market.market_status.is_open ? 'Open' : 'Closed' }}
                        </span>
                     </a>
                  </td>
               </tr>
            </ng-container>
            <ng-template #noMarketStatusData>
               <tr>
                  <td colspan="3" class="align-middle p-0">
                     <p class="mb-0 text-center p-3"> No Market Status Available</p>
                  </td>
               </tr>
            </ng-template>

         </tbody>
      </table>
   </div>
</div>




<!-- <div class="bg-white d-block min-w-0 p-3 p-lg-4 rounded-3">
   <header class="d-flex align-items-center justify-content-between mb-3">
      <h6 class="text-uppercase mb-0 table-title">Market Status</h6>
      <button class="btn d-flex shadow-none align-items-center gap-2 text-nowrap px-2 view-all-btn" [disabled]="marketStatusLoader" (click)="openMarketStatusModal()">
         <span>View All</span>
         <i class="bi bi-arrow-right arrow-right"></i>
      </button>
   </header>
   <ng-container *ngIf="marketStatusLoader">
      <div class="px-2">
         <ngx-skeleton-loader
            count="5" appearance="line" animation="progress"
            [theme]="{ height: '40px', width: '100%', 'border-radius': '4px', 'margin-bottom': '0px'}">
         </ngx-skeleton-loader>
      </div>
   </ng-container>
   <div class="table-responsive" *ngIf="!marketStatusLoader">
      <table class="table mb-0">
         <thead>
            <tr>
               <th class="text-uppercase" scope="col">Country/Exchange</th>
               <th class="text-uppercase text-center" scope="col">Time</th>
               <th class="text-uppercase text-center" scope="col">Status</th>
            </tr>
         </thead>
         <tbody>
            
            <ng-container *ngIf="displayedMarketStatus.length > 0">
               <tr *ngFor="let market of displayedMarketStatus">
                  <td class="align-middle p-0">
                     <a class="d-grid align-items-center text-decoration-none stock-exchange">
                        <p class="mb-0 line-clamp-1">{{ market.exchange.code }}</p>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-center text-decoration-none stock-time">
                        {{ market.exchange.trading_st }} - {{ market.exchange.trading_et }}
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center text-decoration-none stock-status ">
                        <span class="d-block text-center status-chip w-100 rounded-3 py-1 px-2" [ngClass]="market.is_open ? 'open' : 'closed'">
                           {{ market.is_open ? 'Open' : 'Closed' }}
                        </span>
                     </a>
                  </td>
               </tr>
            </ng-container>

            <ng-container *ngIf="displayedMarketStatus.length === 0">
               <tr>
                  <td colspan="3" class="align-middle p-0">
                     <p class="mb-0 text-center p-3"> No market Status for Selected Country</p>
                  </td>
               </tr>
            </ng-container>

         </tbody>
      </table>
   </div>
</div>


<app-modal-wrapper #wrapperModalContent [modalTitle]="'Market Status'">
   <div class="market-status-modal-content">
      <ng-container *ngIf="marketStatusLoader">
         <div class="px-2">
            <ngx-skeleton-loader
               count="5" appearance="line" animation="progress"
               [theme]="{ height: '40px', width: '100%', 'border-radius': '4px', 'margin-bottom': '0px'}">
            </ngx-skeleton-loader>
         </div>
      </ng-container>
      <div class="table-responsive" *ngIf="!marketStatusLoader">
         <table class="table modal-table mb-0">
            <thead>
               <tr>
                  <th class="text-uppercase" scope="col">Country/Exchange</th>
                  <th class="text-uppercase text-center" scope="col">Time</th>
                  <th class="text-uppercase text-center" scope="col">Status</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let market of allMarketsData">
                  <td class="align-middle p-0">
                     <a class="d-grid align-items-center text-decoration-none stock-exchange">
                        <p class="mb-0 line-clamp-1">{{ market.exchange.code }} ({{ market.country_code }}) </p>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-center text-decoration-none stock-time">
                        {{ market.exchange.trading_st }} - {{ market.exchange.trading_et }}
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center text-decoration-none stock-status ">
                        <span class="d-block text-center status-chip w-100 rounded-3 py-1 px-2" [ngClass]="market.is_open ? 'open' : 'closed'">
                           {{ market.is_open ? 'Open' : 'Closed' }}
                        </span>
                     </a>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</app-modal-wrapper> -->