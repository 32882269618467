import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, throwError } from 'rxjs';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { environment } from 'src/environments/environment';
import { TYPESENSE_COLLECTIONS } from 'src/app/shared/constants/typesense-collections';
import {
   CollectionBucket,
   CollectionBucketsApiResponse,
} from '../models/buckets-collection-api-response.model';
import {
   CollectionSymbolData,
   CollectionSymbolsApiResponse,
   CollectionSymbolsHit,
} from '../models/collection-symbols-api-response.model';
import { FormattedCollectionSymbols } from '../models/formatted-halal-collection-symbols.model';
import { SectorIndustries, SectorIndustriesCollectionResponse, SectorIndustriesHits } from 'src/app/models/sector-industries/sector-industries.model';

@Injectable({
   providedIn: 'root',
})
export class HalalCollectionService {
   private collectionBuckets$!: Observable<CollectionBucket[]>;

   private sectorIndustries$!: Observable<SectorIndustries[]>;

   constructor(
      private http: HttpClient,
      private typesenseService: TypesenseService
   ) { }

   getCollectionBuckets(): Observable<CollectionBucket[]> {
      this.collectionBuckets$ =
         this.collectionBuckets$ ??
         this.fetchCollectionBuckets().pipe(shareReplay());
      return this.collectionBuckets$;
   }

   private fetchCollectionBuckets() {
      let queryParams = new HttpParams()
         .set('per_page', '250')
         .set('q', '*')
         .set('sort_by', 'sort_order:asc')
         .set('filter_by', 'status:=published');

      return this.http
         .get<CollectionBucketsApiResponse>(
            `${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.HALAL_COLLECTION}/documents/search`,
            { params: queryParams }
         )
         .pipe(
            map((response) => response.hits.map((bucket) => bucket.document)),
            catchError((error) => {
               console.error('Error fetching bucket list', error);
               return throwError(() => error);
            })
         );
   }

   getBucketEtfCollectionSymbols(country: string, collectionId: string, pageNo: number, filterFields: Array<({ key: string; value: string; } | { key: string; value: number[]; })> = [], sortColumn: string, sortOrder: string, hasPermission: boolean = true): Observable<FormattedCollectionSymbols> {
      const perPage = 250;
      let filterBy = `country:=${country}&&halal_collection_id:=${collectionId}`;

      if (filterFields.length > 0) {
         const additionalFilters = filterFields.map(({ key, value }) => {
            if (Array.isArray(value)) {
               return `${key}:=[${value.join(',')}]`;
            } else {
               return `${key}:=${value}`;
            }
         }).join('&&');

         filterBy += `&&$etfs_data(${additionalFilters})`;
      }

      const etfsDataFields = [
         'id',
         'symbol',
         'shariahCompliantStatus',
         'musaffaHalalRating',
         'assetClass',
         'calculated_investment_segment',
         'aum',
         'price',
         '52WeekHigh',
         '52WeekLow',
         'volume',
         'currency'
      ];

      const searches = [
         {
            q: "*",
            collection: TYPESENSE_COLLECTIONS.HALAL_COLLECTION_ETF_SYMBOLS,
            include_fields: `$etfs_data(${etfsDataFields.join(',')})`,
            filter_by: filterBy,
            sort_by: `$etfs_data(${sortColumn}:${sortOrder})`,
            per_page: perPage,
            page: pageNo
         }
      ];

      return this.http.post<CollectionSymbolsApiResponse>(`${environment.typeSense.url}/multi_search`, { searches })
         .pipe(
            map((response) => {
               const data = response.results[0];
               const items: { [key: string]: any } = {};
               data?.hits?.forEach((hit: any) => {
                  const doc = hit.document;
                  if (doc.etfs_data.shariahCompliantStatus === 'COMPLIANT') {
                     items[doc.symbol] = doc.etfs_data;
                  }
               })
               return {
                  totalCount: data.found,
                  items: items,
               }
            }),
            catchError((error) => {
               console.error('Error fetching bucket collection data', error);
               return throwError(() => error);
            })

         )
   }

   getBucketCollectionSymbols(country: string, collectionId: string, pageNo: number, filterFields: Array<({ key: string; value: string; } | { key: string; value: number[]; })> = [], sortColumn: string, sortOrder: string, hasPermission: boolean = true): Observable<FormattedCollectionSymbols> {
      const perPage = 15;
      let filterBy = `country:=${country}&&halal_collection_id:=${collectionId}`;

      if (filterFields.length > 0) {
         const additionalFilters = filterFields.map(({ key, value }) => {
            if (Array.isArray(value)) {
               return `${key}:=[${value.join(',')}]`;
            } else {
               return `${key}:=${value}`;
            }
         }).join('&&');

         filterBy += `&&$stocks_data(${additionalFilters})`;
      }

      const stocksDataFields = [
         'marketCapClassification',
         'sharia_compliance',
         'ranking',
         'ranking_v2',
         'country',
         'sector',
         'industry',
         'marketcap',
         'musaffaSector',
         'musaffaIndustry',
         'currentPrice',
         'priceChange1D',
         'priceChange1DPercent',
         '52WeekHigh',
         '52WeekLow',
         'currentDividendYieldTTM',
         'peShareAnnual',
         'recommendationWeightedAverage',
         'analyst_recommendation_weighted_avg'
      ];

      const searches = [
         {
            q: "*",
            collection: TYPESENSE_COLLECTIONS.HALAL_COLLECTION_SYMBOLS,
            include_fields: `$stocks_data(${stocksDataFields.join(',')})`,
            filter_by: filterBy,
            sort_by: `$stocks_data(${sortColumn}:${sortOrder})`,
            per_page: perPage,
            page: pageNo
         }
      ];

      return this.http.post<CollectionSymbolsApiResponse>(`${environment.typeSense.url}/multi_search`, { searches })
         .pipe(
            map((response) => {
               const data = response.results[0];
               const items: { [key: string]: any } = {};
               // console.log(data)
               data?.hits?.forEach((hit: any) => {
                  const doc = hit.document;
                  if (doc.stocks_data.sharia_compliance === 'COMPLIANT') {
                     items[doc.symbol] = this.typesenseService.mapPricesData(doc.stocks_data);
                  }
               })
               return {
                  totalCount: data.found,
                  items: items,
               }
            }),
            catchError((error) => {
               console.error('Error fetching bucket collection data', error);
               return throwError(() => error);
            })

         )
   }

   copyToWatchlist(id: string, name: string, country: string) {
      return this.http.post(
         `${environment.apiUrl}/api/halal-collection/${id}/create-watchlist`,
         {
            name: name,
            country: country,
         }
      );
   }

   getCollectionBucketColumns() {
      return [
         { header: 'Compliance', key: 'status' },
         { header: 'Halal Ratings', key: 'complianceRanking' },
         { header: 'Country', key: 'country' },
         { header: 'Sector', key: 'sector' },
         { header: 'Industry', key: 'industry' },
         { header: 'Market Cap', key: 'marketCapitalization' },
         { header: 'Price', key: 'price' },
         { header: 'Price Change', key: 'priceChangePercent' },
      ];
   }

   getSectorIndustries(): Observable<SectorIndustries[]> {
      this.sectorIndustries$ = this.sectorIndustries$ ?? this.fetchSectorIndustries().pipe(shareReplay());
      return this.sectorIndustries$;
   }

   private fetchSectorIndustries(): Observable<SectorIndustries[]> {
      const queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return this.http.get<SectorIndustriesCollectionResponse>(`${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.MUSAFFA_SECTOR}/documents/search`, { params: queryParams }).pipe(
         map((response) => response.hits.map((hits: SectorIndustriesHits) => hits.document) as SectorIndustries[])
      );
   }
}
