<div class="container-md px-0">
   <div class="d-flex">
      <ul class="resp-slider btns-wrapper p-0 align-items-center" #tabsContainer (wheel)="onScroll($event)">
         <ng-container *ngIf="tabs">
            <li class="resp-slider-item" *ngFor="let tab of tabs">
               <button 
                  [id]="'tab-button-identifier-' + tab.cik" 
                  [ngClass]="activeInvestorId === tab.cik ? 'activeClass' : ''"
                  class="btn d-inline-block tab-button-identifier tab-button rounded-0 shadow-none"
                  (click)="navigateTo(tab.cik)">
                  <span>{{tab.manager}}</span>
               </button>
            </li>
         </ng-container>

         <ng-container *ngIf="paramTabs">
            <li class="resp-slider-item" *ngFor="let tab of paramTabs">
               <button 
                  [id]="'tab-button-identifier-' + tab.cik" 
                  [ngClass]="activeInvestorId === tab.cik ? 'activeClass' : ''"
                  class="btn d-inline-block tab-button-identifier tab-button rounded-0 shadow-none"
                  (click)="navigateToSuperInvestorWithParam(tab.cik, paramSymbol)">
                  <span>{{tab.name}}</span>
               </button>
            </li>
         </ng-container>
      </ul>
   </div>
</div>


