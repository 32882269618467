<div class="modal-body text-center">
   <ng-container *ngIf="content === 'deleteList'">
      <h4 class="mb-0 mx-auto">
         Are you sure you want to delete this Public list?
      </h4>
      <p class="mb-0 mx-auto">
         The followers will lose access to your list once deleted.
      </p>
      <div class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3 gap-lg-4 mt-2">
         <button class="btn rounded-3 shadow-none outlined-button" (click)="activeModal.dismiss()">
            Cancel
         </button>
         <button class="btn d-flex align-items-center gap-2 rounded-3 shadow-none filled-button"
            [disabled]="buttonLoader" (click)="deleteList()">
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="buttonLoader">
               <span class="visually-hidden">Loading...</span>
            </div>
            <span>
               {{ buttonLoader ? 'Deleting' : 'Delete'}}
            </span>
         </button>
      </div>
   </ng-container>

   <ng-container *ngIf="content === 'copyToWatchlist'">
      <ng-container *ngIf="!isCopiedToWatchlist">
         <h4 class="mb-0 mx-auto">
            Do you want to add this Public list to your Watchlist?
         </h4>
         <p class="mb-0 mx-auto">
            This public list with all the stocks will be added to your watchlist
         </p>
         <form #addToWatchlistForm="ngForm" (ngSubmit)="createWatchlist(addToWatchlistForm)">
            <div class="my-3 list-input-group">
               <label for="watchlistName" class="form-label text-start d-block mb-0">Watchlist Name</label>
               <input type="text" autocomplete="off" class="form-control shadow-none rounded-0" id="watchlistName"
                  placeholder="Enter your watchlist name" name="watchlistName" [(ngModel)]="form.watchlistName"
                  required />
               <small *ngIf="copyToWatchlistError" class="alert d-flex gap-2 py-2 px-3 mt-1 mb-0 alert-danger">
                  <i class="bi bi-exclamation-triangle"></i>{{ copyToWatchlistError }}
               </small>
            </div>
            <div class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3 gap-lg-4 mt-2">
               <button type="button" class="btn rounded-3 shadow-none outlined-button" (click)="activeModal.dismiss()">
                  Cancel
               </button>
               <button type="submit" class="btn rounded-3 shadow-none filled-button"
                  [disabled]="!addToWatchlistForm.valid || buttonLoader">
                  <div class="spinner-border spinner-border-sm" role="status" *ngIf="buttonLoader">
                     <span class="visually-hidden">Loading...</span>
                  </div>
                  {{ buttonLoader ? 'Adding' : 'Add' }}
               </button>
            </div>
         </form>
      </ng-container>

      <ng-container *ngIf="isCopiedToWatchlist">
         <div class="d-flex align-items-center justify-content-center mb-3 success-image">
            <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M50 0C22.4299 0 0 22.4299 0 50C0 77.5701 22.4299 100 50 100C77.5701 100 100 77.5701 100 50C100 22.4299 77.5701 0 50 0ZM50 96.875C24.1529 96.875 3.125 75.8469 3.125 50C3.125 24.1531 24.1529 3.125 50 3.125C75.8471 3.125 96.875 24.1531 96.875 50C96.875 75.8469 75.8471 96.875 50 96.875ZM75.0691 32.8617C75.3621 33.1547 75.5267 33.5521 75.5267 33.9665C75.5267 34.3809 75.3621 34.7783 75.0691 35.0713L45.1506 64.9898C45.0055 65.135 44.8332 65.2501 44.6436 65.3286C44.454 65.4072 44.2508 65.4476 44.0456 65.4476C43.8404 65.4476 43.6372 65.4072 43.4476 65.3286C43.258 65.2501 43.0857 65.135 42.9406 64.9898L27.0793 49.1285C26.7863 48.8355 26.6217 48.438 26.6217 48.0236C26.6218 47.6092 26.7864 47.2118 27.0795 46.9188C27.3726 46.6257 27.77 46.4612 28.1844 46.4612C28.5988 46.4612 28.9963 46.6259 29.2893 46.9189L44.0457 61.6754L72.8594 32.8617C73.0045 32.7166 73.1767 32.6015 73.3663 32.523C73.5559 32.4445 73.7591 32.404 73.9643 32.404C74.1695 32.404 74.3726 32.4445 74.5622 32.523C74.7518 32.6015 74.924 32.7166 75.0691 32.8617Z"
                  fill="#1BAD4E" />
            </svg>
         </div>
         <h4 class="mb-0 mx-auto">
            List copied to your Watchlist!
         </h4>
         <p class="mb-0 mx-auto">
            A new watchlist is created and all the stocks from this public list are now copied to that watchlist.
         </p>
         <div class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3 gap-lg-4 mt-2">
            <button type="button" class="btn rounded-3 shadow-none outlined-button" (click)="activeModal.dismiss()">
               Close
            </button>
            <button type="button" class="btn rounded-3 shadow-none filled-button" (click)="navigateToWatchlist()">
               Open Watchlist
            </button>
         </div>
      </ng-container>
   </ng-container>



</div>