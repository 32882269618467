import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appElementLoaded]',
})
export class ElementLoadedDirective implements OnDestroy {
  private changes: MutationObserver;

  @Output() onElementLoaded = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => this.onElementLoaded.emit(mutation));
      }
    );

    this.changes.observe(element, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
