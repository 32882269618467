<article class="calculate-portfolio-card">
   <section class="portfolio-container container">
      <div class="text-wrapper">
         <h2 class="mb-0 card-h2">{{heading}}</h2>
         <p class="mb-0 card-p">{{content}}</p>
         <!-- <h2 class="mb-0 card-h2">Calculate Your Entire Portfolio</h2>
         <p class="mb-0 card-p">Connect your brokerage accounts to automatically calculate & track the purification
            amounts.</p> -->
         <!-- <button class="btn gradient-btn d-flex align-items-center gap-2 text-white rounded-3 shadow-none mt-2 py-2 px-4 card-btn fw-500" (click)="linkPortfolioAccount()">
         <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="linkLoader">
            <span class="visually-hidden">Loading...</span>
         </div>
         <span>{{ linkLoader ? 'Connecting...' : 'Connect Your Account' }}</span>
         </button> -->

         <div
            class="d-flex gap-lg-3 gap-1 justify-content-lg-start justify-content-center align-items-center flex-lg-row flex-column">
            <button
               class="btn gradient-btn d-flex align-items-center gap-3 text-white rounded-3 shadow-none mt-2 py-lg-3 py-2 lh-1 px-4 fw-500"
               (click)="handleConnectBtn()">
               <div class="text-center d-flex gap-3 align-items-center" *ngIf="linkLoader">
                  <div class="spinner-border spinner-border-sm" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
                  <span class="block py-1">Connecting...</span>
               </div>
               <span *ngIf="!linkLoader" class="block">Connect your account</span>
               <svg *ngIf="!linkLoader" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#138D72" />
                  <g clip-path="url(#clip0_2838_4854)">
                     <path d="M9.81153 6.89562C9.6667 6.89477 9.5249 6.93708 9.40421 7.01714C9.28352 7.09721 9.1894 7.2114 9.13387 7.34516C9.07833 7.47892 9.06388 7.62619 9.09237 7.7682C9.12086 7.9102 9.19099 8.0405 9.29382 8.14249L13.1584 11.9998L9.29382 15.8571C9.15652 15.9944 9.07938 16.1806 9.07938 16.3748C9.07938 16.4709 9.09832 16.5661 9.13511 16.655C9.17191 16.7438 9.22584 16.8245 9.29382 16.8925C9.36181 16.9605 9.44252 17.0144 9.53135 17.0512C9.62018 17.088 9.71539 17.1069 9.81153 17.1069C10.0057 17.1069 10.1919 17.0298 10.3292 16.8925L14.7042 12.5175C14.84 12.3809 14.9163 12.1961 14.9163 12.0034C14.9163 11.8108 14.84 11.626 14.7042 11.4894L10.3292 7.11437C10.2617 7.04547 10.1812 6.99066 10.0923 6.95311C10.0034 6.91556 9.90801 6.89602 9.81153 6.89562Z" fill="white" />
                  </g>
                  <defs>
                     <clipPath id="clip0_2838_4854">
                        <rect width="7" height="10.5" fill="white" transform="matrix(1 0 0 -1 8.5 17.25)" />
                     </clipPath>
                  </defs>
               </svg>
            </button>
            <button (click)="scrollToViewBrokerges()" class="btn brokerage-btn shadow-none">
               View Supported Brokerages
            </button>
         </div>
      </div>
      <div class="link-image-wrapper">
         <img [src]="imageUrl" class="d-block img-fluid link-image" alt="Link Portfolio IMage">
      </div>
   </section>
</article>