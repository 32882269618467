import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from '../../services/access-control.service';
import { AnalyticsUtils } from '../../services/analytics-utils';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { SubscriptionService } from '../../services/subscription.service';
import { UserApiService } from '../../services/user-api.service';
import { EtfSamplePopupComponent } from '../etf-sample-popup/etf-sample-popup.component';
import { GeneralInfoPopupComponent } from '../general-info-popup/general-info-popup.component';
import { PaywallPopupComponent } from '../paywall-popup/paywall-popup.component';
import { RequestCoveragePopupComponent } from '../request-coverage-popup/request-coverage-popup.component';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-etf-compliance-status-card',
  templateUrl: './etf-compliance-status-card.component.html',
  styleUrls: ['./etf-compliance-status-card.component.scss']
})
export class EtfComplianceStatusCardComponent implements OnInit {

   @Input() stockType: string = 'etf';
   @Input() complianceStatus!: string;
   @Input() complianceRanking!: number;
   @Input() reportFormat!: string;
   @Input() resultScreeningInfo!: any;
   @Input() resultSecuritiesAndAssets!: any;
   @Input() resultDebt!: any;
   @Input() complianceData: any;
   @Input() ticker: any;
   @Input() isin: any;
   @Input() countryCode: any;
   @Input() etfAssetClass: any;
   @Input() data: any;
   reportUpdateDate: any;

   @Output() openReportModal = new EventEmitter<void>();
   @Output() goToComplianceTab = new EventEmitter<void>();

   userRole: string | null = null;
   isUserLoggedIn: boolean = false;
   isUserPremium: boolean = false;
   isCoverageRequested: boolean = false;
   coverageRequestLimitReached: boolean = false;
   coverageRequestLimit: number = 3;
   private subscriptions: Subscription[] = [];
   coverageModalRef: any;
   paywallModal: any;
   etfSampleModalRef: any;
   showRanking: boolean = false;
   complianceHistoryRef!: NgbModalRef;
   @ViewChild('showCompliantHistory', { static: true })
   showCompliantHistory!: TemplateRef<NgbModal>;
   complianceHistorydata: any;
   isComplianceHistoryLoading: boolean = false;
   confirmModalRef!: NgbModalRef;
   requestLoading: boolean = false;
   configRequestUpdationDefaults: any;
   canRequestStockCoverage: boolean = false;
   canRequestStockComplianceUpdate: boolean = false;
   canReadComplianceHistory: boolean = false;
   showComplianceReport: boolean = false;
   ratingLoader: boolean = true

   constructor(
      private auth: AuthService,
      private firebaseConfig: FirebaseRemoteConfigService,
      public subscriptionService: SubscriptionService,
      public userApiService: UserApiService,
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils,
      private apiService: ApiService,
      private toaster: ToastrService,
      private datePipe: DatePipe,
      private authService: AuthService,
      private router: Router,
      private sharedService: SharedService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService
   ) {}

   async ngOnInit(): Promise<void> {
      this.isUserLoggedIn = !!this.auth.getUserId();

      setTimeout(() => {
         this.ratingLoader = false
      }, 100);

      // this.subscriptions.push(SubscriptionService.subscriptionData.subscribe(async (data: SubscriptionInfoModel) => {
      //    this.isUserPremium = data.isSubscribed
      //    this.userRole = data.role
      // if(this.userRole !== 'PREMIUM') {
      //    const unlockedSymbols: Array<string> = (await this.firebaseConfig.getFreeStocksList())
      //       .concat(await this.firebaseConfig.getFreeEtfList())
      //       .concat(await this.firebaseConfig.getBucketList())

      //    this.showRanking = unlockedSymbols.includes(this.stockType === 'etf' ? this.ticker : this.isin);
      //  } else {
      //    this.showRanking = true;
      //  }
      // }))
      this.subscriptions.push(
         this.permissionStore
            .select(({ permissions }) => permissions.permissions)
            .subscribe(async (permissions) => {
               // console.log(permissions)
               this.canRequestStockCoverage =
                  permissions.stock_coverage_request;
               this.canRequestStockComplianceUpdate =
                  permissions.stock_compliance_update_request;
               this.canReadComplianceHistory =
                  permissions.stock_shariah_compliance_history;
               this.showComplianceReport = permissions.stock_compliance_report

               const canReadStockHalalRating = permissions.stock_halal_rating;
               const canReadEtfHalalRating = permissions.etf_halal_rating;
               if (this.stockType === 'etf') {
                  if (canReadStockHalalRating) {
                     this.showRanking = true;
                  } else {
                     const unlockedSymbols: Array<string> = (
                        await this.firebaseConfig.getFreeStocksList()
                     ).concat(await this.firebaseConfig.getBucketList());
                     this.showRanking = unlockedSymbols.includes(this.ticker);
                  }
               } else {
                  if (canReadEtfHalalRating) {
                     this.showRanking = true;
                  } else {
                     const unlockedSymbols: Array<string> =
                        await this.firebaseConfig.getFreeEtfList();
                     this.showRanking = unlockedSymbols.includes(this.ticker);
                  }
               }
            })
      );
      if (this.isUserLoggedIn) {
         this.setHasCoverageRequest();
         // this.setTodaysCoverageLimitCheck()
      }

      // if (this.userRole !== 'ANONYMOUS') {
      //    this.isComplianceHistoryLoading = true;
      //    this.apiService
      //       .getComlianceHistory((this.ticker || 'AAPL') as string)
      //       .then((res) => {
      //          this.complianceHistorydata = res.data;
      //          this.isComplianceHistoryLoading = false;
      //       });
      // }
   }

   async setHasCoverageRequest() {
      if (this.complianceStatus === 'NOT_UNDER_COVERAGE') {
         const hasRequest = await this.userApiService.hasCoverageRequestFor(
            this.ticker
         );
         this.isCoverageRequested =
            hasRequest?.data?.coverage_requested ?? false;
      }
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   // checkRole(role: string): boolean {
   //    return this.auth.checkRole(role);
   // }

   showReport() {
      this.openReportModal.emit();
   }

   handleComplianceReportTabVisibility() {
      if (
         this.etfAssetClass === 'Fixed Income' ||
         (this.etfAssetClass === 'Equity' && this.complianceStatus === 'NOT_UNDER_COVERAGE') ||
         (this.etfAssetClass === 'Equity' && this.complianceStatus === 'LOCKED')
      ) {
         return false;
      }
      return true
   }

   canViewScreeningReport() {
      if ( this.complianceStatus === 'NOT_UNDER_COVERAGE' || this.complianceStatus === 'LOCKED' )
         return false;
      if ( this.resultScreeningInfo || this.resultSecuritiesAndAssets || this.resultDebt )
         return true;
      else return false;
   }

   async setTodaysCoverageLimitCheck() {
      const myRequests: any = await this.userApiService.myCoverageRequests(
         new Date(),
         undefined,
         true
      );
      this.coverageRequestLimitReached =
         myRequests.data.length >= this.coverageRequestLimit;
   }

   handleButtonClick() {
      this.goToComplianceTab.emit();
   }

   handleRatingPopup() {
      if(this.isUserLoggedIn) {
         this.openUpgradePopup()
      } else {
         this.accessControlService.accessDenied();
         // this.openLoginPopup()
      }
   }

   openLoginPopup() {
      // this.authService.openAuthPopup()
      this.accessControlService.accessDenied();
   }

   openUpgradePopup() {
      // this.sharedService.openUpgradePopup('report')
      this.accessControlService.accessDenied();
   }

   openPaywallModal(upgradePlace: string) {
      this.analyticsUtils.feature_upgrade_tapped(upgradePlace);
      this.openUpgradePopup()
      // this.paywallModal = this.modalService.open(PaywallPopupComponent, {
      //    centered: true,
      //    modalDialogClass: 'paywall-popup-modal',
      //    size: 'xl',
      // });
   }

   openCoverageModal() {
      this.analyticsUtils.request_coverage_tapped(
         this.countryCode,
         this.ticker
      );
      this.coverageModalRef = this.modalService.open(
         RequestCoveragePopupComponent,
         {
            centered: true,
            backdrop: 'static',
         }
      );
      this.coverageModalRef.componentInstance.ticker = this.ticker;
      this.coverageModalRef.componentInstance.countryCode = this.countryCode;
      // this.coverageModalRef.componentInstance.error = !this.isCoverageRequested && this.coverageRequestLimitReached;
      // this.coverageModalRef.componentInstance.isLimitReached = this.coverageRequestLimitReached;
      this.coverageModalRef.componentInstance['stockRequested'].subscribe(
         () => {
            this.setHasCoverageRequest();
         }
      );
   }

   featureUpgradeTapped(text: string) {
      this.analyticsUtils.feature_upgrade_tapped(text);
   }

   getQuarterText() {
      var quarter = '';
      if (
         ['Annual', 'ANNUAL'].includes(this.complianceData?.reportTypeSection)
      ) {
         var text = `${this.complianceData?.reportTypeYear} Annual Report`;
         return text;
      } else {
         switch (this.complianceData?.reportTypeSection) {
            case 'firstQuarter':
            case 'FIRST_QUARTER':
               quarter = '1st';
               break;
            case 'secondQuarter':
            case 'SECOND_QUARTER':
               quarter = '2nd';
               break;
            case 'thirdQuarter':
            case 'THIRD_QUARTER':
               quarter = '3rd';
               break;
            case 'fourthQuarter':
            case 'FOURTH_QUARTER':
               quarter = '4th';
               break;
            default:
               quarter = '';
         }

         var text = `${this.complianceData?.reportTypeYear} ${quarter} Quarter Report`;
         return text;
      }
   }

   openComplianceHistoryModal(): void {
      if (this.canReadComplianceHistory) {
         this.complianceHistoryRef = this.modalService.open(
            this.showCompliantHistory,
            {
               centered: true,
               // scrollable: true
            }
         );
      } else {
         this.accessControlService.accessDenied();
      }
   }

   closeComplianceHistoryModal(): void {
      if (this.complianceHistoryRef) {
         this.complianceHistoryRef.dismiss();
      } else {
         this.accessControlService.accessDenied();
      }
   }

   openConfirmationPopup(content: any) {
      if (this.canRequestStockComplianceUpdate) {
         this.confirmModalRef = this.modalService.open(content, {
            centered: true,
         });
      } else {
         this.accessControlService.accessDenied();
      }
   }

   closeConfirmationPopup() {
      if (this.confirmModalRef) {
         this.confirmModalRef.dismiss();
      }
   }

   reportRequest() {
      if (this.ticker) {
         this.closeConfirmationPopup();
         this.requestLoading = true;
         let modal: any;
         this.apiService
            .updateComplianceChangeRequest(this.ticker)
            .then((data) => {
               if (data.status) {
                  modal = this.modalService.open(GeneralInfoPopupComponent, {
                     centered: true,
                  });
                  modal.componentInstance['title'] = 'Request Sent';
                  modal.componentInstance['description'] =
                     this.configRequestUpdationDefaults[
                        'updation_requested_label'
                     ];
               } else {
                  switch (data.status_code) {
                     case 'upgrade_acc':
                        break;
                     case 'not_covered':
                        modal = this.modalService.open(
                           GeneralInfoPopupComponent,
                           {
                              centered: true,
                           }
                        );
                        modal.componentInstance['title'] =
                           'Stock is not covered';
                        modal.componentInstance['description'] =
                           'Stock has to be covered to make this request';
                        break;
                     case 'recently_analyzed':
                        this.toaster.error('Stock was analyzed recently');
                        break;
                     case 'limit_reached':
                        modal = this.modalService.open(
                           GeneralInfoPopupComponent,
                           {
                              centered: true,
                           }
                        );
                        modal.componentInstance['title'] =
                           this.configRequestUpdationDefaults[
                              'limit_reached_label'
                           ]?.title;
                        modal.componentInstance['description'] =
                           this.configRequestUpdationDefaults[
                              'limit_reached_label'
                           ]?.description;
                        break;
                     case 'error':
                        // this.toaster.error('Something went wrong');
                        break;
                     case 'request_exists':
                        modal = this.modalService.open(
                           GeneralInfoPopupComponent,
                           {
                              centered: true,
                           }
                        );
                        modal.componentInstance['title'] =
                           'Request Already Exists';
                        modal.componentInstance['description'] =
                           'Your request to update report of this stock already exists';
                        break;
                  }
               }
            })
            .catch((e) => {
               console.log(e);
               // this.toaster.error('Something went wrong');
            })
            .finally(() => {
               this.requestLoading = false;
            });
      }
   }

   getLastUpdated() {
      return this.datePipe.transform(
         this.data?.lastUpdate * 1000,
         'MMMM dd, yyyy'
      );
   }

}
