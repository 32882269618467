import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';
import { CountryService } from './country.service';
import { Country } from 'src/app/models/market-collection/market-collection.model';
import { FilterOption } from 'src/app/models/filter-data.model';
import { SectorIndustries } from 'src/app/models/sector-industries/sector-industries.model';
import { StockFilterService } from 'src/app/features/stock-screener/services/stock-filter.service';

@Injectable({
   providedIn: 'root',
})
export class FilterDataService {
   private allCountries: Country[] = [];
   private allSectors: SectorIndustries[] = [];

   constructor(
      private http: HttpClient,
      private countryService: CountryService,
      private stockFilterService: StockFilterService
   ) {}

   public async getExchangeList(
      countryCode: string | undefined
   ): Promise<FilterOption[]> {
      if (!countryCode) {
         countryCode = 'US';
      }
      this.allCountries = await lastValueFrom(
         this.countryService.getCountries()
      );
      const country = this.allCountries.find(
         (c) => c.countryCode === countryCode
      );
      if (country) {
         return Promise.resolve(
            country.stockExchanges.map((exchange) => ({
               value: exchange,
               label: exchange,
            }))
         );
      }
      return Promise.resolve([]);
   }

   public async getSectorList(): Promise<FilterOption[]> {
      if(this.allSectors.length === 0) {
         this.allSectors = (
            await lastValueFrom(this.stockFilterService.getSectorIndustries())
         )
      }
      return Promise.resolve(this.allSectors.map((sector) => ({
         value: sector.id,
         label: sector.musaffaSector
      })));
   }

   public async getIndustryList(sectorIds: string[]): Promise<FilterOption[]> {
      if(this.allSectors.length === 0) {
         this.allSectors = (
            await lastValueFrom(this.stockFilterService.getSectorIndustries())
         )
      }
      const industriesResponse = this.allSectors.filter((sector) => sectorIds.includes(sector.id));
      const industries: string[] = [];
      industriesResponse.forEach((sector) => {
         industries.push(...sector.musaffaIndustries);
      });

      if(industries) {
         return Promise.resolve(industries.map((industry) => ({
            value: industry,
            label: industry
         })));
      }
      return Promise.resolve([]);
   }
}

