<!-- <div class="report-data-container p-0" *ngIf="results?.shariahStates == 'LOCKED'">
   <h2 class="mb-0 p-3 p-lg-4 text-lg-center screener-section-title">Screening Report</h2>
   <div class=" d-flex align-items-center mx-lg-auto my-lg-4 justify-content-center status-div text-center locked-status">
      <img src="../../../../assets/images/complanise-locked.svg" alt="locke image" class="lock-image me-3">
      <div class="locked-text">Locked</div>
   </div>
   <div class="status-text-container">
      <p class="mb-0 text-center">
         Sign up now and get Premium access
      </p>
   </div>
   <div class="btns-container d-flex justify-content-center">
      <button class="btn report-button shadow-none text-white fw-bold" [routerLink]="['/authentication/sso-login']">
         Sign up for free
      </button>
   </div>
</div> -->

<div class="report-data-container p-0" *ngIf="results?.shariahStates == 'NOT_UNDER_COVERAGE'">
   <h2 class="mb-0 p-3 p-lg-4 text-lg-center screener-section-title">Screening Report</h2>
   <div class=" d-flex align-items-center mx-lg-auto my-lg-4 justify-content-center status-div text-center not-covered-status">
      <div class="locked-text">Not Covered</div>
   </div>
</div>


<div class="tooltip__app border br-10" *ngIf="handleReportVisibility(results?.shariahStates)">
   <div class="tooltip__main">
      <!-- <div class="tooltip__censel">
         <div class="cencel__pc">
            <img src="../../../../assets/images/group-343.svg" alt="" (click)="closeMe()" class="desctop__left-svg">
         </div>


         <i class="bi bi-arrow-left" (click)="closeMe()"></i>
      </div> -->
      <div class="companiy__main" *ngIf="profile && results">
         <div class="company">
            <div class="company__name">
               <div class="company__titles d-flex gap-3">
                  <h2>{{profile.name}} ({{profile.ticker}})</h2>
                  <span>
                     <div class="halalstoks text-nowrap" *ngIf="results?.shariahStates == 'COMPLIANT'">
                        <span>HALAL</span>
                     </div>
                     <div class="nothalalstocks text-nowrap" *ngIf="results?.shariahStates == 'NON_COMPLIANT'">
                        <span>Not Halal</span>
                     </div>
                     <div class="Questionable text-nowrap" *ngIf="results?.shariahStates == 'QUESTIONABLE'">
                        <span>Doubtful</span>
                     </div>
                     <div class="Not-Under text-nowrap" *ngIf="results?.shariahStates == 'NOT_UNDER_COVERAGE'">
                        <span>Coming Soon</span>
                     </div>
                  </span>
               </div>
               <div class="company__text">
                  <p>Shariah stock screening methodology: AAOIFI </p>
                  <p>Report date: {{results?.reportDate | date:'dd MMM, yyyy'}}</p>
                  <p>Last updated: {{results?.lastUpdate | date:'dd MMM, yyyy'}}</p>
               </div>

            </div>
            <div class="company__status-main" *ngIf="results?.shariahStates == 'COMPLIANT'">
               <div class="company__status" placement="bottom"
                  ngbTooltip="Musaffa Halal Rating is a proprietary ranking metric that compares all stocks in a given country from lowest to highest Shariah compliance standing."
                  tooltipClass="custom__class">

                  <img src="../../../../assets/images/status-1.svg" alt="" *ngIf="results?.compliantRanking == 1">
                  <img src="../../../../assets/images/status-2.svg" alt="" *ngIf="results?.compliantRanking == 2">
                  <img src="../../../../assets/images/status-3.svg" alt="" *ngIf="results?.compliantRanking == 3">
                  <img src="../../../../assets/images/status-4.svg" alt="" *ngIf="results?.compliantRanking == 4">
                  <img src="../../../../assets/images/status-5.svg" alt="" *ngIf="results?.compliantRanking == 5">
               </div>
               
            </div>
            

         </div>

         <div class="screening">


            <div class="screening__main" *ngIf="isBusinessScreeningAvailable()">
               <div class="screening__main-text">
                  <span>Business Activity Screening</span>
               </div>
               <div class="screening__main-color">
                  <span>Revenue breakdown</span>
               </div>
               <div class="screening__progres">
                  <div class="sreening__bar" *ngIf="results?.screeningInfo?.halal">
                     <span class="hl">Halal</span>

                     <ngb-progressbar type="success" textType="white" [value]="results?.screeningInfo?.halal"
                        [max]="(results?.screeningInfo?.halal>100)?results?.screeningInfo?.halal:100">

                     </ngb-progressbar>
                     <span class="hl progressbar__complace-text">{{results?.screeningInfo?.halal |
                        currency:'':''}}%</span>
                  </div>
                  <div class="sreening__bar" *ngIf="results?.screeningInfo?.questionable > -1">
                     <span class="av">Doubtful</span>
                     <ngb-progressbar type="warning" textType="white" [value]="results?.screeningInfo?.questionable"
                        [max]="(results?.screeningInfo?.questionable>100)?results?.screeningInfo?.questionable:100">

                     </ngb-progressbar>
                     <span class="av progressbar__complace-text">{{results?.screeningInfo?.questionable |
                        currency:'':''}}%</span>
                  </div>
                  <div class="sreening__bar" *ngIf="results?.screeningInfo?.notHalal > -1">
                     <span class="nh">Not Halal</span>
                     <ngb-progressbar type="danger" textType="white" [value]="results?.screeningInfo?.notHalal"
                        [max]="(results?.screeningInfo?.notHalal>100)?results?.screeningInfo?.notHalal:100">

                     </ngb-progressbar>
                     <span class="nh progressbar__complace-text">{{results?.screeningInfo?.notHalal |
                        currency:'':''}}%</span>
                  </div>

               </div>
               <div class="screening__pass">
                  <span
                     [ngClass]="{'active': results?.screeningInfo?.status == 'Fail'}">{{results?.screeningInfo?.status}}</span>
               </div>
               <div class="text__main">
                  <div class="text__main-calculet" *ngIf="results &&  results.totalRevenue">
                     <div class="calculet__name">
                        <p>Revenue</p>
                        <p>Ratio</p>
                     </div>
                     <div class="colculet__equality">
                        <span>=</span>
                     </div>
                     <div class="colculate__fraction">
                        <div class="fraction__top">
                           <span>{{((results.questionableRevenue?results.questionableRevenue:0) +
                              (results.impermissibleRevenue?results.impermissibleRevenue:0)) |
                              screaningMillionPipe}}</span>
                        </div>
                        <div class="fraction__bottom">
                           <span>{{results?.totalRevenue | screaningMillionPipe}}</span>
                        </div>
                     </div>
                     <div class="colculet__equality">
                        <span>=</span>
                     </div>
                     <div class="calculet__end">
                        <span>{{renenueRatio | currency:'':''}}%</span>
                     </div>
                  </div>
                  <div class="text__funcsionaly" *ngIf="!showText" (click)="showText = true">
                     <span>View details</span>


                     <i class="bi bi-caret-up-fill"></i>

                  </div>
                  <ng-container *ngIf="!showText">
                     <div class="text__main-midle calculate__sums" >
                        <div class="ratio__name">
                           <p>Revenue</p>
                           <p>Ratio</p>
                        </div>
                        <div class="equality">
                           <span>=</span>
                        </div>
                        <div class="ratio__add">
                           <p class="text__midle">
                              Questionable Revenue + Impermissible Revenue
                           </p>
                           <hr>
                           <p class="text__body">
                              Total Revenue
                           </p>
                        </div>

                     </div>

                     <p class="text__footer" *ngIf="results?.screeningInfo?.status == 'Fail'">
                        Revenue Ratio is more than 5%. Hence, this stock fails the Business Activity Screening.
                     </p>
                     <p class="text__footer" *ngIf="results?.screeningInfo?.status == 'Pass'">
                        Revenue Ratio is less than 5%. Hence, this stock passes the Business Activity Screening.
                     </p>
                  </ng-container>

                  <div class="text__funcsionaly" *ngIf="showText" (click)="showText = false">
                     <span>Hide details</span>

                     <i class="bi bi-caret-down-fill"></i>
                  </div>
               </div>
            </div>


            <div class="screening__main"
               *ngIf="isSecuritiesAvailable()">
               <div class="screening__main-text">
                  <span>Financial Screening</span>
               </div>
               <div class="screening__main-color">
                  <span>Interest-bearing securities and assets</span>
               </div>
               <div class="screening__progres ">
                  <div class="sreening__bar financial__screening">
                     <span
                        [ngClass]="{'progress__text__main': results?.securitiesAndAssets?.credit > 30}">Interest-bearing
                        securities and assets</span>
                     <div class="progress__text">
                        <ngb-progressbar type="success" textType="white" [value]="results?.securitiesAndAssets?.credit"
                           [ngClass]="{'progres__danger': results?.securitiesAndAssets?.credit > 30}"
                           class="progress__line"
                           [max]="(results?.securitiesAndAssets?.credit>100)?results?.securitiesAndAssets?.credit:100">

                        </ngb-progressbar>
                        <span class="progressbar__complace-text"
                           [ngClass]="{'progres__danger': results?.securitiesAndAssets?.credit > 30}">{{results?.securitiesAndAssets?.credit | number : '1.2-2' }}%</span>
                     </div>

                  </div>
               </div>
               <div class="screening__pass">
                  <span
                     [ngClass]="{'active': results?.securitiesAndAssets?.status == 'Fail'}">{{results?.securitiesAndAssets?.status}}</span>
               </div>
               <div class="text__main">
                  <div class="text__main-calculet" *ngIf="results && results.trailing36MonAvrCap">
                     <div class="calculet__name">
                        <p>Interest-bearing</p>
                        <p>securities & assets</p>
                     </div>
                     <div class="colculet__equality">
                        <span>=</span>
                     </div>
                     <div class="colculate__fraction">
                        <div class="fraction__top">
                           <span>{{(results.interestBearingAssets?results.interestBearingAssets:0) |
                              screaningMillionPipe}}</span>
                        </div>
                        <div class="fraction__bottom">
                           <span>{{results?.trailing36MonAvrCap | screaningMillionPipe}}</span>
                        </div>
                     </div>
                     <div class="colculet__equality">
                        <span>=</span>
                     </div>
                     <div class="calculet__end">
                        <span>{{interestBearingSecurities | currency:'':''}}%</span>
                     </div>
                  </div>
                  <div class="text__funcsionaly" *ngIf="!showTextTwo" (click)="showTextTwo = true">
                     <span>View details</span>


                     <i class="bi bi-caret-up-fill"></i>

                  </div>
                  <ng-container *ngIf="!(showTextTwo && isMobile())">
                     <div class="text__main-midle">
                        <p class="text__midle">
                           Interest-Bearing Securities and assets
                        </p>
                        <hr>
                        <p class="text__body">
                           Trailing 36-month Average Market Capitalization
                        </p>
                     </div>

                     <p class="text__footer" *ngIf="results?.securitiesAndAssets?.status == 'Fail'">
                        Interest-Bearing Securities and assets are more than 30% of Trailing 36-month Average Market
                        Capitalization. Hence, this stock fails the Interest Ratio Screening based on AAOIFI screening
                        methodology.

                     </p>
                     <p class="text__footer" *ngIf="results?.securitiesAndAssets?.status == 'Pass'">
                        Interest-Bearing Securities and assets are less than 30% of Trailing 36-month Average Market
                        Capitalization. Hence, this stock passes the Interest Ratio Screening based on AAOIFI screening
                        methodology.

                     </p>
                  </ng-container>

                  <div class="text__funcsionaly" *ngIf="showTextTwo" (click)="showTextTwo = false">
                     <span>Hide details</span>

                     <i class="bi bi-caret-down-fill"></i>
                  </div>
               </div>
            </div>


            <div class="screening__main"
               *ngIf="isDebtAvailable()">
               <div class="screening__main-text">
                  <span>Financial Screening</span>
               </div>
               <div class="screening__main-color">
                  <span>Interest-bearing debt</span>
               </div>
               <div class="screening__progres ">
                  <div class="sreening__bar financial__screening">
                     <span [ngClass]="{'progress__text__main': results?.debt?.debt > 30}">Interest-bearing debt</span>
                     <div class="progress__text">
                        <ngb-progressbar type="success" textType="white" [value]="results?.debt?.debt"
                           [ngClass]="{'progres__danger': results?.debt?.debt > 30}" class="progress__line"
                           [max]="(results?.debt?.debt>100)?results?.debt?.debt:100">

                        </ngb-progressbar>
                        <span class="progressbar__complace-text"
                           [ngClass]="{'progres__danger': results?.debt?.debt > 30}">{{results?.debt?.debt |
                           currency:'':''}}%</span>
                     </div>

                  </div>

               </div>
               <div class="screening__pass">
                  <span [ngClass]="{'active': results?.debt?.status == 'Fail'}">{{results?.debt?.status}}</span>
               </div>
               <div class="text__main">
                  <div class="text__main-calculet" *ngIf="results && results.trailing36MonAvrCap">
                     <div class="calculet__name">
                        <p>Interest-bearing</p>
                        <p>debt</p>
                     </div>
                     <div class="colculet__equality">
                        <span>=</span>
                     </div>
                     <div class="colculate__fraction">
                        <div class="fraction__top">
                           <span>{{(results.interestBearingDebt?results.interestBearingDebt:0) |
                              screaningMillionPipe}}</span>
                        </div>
                        <div class="fraction__bottom">
                           <span>{{results?.trailing36MonAvrCap | screaningMillionPipe}}</span>
                        </div>
                     </div>
                     <div class="colculet__equality">
                        <span>=</span>
                     </div>
                     <div class="calculet__end">
                        <span>{{interestBearingDebt | currency:'':''}}%</span>
                     </div>
                  </div>
                  <div class="text__funcsionaly" *ngIf="!showTextThree" (click)="showTextThree = true">
                     <span>View details</span>


                     <i class="bi bi-caret-up-fill"></i>

                  </div>
                  <ng-container *ngIf="!showTextThree">
                     <div class="text__main-midle">
                        <p class="text__midle">
                           Interest-Bearing Debt
                        </p>
                        <hr>
                        <p class="text__body">
                           Trailing 36-month Average Market Capitalization
                        </p>
                     </div>

                     <p class="text__footer" *ngIf="results?.debt?.status == 'Fail'">
                        Interest-Bearing Debt is more than 30% of Trailing 36-month Average Market Capitalization.
                        Hence, this stock fails the Debt Ratio Screening based on AAOIFI screening methodology.
                     </p>
                     <p class="text__footer" *ngIf="results?.debt?.status == 'Pass'">
                        Interest-Bearing Debt is less than 30% of Trailing 36-month Average Market Capitalization.
                        Hence, this stock passes the Debt Ratio Screening based on AAOIFI screening methodology.
                     </p>
                  </ng-container>

                  <div class="text__funcsionaly" *ngIf="showTextThree" (click)="showTextThree = false">
                     <span>Hide details</span>

                     <i class="bi bi-caret-down-fill"></i>
                  </div>
               </div>
            </div>


         </div>
      </div>
   </div>

   <div class="mobile__app ">

      <div class="mobile__back">
         <!-- <img src="../../../../assets/images/left.svg" alt="" (click)="closeMe()"> -->
         <div class="tooltip__screening">
            <span>{{profile.name}}</span>
         </div>
      </div>
      <div class="">
         <div class="mobile__status">
            <div class="status__name">
               <h2 *ngIf="results?.shariahStates == 'COMPLIANT'"
                  [ngClass]="{'h': results?.shariahStates == 'COMPLIANT'}">Halal</h2>
               <h2 *ngIf="results?.shariahStates == 'NON_COMPLIANT'"
                  [ngClass]="{'nh': results?.shariahStates == 'NON_COMPLIANT'}">Not Halal</h2>
               <h2 *ngIf="results?.shariahStates == 'QUESTIONABLE'"
                  [ngClass]="{'q': results?.shariahStates == 'QUESTIONABLE'}">Doubtful</h2>
               <h2 *ngIf="results?.shariahStates == 'NOT_UNDER_COVERAGE'"
                  [ngClass]="{'cs': results?.shariahStates == 'NOT_UNDER_COVERAGE'}">Coming Soon</h2>
            </div>

            <div class="mobile__status__ranking">
               <ng-container *ngIf="results?.shariahStates == 'COMPLIANT'">
                  <p>Musaffa Halal Rating <i class="bi bi-exclamation-circle" placement="bottom"
                        ngbTooltip="Musaffa Halal Rating is a proprietary ranking metric that compares all stocks in a given country from lowest to highest Shariah compliance standing."
                        tooltipClass="custom__class mobile__tol" triggers="click" [autoClose]="'inside'"></i></p>
                  <div class="status__starts">
                     <div class="stars" [ngClass]="{'active': results?.compliantRanking >= 1}">

                     </div>
                     <div class="stars" [ngClass]="{'active': results?.compliantRanking >= 2}">

                     </div>
                     <div class="stars" [ngClass]="{'active': results?.compliantRanking >= 3}">

                     </div>
                     <div class="stars" [ngClass]="{'active': results?.compliantRanking >= 4}">

                     </div>
                     <div class="stars" [ngClass]="{'active': results?.compliantRanking >= 5}">

                     </div>
                  </div>
               </ng-container>


               <div class="mobili__status__updates">
                  <p>Based on AAOIFI standards</p>
                  <p>Last updated:{{results?.lastUpdate | date:'dd MMM, yyyy'}}</p>
                  <p>Source:{{results?.source}} 3rd Quarterly Report</p>
               </div>
            </div>


         </div>

         <div class="mobile__tabs-main">
            <div class="tabs">
               <button class="btn left "
                  [ngClass]="{ 'active': showRevenue   , 'pas': results?.screeningInfo?.status == 'Pass' , 'fail':results?.screeningInfo?.status == 'Fail' }"
                  type="button" (click)="showRevenuef()" *ngIf="isBusinessScreeningAvailable()">Revenue <i
                     class="bi bi-check-circle" *ngIf="results?.screeningInfo?.status == 'Pass'"></i>
                  <i class="bi bi-x-circle" *ngIf="results?.screeningInfo?.status == 'Fail'"></i>
               </button>
               <button class="btn center "
                  [ngClass]="{ 'active': showSecurities   , 'pas': results?.securitiesAndAssets?.status == 'Pass' , 'fail':results?.securitiesAndAssets?.status == 'Fail' }"
                  type="button" (click)="showSecuritiesf()"
                  *ngIf="isSecuritiesAvailable()">Securities
                  <i class="bi bi-check-circle" *ngIf="results?.securitiesAndAssets?.status == 'Pass'"></i>
                  <i class="bi bi-x-circle" *ngIf="results?.securitiesAndAssets?.status == 'Fail'"></i>
               </button>
               <button class="btn right "
                  [ngClass]="{ 'active': showDebt   , 'pas': results?.debt?.status == 'Pass' , 'fail':results?.debt?.status == 'Fail' }"
                  type="button" (click)="showDebtf()"
                  *ngIf="isDebtAvailable()">Debt <i
                     class="bi bi-check-circle" *ngIf="results?.debt?.status == 'Pass'"></i>
                  <i class="bi bi-x-circle" *ngIf="results?.debt?.status == 'Fail'"></i>
               </button>

            </div>

            <div class="mobile__activities">
               <div class="activities__text" *ngIf="showRevenue">
                  <p>Business Activity Screening</p>
                  <span>Revenue Breakdown</span>
               </div>

               <div class="activities__text" *ngIf="showSecurities">
                  <p>Financial Screening</p>
                  <span>Interest-bearing securities and assets</span>
               </div>

               <div class="activities__text" *ngIf="showDebt">
                  <p>Financial Screening</p>
                  <span>Interest-bearing debt</span>
               </div>


               <ng-container *ngIf="showRevenue">
                  <div class="activities__status nh" *ngIf="results?.screeningInfo?.status == 'Fail'">
                     <i class="bi bi-x-circle"></i>
                     <span>FAIL</span>
                  </div>

                  <div class="activities__status h" *ngIf="results?.screeningInfo?.status == 'Pass'">
                     <i class="bi bi-check-circle"></i>
                     <span>PASS</span>
                  </div>

               </ng-container>

               <ng-container *ngIf="showSecurities">

                  <div class="activities__status nh" *ngIf="results?.securitiesAndAssets?.status == 'Fail'">
                     <i class="bi bi-x-circle"></i>
                     <span>FAIL</span>
                  </div>

                  <div class="activities__status h" *ngIf="results?.securitiesAndAssets?.status == 'Pass'">
                     <i class="bi bi-check-circle"></i>
                     <span>PASS</span>
                  </div>
               </ng-container>

               <ng-container *ngIf="showDebt">

                  <div class="activities__status nh" *ngIf="results?.debt?.status == 'Fail'">
                     <i class="bi bi-x-circle"></i>
                     <span>FAIL</span>
                  </div>

                  <div class="activities__status h" *ngIf="results?.debt?.status == 'Pass'">
                     <i class="bi bi-check-circle"></i>
                     <span>PASS</span>
                  </div>

               </ng-container>


            </div>
            <!-- charts start -->
            <div class="mobile__charts" *ngIf="showRevenue">
               <div class="charts">

                  <app-progres-chart [screeningInfo]="results?.screeningInfo">
                  </app-progres-chart>
               </div>
               <div class="charts__information">
                  <div class="charts__status">
                     <div class="status__color h"></div>
                     <div class="status__numbers">
                        <p>Halal</p>
                        <span>{{results?.screeningInfo?.halal |
                           currency:'':''}}%</span>
                     </div>
                  </div>

                  <div class="charts__status">
                     <div class="status__color db"></div>
                     <div class="status__numbers">
                        <p>Doubtful</p>
                        <span>{{results?.screeningInfo?.questionable |
                           currency:'':''}}%</span>
                     </div>
                  </div>

                  <div class="charts__status">
                     <div class="status__color nh"></div>
                     <div class="status__numbers">
                        <p>Not Halal</p>
                        <span>{{results?.screeningInfo?.notHalal |
                           currency:'':''}}%</span>
                     </div>
                  </div>
               </div>

            </div>

            <!-- charts end -->


            <!-- interest-bearing start -->
            <div class="progress__main-mobile" *ngIf="showSecurities">
               <div class="progress__title">
                  <p>Interest-bearing securities and assets</p>
                  <span class="progressbar__text"
                     [ngClass]="{'progres__danger': results?.securitiesAndAssets?.credit > 30}">{{results?.securitiesAndAssets?.credit
                        | number : '1.2-2'}}%</span>
               </div>

               <div class="progres__bar-main">
                  <ngb-progressbar type="success" textType="white" [value]="results?.securitiesAndAssets?.credit"
                     [ngClass]="{'progres__danger': results?.securitiesAndAssets?.credit > 30}" class="progress__line"
                     [max]="(results?.securitiesAndAssets?.credit>100)?results?.securitiesAndAssets?.credit:100">

                  </ngb-progressbar>
               </div>

            </div>

            <!-- interest-bearing end -->


            <!--interest-debit start-->
            <div class="progress__main-mobile" *ngIf="showDebt">
               <div class="progress__title">
                  <p>Interest-bearing debt</p>
                  <span class="progressbar__text"
                     [ngClass]="{'progres__danger': results?.debt?.debt > 30}">{{results?.debt?.debt |
                     currency:'':''}}%</span>
               </div>

               <div class="progres__bar-main">
                  <ngb-progressbar type="success" textType="white" [value]="results?.debt?.debt"
                     [ngClass]="{'progres__danger': results?.debt?.debt > 30}" class="progress__line"
                     [max]="(results?.debt?.debt>100)?results?.debt?.debt:100">

                  </ngb-progressbar>
               </div>

            </div>


            <!--interest-debit end-->


            <!-- charts start -->

            <div class="charts__text" *ngIf="showRevenue">
               <p>Combined revenue from “Doubtful” and “Not Halal” sources must be less than 5% of total revenue to be
                  Shariah compliant.</p>
            </div>

            <!-- charts end -->

            <!-- interest-bearing start -->
            <div class="charts__text" *ngIf="showSecurities">
               <p>Interest-bearing securities and assets should be less than 30% of trailing 36-month average market
                  capitalization to be Shariah compliant.</p>
            </div>
            <!-- interest-bearing end -->

            <!--interest-debit start-->
            <div class="charts__text" *ngIf="showDebt">
               <p>Interest-bearing debt should be less than 30% of trailing 36-month average market capitalization to be
                  Shariah compliant.</p>
            </div>

            <!--interest-debit end-->

            <div class="calculate__section">


               <!-- chart start-->
               <div class="calculation__section-main" *ngIf="showRevenue && results &&  results.totalRevenue">
                  <div class="calculet__name">
                     <p>Revenue Ratio</p>
                  </div>
                  <div class="colculet__equality">
                     <span>=</span>
                  </div>
                  <div class="colculate__fraction">
                     <div class="fraction__top">
                        <span>{{((results.questionableRevenue?results.questionableRevenue:0) +
                           (results.impermissibleRevenue?results.impermissibleRevenue:0)) |
                           screaningMillionPipe}}</span>
                     </div>
                     <div class="fraction__bottom">
                        <span>{{results?.totalRevenue | screaningMillionPipe}}</span>
                     </div>
                  </div>
                  <div class="colculet__equality">
                     <span>=</span>
                  </div>
                  <div class="calculet__end">
                     <span>{{renenueRatio | currency:'':''}}%</span>
                  </div>
               </div>

               <!-- chart end-->

               <!--interest-bering start -->

               <div class="calculation__section-main" *ngIf="showSecurities && results && results.trailing36MonAvrCap">
                  <div class="calculet__name">
                     <p>Interest-bearing</p>
                     <p>securities & assets</p>
                  </div>
                  <div class="colculet__equality">
                     <span>=</span>
                  </div>
                  <div class="colculate__fraction">
                     <div class="fraction__top">
                        <span>{{(results.interestBearingAssets?results.interestBearingAssets:0) |
                           screaningMillionPipe}}</span>
                     </div>
                     <div class="fraction__bottom">
                        <span>{{results?.trailing36MonAvrCap | screaningMillionPipe}}</span>
                     </div>
                  </div>
                  <div class="colculet__equality">
                     <span>=</span>
                  </div>
                  <div class="calculet__end">
                     <span>{{interestBearingSecurities | currency:'':''}}%</span>
                  </div>
               </div>
               <!--interest-bering end -->

               <!--interest-debit start-->
               <div class="calculation__section-main" *ngIf="showDebt && results && results.trailing36MonAvrCap">
                  <div class="calculet__name">
                     <p>Interest-bearing</p>
                     <p>debt</p>
                  </div>
                  <div class="colculet__equality">
                     <span>=</span>
                  </div>
                  <div class="colculate__fraction">
                     <div class="fraction__top">
                        <span>{{(results.interestBearingDebt?results.interestBearingDebt:0) |
                           screaningMillionPipe}}</span>
                     </div>
                     <div class="fraction__bottom">
                        <span>{{results?.trailing36MonAvrCap | screaningMillionPipe}}</span>
                     </div>
                  </div>
                  <div class="colculet__equality">
                     <span>=</span>
                  </div>
                  <div class="calculet__end">
                     <span>{{interestBearingDebt | currency:'':''}}%</span>
                  </div>
               </div>


               <!--interest-debit end-->

            </div>
            <div class="calculation__text">

               <!--chart start -->
               <div class="calculation__text__main" *ngIf="showRevenue">
                  <span>Doubtful Revenue + Not Halal Revenue</span>
                  <hr>
                  <span>Total Revenue</span>
               </div>
               <!--chart end -->

               <!--interest-bering start -->
               <div class="calculation__text__main" *ngIf="showSecurities">
                  <span>Interest-Bearing Securities and Assets</span>
                  <hr>
                  <span>Trailing 36-month Average Market Capitalization</span>
               </div>
               <!--interest-bering end -->


               <!--interest-debit start-->
               <div class="calculation__text__main" *ngIf="showDebt">
                  <span>Interest-Bearing Debt</span>
                  <hr>
                  <span>Trailing 36-month Average Market Capitalization</span>
               </div>

               <!--interest-debit end-->
            </div>
         </div>
      </div>

   </div>
</div>
