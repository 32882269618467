<section id="brokerages">
    <div class="container content-padding">
        <h2 class="fw-600 text-center">Supported Brokerages</h2>

        <div
            class="d-flex align-items-center justify-content-center gap-3 flex-wrap mx-auto mb-4 mb-lg-5 country-and-tabs-wrapper">
            <ng-select class="country-select" [items]="countries" placeholder="Select Country"
                [(ngModel)]="selectedCountry" [dropdownPosition]="'auto'"
                (change)="getSelectedCountryBrokerages($event)" [searchable]="false" [disabled]="brokeragesLoader"
                [clearable]="false">
                <ng-template ng-label-tmp let-item="item">
                    <div class="d-flex align-items-center gap-2">
                        <img *ngIf="item.country_code === 'all'" src="/assets/icons/globe-icon.svg" alt="" height="24"
                            width="24" class=" rounded-circle img-fluid">
                        <img *ngIf="item.country_code !== 'all'"
                            [src]="item.country_code !== 'UK' ? '/assets/flags/1x1/' + item.country_code.toLowerCase() + '.svg' : '/assets/flags/1x1/gb.svg'"
                            alt="" height="24" width="24" class=" rounded-circle img-fluid">
                        <span>{{ item.country_name }}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <div class="d-flex align-items-center gap-2">
                        <img *ngIf="item.country_code === 'all'" src="/assets/icons/globe-icon.svg" alt="" height="24"
                            width="24" class=" rounded-circle img-fluid">
                        <img *ngIf="item.country_code !== 'all'"
                            [src]="item.country_code !== 'UK' ? '/assets/flags/1x1/' + item.country_code.toLowerCase() + '.svg' : '/assets/flags/1x1/gb.svg'"
                            alt="" height="24" width="24" class=" rounded-circle img-fluid">
                        <span>{{ item.country_name }}</span>
                    </div>
                </ng-template>
            </ng-select>

            <div class="vr d-none d-lg-block"></div>

            <div class="brokerage-tab-btns-wrapper">
                <ng-container *ngFor="let feature of features">
                    <button
                        class="d-flex align-items-center justify-content-between rounded-pill text-nowrap brokerage-tab-btn"
                        [disabled]="brokeragesLoader" [ngClass]="activeFeature === feature ? 'active-tab' : ''"
                        (click)="filterBySupportedFeatures(feature, brokerages)">
                        {{ feature }}
                    </button>
                </ng-container>
            </div>
        </div>

        <ul role="list" class="brokerage-grid">
            <ng-container *ngIf="brokeragesLoader">
                <li class="d-flex flex-column gap-3 gap-lg-4 skeleton-loader grid-item height-10r"
                    *ngFor="let i of [1,2,3,4,5,6]"></li>
            </ng-container>

            <ng-container *ngIf="!brokeragesLoader">
                <ng-container *ngIf="filteredBrokerages && filteredBrokerages.length > 0; else noBrokerages">
                    <li (click)="initiateBrokerage(brokerage.brokerage_id)"
                        class="d-flex flex-column gap-3 gap-lg-4 bg-white grid-item position-relative"
                        *ngFor="let brokerage of filteredBrokerages">
                        <!-- Loader layer -->
                        <div *ngIf="isConnectLoading && currBrokerageId === brokerage.brokerage_id"
                            class="single-brokerage_loader">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <!-- Loader end -->
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                            <div class="flag-image-wrapper flex-shrink-0 rounded-circle bg-light">
                                <img [src]="brokerage?.square_logo" [alt]="brokerage?.name"
                                    class=" rounded-circle img-fluid">
                            </div>
                            <h6 class="mb-0 fw-500">{{ brokerage?.name }}</h6>
                        </div>
                        <div class="d-flex align-items-center flex-wrap coverage-list">
                            <div class="d-flex align-items-start gap-2"
                                *ngIf="brokerage?.supported_features?.is_portfolio_linking_allowed">
                                <svg class="item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    color="#7e7e7e" fill="none">
                                    <path d="M3 10v7.986c0 1.94 1.79 3.514 4 3.514h10c2.21 0 4-1.573 4-3.514V10"
                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                    <path
                                        d="M19.057 3.5c.505 0 .757 0 .966.05.711.171 1.267.756 1.43 1.505.047.22.047.485.047 1.016 0 1.328 0 1.992-.12 2.542-.405 1.872-1.794 3.334-3.573 3.762-.522.125-1.153.125-2.414.125H8.607c-1.261 0-1.892 0-2.414-.125-1.779-.428-3.168-1.89-3.574-3.762C2.5 8.063 2.5 7.399 2.5 6.07c0-.53 0-.796.048-1.016.162-.749.718-1.334 1.43-1.505.208-.05.46-.05.965-.05m4.207 0h5.7"
                                        stroke="currentColor" stroke-width="1.5" />
                                    <path
                                        d="M5 4c0-.466 0-.699.076-.883a1 1 0 0 1 .541-.54c.184-.077.417-.077.883-.077h1c.466 0 .699 0 .883.076a1 1 0 0 1 .54.541C9 3.301 9 3.534 9 4s0 .699-.076.883a1 1 0 0 1-.541.54c-.184.077-.417.077-.883.077h-1c-.466 0-.699 0-.883-.076a1 1 0 0 1-.54-.541C5 4.699 5 4.466 5 4Zm10 0c0-.466 0-.699.076-.883a1 1 0 0 1 .541-.54c.184-.077.417-.077.883-.077h1c.466 0 .699 0 .883.076a1 1 0 0 1 .54.541C19 3.301 19 3.534 19 4s0 .699-.076.883a1 1 0 0 1-.541.54c-.184.077-.417.077-.883.077h-1c-.466 0-.699 0-.883-.076a1 1 0 0 1-.54-.541C15 4.699 15 4.466 15 4Z"
                                        stroke="currentColor" stroke-width="1.5" />
                                    <path d="M7 18h3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <span class="d-block item-text">Portfolio Tracking</span>
                            </div>
                            <div class="d-flex align-items-start gap-2"
                                *ngIf="brokerage?.supported_features?.is_trading_allowed">
                                <svg class="item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    color="#7e7e7e" fill="none">
                                    <path d="M8 17L8 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M12 14L12 9" stroke="currentColor" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 9L16 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M20 10L20 4" stroke="currentColor" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3 3V14C3 17.2998 3 18.9497 4.02513 19.9749C5.05025 21 6.70017 21 10 21H21"
                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <span class="d-block item-text">Trading</span>
                            </div>
                            <div class="d-flex align-items-start gap-2"
                                *ngIf="brokerage?.supported_features?.automated_purification">
                                <svg class="item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                    color="#7e7e7e" fill="none">
                                    <path d="M4 5L20 5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M18 12L6 12" stroke="currentColor" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8 19L16 19" stroke="currentColor" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span class="d-block item-text">Automated Purification</span>
                            </div>
                        </div>
                    </li>
                </ng-container>

                <ng-template #noBrokerages>
                    <li class="d-flex flex-column gap-3 gap-lg-4 bg-white grid-item no-brokerage-item">
                        <p class="mb-0 text-center fw-500 text-black lh-base py-4 py-lg-5">
                            No supported brokerage integration available for your selected filters!
                        </p>
                    </li>
                </ng-template>
            </ng-container>
        </ul>
    </div>
</section>