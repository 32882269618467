<section class="" *ngIf="topLists && topLists.length > 0" >
   <div class="section" [ngClass]="showSymbolsList ? 'bord' : ''" >
      <header class="d-flex align-items-center mb-3 gap-3 justify-content-between" >
         <h3 class="section-heading mb-0 lh-1">
            {{ sectionHeading }}
            <span class="text-secondary fw-500 fs-6" *ngIf="showSymbolsList">( With {{ symbol }} )</span>
         </h3>
         <a class="btn d-flex align-items-center gap-2 text-nowrap p-0 view-all-btn" [routerLink]="['/public-lists']" [queryParams]="{ lists: 'all', page: 1, symbols: symbol }" queryParamsHandling="merge" >
            <span>View All</span>
            <i class="bi bi-arrow-right"></i>
         </a>
      </header>
      <div class="curated-list-container table-responsive" *ngIf="styleType === 'table'">
         <div class="p-2" *ngIf="listLoader">
            <ngx-skeleton-loader
               count="10" appearance="line" animation="progress"
               [theme]="{ height: '50px', width: '100%', 'border-radius': '8px', margin: '0px' }"
            >
            </ngx-skeleton-loader>
         </div>

         <table class="table mb-0" *ngIf="!listLoader">
            <thead>
               <tr>
                  <th class="ps-0" scope="col">Name</th>
                  <th scope="col" class="d-none d-md-table-cell">Followers</th>
                  <th class="pe-0" scope="col" class="text-end">Stocks</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let list of topLists; trackBy: trackByFn">
                  <td class="align-middle">
                     <a class="ps-0 d-flex flex-column justify-content-center text-decoration-none" routerLink="/list-details/{{ makeSlug(list) }}" >
                        <h6 class="mb-0 line-clamp-1 text-black lh-base text-capitalize list-name" >
                           {{ list.name }}
                        </h6>
                        <p class="mb-0 line-clamp-1 list-creator-name" *ngIf="list.user?.firstname" >
                           By {{ list.user.firstname }} {{ list.user.lastname }}
                        </p>
                        <p class="mb-0 line-clamp-1 list-creator-name" *ngIf="!list.user?.firstname" >
                           -
                        </p>
                        <p class="mb-0 list-followers d-md-none">
                           {{ list.followers_count }} Followers
                        </p>
                     </a>
                  </td>
                  <td class="align-middle d-none d-md-table-cell">
                     <a class="d-flex align-items-center justify-content-center text-decoration-none" routerLink="/list-details/{{ makeSlug(list) }}" >
                        <span class="list-followers">{{ list.followers_count }}</span>
                     </a>
                  </td>
                  <td class="align-middle">
                     <a class="pe-0 d-flex flex-column justify-content-center gap-1 text-decoration-none" routerLink="/list-details/{{ makeSlug(list) }}" >
                        <ng-container *ngIf=" list.public_list_holdings.length > 0; else noHoldings " >
                           <ul role="list" class="d-flex align-items-center justify-content-end stocks-list" >
                              <li class="bg-light d-block overflow-hidden" *ngFor=" let holding of list.public_list_holdings.slice( 0, 3 ) " >
                                 <img [src]="holding.logo_url" [alt]="holding.holding_name" class="d-block img-fluid" *ngIf="holding.logo_url" />
                                 <span class="d-flex align-items-center justify-content-center fs-6 text-black fw-bold" *ngIf="!holding.logo_url" >
                                    {{ holding.holding_name?.charAt(0) }}
                                 </span>
                              </li>
                           </ul>
                           <span *ngIf="list.public_list_holdings.length > 3" class="d-block text-end more-span" >+{{ list.public_list_holdings.length - 3 }} more</span >
                        </ng-container>
                        <ng-template #noHoldings>
                           <span class="d-block text-end more-span">0 stocks</span>
                        </ng-template>
                     </a>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>

      <div class="collection-list-grid" *ngIf="styleType === 'card'">
         <a class="text-decoration-none d-flex align-items-start justify-content-between gap-3 gap-lg-4 p-3 collection-card" *ngFor="let list of topLists; trackBy: trackByFn"  routerLink="/list-details/{{ makeSlug(list) }}">
            <div>
               <h6 class="mb-0 line-clamp-1 fw-600 text-black lh-base text-capitalize list-name" >
                  {{ list.name }}
               </h6>
               <p class="line-clamp-1 list-creator-name" *ngIf="list.user?.firstname" >
                  By {{ list.user.firstname }} {{ list.user.lastname }}
               </p>
               <p class="mb-0 d-flex align-items-center gap-2 list-followers">
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M6.99992 12.5503C6.84436 12.5503 6.68603 12.5225 6.52492 12.4669C6.36381 12.4114 6.22214 12.3225 6.09992 12.2003L4.94992 11.1503C3.77214 10.0725 2.70825 9.00304 1.75825 7.94193C0.808252 6.88082 0.333252 5.71137 0.333252 4.4336C0.333252 3.38915 0.683252 2.51693 1.38325 1.81693C2.08325 1.11693 2.95547 0.76693 3.99992 0.76693C4.58881 0.76693 5.14436 0.89193 5.66658 1.14193C6.18881 1.39193 6.63325 1.7336 6.99992 2.16693C7.36658 1.7336 7.81103 1.39193 8.33325 1.14193C8.85547 0.89193 9.41103 0.76693 9.99992 0.76693C11.0444 0.76693 11.9166 1.11693 12.6166 1.81693C13.3166 2.51693 13.6666 3.38915 13.6666 4.4336C13.6666 5.71137 13.1944 6.8836 12.2499 7.95026C11.3055 9.01693 10.2333 10.0892 9.03325 11.1669L7.89992 12.2003C7.7777 12.3225 7.63603 12.4114 7.47492 12.4669C7.31381 12.5225 7.15547 12.5503 6.99992 12.5503Z" fill="#E82127"/>
                  </svg>
                  <span>{{ list.followers_count }} Followers</span>
               </p>
            </div>
            <div class="pe-0 d-flex flex-column justify-content-center gap-1 text-decoration-none" >
               <ng-container *ngIf=" list.public_list_holdings.length > 0; else noHoldings " >
                  <ul role="list" class="d-flex align-items-center justify-content-end stocks-list" >
                     <li class="bg-light d-block overflow-hidden" *ngFor=" let holding of list.public_list_holdings.slice( 0, 3 ) " >
                        <img [src]="holding.logo_url" [alt]="holding.holding_name" class="d-block img-fluid" *ngIf="holding.logo_url" />
                        <span class="d-flex align-items-center justify-content-center fs-6 text-black fw-bold" *ngIf="!holding.logo_url" >
                           {{ holding.holding_name?.charAt(0) }}
                        </span>
                     </li>
                  </ul>
                  <span *ngIf="list.public_list_holdings.length > 3" class="d-block text-end more-span" >+{{ list.public_list_holdings.length - 3 }} more</span >
               </ng-container>
               <ng-template #noHoldings>
                  <span class="d-block text-end more-span">0 stocks</span>
               </ng-template>
            </div>
         </a>
      </div>
   </div>
</section>
