import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { lastValueFrom } from 'rxjs';
import { UserApiService } from './shared/services/user-api.service';

@Injectable({
  providedIn: 'root'
})
export class CheckMobileGuard implements CanActivate {
   constructor(
      public router: Router,
      private authService: AuthService,
      public authFire: AngularFireAuth,
      private userService: UserApiService
   ) {}
   async canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Promise<boolean> {
      const isAuthenticated = !!this.authService.getUserId();
      if (!isAuthenticated) {
         return true
      }
      const user = await lastValueFrom(this.userService.getUserData(true));
      if (!user.sign_up_completed) {
         this.router.navigate(['authentication/activate']);
         return false;
      }
      return true;
   }
}
