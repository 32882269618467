import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { FirebaseRemoteConfigService } from 'src/app/shared/services/firebase-remote-config.service';

@Component({
   selector: 'app-stock-details-popup',
   templateUrl: './stock-details-popup.component.html',
   styleUrls: ['./stock-details-popup.component.scss'],
})
export class StockDetailsPopupComponent implements OnInit {
   @Input() logo: string = '';
   @Input() stockName: string = '';
   @Input() stockIsin: string = '';
   @Input() stockSymbol: string = '';
   @Input() complianceStatus: string = '';
   @Input() complianceRanking: number = 0;
   @Input() ranking_v2: number = 0;
   @Input() sharesOwned: number = 0;
   @Input() portfolioPercentage: number = 0;
   @Input() currentValue: number = 0;
   @Input() reportDate: string = '';
   @Input() stockType: string = '';

   constructor(
      public activeModal: NgbActiveModal,
      private router: Router,
      private analyticsUtils: AnalyticsUtils,
      private remoteConfig: FirebaseRemoteConfigService
   ) {}

   ngOnInit(): void {
      // this.getNonPurifiableText()
   }

   goToDetails() {
      // this.analyticsUtils.portfolio_holding_item_view_details();
      // console.log(this.stockType)
      this.activeModal.dismiss();
      if (this.stockType === 'stock') {
         this.router.navigate(['/stock/' + this.stockSymbol]);
      } else {
         this.router.navigate(['/etf/' + this.stockSymbol]);
      }
   }

   getPercentChangeClasses(holding: any) {
      return {
         green: holding.percentage_change >= 0,
         red: holding.percentage_change < 0,
      };
   }

   // getNonPurifiableText() {
   // this.remoteConfig.getPurificationDefaults().then(data => {
   //    this.nonPurifiableText = data.not_purifiable_info
   // })
   // }
}
