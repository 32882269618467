import {
   Component,
   Input,
   OnInit,
   OnDestroy,
   TemplateRef,
   ViewChild,
   Output,
   EventEmitter,
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralInfoPopupComponent } from '../general-info-popup/general-info-popup.component';
import {
   NgbActiveModal,
   NgbModal,
   NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';

@Component({
   selector: 'app-report-compliance-status-card',
   templateUrl: './report-compliance-status-card.component.html',
   styleUrls: ['./report-compliance-status-card.component.scss'],
})
export class ReportComplianceStatusCardComponent implements OnInit, OnDestroy {
   @Input() complianceStatus!: string;
   @Input() complianceRanking!: number;
   @Input() companyProfile!: any;
   @Input() reportDate!: any;
   @Input() quarterText: any;
   @Input() lastUpdated: any;
   @Input() statusValue!: string;
   @Input() stockType: string = '';
   @Input() cbaComment!: any;
   @Input() isSample!: boolean;
   @Input() page!: string;
   @Input() closeActiveModal: any;
   @Output() closeModal = new EventEmitter<void>();
   // complianceHistoryRef!: NgbModalRef;
   // @ViewChild('showCompliantHistory', { static: true })
   // showCompliantHistory!: TemplateRef<NgbModal>;
   ticker: string | null = null;
   userRole: string | null = null;
   private subscriptions: Subscription[] = [];
   loading: boolean = false;
   confirmModalRef!: NgbModalRef;
   configRequestUpdationDefaults: any;
   minDuration!: number;
   diffDays!: number;
   complianceHistorydata: any;
   isComplianceHistoryLoading: boolean = false;

   statusText: string = `Musaffa Halal Rating is a proprietary ranking metric that compares all stocks in a given country from lowest to highest Shariah compliance standing. \n\nThe higher the rating, the greater the adherence to Shariah regulations, resulting in a reduced requirement to purify Haram income following the sale of these stock holdings.`;

   constructor(
      private apiService: ApiService,
      private route: ActivatedRoute,
      private modalService: NgbModal,
      private configService: FirebaseRemoteConfigService,
      private toaster: ToastrService,
      private router: Router // private activeModel: NgbActiveModal
   ) {}

   async ngOnInit(): Promise<void> {
      this.isComplianceHistoryLoading = true;
      this.route.paramMap.subscribe((params) => {
         this.ticker = params.get('id');
      });
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );

      // if (this.userRole !== 'ANONYMOUS')
      //    this.apiService
      //       .getComlianceHistory((this.ticker || 'AAPL') as string)
      //       .then((res) => {
      //          this.complianceHistorydata = res.data;
      //          this.isComplianceHistoryLoading = false;
      //          // console.log(this.ticker);
      //          // console.log(res);
      //       });
      if (this.reportDate) {
         this.configRequestUpdationDefaults =
            await this.configService.getRequestReportUpdationDefaults();
         let duration =
            this.configRequestUpdationDefaults.country_durations.find(
               (duration: any) =>
                  duration.country_code === this.companyProfile.country
            );
         if (!duration) {
            duration =
               this.configRequestUpdationDefaults.country_durations.find(
                  (duration: any) => duration.country_code === 'DEFAULT'
               );
         }
         this.minDuration = duration.duration;
         const now = new Date();
         const reportDate = new Date(this.reportDate);
         const diffTime = Math.abs(now.getTime() - reportDate.getTime());
         this.diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
      // this.closeComplianceHistoryModal();
   }

   // openComplianceHistoryModal(): void {
   //    if (this.userRole === 'ANONYMOUS') {
   //       this.closeModal.emit();
   //       this.router.navigate(['/authentication/login']);
   //       return;
   //    }
   //    this.complianceHistoryRef = this.modalService.open(
   //       this.showCompliantHistory,
   //       {
   //          centered: true,
   //       }
   //    );
   // }

   // closeComplianceHistoryModal(): void {
   //    if (this.complianceHistoryRef) {
   //       this.complianceHistoryRef.dismiss();
   //    }
   // }

   handleStatusParaVisibility(status: string) {
      return ['COMPLIANT', 'NON_COMPLIANT', 'QUESTIONABLE'].includes(status);
   }

   openConfirmationPopup(content: any) {
      this.confirmModalRef = this.modalService.open(content, {
         centered: true,
      });
   }

   closeConfirmationPopup() {
      if (this.confirmModalRef) {
         this.confirmModalRef.dismiss();
      }
   }

   reportRequest() {
      if (this.ticker) {
         this.closeConfirmationPopup();
         this.loading = true;
         let modal: any;
         this.apiService
            .updateComplianceChangeRequest(this.ticker)
            .then((data) => {
               if (data.status) {
                  modal = this.modalService.open(GeneralInfoPopupComponent, {
                     centered: true,
                  });
                  modal.componentInstance['title'] = 'Request Sent';
                  modal.componentInstance['description'] =
                     this.configRequestUpdationDefaults[
                        'updation_requested_label'
                     ];
               } else {
                  switch (data.status_code) {
                     case 'upgrade_acc':
                        break;
                     case 'not_covered':
                        modal = this.modalService.open(
                           GeneralInfoPopupComponent,
                           {
                              centered: true,
                           }
                        );
                        modal.componentInstance['title'] =
                           'Stock is not covered';
                        modal.componentInstance['description'] =
                           'Stock has to be covered to make this request';
                        break;
                     case 'recently_analyzed':
                        this.toaster.error('Stock was analyzed recently');
                        break;
                     case 'limit_reached':
                        modal = this.modalService.open(
                           GeneralInfoPopupComponent,
                           {
                              centered: true,
                           }
                        );
                        modal.componentInstance['title'] =
                           this.configRequestUpdationDefaults[
                              'limit_reached_label'
                           ]?.title;
                        modal.componentInstance['description'] =
                           this.configRequestUpdationDefaults[
                              'limit_reached_label'
                           ]?.description;
                        break;
                     case 'error':
                        // this.toaster.error('Something went wrong');
                        break;
                     case 'request_exists':
                        modal = this.modalService.open(
                           GeneralInfoPopupComponent,
                           {
                              centered: true,
                           }
                        );
                        modal.componentInstance['title'] =
                           'Request Already Exists';
                        modal.componentInstance['description'] =
                           'Your request to update report of this stock already exists';
                        break;
                  }
               }
            })
            .catch((e) => {
               console.log(e);
               // this.toaster.error('Something went wrong');
            })
            .finally(() => {
               this.loading = false;
            });
      }
   }
}
