import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
   Filter,
   FilterCategory,
   FilterGroup,
   FilterOption,
} from 'src/app/models/filter-data.model';
import { FilterDataService } from './filter-data.service';
import { filterCategoryConfig, filterGroupsConfig, filtersConfig } from 'src/app/utils/stock.filters-config';
import { BehaviorSubject } from 'rxjs';
import { etfFilterCategoryConfig } from 'src/app/utils/etf.filters-config';
import { etfFilterGroupsConfig } from 'src/app/utils/etf.filters-config';
import { etfFiltersConfig } from 'src/app/utils/etf.filters-config';

@Injectable({
   providedIn: 'root',
})
export class FilterService {
   // private readonly APPLIED_FILTERS_KEY = 'appliedFilters';
   // private readonly APPLIED_FILTERS_KEY_ETF = 'appliedFiltersETF';

   constructor(
      private router: Router,
      private route: ActivatedRoute,
      private filterDataService: FilterDataService
   ) {}

   public appliedFilters$ = new BehaviorSubject<{ name: string; value: any; category: string }[]>([]);

   public counts$ = new BehaviorSubject<{
      categories: { [key: string]: number };
      groups: { [key: string]: number };
      filters: { [key: string]: number };
      total: number;
   }>({
      categories: {},
      groups: {},
      filters: {},
      total: 0
   });

   saveAppliedFilter(filter: { name: string; value: any; category: string }): void {
      const appliedFilters = this.appliedFilters$.value;
      const existingFilterIndex = appliedFilters.findIndex(
         (f) => f.name === filter.name
      );

      if (filter.value) {
         if (existingFilterIndex !== -1) {
            appliedFilters[existingFilterIndex] = filter;
         } else {
            appliedFilters.push(filter);
         }
      } else if (existingFilterIndex !== -1) {
         appliedFilters.splice(existingFilterIndex, 1);
      }
      this.appliedFilters$.next(appliedFilters);
      // localStorage.setItem(
      //    type === 'stock' ? this.APPLIED_FILTERS_KEY : this.APPLIED_FILTERS_KEY_ETF,
      //    JSON.stringify(appliedFilters)
      // );
   }

   storeAndGetFilterCounts(type: string = 'stock') {
      const counts: {
         categories: { [key: string]: number };
         groups: { [key: string]: number };
         filters: { [key: string]: number };
         total: number;
      } = {
         categories: {},
         groups: {},
         filters: {},
         total: 0,
      };

      let appliedFilters = this.appliedFilters$.value;
      appliedFilters = appliedFilters.filter((filter) => {
         return filter.value.length > 0;
      });

      // Count applied filters by category
      // Process all counts in one loop through appliedFilters
      appliedFilters.forEach((filter: { name: string; value: any }) => {
         const filterConfig = (type === 'stock') ? this.stockFilters.find((f) => f.name === filter.name) : this.etfFilters.find((f) => f.name === filter.name);
         if (filterConfig) {
            // Track if filter has been counted for any category
            const countedCategories = new Set<string>();

            // First check groups
            this.filterGroups.forEach((group: FilterGroup) => {
               if (filterConfig.group.includes(group.name)) {
                  counts.groups[group.name] =
                     (counts.groups[group.name] || 0) + 1;

                  // Then check categories for matching groups
                  this.filterCategories.forEach((category: FilterCategory) => {
                     if (
                        group.category.includes(category.name) &&
                        !countedCategories.has(category.name)
                     ) {
                        counts.categories[category.name] =
                           (counts.categories[category.name] || 0) + 1;
                        countedCategories.add(category.name);
                     }
                  });
               }
            });
         }

         // Count individual filters
         counts.filters[filter.name] = filter.value ? 1 : 0;

         // Add to total if filter has value
         if (filter.value) {
            counts.total++;
         }
      });

      this.counts$.next(counts);
      return counts;
   }

   clearAppliedFilters(category: string | null = null): void {
      let appliedFilters = this.appliedFilters$.value;
      if(category) {
         appliedFilters = appliedFilters.filter((filter) => filter.category !== category);
      } else {
         appliedFilters = [];
      };
      this.appliedFilters$.next(appliedFilters);
      // this.updateFiltersQueryParams(appliedFilters);
      // localStorage.removeItem(type === 'stock' ? this.APPLIED_FILTERS_KEY : this.APPLIED_FILTERS_KEY_ETF);
   }

   public loadAppliedFilters(type: string = 'stock'): void {
      const queryParams = this.route.snapshot.queryParams;
      const appliedFilters: { name: string; value: any; category: string }[] = Object.keys(queryParams)
         .filter(param => param.startsWith('filters['))
         .map(param => {
            const name = param.match(/\[(.*?)\]/)?.[1] ?? '';
            const value = queryParams[param];
            const filterConfig = type === 'stock' ? this.stockFilters.find(f => f.name === name) : this.etfFilters.find(f => f.name === name);
            const group = filterConfig?.group[0] ?? '';
            const groupConfig = type === 'stock' ? this.stockFilterGroups.find(g => g.name === group) : this.etfFilterGroups.find(g => g.name === group);

            return {
               name,
               value: Array.isArray(value) ? value : [value],
               category: groupConfig?.category[0] ?? '',
            };
         })
         .filter(filter => {
            if (Array.isArray(filter.value)) {
               return filter.value.length > 0;
            }
            return filter.value !== null &&
                   filter.value !== undefined &&
                   filter.value !== '';
         });
      this.appliedFilters$.next(appliedFilters);
      // const storedFilters = localStorage.getItem(type === 'stock' ? this.APPLIED_FILTERS_KEY : this.APPLIED_FILTERS_KEY_ETF);
      // if (storedFilters) {
      //    let appliedFilters = JSON.parse(storedFilters);
      //    // Remove filters with no values
      //    appliedFilters = appliedFilters.filter((filter: { name: string; value: any }) => {
      //       if (Array.isArray(filter.value)) {
      //          return filter.value.length > 0;
      //       }
      //       return (
      //          filter.value !== null &&
      //          filter.value !== undefined &&
      //          filter.value !== ''
      //       );
      //    });
      //    this.appliedFilters$.next(appliedFilters);
      //    this.updateFiltersQueryParams(appliedFilters);
      // }
   }

   public filterCategories: FilterCategory[] = filterCategoryConfig;
   public stockFilterCategories: FilterCategory[] = filterCategoryConfig;
   public etfFilterCategories: FilterCategory[] = etfFilterCategoryConfig;

   public filterGroups: FilterGroup[] = filterGroupsConfig;
   public stockFilterGroups: FilterGroup[] = filterGroupsConfig;
   public etfFilterGroups: FilterGroup[] = etfFilterGroupsConfig;


   public filters: Filter[] = filtersConfig;
   public stockFilters: Filter[] = filtersConfig;
   public etfFilters: Filter[] = etfFiltersConfig;


   getFilterCategories(type: string = 'stock') {
      if(type === 'etf') {
         return this.etfFilterCategories;
      }
      return this.filterCategories;
   }

   getFilterGroups(category: string | null = null, type: string = 'stock') {
      if(type === 'etf') {
         return this.etfFilterGroups;
      }
      if (category) {
         return this.filterGroups.filter((group) =>
            group.category.includes(category)
         );
      }
      return this.filterGroups;
   }

   getFilters(group: string | null = null, type: string = 'stock') {
      let filters: Filter[] = [];
      if(type === 'etf') {
         filters = this.etfFilters;
      } else {
         filters = this.filters;
      }
      if (group) {
         return filters.filter((filter) => filter.group.includes(group));
      }
      this.filters = filters;
      // this.loadAppliedFilters();
      return filters;
   }

   removeFilter(filterName: string) {
      this.appliedFilters$.next(this.appliedFilters$.value.filter((filter) => filter.name !== filterName));
      // localStorage.setItem(type === 'stock' ? this.APPLIED_FILTERS_KEY : this.APPLIED_FILTERS_KEY_ETF, JSON.stringify(this.appliedFilters$.value));
      // this.updateFiltersQueryParams(this.appliedFilters$.value);
   }


   updateFiltersQueryParams(
      updatedFilters: { name: string; value: any }[] = []
   ): Promise<boolean> {
      return new Promise((resolve) => {
         const queryParams: { [key: string]: string | null } = {
            'page': this.route.snapshot.queryParams['page'] ?? '1'
         };

         // if(!updatedFilters.length) {
         //    updatedFilters = this.appliedFilters
         // }
         const setFilters: string[] = [];
         updatedFilters.forEach((filter) => {
            if (filter.value) {
               const filterName = `filters[${filter.name}]`;
               setFilters.push(filterName);
               queryParams[filterName] = filter.value;
            }
         });

         // Clear existing filter query params
         this.route.snapshot.queryParams &&
            Object.keys(this.route.snapshot.queryParams).forEach((param) => {
               if (
                  param.startsWith('filters[') &&
                  !setFilters.includes(param)
               ) {
                  queryParams[param] = null;
               }
            });

         // const halalFiltersFields = [
         //    'businessCompliantRatio',
         //    'businessNonCompliantRatio',
         //    'businessQuestionableRatio',
         //    'interestBearingAssetsRatio',
         //    'interestBearingDebtRatio',
         // ];

         // Build query params object, only including non-empty values

         // if(halalFiltersFields.some((field) => !!queryParams[`filters[${field}]`]?.length)) {
         //    queryParams['compliances'] = 'COMPLIANT';
         // }
         // Update URL without reloading the page
         this.router
            .navigate([], {
               relativeTo: this.route,
               queryParams,
               queryParamsHandling: 'merge',
               replaceUrl: true,
            })
            .then((success) => resolve(success));
      });
   }

   async getOptionsData<T>(
      optionsFunction: string,
      params: string[] = []
   ): Promise<FilterOption[]> {
      if (optionsFunction === 'getExchangeList') {
         return await this.filterDataService.getExchangeList(params[0]);
      }
      if (optionsFunction === 'getSectorList') {
         return await this.filterDataService.getSectorList();
      }
      if (optionsFunction === 'getIndustryList') {
         return await this.filterDataService.getIndustryList(params);
      }
      return Promise.resolve([]);
   }

   getFilterParams(params: any) {
      return Object.entries(params)
         .filter(([key]) => key.startsWith('filters['))
         .map(([key, value]) => {
            const filterKey = key.match(/\[(.*?)\]/)?.[1];
            return { field: filterKey, value: value };
         })
         .filter((item) => item.field != null);
   }

   buildFilterConditions(filters: any, type: string = 'stock'): any {
      const filterBy: string[] = [];
      const processFilters = (filters: any) => {
         return filters
            .filter((filter: any) => filter?.value?.length > 0)
            .map((filter: any) => {
               const field = filter.field;
               const value = filter.value;

               // In-array filter
               if (Array.isArray(value)) {
                  if (value.length > 0) {
                     const values = value.map((v: string | number) => (typeof v === 'number') ? v : `\`${v}\``).join(',');
                     return `${field}:=[${values}]`;
                  } else {
                     return '';
                  }
               }

               // Range filter
               const [min, max] = value.split(':');
               if (min && max) {
                  // if (field === 'ipoDate') {
                  //    return `${field}:[<${min}]`;
                  // }
                  if (min === '*' && max !== '*') {
                     return `${field}:<=${max}`;
                  }
                  if (min !== '*' && max === '*') {
                     return `${field}:>=${min}`;
                  }
                  if (min !== '*' && max !== '*') {
                     return `${field}:>=${min}&&${field}:<=${max}`;
                  }
               }

               // Not filter
               if (value.includes('!')) {
                  return `${field}:!=${value.replace('!', '')}`;
               }
               if (value === '[]') {
                  return '';
               }
               return `${field}:=${value}`;
            });
      };

      if (filters.length > 0) {
         const exchangeIndex = filters.findIndex(
            (filter: any) => filter.field === 'exchange'
         );
         const countryIndex = filters.findIndex(
            (filter: any) => type === 'stock' ? filter.field === 'country' : filter.field === 'domicile'
         );
         const complianceStatusIndex = filters.findIndex(
            (filter: any) => type === 'stock' ? filter.field === 'sharia_compliance' : filter.field === 'shariahCompliantStatus'
         );
         if (
            exchangeIndex === -1 ||
            (exchangeIndex > -1 && !filters[exchangeIndex]?.value?.length)
         ) {
            if (filters[countryIndex].value?.toUpperCase() === 'US') {
               filters.push({
                  field: 'exchange',
                  value: ['NYSE', 'NASDAQ'],
               });
            }
         }
         if (complianceStatusIndex !== -1) {
            if (!filters[complianceStatusIndex]?.value?.length) {
               filters[complianceStatusIndex].value.push(
                  'COMPLIANT',
                  'NON_COMPLIANT',
                  'QUESTIONABLE'
               );
            }
         } else {
            filters.push({
               field: type === 'stock' ? 'sharia_compliance' : 'shariahCompliantStatus',
               value: ['COMPLIANT', 'NON_COMPLIANT', 'QUESTIONABLE'],
            });
         }
         filterBy.push(...processFilters(filters));
      }
      return filterBy;
   }
}
