import { Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';

@Component({
   selector: 'app-pureness',
   templateUrl: './pureness.component.html',
   styleUrls: ['./pureness.component.scss'],
})
export class PurenessComponent implements OnInit, OnDestroy {
   @ViewChild('chartCanvas') chartCanvas!: ElementRef;

   @Input() data: any;
   @Input() chartType: string = 'purenessLevel';
   centerText: string = '';
   _loading: boolean = false;
   chart: any;
   isMobile: boolean = false;
   private destroy$ = new Subject<void>();

   @Input() set loading(value: boolean) {
      this._loading = value;
      this.shariahData = [];
      this.halalData = {
         value: 0,
         label: 'Halal',
      };
      if (!this._loading) {
         if (!this.data || this.data?.length === 0) {
            return;
         }
         this.data.forEach((item: any) => {
            if (item.label === 'COMPLIANT') {
               this.halalData.value = item.value;
               this.centerText = `<div>
                    <div class="label__chart" style="">${item.value}</div>
                    <div class="status halal_status">%HALAL</div>
                </div>`;
            }
            this.shariahData.push({
               value: item.value,
               label: this.convertShariahStatus(item.label),
               color: this.getShariahStatusColor(item.label),
            });
         });

         // Wait for this to complete before creating the chart
         if (this.chartType === 'donut') {
            setTimeout(() => {
               this.createDonutChart();
            });
         }
      }
      this.cdr.detectChanges();
   }
   get loading() {
      return this._loading;
   }

   constructor(private cdr: ChangeDetectorRef, private observer: BreakpointObserver) {}

   shariahData: any = [];

   halalData: any = {
      value: 0,
      label: 'Halal',
   };

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
         this.createDonutChart();
         this.cdr.detectChanges();
      });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   createDonutChart() {
      if (!this.chartCanvas?.nativeElement || !this.shariahData?.length) return;

      if (this.chart) {
         this.chart.destroy();
      }

      const ctx = this.chartCanvas.nativeElement.getContext('2d');

      this.chart = new Chart(ctx, {
         type: 'doughnut', // Doughnut chart type for a similar "donut" effect
         data: {
            labels: this.shariahData.map((item: any) => item.label),
            datasets: [
               {
                  data: this.shariahData.map((item: any) => item.value),
                  backgroundColor: this.shariahData.map((item: any) => item.color),
                  borderWidth: 0,
               },
            ],
         },
         options: {
            maintainAspectRatio: false,
            responsive: true,
            cutoutPercentage: !this.isMobile ? 75 : 60, // Donut effect (innerSize)
            tooltips: {
               enabled: false, // Disable tooltips
            },
            legend: {
               display: false, // Hide the legend
            },
            animation: {
               animateRotate: true, // Enable animation
            },
            events: ['click'], // Optional: Add events as needed
            plugins: {
               labels: {
                  render: 'percentage', // Render percentage in the center
                  fontSize: 18,
                  fontColor: '#000',
                  fontStyle: 'bold',
               },
            },
         },
      });
      // this.cdr.detectChanges();
   }

   getShariahStatusColor(status: string): string {
      switch (status) {
         case 'COMPLIANT':
            return '#6CE79F';
         case 'NON_COMPLIANT':
            return '#E72D2D';
         case 'QUESTIONABLE':
            return '#E9B847';
         case 'NOT_COVERED':
            return '#448DD1';
         default:
            return '';
      }
   }

   convertShariahStatus(status: string): string {
      switch (status) {
         case 'COMPLIANT':
            return 'Halal';
         case 'NON_COMPLIANT':
            return 'Not Halal';
         case 'QUESTIONABLE':
            return 'Doubtful';
         case 'NOT_COVERED':
            return 'Not Covered';
         default:
            return '';
      }
   }
}
