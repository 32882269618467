<div class="shares-container p-2 p-lg-3 rounded-3">
   <p
      *ngIf="
         (purificationTracking.sellUnits | abs) !==
         (transaction.soldUnits | abs)
      "
      class="shares-container-text text-black mb-0 d-flex align-items-center flex-wrap gap-1 lh-base"
   >
      <span class="fw-bold">{{ transaction.soldUnits | abs }} Shares sold</span>
      <span class=""
         >out of {{ purificationTracking.sellUnits | abs }} Shares</span
      >
   </p>
   <p>
      <span class=""></span>
      Buy Value
      <span
         >{{
            transaction.buyAmount
               | abs
               | currency : purificationTracking.currency
         }}
      </span>
      <span class="vr mx-1"></span>
      <span class="mx-1">{{ transaction.buyDate | date : "mediumDate" }}</span>
   </p>
   <hr class="" *ngIf="transaction.haramPurificationAmount" />
   <ng-container
      *ngIf="
         purificationMethod.value === 'haram_purification' &&
         transaction.haramPurificationAmount
      "
   >
      <div class="income-wrapper mb-2">
         <span class="income-text">Not Halal Income Purification Amount</span>
         <span class="income-value">
            <!-- {{transaction.haramPurificationAmount | currency: purificationTracking.currency}} -->
            <app-less-than-tooltip
               [amount]="transaction.haramPurificationAmount"
               [currency]="purificationTracking.currency"
               [tooltipPosition]="'left'"
               [amountStyle]="{ color: '#00000099', fontWeight: '500' }"
               [tooltipContent]="transaction.haramPurificationAmount"
            >
            </app-less-than-tooltip>
         </span>
      </div>

      <!-- <div class="income-wrapper">
         <span class="income-text">x No. of days owned / No. of days in entire accounting period</span>
         <span class="income-value">{{(transaction.sharesHeldDays / transaction.haramAccountingPeriod) | number: "1.3-3"}}</span>
      </div> -->
   </ng-container>
   <ng-container *ngIf="purificationMethod.value === 'interest_purification'">
      <div class="income-wrapper mb-2">
         <span class="income-text">Not Halal Income Purification Amount</span>
         <span class="income-value">
            <!-- {{transaction.interestPurificationAmount | currency: purificationTracking.currency}} -->
            <app-less-than-tooltip
               [amount]="transaction.interestPurificationAmount"
               [currency]="purificationTracking.currency"
               [tooltipPosition]="'left'"
               [amountStyle]="{ color: '#00000099', fontWeight: '500' }"
               [tooltipContent]="transaction.interestPurificationAmount"
            >
            </app-less-than-tooltip>
         </span>
      </div>
      <!-- <div class="income-wrapper">
         <span class="income-text">x No. of days owned / No. of days in entire accounting period</span>
         <span class="income-value">{{(transaction.sharesHeldDays / transaction.interestAccountingPeriod)  | number: "1.3-3"}}</span>
      </div> -->
   </ng-container>
   <ng-container *ngIf="purificationMethod.value === 'dividend_purification'">
      <div class="income-wrapper">
         <span class="income-text">Dividends Per Share</span>
         <span class="income-value">
            <!-- {{transaction.dividendsPerShare | number: "1.2-2"}} -->
            <app-less-than-tooltip
               [amount]="transaction.dividendsPerShare"
               [currency]="purificationTracking.currency"
               [tooltipPosition]="'left'"
               [amountStyle]="{ color: '#00000099', fontWeight: '500' }"
               [tooltipContent]="transaction.dividendsPerShare"
            >
            </app-less-than-tooltip>
         </span>
      </div>
      <div
         class="income-wrapper mt-2"
         *ngIf="
            transaction.dividendNonCompliantRevenue &&
            transaction.dividendTotalRevenue
         "
      >
         <span class="income-text">Not halal Revenue / Total Revenue</span>
         <span class="income-value">
            {{
               transaction.dividendNonCompliantRevenue /
                  transaction.dividendTotalRevenue | number : "1.2-2"
            }}%
         </span>
         <!-- <span class="income-value" *ngIf="transaction.dividendNonCompliantRevenue && transaction.dividendTotalRevenue; else noDividendRevenue">
            {{(transaction.dividendNonCompliantRevenue / transaction.dividendTotalRevenue) | number: "1.2-2"}}%
         </span> -->
         <!-- <ng-template #noDividendRevenue>-</ng-template> -->
      </div>
   </ng-container>
</div>
