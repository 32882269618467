import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

   constructor() {}

   sortData(data: any[], columnName: string, sortOrder: "asc" | "desc" | ""): any[] {
      if (sortOrder === 'asc') {
        return data.sort((a, b) => {
          const valueA = a[columnName];
          const valueB = b[columnName];
    
          if (valueA === valueB) {
            return 0;
          } else if (valueA === null || valueA === undefined) {
            return 1;
          } else if (valueB === null || valueB === undefined) {
            return -1;
          } else {
            return Number(valueA) - Number(valueB);
          }
        });
      } else if (sortOrder === 'desc') {
        return data.sort((a, b) => {
          const valueA = a[columnName];
          const valueB = b[columnName];
    
          if (valueA === valueB) {
            return 0;
          } else if (valueA === null || valueA === undefined) {
            return 1;
          } else if (valueB === null || valueB === undefined) {
            return -1;
          } else {
            return Number(valueB) - Number(valueA);
          }
        });
      } else {
        return [...data];
      }
   }
}