import { Component, OnInit } from '@angular/core';
import { PortfolioService } from '../../services/portfolio.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AnalyticsUtils } from '../../services/analytics-utils';


@Component({
  selector: 'app-my-portfolio-card',
  templateUrl: './my-portfolio-card.component.html',
  styleUrls: ['./my-portfolio-card.component.scss']
})

export class MyPortfolioCardComponent implements OnInit {
   private subscriptions: Subscription[];
   userRole: string|null = null
   showCard: boolean = true;
   hasBrokerages!: boolean ;
   loader: boolean = true

   constructor(private analyticsUtils:AnalyticsUtils, private portfolioService: PortfolioService, private router: Router) {
      this.subscriptions = [];
   }

   ngOnInit(): void {
      this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
         this.userRole = data.role
         if(data.isDataFetched) {
            if(this.userRole === 'PREMIUM') {
               this.fetchPortfolioAccounts()
            } else {
               this.loader = false
            }
         }
      }))
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
        this.subscriptions.forEach((item) => {
          item.unsubscribe();
        });
      }
   }

   homePortfolioTapped(){
      this.analyticsUtils.home_portfolio_tapped();
   }

   async fetchPortfolioAccounts() {
      this.loader = true

      try {
         const response = await lastValueFrom(this.portfolioService.getAccounts())
         if(response.status) {
            if(response.data.length > 0) {
               this.loader = false
               this.hasBrokerages = true
            } else {
               this.loader = false
               this.hasBrokerages = false
            }
         }
      } catch (error) {
         this.loader = false
      } finally {
         this.loader = false
      }

   }

}
