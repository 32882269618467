import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'millionPipe' })
export class MillionPipe implements PipeTransform {
   transform(value: any, marketCap?: boolean): any {
      value = +value;
      if (marketCap) {
         value = value * 1000000;
      }
      value = formatNumber(value, 'en-US', '1.2-2').replace(/,/g, '')
      if (value < 10000) {
         return value;
      }
      if (value >= 10000 && value < 999999) {
         return (value / 1000).toFixed(2) + 'K';
      }

      if (value >= 1000000 && value < 999999999) {
         return (value / 1000000).toFixed(2) + 'M';
      }

      if (value >= 1000000000 && value < 999999999999) {
         return (value / 1000000000).toFixed(2) + 'B';
      }

      if (value >= 1000000000000 && value < 999999999999999) {
         return (value / 1000000000000).toFixed(2) + 'T';
      }
   }
}
