import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'highLightPipe' })

export class HighLightPipe implements PipeTransform {
   transform(word: string, searchingWord: string) {
      searchingWord = searchingWord.trim().toLowerCase()

      const index = word.toLowerCase().trim().indexOf(searchingWord)

      if (index !== -1) {
         return  word.substring(0, index) + '<span class="search__matched-fragment">' + word.substr(index, searchingWord.length) + '</span>' + word.substring(index + searchingWord.length)
      }

      return word
   }
}
