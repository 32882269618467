<div class="table-responsive">
   <div
      class="d-flex align-items-center justify-content-center py-4"
      *ngIf="loader"
   >
      <div class="spinner-border text-success" role="status">
         <span class="visually-hidden">Loading...</span>
      </div>
   </div>
   <div *ngIf="!loader">
      <table class="table px-0">
         <thead>
            <tr>
               <th
                  scope="col"
                  class="holdings text-nowrap p-3"
                  style="width: 50%"
               >
                  Holdings
               </th>
               <th
                  scope="col"
                  class="holdings text-nowrap p-3"
                  style="width: 20%"
               >
                  Compliance
               </th>

               <th
                  scope="col"
                  class="weight text-center text-nowrap p-3"
                  style="width: 15%"
               >
                  Weight
               </th>
               <th
                  scope="col"
                  class="holdings text-nowrap p-3"
                  style="width: 15%"
               >
                  CBA Status
               </th>
               <!-- <th scope="col" class="core text-center text-nowrap p-3" style="width: 25%;">Core Business Activity</th> -->
            </tr>
         </thead>
         <tbody>
            <tr
               *ngFor="
                  let holding of holdings
                     | slice
                        : (page - 1) * pageSize
                        : (page - 1) * pageSize + pageSize;
                  let i = index
               "
               role="button"
               (click)="setEtfLocation(holding)"
            >
               <td class="px-3 py-2 align-middle">
                  <div class="d-flex align-items-center">
                     <img
                        [src]="holding.logo"
                        class="bg-light me-3 rounded-circle company-image"
                        [alt]="holding.name"
                        *ngIf="holding.logo"
                     />
                     <span
                        *ngIf="!holding.logo"
                        class="fw-600 me-3 bg-light company-image rounded-circle d-flex align-items-center justify-content-center fs-4 h-100"
                        >{{ holding.name?.substr(0, 1) }}</span
                     >
                     <div>
                        <h6
                           class="mb-0 company-name fw-600 lh-1 mb-0 d-inline-block text-nowrap text-truncate"
                        >
                           {{ holding.name }}
                        </h6>
                        <p class="mb-0 company-ticker lh-1">
                           {{ holding.symbol }}
                        </p>
                     </div>
                  </div>
               </td>
               <td class="px-3 py-2 align-middle text-center">
                  <app-compliance-chip
                     [showCompliance]="i < 3"
                     [symbol]="holding?.isin"
                     [align]="'center'"
                     [complianceStatus]="holding?.shariahStates"
                     [complianceRanking]="holding?.ranking"
                     [complianceRankingV2]="holding?.ranking_v2"
                  >
                  </app-compliance-chip>
               </td>
               <td class="px-3 py-2 align-middle text-center">
                  {{ holding.percent | number : "1.2-2" }}%
               </td>
               <td
                  class="px-3 py-2 align-middle text-center"
                  [ngClass]="getstatuscolorClasses(holding.cbaStatus)"
               >
                  {{ holding.cbaStatus }}
               </td>

               <!-- <td class="px-3 py-2 align-middle text-center">
                  <span class="holding-status pass" *ngIf="holding?.cbaStatus?.toLowerCase() == 'pass'">Pass</span>
                  <span class="holding-status fail" *ngIf="holding?.cbaStatus?.toLowerCase() == 'fail'">Fail</span>
                  <span class="holding-status fail" *ngIf="!holding?.cbaStatus">-</span>
               </td> -->
            </tr>
         </tbody>
      </table>

      <div
         class="d-flex justify-content-start justify-content-lg-end px-3 pb-3 px-lg-4"
      >
         <ngb-pagination
            [collectionSize]="collectionSize"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="3"
         >
         </ngb-pagination>
      </div>
   </div>
</div>
