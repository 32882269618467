<div class="bottomsheet-container" [ngClass]="isOpen ? 'menu-open' : ''" *ngIf="isMobile">
   <header class="d-flex p-3 align-items-center justify-content-between">
      <h5 class="mb-0 fw-600">{{ title }}</h5>
      <button type="button" class="btn bg-light shadow-none rounded-3 p-2" (click)="closeBottomSheet()">
         <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="#000" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
         </svg>
      </button>
   </header>
 
   <div class="content-container px-3 pt-3 pb-4">
      <ng-content></ng-content>
   </div>
</div>

<div class="bottomsheet-backdrop" [ngClass]="isOpen ? 'show-bottomsheet-backdrop' : '' " (click)="closeBottomSheet()"></div>
