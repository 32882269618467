<header class="d-flex px-0 align-items-center justify-content-between gap-3 section-header">
   <h2 class="mb-0 heading">Market News</h2>
   <!-- <a class="btn rounded-3 text-white text-nowrap py-2 px-3 see-all-btn" routerLink="/cabinet/academy-articles">See All</a> -->
</header>

<div class="news-container">
   <div class="news__row__main">
      <a class="single-news text-decoration-none" [href]="singleNews.URL" target="_blank">
         <div class="news-image-wrapper single-news-image bg-light rounded-3 overflow-hidden">
            <img [appLazyLoadImage]="singleNews.Image" placeholder="/assets/images/dashboard/news-lowres.png"
               [alt]="singleNews.Headline" class="news-image w-100 img-fluid">
         </div>
         <div class="d-grid gap-1">
            <h5 class="mb-1 single-news-headline clamp-line clamp-line-2">{{ singleNews.Headline }}</h5>
            <span class="lh-1 text-uppercase single-news-date">
               {{ formattedDate(singleNews.Datetime) | date: "medium" }}
            </span>
            <p class="single-news-summary clamp-line my-2 clamp-line-3">{{ singleNews.Summary }}</p>
            <button class="btn p-0 text-uppercase text-start single-news-link">Read More</button>
         </div>
      </a>

      <a class="single-news text-decoration-none mobile_hidden" [href]="singleNews.URL" target="_blank">
         <div class="news-image-wrapper single-news-image bg-light rounded-3 overflow-hidden">
            <img [appLazyLoadImage]="singleNews.Image" placeholder="/assets/images/dashboard/news-lowres.png"
               [alt]="singleNews.Headline" class="news-image w-100 img-fluid">
         </div>
         <div class="d-grid gap-1">
            <h5 class="mb-1 single-news-headline clamp-line clamp-line-2">{{ singleNews.Headline }}</h5>
            <span class="lh-1 text-uppercase single-news-date">
               {{ formattedDate(singleNews.Datetime) | date: "medium" }}
            </span>
            <p class="single-news-summary clamp-line my-2 clamp-line-3">{{ singleNews.Summary }}</p>
            <button class="btn p-0 text-uppercase text-start single-news-link">Read More</button>
         </div>
      </a>
   </div>

   <div class="carousel-wrapper">
      <owl-carousel-o [options]="customOptions">
         <ng-container *ngFor="let news of marketNews; trackBy: trackByNewsId">
            <ng-template carouselSlide [id]="news.id">
               <a class="text-decoration-none news-card rounded-3" [href]="news.URL" target="_blank">
                  <div class="news-image-wrapper bg-light rounded-3 overflow-hidden">
                     <img [appLazyLoadImage]="news.Image" placeholder="/assets/images/dashboard/news-lowres.png"
                        [alt]="news.Headline" class="news-image img-fluid">
                  </div>
                  <div class="d-flex flex-column gap-2 news-info p-3">
                     <span class="text-uppercase lh-1 news-source">{{ news.Source }}</span>
                     <h5 class="mb-0 news-headline clamp-line clamp-line-3">{{ news.Headline }}</h5>
                  </div>
               </a>
            </ng-template>
         </ng-container>
      </owl-carousel-o>
      <!-- <button class="btn py-2 d-block mt-4 mx-auto px-3 px-lg-5 read-more-btn">
         Read More News
      </button> -->
   </div>
</div>
