<section class="content-padding bg-white" *ngIf="userRole === 'ANONYMOUS'">
   <div class="container">
      <h2 class="text-center section-main-heading">
         The go-to platform for <br class="d-none d-lg-block"> <span>Shariah-compliant</span> investment research
      </h2>
      <ul role="list" class="stats-wrapper">
         <li class="stat-item">
            <article class="d-flex flex-column gap-2 stat-card">
               <div class="d-flex align-items-center justify-content-between align-items-lg-start flex-lg-column gap-3">
                  <h5 class="mb-0 pt-1 stat-title order-lg-1">
                     #2
                  </h5>
                  <img src="/assets/images/dashboard/search-engine.svg" alt="Islamic Fintech in the USA" class="img-fluid stat-image order-lg-0">
               </div>
               <p class="mb-0 stat-description">
                  Islamic Fintech in the USA <br>
                  As per <a href="https://salaamgateway.com/ranking/top-30-digital-islamic-economy-startups-2024" target="_blank">Salaam Gateway, 2024 </a>
               </p>
            </article>
         </li>

         <li class="stat-item">
            <article class="d-flex flex-column gap-2 stat-card">
               <div class="d-flex align-items-center justify-content-between align-items-lg-start flex-lg-column gap-3">
                  <h5 class="mb-0 pt-1 stat-title order-lg-1">
                     {{ userCount ? ( userCount | number ) : '410,000+' }}
                  </h5>
                  <img src="/assets/images/dashboard/world.svg" alt="Islamic Fintech in the USA" class="img-fluid stat-image order-lg-0">
               </div>
               <p class="mb-0 stat-description">
                  Muslim investors use our platform from <span class="fw-700 country-num">195</span> countries
               </p>
            </article>
         </li>

         <li class="stat-item">
            <article class="d-flex flex-column gap-2 stat-card">
               <div class="d-flex align-items-center justify-content-between align-items-lg-start flex-lg-column gap-3">
                  <h5 class="mb-0 pt-1 stat-title order-lg-1">
                     4.7
                  </h5>
                  <img src="/assets/images/dashboard/review.svg" alt="Islamic Fintech in the USA" class="img-fluid stat-image order-lg-0">
               </div>
               <p class="mb-0 stat-description">
                  Average rating from 6000+ reviews
               </p>
            </article>
         </li>

         <li class="stat-item">
            <article class="d-flex flex-column gap-2 stat-card">
               <div class="d-flex align-items-center justify-content-between align-items-lg-start flex-lg-column gap-3">
                  <h5 class="mb-0 pt-1 stat-title order-lg-1">
                     {{ stockCount ? ( stockCount | number ) : '90,000+' }}
                  </h5>
                  <img src="/assets/images/dashboard/monitor-stocks.svg" alt="Islamic Fintech in the USA" class="img-fluid stat-image order-lg-0">
               </div>
               <p class="mb-0 stat-description">
                  Stocks are screened for Shariah compliance.
               </p>
            </article>
         </li>

         <li class="stat-item">
            <article class="d-flex flex-column gap-2 stat-card">
               <div class="d-flex align-items-center justify-content-between align-items-lg-start flex-lg-column gap-3">
                  <h5 class="mb-0 pt-1 stat-title order-lg-1">
                     {{ etfsCount ? ( etfsCount | number ) : '3,000+' }}
                  </h5>
                  <img src="/assets/images/dashboard/monitor-etfs.svg" alt="Islamic Fintech in the USA" class="img-fluid stat-image order-lg-0">
               </div>
               <p class="mb-0 stat-description">
                  ETFs are screened for Shariah compliance.
               </p>
            </article>
         </li>

         <!-- <li *ngFor="let stat of stats" class="stat-item">
            <article class="d-flex flex-column gap-2 stat-card">
               <div class="d-flex align-items-center justify-content-between align-items-lg-start flex-lg-column gap-3">
                  <h5 class="mb-0 pt-1 stat-title order-lg-1" *ngIf="isNumber(stat.title); else nonNumeric">
                     {{ stat.title | number }}
                  </h5>
                  <ng-template #nonNumeric>
                     <h5 class="mb-0 pt-1 stat-title order-lg-1">
                        {{ stat.title}}
                     </h5>
                  </ng-template>
                  <img [src]="stat.image_path" [alt]="stat.title" class="img-fluid stat-image order-lg-0">
               </div>
               <p class="mb-0 stat-description">
                  {{ stat.description }}
               </p>
            </article>
         </li> -->
      </ul>
   </div>
</section>

