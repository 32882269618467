import { createAction, props } from '@ngrx/store';
import { PortfolioAccount } from 'src/app/features/our-portfolio/models/account-list-response.model';

export const loadAccounts = createAction('[Portfolio] Load Accounts');

export const loadAccountsSuccess = createAction(
  '[Portfolio] Load Accounts Success',
  props<{ accounts: PortfolioAccount[] }>()
);

export const loadAccountsFailure = createAction(
  '[Portfolio] Load Accounts Failure',
  props<{ error: string }>()
);

export const setSelectedAccount = createAction(
  '[Portfolio] Set Selected Account',
  props<{ accountId: string }>()
);

export const PortfolioActions = {
  loadAccounts,
  loadAccountsSuccess,
  loadAccountsFailure,
  setSelectedAccount
};
