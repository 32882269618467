<div class="modal-header border-0 flex-column w-100 p-0">

   <div class="brokarge-modal-header border-bottom p-3 p-lg-4 pt-xl-5">
      <button class="p-1 btn text-black shadow-none close-btn" (click)="activeModal.dismiss()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
      <div>
         <h4 class="text-center mb-2">Connect your broker</h4>
      </div>
      <div class="header-search">
         <div class="search-main-container">
            <div class="d-flex align-items-center overflow-hidden search-input-container border mx-auto">
               <div class="search-icon-wrapper d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-search search-icon" viewBox="0 0 16 16">
                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
               </div>
               <input class="search-input flex-grow-1" placeholder="Search Broker Here..." autocomplete="off" [(ngModel)]="searchText" (input)="searchBrokerage()" />
               <ul class="search-dropdown" *ngIf="searching">
                  <li class="dropdown-item" *ngFor="let brokerage of searchBrokerages" (click)="initiateBrokerage(brokerage)" >
                    <img *ngIf="!brokerage.square_logo" src="https://ui-avatars.com/api/?name={{ brokerage.name }}&background=101e37&color=fff" alt="Broker 1 Logo" class="dropdown-logo" />
                    <img *ngIf="brokerage.square_logo" [src]="brokerage.square_logo" class="dropdown-logo" [alt]="brokerage.name"/>
                    <div class="broker-info">
                      <span class="broker-name">{{ brokerage.name }}</span>
                      <div class="features">
                        <span class="feature">
                          <span class="feature-icon tick"></span>
                          <span class="feature-label">Portfolio Tracking</span>
                        </span>
                        <span class="feature">
                          <span class="feature-icon tick" *ngIf="brokerage.supported_features.is_trading_allowed"></span>
                          <span class="feature-icon cross" *ngIf="!brokerage.supported_features.is_trading_allowed"></span>
                          <span class="feature-label">Trading</span>
                        </span>
                        <span class="feature">
                          <span class="feature-icon cross" *ngIf="!brokerage.supported_features.automated_purification"></span>
                          <span class="feature-icon tick" *ngIf="brokerage.supported_features.automated_purification"></span>
                          <span class="feature-label">Automated Purification</span>
                        </span>
                      </div>
                    </div>
                  </li>
                  <div class="mb-2 mb-lg-4" *ngIf="searchLoading">
                     <p class="no-data-text m-2">Loading...</p>
                  </div>
                  <div class="mb-2 mb-lg-4" *ngIf="!searchBrokerages.length && !searchLoading">
                     <p class="no-data-text m-2">No supported brokerage found.</p>
                  </div>
                </ul>
            </div>
         </div>   
      </div>
   </div>
</div>

<div class="modal-body p-3 p-lg-4 ">
   <ng-container *ngIf="loading">
      <div class="grid-view gap-3">
         <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress"
               [theme]="{ height: '150px', width: '100%', 'border-radius': '4px', margin: '0px' }">
            </ngx-skeleton-loader>
         </div>
      </div>
   </ng-container>
   <ng-container *ngIf="!loading">
      <h5 class="mb-2">Top brokers worldwide</h5>
      <!-- supported brokerages -->
      <div *ngIf="filteredBrokerages.length" class="grid-view gap-3 mb-4">
         <button class="btn shadow-none d-flex align-items-start single-brokerage" *ngFor="let brokerage of filteredBrokerages" (click)="initiateBrokerage(brokerage)" [disabled]="isConnectLoading">

            <!-- Loader layer -->
            <div *ngIf="isConnectLoading && currBrokerageId === brokerage.brokerage_id" class="single-brokerage_loader">
               <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
               </div>
            </div>

            <!-- <div class="d-flex align-items-center justify-content-between gap-4"> -->
            <div class="d-flex flex-column align-items-start gap-3">
               <div class="d-flex align-items-center gap-3">
                  <img [src]="brokerage.square_logo" class="d-block img-fluid" [alt]="brokerage.name" *ngIf="brokerage.square_logo" />
                  <img src="https://ui-avatars.com/api/?name={{ brokerage.name }}&background=ccff00&color=000" class="d-block img-fluid" [alt]="brokerage.name" *ngIf="!brokerage.square_logo" /> 
                  <div class="d-flex align-items-center gap-3 gal-lg-4">
                     <h6 class="mb-2 line-clamp-1">{{ brokerage.name }}</h6>
                  </div>
               </div>
               <div class="d-block w-100" style="height: 1px; background-color: #B0D1E1;"></div>
               <div>
                  <div class="features">
                     <div class="d-flex gap-2 align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#009000" fill="none">
                           <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Portfolio Tracking</span>
                     </div>
                     <div class="d-flex gap-1 align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#009000" fill="none" *ngIf="brokerage.supported_features.is_trading_allowed">
                           <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#E82127" fill="none" *ngIf="!brokerage.supported_features.is_trading_allowed">
                           <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Trading</span>
                     </div>
                     <div class="d-flex gap-1 align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#009000" fill="none" *ngIf="brokerage.supported_features.automated_purification">
                           <path d="M5 14L8.5 17.5L19 6.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#E82127" fill="none" *ngIf="!brokerage.supported_features.automated_purification">
                           <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Automated Purification</span>
                     </div>
                  </div>
               </div>
            </div>
         </button>
      </div>
      <div class="mb-3 mb-lg-4" *ngIf="!filteredBrokerages.length">
         <p class="no-data-text m-0">No supported brokerage found.</p>
      </div>
      <!-- <app-plaid-card [isInsideBrokeragesPopup]='true' (plaidLoading)="plaidLoading($event)"></app-plaid-card> -->
      <app-add-manual-card
         [portfolioAccounts]="portfolioAccounts"
         [portfolioType]="'manual'"
         [isInsideBrokeragesPopup]="true">
      </app-add-manual-card>

   </ng-container>
</div>

