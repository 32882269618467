import { BreakpointObserver } from '@angular/cdk/layout';
import { DecimalPipe, Location, PlatformLocation } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { screaningMillionPipe } from '../../pipes/screaning-million.pipe';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TypesenseService } from '../../services/typesense.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { Subscription } from 'rxjs';
import { ConditionalRenderingService } from '../../services/conditional-rendering.service';
import { ApiService } from '../../services/api.service';
import { Toast } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-screener-report',
   templateUrl: './screener-report.component.html',
   styleUrls: ['./screener-report.component.scss'],
})
export class ScreenerReportComponent implements OnInit {
   id: any;
   complianceLoading: boolean = false;
   subTicker!: string;
   rate: number = 1;
   usdRate: number = 1;
   currency!: string;
   closeResult!: string;
   isLimitReached: boolean = false;
   private subscriptions: Subscription[] = [];
   // userRole: string | null = null;
   @Input() isSample: boolean = false;
   @Output() closeModal = new EventEmitter<void>();
   @Output() responseLoaded = new EventEmitter<any>();
   businessText: string =
      'Revenue screening is based on the revenue sources of a company. The combined value of Non Halal and Doubtful sources should not exceed 5% of the Total Revenue (Gross Sales + Other Income).';
   interestText: string =
      'Total amount of interest-bearing securities and assets, whether short or long term, should not exceed 30% of the market capitalization of the company to be Shariah compliant.';
   debtText: string =
      'Interest-bearing debt, whether long-term or short-term debt should not exceed 30% of the market capitalization of the company.';

   constructor(
      private screaningMillionPipe: screaningMillionPipe,
      private decimalPipe: DecimalPipe,
      private observer: BreakpointObserver,
      private datePipe: DatePipe,
      private route: ActivatedRoute,
      private typesenseService: TypesenseService,
      private toaster: ToastrService,
      private router: Router,
      private apiService: ApiService,
      private modalService: NgbModal,
      private location: PlatformLocation // private conditionalRenderingService: ConditionalRenderingService,
   ) {
      this.subscriptions = [];
      router.events.forEach((event) => {
         if (event instanceof NavigationStart) {
            if (event.navigationTrigger === 'popstate') {
               /* Do something here */
               this.modalService.dismissAll();
            }
         }
      });
   }
   active = 1;
   showPercent = true;
   data: any;
   @Input() storedResponse: any;
   @Input() companyProfile?: any = null;
   isMobile = false;
   panelStatus = {
      businessPanel: false,
      businessPanel2: false,
      interestCalculationPanel: false,
      debtCalculationPanel: false,
   };
   businessObj = {
      showPercent: false,
   };
   securitiesObj = {
      showPercent: false,
   };
   debtObj = {
      showPercent: false,
   };

   grossIncomeObj = {
      halal: {
         items: [],
      },
      notHalal: {
         items: [],
      },
      doubtful: {
         items: [],
      },
   };

   otherIncomeObj = {
      halal: {
         items: [],
      },
      notHalal: {
         items: [],
      },
      doubtful: {
         items: [],
      },
   };

   closeActiveModel() {
      this.closeModal.emit();
   }

   async ngOnInit(): Promise<void> {
      //  this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      //     this.userRole = data.role
      //  }))
      // console.log('this.companyProfile?.mainTicker');
      let response: any;
      if(this.storedResponse) {
         response = this.storedResponse
      } else {
         this.complianceLoading = true;
         if(this.isSample) {
            response = await this.apiService.getSampleStockComplianceInfo()
         } else {
            response = await this.apiService.getStockComplianceInfo(this.companyProfile?.mainTicker)
         }
         this.responseLoaded.emit(response)
         this.complianceLoading = false;
      }

      if(response?.status_code === 'SUCCESS') {
         const data: any = response?.data?.compliance_report
         this.processData(data)
      } else if(response?.status_code === 'LIMIT_REACHED'){
         this.isLimitReached = true
      } else if(response?.status_code === 'NOT_COVERED') {
         this.data = {
            status: 'NOT_UNDER_COVERAGE'
         }
      } else {
         this.toaster.error('Something went wrong')
      }
   }

   processData(data: any) {
      this.data = data
      this.currency = this.companyProfile?.currency;
      this.route.paramMap.subscribe((params) => {
         this.id = params.get('id');
         this.getSubTickers(this.id);
      });

      this.usdRate = this.data?.usdRate ?? 1;
      this.observer.observe('(max-width: 768px)').subscribe((result) => {
         this.isMobile = result.matches;
         // Do something with the result
      });

      if (this.isBusinessActivityAvailable()) this.active = 1;
      else if (this.isSecuritiesAvailable()) this.active = 2;
      else if (this.isDebtAvailable()) this.active = 3;

      this.parseTree(
         this.data['revenueBreakdownJson.items'],
         0,
         this.grossIncomeObj
      );
      this.parseTree(
         this.data['interestIncomeJson.items'],
         0,
         this.otherIncomeObj
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   // async fetchData() {
   //   this.subTicker = await this.typesenseService.getStockComplianceInfo(this.id)
   //   console.log(this.subTicker);

   // }

   parseTree(array: any, level: number, bucket: any) {
      level += 1;
      for (var i = 0, len = array.length; i < len; i++) {
         var obj = array[i];
         if (obj.items.length > 0) {
            this.parseTree(obj.items, level, bucket);
         } else {
            if (obj.selector == 'COMPLIANT') {
               bucket.halal.items.push(obj);
               bucket.halal.percent += obj.percentage;
            } else if (obj.selector == 'NON_COMPLIANT') {
               bucket.notHalal.items.push(obj);
               bucket.notHalal.percent += obj.percentage;
            } else if (obj.selector == 'QUESTIONABLE') {
               bucket.doubtful.items.push(obj);
               bucket.doubtful.percent += obj.percentage;
            }
         }
      }
   }

   isBusinessActivityAvailable() {
      return this.data?.['revenueBreakdownStatus'];
      // return this.data?.['revenueBreakdownJson.questionablePercentage'] != null
   }

   isSecuritiesAvailable() {
      return this.data?.['securitiesAndAssetsStatus'];
      // return this.data?.['securitiesAndAssetsJson.totalAmount'] != null
   }

   isDebtAvailable() {
      return this.data?.['debtStatus'];
      // return this.data?.['interestBearingDebtJson.totalAmount'] != null
   }

   getStatusDisplayValue() {
      switch (this.data?.status) {
         case 'COMPLIANT':
            return 'Halal';
         case 'NON_COMPLIANT':
            return 'Not Halal';
         case 'QUESTIONABLE':
            return 'Doubtful';
         case 'NOT_UNDER_COVERAGE':
            return 'Not Covered';
         default:
            return '';
      }
   }

   getPassFailText() {}

   isActive(currentId: any) {
      if (currentId == this.active) {
      }
   }

   getSubTickers(id: string) {
      let objsArr = this.data.subTickersInfo;

      for (const obj of objsArr) {
         if (this.id === obj.subTicker) {
            this.rate = obj.rate ? obj.rate : 1;
            // this.currency = obj.currency

            // console.log(this.rate, this.currency);
         }
      }
   }

   // isPassed(bool:any){
   //   return bool
   // }

   // getTabClass(currentId:any){
   //   var isPassed = true
   //   if(currentId == 1)
   //     isPassed = false
   //   if(currentId == this.active){
   //     if(isPassed)
   //       return "pass-bg"
   //     else
   //       return "fail-bg"
   //   }
   //   else{
   //     if(isPassed)
   //       return "pass-bg-light"
   //     else
   //       return "fail-bg-light"
   //   }
   // }

   getLastUpdated() {
      return this.datePipe.transform(
         this.data?.lastUpdate * 1000,
         'MMMM dd, yyyy'
      );

      // if(this.data?.financialStatementDate == null)
      //   return this.datePipe.transform(this.data?.lastUpdate * 1000, 'MMMM dd, yyyy')
      // else{
      //   if(this.active == 1){
      //     return this.datePipe.transform(this.data?.lastUpdate * 1000, 'MMMM dd, yyyy')
      //   }
      //   else{
      //     return this.datePipe.transform(this.data?.financialStatementDate, 'MMMM dd, yyyy')
      //   }
      // }
   }

   getSecuritiesItemVal(obj: any) {
      if (!this.securitiesObj.showPercent)
         return this.screaningMillionPipe.transform(obj?.amountInOnes);
      else return this.decimalPipe.transform(obj?.percentage, '1.2-2') + '%';
   }

   getBusinessValueOrPercent(value: any, percent: any) {
      if (!this.businessObj.showPercent)
         return this.decimalPipe.transform(percent, '1.2-2') + '%';
      else
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(value)
         );
   }

   getInterestDebtValueOrPercent(value: any, percent: any) {
      if (!this.debtObj.showPercent)
         return this.decimalPipe.transform(percent, '1.2-2') + '%';
      else
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(value)
         );
   }

   getSecuritiesAndAssetsDebtValueOrPercent(value: any, percent: any) {
      if (!this.securitiesObj.showPercent)
         return this.decimalPipe.transform(percent, '1.2-2') + '%';
      else
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(value)
         );
   }

   getDebtItemSubtitleVal(index: number) {
      let subtitles = [
         'Due to be paid within a 12-month period',
         'Due to be paid in more than a 12-month period',
      ];
      if (index < subtitles.length) return subtitles[index];
      return '';
   }
   getDebtItemVal(obj: any) {
      if (!this.debtObj.showPercent)
         return this.screaningMillionPipe.transform(obj?.amountInOnes);
      else return this.decimalPipe.transform(obj?.percentage, '1.2-2') + '%';
   }

   getTotalBusinessAct() {
      if (this.businessObj.showPercent)
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(this.data?.totalRevenue)
         );
      // return this.decimalPipe.transform(this.data?.securitiesAndAssets,'1.2-2')+"%"
      else return '100%';
   }

   getConvertedAmount(amt: any) {
      return (amt / this.usdRate) * this.rate;
   }

   getTotalInterestBearingDebt() {
      if (this.debtObj.showPercent)
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(
               this.data?.['interestBearingDebtJson.totalNonIslamicAmount'] ?? 0
            ) * this.data?.units
         );
      else
         return (
            this.decimalPipe.transform(
               this.data?.['interestBearingDebtJson.totalRetio'] ?? 0,
               '1.2-2'
            ) + '%'
         );
   }

   getTotalSecuritiesAndAssets() {
      if (this.securitiesObj.showPercent)
         return this.screaningMillionPipe.transform(
            this.getConvertedAmount(
               // this.data?.['securitiesAndAssetsJson.totalNonIslamicAmount'] ?? 0
               this.data?.['securitiesAndAssetsRevenue'] ?? 0
            ) * this.data?.units
         );
      else
         return (
            this.decimalPipe.transform(
               this.data?.['securitiesAndAssetsJson.totalRetio'] ?? 0,
               '1.2-2'
            ) + '%'
         );
   }

   getQuarterText() {
      var quarter = '';
      if (['Annual', 'ANNUAL'].includes(this.data?.reportTypeSection)) {
         var text = `${this.data?.reportTypeYear} Annual Report`;
         return text;
      } else {
         switch (this.data?.reportTypeSection) {
            case 'firstQuarter':
            case 'FIRST_QUARTER':
               quarter = '1st';
               break;
            case 'secondQuarter':
            case 'SECOND_QUARTER':
               quarter = '2nd';
               break;
            case 'thirdQuarter':
            case 'THIRD_QUARTER':
               quarter = '3rd';
               break;
            case 'fourthQuarter':
            case 'FOURTH_QUARTER':
               quarter = '4th';
               break;
            default:
               quarter = '';
         }

         var text = `${this.data?.reportTypeYear} ${quarter} Quarter Report`;
         return text;
      }
   }

   onPanelShown(event: any) {
      switch (event) {
         case 'business-panel':
            this.panelStatus.businessPanel = true;
            break;
         case 'business-panel2':
            this.panelStatus.businessPanel2 = true;
            break;
         case 'debt-calculation-panel':
            this.panelStatus.debtCalculationPanel = true;
            break;
         case 'interest-calculation-panel':
            this.panelStatus.interestCalculationPanel = true;
            break;
      }
   }

   onPanelHidden(event: any) {
      switch (event) {
         case 'business-panel':
            this.panelStatus.businessPanel = false;
            break;
         case 'business-panel2':
            this.panelStatus.businessPanel2 = false;
            break;
         case 'debt-calculation-panel':
            this.panelStatus.debtCalculationPanel = false;
            break;
         case 'interest-calculation-panel':
            this.panelStatus.interestCalculationPanel = false;
            break;
      }
   }

   setActive(no: number) {
      this.active = no;
      this.tabChange();
   }
   tabChange() {
      this.panelStatus = {
         businessPanel: false,
         businessPanel2: false,
         interestCalculationPanel: false,
         debtCalculationPanel: false,
      };
   }
   getCardClass(isOpen: boolean, status: string) {
      var cardClass = 'screening-report-panel-card';
      if (status == 'Pass') cardClass += ' pass';
      if (status == 'Fail') cardClass += ' fail';
      if (isOpen) cardClass += ' active';
      return cardClass;
   }

   filterGrossSaleItems(status: string) {
      return this.data?.['revenueBreakdownJson.items'].filter((item: any) => {
         return item['selector'] == status;
      });
   }

   filterOtherIncomeItems(status: string) {
      return this.data?.['interestIncomeJson.items'].filter((item: any) => {
         return item['selector'] == status;
      });
   }

   handleReportVisibility(status: string) {
      return ['COMPLIANT', 'NON_COMPLIANT', 'QUESTIONABLE'].includes(status);
   }

   // onViewSampleClicked(componentName: string) {
   //   this.conditionalRenderingService.setComponentType(componentName);
   //   // console.log(`Show Sample button clicked in ${componentName} component.`);
   // }
}
