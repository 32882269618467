import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Meta } from '@angular/platform-browser';

@Component({
   selector: 'app-all-lists-header',
   templateUrl: './all-lists-header.component.html',
   styleUrls: ['./all-lists-header.component.scss'],
})
export class AllListsHeaderComponent implements OnInit {
   selectedTab: number = 1;
   userRole!: string | null;
   private destroy$ = new Subject<void>();
   symbol!: string

   constructor(private router: Router, private route: ActivatedRoute, private metaService: Meta) {
   }

   ngOnInit(): void {
      this.updateQueryParams();
      this.metaService.updateTag({ name: 'title', content: 'User Generated Collection of Halal Investment Ideas' });
      this.metaService.updateTag({ name: 'description', content: 'Discover user generated collection of halal and shariah compliant ideas across the world. Filter by Market, etc.' });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   updateQueryParams() {
      this.route.queryParams
         .pipe(takeUntil(this.destroy$))
         .subscribe((params) => {
            this.symbol = params['symbols'] ? params['symbols'] : ''

            const list = params['lists'];
            if (list === 'all') {
               this.selectedTab = 1;
            } else if (list === 'followed') {
               this.selectedTab = 2;
            } else if (list === 'created') {
               this.selectedTab = 3;
            } else {
               this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { lists: 'all' },
                  queryParamsHandling: 'merge',
               });
            }
         });
   }

   selectTab(tab: number) {
      this.selectedTab = tab;
      let queryParams = {};

      if (tab === 1) {
         queryParams = { lists: 'all', page: 1  };
      } else if (tab === 2) {
         queryParams = { lists: 'followed', page: 1  };
      } else if (tab === 3) {
         queryParams = { lists: 'created', page: 1  };
      }

      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: queryParams,
         queryParamsHandling: 'merge',
      });
   }

   navigateToLoginScreen(){
      this.router.navigate(['/authentication/login'])
   }
}
