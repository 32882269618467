<ng-container>
   <section class="content-padding">
      <div class="container py-lg-2">
         <div class="section-grid-wrapper">
            <div class="min-width-zero text-section text-center text-lg-start">
               <h1 class="fw-600">
                  The go-to platform for <br> <span>Shariah-compliant</span> <br> investment research.
               </h1>
               <a class="btn text-white gradient-btn text-white fw-500 shadow-none d-flex align-items-center gap-2 mx-auto ms-lg-0 text-decoration-none rounded-3 free-watchlist-btn" routerLink="/authentication/login">
                  <span>Start your halal investment journey</span>
                  <svg width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                     <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                  </svg>
               </a>
               <!-- <ng-template #showLogin>
                  <a class="btn text-white gradient-btn text-white fw-500 shadow-none d-flex align-items-center gap-2 mx-auto ms-lg-0 text-decoration-none rounded-3 free-watchlist-btn" routerLink="/watchlist-lists">
                     <span>Start your halal investment journey</span>
                     <svg width="16" height="16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                     </svg>
                  </a>
               </ng-template> -->
            </div>
            <div class="min-width-zero video-section">
               <div class="position-relative overflow-hidden br-10 mx-auto me-lg-0 bg-light youtube-video-wrapper">
                  <iframe 
                     class="position-absolute top-0 bottom-0 start-0 end-0"
                     [src]="safeURL"
                     [title]="videoTitle" 
                     frameborder="0" 
                     allow="autoplay" 
                     referrerpolicy="strict-origin-when-cross-origin" 
                     allowfullscreen>
                  </iframe>
               </div>
            </div>
         </div>
      </div>
   </section>
</ng-container>
