<ng-container *ngIf="listLoader">
   <div class="container-lg loader-col p-3 p-lg-4 bg-white" *ngIf="!viewAll">
      <div *ngFor="let i of [1,2,3,4,5]" >
         <ngx-skeleton-loader
            count="1" appearance="line" animation="progress"
            [theme]="{ height: '80px', width: '100%', 'border-radius': '10px', margin: '0px'}">
         </ngx-skeleton-loader>
      </div>
   </div>

   <div class="container-lg loader-grid p-3 p-lg-4 bg-transparent" *ngIf="viewAll">
      <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12]" >
         <ngx-skeleton-loader
            count="1" appearance="line" animation="progress"
            [theme]="{ height: '80px', width: '100%', 'border-radius': '10px', margin: '0px'}">
         </ngx-skeleton-loader>
      </div>
   </div>
</ng-container>

<ng-container *ngIf="!listLoader">
   <div class="px-3 px-lg-0" *ngIf="superInvestorsList.length > 0">
      <section class="p-3 p-lg-4 superinvestors-container" [ngClass]="superInvestorsStyle">
         <header class="d-flex align-items-center justify-content-between mb-3 mb-lg-4">
            <h4 class="mb-0 section-heading">
               Super Investors <span *ngIf="viewAll && displaySymbol">in {{displaySymbol}}</span>
               <span class="align-middle d-inline-block bg-transparent border-0 rounded-circle ms-1" placement="bottom" [ngbTooltip]="superInvestorContent" tooltipClass="custom-width-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 24 24" width="16" height="16" color="#7e7e7e" fill="none">
                     <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                     <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M11.992 8H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </span>
               <ng-template #superInvestorContent>
                  <div class="text-start">
                     <p class="mb-2">Shows the list of renowned investors who have invested in this particular stock. </p>
                     <p class="mb-2">The % portfolio indicates: What % of their total portfolio is invested in this stock.</p>
                     <p class="mb-0">The recent activity indicates: Whether they increased or decreased their investment in this stock.</p>
                  </div>
               </ng-template>
               <!-- <span class="text-secondary fw-500 ms-2 fs-6">({{superInvestorsList.length}})</span> -->
            </h4>
            <a href="" class="btn shadow-none view-all-btn p-0" *ngIf="!viewAll && superInvestorsList.length > 6" [routerLink]="['/super-investors']" [queryParams]="{ stock: stockSymbol}" queryParamsHandling="merge">
               View All <span>&#10140;</span>
            </a>
         </header>

         <ng-container>
            <!-- <p class="mb-0 text-center fs-6 fw-bold" *ngIf="superInvestorsList && superInvestorsList.length === 0">No Super Investor has invested in this stock</p> -->

            <ul class="p-0 m-0 superInvestors-list" *ngIf="superInvestorsList.length > 0" [ngClass]="userRole !== 'PREMIUM' && superInvestorsList.length > 2 ? 'lock-height' : '' ">
               <li *ngFor="let superInvestor of filteredSuperInvestorsList">
                  <a class="super-investor-card text-decoration-none p-3 d-flex align-items-center justify-content-between gap-3" [routerLink]="['/super-investor-portfolio', superInvestor.cik]" [queryParams]="{stock: displaySymbol}" queryParamsHandling="merge">
                     <div class="d-flex align-items-start gap-3">
                        <img *ngIf="superInvestor.profileImage" src="{{superInvestor.profileImage}}" class="rounded-circle bg-light border investor-image" alt="">
                        <div *ngIf="!superInvestor.profileImage" class="investor-image rounded-circle border border-white bg-light fw-bold d-flex align-items-center justify-content-center fs-3">{{ superInvestor.name.substr(0, 1) }}</div>
                        <div>
                           <h6 class="super-investor-name text-black">{{superInvestor.name}}</h6>
                           <!-- <p class="my-1 grey-text lh-1 amount">% Portfolio: <span class="fw-600">{{superInvestor.percentage | number : "1.2-2" }}</span></p> -->
                           <p class="my-1 grey-text lh-1 amount">% Portfolio:
                              <span class="fw-600">
                                 <app-less-than-tooltip
                                    [amount]="superInvestor.percentage"
                                    [currency]="''"
                                    [tooltipPosition]="'left'"
                                    [amountStyle]="{ color: '#00000099', fontWeight: '500' }"
                                    [tooltipContent]="superInvestor.percentage">
                                 </app-less-than-tooltip>
                              </span>
                           </p>
                           <p class="mb-0 d-flex align-items-center flex-wrap grey-text gap-2 lh-1 amount">
                              <span class="text-nowrap">Recent Activity:</span>
                              <span class="d-flex gap-1 fw-600 align-items-center" *ngIf="superInvestor.percentageChange" [ngClass]="getPercentChangeClasses(superInvestor)">
                                 <span>{{ superInvestor.percentageChange | number : "1.2-2" }}%</span>
                                 <svg width="14" height="14" class="lh-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="superInvestor.percentageChange > 0">
                                    <path d="M6.33398 6.86671V9.00004C6.33398 9.18893 6.39787 9.34726 6.52565 9.47504C6.65343 9.60282 6.81176 9.66671 7.00065 9.66671C7.18954 9.66671 7.34787 9.60282 7.47565 9.47504C7.60343 9.34726 7.66732 9.18893 7.66732 9.00004V6.86671L8.26732 7.46671C8.38954 7.58893 8.5451 7.65004 8.73398 7.65004C8.92287 7.65004 9.07843 7.58893 9.20065 7.46671C9.32287 7.34448 9.38398 7.18893 9.38398 7.00004C9.38398 6.81115 9.32287 6.6556 9.20065 6.53337L7.46732 4.80004C7.33398 4.66671 7.17843 4.60004 7.00065 4.60004C6.82287 4.60004 6.66732 4.66671 6.53398 4.80004L4.80065 6.53337C4.67843 6.6556 4.61732 6.81115 4.61732 7.00004C4.61732 7.18893 4.67843 7.34448 4.80065 7.46671C4.92287 7.58893 5.07843 7.65004 5.26732 7.65004C5.45621 7.65004 5.61176 7.58893 5.73398 7.46671L6.33398 6.86671ZM7.00065 13.6667C6.07843 13.6667 5.21176 13.4917 4.40065 13.1417C3.58954 12.7917 2.88398 12.3167 2.28398 11.7167C1.68398 11.1167 1.20898 10.4112 0.858984 9.60004C0.508984 8.78893 0.333984 7.92226 0.333984 7.00004C0.333984 6.07782 0.508984 5.21115 0.858984 4.40004C1.20898 3.58893 1.68398 2.88337 2.28398 2.28337C2.88398 1.68337 3.58954 1.20837 4.40065 0.858374C5.21176 0.508374 6.07843 0.333374 7.00065 0.333374C7.92287 0.333374 8.78954 0.508374 9.60065 0.858374C10.4118 1.20837 11.1173 1.68337 11.7173 2.28337C12.3173 2.88337 12.7923 3.58893 13.1423 4.40004C13.4923 5.21115 13.6673 6.07782 13.6673 7.00004C13.6673 7.92226 13.4923 8.78893 13.1423 9.60004C12.7923 10.4112 12.3173 11.1167 11.7173 11.7167C11.1173 12.3167 10.4118 12.7917 9.60065 13.1417C8.78954 13.4917 7.92287 13.6667 7.00065 13.6667Z" fill="#1B5B44"/>
                                 </svg>
                                 <svg width="14" height="14" class="lh-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"  *ngIf="superInvestor.percentageChange < 0">
                                    <path d="M7.66602 7.13329V4.99996C7.66602 4.81107 7.60213 4.65274 7.47435 4.52496C7.34657 4.39718 7.18824 4.33329 6.99935 4.33329C6.81046 4.33329 6.65213 4.39718 6.52435 4.52496C6.39657 4.65274 6.33268 4.81107 6.33268 4.99996V7.13329L5.73268 6.53329C5.61046 6.41107 5.4549 6.34996 5.26602 6.34996C5.07713 6.34996 4.92157 6.41107 4.79935 6.53329C4.67713 6.65552 4.61602 6.81107 4.61602 6.99996C4.61602 7.18885 4.67713 7.3444 4.79935 7.46663L6.53268 9.19996C6.66602 9.33329 6.82157 9.39996 6.99935 9.39996C7.17713 9.39996 7.33268 9.33329 7.46602 9.19996L9.19935 7.46663C9.32157 7.3444 9.38268 7.18885 9.38268 6.99996C9.38268 6.81107 9.32157 6.65552 9.19935 6.53329C9.07713 6.41107 8.92157 6.34996 8.73268 6.34996C8.54379 6.34996 8.38824 6.41107 8.26602 6.53329L7.66602 7.13329ZM6.99935 0.333294C7.92157 0.333294 8.78824 0.508294 9.59935 0.858294C10.4105 1.20829 11.116 1.68329 11.716 2.28329C12.316 2.88329 12.791 3.58885 13.141 4.39996C13.491 5.21107 13.666 6.07774 13.666 6.99996C13.666 7.92218 13.491 8.78885 13.141 9.59996C12.791 10.4111 12.316 11.1166 11.716 11.7166C11.116 12.3166 10.4105 12.7916 9.59935 13.1416C8.78824 13.4916 7.92157 13.6666 6.99935 13.6666C6.07713 13.6666 5.21046 13.4916 4.39935 13.1416C3.58824 12.7916 2.88268 12.3166 2.28268 11.7166C1.68268 11.1166 1.20768 10.4111 0.857683 9.59996C0.507684 8.78885 0.332684 7.92218 0.332684 6.99996C0.332684 6.07774 0.507684 5.21107 0.857683 4.39996C1.20768 3.58885 1.68268 2.88329 2.28268 2.28329C2.88268 1.68329 3.58824 1.20829 4.39935 0.858294C5.21046 0.508294 6.07713 0.333294 6.99935 0.333294Z" fill="#C70707"/>
                                 </svg>
                              </span>
                              <span *ngIf="!superInvestor.percentageChange">-</span>
                           </p>
                        </div>
                     </div>
                  </a>
               </li>
            </ul>
         </ng-container>

         <div class="d-flex flex-column gap-4 align-items-center justify-content-end pb-5 lock-wrapper" *ngIf="userRole !== 'PREMIUM' && superInvestorsList.length > 2">
            <img src="/assets/images/lock.png" class="lock-image" alt="lock-image">
            <button class="btn text-white shadow-none rounded-3 py-2 px-4 gradient-btn" *ngIf="userRole === 'ANONYMOUS'" (click)="accessControlService.accessDenied()">
               <!-- Sign-up & Upgrade to Unlock! -->
               <img src="/assets/icons/lock-white.svg" width="16" height="16" alt="lock image" class="img-fluid">
               <span>Unlock</span>
            </button>
            <button class="btn text-white shadow-none rounded-3 py-2 px-4 gradient-btn" *ngIf="userRole === 'FREEMIUM'" (click)="accessControlService.accessDenied()">
               <!-- Upgrade & Unlock! -->
               <img src="/assets/icons/lock-white.svg" width="16" height="16" alt="lock image" class="img-fluid">
               <span>Unlock</span>
            </button>
         </div>
      </section>

      <section class="pt-4 pt-lg-5" *ngIf="viewAll && superInvestorsList && superInvestorsList.length === 0">
         <div class="bg-white rounded container-lg px-3 py-5 px-lg-4 ">
            <p class="mb-0 text-center fs-6 fw-bold">No Super Investor has invested in this stock</p>
         </div>
      </section>


   </div>
</ng-container>

