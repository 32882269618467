<div class="login-form-container" [ngClass]="popupStyling">

   <h3 class="form-heading">Assalamu Alaykum</h3>
   <p class="mb-0 form-welcome-text">Welcome to Musaffa!</p>
   <form class="login-form d-grid" [formGroup]="userLoginForm" (ngSubmit)="login()">
      <ng-container *ngIf="!showOtpSection">
         <div>
            <div class="d-flex align-items-center input-group-wrapper" [ngClass]="{'invalid': userLoginForm.controls.email.touched && userLoginForm.controls.email.invalid}">
               <svg class="input-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#A8A8A8" fill="none">
                  <path d="m7 7.5 2.942 1.74c1.715 1.014 2.4 1.014 4.116 0L17 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.5 19.5a116 116 0 0 1-1.401-.027c-3.149-.079-4.723-.118-5.854-1.255-1.131-1.136-1.164-2.67-1.23-5.737a69 69 0 0 1 0-2.953c.066-3.067.099-4.6 1.23-5.737C4.376 2.655 5.95 2.616 9.099 2.537a115 115 0 0 1 5.802 0c3.149.079 4.723.118 5.854 1.254s1.164 2.67 1.23 5.737c.009.455.014.668.015.972" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19 17a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0 0v.5a1.5 1.5 0 0 0 3 0V17a4.5 4.5 0 1 0-4.5 4.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
               <input type="email" id="email" class="input-field" placeholder="Enter Email Address" formControlName="email">
               <label for="email" class="input-label">Enter Email Address</label>
            </div>
            <small class="text-danger error-msg-text" *ngIf="userLoginForm.controls.email.touched && userLoginForm.controls.email.errors?.required">
               Email is required
            </small>
            <small class="text-danger error-msg-text" *ngIf="userLoginForm.controls.email.touched && userLoginForm.controls.email.errors?.pattern">
               Email is Invalid
            </small>
         </div>

         <div>
            <div>
               <div class="d-flex align-items-center input-group-wrapper" [ngClass]="{'invalid': userLoginForm.controls.password.touched && userLoginForm.controls.password.invalid}">
                  <svg class="input-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#A8A8A8" fill="none">
                     <path d="M12 16.5v-2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                     <path d="M4.268 18.845c.225 1.67 1.608 2.979 3.292 3.056 1.416.065 2.855.099 4.44.099s3.024-.034 4.44-.1c1.684-.076 3.067-1.385 3.292-3.055.147-1.09.268-2.207.268-3.345s-.121-2.255-.268-3.345c-.225-1.67-1.608-2.979-3.292-3.056A95 95 0 0 0 12 9c-1.585 0-3.024.034-4.44.1-1.684.076-3.067 1.385-3.292 3.055C4.12 13.245 4 14.362 4 15.5s.121 2.255.268 3.345Z" stroke="currentColor" stroke-width="1.5"/><path d="M7.5 9V6.5a4.5 4.5 0 0 1 9 0V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <input type="{{ showPassword ? 'text' : 'password' }}" id="password" class="input-field" formControlName="password" placeholder="Enter Password">
                  <label for="password" class="input-label">Enter Password</label>
                  <button type="button" class="btn shadow-none bg-transparent eye-btn p-0" (click)="togglePasswordVisibility()">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#A8A8A8" fill="none" *ngIf="!showPassword">
                        <path d="M19.439 15.439a19.5 19.5 0 0 0 2.105-2.484c.304-.426.456-.64.456-.955 0-.316-.152-.529-.456-.955C20.178 9.129 16.689 5 12 5c-.908 0-1.77.155-2.582.418m-2.67 1.33c-2.017 1.36-3.506 3.195-4.292 4.297-.304.426-.456.64-.456.955 0 .316.152.529.456.955C3.822 14.871 7.311 19 12 19c1.99 0 3.765-.744 5.253-1.747" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.858 10A2.929 2.929 0 1 0 14 14.142" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="m3 3 18 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                     </svg>
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#A8A8A8" fill="none" *ngIf="showPassword">
                        <path d="M21.544 11.045C21.848 11.4713 22 11.6845 22 12C22 12.3155 21.848 12.5287 21.544 12.955C20.1779 14.8706 16.6892 19 12 19C7.31078 19 3.8221 14.8706 2.45604 12.955C2.15201 12.5287 2 12.3155 2 12C2 11.6845 2.15201 11.4713 2.45604 11.045C3.8221 9.12944 7.31078 5 12 5C16.6892 5 20.1779 9.12944 21.544 11.045Z" stroke="currentColor" stroke-width="1.5" />
                        <path d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z" stroke="currentColor" stroke-width="1.5" />
                     </svg>
                  </button>
               </div>
               <small class="text-danger error-msg-text" *ngIf="userLoginForm.controls.password.touched && userLoginForm.controls.password.errors?.required">
                  Password is required
               </small>
            </div>

            <a class="border-0 py-1 mt-1 text-decoration-none bg-transparent ms-auto d-block shadow-none px-0 forgot-password-button" routerLink="/authentication/forgot-pasword" (click)="forgotPassword()" *ngIf="!isInsidePopup">
               Forgot Password?
            </a>
            <button type="button" class="border-0 py-1 mt-1 text-decoration-none bg-transparent ms-auto d-block shadow-none px-0 forgot-password-button" (click)="navigateToForgotPasswordPage()" *ngIf="isInsidePopup">
               Forgot Password?
            </button>

         </div>

         <div class="alert d-flex align-items-center gap-2 alert-danger br-10 mb-0 p-2" role="alert" *ngIf="errMsg">
            <svg class="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22" color="#dc3545" fill="none">
               <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
               <path d="M11.9998 16H12.0088" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               <path d="M12 13L12 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
           </svg>
           <small class="err-msg">{{ errMsg }}</small>
         </div>

         <button type="submit" class="border-0 d-block login-btn gradient-bg-btn" [disabled]="!userLoginForm.valid || loginLoader">
            <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="loginLoader">
               <span class="visually-hidden">Loading...</span>
            </div>
            <span>{{ loginLoader ? 'Logging in...' : 'Login' }}</span>
         </button>
      </ng-container>

      <div *ngIf="showOtpSection">
         <div class="alert d-flex align-items-start gap-2 alert-danger br-10 my-3 p-2" role="alert"  *ngIf="showInput">
            <svg class="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22" color="#dc3545" fill="none">
               <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" />
               <path d="M11.9998 16H12.0088" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               <path d="M12 13L12 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
           </svg>
           <small class="err-msg">If you do not see the email in a few minutes, check your "Promotions" or “junk/spam mail” folder.</small>
         </div>

         <div>
            <div class="d-flex align-items-stretch gap-2 gap-lg-3">
               <div class="d-flex flex-grow-1 align-items-center input-group-wrapper" [ngClass]="{'invalid': userLoginForm.controls.code.touched && userLoginForm.controls.code.invalid}">
                  <svg class="input-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#A8A8A8" fill="none">
                     <path d="M18.6436 4C18.4955 3.61742 18.2933 3.29898 18.0175 3.02513C16.9851 2 15.3234 2 12 2C8.67661 2 7.01491 2 5.98247 3.02513C5.70666 3.29898 5.50453 3.61742 5.3564 4M5 18C5.08715 19.4194 5.32629 20.3233 5.98247 20.9749C7.01491 22 8.67661 22 12 22C15.3234 22 16.9851 22 18.0175 20.9749C18.6737 20.3233 18.9128 19.4194 19 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                     <path d="M6 10L8 12M8 10L6 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M11 10L13 12M13 10L11 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M16 10L18 12M18 10L16 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M17 7H7C5.11438 7 4.17157 7 3.58579 7.58579C3 8.17157 3 9.11438 3 11C3 12.8856 3 13.8284 3.58579 14.4142C4.17157 15 5.11438 15 7 15H17C18.8856 15 19.8284 15 20.4142 14.4142C21 13.8284 21 12.8856 21 11C21 9.11438 21 8.17157 20.4142 7.58579C19.8284 7 18.8856 7 17 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M12 19V19.01" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <input
                     type="text"
                     autocomplete="off"
                     mask="0  0  0  0"
                     id="verificationPin"
                     formControlName="code"
                     name="verificationPin"
                     class="input-field fw-bold"
                     placeholder=">Enter Verification Pin"
                     [ngClass]="{'readonly':!showInput}">
                  <label for="verificationPin" class="input-label">Enter 4 digit Pin</label>
               </div>
               <button type="button" [disabled]="showInput" class="flex-grow-1 d-block code-btn" (click)="resendMail()">
                  <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="loginLoader">
                     <span class="visually-hidden">Loading...</span>
                  </div>
                  <span *ngIf="loginLoader">Resend Code</span>
               </button>
            </div>
            <small class="text-danger error-msg-text" *ngIf="userLoginForm.controls.code.touched && (userLoginForm.controls.code.errors?.required || userLoginForm.controls.code.errors?.minlength)">
               The OTP must be exactly 4 digits long
            </small>
         </div>

         <div class="request-code" *ngIf="showInput">
            Request a new pin in {{this.seconds}} seconds
         </div>

         <button type="button" class="border-0 w-100 d-block login-btn gradient-bg-btn" [disabled]="!userLoginForm.controls['code'].valid || showOTPLoader" (click)="verifyOTP()">
            <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="showOTPLoader">
               <span class="visually-hidden">Loading...</span>
            </div>
            <span>{{ showOTPLoader ? 'Verifying' : 'Verify' }}</span>
         </button>
      </div>
   </form>

   <div class="d-flex align-items-center justify-content-between mx-auto flex-wrap not-account-para">
      <p class="mb-0" *ngIf="!isInsidePopup">
         Don't have an account? <a class="text-decoration-underline d-inline-block" routerLink='/authentication/registration'>Sign up</a>
      </p>
      <p class="mb-0" *ngIf="isInsidePopup">
         Don't have an account? <button type="button" class="border-0 bg-transparent text-decoration-underline d-inline-block" (click)="navigateToSignUpPage()">Sign up</button>
      </p>
   </div>

   <div class="social-login-btns-wrapper" *ngIf="!showOtpSection" >
      <app-google-signin
         [isGoogleBtnInsidePopup]="isInsidePopup"
         [tapEvent]="analyticsUtils.LoginScreen_ContinueGoogle_Tapped_EVENT"
         [completedEvent]="analyticsUtils.LoginScreen_ContinueGoogle_Completed_EVENT"
         [failEvent]="analyticsUtils.LoginScreen_ContinueGoogle_Failed_EVENT">
      </app-google-signin>

      <app-apple-login
         [isAppleBtnInsidePopup]="isInsidePopup"
         [tapEvent]="analyticsUtils.LoginScreen_ContinueApple_Tapped_EVENT"
         [completedEvent]="analyticsUtils.LoginScreen_ContinueApple_Completed_EVENT"
         [failEvent]="analyticsUtils.LoginScreen_ContinueApple_Failed_EVENT">
      </app-apple-login>
   </div>
   <div class="disclaimer-text">
      By signing up, you agree to Musaffa's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>
   </div>
</div>
