import { Filter, FilterGroup, FilterCategory } from "../models/filter-data.model";

export const filterCategoryConfig: FilterCategory[] = [
   {
      name: 'musaffa_filtering',
      label: 'Musaffa Filtering',
      showMore: false,
      hideUnlock: false,
      hidePlanIcon: false,
      showPremiumIcon: false
   },
   {
      name: 'filters',
      label: 'Filters',
      showMore: true,
      hideUnlock: true,
      hidePlanIcon: true,
      showPremiumIcon: true
   },
];

export const filterGroupsConfig: FilterGroup[] = [
   {
      name: 'musaffa_all',
      label: 'All',
      category: ['musaffa_filtering'],
   },
   {
      name: 'compliance',
      label: 'Compliance',
      category: ['musaffa_filtering'],
   },
   {
      name: 'financial_screening',
      label: 'Financial Screening',
      category: ['musaffa_filtering'],
   },
   // {
   //    name: 'purification_metrics',
   //    label: 'Purification Metrics',
   //    category: ['musaffa_filtering'],
   // },
   // {
   //    name: 'esg_metrics',
   //    label: 'ESG Metrics',
   //    category: ['musaffa_filtering'],
   // },
   {
      name: 'filters_all',
      label: 'All',
      category: ['filters'],
   },
   {
      name: 'popular',
      label: 'Popular',
      category: ['filters'],
   },
   {
      name: 'general_information',
      label: 'General Information',
      category: ['filters'],
   },
   {
      name: 'price_volume_trading_metrics',
      label: 'Price, Volume & Trading Metrics',
      category: ['filters'],
   },
   {
      name: 'valuation_metrics',
      label: 'Valuation Metrics',
      category: ['filters'],
   },
   {
      name: 'financial_performance',
      label: 'Financial Performance',
      category: ['filters'],
   },
   {
      name: 'financial_ratios',
      label: 'Financial Ratios',
      category: ['filters'],
   },
   {
      name: 'growth_metrics',
      label: 'Growth Metrics',
      category: ['filters'],
   },
   {
      name: 'dividend',
      label: 'Dividends',
      category: ['filters'],
   },
];

export const filtersConfig: Filter[] = [
   // Compliance Group Start
   {
      name: 'sharia_compliance',
      group: ['musaffa_all', 'compliance'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown',
      multiple: true,
      label: 'Shariah Compliance',
      role: 'starter',
      cols: 1,
      placeholder: 'Select Compliance Status',
      options: [
         {
            value: 'COMPLIANT',
            label: 'Halal',
         },
         {
            value: 'QUESTIONABLE',
            label: 'Doubtful',
         },
         {
            value: 'NON_COMPLIANT',
            label: 'Not halal',
         },
      ],
   },
   {
      name: 'ranking_v2',
      group: ['musaffa_all', 'compliance'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown',
      multiple: true,
      label: 'Halal Rating',
      role: 'starter',
      cols: 1,
      placeholder: 'Select Rating',
      conditions: [
         {
            field: 'sharia_compliance',
            value: ['COMPLIANT'],
         },
      ],
      options: [
         { value: "9", label: "A+" },
         { value: "8", label: "A" },
         { value: "7", label: "A-" },
         { value: "6", label: "B+" },
         { value: "5", label: "B" },
         { value: "4", label: "B-" },
         { value: "3", label: "C+" },
         { value: "2", label: "C" },
         { value: "1", label: "C-" },
       ]
   },
   // Financial Screening Group Start
   {
      name: 'halal_revenue_percent',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Halal Revenue %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '99',
               label: '99%',
            },
            {
               value: '98',
               label: '98%',
            },
            {
               value: '97',
               label: '97%',
            },
            {
               value: '96',
               label: '96%',
            },
            {
               value: '95.01',
               label: '95.01%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '10',
               label: '10%',
            },
         ],
         from: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '99',
               label: '99%',
            },
            {
               value: '98',
               label: '98%',
            },
            {
               value: '97',
               label: '97%',
            },
            {
               value: '96',
               label: '96%',
            },
            {
               value: '95.01',
               label: '95.01%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '10',
               label: '10%',
            },
         ],
      },
   },
   {
      name: 'nothalal_revenue_percent',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Not Halal Revenue %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
         from: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
      },
   },
   {
      name: 'doubtful_revenue_percent',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Doubtful Revenue %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
         from: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
      },
   },
   {
      name: 'intrestbearing_asset_percent',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Interest-bearing Assets %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
         from: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
      },
   },
   {
      name: 'interestbearing_debt_percent',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Interest-bearing Debt %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
         from: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
      },
   },

   // General Information Group Start
   {
      name: 'exchange',
      group: ['filters_all', 'general_information'],
      type: 'dropdown',
      multiple: true,
      label: 'Exchange',
      role: 'free',
      cols: 1,
      placeholder: 'Select Exchange',
      optionsFunction: 'getExchangeList',
      optionsFunctionParams: ['country'],
   },
   {
      name: 'sector',
      group: ['filters_all', 'popular', 'general_information'],
      type: 'dropdown',
      multiple: true,
      label: 'Sector',
      description: 'The broadly category of the economy that the companies operate in.',
      role: 'free',
      cols: 1,
      placeholder: 'Select Sector',
      optionsFunction: 'getSectorList',
      optionsFunctionParams: [],
   },
   {
      name: 'recommendationWeightedAverage',
      group: ['filters_all', 'popular', 'general_information'],
      type: 'dropdown',
      multiple: true,
      label: 'Analyst Rating',
      role: 'free',
      cols: 1,
      placeholder: 'Select Analyst Rating',
      description: 'The average analyst rating for the company.',
      options: [
         { value: 'Strong buy', label: 'Strong Buy' },
         { value: 'Buy', label: 'Buy' },
         { value: 'Hold', label: 'Hold' },
         { value: 'Sell', label: 'Sell' },
         { value: 'Strong sell', label: 'Strong Sell' },
      ],
   },
   // {
   //    name: 'industry',
   //    group: ['general_information'],
   //    type: 'dropdown',
   //    description: 'A more specific classification within a sector that defines the companies line of business.',
   //    multiple: true,
   //    label: 'Industries',
   //    role: 'free',
   //    cols: 1,
   //    placeholder: 'Select Industries',
   //    optionsFunction: 'getIndustryList',
   //    optionsFunctionParams: ['filters[musaffaSector]'],
   //    conditions: [
   //       {
   //          field: 'musaffaSector'
   //       },
   //    ],
   // },
   // {
   //    // WIP
   //    name: 'ipoDate',
   //    group: ['general_information'],
   //    type: 'dropdown',
   //    multiple: true,
   //    description: 'The date when the companies first offered their shares to the public through an Initial Public Offering (IPO).'
   //    label: 'IPO Date',
   //    role: 'premium',
   //    cols: 1,
   //    placeholder: 'Select',
   //    options: [
   //       { value: '*:1D', label: '1 Day' },
   //       { value: '*:1W', label: '1 Week' },
   //       { value: '*:1M', label: '1 Month' },
   //       { value: '*:3M', label: '3 Months' },
   //       { value: '*:6M', label: '6 Months' },
   //       { value: '*:1Y', label: '1 Year' },
   //       { value: '*:3Y', label: '3 Years' },
   //       { value: '*:5Y', label: '5 Years' },
   //       { value: '5Y:*', label: '5+ Years' },
   //    ]
   // },
   //---------------------------------new--JSON---Created-----------06/01/2025------------------//
   // {
   //    name: 'country',
   //    label: 'Country (Headquarter)',
   // },
   {
      name: 'currentPrice',
      label: 'Stock Price',
      description: 'Current trading price of the stock.',
      group: ['filters_all', 'popular', 'general_information'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      }
   },
   {
      name: 'employeeTotal',
      label: '# of Employees',
      group: ['filters_all', 'general_information'],
      type: 'dropdown_range',
      description: 'The total count of individuals employed by the companies. ',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0', label: '-' },
            { value: '100', label: '100' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
            { value: '100000', label: '100,000' },
            { value: '500000', label: '500,000' }
         ],
         from: [
            { value: '0', label: '-' },
            { value: '100', label: '100' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
            { value: '100000', label: '100,000' },
            { value: '500000', label: '500,000' }
         ]
      }
   },
   {
      name: 'marketCapClassification',
      label: 'Market Cap Classification',
      group: ['filters_all', 'popular', 'general_information'],
      type: 'dropdown',
      multiple: true,
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      options: [
         { value: 'MEGA_CAP', label: 'Mega Cap (Over $200B)' },
         { value: 'LARGE_CAP', label: 'Large Cap ($10B - $200B)' },
         { value: 'MID_CAP', label: 'Mid Cap ($2B - $10B)' },
         { value: 'SMALL_CAP', label: 'Small Cap (Up to $2B)' }
      ],
   },
   {
      name: '52WeekLow',
      label: '52-Week Low',
      description: 'The lowest stock price over the past 52 weeks.',
      group: ['filters_all', 'popular', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      },
   },
   {
      name: '52WeekHigh',
      label: '52-Week High',
      group: ['filters_all', 'popular', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: 'The highest stock price over the past 52 weeks.',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      },
   },
   {
      name: 'priceChange1D',
      label: 'Price Change',
      group: ['filters_all', 'popular', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "The change in a stock's price compared to its closing price on the previous trading day. This filter highlights the stock's daily price movement, helping investors assess short-term market sentiment and volatility.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'amount',
      rangeOptions: {
         to: [
            { value: '-100', label: '-100' },
            { value: '-90', label: '-90' },
            { value: '-80', label: '-80' },
            { value: '-70', label: '-70' },
            { value: '-60', label: '-60' },
            { value: '-50', label: '-50' },
            { value: '-40', label: '-40' },
            { value: '-30', label: '-30' },
            { value: '-20', label: '-20' },
            { value: '-10', label: '-10' },
            { value: '-5', label: '-5' },
            { value: '0', label: '0' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '60', label: '60' },
            { value: '70', label: '70' },
            { value: '80', label: '80' },
            { value: '90', label: '90' },
            { value: '100', label: '100' }
         ],
         from: [
            { value: '-100', label: '-100' },
            { value: '-90', label: '-90' },
            { value: '-80', label: '-80' },
            { value: '-70', label: '-70' },
            { value: '-60', label: '-60' },
            { value: '-50', label: '-50' },
            { value: '-40', label: '-40' },
            { value: '-30', label: '-30' },
            { value: '-20', label: '-20' },
            { value: '-10', label: '-10' },
            { value: '-5', label: '-5' },
            { value: '0', label: '0' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '60', label: '60' },
            { value: '70', label: '70' },
            { value: '80', label: '80' },
            { value: '90', label: '90' },
            { value: '100', label: '100' }
         ]
      },
   },
   {
      name: 'priceChange1DPercent',
      label: 'Price Change (%)',
      group: ['filters_all', 'popular', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "The percentage change in a stock's price compared to its closing price on the previous trading day. This filter highlights the stock's daily price movement, helping investors assess short-term market sentiment and volatility.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-100', label: '-100%' },
            { value: '-50', label: '-50%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '50', label: '50%' },
            { value: '100', label: '100%' }
         ],
         from: [
            { value: '-100', label: '-100%' },
            { value: '-50', label: '-50%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '50', label: '50%' },
            { value: '100', label: '100%' }
         ]
      },
   },
   {
      name: 'priceChange1MPercent',
      label: 'Price Change 1M (%)',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "The percentage change in a stock's price over the past month.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-100', label: '-100%' },
            { value: '-50', label: '-50%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '50', label: '50%' },
            { value: '100', label: '100%' }
         ],
         from: [
            { value: '-100', label: '-100%' },
            { value: '-50', label: '-50%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '50', label: '50%' },
            { value: '100', label: '100%' }
         ]
      },
   },
   {
      name: 'priceChange1YPercent',
      label: 'Price Change 1Y (%)',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "The percentage change in a stock's price over the past year, calculated using the closing price from the same date one year ago. This filter evaluates the stock’s long-term price performance and growth.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' }
         ],
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' }
         ]
      },
   },
   {
      name: 'priceChange3YPercent',
      label: 'Price Change 3Y (%)',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "The percentage change in a stock's price over the past three years, calculated using the closing price from three years ago. It helps investors analyze the stock’s medium- to long-term growth and market trends.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' }
         ],
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' }
         ]
      },
   },
   {
      name: 'priceChange5YPercent',
      label: 'Price Change 5Y (%)',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "The percentage change in a stock's price over the past five years, based on the closing price from five years ago. This metric is useful for understanding the stock’s performance over a long-term investment horizon.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' }
         ],
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '0%' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' }
         ]
      },
   },
   {
      name: 'volume',
      label: 'Volume',
      group: ['filters_all', 'popular', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "Volume represents the total number of shares traded for a given stock during a specific period (e.g., daily, weekly, or monthly). It reflects the level of trading activity and liquidity in the stock, with higher volumes generally indicating greater investor interest and easier execution of buy and sell orders.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1000', label: '1K' },
            { value: '10000', label: '10K' },
            { value: '100000', label: '100K' },
            { value: '1000000', label: '1M' },
            { value: '10000000', label: '10M' },
            { value: '100000000', label: '100M' }
         ],
         from: [
            { value: '1000', label: '1K' },
            { value: '10000', label: '10K' },
            { value: '100000', label: '100K' },
            { value: '1000000', label: '1M' },
            { value: '10000000', label: '10M' },
            { value: '100000000', label: '100M' }
         ]
      },
   },
   {
      name: 'beta',
      label: 'Beta 1Y',
      group: ['filters_all', 'popular', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "Beta (1Y) measures a stock’s volatility in relation to the overall market over the past year. A Beta of 1 indicates that the stock’s price moves in line with the market, while a Beta greater than 1 suggests that the stock is more volatile than the market. A Beta less than 1 indicates lower volatility compared to the market.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-1', label: '-1' },
            { value: '-0.8', label: '-0.8' },
            { value: '-0.5', label: '-0.5' },
            { value: '0', label: '0' },
            { value: '0.5', label: '0.5' },
            { value: '0.8', label: '0.8' },
            { value: '1', label: '1' },
            { value: '1.3', label: '1.3' },
            { value: '1.5', label: '1.5' },
            { value: '1.8', label: '1.8' },
            { value: '2', label: '2' }
         ],
         from: [
            { value: '-1', label: '-1' },
            { value: '-0.8', label: '-0.8' },
            { value: '-0.5', label: '-0.5' },
            { value: '0', label: '0' },
            { value: '0.5', label: '0.5' },
            { value: '0.8', label: '0.8' },
            { value: '1', label: '1' },
            { value: '1.3', label: '1.3' },
            { value: '1.5', label: '1.5' },
            { value: '1.8', label: '1.8' },
            { value: '2', label: '2' }
         ]
      },
   },
   {
      name: 'open',
      label: 'Open Price',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      description: 'Stock price at market opening of the current trading day. If the market is closed, it is stock price at market opening of the latest trading day.',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      },
   },
   // API KEY NOT IN SHEET
   {
      name: 'close',
      label: 'Previous Close',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: 'Stock price at the previous trading day’s close.',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      },
   },
   {
      name: 'low',
      label: 'Low Price',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      description: 'Lowest price of the stock during the current trading day. If the market is closed, it is the lowest price of the stock during the latest trading day.',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      },
   },
   {
      name: 'high',
      label: 'High Price',
      group: ['filters_all', 'price_volume_trading_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: 'Highest price of the stock during the current trading day. If the market is closed, it is the highest price of the stock during the latest trading day.',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ],
         from: [
            // { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' }
         ]
      },
   },
   // NOT IN SHEET
   {
      name: 'marketcap',
      label: 'Market Cap',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      valueType: 'marketcap',
      rangeOptions: {
         to: [
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '500', label: '500M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' },
            { value: '200000', label: '200B' },
            { value: '500000', label: '500B' },
            { value: '1000000', label: '1T' }
         ],
         from: [
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '500', label: '500M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' },
            { value: '200000', label: '200B' },
            { value: '500000', label: '500B' },
            { value: '1000000', label: '1T' }
         ]
      },
   },
   {
      name: 'peAnnual',
      label: 'P/E Ratio',
      group: ['filters_all', 'popular', 'valuation_metrics'],
      type: 'dropdown_range',
      description: "A valuation ratio that compares a stock's market price to its earnings per share. It reflects how much investors are willing to pay for each dollar of earnings. Lower P/E suggests undervaluation, while higher P/E indicates growth expectations or overvaluation.",
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   // {
   //    name: 'priceTargetMedian',
   //    label: 'Target Price',
   //    group: ['filters_all', 'popular', 'valuation_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: 'The Analysts\' median 12-month projected stock price.',
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '1', label: '1' },
   //          { value: '5', label: '5' },
   //          { value: '10', label: '10' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' },
   //          { value: '100', label: '100' },
   //          { value: '200', label: '200' },
   //          { value: '300', label: '300' },
   //          { value: '400', label: '400' },
   //          { value: '500', label: '500' },
   //          { value: '1000', label: '1,000' },
   //          { value: '2000', label: '2,000' },
   //          { value: '3000', label: '3,000' },
   //          { value: '4000', label: '4,000' },
   //          { value: '5000', label: '5,000' },
   //          { value: '10000', label: '10,000' },
   //          { value: '50000', label: '50,000' }
   //       ],
   //       from: [
   //          { value: '1', label: '1' },
   //          { value: '5', label: '5' },
   //          { value: '10', label: '10' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' },
   //          { value: '100', label: '100' },
   //          { value: '200', label: '200' },
   //          { value: '300', label: '300' },
   //          { value: '400', label: '400' },
   //          { value: '500', label: '500' },
   //          { value: '1000', label: '1,000' },
   //          { value: '2000', label: '2,000' },
   //          { value: '3000', label: '3,000' },
   //          { value: '4000', label: '4,000' },
   //          { value: '5000', label: '5,000' },
   //          { value: '10000', label: '10,000' },
   //          { value: '50000', label: '50,000' }
   //       ]
   //    },
   // },
   {
      name: 'enterpriseValue',
      label: 'Enterprise Value',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "Enterprise Value represents the total value of a company, including market capitalization, debt, and excluding cash. It is often considered a more comprehensive measure of a company’s value than market cap alone, as it accounts for the company’s overall financial structure.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'marketcap',
      rangeOptions: {
         to: [
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '500', label: '500M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' },
            { value: '200000', label: '200B' },
            { value: '500000', label: '500B' },
            { value: '1000000', label: '1T' }
         ],
         from: [
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '500', label: '500M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' },
            { value: '200000', label: '200B' },
            { value: '500000', label: '500B' },
            { value: '1000000', label: '1T' }
         ]
      },
   },
   {
      name: 'pbAnnual',
      label: 'P/B Ratio',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      description: "A valuation ratio that compares a stock's market price to its annual book value per share (annual). It shows how much investors are paying for each dollar of the company’s net assets. A lower P/B ratio may indicate undervaluation, while a higher ratio suggests potential overvaluation.",
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   // {
   //    name: 'pb_ratio_quarterly',
   //    label: 'P/B Ratio - Quarterly',
   //    group: ['filters_all', 'valuation_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "A valuation ratio that compares a stock's market price to its quarterly book value per share (annual). It shows how much investors are paying for each dollar of the company’s net assets. A lower P/B ratio may indicate undervaluation, while a higher ratio suggests potential overvaluation.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '4', label: '4' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '25', label: '25' },
   //          { value: '30', label: '30' },
   //          { value: '35', label: '35' },
   //          { value: '40', label: '40' },
   //          { value: '45', label: '45' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '4', label: '4' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '25', label: '25' },
   //          { value: '30', label: '30' },
   //          { value: '35', label: '35' },
   //          { value: '40', label: '40' },
   //          { value: '45', label: '45' },
   //          { value: '50', label: '50' }
   //       ]
   //    },
   // },
   {
      name: 'bookValuePerShareAnnual',
      label: 'Book Value Per Share',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-10', label: '-10' },
            { value: '-9', label: '-9' },
            { value: '-8', label: '-8' },
            { value: '-7', label: '-7' },
            { value: '-6', label: '-6' },
            { value: '-5', label: '-5' },
            { value: '-4', label: '-4' },
            { value: '-3', label: '-3' },
            { value: '-2', label: '-2' },
            { value: '-1', label: '-1' },
            { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ],
         from: [
            { value: '-10', label: '-10' },
            { value: '-9', label: '-9' },
            { value: '-8', label: '-8' },
            { value: '-7', label: '-7' },
            { value: '-6', label: '-6' },
            { value: '-5', label: '-5' },
            { value: '-4', label: '-4' },
            { value: '-3', label: '-3' },
            { value: '-2', label: '-2' },
            { value: '-1', label: '-1' },
            { value: '', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ]
      },
   },
   {
      name: 'psAnnual',
      label: 'P/S Ratio',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "A valuation metric that compares a stock’s price to its revenue per share (annual). It indicates how much investors are willing to pay for each dollar of the company’s sales. Lower ratios may suggest undervaluation, while higher ratios may indicate growth expectations.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   {
      name: 'price_tangiblebook_value_annual',
      label: 'Price to Tangible Book Value',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "Ratio of stock price relative to its annual tangible book value. It is used to assess whether a stock is undervalued or overvalued relative to its tangible assets. ",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   // {
   //    name: 'price_tangiblebook_value_quarterly',
   //    label: 'Price to Tangible Book Value - Quarterly',
   //    group: ['filters_all', 'valuation_metrics'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "Ratio of stock price relative to its quarterly tangible book value. It is used to assess whether a stock is undervalued or overvalued relative to its tangible assets. ",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '4', label: '4' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '25', label: '25' },
   //          { value: '30', label: '30' },
   //          { value: '35', label: '35' },
   //          { value: '40', label: '40' },
   //          { value: '45', label: '45' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '4', label: '4' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '25', label: '25' },
   //          { value: '30', label: '30' },
   //          { value: '35', label: '35' },
   //          { value: '40', label: '40' },
   //          { value: '45', label: '45' },
   //          { value: '50', label: '50' }
   //       ]
   //    },
   // },
   // API KEY NOT IN SHEET
   {
      name: 'pfcfShareAnnual',
      label: 'Price to Free Cash Flow',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   // API KEY NOT IN SHEET
   {
      name: 'ev_ebit',
      label: 'EV/EBIT',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "A valuation ratio comparing the company’s enterprise value (EV) to its Earnings before interest and taxes (annual). It assesses the company’s value relative to its operational profitability",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50' },
            { value: '-40', label: '-40' },
            { value: '-30', label: '-30' },
            { value: '-20', label: '-20' },
            { value: '-10', label: '-10' },
            { value: '-5', label: '-5' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '-50', label: '-50' },
            { value: '-40', label: '-40' },
            { value: '-30', label: '-30' },
            { value: '-20', label: '-20' },
            { value: '-10', label: '-10' },
            { value: '-5', label: '-5' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   // API KEY NOT IN SHEET
   // {
   //    name: 'ebitda_annual',
   //    label: 'EV/EBITDA',
   //    group: ['filters_all', 'popular', 'valuation_metrics'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "A valuation ratio comparing the company's enterprise value (EV) to its Earnings before interest, taxes, depreciation, and amortization. It helps in comparing the oprational profitability of companies with different capital structures",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50' },
   //          { value: '-40', label: '-40' },
   //          { value: '-30', label: '-30' },
   //          { value: '-20', label: '-20' },
   //          { value: '-10', label: '-10' },
   //          { value: '-5', label: '-5' },
   //          { value: '5', label: '5' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '25', label: '25' },
   //          { value: '30', label: '30' },
   //          { value: '35', label: '35' },
   //          { value: '40', label: '40' },
   //          { value: '45', label: '45' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50' },
   //          { value: '-40', label: '-40' },
   //          { value: '-30', label: '-30' },
   //          { value: '-20', label: '-20' },
   //          { value: '-10', label: '-10' },
   //          { value: '-5', label: '-5' },
   //          { value: '5', label: '5' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '25', label: '25' },
   //          { value: '30', label: '30' },
   //          { value: '35', label: '35' },
   //          { value: '40', label: '40' },
   //          { value: '45', label: '45' },
   //          { value: '50', label: '50' }
   //       ]
   //    },
   // },
   {
      name: 'currentEv_freeCashFlowAnnual',
      label: 'EV/FCF',
      group: ['filters_all', 'valuation_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "A valuation metric comparing the enterprise value (EV) of a company to its free cash flow. It shows how efficiently the company generates csh flow relative to its market valuation. A lower ratio signals undervaluation. ",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50' },
            { value: '-40', label: '-40' },
            { value: '-30', label: '-30' },
            { value: '-20', label: '-20' },
            { value: '-10', label: '-10' },
            { value: '-5', label: '-5' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '-50', label: '-50' },
            { value: '-40', label: '-40' },
            { value: '-30', label: '-30' },
            { value: '-20', label: '-20' },
            { value: '-10', label: '-10' },
            { value: '-5', label: '-5' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '35', label: '35' },
            { value: '40', label: '40' },
            { value: '45', label: '45' },
            { value: '50', label: '50' }
         ]
      },
   },
   {
      name: 'epsAnnual',
      label: 'EPS',
      group: ['filters_all', 'popular', 'financial_performance'],
      type: 'dropdown_range',
      role: 'free',
      description: "The portion of a company’s annual profit allocated to each outstanding share of stock. It is calculated by dividing the annual net income by the weighted average of outstanding shares and indicates shareholder earnings on a yearly basis.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-10', label: '-10' },
            { value: '-9', label: '-9' },
            { value: '-8', label: '-8' },
            { value: '-7', label: '-7' },
            { value: '-6', label: '-6' },
            { value: '-5', label: '-5' },
            { value: '-4', label: '-4' },
            { value: '-3', label: '-3' },
            { value: '-2', label: '-2' },
            { value: '-1', label: '-1' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ],
         from: [
            { value: '-10', label: '-10' },
            { value: '-9', label: '-9' },
            { value: '-8', label: '-8' },
            { value: '-7', label: '-7' },
            { value: '-6', label: '-6' },
            { value: '-5', label: '-5' },
            { value: '-4', label: '-4' },
            { value: '-3', label: '-3' },
            { value: '-2', label: '-2' },
            { value: '-1', label: '-1' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ]
      },
   },
   {
      name: 'revenue_annual',
      label: 'Revenue',
      group: ['filters_all', 'financial_performance'],
      type: 'dropdown_range',
      role: 'free',
      description: "The total income generated by a company from its business activities over the course of a fiscal year. This metric reflects the company’s overall sales performance and market reach on an annual basis.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'marketcap',
      rangeOptions: {
         to: [
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ],
         from: [
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ]
      }
   },
   // {
   //    name: 'revenue_quarterly',
   //    label: 'Revenue - Quarterly',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The total income generated by a company from its business activities during a fiscal quarter. It provides insights into the company’s short-term sales trends and seasonal performance.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'marketcap',
   //    rangeOptions: {
   //       to: [
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ],
   //       from: [
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ]
   //    }
   // },
   {
      name: 'grossIncome_annual',
      label: 'Gross Income',
      group: ['filters_all', 'financial_performance'],
      type: 'dropdown_range',
      role: 'free',
      description: "The total revenue earned by a company in a fiscal year after deducting the cost of goods sold (COGS). This metric assesses the company’s ability to manage production and pricing effectively over the long term.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'marketcap',
      rangeOptions: {
         to: [
            { value: '-1000', label: '-1B' },
            { value: '-100', label: '-100M' },
            { value: '-50', label: '-50M' },
            { value: '-10', label: '-10M' },
            { value: '-1', label: '-1M' },
            { value: '0', label: '-' },
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ],
         from: [
            { value: '-1000', label: '-1B' },
            { value: '-100', label: '-100M' },
            { value: '-50', label: '-50M' },
            { value: '-10', label: '-10M' },
            { value: '-1', label: '-1M' },
            { value: '0', label: '-' },
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ]
      }
   },
   // {
   //    name: 'grossIncome_quarterly',
   //    label: 'Gross Income - Quarterly',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The total revenue earned by a company during a fiscal quarter after deducting COGS. It highlights short-term efficiency in managing production costs and sales.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'marketcap',
   //    rangeOptions: {
   //       to: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ],
   //       from: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ]
   //    }
   // },
   // {
   //    name: 'ebitda_quarterly',
   //    label: 'EBITDA - Quarterly',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The company’s earnings during a fiscal quarter before subtracting interest, taxes, depreciation, and amortization. It measures short-term operational profitability while excluding non-operational factors.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'marketcap',
   //    rangeOptions: {
   //       to: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ],
   //       from: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ]
   //    }
   // },
   // {
   //    name: 'ebit_quarterly',
   //    label: 'EBIT - Quarterly',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The company’s earnings during a fiscal quarter before subtracting interest, taxes, depreciation, and amortization. It measures short-term operational profitability while excluding non-operational factors.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'marketcap',
   //    rangeOptions: {
   //       to: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ],
   //       from: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ]
   //    }
   // },
   {
      name: 'net_income_annual',
      label: 'Net Income',
      group: ['filters_all', 'financial_performance'],
      type: 'dropdown_range',
      role: 'free',
      description: "The company’s total profit after deducting all expenses, taxes, and costs from revenue over a fiscal year. It measures the company's overall profitability and financial health annually.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'marketcap',
      rangeOptions: {
         to: [
            { value: '-1000', label: '-1B' },
            { value: '-100', label: '-100M' },
            { value: '-50', label: '-50M' },
            { value: '-10', label: '-10M' },
            { value: '-1', label: '-1M' },
            { value: '0', label: '-' },
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ],
         from: [
            { value: '-1000', label: '-1B' },
            { value: '-100', label: '-100M' },
            { value: '-50', label: '-50M' },
            { value: '-10', label: '-10M' },
            { value: '-1', label: '-1M' },
            { value: '0', label: '-' },
            { value: '1', label: '1M' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '1000', label: '1B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ]
      }
   },
   // {
   //    name: 'net_income_quarterly',
   //    label: 'Net Income - Quarterly',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The company’s total profit after deducting all expenses, taxes, and costs from revenue during a fiscal quarter. It helps analyze short-term profitability and operational efficiency.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'marketcap',
   //    rangeOptions: {
   //       to: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ],
   //       from: [
   //          { value: '-1000', label: '-1B' },
   //          { value: '-100', label: '-100M' },
   //          { value: '-50', label: '-50M' },
   //          { value: '-10', label: '-10M' },
   //          { value: '-1', label: '-1M' },
   //          { value: '0', label: '-' },
   //          { value: '1', label: '1M' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '1000', label: '1B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' },
   //          { value: '100000', label: '100B' }
   //       ]
   //    }
   // },
   {
      name: 'gross_margin_annual',
      label: 'Gross Margin',
      group: ['filters_all', 'popular', 'financial_performance'],
      type: 'dropdown_range',
      role: 'premium',
      description: "The percentage of revenue remaining after deducting the cost of goods sold (COGS). It measures the company’s efficiency in producing goods or services relative to its revenue. A higher gross margin indicates better cost management in core operations.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ]
      }
   },
   // {
   //    name: 'ebitda_margin',
   //    label: 'EBITDA Margin',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "The percentage of revenue remaining after excluding interest, taxes, depreciation, and amortization expenses. It assesses a company’s operational profitability without the influence of financing or non-cash accounting items, making it a common measure for cross-industry comparison.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ]
   //    }
   // },
   // {
   //    name: 'operatingMargin',
   //    label: 'Operating Margin',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "The percentage of revenue remaining after covering all operating expenses, excluding interest and taxes. It evaluates the profitability of a company’s core operations, independent of financing and tax impacts.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ]
   //    }
   // },
   // {
   //    name: 'preTaxMargin',
   //    label: 'Pretax Margin',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The percentage of revenue remaining after accounting for all expenses, including interest, but before taxes. It provides a view of profitability before tax strategies are considered, focusing on operational and financial efficiency.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ]
   //    }
   // },
   {
      name: 'netProfitMarginAnnual',
      label: 'Net Profit Margin',
      group: ['filters_all', 'financial_performance'],
      type: 'dropdown_range',
      role: 'premium',
      description: "The percentage of revenue that remains as net income after all expenses, taxes, and costs have been deducted. It reflects the company’s overall profitability and ability to turn revenue into profit.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ]
      }
   },
   {
      name: 'currentEv_freeCashFlowAnnual',
      label: 'Free Cash Flow Margin ',
      group: ['filters_all', 'financial_performance'],
      type: 'dropdown_range',
      role: 'free',
      description: "The percentage of a company’s annual revenue converted into free cash flow after accounting for capital expenditures. It indicates the company’s ability to generate cash for debt repayment, dividends, or reinvestment over a fiscal year.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ]
      }
   },
   // {
   //    name: 'free_cashflow_margin_quarterly',
   //    label: 'Free Cash Flow Margin - Quarterly ',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The percentage of a company’s quarterly revenue that becomes free cash flow after deducting capital expenditures. It provides insight into the company’s short-term cash generation and operational efficiency.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ]
   //    }
   // },
   // API KEY NOT IN SHEET
   // {
   //    name: 'roa_quarterly',
   //    label: 'ROA - Quarterly',
   //    group: ['filters_all', 'financial_performance'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "ROA (Quarterly) represents the company’s ability to generate profit from its total assets on a quarterly basis. It evaluates the short-term efficiency of asset utilization in creating returns.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '0', label: '-' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' }
   //       ]
   //    }
   // },
   {
      name: 'roa5Y',
      label: 'ROA 5Y',
      group: ['filters_all', 'financial_performance'],
      type: 'dropdown_range',
      role: 'premium',
      description: "ROA (5Y) calculates the average annual return on assets over a 5-year period. It provides insight into the company's long-term efficiency and asset management performance.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ]
      }
   },
   {
      name: 'roiAnnual',
      label: 'ROI',
      group: ['filters_all', 'popular', 'financial_performance'],
      type: 'dropdown_range',
      role: 'free',
      description: "ROI represents the profitability of an investment relative to its cost. It measures the financial gains or losses as a percentage of the original investment, offering insights into the investment’s efficiency.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '0', label: '-' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' }
         ]
      }
   },
   // {
   //    name: 'currentRatioQuarterly',
   //    label: 'Current Ratio - Quarterly',
   //    group: ['filters_all', 'financial_ratios'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "Current Ratio (Quarterly) assesses the company's short-term liquidity by comparing its current assets to current liabilities on a quarterly basis. It highlights the firm’s ability to cover immediate financial obligations.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ]
   //    }
   // },
   {
      name: 'currentRatioAnnual',
      label: 'Current Ratio',
      group: ['filters_all', 'popular', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'premium',
      description: "Current Ratio (Annual) measures the company’s ability to meet its short-term obligations using its current assets over a fiscal year. A higher ratio indicates better liquidity and financial health on an annual basis.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ]
      },
   },
   {
      name: 'quickRatioAnnual',
      label: 'Quick Ratio',
      group: ['filters_all', 'popular', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'free',
      description: "Quick Ratio (Annual) evaluates the company’s ability to meet its short-term liabilities using highly liquid assets, excluding inventory, over a fiscal year. It provides a stringent measure of liquidity on an annual scale.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ]
      },
   },
   {
      name: 'totalDebt_totalEquityAnnual',
      label: 'Debt to Equity',
      group: ['filters_all', 'popular', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'free',
      description: "Debt to Equity Ratio (Annual) indicates the company’s leverage by comparing its total debt to shareholder equity over a fiscal year. It shows how much of the company’s financing is sourced from debt relative to equity.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ]
      },
   },
   // {
   //    name: 'quickRatioQuarterly',
   //    label: 'Quick Ratio - Quarterly',
   //    group: ['filters_all', 'financial_ratios'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "Quick Ratio (Quarterly) calculates the company’s liquidity by focusing on quick assets (cash, marketable securities, and receivables) compared to liabilities for a given quarter. It emphasizes short-term financial stability.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ]
   //    }
   // },
   // {
   //    name: 'totalDebt_totalEquityQuarterly',
   //    label: 'Debt to Equity - Quarterly',
   //    group: ['filters_all', 'financial_ratios'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "Debt to Equity Ratio (Quarterly) assesses the company's debt-to-equity structure over a quarter. It reflects the short-term leverage position and the balance between debt financing and equity capital.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ]
   //    }
   // },
   {
      name: 'equity_to_assets_annual',
      label: 'Equity to Assets',
      group: ['filters_all', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'free',
      description: "Equity to Assets (Annual) measures the proportion of a company’s total assets funded by shareholder equity over a fiscal year. It provides insights into the company’s reliance on equity versus debt financing annually.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0', label: '-' },
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ],
         from: [
            { value: '0', label: '-' },
            { value: '0.5', label: '0.5' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ]
      }
   },
   // {
   //    name: 'equity_to_assets_quarterly',
   //    label: 'Equity to Assets - Quarterly',
   //    group: ['filters_all', 'financial_ratios'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "Equity to Assets (Quarterly) evaluates the ratio of shareholder equity to total assets for a specific quarter. This metric highlights the short-term balance of equity financing within the company’s asset structure.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ]
   //    }
   // },
   // {
   //    name: 'cash_to_debt',
   //    label: 'Cash to Debt',
   //    group: ['filters_all', 'financial_ratios'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "Cash to Debt Ratio compares the company’s cash and cash equivalents to its total debt. This ratio illustrates how much of the debt could be covered immediately using available cash resources, offering a direct view of liquidity relative to debt.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ],
   //       from: [
   //          { value: '0', label: '-' },
   //          { value: '0.5', label: '0.5' },
   //          { value: '1', label: '1' },
   //          { value: '2', label: '2' },
   //          { value: '3', label: '3' },
   //          { value: '5', label: '5' },
   //          { value: '6', label: '6' },
   //          { value: '7', label: '7' },
   //          { value: '8', label: '8' },
   //          { value: '9', label: '9' },
   //          { value: '10', label: '10' },
   //          { value: '15', label: '15' },
   //          { value: '20', label: '20' },
   //          { value: '30', label: '30' },
   //          { value: '40', label: '40' },
   //          { value: '50', label: '50' }
   //       ]
   //    }
   // },
   {
      name: 'assetTurnoverAnnual',
      label: 'Asset Turnover',
      group: ['filters_all', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' }
         ],
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' }
         ]
      }
   },
   {
      name: 'inventoryTurnoverAnnual',
      label: 'Inventory Turnover',
      group: ['filters_all', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' }
         ],
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' }
         ]
      }
   },
   {
      name: 'netInterestCoverageAnnual',
      label: 'Net Interest Coverage',
      group: ['filters_all', 'financial_ratios'],
      type: 'dropdown_range',
      role: 'free',
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-1', label: '-1' },
            { value: '-0.5', label: '-0.5' },
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '10', label: '10' }
         ],
         from: [
            { value: '-1', label: '-1' },
            { value: '-0.5', label: '-0.5' },
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '10', label: '10' }
         ]
      }
   },
   {
      name: 'revenueGrowth3Y',
      label: 'Revenue Growth 3Y',
      group: ['filters_all', 'growth_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "The CAGR of revenue over the past three years. This metric captures medium-term trends in sales growth, reflecting the company’s capacity to generate consistent revenue increases.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ]
      },
   },
   {
      name: 'revenueGrowth5Y',
      label: 'Revenue Growth 5Y',
      group: ['filters_all', 'popular', 'growth_metrics'],
      type: 'dropdown_range',
      role: 'premium',
      description: "The CAGR of revenue over the past five years. It indicates long-term growth in the company’s top-line performance and resilience in the market.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ]
      },
   },
   // NOT IN SHEET
   // {
   //    name: 'ebitda_growth_1y',
   //    label: 'EBITDA Growth 1Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The percentage change in Earnings before interest, taxes, depreciation, and amortization over the past year. This filter evaluates short-term growth in operational profitability, excluding non-cash expenses.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // API KEY NOT IN SHEET
   // {
   //    name: 'ebitda_growth_3y',
   //    label: 'EBITDA Growth 3Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of Earnings before interest, taxes, depreciation, and amortization over the past three years. This metric captures medium-term trends in core earnings growth and operational performance.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // API KEY NOT IN SHEET
   // {
   //    name: 'ebitda_growth_5y',
   //    label: 'EBITDA Growth 5Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of Earnings before interest, taxes, depreciation, and amortization over the past five years. It reflects long-term improvements in profitability and operational success.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   {
      name: 'assets_growth_1y',
      label: 'Asset Growth 1Y',
      group: ['filters_all', 'growth_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "The percentage change in a company’s total assets over the past year, calculated by comparing the current total assets to the previous year’s total assets. This filter evaluates the company’s short-term expansion and investment in resources.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ]
      },
   },
   {
      name: 'assets_growth_3y',
      label: 'Asset Growth 3Y',
      group: ['filters_all', 'growth_metrics'],
      type: 'dropdown_range',
      role: 'free',
      description: "The CAGR of total assets over the past three years. This metric reflects medium-term growth in a company’s asset base and its capacity to support business operations.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ],
         from: [
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-15', label: '-15%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '15', label: '15%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' }
         ]
      },
   },
   // {
   //    name: 'assets_growth_5y',
   //    label: 'Asset Growth 5Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of total assets over the past five years. It highlights the company’s long-term strategy for growth and expansion.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // {
   //    name: 'total_debt_growth_1y',
   //    label: 'Total Debt Growth 1Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The percentage change in total debt over the past year, calculated by comparing the current debt level to that of the previous year. This filter evaluates short-term changes in the company’s reliance on borrowed funds.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // CONFIRM KEY
   // {
   //    name: 'total_debt_growth_3y',
   //    label: 'Total Debt Growth 3Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of total debt over the past three years. This metric reflects medium-term trends in the company’s financial leverage and borrowing practices.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // CONFIRM KEY
   // {
   //    name: 'total_debt_growth_5y',
   //    label: 'Total Debt Growth 5Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of total debt over the past five years. It highlights the company’s long-term debt management and financial stability.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // {
   //    name: 'long_term_debt_growth_1y',
   //    label: 'Long-term Debt Growth 1Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The percentage change in long-term debt over the past year, calculated by comparing the current long-term debt to that of the previous year. This filter evaluates short-term changes in the company’s reliance on long-term borrowing.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // {
   //    name: 'long_term_debt_growth_3y',
   //    label: 'Long-term Debt Growth 3Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of long-term debt over the past three years. This metric reflects medium-term trends in the company’s use of long-term financing for operations and growth.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // {
   //    name: 'long_term_debt_growth_5y',
   //    label: 'Long-term Debt Growth 5Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'free',
   //    description: "The CAGR of long-term debt over the past five years. It highlights the company’s long-term borrowing strategy and its impact on financial sustainability.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   {
      name: 'dividendPerShareTTM',
      label: 'Dividend Per Share',
      group: ['filters_all', 'popular', 'dividend'],
      type: 'dropdown_range',
      role: 'free',
      description: "The total dividend paid by the company to its shareholders for each outstanding share over a specified period, typically a fiscal year. It reflects the company’s commitment to returning profits to its investors.",
      cols: 1,
      placeholder: 'Select',
      rangeOptions: {
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ],
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ]
      }
   },
   // API KEY NOT IN SHEET
   {
      name: 'currentDividendYieldTTM',
      label: 'Dividend Yield',
      group: ['filters_all', 'popular', 'dividend'],
      type: 'dropdown_range',
      role: 'premium',
      description: "Dividend Yield measures the annual dividend payout as a percentage of the company’s current stock price. This ratio highlights the income return investors can expect relative to the stock price, helping assess the attractiveness of a stock for dividend-focused strategies.",
      cols: 1,
      placeholder: 'Select',
      valueType: 'percent',
      rangeOptions: {
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1%' },
            { value: '2', label: '2%' },
            { value: '3', label: '3%' },
            { value: '4', label: '4%' },
            { value: '5', label: '5%' },
            { value: '6', label: '6%' },
            { value: '7', label: '7%' },
            { value: '8', label: '8%' },
            { value: '9', label: '9%' },
            { value: '10', label: '10%' }
         ],
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1%' },
            { value: '2', label: '2%' },
            { value: '3', label: '3%' },
            { value: '4', label: '4%' },
            { value: '5', label: '5%' },
            { value: '6', label: '6%' },
            { value: '7', label: '7%' },
            { value: '8', label: '8%' },
            { value: '9', label: '9%' },
            { value: '10', label: '10%' }
         ]
      }
   },
   // {
   //    name: 'epsGrowth3Y',
   //    label: 'EPS Growth 3Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "The percentage change in EPS over the past three years, represented as a compound annual growth rate (CAGR). This metric evaluates medium-term profitability growth and reflects the company's operational performance over multiple fiscal periods.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
   // {
   //    name: 'epsGrowth5Y',
   //    label: 'EPS Growth 5Y',
   //    group: ['filters_all', 'growth_metrics'],
   //    type: 'dropdown_range',
   //    role: 'premium',
   //    description: "The percentage change in EPS over the past five years, also expressed as a CAGR. It highlights the company's long-term profitability trajectory, indicating financial stability and growth over an extended timeframe.",
   //    cols: 1,
   //    placeholder: 'Select',
   //    rangeOptions: {
   //       to: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ],
   //       from: [
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-15', label: '-15%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '15', label: '15%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' }
   //       ]
   //    },
   // },
];
