import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
   selector: 'app-general-info-popup',
   templateUrl: './general-info-popup.component.html',
   styleUrls: ['./general-info-popup.component.scss']
})
export class GeneralInfoPopupComponent implements OnInit {

   constructor(private router: Router, private modalService: NgbModal, private subscriptionService: SubscriptionService) { }

   @Input() description: string = '';
   @Input() title: string = '';
   @Input() showUpgradeButton: boolean = false;
   @Input() showCancelBtn: boolean = false;
   @Input() goToOnConfirm!: string;
   @Input() confirmationText: string = 'OK';
   @Input() cancelText: string = 'Cancel';
   showUpgradeSection: boolean = false;
   private subscriptions: Subscription[] = [];

   ngOnInit(): void {
      this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
         // console.log(data)
         this.showUpgradeSection = this.showUpgradeButton && data.role !== 'PREMIUM'
      }))
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach(item => {
            item.unsubscribe();
         })
      }
   }

   dismissPopup() {
      this.modalService.dismissAll()
   }

   okayPressed() {
      if (this.goToOnConfirm) {
         this.router.navigateByUrl(this.goToOnConfirm)
      }
      this.modalService.dismissAll()
   }

   openPaywall() {
      this.modalService.dismissAll()
      setTimeout(() => {
         this.subscriptionService.openPaywallModal()
      }, 100)
   }

}