<footer>
   <section class="container">
      <nav class="footer-nav-wrapper">
         <div>
            <h6 class="nav-heading">Quick Links</h6>
            <ul role="list" class="links-list">
               <li class="link-item" *ngFor="let link of quickLinks">
                  <!-- <ng-container *ngIf="userRole === 'ANONYMOUS' && (link.title === 'Zakat Calculator'); else linkTemplate">
                     <button (click)="openAuth()" class=" footer-link d-flex align-items-center">
                        <i class="bi bi-arrow-right"></i>
                        <span>{{ link.title }}</span>
                     </button>
                  </ng-container> -->
                  <!-- <ng-template #linkTemplate> -->
                  <a class="d-flex align-items-center footer-link" [routerLink]="link.path">
                     <i class="bi bi-arrow-right"></i>
                     <span>{{ link.title }}</span>
                  </a>
                  <!-- </ng-template> -->
               </li>
            </ul>
         </div>


         <div>
            <h6 class="nav-heading">Company</h6>
            <ul role="list" class="links-list">
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/about-us">
                     <i class="bi bi-arrow-right"></i>
                     <span>About Us</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/for-business">
                     <i class="bi bi-arrow-right"></i>
                     <span>For Business</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/ambassador-program">
                     <i class="bi bi-arrow-right"></i>
                     <span>Ambassador Program</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/halal-investing">
                     <i class="bi bi-arrow-right"></i>
                     <span>Screening Methodology</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/terms-of-use">
                     <i class="bi bi-arrow-right"></i>
                     <span>Terms of Use</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/privacy-policy">
                     <i class="bi bi-arrow-right"></i>
                     <span>Privacy Policy</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/disclaimer">
                     <i class="bi bi-arrow-right"></i>
                     <span>General Disclaimer</span>
                  </a>
               </li>
               <li class="link-item">
                  <a class="d-flex align-items-center footer-link" routerLink="/contact-us">
                     <i class="bi bi-arrow-right"></i>
                     <span>Contact Us</span>
                  </a>
               </li>
               <!-- <li class="link-item">
                  <a class="d-flex align-items-center footer-link" href="mailto:info@musaffa.com">
                     <i class="bi bi-arrow-right"></i>
                     <span>Contact Us</span>
                  </a>
               </li> -->
            </ul>
         </div>


         <div>
            <h6 class="nav-heading">Account</h6>

            <ul role="list" class="links-list">
               <li class="link-item" *ngIf="userRole === 'ANONYMOUS'">
                  <a class="d-flex align-items-center footer-link" routerLink="/authentication/login">
                     <i class="bi bi-arrow-right"></i>
                     <span>Login</span>
                  </a>
               </li>
               <li class="link-item" *ngFor="let link of premiumLinks">
                  <a class="d-flex align-items-center footer-link" [routerLink]="link.path">
                     <i class="bi bi-arrow-right"></i>
                     <span>{{ link.title }}</span>
                  </a>
               </li>
            </ul>
         </div>







         <div>
            <h6 class="nav-heading">Install Our App</h6>
            <p class="mb-3 d-none d-lg-block scan-text">Scan QR code to install app</p>
            <div class="d-grid gap-3 gap-lg-4 store-badges">
               <a href="https://app.musaffa.com/wKcRlYiPpKb" target="_blank">
                  <img src="/assets/images/dashboard/app-store-badge.png" alt="app-store-badge" class="img-fluid">
               </a>
               <a href="https://app.musaffa.com/mcJZc85OpKb" target="_blank">
                  <img src="/assets/images/dashboard/play-store-badge.png" alt="play-store-badge" class="img-fluid">
               </a>
               <a href="https://chromewebstore.google.com/detail/musaffa-halal-stock-scann/khhlfpiekiijfkpcobnfcagchfepnfla"
                  target="_blank"
                  class="d-inline-flex text-decoration-none bg-white align-items-center gap-2 extension-link">
                  <svg class="flex-shrink-0" height="30" width="30" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512" xml:space="preserve">
                     <g stroke-width="0" />
                     <g stroke-linecap="round" stroke-linejoin="round" />
                     <path style="fill:#82d889"
                        d="M256 386.648c-25.6 0-49.434-7.062-70.621-21.186s-37.076-31.779-47.669-53.848L31.779 128C9.71 167.724 0 210.979 0 256c0 64.441 21.186 120.055 62.676 167.724s93.572 75.917 155.366 85.628l74.152-128.883c-7.063 2.648-20.304 6.179-36.194 6.179" />
                     <path style="fill:#e86438"
                        d="M175.669 150.069c23.834-18.538 50.317-26.483 80.331-26.483h219.807c-22.952-38.841-53.848-67.09-92.69-89.159Q324.855 0 256 0c-39.724 0-77.683 8.828-112.11 25.6S76.8 67.09 53.848 98.869L128 220.69c7.062-28.249 23.834-52.083 47.669-70.621" />
                     <path style="fill:#fce056"
                        d="M492.579 159.779H344.276c25.6 25.6 43.255 59.145 43.255 96.221 0 27.366-7.945 52.083-22.952 75.034L258.648 512c69.738-.883 129.766-25.6 179.2-75.917S512 325.738 512 256c0-32.662-5.297-67.09-19.421-96.221" />
                     <path style="fill:#25b9e1"
                        d="M256 158.897c52.966 0 97.103 44.138 97.103 97.103S308.966 353.103 256 353.103 158.897 308.966 158.897 256s44.137-97.103 97.103-97.103" />
                  </svg>
                  <span class="block text-black fw-500 lh-sm">
                     Chrome Extension
                  </span>
               </a>
               <img src="/assets/images/footer/qr-code.png" alt="qr-code" class="img-fluid d-none d-lg-block">
            </div>
         </div>

      </nav>
      <p class="mb-0 footer-para">
         The term halal, when applied to investments, is subject to multiple interpretations. We define halal
         investments as investments permitted by Shariah law. Shariah-compliant investments are investment products
         governed by the requirements of Shariah law and the principles of the Islamic religion.
      </p>
      <div class="d-flex align-items-center justify-content-between flex-wrap gap-2 border-0 copyright-wrapper">
         <div class="d-flex align-items-center flex-wrap gap-2 gap-md-3">
            <a href="//www.dmca.com/Protection/Status.aspx?ID=79d2fbda-fa14-4e0b-b7a3-015a110e3e9a"
               title="DMCA.com Protection Status" class="dmca-badge">
               <img
                  src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-01.png?ID=79d2fbda-fa14-4e0b-b7a3-015a110e3e9a"
                  alt="DMCA.com Protection Status" />
            </a>
            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
            <!-- <a href="//www.dmca.com/Protection/Status.aspx?ID=fe41b1de-e760-4095-b8c4-d14320644505" title="DMCA.com Protection Status" class="dmca-badge">
               <img src ="https://images.dmca.com/Badges/dmca-badge-w100-5x1-01.png?ID=fe41b1de-e760-4095-b8c4-d14320644505"  alt="DMCA.com Protection Status" />
            </a>
            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script> -->
            <p class="mb-0">© {{ currentYear }} Musaffa | All rights reserved.</p>
         </div>
         <ul role="list" class="d-flex align-items-center gap-3 gap-lg-4">
            <li class="social-link-item">
               <a class="d-flex align-items-center" href="https://www.linkedin.com/company/musaffa" target="_blank">
                  <i class="bi bi-linkedin"></i>
               </a>
            </li>
            <li class="social-link-item">
               <a class="d-flex align-items-center" href="https://www.facebook.com/musaffa.fintech" target="_blank">
                  <i class="bi bi-facebook"></i>
               </a>
            </li>
            <li class="social-link-item">
               <a class="d-flex align-items-center" href="https://twitter.com/Musaffa_Fintech" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-twitter-x"
                     viewBox="0 0 16 16">
                     <path
                        d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
               </a>
            </li>
            <li class="social-link-item">
               <a class="d-flex align-items-center" href="https://www.instagram.com/musaffa.fintech/" target="_blank">
                  <i class="bi bi-instagram"></i>
               </a>
            </li>
            <li class="social-link-item">
               <a class="d-flex align-items-center" href="https://www.youtube.com/@musaffafintech" target="_blank">
                  <i class="bi bi-youtube"></i>
               </a>
            </li>
         </ul>
      </div>
   </section>
</footer>
