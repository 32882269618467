<section class="step-form-container">
   <div class="form-wrapper">
      <div class="form-details-wrapper">
         <ng-content select="[stepsCounter]"></ng-content>
         <div class="heading-container">
            <h6 class="steps-form-heading">{{ formHeader }}</h6>
            <p class="steps-form-description">{{ formDescription }}</p>
         </div>
      </div>
      <div class="form-content">
         <ng-content select="[formContent]"></ng-content>
      </div>
   </div>
   <ng-content select=".steps-form-buttons"></ng-content>
   
</section>
