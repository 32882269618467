<div class="portfolio-grid-container bg-white br-10 mx-auto" [ngClass]="isFromPortfolio? '': 'portfolio-border'">
   <header class="d-flex justify-content-between portfolio-header">
      <div class="d-flex align-items-center gap-2 flex-wrap gap-lg-3">
         <h4 class="mb-0 fw-600 text-black">My Portfolio</h4>
         <div class="vr"></div>
         <ng-select class="portfolio-select" [items]="portfolios" placeholder="Select Portfolio"
            [(ngModel)]="selectedPortfolio" [dropdownPosition]="'auto'" (change)="getSelectedPortfolioDetails($event)"
            [trackByFn]="trackById" [searchable]="false" [clearable]="false">
            <ng-template ng-label-tmp let-item="item">
               <div class="d-flex align-items-center gap-2">
                  <img height="20" width="20" class="rounded-circle portfolio-image" [src]="item.brokerage_square_logo"
                     *ngIf="item.brokerage_square_logo; else noLogo" />
                  <ng-template #noLogo>
                     <div
                        class="d-flex align-items-center rounded-circle oveflow-hidden bg-white justify-content-center no-logo-div">
                        <span class="d-block text-uppercase fw-600">{{ item.name.charAt(0) }}</span>
                     </div>
                  </ng-template>
                  <span class="text-capitalize">{{ item.name }}</span>
               </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
               <div class="d-flex align-items-center gap-2">
                  <img height="20" width="20" class="rounded-circle portfolio-image" [src]="item.brokerage_square_logo"
                     *ngIf="item.brokerage_square_logo; else noListLogo" />
                  <ng-template #noListLogo>
                     <div
                        class="d-flex align-items-center rounded-circle oveflow-hidden bg-light justify-content-center no-logo-div">
                        <span class="d-block text-uppercase fw-600">{{ item.name.charAt(0) }}</span>
                     </div>
                  </ng-template>
                  <span class="text-capitalize">{{ item.name }}</span>
               </div>
            </ng-template>
         </ng-select>
         <!-- setting icon -->
         <div (click)="openPortfolioSetting()" *ngIf="isFromPortfolio" class="cursor-pointer">
            <svg class="setting-btn" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect y="0.5" width="50" height="50" rx="25" fill="#F7F6F4" />
               <path d="M35.288 27.2144C35.3841 26.6413 35.4327 26.0667 35.4327 25.5002C35.4327 24.9336 35.3841 24.359 35.288 23.7858L36.717 21.9698C36.9899 21.623 37.0242 21.1451 36.8035 20.7629L35.005 17.6478C34.7844 17.2656 34.3534 17.0563 33.9165 17.1192L31.6199 17.4501C30.7232 16.7091 29.7307 16.133 28.6587 15.7313L27.8018 13.5888C27.6379 13.1793 27.2412 12.9106 26.7999 12.9106H23.2029C22.7616 12.9106 22.3649 13.1793 22.201 13.589L21.3441 15.7314C20.2722 16.1331 19.2797 16.7092 18.3829 17.4502L16.0862 17.1193C15.6494 17.0563 15.2184 17.2657 14.9978 17.6478L13.1993 20.763C12.9786 21.1451 13.0129 21.6231 13.2858 21.9698L14.7148 23.7859C14.6187 24.3591 14.5701 24.9337 14.5701 25.5002C14.5701 26.0666 14.6187 26.6412 14.7148 27.2144L13.2858 29.0305C13.013 29.3772 12.9786 29.8552 13.1994 30.2373L14.9979 33.3525C15.2184 33.7346 15.6493 33.9441 16.0862 33.881L18.3829 33.5501C19.2797 34.2912 20.2721 34.8673 21.3441 35.269L22.2011 37.4114C22.3649 37.8211 22.7616 38.0897 23.2029 38.0897H26.7999C27.2412 38.0897 27.6379 37.8211 27.8018 37.4114L28.6588 35.2689C29.7308 34.8673 30.7232 34.2911 31.62 33.55L33.9167 33.881C34.3533 33.9439 34.7845 33.7346 35.005 33.3524L36.8036 30.2373C37.0242 29.8551 36.9899 29.3772 36.7171 29.0304L35.288 27.2144ZM25.0014 30.536C22.2247 30.536 19.9656 28.2769 19.9656 25.5002C19.9656 22.7233 22.2247 20.4644 25.0014 20.4644C27.7782 20.4644 30.0372 22.7234 30.0372 25.5002C30.0372 28.277 27.7782 30.536 25.0014 30.536Z" fill="#898888" />
            </svg>
         </div>
         <button class="btn d-md-none shadow-none rounded-3 p-1 bg-light" (click)="openSupportedBrokerages()" *ngIf="isFromPortfolio">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#4a4a4a" fill="none">
               <path d="M12 4V20M20 12H4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button>
         <button [disabled]="reconnectLoader" *ngIf="selectedPortfolio?.brokerage_connection_status==='BROKEN'" (click)="reconnectBrokenBrokerage()" class="d-flex gap-2 align-item-center shadow-none mb-0 border-0 bg-transparent">
            <svg *ngIf="!reconnectLoader" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3936 3.71039C12.5034 3.84527 11.7804 4.2804 11.5303 4.53045L10.5607 5.50013L15 9.9395L15.9697 8.9698C16.2197 8.7197 16.6548 7.99669 16.7897 7.10651C16.9186 6.25561 16.7652 5.32599 15.9697 4.53046C15.1741 3.73492 14.2445 3.58147 13.3936 3.71039ZM13.1689 2.22732C14.3805 2.04374 15.8259 2.26532 17.0303 3.4698C18.2348 4.67427 18.4564 6.11962 18.2728 7.33122C18.0952 8.5035 17.5303 9.5305 17.0303 10.0304L15.5303 11.5305C15.2374 11.8234 14.7626 11.8234 14.4697 11.5305L8.9697 6.03046C8.829 5.88981 8.75 5.69904 8.75 5.50013C8.75 5.30122 8.829 5.11045 8.9697 4.9698L10.4697 3.46981C10.9696 2.96982 11.9966 2.40493 13.1689 2.22732Z" fill="#E9291D" />
               <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5303 0.969668C19.8232 1.26256 19.8232 1.73744 19.5303 2.03033L17.0303 4.53033C16.7374 4.82322 16.2626 4.82335 15.9697 4.53046C15.6768 4.23757 15.6768 3.76256 15.9697 3.46967L18.4697 0.969668C18.7626 0.676777 19.2374 0.676777 19.5303 0.969668Z" fill="#E9291D" />
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99962 9.25C5.19853 9.25 5.3893 9.329 5.52995 9.4697L11.0299 14.9697C11.3228 15.2626 11.3228 15.7374 11.0299 16.0303L9.53 17.5303C9.03 18.0303 8.003 18.5952 6.83072 18.7728C5.61912 18.9564 4.17376 18.7348 2.96929 17.5303M2.96929 17.5303C1.76482 16.3259 1.54326 14.8805 1.72683 13.6689C1.90445 12.4966 2.46933 11.4697 2.96927 10.9697L4.46929 9.4697C4.60994 9.329 4.80071 9.25 4.99962 9.25M4.99962 11.0607L4.02997 12.0303C3.77991 12.2804 3.34478 13.0034 3.2099 13.8936C3.08098 14.7445 3.23442 15.6741 4.02995 16.4697C4.82548 17.2652 5.75512 17.4187 6.60601 17.2898C7.49622 17.1549 8.2192 16.7197 8.4693 16.4697L9.439 15.5L4.99962 11.0607Z" fill="#E9291D" />
               <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0303 11.9697C12.3232 12.2626 12.3232 12.7374 12.0303 13.0303L10.0303 15.0303C9.7374 15.3232 9.2626 15.3232 8.9697 15.0303C8.6768 14.7374 8.6768 14.2626 8.9697 13.9697L10.9697 11.9697C11.2626 11.6768 11.7374 11.6768 12.0303 11.9697Z" fill="#E9291D" />
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.02995 16.4697C4.32284 16.7626 4.32322 17.2374 4.03033 17.5303L1.53033 20.0303C1.23744 20.3232 0.762558 20.3232 0.469668 20.0303C0.176777 19.7374 0.176777 19.2626 0.469668 18.9697L2.96967 16.4697C3.26256 16.1768 3.73706 16.1768 4.02995 16.4697Z" fill="#E9291D" />
               <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5303 8.46967C8.8232 8.7626 8.8232 9.2374 8.5303 9.5303L6.53033 11.5303C6.23744 11.8232 5.76256 11.8232 5.46967 11.5303C5.17678 11.2374 5.17678 10.7626 5.46967 10.4697L7.46967 8.46967C7.76256 8.17678 8.2374 8.17678 8.5303 8.46967Z" fill="#E9291D" />
            </svg>
            <span *ngIf="!reconnectLoader" class="reconnect-text">Reconnection Required</span>
            <div style="border:0.25em solid #e9291d;border-right-color: transparent;vertical-align: 0em;margin-top: 3px;" *ngIf="reconnectLoader" class="spinner-border spinner-border-sm red-loader" role="status">
               <span class="visually-hidden">Loading...</span>
            </div>
            <span *ngIf="reconnectLoader" class="reconnect-text">Reconnecting...</span>
         </button>
      </div>
      <button *ngIf="!isFromPortfolio" (click)="navigateToPortfolio()" class="btn d-none text-decoration-none d-md-inline-block rounded-3 explore-btn">
         Explore Entire Portfolio
      </button>

      <button (click)="openBrokeragesPopup()" *ngIf="isFromPortfolio"
         class="btn d-none d-md-flex gap-3 align-items-center justify-content-between rounded-3 connect-btn">
         <span>Connect New Account</span>
         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3936 3.21039C12.5034 3.34527 11.7804 3.7804 11.5303 4.03045L10.5607 5.00013L15 9.4395L15.9697 8.4698C16.2197 8.2197 16.6548 7.49669 16.7897 6.60651C16.9186 5.75561 16.7652 4.82599 15.9697 4.03046C15.1741 3.23492 14.2445 3.08147 13.3936 3.21039ZM13.1689 1.72732C14.3805 1.54374 15.8259 1.76532 17.0303 2.9698C18.2348 4.17427 18.4564 5.61962 18.2728 6.83122C18.0952 8.0035 17.5303 9.0305 17.0303 9.5304L15.5303 11.0305C15.2374 11.3234 14.7626 11.3234 14.4697 11.0305L8.9697 5.53046C8.829 5.38981 8.75 5.19904 8.75 5.00013C8.75 4.80122 8.829 4.61045 8.9697 4.4698L10.4697 2.96981C10.9696 2.46982 11.9966 1.90493 13.1689 1.72732Z" fill="url(#paint0_linear_2947_24201)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5303 0.469668C19.8232 0.762558 19.8232 1.23744 19.5303 1.53033L17.0303 4.03033C16.7374 4.32322 16.2626 4.32335 15.9697 4.03046C15.6768 3.73757 15.6768 3.26256 15.9697 2.96967L18.4697 0.469668C18.7626 0.176777 19.2374 0.176777 19.5303 0.469668Z" fill="url(#paint1_linear_2947_24201)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99962 8.75C5.19853 8.75 5.3893 8.829 5.52995 8.9697L11.0299 14.4697C11.3228 14.7626 11.3228 15.2374 11.0299 15.5303L9.53 17.0303C9.03 17.5303 8.003 18.0952 6.83072 18.2728C5.61912 18.4564 4.17376 18.2348 2.96929 17.0303M2.96929 17.0303C1.76482 15.8259 1.54326 14.3805 1.72683 13.1689C1.90445 11.9966 2.46933 10.9697 2.96927 10.4697L4.46929 8.9697C4.60994 8.829 4.80071 8.75 4.99962 8.75M4.99962 10.5607L4.02997 11.5303C3.77991 11.7804 3.34478 12.5034 3.2099 13.3936C3.08098 14.2445 3.23442 15.1741 4.02995 15.9697C4.82548 16.7652 5.75512 16.9187 6.60601 16.7898C7.49622 16.6549 8.2192 16.2197 8.4693 15.9697L9.439 15L4.99962 10.5607Z" fill="url(#paint2_linear_2947_24201)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0303 11.4697C12.3232 11.7626 12.3232 12.2374 12.0303 12.5303L10.0303 14.5303C9.7374 14.8232 9.2626 14.8232 8.9697 14.5303C8.6768 14.2374 8.6768 13.7626 8.9697 13.4697L10.9697 11.4697C11.2626 11.1768 11.7374 11.1768 12.0303 11.4697Z" fill="url(#paint3_linear_2947_24201)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.02995 15.9697C4.32284 16.2626 4.32322 16.7374 4.03033 17.0303L1.53033 19.5303C1.23744 19.8232 0.762558 19.8232 0.469668 19.5303C0.176777 19.2374 0.176777 18.7626 0.469668 18.4697L2.96967 15.9697C3.26256 15.6768 3.73706 15.6768 4.02995 15.9697Z" fill="url(#paint4_linear_2947_24201)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5303 7.96967C8.8232 8.2626 8.8232 8.7374 8.5303 9.0303L6.53033 11.0303C6.23744 11.3232 5.76256 11.3232 5.46967 11.0303C5.17678 10.7374 5.17678 10.2626 5.46967 9.9697L7.46967 7.96967C7.76256 7.67678 8.2374 7.67678 8.5303 7.96967Z" fill="url(#paint5_linear_2947_24201)" />
            <defs>
               <linearGradient id="paint0_linear_2947_24201" x1="10" y1="0.249998" x2="10" y2="19.75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#21B15A" />
                  <stop offset="1" stop-color="#2EA8BE" />
               </linearGradient>
               <linearGradient id="paint1_linear_2947_24201" x1="10" y1="0.249998" x2="10" y2="19.75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#21B15A" />
                  <stop offset="1" stop-color="#2EA8BE" />
               </linearGradient>
               <linearGradient id="paint2_linear_2947_24201" x1="10" y1="0.249998" x2="10" y2="19.75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#21B15A" />
                  <stop offset="1" stop-color="#2EA8BE" />
               </linearGradient>
               <linearGradient id="paint3_linear_2947_24201" x1="10" y1="0.249998" x2="10" y2="19.75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#21B15A" />
                  <stop offset="1" stop-color="#2EA8BE" />
               </linearGradient>
               <linearGradient id="paint4_linear_2947_24201" x1="10" y1="0.249998" x2="10" y2="19.75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#21B15A" />
                  <stop offset="1" stop-color="#2EA8BE" />
               </linearGradient>
               <linearGradient id="paint5_linear_2947_24201" x1="10" y1="0.249998" x2="10" y2="19.75" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#21B15A" />
                  <stop offset="1" stop-color="#2EA8BE" />
               </linearGradient>
            </defs>
         </svg>
      </button>
   </header>

   <section class="portfolio-info-section">
      <ng-container *ngIf="detailsLoader">
         <div class="br-10 d-grid gap-4 p-3 p-lg-4 portfolio-data-card">
            <span class="skeleton-loader w-50 py-3 rounded-3"></span>
            <div class="d-flex w-100 align-items-center gap-3 gap-lg-4">
               <div class="flex-grow-1">
                  <span class="skeleton-loader rounded-3 mb-2 py-2 px-5"></span>
                  <span class="skeleton-loader rounded-3 py-3 px-5"></span>
               </div>
               <div class="vr"></div>
               <div class="flex-grow-1">
                  <span class="skeleton-loader rounded-3 mb-2 py-2 px-5"></span>
                  <span class="skeleton-loader rounded-3 py-3 px-5"></span>
               </div>
            </div>
            <span class="w-100 skeleton-loader rounded-3 pt-3 pb-1"></span>
         </div>

         <div class="d-grid gap-3">
            <span class="skeleton-loader w-50 py-3 rounded-3"></span>
            <div class="d-flex w-100 align-items-center gap-3 gap-lg-4">
               <span class="flex-grow-1 skeleton-loader br-10 p-5"></span>
               <span class="flex-grow-1 skeleton-loader br-10 p-5"></span>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="!detailsLoader">
         <article class="br-10 p-3 p-lg-4 portfolio-data-card">
            <span class="portfolio-amount d-block fw-700">
               {{ selectedPortfolioDetails?.total_current?.toFixed(2) | sumPipe : (selectedPortfolioDetails?.currency || 'USD') }}
            </span>
            <span class="d-block cash-balance fw-500" *ngIf="!selectedPortfolio?.is_manual">
               + Cash Balance: {{ selectedPortfolioDetails?.cash_balance?.toFixed(2) | sumPipe : (selectedPortfolioDetails?.currency || 'USD')}}
            </span>
            <div class="d-flex align-items-start my-3 gap-3 gap-md-4">
               <div>
                  <h6 class="mb-1 fw-500">Invested amount</h6>
                  <span class="invested-amount fw-600">{{ selectedPortfolioDetails?.total_invested?.toFixed(2) | sumPipe : (selectedPortfolioDetails?.currency || 'USD') }}</span>
               </div>
               <div class="vr"></div>
               <div>
                  <h6 class="mb-1 fw-500">Total returns</h6>
                  <span class="returns-amount fw-600" [ngClass]="(totalReturns ?? 0) >= 0 ? 'positive' : 'negative'">
                     <i class="bi" [ngClass]="getIconClasses(totalReturns)"></i>
                     {{ transformValue(totalReturns) | sumPipe : (selectedPortfolioDetails?.currency || 'USD') }}
                     ({{ calculatePercentage(totalReturns, selectedPortfolioDetails?.total_invested) }}%)
                  </span>
               </div>
            </div>
         </article>

         <div class="highlights-slider">
            <div class="d-flex align-items-center mb-2 mb-md-3 justify-content-between">
               <h5 class="mb-0 fw-600">Highlights</h5>
               <!-- <div class="d-flex align-items-center gap-2" *ngIf="highlights.length > 2">
                  <a class="btn p-0 slider-btn" (click)="owlCar.prev()">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color="#28a3be" fill="none">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1" />
                        <path d="M13.5 16C13.5 16 10.5 13.054 10.5 12C10.5 10.9459 13.5 8 13.5 8" stroke="currentColor"
                           stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </a>
                  <a class="btn p-0 slider-btn" (click)="owlCar.next()">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color="#28a3be" fill="none">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1" />
                        <path d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16" stroke="currentColor"
                           stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </a>
               </div> -->
            </div>

            <ng-container *ngIf="highlights.length > 0">
               <owl-carousel-o [options]="customOptions" #owlCar>

                  <ng-template carouselSlide *ngFor="let highlight of highlights">
                     <article class="text-decoration-none highlight-card my-1 p-3 br-10 border" [ngClass]="highlights.length === 1 ? 'width-50' : ''">
                        <div class="d-flex align-items-center mb-3 gap-3">
                           <div class="image-wrapper overflow-hidden d-flex align-items-center justify-content-center flex-shrink-0 rounded-circle bg-light">
                              <img [src]="highlight.logo" class="d-block" alt="Top gainer logo" *ngIf="highlight?.logo || highlight?.logo !== null; else noTopLoosersLogo">
                              <ng-template #noTopLoosersLogo>
                                 <span class="fw-600">{{ highlight?.name !== null ? highlight?.name?.charAt(0) : '' }}</span>
                              </ng-template>
                           </div>
                           <h6 class="mb-0 fw-600 line-clamp-1">{{ highlight?.name !== null ? highlight?.name : ''}}
                           </h6>
                        </div>
                        <p class="mb-0 fw-600 percent-para" [ngClass]="(highlight?.change_pc ?? 0) >= 0 ? 'positive' : 'negative'">
                           {{ highlight?.change_pc?.toFixed(2) }}% {{ (highlight?.change_pc ?? 0) >= 0 ? 'increased' : 'decreased' }}
                        </p>
                        <p class="mb-0 movement-para" *ngIf="(highlight?.change_pc ?? 0) >= 0">moved up your portfolio </p>
                        <p class="mb-0 movement-para" *ngIf="(highlight?.change_pc ?? 0) < 0">moved down your portfolio </p>
                     </article>
                  </ng-template>

               </owl-carousel-o>
            </ng-container>

            <ng-container *ngIf="highlights.length === 0">
               <div
                  class="d-flex align-items-center justify-content-center justify-content-lg-start gap-3 mt-4 gap-lg-4 no-highlight-div">
                  <img src="/assets/images/portfolio/no-highlights.svg" class="img-fluid" alt="No Highlights">
                  <p class="mb-0">No stock highlights found for your account</p>
               </div>
            </ng-container>
         </div>
         <p class="mb-0 mt-3 last-updated-para">
            <span *ngIf="selectedPortfolioDetails?.last_sync_at">Last Updated: {{ selectedPortfolioDetails?.last_sync_at
               | date : "medium" }}· </span>
            <span *ngIf="!selectedPortfolioDetails?.last_sync_at">Last Updated Never· </span>
            <span *ngIf="!syncLoader" (click)="syncPortfolio()"
               class="fw-bold text-decoration-underline cursor-pointer">Sync Now</span>
            <span *ngIf="syncLoader">Syncing...</span>
         </p>
      </ng-container>


      <!-- <div *ngIf="!detailsLoader; else buttonLoader">
         <button *ngIf="!isFromPortfolio" (click)="navigateToPortfolio()" class="btn d-block text-decoration-none d-md-none mt-3 mx-auto rounded-3 explore-btn">
            Explore Entire Portfolio
         </button>

      </div>
      <ng-template #buttonLoader>
         <span class="skeleton-loader w-25 py-2 mt-4 rounded-3"></span>
      </ng-template> -->

   </section>

   <section *ngIf="!isFromPortfolio;else fromPortFolio" class="portfolio-chart-section br-10 p-md-4">
      <div class="tab-btns-wrapper d-flex align-items-center">
         <button class="chart-tab-btn text-nowrap d-flex rounded-pill align-items-center gap-2" [disabled]="detailsLoader"
            (click)="getSelectedAnalysisChart('shariah', shariahLabels, shariahData, shariahColors)"
            [ngClass]="activeAnalysis === 'shariah' ? 'active' : ''">
            <span>Shariah Analysis</span>
         </button>
         <button class="chart-tab-btn text-nowrap d-flex rounded-pill align-items-center gap-2" [disabled]="detailsLoader"
            (click)="getSelectedAnalysisChart('sector', sectorsLabels, sectorsData, sectorsColors)"
            [ngClass]="activeAnalysis === 'sector' ? 'active' : ''">
            <span>Sector Analysis</span>
         </button>
         <button class="chart-tab-btn text-nowrap d-flex rounded-pill align-items-center gap-2" [disabled]="detailsLoader"
            (click)="navigateToPortfolio()">
            <span>More Analysis</span>
            <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M0.957031 1.4499C0.957031 1.07711 1.25924 0.774902 1.63203 0.774902H9.73201C10.1048 0.774902 10.407 1.07711 10.407 1.4499V9.5499C10.407 9.92268 10.1048 10.2249 9.73201 10.2249C9.35923 10.2249 9.05701 9.92268 9.05701 9.5499V2.1249H1.63203C1.25924 2.1249 0.957031 1.82269 0.957031 1.4499Z" fill="#727272" />
               <path d="M10.2074 0.972603C10.471 1.2362 10.471 1.6636 10.2074 1.9272L1.15229 9.78237C0.888694 10.046 0.461311 10.046 0.197701 9.78237C-0.0659003 9.51876 -0.0659003 9.09135 0.197701 8.82774L9.25278 0.972603C9.51639 0.709002 9.9438 0.709002 10.2074 0.972603Z" fill="#727272" />
            </svg>
         </button>
      </div>

      <div class="d-grid gap-4 pt-4" *ngIf="detailsLoader">
         <div class="skeleton-loader rounded-circle p-5 mx-auto chart-loader"></div>
         <div class="chart-labels-grid mx-auto">
            <span class="rounded-pill w-100 py-3 px-5 skeleton-loader" *ngFor="let i of [1,2,3,4]"></span>
         </div>
      </div>

      <div *ngIf="!detailsLoader">
         <div class="d-flex align-items-center justify-content-center w-100">
            <div class="position-relative analyst-chart-div" *ngIf="hasData; else noChartData">
               <canvas #analysisChartCanvas class="analyst-chart-canvas d-block mx-auto" id="AnalysisChart">
                  {{ analysisChart }}
               </canvas>
            </div>
            <ng-template #noChartData>
               <div class="d-flex align-items-center flex-column justify-content-center">
                  <img src="/assets/images/no-holdings-image.svg" class="img-fluid mt-4 no-data-chart mx-auto chart-loader" alt="No Chart Data">
                  <p class="mb-0 p-3 text-center no-data-text">Seems like there's not enough data to classify</p>
               </div>
            </ng-template>
         </div>

         <div class="chart-labels-grid px-3 pb-3 px-md-0 pb-md-0 mx-auto" *ngIf="hasData">
            <ng-container *ngIf="activeAnalysis === 'shariah'">
               <div
                  class="d-flex align-items-center gap-2 justify-content-between rounded-pill border chart-label-wrapper shariah-labels"
                  *ngFor="let label of shariahLabels; let i = index">
                  <div class="d-flex align-items-center gap-2">
                     <span class="rounded-circle label-clr" [ngStyle]="{'background-color': shariahColors[i]}"></span>
                     <span class="label-text fw-500">{{ label }}</span>
                  </div>
                  <span class="label-value fw-600">
                     {{ shariahData[i] }}%
                  </span>
               </div>
            </ng-container>

            <ng-container *ngIf="activeAnalysis === 'sector'">
               <div
                  class="d-flex align-items-center gap-2 justify-content-between rounded-pill border chart-label-wrapper sector-labels"
                  *ngFor="let label of sectorsLabels; let i = index">
                  <div class="d-flex align-items-center gap-2">
                     <span class="rounded-circle label-clr" [ngStyle]="{'background-color': sectorsColors[i]}"></span>
                     <span class="label-text fw-500">{{ label }}</span>
                  </div>
                  <span class="label-value fw-600">
                     {{ sectorsData[i] }}%
                  </span>
               </div>
            </ng-container>
         </div>

      </div>
   </section>


   <ng-template #fromPortFolio>
      <div class="d-grid gap-4 pt-4" *ngIf="detailsLoader">
         <div class="skeleton-loader rounded-circle p-5 mx-auto chart-loader"></div>
      </div>
      <section *ngIf="!detailsLoader" class="portfolio-data">
         <article class="w-100" *ngIf="hasData;else noChartData;">
            <div class="portfolio-data-container">
               <div class="portfolio-percentage-container">
                  <div class="percentage-data">
                     <p class="mb-0">{{shariahData[0]}}<span>%</span></p>
                     <div class="mx-auto">Halal</div>
                  </div>
                  <img [ngStyle]="{'bottom':-(100-shariahData[0])+'%'}"
                     src="/assets/images/portfolio/portfolio-percentage.png" />
               </div>
               <div class="compliance-grid">
                  <div class="compliance-percentage" *ngFor="let item of shariahLabels; let i = index">
                     <div [ngStyle]="{'background-color':shariahColors[i]}" class="sharia-dot-badge me-2"></div>
                     <p class="mb-0 sharia-text">{{item}}</p>
                     <p class="mb-0 ml-auto sharia-percentage">{{shariahData[i]}}%</p>
                  </div>
               </div>
            </div>
            <div (click)="gotoAnalyticsTab()" class="detailed-btn-container">
               <p>View Detailed Analytics</p>
            </div>
         </article>

         <ng-template #noChartData>
            <div class="d-flex align-items-center flex-column justify-content-center">
               <img src="/assets/images/no-holdings-image.svg" class="img-fluid mt-4 no-data-chart mx-auto chart-loader"
                  alt="No Chart Data">
               <p class="mb-0 text-center p-3 no-data-text">Seems like there's not enough data to classify</p>
            </div>
         </ng-template>
      </section>
   </ng-template>

   <button *ngIf="!isFromPortfolio" (click)="navigateToPortfolio()" class="btn d-block text-decoration-none d-md-none mx-auto rounded-3 explore-btn">
      Explore Entire Portfolio
   </button>
</div>