import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';

interface Link {
   title: string,
   path: string,
   icon?: string
}

@Component({
   selector: 'app-primary-footer',
   templateUrl: './primary-footer.component.html',
   styleUrls: ['./primary-footer.component.scss']
})

export class PrimaryFooterComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   currentYear: number = new Date().getFullYear();
   userRole: string | null = null;

   quickLinks: Link[] = [
      { title: 'Stock Screener', path: '/stock-screener'},
      { title: 'ETF Screener', path: '/etf-screener'},
      { title: 'Super Investors', path: '/super-investors'},
      { title: 'Purification Calculator', path: '/purification'},
      { title: 'Zakat Calculator', path: '/zakat-calculator'},
      { title: 'Musaffa Academy', path: '/academy-articles'},
      { title: 'User Collections', path: '/public-lists'},
      { title: 'Covered Countries', path: '/covered-countries'},
   ]

   companyLinks: Link[] = [
      { title: 'About Us', path: ''},
      { title: 'Contact Us', path: ''},
      { title: 'Terms of Use', path: ''},
      { title: 'Privacy Policy', path: ''},
   ]

   premiumLinks: Link[] = [
      // { title: 'Login', path: '/authentication/login'},
      { title: 'My Portfolio', path: '/portfolio-dashboard'},
      { title: 'My Watchlist', path: '/watchlist-lists'},
      { title: 'Pricing Plans', path: '/pricing'},
   ]

   // socialLinks: Link[] = [
   //    { title: 'LinkedIn', path: 'https://www.linkedin.com/company/musaffa', icon: 'bi bi-linkedin'},
   //    { title: 'Facebook', path: 'https://www.facebook.com/musaffa.fintech', icon: 'bi bi-facebook'},
   //    { title: 'Twitter', path: 'https://twitter.com/Musaffa_Fintech', icon: 'bi bi-twitter-x'},
   //    { title: 'Instagram', path: 'https://www.instagram.com/musaffa.fintech/', icon: 'bi bi-instagram'},
   //    { title: 'YouTube', path: 'https://www.youtube.com/@musaffafintech', icon: 'bi bi-youtube'},
   // ]

   constructor(
      private subscriptionService: SubscriptionService
   ) { }

   ngOnInit(): void {
      SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
         this.userRole = data.role
      })
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   openAuth() {
      console.log('modal opened');
      
   }

}
