<header [class.active]="showMenu">
   <div class="border-line">
      <div class="container">
         <div class="account-header">
            <ng-container *ngIf="!checkRole('PREMIUM') && !checkRole('FREEMIUM')">
               <img src="/assets/images/musaffa-logo.svg" alt="" class="img-logo" />
            </ng-container>

            <a [routerLink]="['/cabinet']" *ngIf="checkRole('FREEMIUM')">
               <img src="/assets/images/musaffa-logo.svg" alt="" class="img-logo" />
            </a>
            <div ngbDropdown class="d-inline-block">
               <button class="btn" id="dropdownBasic1" ngbDropdownToggle>
                  {{ user.firstname }} {{ user.lastname
                  }}<img src="/assets/images/forward.svg" alt="" />
               </button>
               <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem class="accaunt" (click)="navigate('/cabinet/profile')"
                     *ngIf="checkRole('FREEMIUM')">
                     <span><img src="/assets/images/user.svg" alt=""></span>
                     <span class="accaunt">Starter account</span>
                  </button>
                  <!-- <button ngbDropdownItem class="accaunt" (click)="navigate('/pages/stripe-premium-mobile')"
                     *ngIf="checkRole('FREEMIUM')">
                     <span><img src="/assets/images/logoMark.svg" alt="" /></span>
                     <span class="accaunt-text">Start 7-day Free Trial <br /> </span>
                  </button> -->

                  <button ngbDropdownItem class="accaunt" (click)="navigate('/cabinet/settings')"
                     *ngIf="checkRole('FREEMIUM')">
                     <span><img src="/assets/images/settings.svg" alt="" /></span>
                     <span>Settings</span>
                  </button>
                  <button ngbDropdownItem class="accaunt" (click)="navigate('/cabinet/reset-password')"
                     *ngIf="checkRole('FREEMIUM')">
                     <span><img width="20" src="/assets/images/reset-password.svg" alt="" /></span>
                     <span>Reset password</span>
                  </button>
                  <button ngbDropdownItem class="accaunt" (click)="navigate('/cabinet/support')"
                     *ngIf="checkRole('FREEMIUM')">
                     <span><i class="bi bi-envelope-fill"></i></span>
                     <span class="accaunt">Get in touch</span>
                  </button>
                  <button ngbDropdownItem class="accaunt" (click)="modalLogout()">
                     <span><img src="/assets/images/log_out.svg" alt="" /></span>
                     <span>Log out</span>
                  </button>
               </div>
            </div>
            <div class="account-hamburger" (click)="showMenu = !showMenu">
               <div [class.active]="showMenu" class="account-hamburger__line account-hamburger__line--top"></div>
               <div [class.active]="showMenu" class="account-hamburger__line account-hamburger__line--bottom"></div>
            </div>
            <div [class.mobile-menu--open]="showMenu" class="mobile-menu">
               <div class="container">
                  <div class="mobile-menu__item" (click)="navigate('/cabinet/profile')"
                     *ngIf="checkRole('FREEMIUM')">
                     <div class="mobile-main">
                        <img src="/assets/images/user.svg" alt="">
                        <span class="mobile-menu-text">Starter account</span>
                     </div>
                  </div>
                  <!-- <div class="mobile-menu__item" (click)="navigate('/pages/stripe-premium-mobile')"
                     *ngIf="checkRole('FREEMIUM')">
                     <div class="mobile-main">
                        <img src="/assets/images/logoMark.svg" alt="" />
                        <span class="mobile-menu-text-2">Start 7-day Free Trial</span>
                     </div>
                  </div> -->

                  <div class="mobile-menu__item" (click)="navigate('/cabinet/settings')"
                     *ngIf="checkRole('FREEMIUM')">
                     <div class="mobile-main">
                        <img src="/assets/images/settings.svg" alt="" />
                        <span class="mobile-menu-text-3">Settings</span>
                     </div>
                  </div>
                  <div class="mobile-menu__item" (click)="navigate('/cabinet/reset-password')"
                     *ngIf="checkRole('FREEMIUM')">
                     <div class="mobile-main">
                        <img width="20" src="/assets/images/reset-password.svg" alt="" />
                        <span class="mobile-menu-text-3">Reset password</span>
                     </div>
                  </div>
                  <div class="mobile-menu__item" (click)="navigate('/cabinet/support')"
                     *ngIf="checkRole('FREEMIUM')">
                     <div class="mobile-main">
                        <div class="mobile-icons">
                           <i class="bi bi-envelope-fill"></i>
                           <span class="mobile-menu-text-2">Get in touch</span>
                        </div>
                     </div>
                  </div>
                  <div class="mobile-menu__item mobile-menu__item--logout" (click)="modalLogout()">
                     <div class="mobile-main">
                        <img src="/assets/images/log_out.svg" alt="" />
                        <span class="mobile-menu-text-3">Log out</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</header>