<div class="popular-stocks-table-container">

   <!-- display ( popular stocks table ) for desktop hide for mobile -->
   <table class="table d-none d-md-table">
      <thead>
         <tr>
            <th scope="col" class="ps-3">Name</th>
            <th scope="col" class="d-none d-lg-table-cell text-end">Price</th>
            <th scope="col" class="d-none d-lg-table-cell text-end">Change</th>
            <th scope="col" class="text-end pe-3">Compliance</th>
            <!-- <th scope="coll" class="">Watch</th> -->
         </tr>
      </thead>
      <tbody>
         <tr
            *ngFor="let stock of stocks"


         >
            <th scope="row">
               <div class="d-flex align-items-center">
                  <div class="table-image-div">
                     <img
                     [src]="stock.logo" alt=""
                     class="img-fluid table-company-logo me-3"
                     *ngIf="stock.logo"
                     (error)="handleMissingImage($event)"
                  >
                  </div>
                  <div>
                     <span class="company-name d-block">{{ stock.companyName }}</span>
                     <span class="company-symbol d-block">{{ stock.stockName }}</span>
                  </div>
               </div>
            </th>
            <td class="d-none d-lg-table-cell text-end">
               <span *ngIf="stock.price">
                  {{ stock.price | sumPipe: stock.currency }}
               </span>
               <span *ngIf="stock.price == null"> - </span>
            </td>
            <td class="change-percent d-none d-lg-table-cell text-end">
               <span
                  *ngIf="stock.priceChangePercent"
                  [ngClass]=" (stock.priceChangePercent < 0) ? 'red-text' : 'green-text'"
               >
                  <i
                     class="fa-solid me-1"
                     [ngClass]="{
                        'fa-caret-down': stock.priceChangePercent < 0,
                        'fa-caret-up': stock.priceChangePercent > 0
                     }"
                  ></i>
                  {{ transformValue(stock.priceChangePercent) + '%' }}
               </span>
               <span *ngIf="stock.priceChangePercent == null"> - </span>
            </td>
            <td class="pe-3">
               <ng-template
                  *ngTemplateOutlet="complianceElem; context: {$implicit: complianceInfo?.[stock.id]}"
               ></ng-template>
            </td>
            <!-- <td class=""><i class="fa-solid fa-star"></i></td> -->
         </tr>
      </tbody>
   </table>


</div>

<!-- display ( popular stocks cards ) for mobile hide for desktop -->
<div class="popular-stocks-cards-container d-md-none">
   <div
      class="popular-stocks-card "
      *ngFor="let stock of stocks"

   >
      <div class="row mb-3">
         <div class="col-6 pe-0">
            <img [src]="stock.logo" alt="" class="img-fluid mob-company-image">
         </div>
         <div class="col-6 ps-0">
             <ng-template
                  *ngTemplateOutlet="complianceElem; context: {$implicit: complianceInfo?.[stock.id]}"
               ></ng-template>
         </div>
      </div>
      <h6 class="mob-company-name">
         {{ stock.companyName }}
      </h6>
      <div class="mob-price">
         <span *ngIf="stock.price">
            <!-- {{ stock.priceText }} -->
            {{ stock.price | sumPipe: stock.currency }}
         </span>
         <span *ngIf="stock.price == null"> - </span>
      </div>
      <div class="mob-percent-change">
         <span
            *ngIf="stock.priceChangePercent"
            [ngClass]=" (stock.priceChangePercent < 0) ? 'red-text' : 'green-text'"
         >
         <i
            class="fa-solid mobile-caret"
            [ngClass]="{
               'fa-caret-down': stock.priceChangePercent < 0,
               'fa-caret-up': stock.priceChangePercent > 0
            }"
         ></i>
            {{ transformValue(stock.priceChangePercent) + '%' }}
         </span>
         <span *ngIf="stock.priceChangePercent == null"> - </span>
      </div>
   </div>
</div>

<ng-template #complianceElem let-compliance>
   <div class="halal-tag ms-auto  py-1 px-2" *ngIf="compliance?.status == 'COMPLIANT'">
     <span class="halal-text">Halal</span>
     <div class="halal-ratings d-flex align-items-center justify-content-between">
       <i *ngFor="let i of [1,2,3,4,5]" class="fa-star" [ngClass]="{
         'fa-solid':compliance?.ranking >= i,
         'fa-regular':compliance?.ranking < i
       }"></i>

     </div>
   </div>
   <div class="doubtful-tag ms-auto p-2" *ngIf="compliance?.status == 'QUESTIONABLE'">
     <span class="doubtful-text">Doubtful</span>
   </div>
   <div class="not-halal-tag ms-auto  p-2" *ngIf="compliance?.status == 'NON_COMPLIANT'">
     <span class="not-halal-text">Not Halal</span>
   </div>

   <div class="locked-tag ms-auto p-2" *ngIf="compliance?.status == 'LOCKED'">
      <img src="../../../assets/images/complanise-locked.svg" alt="" class="img__locked" />
      <span class="doubtful-text">Locked</span>
   </div>
   <!-- <div>
      <div class="complinese__losked"
      *ngIf=" complianceInfo?.[stock.isin]?.status === 'LOCKED' || complianceInfo?.[stock.isin]?.status === 'DEFAULT'"
      [routerLink]="['/stock', stock.stockName]">
      <img src="../../../assets/images/complanise-locked.svg" alt="" class="img__locked" />
      <span>Locked</span>

   </div>
   </div> -->
 </ng-template>


