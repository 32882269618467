import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { TypesenseService } from '../../services/typesense.service';
import { Subscription } from 'rxjs';

interface Holding {
   id?: number;
   logo?: string;
   name?: string;
   symbol?: string;
   percent?: number;
   cbaStatus?: string;
   isin?: string;
   ranking?: number;
   shariahStates?: string;
}

@Component({
   selector: 'app-etf-screening-detailed-report',
   templateUrl: './etf-screening-detailed-report.component.html',
   styleUrls: ['./etf-screening-detailed-report.component.scss'],
})
export class EtfScreeningDetailedReportComponent implements OnInit, OnChanges {
   @Input() symbol!: string;

   private subscriptions: Subscription[] = [];
   page = 1;
   pageSize = 10;
   collectionSize!: number;
   holdings: any[] = [];
   loader: boolean = false;

   constructor(private typesenseService: TypesenseService) {
      // this.refreshHoldings()
   }

   ngOnChanges() {}

   ngOnInit(): void {
      this.etfHoldings();
   }

   private etfHoldings() {
      this.loader = true;
      let symbol = this.symbol;
      let subscribe = this.typesenseService
         .etfHoldings(symbol)
         .subscribe(async (data: any) => {
            var holdingsArr = data.items;

            holdingsArr = holdingsArr.sort(
               (a: any, b: any) => b.percent - a.percent
            );
            this.holdings = holdingsArr;
            this.collectionSize = this.holdings.length;
            this.typesenseService.getStockFromSymbols(this.holdings.map((holding) => holding.symbol)).then((data: any) => {
               this.holdings = this.holdings.map((holding) => {
                  return {
                     ...holding,
                     logo: data[holding.symbol]?.logo || ''
                  }
               });
            });
            this.loader = false;
         });
      this.subscriptions.push(subscribe);
   }


   public setEtfLocation(holding: any) {
      if (holding.symbol) {
         window.location.href = '/stock/' + holding.symbol;
      }
   }

   getstatuscolorClasses(status: any) {
      return {
         green: status === 'PASS',
         red: status === 'FAIL',
      };
   }
   // refreshHoldings() {
   //   this.holdings
   //     .map((holding, i) => ({id: i + 1, ...holding}))
   //     .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
   // }
}
