<div class="loader-wrapper border br-10 p-3 p-lg-4" *ngIf="complianceLoading">
   <div class="top gap-2 gap-lg-4" >
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
   </div>
   <div class="middle gap-3 mx-auto gap-lg-5">
      <span class="d-block skeleton-loader rounded-circle cr p-4 w-100"></span>
      <div class="d-flex flex-column align-items-center gap-3 gap-lg-4">
         <span class="d-block skeleton-loader rounded-3 pt-2 labl pb-3 py-lg-4 w-100"></span>
         <span class="d-block skeleton-loader rounded-3 pt-2 labl pb-3 py-lg-4 w-100"></span>
         <span class="d-block skeleton-loader rounded-3 pt-2 labl pb-3 py-lg-4 w-100"></span>
      </div>
   </div>
   <div class="bottom d-grid gap-2 gap-lg-4">
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
   </div>
</div>
<app-limit-reached-card *ngIf="isLimitReached && !complianceLoading" [permissionKey]="'stock_compliance_report'"></app-limit-reached-card>
<div
   class="report-main-container"
   *ngIf="!isLimitReached && !complianceLoading"
>
   <!-- <div>
      <app-report-compliance-status-card
         [companyProfile]="companyProfile"
         [reportDate]="data?.reportDate"
         [complianceStatus]="data?.status"
         [complianceRanking]="data?.ranking"
         [quarterText]="getQuarterText()"
         [lastUpdated]="getLastUpdated()"
         [statusValue]="getStatusDisplayValue()"
         [stockType]="'stock'"
         [isSample]="isSample"
         [page]="'stock'"
         (closeModal)="closeActiveModel()"
      >
      </app-report-compliance-status-card>
   </div> -->

   <!-- <div class="report-data-container p-0" *ngIf="data?.status === 'LOCKED'">
      <h2 class="mb-0 p-3 p-lg-4 screener-section-title">Screening Report</h2>
      <div class=" d-flex align-items-center justify-content-center status-div text-center locked-status">
         <img src="../../../../assets/images/complanise-locked.svg" alt="locke image" class="lock-image me-3">
         <div class="locked-text">Locked</div>
      </div>
      <div class="status-text-container">
         <p class="mb-0 text-center">
            Sign up now and get Premium access
         </p>
      </div>
      <div class="btns-container d-flex justify-content-center">
         <button class="btn report-button shadow-none text-white fw-bold" [routerLink]="['/authentication/sso-login']">
            Sign up for free
         </button>
      </div>
   </div> -->

   <div
      class="report-data-container p-0"
      *ngIf="data?.status === 'NOT_UNDER_COVERAGE'"
   >
      <h2 class="mb-0 p-4 d-none d-lg-block screener-section-title">
         Screening Report
      </h2>
      <div
         class="d-flex align-items-center justify-content-center status-div text-center not-covered-status"
      >
         <div class="locked-text">Not Covered</div>
      </div>
   </div>

   <div
      class="report-data-container"
      *ngIf="handleReportVisibility(data?.status)"
   >
      <div class="container" *ngIf="isSample && isMobile">
         <div class="row">
            <div class="col col-4 text-center">
               <app-sample-bubble
                  [bubbleContent]="businessText"
                  tooltipPosition="right"
               ></app-sample-bubble>
            </div>
            <div class="col col-4 text-center">
               <app-sample-bubble
                  [bubbleContent]="interestText"
                  tooltipPosition="bottom"
               ></app-sample-bubble>
            </div>
            <div class="col col-4 text-center">
               <app-sample-bubble
                  [bubbleContent]="debtText"
                  tooltipPosition="left"
               ></app-sample-bubble>
            </div>
         </div>
      </div>
      <div class="container" *ngIf="isMobile">
         <div class="row">
            <div class="col">
               <div class="mobile__tabs-main">
                  <div class="tabs">
                     <button
                        class="btn left"
                        [ngClass]="{
                           active: active == 1,
                           pas: data?.revenueBreakdownStatus == 'Pass',
                           fail: data?.revenueBreakdownStatus == 'Fail'
                        }"
                        type="button"
                        (click)="setActive(1)"
                        *ngIf="isBusinessActivityAvailable()"
                     >
                        <span>Revenue</span>
                        <i
                           class="bi bi-check-circle"
                           *ngIf="data?.revenueBreakdownStatus == 'Pass'"
                        ></i>
                        <i
                           class="bi bi-x-circle"
                           *ngIf="data?.revenueBreakdownStatus == 'Fail'"
                        ></i>
                     </button>
                     <button
                        class="btn center"
                        [ngClass]="{
                           active: active == 2,
                           pas: data?.securitiesAndAssetsStatus == 'Pass',
                           fail: data?.securitiesAndAssetsStatus == 'Fail'
                        }"
                        type="button"
                        (click)="setActive(2)"
                        *ngIf="isSecuritiesAvailable()"
                     >
                        <span>Securities</span>
                        <i
                           class="bi bi-check-circle"
                           *ngIf="data?.securitiesAndAssetsStatus == 'Pass'"
                        ></i>
                        <i
                           class="bi bi-x-circle"
                           *ngIf="data?.securitiesAndAssetsStatus == 'Fail'"
                        ></i>
                     </button>
                     <button
                        class="btn right"
                        [ngClass]="{
                           active: active == 3,
                           pas: data?.debtStatus == 'Pass',
                           fail: data?.debtStatus == 'Fail'
                        }"
                        type="button"
                        (click)="setActive(3)"
                        *ngIf="isDebtAvailable()"
                     >
                        <span>Debt</span>
                        <i
                           class="bi bi-check-circle"
                           *ngIf="data?.debtStatus == 'Pass'"
                        ></i>
                        <i
                           class="bi bi-x-circle"
                           *ngIf="data?.debtStatus == 'Fail'"
                        ></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <section class="tabs-section">
         <div class="">
            <ul
               *ngIf="!isMobile"
               ngbNav
               #nav="ngbNav"
               [(activeId)]="active"
               (navChange)="tabChange()"
               class="nav nav-pills nav-fill nav-tab-grid"
               id="pills-tab"
               role="tablist"
            >
               <li class="grid-item-1" *ngIf="isBusinessActivityAvailable()">
                  <h6 class="text-start screener-section-title">
                     Business Screening
                  </h6>
                  <hr class="mb-0" />
               </li>
               <li
                  [ngbNavItem]="1"
                  class="grid-item-2"
                  role="presentation"
                  *ngIf="isBusinessActivityAvailable()"
               >
                  <div class="" *ngIf="isSample">
                     <app-sample-bubble
                        [bubbleContent]="businessText"
                        tooltipPosition="bottom"
                     ></app-sample-bubble>
                  </div>
                  <a
                     ngbNavLink
                     class="w-100 text-start tab-btn d-flex flex-column p-3"
                     id="pills-home-tab"
                     data-toggle="pill"
                     data-target="#pills-home"
                     type="button"
                     role="tab"
                     aria-controls="pills-home"
                     aria-selected="true"
                     [ngClass]="{
                        'grid-green-btn':
                           data?.revenueBreakdownStatus == 'Pass',
                        'grid-red-btn': data?.revenueBreakdownStatus == 'Fail'
                     }"
                  >
                     <span class="triangle tri-1"></span>
                     <span
                        class="w-fit rounded-pill text-white px-3 py-1 tabs-badge mb-2"
                        >{{ data?.revenueBreakdownStatus }}</span
                     >
                     <span class="">Business Activity</span>
                     <i class="fa-solid fa-briefcase tab-icon"></i>
                  </a>
               </li>
               <li
                  class="grid-item-3 align-self-end"
                  *ngIf="isSecuritiesAvailable() || isDebtAvailable()"
               >
                  <h6 class="text-start screener-section-title">
                     Financial Screening
                  </h6>
                  <hr class="mb-0" />
               </li>
               <li
                  [ngbNavItem]="2"
                  class="grid-item-4"
                  role="presentation"
                  *ngIf="isSecuritiesAvailable()"
               >
                  <div class="" *ngIf="isSample">
                     <app-sample-bubble
                        [bubbleContent]="interestText"
                        tooltipPosition="bottom"
                     ></app-sample-bubble>
                  </div>
                  <a
                     [ngClass]="{
                        'grid-green-btn':
                           data?.securitiesAndAssetsStatus == 'Pass',
                        'grid-red-btn':
                           data?.securitiesAndAssetsStatus == 'Fail'
                     }"
                     ngbNavLink
                     class="w-100 text-start tab-btn d-flex flex-column p-3"
                     id="pills-profile-tab"
                  >
                     <span class="triangle tri-2"></span>
                     <span
                        class="w-fit rounded-pill text-white px-3 py-1 tabs-badge mb-2"
                        >{{ data?.securitiesAndAssetsStatus }}</span
                     >
                     <span>
                        Interest-bearing <br />
                        securities and assets
                     </span>
                     <i
                        class="fa-solid fa-arrow-trend-up tab-icon icon-green"
                     ></i>
                  </a>
               </li>
               <li
                  [ngbNavItem]="3"
                  class="grid-item-5"
                  role="presentation"
                  *ngIf="isDebtAvailable()"
               >
                  <div class="" *ngIf="isSample">
                     <app-sample-bubble
                        [bubbleContent]="debtText"
                        tooltipPosition="bottom"
                     ></app-sample-bubble>
                  </div>
                  <a
                     ngbNavLink
                     class="w-100 text-start tab-btn d-flex flex-column triangle tri-3 p-3"
                     id="pills-contact-tab"
                     data-toggle="pill"
                     data-target="#pills-contact"
                     type="button"
                     role="tab"
                     aria-controls="pills-contact"
                     aria-selected="false"
                     [ngClass]="{
                        'grid-green-btn': data?.debtStatus == 'Pass',
                        'grid-red-btn': data?.debtStatus == 'Fail'
                     }"
                  >
                     <span class="triangle tri-3"></span>
                     <span
                        class="w-fit rounded-pill text-white px-3 py-1 tabs-badge mb-2"
                        >{{ data?.debtStatus }}</span
                     >
                     Interest-bearing debt
                     <i
                        class="fa-solid fa-arrow-trend-up tab-icon icon-red"
                     ></i>
                  </a>
               </li>
            </ul>

            <div class="tab-content mt-4" id="pills-tabContent">
               <div class="border-danger" id="pills-home" *ngIf="active == 1">
                  <div class="row">
                     <div class="col-12 col-lg-9">
                        <h3 class="d-flex align-items-center mb-0">
                           <span class="section-title">Business Activity</span>
                           <span
                              class="ms-2 rounded-pill ms-3 status-pill-large"
                              [ngClass]="{
                                 'status-pill-large-pass':
                                    data?.revenueBreakdownStatus == 'Pass',
                                 'status-pill-large-fail':
                                    data?.revenueBreakdownStatus == 'Fail'
                              }"
                           >
                              {{ data?.revenueBreakdownStatus }}
                           </span>
                        </h3>
                     </div>
                  </div>

                  <div class="">
                     <div
                        class="row mt-5 chart-content-wrapper justify-content-center mx-auto"
                     >
                        <div
                           class="col-7 col-md-6 col-lg-8 px-0 px-md-5 px-lg-0 align-self-center mx-lg-4 graph-wrapper"
                        >
                           <div class="revenue-graph">
                              <app-revenue-breakdown-chart
                                 [screeningInfo]="data"
                              ></app-revenue-breakdown-chart>
                           </div>
                        </div>
                        <div
                           class="col-5 col-md-6 col-lg-4 distribution-container ps-sm-4 ps-lg-5 align-self-center pb-lg-4"
                        >
                           <div class="d-flex align-items-center mb-3 mb-lg-5">
                              <div class="distribution-elem halal"></div>
                              <div class="d-flex flex-column ms-2">
                                 <span class="legend-title">Halal</span>
                                 <span class="percent"
                                    >{{data?.['halal'] | number: "1.2-2"
                                    }}%</span
                                 >
                              </div>
                           </div>
                           <div class="d-flex align-items-center mb-3 mb-lg-5">
                              <div class="distribution-elem doubtful"></div>
                              <div class="d-flex flex-column ms-2">
                                 <span class="legend-title">Doubtful</span>
                                 <span class="percent"
                                    >{{data?.['questionable'] | number: "1.2-2"
                                    }}%</span
                                 >
                              </div>
                           </div>
                           <div class="d-flex align-items-center">
                              <div class="distribution-elem not-halal"></div>
                              <div class="d-flex flex-column ms-2">
                                 <span class="legend-title">Not Halal</span>
                                 <span class="percent"
                                    >{{data?.['notHalal'] | number: "1.2-2"
                                    }}%</span
                                 >
                              </div>
                           </div>
                        </div>
                        <div class="col-12">
                           <div class="screening-desc my-4 my-lg-5">
                              The combined value of Non Halal and Doubtful
                              sources
                              <b>should not exceed 5% of the Total Revenue</b>
                              (Gross Sales + Other Income).
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row">
                     <div class="col-4 text-start">
                        <label class="toggle-switch">
                           <input
                              type="checkbox"
                              [(ngModel)]="businessObj.showPercent"
                           />
                           <span
                              class="toggle-slider bg-light pill-slider d-flex align-items-center justify-content-between"
                           >
                              <span
                                 class="toggle-symbols d-flex align-items-center justify-content-end ps-1"
                                 >%</span
                              >
                              <span
                                 class="toggle-symbols d-flex align-items-center justify-content-start"
                                 ><i
                                    class="material-symbols-outlined toggle-bill"
                                    >payments</i
                                 ></span
                              >
                           </span>
                        </label>
                     </div>
                     <div class="col-8 text-end">
                        <p
                           class="mb-0 currency-text ms-auto d-flex align-items-center justify-content-center"
                        >
                           <i class="material-symbols-outlined toggle-bill me-2"
                              >payments</i
                           >
                           <span
                              >Currency in {{ companyProfile?.currency }}</span
                           >
                        </p>
                     </div>
                  </div>
                  <div class="mt-4">
                     <ngb-accordion
                        #acc="ngbAccordion"
                        class="calculation-accordion bg-primary"
                        (shown)="onPanelShown($event)"
                        (hidden)="onPanelHidden($event)"
                     >
                        <ngb-panel
                           [cardClass]="
                              getCardClass(
                                 panelStatus.businessPanel,
                                 data?.revenueBreakdownStatus
                              )
                           "
                           class="calculation-panel-container"
                           id="business-panel"
                        >
                           <ng-template ngbPanelHeader>
                              <div class="calculation-panel">
                                 <div
                                    class="p-0 bg-transparent"
                                    id="headingOne"
                                 >
                                    <h2 class="mb-0">
                                       <button
                                          ngbPanelToggle
                                          class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3"
                                          type="button"
                                       >
                                          <span
                                             class="accordion-btn-title fw-500 flex-grow-1 text-start"
                                             >Calculation</span
                                          >
                                          <i
                                             class="fa-solid fa-chevron-up accordion-icon"
                                          ></i>
                                       </button>
                                    </h2>
                                 </div>
                              </div>
                           </ng-template>
                           <ng-template ngbPanelContent>
                              <div class="row">
                                 <div class="col-md-6">
                                    <div
                                       class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mt-2"
                                    >
                                       <p
                                          class="mb-0 calculation-line-item-title"
                                       >
                                          Halal Sales & Income
                                       </p>
                                       <span
                                          class="fw-500 calc-revenue calculation-line-item-val"
                                          >{{ getBusinessValueOrPercent(data?.['halalRevenue'], data?.['halal']) }}</span
                                       >
                                    </div>

                                    <div
                                       class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mt-2"
                                    >
                                       <p
                                          class="mb-0 calculation-line-item-title"
                                       >
                                          Doubtful Sales & Income
                                       </p>
                                       <span
                                          class="fw-500 calc-revenue calculation-line-item-val"
                                          >{{ getBusinessValueOrPercent(data?.['questionableRevenue'], data?.['questionable']) }}</span
                                       >
                                    </div>

                                    <div
                                       class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mt-2"
                                    >
                                       <p
                                          class="mb-0 calculation-line-item-title"
                                       >
                                          Non Halal Sales & Income
                                       </p>
                                       <span
                                          class="fw-500 calc-revenue calculation-line-item-val"
                                          >{{ getBusinessValueOrPercent(data?.['notHalalRevenue'], data?.['notHalal']) }}</span
                                       >
                                    </div>

                                    <div
                                       class="d-flex p-2 mt-2 rounded align-items-center justify-content-between calculation-line-item-container-total"
                                    >
                                       <p
                                          class="mb-0 calculation-line-item-title"
                                       >
                                          Total Revenue
                                       </p>
                                       <span
                                          class="fw-500 calc-revenue calculation-line-item-val"
                                       >
                                          {{ getTotalBusinessAct() }}
                                       </span>
                                    </div>
                                 </div>
                                 <div class="col-md-6 mt-3 mt-md-0">
                                    <div class="calc-percent text-center py-4">
                                       <span
                                          >Not Halal Business Activity
                                          Percentage =</span
                                       >
                                       <br />
                                       <br />
                                       <span
                                          >( Not Halal Sales & Income + Doubtful
                                          Sales & Income )</span
                                       >
                                       <br />
                                       <span
                                          class="border-t d-block mx-3 mx-lg-5"
                                          >( Total Revenue )</span
                                       >
                                       <br />
                                       <br />
                                       <div
                                          class="d-flex align-items-center justify-content-center"
                                       >
                                          <div
                                             class="d-flex flex-column align-items-center"
                                          >
                                             <span class="">
                                                {{ getConvertedAmount(data?.['notHalalRevenue']) + getConvertedAmount(data?.['questionableRevenue']) | screaningMillionPipe}}</span
                                             >
                                             <span class="border-t">
                                                {{ getConvertedAmount(data?.['totalRevenue']) | screaningMillionPipe }}</span
                                             >
                                          </div>
                                          <div class="mx-2">=</div>
                                          <div>
                                             {{ data?.['notHalal'] + data?.['questionable'] | number: "1.2-2"
                                             }}%
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </ng-template>
                        </ngb-panel>

                        <ngb-panel
                           [cardClass]="
                              getCardClass(
                                 panelStatus.businessPanel2,
                                 data?.revenueBreakdownStatus
                              ) + ' mt-4'
                           "
                           class="calculation-panel-container"
                           id="business-panel2"
                        >
                           <ng-template ngbPanelHeader>
                              <div class="calculation-panel">
                                 <div
                                    class="p-0 bg-transparent"
                                    id="headingOne"
                                 >
                                    <h2 class="mb-0">
                                       <button
                                          ngbPanelToggle
                                          class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3"
                                          type="button"
                                       >
                                          <span
                                             class="accordion-btn-title fw-500 flex-grow-1 text-start"
                                             >Detailed Report</span
                                          >
                                          <i
                                             class="fa-solid fa-chevron-up accordion-icon"
                                          ></i>
                                       </button>
                                    </h2>
                                 </div>
                              </div>
                           </ng-template>
                           <ng-template ngbPanelContent>
                              <div class="row">
                                 <div class="col-12">
                                    <h3 class="gross-heading py-2 py-lg-3">
                                       Gross Sales
                                    </h3>
                                    <div
                                       class="mb-4"
                                       *ngIf="data?.['revenueBreakdownJson.halalAmountInOnes'] && data?.['revenueBreakdownJson.halalPercentage']"
                                    >
                                       <app-income-breakdown
                                          class="mb-4"
                                          [showPercent]="
                                             !businessObj.showPercent
                                          "
                                          [type]="'halal'"
                                          [data]="data"
                                          [rate]="rate"
                                          [usdRate]="usdRate"
                                          [currency]="companyProfile?.currency"
                                          title="Halal Revenue"
                                          [items]="grossIncomeObj.halal.items"
                                          [overallAmount]="data?.['revenueBreakdownJson.halalAmountInOnes']"
                                          [overallPercent]="data?.['revenueBreakdownJson.halalPercentage']"
                                       >
                                       </app-income-breakdown>
                                    </div>
                                    <div
                                       class="mb-4"
                                       *ngIf="data?.['revenueBreakdownJson.questionableAmountInOnes'] && data?.['revenueBreakdownJson.questionablePercentage']"
                                    >
                                       <app-income-breakdown
                                          class="mb-4"
                                          [showPercent]="
                                             !businessObj.showPercent
                                          "
                                          type="doubtful"
                                          [data]="data"
                                          [rate]="rate"
                                          [usdRate]="usdRate"
                                          [currency]="companyProfile?.currency"
                                          title="Doubtful Revenue"
                                          [items]="
                                             grossIncomeObj.doubtful.items
                                          "
                                          [overallAmount]="data?.['revenueBreakdownJson.questionableAmountInOnes']"
                                          [overallPercent]="data?.['revenueBreakdownJson.questionablePercentage']"
                                       >
                                       </app-income-breakdown>
                                    </div>
                                    <div
                                       class="mb-4"
                                       *ngIf="data?.['revenueBreakdownJson.notHalalAmountInOnes'] && data?.['revenueBreakdownJson.notHalalPercentage']"
                                    >
                                       <app-income-breakdown
                                          class="mb-4"
                                          [showPercent]="
                                             !businessObj.showPercent
                                          "
                                          type="not_halal"
                                          [data]="data"
                                          [rate]="rate"
                                          [usdRate]="usdRate"
                                          [currency]="companyProfile?.currency"
                                          title="Not Halal Revenue"
                                          [items]="
                                             grossIncomeObj.notHalal.items
                                          "
                                          [overallAmount]="data?.['revenueBreakdownJson.notHalalAmountInOnes']"
                                          [overallPercent]="data?.['revenueBreakdownJson.notHalalPercentage']"
                                       ></app-income-breakdown>
                                    </div>
                                 </div>
                                 <div
                                    class="col-12"
                                    *ngIf="data?.['interestIncomeJson.halalAmountInOnes'] && data?.['interestIncomeJson.halalPercentage'] || data?.['interestIncomeJson.questionableAmountInOnes'] && data?.['interestIncomeJson.questionablePercentage'] || data?.['interestIncomeJson.notHalalAmountInOnes'] && data?.['interestIncomeJson.notHalalPercentage']"
                                 >
                                    <h3 class="gross-heading py-2 mb- py-lg-3">
                                       Other Income
                                    </h3>
                                    <div
                                       class="mb-4"
                                       *ngIf="data?.['interestIncomeJson.halalAmountInOnes'] && data?.['interestIncomeJson.halalPercentage']"
                                    >
                                       <app-income-breakdown
                                          class="mb-4"
                                          [showPercent]="
                                             !businessObj.showPercent
                                          "
                                          [type]="'halal'"
                                          [data]="data"
                                          [rate]="rate"
                                          [usdRate]="usdRate"
                                          [currency]="companyProfile?.currency"
                                          title="Halal Income"
                                          [items]="otherIncomeObj.halal.items"
                                          [overallAmount]="data?.['interestIncomeJson.halalAmountInOnes']"
                                          [overallPercent]="data?.['interestIncomeJson.halalPercentage']"
                                       >
                                       </app-income-breakdown>
                                    </div>
                                    <div
                                       class="mb-4"
                                       *ngIf="data?.['interestIncomeJson.questionableAmountInOnes'] && data?.['interestIncomeJson.questionablePercentage']"
                                    >
                                       <app-income-breakdown
                                          class="mb-4"
                                          [showPercent]="
                                             !businessObj.showPercent
                                          "
                                          type="doubtful"
                                          [data]="data"
                                          [rate]="rate"
                                          [usdRate]="usdRate"
                                          [currency]="companyProfile?.currency"
                                          title="Doubtful Income"
                                          [items]="
                                             otherIncomeObj.doubtful.items
                                          "
                                          [overallAmount]="data?.['interestIncomeJson.questionableAmountInOnes']"
                                          [overallPercent]="data?.['interestIncomeJson.questionablePercentage']"
                                       >
                                       </app-income-breakdown>
                                    </div>
                                    <div
                                       class="mb-4"
                                       *ngIf="data?.['interestIncomeJson.notHalalAmountInOnes'] && data?.['interestIncomeJson.notHalalPercentage']"
                                    >
                                       <app-income-breakdown
                                          class="mb-4"
                                          [showPercent]="
                                             !businessObj.showPercent
                                          "
                                          type="not_halal"
                                          [data]="data"
                                          [rate]="rate"
                                          [usdRate]="usdRate"
                                          [currency]="companyProfile?.currency"
                                          title="Not Halal Income"
                                          [items]="
                                             otherIncomeObj.notHalal.items
                                          "
                                          [overallAmount]="data?.['interestIncomeJson.notHalalAmountInOnes']"
                                          [overallPercent]="data?.['interestIncomeJson.notHalalPercentage']"
                                       >
                                       </app-income-breakdown>
                                    </div>
                                 </div>
                              </div>
                           </ng-template>
                        </ngb-panel>
                     </ngb-accordion>
                  </div>
               </div>

               <div
                  class="border-success pb-5"
                  id="pills-profile"
                  *ngIf="active == 2"
               >
                  <div class="row">
                     <div class="col-12 col-lg-9">
                        <h3 class="d-flex align-items-center mb-0">
                           <span class="section-title"
                              >Interest-bearing securities and assets</span
                           >
                           <span
                              class="ms-2 rounded-pill ms-3 status-pill-large"
                              [ngClass]="{
                                 'status-pill-large-pass':
                                    data?.securitiesAndAssetsStatus == 'Pass',
                                 'status-pill-large-fail':
                                    data?.securitiesAndAssetsStatus == 'Fail'
                              }"
                              >{{ data?.securitiesAndAssetsStatus }}</span
                           >
                        </h3>
                     </div>
                  </div>

                  <div>
                     <div class="row mt-5 chart-content-wrapper mx-auto">
                        <div class="row">
                           <div class="col-12 col-sm-7 col-md-8 px-0 pie-graph">
                              <div class="revenue-graph mt-4">
                                 <app-debt-chart
                                    [gauge]="data?.securitiesAndAssets"
                                    [status]="data?.securitiesAndAssetsStatus"
                                 >
                                 </app-debt-chart>
                              </div>
                           </div>
                           <div
                              class="col-12 col-sm-5 col-md-4 distribution-container align-self-lg-end pb-lg-4"
                           >
                              <div
                                 class="d-flex align-items-center mb-3 mb-lg-5"
                              >
                                 <div class="distribution-elem compliant"></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title"
                                       >Shariah-compliant</span
                                    >
                                    <span class="percent light"
                                       >Less than 30%</span
                                    >
                                 </div>
                              </div>
                              <div class="d-flex align-items-center">
                                 <div
                                    class="distribution-elem non-compliant"
                                 ></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title"
                                       >Non-shariah compliant</span
                                    >
                                    <span class="percent light"
                                       >Greater than 30%</span
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div>
                           <div class="screening-desc my-4 my-lg-5">
                              Total amount of interest-bearing securities and
                              assets, whether short or long term,
                              <b
                                 >should not exceed 30% of the market
                                 capitalization</b
                              >
                              of the company to be Shariah compliant.
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-4 text-start">
                           <label class="toggle-switch">
                              <input
                                 type="checkbox"
                                 [(ngModel)]="securitiesObj.showPercent"
                              />
                              <span
                                 class="toggle-slider bg-light pill-slider d-flex align-items-center justify-content-between"
                              >
                                 <span
                                    class="toggle-symbols d-flex align-items-center justify-content-end ps-1"
                                    >%</span
                                 >
                                 <span
                                    class="toggle-symbols d-flex align-items-center justify-content-start"
                                 >
                                    <i
                                       class="material-symbols-outlined toggle-bill"
                                       >payments</i
                                    >
                                 </span>
                              </span>
                           </label>
                        </div>
                        <div class="col-8 text-end">
                           <p
                              class="mb-0 currency-text ms-auto d-flex align-items-center justify-content-center"
                           >
                              <i
                                 class="material-symbols-outlined toggle-bill me-2"
                                 >payments</i
                              >
                              <span
                                 >Currency in
                                 {{ companyProfile?.currency }}</span
                              >
                           </p>
                        </div>
                     </div>
                     <div class="mt-4">
                        <ngb-accordion
                           #acc="ngbAccordion"
                           class="calculation-accordion bg-primary"
                           (shown)="onPanelShown($event)"
                           (hidden)="onPanelHidden($event)"
                        >
                           <ngb-panel
                              [cardClass]="
                                 getCardClass(
                                    panelStatus.interestCalculationPanel,
                                    data?.securitiesAndAssetsStatus
                                 )
                              "
                              class="calculation-panel-container"
                              id="interest-calculation-panel"
                           >
                              <ng-template ngbPanelHeader>
                                 <div class="calculation-panel">
                                    <div
                                       class="p-0 bg-transparent"
                                       id="headingOne"
                                    >
                                       <h2 class="mb-0">
                                          <button
                                             ngbPanelToggle
                                             class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3"
                                             type="button"
                                          >
                                             <span
                                                class="accordion-btn-title fw-500 flex-grow-1 text-start"
                                                >Calculation</span
                                             >
                                             <i
                                                class="fa-solid fa-chevron-up accordion-icon"
                                             ></i>
                                          </button>
                                       </h2>
                                    </div>
                                 </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                 <div class="row">
                                    <div class="col-md-6">
                                       <div
                                          *ngIf="data?.['securitiesAndAssetsJson.shortTermJson.name']"
                                          class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mb-2"
                                       >
                                          <p
                                             class="mb-0 calculation-line-item-title"
                                          >
                                             {{ data?.['securitiesAndAssetsJson.shortTermJson.name'] }}
                                          </p>
                                          <span
                                             class="fw-500 calc-revenue calculation-line-item-val"
                                          >
                                             {{ getSecuritiesAndAssetsDebtValueOrPercent(data?.['securitiesAndAssetsJson.shortTermJson.nonIslamicAmountInOnes'],data?.['securitiesAndAssetsJson.shortTermJson.retio']) }}
                                          </span>
                                       </div>

                                       <div
                                          *ngIf="data?.['securitiesAndAssetsJson.longTermJson.name']"
                                          class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between"
                                       >
                                          <p
                                             class="mb-0 calculation-line-item-title"
                                          >
                                             {{ data?.['securitiesAndAssetsJson.longTermJson.name'] }}
                                          </p>
                                          <span
                                             class="fw-500 calc-revenue calculation-line-item-val"
                                          >
                                             {{ getSecuritiesAndAssetsDebtValueOrPercent(data?.['securitiesAndAssetsJson.longTermJson.nonIslamicAmountInOnes'],data?.['securitiesAndAssetsJson.longTermJson.retio']) }}
                                          </span>
                                       </div>

                                       <div
                                          class="d-flex p-2 mt-2 rounded align-items-center justify-content-between calculation-line-item-container-total"
                                       >
                                          <p
                                             class="mb-0 calculation-line-item-title"
                                          >
                                             Interest-bearing securities and
                                             assets
                                          </p>
                                          <span
                                             class="fw-500 calc-revenue calculation-line-item-val"
                                             >{{
                                                getTotalSecuritiesAndAssets()
                                             }}
                                          </span>
                                       </div>
                                    </div>
                                    <div class="col-md-6 mt-3 mt-md-0">
                                       <div
                                          class="calc-percent text-center py-4"
                                       >
                                          <span
                                             >Interest-bearing securities and
                                             assets percentage =</span
                                          >
                                          <br />
                                          <br />
                                          <span
                                             >( Interest-bearing securities and
                                             assets )</span
                                          >
                                          <br />
                                          <span
                                             class="border-t d-inline-block mx-3"
                                             >( Trailing 36-month average market
                                             capitalization )</span
                                          >
                                          <br />
                                          <br />
                                          <div
                                             class="d-flex align-items-center justify-content-center"
                                          >
                                             <div
                                                class="d-flex flex-column align-items-center"
                                             >
                                                <span class="">
                                                   {{ getConvertedAmount((data?.['securitiesAndAssetsJson.totalNonIslamicAmount'] ?? 0) * data?.units) | screaningMillionPipe }}
                                                </span>
                                                <span class="border-t">{{
                                                   getConvertedAmount(
                                                      data?.trailing36MonAvrCap
                                                   ) | screaningMillionPipe
                                                }}</span>
                                             </div>
                                             <div class="mx-2">=</div>
                                             <div>
                                                {{
                                                   data?.securitiesAndAssets
                                                      | number : "1.2-2"
                                                }}%
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </ng-template>
                           </ngb-panel>
                        </ngb-accordion>
                     </div>
                  </div>
               </div>

               <div class="border-danger pb-5" *ngIf="active == 3">
                  <div class="row">
                     <div class="col-lg-9">
                        <h3 class="d-flex align-items-center mb-0">
                           <span class="section-title"
                              >Interest-bearing debt</span
                           >
                           <span
                              class="ms-2 rounded-pill ms-3 status-pill-large"
                              [ngClass]="{
                                 'status-pill-large-pass':
                                    data?.debtStatus == 'Pass',
                                 'status-pill-large-fail':
                                    data?.debtStatus == 'Fail'
                              }"
                              >{{ data?.debtStatus }}</span
                           >
                        </h3>
                     </div>
                  </div>

                  <div>
                     <div class="row mt-5 chart-content-wrapper mx-auto">
                        <div class="col-12 col-sm-7 col-md-8 px-0 pie-graph">
                           <div class="revenue-graph mt-4">
                              <app-debt-chart
                                 [gauge]="data?.debt"
                                 [status]="data?.debtStatus"
                              ></app-debt-chart>
                           </div>
                        </div>
                        <div
                           class="col-12 col-sm-5 col-md-4 distribution-container align-self-lg-end pb-lg-4"
                        >
                           <div class="d-flex align-items-center mb-3 mb-lg-5">
                              <div class="distribution-elem compliant"></div>
                              <div class="d-flex flex-column ms-2">
                                 <span class="legend-title"
                                    >Shariah compliant</span
                                 >
                                 <span class="percent light"
                                    >Less than 30%</span
                                 >
                              </div>
                           </div>
                           <div class="d-flex align-items-center">
                              <div
                                 class="distribution-elem non-compliant"
                              ></div>
                              <div class="d-flex flex-column ms-2">
                                 <span class="legend-title"
                                    >Non-shariah compliant</span
                                 >
                                 <span class="percent light"
                                    >Greater than 30%</span
                                 >
                              </div>
                           </div>
                        </div>
                        <div>
                           <div class="screening-desc my-4 my-lg-5">
                              Interest-bearing debt, whether long-term or
                              short-term debt
                              <b
                                 >should not exceed 30% of the market
                                 capitalization</b
                              >
                              of the company.
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-4 text-start">
                           <label class="toggle-switch">
                              <input
                                 type="checkbox"
                                 [(ngModel)]="debtObj.showPercent"
                              />
                              <span
                                 class="toggle-slider bg-light pill-slider d-flex align-items-center justify-content-between"
                              >
                                 <span
                                    class="toggle-symbols d-flex align-items-center justify-content-end ps-1"
                                    >%</span
                                 >
                                 <span
                                    class="toggle-symbols d-flex align-items-center justify-content-start"
                                    ><i
                                       class="material-symbols-outlined toggle-bill"
                                       >payments</i
                                    ></span
                                 >
                              </span>
                           </label>
                        </div>
                        <div class="col-8 text-end">
                           <p
                              class="mb-0 currency-text ms-auto d-flex align-items-center justify-content-center"
                           >
                              <i
                                 class="material-symbols-outlined toggle-bill me-2"
                                 >payments</i
                              >
                              <span
                                 >Currency in
                                 {{ companyProfile?.currency }}</span
                              >
                           </p>
                        </div>
                     </div>
                     <div class="mt-4">
                        <ngb-accordion
                           #acc="ngbAccordion"
                           class="calculation-accordion"
                           (shown)="onPanelShown($event)"
                           (hidden)="onPanelHidden($event)"
                        >
                           <ngb-panel
                              [cardClass]="
                                 getCardClass(
                                    panelStatus.debtCalculationPanel,
                                    data?.debtStatus
                                 )
                              "
                              class="calculation-panel-container"
                              id="debt-calculation-panel"
                           >
                              <ng-template ngbPanelHeader>
                                 <div class="calculation-panel">
                                    <div
                                       class="p-0 bg-transparent"
                                       id="headingOne"
                                    >
                                       <h2 class="mb-0">
                                          <button
                                             ngbPanelToggle
                                             class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3"
                                             type="button"
                                          >
                                             <span
                                                class="accordion-btn-title fw-500 flex-grow-1 text-start"
                                                >Calculation</span
                                             >
                                             <i
                                                class="fa-solid fa-chevron-up accordion-icon"
                                             ></i>
                                          </button>
                                       </h2>
                                    </div>
                                 </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                 <div class="row">
                                    <div class="col-md-6">
                                       <div
                                          *ngIf="data?.['interestBearingDebtJson.shortTermJson.name']"
                                          class="d-flex bg-light p-2 pr-lg-3 rounded align-items-center justify-content-between mb-2"
                                       >
                                          <div class="py-2">
                                             <h4
                                                class="mb-0 calc-heading calculation-line-item-title"
                                             >
                                                {{ data?.['interestBearingDebtJson.shortTermJson.name'] }}
                                             </h4>
                                             <p
                                                class="mb-0 calculation-line-item-subtitle"
                                             ></p>
                                          </div>
                                          <span
                                             class="fw-500 calc-revenue calculation-line-item-val"
                                             >{{ getInterestDebtValueOrPercent(data?.['interestBearingDebtJson.shortTermJson.nonIslamicAmountInOnes'],data?.['interestBearingDebtJson.shortTermJson.retio']) }}</span
                                          >
                                       </div>

                                       <div
                                          *ngIf="data?.['interestBearingDebtJson.longTermJson.name']"
                                          class="d-flex bg-light p-2 pr-lg-3 rounded align-items-center justify-content-between"
                                       >
                                          <div class="py-2">
                                             <h4
                                                class="mb-0 calc-heading calculation-line-item-title"
                                             >
                                                {{ data?.['interestBearingDebtJson.longTermJson.name'] }}
                                             </h4>
                                             <p
                                                class="mb-0 calculation-line-item-subtitle"
                                             ></p>
                                          </div>
                                          <span
                                             class="fw-500 calc-revenue calculation-line-item-val"
                                             >{{ getInterestDebtValueOrPercent(data?.['interestBearingDebtJson.longTermJson.nonIslamicAmountInOnes'],data?.['interestBearingDebtJson.longTermJson.retio']) }}</span
                                          >
                                       </div>

                                       <div
                                          class="d-flex calculation-line-item-container-total p-2 pr-lg-3 mt-2 rounded align-items-center justify-content-between"
                                       >
                                          <h4
                                             class="mb-0 calc-heading py-2 calculation-line-item-title"
                                          >
                                             Total Interest-bearing debt
                                          </h4>
                                          <span
                                             class="fw-500 calc-revenue calculation-line-item-val"
                                             >{{
                                                getTotalInterestBearingDebt()
                                             }}</span
                                          >
                                       </div>
                                    </div>

                                    <div class="col-md-6 mt-3 mt-md-0">
                                       <div
                                          class="calc-percent text-center py-4"
                                       >
                                          <span
                                             >Interest-bearing debt percentage
                                             =</span
                                          >
                                          <br />
                                          <br />
                                          <span
                                             >( Total interest-bearing debt
                                             )</span
                                          >
                                          <br />
                                          <span
                                             class="border-t d-inline-block mx-3"
                                             >( Trailing 36-month average market
                                             capitalization )</span
                                          >
                                          <br />
                                          <br />
                                          <div
                                             class="d-flex align-items-center justify-content-center"
                                          >
                                             <div
                                                class="d-flex flex-column align-items-center"
                                             >
                                                <span class="">
                                                   {{ getConvertedAmount((data?.['interestBearingDebtJson.totalNonIslamicAmount'] ?? 0) * data?.units) | screaningMillionPipe }}</span
                                                >
                                                <span class="border-t">
                                                   {{
                                                      getConvertedAmount(
                                                         data?.trailing36MonAvrCap
                                                      ) | screaningMillionPipe
                                                   }}</span
                                                >
                                             </div>
                                             <div class="mx-2">=</div>
                                             <div>
                                                {{
                                                   data?.debt
                                                      | number : "1.2-2"
                                                }}%
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </ng-template>
                           </ngb-panel>
                        </ngb-accordion>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</div>
