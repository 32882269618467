import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-team-modal',
  templateUrl: './company-team-modal.component.html',
  styleUrls: ['./company-team-modal.component.scss']
})
export class CompanyTeamModalComponent implements OnInit {

  @Input() name:string = '';
  @Input() title:string = ''; 
  @Input() discription:string = ''; 
  @Input() image:string = ''; 
  @Output() closeTeamModal: EventEmitter<void> = new EventEmitter();

  constructor(
    // private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closeTeamModal.emit()
  }

}
