import {
   Component,
   OnInit,
   ElementRef,
   HostListener,
   Input,
   OnDestroy,
} from '@angular/core';
import {
   trigger,
   state,
   style,
   transition,
   animate,
} from '@angular/animations';
import {
   allAccounts,
   allPortfolioLoading,
} from 'src/app/stores/portfolio/portfolio.selectors';
import { selectedAccountDetails } from 'src/app/stores/portfolio/portfolio.selectors';
import { AccountDetailsData } from '../../models/account-details-response.model';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { PortfolioService } from '../../services/portfolio.service';
import {
   loadAccounts,
   setSelectedAccount,
} from 'src/app/stores/portfolio/portfolio.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { PortfolioSettingsComponent } from 'src/app/features/portfolio/components/portfolio-settings/portfolio-settings.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnlinkConfirmationComponent } from 'src/app/features/portfolio/components/unlink-confirmation/unlink-confirmation.component';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MyPortfolioService } from 'src/app/features/portfolio/services/my-portfolio.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { PortfolioService as SharedPortfolioService } from 'src/app/shared/services/portfolio.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { ToastrService } from 'ngx-toastr';
import { PortfolioService as PortfolioServiceShared } from 'src/app/shared/services/portfolio.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-portfolio-header',
   templateUrl: './portfolio-header.component.html',
   styleUrls: ['./portfolio-header.component.scss'],
   animations: [
      trigger('slideToggleAnimation', [
         state(
            'void',
            style({
               transform: 'translateY(-10%)',
               opacity: 0,
               marginTop: '0',
            })
         ),
         state(
            '*',
            style({
               transform: 'translateY(0)',
               opacity: 1,
               marginTop: '10px',
            })
         ),
         transition('void <=> *', [animate('300ms ease-in-out')]),
      ]),
   ],
})
export class PortfolioHeaderComponent implements OnInit, OnDestroy {
   selectedAccount$ = this.store.select(selectedAccountDetails);
   accounts$ = this.store.select(allAccounts);
   accountDetails: AccountDetailsData | undefined;
   loading$ = this.store.select(allPortfolioLoading);
   accountId: string | undefined;
   portfolioSettingsRef: any;
   accountUnlinkRef: any;
   account: PortfolioAccount | undefined;
   syncLoader: boolean = false;
   isTransactionAvailable: boolean | undefined = false;
   componentLoading = false;
   maxManualPortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;
   reconnectLoader: boolean = false;
   @Input() hideAddPortfolio: boolean = false;
   @Input() hideSettings: boolean = false;
   @Input() hideSync: boolean = false;
   @Input() hideValue: boolean = false;
   @Input() accountChangeRedirect: boolean = true;

   private destroy$ = new Subject<boolean>();
   ngOnInit(): void {
      this.selectedAccount$
         .pipe(takeUntil(this.destroy$))
         .subscribe((account) => {
            if (account) {
               this.accountId = account.id;
               this.account = account;
               this.componentLoading = true;
               this.portfolioService
                  .getPortfolioAccountDetails(account.id)
                  .pipe(take(1))
                  .subscribe({
                     next: (details) => {
                        this.accountDetails = details.data;
                     },
                     error: (error) => {
                        console.log('error', error);
                        this.componentLoading = false;
                     },
                     complete: () => {
                        this.componentLoading = false;
                     },
                  });
            }
         });
      this.getAccountsCount();
      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .pipe(take(1))
         .subscribe((permissions) => {
            // this.isPremiumUser = permission;
            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;
         });
      const params = this.route.snapshot.queryParams;
      if (params['open_connect'] === '1') {
         this.router.navigate([], {
            queryParams: { open_connect: null },
            queryParamsHandling: 'merge',
            replaceUrl: true,
         });
         this.portfolioService.openConnectionModal();
      }
   }

   ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.complete();
   }

   showGoToAnalytics() {
      return !this.router.url.includes('portfolio-dashboard');
   }

   getAccountsCount() {
      if (!this.authService.getUserId()) return;

      this.sharedPortfolioService
         .getAccountCounts()
         .pipe(take(1))
         .subscribe((data) => {
            this.currentManualPortfoliosCount = data.manual_connected_count;
         });
   }

   addManually() {
      if (!this.authService.getUserId()) {
         // this.authService.openAuthPopup();
         this.accessControlService.accessDenied();
         return;
      }
      if (this.currentManualPortfoliosCount < this.maxManualPortfoliosCount) {
         this.sharedPortfolioService.closeSupportedBrokeragesModal();
         this.myPortfolio.openCreateManualPortfolioPopup();
      } else {
         this.sharedPortfolioService.closeSupportedBrokeragesModal();
         this.sharedService.openUpgradePopup('portfolio');
      }
      this.toggleDropdown();
   }

   openConnectionModal() {
      if (!this.authService.getUserId()) {
         this.accessControlService.accessDenied();
         return;
      }
      if (!this.router.url.includes('portfolio-dashboard')) {
         this.router.navigate(['/portfolio-dashboard'], {
            queryParams: { open_connect: '1' },
            queryParamsHandling: 'merge',
         });
         return;
      } else {
         this.portfolioService.openConnectionModal();
      }
   }

   openPortfolioSetting() {
      this.portfolioSettingsRef = this.modalService.open(
         PortfolioSettingsComponent,
         {
            centered: true,
            windowClass: 'portfolio-setting-popup',
            scrollable: true,
         }
      );
      this.portfolioSettingsRef.componentInstance.selectedAccount =
         this.account;
      this.portfolioSettingsRef.componentInstance.selectedPortfolioDetails =
         this.accountDetails;
      this.portfolioSettingsRef.componentInstance.isTransactionAvailable =
         this.account?.is_transaction_fetched;
      this.portfolioSettingsRef.componentInstance.syncPortfolioLoader =
         this.syncLoader;
      this.portfolioSettingsRef.componentInstance.refreshData = () => {
         this.store.dispatch(loadAccounts());
      };

      this.portfolioSettingsRef.componentInstance.closeSettingPopup = () => {
         this.closePortfolioSettingPopup();
      };

      this.portfolioSettingsRef.componentInstance.openUnlinkConfirmation =
         () => {
            this.openUnlinkConfirmation();
         };
   }

   closePortfolioSettingPopup() {
      if (this.portfolioSettingsRef) {
         this.portfolioSettingsRef.dismiss();
      }
   }

   openUnlinkConfirmation() {
      this.accountUnlinkRef = this.modalService.open(
         UnlinkConfirmationComponent,
         {
            centered: true,
            windowClass: 'portfolio-setting-popup',
            scrollable: true,
         }
      );
      this.accountUnlinkRef.componentInstance.selectedAccount = this.account;

      this.accountUnlinkRef.componentInstance.closeUnlinkConfirmationModal =
         () => {
            this.closeUnlinkConfirmation();
         };

      this.accountUnlinkRef.componentInstance.unlinkSuccess = () => {
         this.accounts$.pipe(take(1)).subscribe((accounts) => {
            const firstAccount = accounts.find(
               (account) => account.id !== this.account?.id
            );
            if (firstAccount) {
               this.router.navigate(['/portfolio-dashboard'], {
                  queryParams: { accountId: firstAccount.id },
               });
            }
            this.store.dispatch(loadAccounts());
         });
      };
   }

   closeUnlinkConfirmation() {
      if (this.accountUnlinkRef) {
         this.accountUnlinkRef.dismiss();
      }
   }

   dropdownOpen = false;

   constructor(
      private eRef: ElementRef,
      private store: Store,
      private portfolioService: PortfolioService,
      private router: Router,
      private modalService: NgbModal,
      private authService: AuthService,
      private myPortfolio: MyPortfolioService,
      private sharedService: SharedService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private sharedPortfolioService: SharedPortfolioService,
      private analyticsUtils: AnalyticsUtils,
      private toaster: ToastrService,
      private portfolioServiceShared: PortfolioServiceShared,
      private route: ActivatedRoute,
      public accessControlService: AccessControlService
   ) {}

   toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
   }

   @HostListener('document:click', ['$event'])
   handleClickOutside(event: Event) {
      if (!this.eRef.nativeElement.contains(event.target)) {
         this.dropdownOpen = false;
      }
   }

   selectAccount(accountId: string) {
      if (accountId === this.accountId) {
         this.toggleDropdown();
         return;
      }
      if (this.accountChangeRedirect) {
         this.router.navigate(['/portfolio-dashboard'], {
            queryParams: { accountId },
         });
      } else {
         this.store.dispatch(setSelectedAccount({ accountId }));
      }
      this.toggleDropdown();
   }

   async syncCurrentPortfolio() {
      if (this.account?.is_sample) {
         this.toaster.info('Sample account cannot be synced');
         return;
      }
      if (this.syncLoader) return;
      this.analyticsUtils.portfolio_sync_tapped();
      this.syncLoader = true;

      let resp: any = '';

      try {
         if (!this.accountDetails?.id) {
            throw new Error('Account ID is missing');
         }

         if (!this.accountDetails.is_manual) {
            resp = await lastValueFrom(
               this.portfolioServiceShared.syncPortfolio(
                  this.accountDetails.id.toString()
               )
            );
         } else {
            resp = await lastValueFrom(
               this.portfolioServiceShared.syncManualPortfolio(
                  this.accountDetails.id.toString()
               )
            );
         }

         if (resp?.status && resp?.status_code === 'SUCCESS') {
            this.toaster.success(resp.message);
            this.portfolioServiceShared.updateDate(resp.data.last_sync_at);
            this.store.dispatch(loadAccounts());
         } else {
            this.toaster.error('Sync failed');
         }
      } catch (error) {
         this.toaster.error('Sync failed');
         console.error('Sync Error:', error);
      } finally {
         this.syncLoader = false;
      }
   }

   reconnectBrokerage() {
      this.reconnectLoader = true;
      this.portfolioServiceShared
         .getReconnectUrl(this.accountDetails?.id, true)
         .subscribe((resp: any) => {
            if (resp.status) {
               this.reconnectLoader = false;
               this.portfolioServiceShared.openConnectBrokerageIframeModal(
                  resp.data.redirect_url
               );
            }
            this.reconnectLoader = false;
         }),
         (error: HttpErrorResponse) => {
            console.log(error);
            this.reconnectLoader = false;
         };
   }

   // reconnectBrokenBrokerage() {
   //    this.reconnectLoader = true;
   //    this.portfolioService
   //       .getReconnectUrl(this.selectedPortfolio?.brokerage_id, true)
   //       .subscribe((resp: any) => {
   //          if (resp.status) {
   //             this.reconnectLoader = false;
   //             this.portfolioService.openConnectBrokerageIframeModal(
   //                resp.data.redirect_url
   //             );
   //          }
   //          this.reconnectLoader = false;
   //       }),
   //       (error: HttpErrorResponse) => {
   //          console.log(error);
   //          this.reconnectLoader = false;
   //       };
   // }
}
