import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'currencyMilion' })

export class currencyMilionPipe extends CurrencyPipe implements PipeTransform {

	transform(value: any, currancy: string, marketCap?: boolean): any {
		let symbol;
		switch (currancy) {
			case 'MYR':
				symbol = 'RM';
				break;
			case 'GBP':
				symbol = '£';
				break;
			case 'SGD':
				symbol = 'SGD';
				break;
			case 'CAD':
				symbol = 'CAD';
				break;
			case 'IDR':
				symbol = 'Rp';
				break;
			case 'EUR':
				symbol = '€';
				break;
			case 'USD':
				symbol = '$';
				break;
			case 'QAR':
				symbol = 'QR';
				break;
			case 'BHD':
				symbol = 'BD';
				break;
			case 'INR':
				symbol = '₹';
				break;
			// case 'SAR':
			// 	symbol = 'Riyal';
			// 	break;
			// case 'AED':
			// 	symbol = 'Dirham';
			// 	break;
			default:
				symbol = currancy;
				break;
		}

    value = +value

    if (marketCap) {
      value = value * 1000000
    }
    if (value < 0) {
      value = '-' + symbol + (value * -1);
    }
    if (value < 1000) {
      // value = super.transform(value);
      return symbol +  value
    }
	if (value >= 1000 && value < 999999) {
		return symbol + (value / 1000).toFixed(2) + 'K'
	  }

    if (value >= 1000000 && value < 999999999) {
      return symbol + (value / 1000000).toFixed(2) + 'M'
    }

    if (value >= 1000000000 && value < 999999999999) {
      return symbol + (value / 1000000000).toFixed(2) + 'B'
    }

    if (value >= 1000000000000) {
      return symbol + (value / 1000000000000).toFixed(2) + 'T'
    }
	}

}

// export class currencyMilionPipe implements PipeTransform {
//   transform(value: any, marketCap?: boolean ,): any {
//
//   }
// }
