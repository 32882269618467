import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Currency } from 'src/app/models/zakat.model';

@Component({
  selector: 'app-cash-details',
  templateUrl: './cash-details.component.html',
  styleUrls: ['./cash-details.component.scss']
})
export class CashDetailsComponent implements OnInit {
  form!: FormGroup;
  selectedCurrency!: string | null

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.selectedCurrency = localStorage.getItem('selectedCurrency');
    if(this.selectedCurrency) {
      this.selectedCurrency = this.selectedCurrency?.replace(/^"|"$/g, '')
    }
    this.form = this.rootFormGroup.control as FormGroup;
  }

}
