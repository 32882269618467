import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EtfDetailsService {

  constructor() { }

  getNoHoldingsDisclaimerList() {
    return [
      'HLAL', 'UMMA', 'SPSK', 'SPUS', 'SPRE', 'SPTE', 'SPWO', 'ISWD.L', 'ISDE.L', 'ISUS.L', 'IGDA.SW', 'HIEU.L','HIUS.L',
      'AMAL.L', 'HIND.IR', 'HIJP.L', 'HIWO.L', 'HIEM.L', 'WSHR.NE', '0821EA.KL', '0824EA.KL', '0825EA.KL', '0827EA.KL',
      '0838EA.KL', '0828EA.KL', 'CHSASHIN.AD', 'CHHKSHIN.AD', 'CHAESH.AD', 'CHTRSHIN.AD', 'CHUSSHINV.AD', 'CHINDSHI.AD',
      'CHUSSHG.AD', 'CHKWSHIN.AD', 'CHAESHIN.AD', 'XIJI.JK', 'XSSI.JK', 'STXSHA.JO', 'STXWIS.JO'
    ]
  }
}
