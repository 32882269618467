import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { createChart } from 'lightweight-charts';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { TypesenseService } from '../../services/typesense.service';
// import { WebsoketService } from '../../services/websoket.service';

@Component({
  selector: 'app-trading-chart',
  templateUrl: './trading-chart.component.html',
  styleUrls: ['./trading-chart.component.scss']
})

export class TradingChartComponent implements OnInit {
  private subscriptions: Subscription[] = [];

  public chartsLoader: boolean = false;

  @Output() hasData: EventEmitter<any> = new EventEmitter()
  // @Output() getPrice: EventEmitter<any> = new EventEmitter()
  public currentPrice: number = 0;

  public selectedTab: string = '';
  public _ticker: string = ''

  public lastDate: Date = new Date();

  public lastTime: Date = new Date();
  public areaSeries: any;
  public chart: any;
  constructor(
    private renderer: Renderer2,
    private apiService: ApiService,
    // private ws: WebsoketService,
    private datePipe: DatePipe,
    private typesenseService: TypesenseService
  ) { }


  @Input() set ticker(value: string) {
    if (value) {
      this._ticker = value
      this.stockCandles(this.selectedTab)
    }
  }

  @Input() currency: any
  @Output() getPrice: EventEmitter<any> = new EventEmitter()
  @ViewChild('tradingChart', { read: ElementRef })
  tradingChartElement!: ElementRef;


  ngOnInit(): void {

  }

  tradingChart(data: any[]) {
    let chartOptions = {
      plotAreaBorderWidth: 0,
      margin: 0,
      grid:
      {
        vertLines: { visible: false },
        horzLines: { visible: false }
      },
      localization: {
        locale: 'en'
      },
      handleScroll: false,
      handleScale: {
        mouseWheel: false
      }
    };
    this.chart = createChart(this.renderer.selectRootElement('.trading__chart'), chartOptions);
    this.areaSeries = this.chart.addAreaSeries({ lineColor: '#39a939', topColor: '#39a939', bottomColor: 'rgba(57, 169, 57, 0.28)' });

    this.areaSeries.setData(data);

    this.chart.timeScale().applyOptions({
      rightOffset: -0.6,
      fixLeftEdge: true,
      fixRightEdge: true
    });
    // this.chart.timeScale().fitContent();

  }

  stockCandles(resolution: string) {
    this.selectedTab = resolution
    let data = {
      // 'customFilter': {
        'symbol': this._ticker,
        'resolution': resolution
      // }
    }

    // this.loader.show();
    this.chartsLoader = true
    try{
      let subscription = this.apiService.stockCandles(data).subscribe(
        (data: any) => {
          this.hasData.emit(true)

          let returnData: any[] = [];

          if (data && data.length > 0) {
            data.forEach((element: any, index: number) => {
              if (index == 0) {
                this.getPrice.emit(element)
               //  console.log(element)
              }

              returnData.push({ time: this.datePipe.transform(element.time, 'yyyy-MM-dd'), value: element.close })
              if (index == data.length - 1) {

                this.lastTime = new Date(element.time)
                this.currentPrice = element.close
                this.lastDate = new Date(element.time)
              }
            })

          }
          // switch (resolution) {
          //   case '1D':
          //     // this.linerChartOptions.scales.xAxes[0].time.unit = 'hour';
          //     // this.subscribeStokToWS('5')
          //     break
          //   case '1W':
          //     // this.linerChartOptions.scales.xAxes[0].time.unit = 'day';
          //     // this.subscribeStokToWS('5')
          //     break
          //   case '1M':
          //     // this.linerChartOptions.scales.xAxes[0].time.unit = 'day';
          //     // this.subscribeStokToWS('5')
          //     break
          //   case '6M':
          //     // this.linerChartOptions.scales.xAxes[0].time.unit = 'month';
          //     // this.subscribeStokToWS('5')
          //     break
          //   case 'YTD':
          //     // this.linerChartOptions.scales.xAxes[0].time.unit = 'month';
          //     // this.subscribeStokToWS('5')
          //     break
          //   default:
          //     // this.linerChartOptions.scales.xAxes[0].time.unit = 'quarter';
          //     // this.subscribeStokToWS('5')
          //     break
          // }
          this.tradingChart(returnData);
          // this.subscribeToWSData();
          this.getPriceData(this._ticker)
          this.chartsLoader = false
          // this.hasData.emit(true)
          // this.loader.hide();
        },
        (error: HttpErrorResponse) => {
          // this.loader.hide();
          this.hasData.emit(false)
        }
      )
      this.subscriptions.push(subscription)
    }
    catch(e){
      this.hasData.emit(false)
    }



  }

  getPriceData(symbols:string){
    this.typesenseService.getPrices([symbols]).then((res)=>{
      if([symbols].length > 0) {
        for (const key in res){
          let priceValue = res[key]
          this.currentPrice = priceValue.close
          this.lastDate = new Date(priceValue.datetime)
          let dateTime: Date = new Date(priceValue.datetime)
          if (dateTime.getDate() != this.lastTime.getDate()) {
            this.areaSeries.update({ time: this.datePipe.transform(priceValue.datetime, 'yyyy-MM-dd'), value: priceValue.close })
          }
        }
      }
    })
  }

  // subscribeToWSData() {
  //   let subscription = this.ws.getDataFromWS.subscribe(
  //     (msg: any) => {
  //       if (msg) {

  //         this.currentPrice = msg.close
  //         this.lastDate = new Date(msg.datetime)
  //         let dateTime: Date = new Date(msg.datetime)
  //         if (dateTime.getDate() != this.lastTime.getDate()) {
  //           this.areaSeries.update({ time: this.datePipe.transform(msg.datetime, 'yyyy-MM-dd'), value: msg.close })
  //         }
  //       }
  //     }
  //   )
  //   this.subscriptions.push(subscription)
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.chart.resize(this.tradingChartElement.nativeElement.clientWidth, 300)

  }

}
