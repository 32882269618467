import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-plaid-card',
  templateUrl: './plaid-card.component.html',
  styleUrls: ['./plaid-card.component.scss']
})
export class PlaidCardComponent implements OnInit {
   @Input() isInsideBrokeragesPopup: boolean = false
   
   constructor() { }

   ngOnInit(): void {
   }

}
