import { Component, OnInit } from '@angular/core';
import { PortfolioService } from '../../services/portfolio.service';

@Component({
  selector: 'app-view-supported-institutions-card',
  templateUrl: './view-supported-institutions-card.component.html',
  styleUrls: ['./view-supported-institutions-card.component.scss']
})
export class ViewSupportedInstitutionsCardComponent implements OnInit {

  constructor(private portfolioService: PortfolioService) { }

  ngOnInit(): void {
  }

  openBrokeragesModal() {
    this.portfolioService.openSupportedBrokeragesModal(true)
  }

}
