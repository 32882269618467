<div class="radios-container">
   <div class="form-check m-0 px-3 px-lg-4 py-3 d-flex align-items-center single-radio" *ngFor="let account of accounts">
      <input type="radio" class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 me-lg-4 shadow-none flex-shrink-0" [value]="account" [id]="account.id" [(ngModel)]="selectedOption" (change)="onOptionChange(account)"/>
      <label class="form-check-label d-flex align-items-center gap-3 w-100 checkbox-label" [for]="account.id">
         <img src="{{account.brokerage_logo}}" class="rounded-2 bg-light radio-image" alt="{{account.brokerage_name}}">
         <div class="account-text">
            <span class="d-block account-name mb-2 fw-bold lh-1">{{account.name}}</span>
            <span class="d-block lh-1 account-number">{{account.number}}</span>
         </div>
      </label>
   </div>
</div>
