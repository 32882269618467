import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FirestoreService } from 'src/app/shared/services/firestore.service';
import { LoaderServices } from 'src/app/shared/services/loader.service';


@Component({
  selector: 'upgrade-now',
  templateUrl: './upgrade-now.component.html',
  styleUrls: ['./upgrade-now.component.scss']
})

export class UpgradeNowComponent implements OnInit {

  @Output() close:EventEmitter<void> = new EventEmitter()
  
  constructor(
    private apiservise: ApiService,
    private auth: AuthService,
    private firestoreService: FirestoreService,
    private toaster: ToastrService,
    private loader: LoaderServices
  ) {
    
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  closeMe(){
    this.close.emit();
  }

}
