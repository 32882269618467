<button class="d-flex align-items-center justify-content-center gap-3 social-login-btn" (click)="signInWithApple()" [ngClass]="appleBtnStyling">
   <img src="/assets/icons/apple-icon.svg" alt="Apple logo" class="img-fluid">
   <span class="text-start">Continue with Apple</span>
</button>






<!-- <div class="google__main mt-3">
   <button class="btn" type="button" (click)="signInWithApple()">
       <div class="image-wrapper bg-white d-flex align-items-center justify-content-center rounded-circle">
         <img src="../../../assets/images/apple-logo.svg" alt="" class="d-inline-block">
       </div>
       <span>Continue with Apple</span>
   </button>
</div> -->
