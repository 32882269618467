<section class="content-padding pb-0">
   <div>
      <div class="musaffa-growth-container mx-auto container d-lg-none">
         <h2 class="text-center section-main-heading fw-600 mb-0">Musaffa’s Growth</h2>
         <h3 class="history-heading fw-600 text-center text-lg-start mt-2 mb-3">
            Company History <br class="d-none d-lg-block"> From 2020 to 2024
         </h3>
         <div class="overflow-hidden">
            <img src="/assets/images/about-us/office-building.webp" class="img-fluid office-building-wrapper" alt="">
         </div>
         <div class="musaffa-info-cards-container mt-2">
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center" data-aos="fade-up">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2020</div>
               <p class="mb-0 musaffa-info-para">
                  Musaffa’s story begins in NYC with 3 passionate founders
               </p>
            </article>
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center" data-aos="fade-up">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2021</div>
               <p class="mb-0 musaffa-info-para">
                  <span class="d-block mb-1">Launched Web Platform with 12 Countries Covered</span> 
                  <span>New Office in Tashkent</span>
               </p>
            </article>
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center" data-aos="fade-up">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2022</div>
               <p class="mb-0 musaffa-info-para">
                  Launched Mobile Apps with 50,000+ Stocks Covered
               </p>
            </article>
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center" data-aos="fade-up">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2022</div>
               <p class="mb-0 musaffa-info-para">
                  <span class="d-block mb-1">72K+ Users Globally</span> 
                  <span>6 B2B Partnerships</span>
               </p>
            </article>
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center" data-aos="fade-up">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2023</div>
               <p class="mb-0 musaffa-info-para">
                  <span class="d-block mb-1">New Office in New York</span> <span>Launched world's first Shariah-compliant ETF Screener</span>
               </p>
            </article>
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center" data-aos="fade-up">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2023</div>
               <p class="mb-0 musaffa-info-para">
                  <span class="d-block mb-1">314K+ Users Globally</span> 
                  <span>The team expanded to 50+ members</span>
               </p>
            </article>
            <article class="musaffa-info-card d-flex gap-3 p-3 align-items-center">
               <div class="year-div flex-shrink-0 bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2024</div>
               <p class="mb-0 musaffa-info-para">
                  <span class="d-block mb-1">New Offices in Dubai & Tashkent</span>
                  <span>Portfolio Integration & Purification with 15 Brokerages</span>
               </p>
            </article>
         </div>
      </div>
      <div class="desktop-office-grid">
         <div class="clip-building-img">
            <img src="/assets/images/about-us/office-building.webp" class="d-block img-fluid" alt="">
         </div>
         <div class="office-heading-container">
            <h2 class="mb-0 fw-600">Musaffa’s Growth</h2>
            <h3 class="fw-600 lh-base text-start mt-2 mb-3">Company History <br> From 2020 to 2024</h3>
         </div>
      </div> 
      <div class="info-clip-container">
         <div class="info-clip-path"></div>
         <div class="mx-auto musaffa-info-card-grid container-fluid">
            <article class="musaffa-info-card info-card-1 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2020</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 text-white mt-1 text-center fw-normal">
                  Musaffa’s story begins in NYC with 3 passionate founders
               </p>
            </article>
            <article class="musaffa-info-card info-card-2 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2021</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 mt-1 d-flex align-items-center flex-wrap gap-2 text-white text-center fw-normal">
                  <span><span class="bullet-point"></span>Launched Web Platform with 12 Countries Covered</span> 
                  <span><span class="bullet-point"></span>New Office in Tashkent</span>
               </p>
            </article>
            <article class="musaffa-info-card info-card-3 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2022</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 mt-1 text-white text-center fw-normal">
                  Launched Mobile Apps  with 50,000+ Stocks Covered
               </p>
            </article>
            <article class="musaffa-info-card info-card-4 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2022</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 mt-1 d-flex align-items-center flex-wrap gap-2 text-white text-center fw-normal">
                  <span><span class="bullet-point"></span>72K+ Users Globally</span> 
                  <span><span class="bullet-point"></span>6 B2B Partnerships</span>
               </p>
            </article>
            <article class="musaffa-info-card info-card-5 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2023</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 mt-1 d-flex align-items-center flex-wrap gap-2 text-white text-center fw-normal">
                  <span><span class="bullet-point"></span>New Office in New York</span> 
                  <span><span class="bullet-point"></span>Launched world's first Shariah-compliant ETF Screener</span>
               </p>
            </article>
            <article class="musaffa-info-card info-card-6 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2023</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 mt-1 d-flex align-items-center flex-wrap gap-2 text-white text-center fw-normal">
                  <span><span class="bullet-point"></span>314K+ Users Globally</span> 
                  <span><span class="bullet-point"></span>The team expanded to 50+ members</span>
               </p>
            </article>
            <article class="musaffa-info-card info-card-7 d-flex flex-column gap-2 align-items-center">
               <div class="year-div bg-white fw-bold rounded-circle ratio ratio-1x1 d-flex align-items-center justify-content-center">2024</div>
               <svg width="12" height="69" viewBox="0 0 12 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10.5635L11.7735 0.563477L0.226497 0.563476L5 10.5635L7 10.5635ZM6 63.2301C3.05448 63.2301 0.666664 65.618 0.666664 68.5635C0.666663 71.509 3.05448 73.8968 6 73.8968C8.94552 73.8968 11.3333 71.509 11.3333 68.5635C11.3333 65.618 8.94552 63.2301 6 63.2301ZM5 9.56348L5 68.5635L7 68.5635L7 9.56348L5 9.56348Z" fill="white"/>
               </svg>
               <p class="mb-0 mt-1 d-flex align-items-center flex-wrap gap-2 text-white text-center fw-normal">
                  <span><span class="bullet-point"></span>New Offices in Dubai & Tashkent</span>
                  <span><span class="bullet-point"></span>Portfolio Integration & Purification with 15 Brokerages</span>
               </p>
            </article>
         </div>
      </div>
   </div>
</section>
