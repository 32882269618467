<div class="container-fluid position-relative px-0" [ngClass]="tabType">
   <div class="d-flex">
      <ul class="resp-slider btns-wrapper p-0 m-0 align-items-center" [ngClass]="stockOverviewTab">
         <li class="resp-slider-item" *ngFor="let tab of tabs; let i = index" (click)="setActiveTab(i)">
            <button class="btn tab-button shadow-none" [ngClass]="activeTab === i ? 'active' : ''">
               {{tab}}
            </button>
         </li>
      </ul>
   </div>
   <div class="position-absolute top-50 end-0 translate-middle-y share-wrap" *ngIf="!isMobile">
      <app-share-links-popup></app-share-links-popup>
   </div>
</div>
