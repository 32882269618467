import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SupportedBrokeragesComponent } from '../components/supported-brokerages/supported-brokerages.component';
import { PlaceOrderComponent } from '../components/place-order/place-order.component';

@Injectable({
   providedIn: 'root',
})
export class TradeService {
   public url: string = environment.apiUrl;
   constructor(
      private http: HttpClient,
      authService: AuthService,
      private modalService: NgbModal
   ) {}

   suppotedBrokeragesModalRef!: NgbModalRef;
   buySellModalRef!: NgbModalRef;

   private tabChangeSubjectDeatil = new BehaviorSubject<any>('');
   tabChangeDetail$ = this.tabChangeSubjectDeatil.asObservable();

   setOverviewTab(tab: string) {
      this.tabChangeSubjectDeatil.next(tab);
   }

   public async getSymbolId(
      symbol: string,
      exchangeCode: string,
      countryCode: string,
      account_id: string
   ) {
      return await lastValueFrom(
         this.http.get(
            `${this.url}/api/trading/get-symbolid?symbol=${symbol}&exchangeCode=${exchangeCode}&countryCode=${countryCode}&account_id=${account_id}`
         )
      );
   }

   public async getAccountBalance(accountId: any) {
      return await lastValueFrom(
         this.http.post(`${this.url}/api/trading/account-balalnce`, {
            account_id: accountId,
         })
      );
   }

   // public async fetchMarketHours() {
   //    return await lastValueFrom(
   //       this.http.get(`${this.url}/api/trading/market-hours`)
   //    );
   // }

   public async isTradingAllowed(countryCode: any) {
      return await lastValueFrom(
         this.http.post(`${this.url}/api/trading/is-trading-allowed`, {
            countryCode: countryCode,
         })
      );
   }

   public async isTradingEnabled(exchange: any) {
      return await lastValueFrom(
         this.http.post(`${this.url}/api/trading/is-trading-enabled`, {
            exchange_code: exchange,
         })
      );
   }

   public async marketStatus(exchangeCode: any) {
      return await lastValueFrom(
         this.http.post(`${this.url}/api/trading/market-status`, {
            exchangeCode: exchangeCode,
         })
      );
   }

   public async marketTime(exchangeCode: any) {
      return await lastValueFrom(
         this.http.post(`${this.url}/api/trading/market-time`, {
            exchangeCode: exchangeCode,
         })
      );
   }

   // <----- New apis ------->
   public async getTradingAccounts<T>(
      exchangeCode: string,
      symbol: string
   ): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(`${this.url}/api/trading/accounts`, {
            exchange_code: exchangeCode,
            symbol: symbol,
         })
      );
   }

   public async getStockInfo<T>(
      symbol: string,
      account_id: string | undefined,
      action: string
   ): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(`${this.url}/api/trading/symbol-data`, {
            symbol: symbol,
            account_id: account_id,
            action: action,
         })
      );
   }

   public async checkMarketStatus<T>(exchange_code: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(`${this.url}/api/trading/market-status`, {
            exchange_code: exchange_code,
         })
      );
   }

   public async getTradeImpact<T>(formData: any): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(`${this.url}/api/trading/calculate-price`, {
            ...formData,
         })
      );
   }

   public async placeOrder(tradeId: any) {
      return await lastValueFrom(
         this.http.post(`${this.url}/api/trading/place-order`, {
            trade_id: tradeId,
         })
      );
   }

   public async getSupportedBrokerages<T>(exchange_code: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(`${this.url}/api/trading/get-brokerages`, {
            exchange_code: exchange_code,
         })
      );
   }
   // <---------- New apis end ----------->

   public openSupportedBrokeragesModal(exchangeCode: string) {
      this.suppotedBrokeragesModalRef = this.modalService.open(
         SupportedBrokeragesComponent,
         {
            centered: true,
            size: 'xl',
            scrollable: true,
         }
      );
      this.suppotedBrokeragesModalRef.componentInstance.exchange = exchangeCode;
   }

   closeSupportedBrokeragModal() {
      if (this.suppotedBrokeragesModalRef) {
         this.suppotedBrokeragesModalRef.dismiss();
      }
   }

   public openBuySellPopup(
      exchangeCode: string,
      userRole: string,
      symbol: string,
      action: string | null,
      status?: string
   ) {
      this.buySellModalRef = this.modalService.open(PlaceOrderComponent, {
         centered: true,
         size: 'lg',
         scrollable: true,
         windowClass: 'buy-sell-popup',
      });
      this.buySellModalRef.componentInstance.exchangeCode = exchangeCode;
      this.buySellModalRef.componentInstance.userRole = userRole;
      this.buySellModalRef.componentInstance.symbol = symbol;
      this.buySellModalRef.componentInstance.action = action;
      this.buySellModalRef.componentInstance.status = status;
   }

   closeBuySellPopup() {
      if (this.buySellModalRef) {
         this.buySellModalRef.dismiss();
      }
   }

   // test function
   getStockPrice(symbolId: string) {
      // var userId = this.authService.getUserId();
      return [
         {
            symbol: {
               id: '2bcd7cc3-e922-4976-bce1-9858296801c3',
               symbol: 'AAPL',
               raw_symbol: 'VAB',
               description: 'VANGUARD CDN AGGREGATE BOND INDEX ETF',
               currency: {
                  id: '87b24961-b51e-4db8-9226-f198f6518a89',
                  code: 'USD',
                  name: 'US Dollar',
               },
               exchange: {
                  id: '2bcd7cc3-e922-4976-bce1-9858296801c3',
                  code: 'TSX',
                  mic_code: 'XTSE',
                  name: 'Toronto Stock Exchange',
                  timezone: 'America/New_York',
                  start_time: '09:30:00',
                  close_time: '16:00:00',
                  suffix: '.TO',
               },
               type: {
                  id: '2bcd7cc3-e922-4976-bce1-9858296801c3',
                  code: 'cs',
                  description: 'Common Stock',
                  is_supported: true,
               },
               currencies: [
                  {
                     id: '87b24961-b51e-4db8-9226-f198f6518a89',
                     code: 'USD',
                     name: 'US Dollar',
                  },
               ],
               figi_code: 'BBG000B9XRY4',
            },
            bid_price: 8.43,
            ask_price: 8.43,
            last_trade_price: 8.74,
            bid_size: 260,
            ask_size: 344,
         },
      ];
   }
}
