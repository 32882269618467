import { Injectable } from '@angular/core';
import { ListDetailsPopupComponent } from '../components/public-list/list-details-popup/list-details-popup.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Stock } from 'src/app/models/public-list/create-list.model';
import { ListDetails } from 'src/app/models/public-list/list-details.model';

@Injectable({
   providedIn: 'root',
})
export class PublicListsService {
   url: string = environment.apiUrl;
   actionsModalRef!: NgbModalRef;

   private listReloadSubject = new Subject<void>();
   listReloadObservable$ = this.listReloadSubject.asObservable();

   private popupVisibleSubject = new BehaviorSubject<boolean>(true);
   public popupVisible$ = this.popupVisibleSubject.asObservable();

   constructor(private modalService: NgbModal, private http: HttpClient) {}

   showPopup() {
      this.popupVisibleSubject.next(true);
   }

   hidePopup() {
      this.popupVisibleSubject.next(false);
   }

   async getTop10Lists<T>(
      page: number = 1,
      per_page: number = 10,
      sort_by: string = 'followers_count',
      sort_order: string = 'desc',
      symbols?: string[]
   ): Promise<T> {
      let params = new HttpParams()
         .set('page', page)
         .set('per_page', per_page)
         .set('sort_by', sort_by)
         .set('sort_order', sort_order);

      if (Array.isArray(symbols) && symbols.length > 0) {
         symbols.forEach((symbol) => {
            params = params.append('symbols[]', symbol);
         });
      }

      return await lastValueFrom(
         this.http.get<T>(this.url + '/api/public-lists', { params })
      );
   }

   async getAllLists<T>(
      page: number,
      per_page: number = 10,
      sort_by: string,
      sort_order: string,
      name?: string,
      symbols?: string[]
   ): Promise<T> {
      let params = new HttpParams()
         .set('page', page)
         .set('per_page', per_page)
         .set('sort_by', sort_by)
         .set('sort_order', sort_order);

      if (Array.isArray(symbols) && symbols.length > 0) {
         symbols.forEach((symbol) => {
            params = params.append('symbols[]', symbol);
         });
      }

      if (name) {
         params = params.set('name', name);
      }

      return await lastValueFrom(
         this.http.get<T>(this.url + '/api/public-lists', { params })
      );
   }

   async getMyCreatedLists<T>(
      user_id: string,
      page: number,
      per_page: number = 10,
      sort_by: string,
      sort_order: string,
      name?: string,
      symbols?: string[]
   ): Promise<T> {
      let params = new HttpParams()
         .set('user_id', user_id)
         .set('page', page)
         .set('per_page', per_page)
         .set('sort_by', sort_by)
         .set('sort_order', sort_order);

      if (Array.isArray(symbols) && symbols.length > 0) {
         symbols.forEach((symbol) => {
            params = params.append('symbols[]', symbol);
         });
      }

      if (name) {
         params = params.set('name', name);
      }

      return await lastValueFrom(
         this.http.get<T>(this.url + '/api/public-lists', { params })
      );
   }

   async getMyFollowedLists<T>(
      page: number,
      per_page: number = 10,
      sort_by: string,
      sort_order: string,
      name?: string,
      symbols?: string[]
   ): Promise<T> {
      let params = new HttpParams()
         .set('page', page)
         .set('per_page', per_page)
         .set('sort_by', sort_by)
         .set('sort_order', sort_order);

      if (Array.isArray(symbols) && symbols.length > 0) {
         symbols.forEach((symbol) => {
            params = params.append('symbols[]', symbol);
         });
      }

      if (name) {
         params = params.set('name', name);
      }

      return await lastValueFrom(
         this.http.get<T>(this.url + '/api/public-lists/my-followed', {
            params,
         })
      );
   }

   async createList<T>(
      name: string,
      description: string,
      stocks: Stock[]
   ): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(this.url + '/api/public-lists/create', {
            name,
            description,
            symbols: stocks,
         })
      );
   }
   async addPublicListToWatchlist<T>(id: string, name: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + `/api/public-lists/${id}/create-watchlist`,
            { name }
         )
      );
   }

   async updateListHoldings<T>(
      id: string,
      symbols: { name: string; type: string }[]
   ): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + `/api/public-lists/${id}/update-holdings`,
            { symbols }
         )
      );
   }

   async updatePublicList<T>(
      id: string,
      name: string,
      description: string | null,
      stocks: Stock[]
   ): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(this.url + `/api/public-lists/${id}/update`, {
            name,
            description,
            symbols: stocks,
         })
      );
   }

   async getListHoldings<T>(id: string): Promise<T> {
      return await lastValueFrom(
         this.http.get<T>(this.url + `/api/public-lists/${id}/holdings`)
      );
   }

   async deleteList<T>(id: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(this.url + `/api/public-lists/${id}/delete`, {})
      );
   }

   async getListInfo<T>(id: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(this.url + `/api/public-lists/${id}`, {})
      );
   }

   async deleteStock<T>(id: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + `/api/public-lists/holding/${id}/delete`,
            {}
         )
      );
   }

   async toggleListFollow<T>(id: string | number): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + `/api/public-lists/${id}/follow-toggle`,
            {}
         )
      );
   }

   async copyToPublicList<T>(
      id: string,
      name: string,
      description: string | null
   ): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + `/api/public-lists/${id}/create-from-watchlist`,
            {
               name: name,
               description: description,
            }
         )
      );
   }

   async isFollowing<T>(id: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(this.url + `/api/public-lists/${id}/is-followed`, {})
      );
   }

   openActionsModal(
      lisInfo: ListDetails,
      content: 'deleteList' | 'copyToWatchlist'
   ) {
      this.actionsModalRef = this.modalService.open(ListDetailsPopupComponent, {
         centered: true,
         windowClass: 'list-details-popup-size',
      });
      this.actionsModalRef.componentInstance.listInfo = lisInfo;
      this.actionsModalRef.componentInstance.content = content;
   }

   closeActionsModal() {
      if (this.actionsModalRef) {
         this.actionsModalRef.close();
      }
   }

   triggerListsReload() {
      this.listReloadSubject.next();
   }
}
