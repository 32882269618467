import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import {
   NgbProgressbarModule,
   NgbPaginationModule,
   NgbDropdownModule,
   NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SumPipe } from './pipes/sum.pipe';
import { CommonModule } from '@angular/common';
import { CategoryToTextPipe } from './pipes/category-to-text.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TooltipComponent } from './components/tooltip/tooltip.component';

import { HighLightPipe } from './pipes/highlight.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { MillionPipe } from './pipes/million.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CutTextPipe } from './pipes/cut-text.pipe';
import { FooterNavigateComponent } from './components/footer-navigate/footer-navigate.component';
import { NgxMaskModule } from 'ngx-mask';
import { countryPipe } from './pipes/country.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { CountryRouterlinkComponent } from './components/country-routerlink/country-routerlink.component';
import { ResetPasswordModaleComponent } from './components/reset-password-modale/reset-password-modale.component';
import { StripeFreeComponent } from './components/stripe-free/stripe-free.component';
import { LoaderComponent } from './components/loaders/loader.component';

import { StripeHeaderComponent } from './components/stripe-header/stripe-header.component';
import { LogOutModaleDayComponent } from './components/log-out-modale/log-out-modale.component';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { ErrorPageServerComponent } from './components/error-page-server/error-page-server.component';
import { textToCutPipe } from './pipes/text-to-cut.pipe';
import { ReletedNewsChangePipe } from './pipes/releted-news-change';
import { ProfileTextPipe } from './pipes/profile-text';
import { currencyMilionPipe } from './pipes/currencyMilion.pipe';
import { screaningMillionPipe } from './pipes/screaning-million.pipe';
import { EtfHoldingsByPipe } from './pipes/etf-holdings-by';
import { TooltipChartComponent } from './components/tooltip-chart/tooltip-chart.component';
import { countryCutEtfPipe } from './pipes/country-cut-etf';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { countryShortPipe } from './pipes/country-short.pipe copy';
import { ProgresChartComponent } from './components/progres-chart/progres-chart.component';
import { GoogleSigninComponent } from './components/google-signin/google-signin.component';
import { TradingChartComponent } from './components/trading-chart/trading-chart.component';
import { TradingChartEtfComponent } from './components/trading-chart-etf/trading-chart-etf.component';
import { ScreenerReportComponent } from './components/screener-report/screener-report.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IncomeBreakdownComponent } from './components/income-breakdown/income-breakdown.component';
import { RevenueBreakdownChartComponent } from './components/revenue-breakdown-chart/revenue-breakdown-chart.component';
import { DatePipe } from '@angular/common';
import { DebtChartComponent } from './components/debt-chart/debt-chart.component';
import { PurificationCalculatorComponent } from './components/purification-calculator/purification-calculator.component';
import { PurificationOptionsComponent } from './components/purification-options/purification-options.component';
import { PopularStocksComponent } from './components/popular-stocks/popular-stocks.component';
import { PurificationComingSoonComponent } from './components/purification-coming-soon/purification-coming-soon.component';
import { ScreenerFilterComponent } from './components/screener-filter/screener-filter.component';
import { StockListItemComponent } from './components/stock-list-item/stock-list-item.component';
import { TradingViewComponent } from './trading-view/trading-view.component';
import { ComplianceChipComponent } from './components/compliance-chip/compliance-chip.component';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { InvestmentChecklistComponent } from './components/investment-checklist/investment-checklist.component';
import { ForecastsComponent } from './components/forecasts/forecasts.component';
import { EtfComplianceChipComponent } from './components/etf-compliance-chip/etf-compliance-chip.component';
import { EtfScreenerReportComponent } from './components/etf-screener-report/etf-screener-report.component';
import { FacebookLoginComponent } from './components/facebook-login/facebook-login.component';
// import {
//    SocialLoginModule,
//    SocialAuthServiceConfig,
// } from '@abacritt/angularx-social-login';
// import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { AppleLoginComponent } from './components/apple-login/apple-login.component';
import { EtfScreeningDetailedReportComponent } from './components/etf-screening-detailed-report/etf-screening-detailed-report.component';

import { DetailsHeaderComponent } from './components/details-header/details-header.component';
import { ComplianceStatusCardComponent } from './components/compliance-status-card/compliance-status-card.component';
import { StockProfileComponent } from './components/stock-profile/stock-profile.component';
import { MobileOnlyHeaderComponent } from './components/mobile-only-header/mobile-only-header.component';

import { DetailsTabBtnsComponent } from './components/details-tab-btns/details-tab-btns.component';
import { ReportComplianceStatusCardComponent } from './components/report-compliance-status-card/report-compliance-status-card.component';
import { NoDataCardComponent } from './components/no-data-card/no-data-card.component';
import { ForecastRevenueChartComponent } from './components/forecast-revenue-chart/forecast-revenue-chart.component';
import { ForecastEarningsChartComponent } from './components/forecast-earnings-chart/forecast-earnings-chart.component';
import { ForecastPriceChartComponent } from './components/forecast-price-chart/forecast-price-chart.component';
import { ForecastDataSectionComponent } from './components/forecast-data-section/forecast-data-section.component';
import { ForecastDataBarChartComponent } from './components/forecast-data-bar-chart/forecast-data-bar-chart.component';
import { ForecastPriceDataSectionComponent } from './components/forecast-price-data-section/forecast-price-data-section.component';
import { AnalystRatingGuageChartComponent } from './components/analyst-rating-guage-chart/analyst-rating-guage-chart.component';
import { MobileSortOffcanvasComponent } from './components/mobile-sort-offcanvas/mobile-sort-offcanvas.component';
import { FeatureLockedCardComponent } from './components/feature-locked-card/feature-locked-card.component';
import { PaywallPopupComponent } from './components/paywall-popup/paywall-popup.component';
import { PremiumMemberGreetingPopupComponent } from './components/premium-member-greeting-popup/premium-member-greeting-popup.component';
import { PremiumSamplePopupComponent } from './components/premium-sample-popup/premium-sample-popup.component';
import { RequestCoveragePopupComponent } from './components/request-coverage-popup/request-coverage-popup.component';
import { EtfSamplePopupComponent } from './components/etf-sample-popup/etf-sample-popup.component';
import { CountryPromptPopupComponent } from './components/country-prompt-popup/country-prompt-popup.component';
import { SampleBubbleComponent } from './components/sample-bubble/sample-bubble.component';
import { AnalystRatingChipComponent } from './components/analyst-rating-chip/analyst-rating-chip.component';
import { CancelSubscriptionPopupComponent } from './components/cancel-subscription-popup/cancel-subscription-popup.component';
import { ResumeSubscriptionPopupComponent } from './components/resume-subscription-popup/resume-subscription-popup.component';
import { GeneralInfoPopupComponent } from './components/general-info-popup/general-info-popup.component';
// import { LinkPortfolioComponent } from './components/link-portfolio/link-portfolio.component';

import { BrokersPopupComponent } from './components/brokers-popup/brokers-popup.component';
import { SyncButtonComponent } from './components/sync-button/sync-button.component';
import { SupportedInstitutionsComponent } from './components/supported-institutions/supported-institutions.component';
import { SupportedBrokeragesComponent } from './components/supported-brokerages/supported-brokerages.component';
import { ConnectIframeModalComponent } from './components/connect-iframe-modal/connect-iframe-modal.component';
import { SafePipe } from './pipes/safe-url.pipe';
import { AbsPipe } from './pipes/abs.pipe';
import { RankingGradePipe } from './pipes/ranking-grade.pipe';
import { KeyToLabelPipe } from './pipes/key-to-label.pipe'; // adjust the path as needed
import { FormatValuePipe } from './pipes/format-value.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { PurificationTransactionComponent } from './components/purification-transaction/purification-transaction.component';
import { LessThanTooltipComponent } from './components/less-than-tooltip/less-than-tooltip.component';
import { HoldingDetailsPopupComponent } from './components/holding-details-popup/holding-details-popup.component';
import { PurificationMethodologyPopupComponent } from './components/purification-methodology-popup/purification-methodology-popup.component';
import { StatusPopupComponent } from './components/status-popup/status-popup.component';
import { SuperInvestorShortInfoCardComponent } from './components/super-investors/super-investor-short-info-card/super-investor-short-info-card.component';
import { SuperInvestorDetailedInfoCardComponent } from './components/super-investors/super-investor-detailed-info-card/super-investor-detailed-info-card.component';
import { SuperInvestorTableComponent } from './components/super-investors/super-investor-table/super-investor-table.component';
import { SuperInvestorTabsComponent } from './components/super-investors/super-investor-tabs/super-investor-tabs.component';
import { CollectionsListCardComponent } from './components/halal-stock-collections/collections-list-card/collections-list-card.component';
import { InvestorsPortfolioListComponent } from './components/super-investors/investors-portfolio-list/investors-portfolio-list.component';
import { StockDetailsPopupComponent } from './components/super-investors/stock-details-popup/stock-details-popup.component';
import { FilterByCountryPopupComponent } from './components/filter-by-country-popup/filter-by-country-popup.component';
import { HalalCircularProgressCardComponent } from './components/super-investors/halal-circular-progress-card/halal-circular-progress-card.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { ReconnectBrokerageBtnComponent } from './components/reconnect-portfolio/reconnect-brokerage-btn/reconnect-brokerage-btn.component';
import { ReconnectPortfolioCardComponent } from './components/reconnect-portfolio/reconnect-portfolio-card/reconnect-portfolio-card.component';
import { MyPortfolioCardComponent } from './components/my-portfolio-card/my-portfolio-card.component';
import { DashboardScreenerCardsComponent } from './components/dashboard-screener-cards/dashboard-screener-cards.component';
import { PaywallOfferCardComponent } from './components/paywall-offer-card/paywall-offer-card.component';
import { StepsFormWrapperComponent } from './components/zakat-calculator/steps-form-wrapper/steps-form-wrapper.component';
import { ZakatCalculatorHomeComponent } from './components/zakat-calculator/zakat-calculator-home/zakat-calculator-home.component';
import { StepsCounterComponent } from './components/zakat-calculator/steps-counter/steps-counter.component';
import { CashDetailsComponent } from './components/zakat-calculator/cash-details/cash-details.component';
import { GoldAndSilverDetailsComponent } from './components/zakat-calculator/gold-and-silver-details/gold-and-silver-details.component';
import { BusinessAssetsComponent } from './components/zakat-calculator/business-assets/business-assets.component';
import { PropertyComponent } from './components/zakat-calculator/property/property.component';
import { LiabilitiesComponent } from './components/zakat-calculator/liabilities/liabilities.component';
import { StockFundInvestmentsComponent } from './components/zakat-calculator/stock-fund-investments/stock-fund-investments.component';
import { ZakatCalculationsComponent } from './components/zakat-calculator/zakat-calculations/zakat-calculations.component';
import { OtherInvestmentsComponent } from './components/zakat-calculator/other-investments/other-investments.component';
import { ZakatDashboardCardComponent } from './components/zakat-calculator/zakat-dashboard-card/zakat-dashboard-card.component';
import { OnlyNumbersAndSingleDotDirective } from './directives/only-numbers-and-single-dot/only-numbers-and-single-dot.directive';
import { OnlyNumberDirective } from './directives/only-numbers/only-numbers.directive';
import { OnlyNumberTooltipDirective } from './directives/only-numbers-with-tooltip/only-numbers-tooltip.directive';
import { ViewSupportedInstitutionsCardComponent } from './components/view-supported-institutions-card/view-supported-institutions-card.component';
import { MyBrokerageAccountsComponent } from './components/portfolio/my-brokerage-accounts/my-brokerage-accounts.component';
import { ComplianceHistoryComponent } from './components/compliance-history/compliance-history.component';
import { DefaultCountryComponent } from './components/default-country/default-country.component';
import { TrimLeadingSpacesDirective } from './directives/trim-leading-spaces/trim-leading-spaces.directive';
import { ElementLoadedDirective } from './directives/element-loaded/element-loaded.directive';
import { SuperinvestorsStockOverviewListComponent } from './components/super-investors/superinvestors-stock-overview-list/superinvestors-stock-overview-list.component';
import { ProfileNavComponent } from './components/profile/profile-nav/profile-nav.component';
import { StringInitialsPipe } from './pipes/string-initials.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TopCuratedListsComponent } from './components/public-list/top-curated-lists/top-curated-lists.component';
import { AllListsHeaderComponent } from './components/public-list/all-lists-header/all-lists-header.component';
import { ListsSectionComponent } from './components/public-list/lists-section/lists-section.component';
import { ListSearchComponent } from './components/public-list/list-search/list-search.component';
import { ListFilterComponent } from './components/public-list/list-filter/list-filter.component';
import { ListTableComponent } from './components/public-list/list-table/list-table.component';
import { CreateListPopupComponent } from './components/public-list/create-list-popup/create-list-popup.component';
import { ChipInputComponent } from './components/public-list/chip-input/chip-input.component';
import { ListDetailsHeaderComponent } from './components/public-list/list-details-header/list-details-header.component';
import { ListDetailsTableComponent } from './components/public-list/list-details-table/list-details-table.component';
import { ListDetailsPopupComponent } from './components/public-list/list-details-popup/list-details-popup.component';
import { PrimaryFooterComponent } from './components/primary-footer/primary-footer.component';
import { InvestBannerComponent } from './components/dashboard/invest-banner/invest-banner.component';
import { TradingToolsComponent } from './components/dashboard/trading-tools/trading-tools.component';
import { SuperInvestorsListComponent } from './components/dashboard/super-investors-list/super-investors-list.component';
import { MusaffaStatsComponent } from './components/dashboard/musaffa-stats/musaffa-stats.component';
import { AcademyComponent } from './components/dashboard/academy/academy.component';
import { TestimonialsComponent } from './components/dashboard/testimonials/testimonials.component';
import { TrendingHalalStocksComponent } from './components/dashboard/trending-halal-stocks/trending-halal-stocks.component';
import { StocksTableComponent } from './components/dashboard/stocks-table/stocks-table.component';
import { MarketNewsComponent } from './components/dashboard/market-news/market-news.component';
import { MarketSectionComponent } from './components/dashboard/market-section/market-section.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MarketStatusComponent } from './components/dashboard/market-status/market-status.component';
import { HeroBannerComponent } from './components/dashboard/hero-banner/hero-banner.component';
import { CollectionsSectionComponent } from './components/dashboard/collections-section/collections-section.component';
import { LinkPortfolioComponent } from './components/dashboard/link-portfolio/link-portfolio.component';
import { PortfolioSectionComponent } from './components/dashboard/portfolio-section/portfolio-section.component';
import { SupportedBrokeragesSliderComponent } from './components/dashboard/supported-brokerages-slider/supported-brokerages-slider.component';
import { DownloadBannerComponent } from './components/dashboard/download-banner/download-banner.component';
import { TrendingHalalEtfsComponent } from './components/dashboard/trending-halal-etfs/trending-halal-etfs.component';
import { StocksNewsComponent } from './components/dashboard/stocks-news/stocks-news.component';
import { WatchlistSectionComponent } from './components/dashboard/watchlist-section/watchlist-section.component';
import { MarketHolidaysComponent } from './components/dashboard/market-holidays/market-holidays.component';
import { WatchlistButtonComponent } from './components/dashboard/watchlist-button/watchlist-button.component';
import { ModalWrapperComponent } from './components/modal-wrapper/modal-wrapper.component';
import { TopUserCollectionsComponent } from './components/dashboard/top-user-collections/top-user-collections.component';
import { HeaderComponent } from './components/primary-header/header/header.component';
import { StocksAndEtfsSearchComponent } from './components/primary-header/stocks-and-etfs-search/stocks-and-etfs-search.component';
import { MobileSidenavComponent } from './components/primary-header/mobile-sidenav/mobile-sidenav.component';
import { CountriesDropdownComponent } from './components/primary-header/countries-dropdown/countries-dropdown.component';
import { UserNotificationsComponent } from './components/primary-header/user-notifications/user-notifications.component';
import { EtfsTableComponent } from './components/dashboard/etfs-table/etfs-table.component';
import { EtfNewsComponent } from './components/dashboard/etf-news/etf-news.component';
import { LazyLoadImageDirective } from './directives/lazy-load-image/lazy-load-image.directive';
import { TitleCaseDirective } from './directives/title-case/title-case.directive';
import { UserOnboardingServeComponent } from './components/user-onboarding-serve/user-onboarding-serve.component';
import { EditUserEmailNumberPopupComponent } from './components/edit-user-email-number-popup/edit-user-email-number-popup.component';
// import { NotificationsComponent } from './components/notifications/notifications.component';
import { BuysellComponent } from './components/buysell/buysell.component';
import { PlaceOrderComponent } from './components/place-order/place-order.component';
import { MyPortfolioInfoComponent } from './components/dashboard/my-portfolio-info/my-portfolio-info.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { AuthPopupComponent } from '../authentication/auth-popup/auth-popup.component';
import { ForgotPasswordFormComponent } from '../authentication/forgot-password-form/forgot-password-form.component';
import { LoginFormComponent } from '../authentication/login-form/login-form.component';
import { SignupFormComponent } from '../authentication/signup-form/signup-form.component';
import { CompanyTeamModalComponent } from './components/company-team-modal/company-team-modal.component';
import { AddListsComponent } from '../cabinet/add-lists/add-lists.component';
import { AddListsCreateComponent } from '../cabinet/add-lists-create/add-lists-create.component';
import { UpgradeNowComponent } from '../cabinet/upgrade-now/upgrade-now.component';
import { UpgradePopupComponent } from './components/upgrade-popup/upgrade-popup.component';
import { PurificationCardComponent } from './components/stock-details/purification-card/purification-card.component';
import { EtfHoldingsComponent } from './components/etf-holdings/etf-holdings.component';
import { CoveredCountriesComponent } from './components/dashboard/covered-countries/covered-countries.component';
import { CalculatePortfolioCardComponent } from './components/portfolio/calculate-portfolio-card/calculate-portfolio-card.component';
import { BrokerageCoveragesComponent } from './components/portfolio/brokerage-coverages/brokerage-coverages.component';
import { InfiniteStocksComponent } from './components/infinite-stocks/infinite-stocks.component';
import { AddManualCardComponent } from './components/add-manual-card/add-manual-card.component';
import { EtfComplianceStatusCardComponent } from './components/etf-compliance-status-card/etf-compliance-status-card.component';
import { GlobalOfficesComponent } from './components/global-offices/global-offices.component';
import { MusaffaHistoryComponent } from './components/musaffa-history/musaffa-history.component';
import { FeaturedInComponent } from './components/featured-in/featured-in.component';
import { ManageSubscriptionComponent } from './components/manage-subscription/manage-subscription.component';
import { DisclaimerPopupComponent } from './components/disclaimer-popup/disclaimer-popup.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/click-stop-propagation.directive';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TrendingStocksTableComponent } from './components/trending-halal-stocks/trending-stocks-table/trending-stocks-table.component';
import { PortfolioLimitMessagePopupComponent } from './components/portfolio/portfolio-limit-message-popup/portfolio-limit-message-popup.component';
import { SwipeDirective } from './directives/swipe/swipe.directive';
import { LimitReachedCardComponent } from './components/limit-reached-card/limit-reached-card.component';
import { LimitReachedPopupComponent } from './components/limit-reached-popup/limit-reached-popup.component';
import { StyleFeatureStringDirective } from './directives/style-feature-string/style-feature-string.directive';
import { EnablePersonalizedAssistancePopupComponent } from './components/enable-personalized-assistance-popup/enable-personalized-assistance-popup.component';
import { PlaidCardComponent } from './components/portfolio/plaid-card/plaid-card.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareLinksPopupComponent } from './components/share-links-popup/share-links-popup.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { BucketCollectionChipsComponent } from './components/bucket-collection-chips/bucket-collection-chips.component';
import { CopyToWatchlistPopupComponent } from './components/copy-to-watchlist-popup/copy-to-watchlist-popup.component';
import { MarketIndicesComponent } from './components/dashboard/market-indices/market-indices.component';
import { CrowdfundingBannerComponent } from './components/dashboard/crowdfunding-banner/crowdfunding-banner.component';
import { UnlockBrokerPopupComponent } from './components/unlock-broker-popup/unlock-broker-popup.component';
import { ContainerLoaderComponent } from './components/container-loader/container-loader.component';
import { TopHeaderComponent } from './components/latest-header/top-header/top-header.component';
import { HeaderSearchbarComponent } from './components/latest-header/header-searchbar/header-searchbar.component';
import { HeaderUserNotificationsComponent } from './components/latest-header/header-user-notifications/header-user-notifications.component';
import { HeaderCountriesDropdownComponent } from './components/latest-header/header-countries-dropdown/header-countries-dropdown.component';
import { SidebarMenuComponent } from './components/latest-header/sidebar-menu/sidebar-menu.component';
import { LatestFooterComponent } from './components/latest-footer/latest-footer.component';
import { ShariahCompliantStatusPipe } from './pipes/shariah-compliant-status.pipe';
import { PortfolioHeaderComponent } from '../features/our-portfolio/components/portfolio-header/portfolio-header.component';
import { OverviewComponent } from '../features/our-portfolio/components/overview/overview.component';
import { PortfolioValueComponent } from '../features/our-portfolio/components/overview/portfolio-value/portfolio-value.component';
import { PurenessComponent } from '../features/our-portfolio/components/overview/pureness/pureness.component';
import { MiniChartComponent } from '../features/our-portfolio/components/overview/mini-chart/mini-chart.component';
import { JoinReferralPopupComponent } from './components/join-referral-popup/join-referral-popup.component';
import { AnalyticsPieChartComponent } from '../features/our-portfolio/components/overview/analytics-pie-chart/analytics-pie-chart.component';
import { VerifyOtpComponent } from '../authentication/verify-otp/verify-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
   declarations: [
      AddListsComponent,
      AddListsCreateComponent,
      UpgradeNowComponent,
      StripeHeaderComponent,
      SumPipe,
      countryCutEtfPipe,
      countryPipe,
      CategoryToTextPipe,
      EtfHoldingsByPipe,
      ReletedNewsChangePipe,
      ProfileTextPipe,
      TooltipComponent,
      textToCutPipe,
      HighLightPipe,
      HighlightSearchPipe,
      MillionPipe,
      countryShortPipe,
      screaningMillionPipe,
      currencyMilionPipe,
      OrderByPipe,
      CutTextPipe,
      FooterNavigateComponent,
      CountryRouterlinkComponent,
      ResetPasswordModaleComponent,
      StripeFreeComponent,
      LogOutModaleDayComponent,
      ErrorNotFoundComponent,
      ErrorPageServerComponent,
      TooltipChartComponent,
      ProgresChartComponent,
      GoogleSigninComponent,
      TradingChartComponent,
      TradingChartEtfComponent,
      ScreenerReportComponent,
      IncomeBreakdownComponent,
      RevenueBreakdownChartComponent,
      DebtChartComponent,
      PurificationCalculatorComponent,
      PurificationOptionsComponent,
      // MarketNewsComponent,
      PopularStocksComponent,
      PurificationComingSoonComponent,
      ScreenerFilterComponent,
      StockListItemComponent,
      TradingViewComponent,
      ComplianceChipComponent,
      MobileHeaderComponent,
      InvestmentChecklistComponent,
      ForecastsComponent,
      EtfComplianceChipComponent,
      EtfScreenerReportComponent,
      FacebookLoginComponent,
      AppleLoginComponent,
      EtfScreeningDetailedReportComponent,

      DetailsHeaderComponent,
      ComplianceStatusCardComponent,
      StockProfileComponent,
      MobileOnlyHeaderComponent,

      DetailsTabBtnsComponent,
      ReportComplianceStatusCardComponent,
      NoDataCardComponent,
      ForecastRevenueChartComponent,
      ForecastEarningsChartComponent,
      ForecastPriceChartComponent,
      ForecastDataSectionComponent,
      ForecastDataBarChartComponent,
      ForecastPriceDataSectionComponent,
      AnalystRatingGuageChartComponent,
      MobileSortOffcanvasComponent,
      FeatureLockedCardComponent,
      PaywallPopupComponent,
      PremiumMemberGreetingPopupComponent,
      PremiumSamplePopupComponent,
      RequestCoveragePopupComponent,
      EtfSamplePopupComponent,
      CountryPromptPopupComponent,
      SampleBubbleComponent,
      AnalystRatingChipComponent,
      CancelSubscriptionPopupComponent,
      ResumeSubscriptionPopupComponent,
      GeneralInfoPopupComponent,
      // LinkPortfolioComponent,
      BrokersPopupComponent,
      SyncButtonComponent,
      SupportedInstitutionsComponent,
      SupportedBrokeragesComponent,
      ConnectIframeModalComponent,
      SafePipe,
      AbsPipe,
      RankingGradePipe,
      KeyToLabelPipe,
      FormatValuePipe,
      ShariahCompliantStatusPipe,
      TimeAgoPipe,
      PurificationTransactionComponent,
      LessThanTooltipComponent,
      HoldingDetailsPopupComponent,
      PurificationMethodologyPopupComponent,
      StatusPopupComponent,
      SuperInvestorShortInfoCardComponent,
      SuperInvestorDetailedInfoCardComponent,
      SuperInvestorTableComponent,
      SuperInvestorTabsComponent,
      CollectionsListCardComponent,
      InvestorsPortfolioListComponent,
      StockDetailsPopupComponent,
      FilterByCountryPopupComponent,
      HalalCircularProgressCardComponent,
      ClickOutsideDirective,
      ReconnectBrokerageBtnComponent,
      ReconnectPortfolioCardComponent,
      MyPortfolioCardComponent,
      DashboardScreenerCardsComponent,
      PaywallOfferCardComponent,
      StepsFormWrapperComponent,
      ZakatCalculatorHomeComponent,
      StepsCounterComponent,
      CashDetailsComponent,
      GoldAndSilverDetailsComponent,
      BusinessAssetsComponent,
      PropertyComponent,
      LiabilitiesComponent,
      StockFundInvestmentsComponent,
      ZakatCalculationsComponent,
      OtherInvestmentsComponent,
      ZakatDashboardCardComponent,
      OnlyNumbersAndSingleDotDirective,
      OnlyNumberDirective,
      OnlyNumberTooltipDirective,
      ViewSupportedInstitutionsCardComponent,
      MyBrokerageAccountsComponent,
      ComplianceHistoryComponent,
      DefaultCountryComponent,
      TrimLeadingSpacesDirective,
      ElementLoadedDirective,
      SuperinvestorsStockOverviewListComponent,
      ProfileNavComponent,
      StringInitialsPipe,
      DurationPipe,
      TopCuratedListsComponent,
      AllListsHeaderComponent,
      ListsSectionComponent,
      ListSearchComponent,
      ListFilterComponent,
      ListTableComponent,
      CreateListPopupComponent,
      ChipInputComponent,
      ListDetailsHeaderComponent,
      ListDetailsTableComponent,
      ListDetailsPopupComponent,
      PrimaryFooterComponent,
      InvestBannerComponent,
      TradingToolsComponent,
      SuperInvestorsListComponent,
      MusaffaStatsComponent,
      AcademyComponent,
      TestimonialsComponent,
      TrendingHalalStocksComponent,
      StocksTableComponent,
      MarketNewsComponent,
      MarketSectionComponent,
      MarketStatusComponent,
      HeroBannerComponent,
      CollectionsSectionComponent,
      PortfolioSectionComponent,
      LinkPortfolioComponent,
      SupportedBrokeragesSliderComponent,
      DownloadBannerComponent,
      TrendingHalalEtfsComponent,
      StocksNewsComponent,
      WatchlistSectionComponent,
      MarketHolidaysComponent,
      WatchlistButtonComponent,
      ModalWrapperComponent,
      TopUserCollectionsComponent,
      HeaderComponent,
      StocksAndEtfsSearchComponent,
      MobileSidenavComponent,
      CountriesDropdownComponent,
      UserNotificationsComponent,
      EtfsTableComponent,
      EtfNewsComponent,
      LazyLoadImageDirective,
      TitleCaseDirective,
      UserOnboardingServeComponent,
      EditUserEmailNumberPopupComponent,
      PlaceOrderComponent,
      BuysellComponent,
      MyPortfolioInfoComponent,
      BottomSheetComponent,
      AuthPopupComponent,
      LoginFormComponent,
      SignupFormComponent,
      VerifyOtpComponent,
      ForgotPasswordFormComponent,
      CompanyTeamModalComponent,
      UpgradePopupComponent,
      PurificationCardComponent,
      EtfHoldingsComponent,
      CoveredCountriesComponent,
      CalculatePortfolioCardComponent,
      BrokerageCoveragesComponent,
      InfiniteStocksComponent,
      AddManualCardComponent,
      EtfComplianceStatusCardComponent,
      GlobalOfficesComponent,
      MusaffaHistoryComponent,
      FeaturedInComponent,
      ManageSubscriptionComponent,
      DisclaimerPopupComponent,
      ClickStopPropagationDirective,
      TrendingStocksTableComponent,
      PortfolioLimitMessagePopupComponent,
      SwipeDirective,
      LimitReachedCardComponent,
      LimitReachedPopupComponent,
      StyleFeatureStringDirective,
      EnablePersonalizedAssistancePopupComponent,
      PlaidCardComponent,
      ShareLinksPopupComponent,
      DynamicTableComponent,
      BucketCollectionChipsComponent,
      CopyToWatchlistPopupComponent,
      MarketIndicesComponent,
      CrowdfundingBannerComponent,
      UnlockBrokerPopupComponent,
      ContainerLoaderComponent,
      TopHeaderComponent,
      HeaderSearchbarComponent,
      HeaderUserNotificationsComponent,
      HeaderCountriesDropdownComponent,
      SidebarMenuComponent,
      LatestFooterComponent,
      PortfolioHeaderComponent,
      OverviewComponent,
      PortfolioValueComponent,
      PurenessComponent,
      MiniChartComponent,
      JoinReferralPopupComponent,
      AnalyticsPieChartComponent
   ],
   imports: [

      FormsModule,
      ReactiveFormsModule,
      NgSelectModule,
      ChartsModule,
      NgbProgressbarModule,
      NgbPaginationModule,
      NgbDropdownModule,
      NgbTooltipModule,
      CommonModule,
      MatDatepickerModule,
      MatNativeDateModule,
      RouterModule,
      NgxSkeletonLoaderModule,
      NgbModule,
      NgOtpInputModule,
      // SocialLoginModule,
      CarouselModule,
      NgxMaskModule,
      ShareButtonsModule,
      ShareIconsModule,
   ],
   exports: [
      ContainerLoaderComponent,
      AddListsComponent,
      AddListsCreateComponent,
      UpgradeNowComponent,
      FormsModule,
      ReactiveFormsModule,
      NgSelectModule,
      StripeHeaderComponent,
      ChartsModule,
      SumPipe,
      countryCutEtfPipe,
      countryShortPipe,
      countryPipe,
      CommonModule,
      CategoryToTextPipe,
      EtfHoldingsByPipe,
      ReletedNewsChangePipe,
      ProfileTextPipe,
      MatDatepickerModule,
      MatNativeDateModule,
      TooltipComponent,
      textToCutPipe,
      HighLightPipe,
      HighlightSearchPipe,
      MillionPipe,
      screaningMillionPipe,
      currencyMilionPipe,
      OrderByPipe,
      CutTextPipe,
      FooterNavigateComponent,
      NgxMaskModule,
      TextMaskModule,
      CountryRouterlinkComponent,
      ResetPasswordModaleComponent,
      StripeFreeComponent,
      LogOutModaleDayComponent,
      ErrorNotFoundComponent,
      ErrorPageServerComponent,
      NgbProgressbarModule,
      NgbPaginationModule,
      NgbDropdownModule,
      NgbTooltipModule,
      NgxSkeletonLoaderModule,
      GoogleSigninComponent,
      TradingChartComponent,
      TradingChartEtfComponent,
      ScreenerReportComponent,
      RevenueBreakdownChartComponent,
      NgbModule,
      PurificationCalculatorComponent,
      PurificationOptionsComponent,
      // MarketNewsComponent,
      PopularStocksComponent,
      ScreenerFilterComponent,
      StockListItemComponent,
      TradingViewComponent,
      ComplianceChipComponent,
      MobileHeaderComponent,
      InvestmentChecklistComponent,
      ForecastsComponent,
      EtfComplianceChipComponent,
      EtfScreenerReportComponent,
      FacebookLoginComponent,
      AppleLoginComponent,

      EtfScreeningDetailedReportComponent,
      DetailsHeaderComponent,
      ComplianceStatusCardComponent,
      StockProfileComponent,
      MobileOnlyHeaderComponent,

      EtfScreeningDetailedReportComponent,

      DetailsTabBtnsComponent,
      ReportComplianceStatusCardComponent,
      NoDataCardComponent,
      ForecastRevenueChartComponent,
      ForecastEarningsChartComponent,
      ForecastPriceChartComponent,
      ForecastDataBarChartComponent,
      ForecastPriceDataSectionComponent,
      AnalystRatingGuageChartComponent,
      MobileSortOffcanvasComponent,
      FeatureLockedCardComponent,
      PaywallPopupComponent,
      PremiumMemberGreetingPopupComponent,
      PremiumSamplePopupComponent,
      RequestCoveragePopupComponent,
      EtfSamplePopupComponent,
      SampleBubbleComponent,
      AnalystRatingChipComponent,
      // LinkPortfolioComponent,

      BrokersPopupComponent,
      SyncButtonComponent,
      SupportedInstitutionsComponent,
      SupportedBrokeragesComponent,
      PurificationTransactionComponent,
      LessThanTooltipComponent,
      StatusPopupComponent,
      SuperInvestorShortInfoCardComponent,
      SuperInvestorDetailedInfoCardComponent,
      SuperInvestorTableComponent,
      SuperInvestorTabsComponent,
      CollectionsListCardComponent,
      InvestorsPortfolioListComponent,
      StockDetailsPopupComponent,
      FilterByCountryPopupComponent,
      HalalCircularProgressCardComponent,
      ReconnectBrokerageBtnComponent,
      ReconnectPortfolioCardComponent,
      MyPortfolioCardComponent,
      DashboardScreenerCardsComponent,
      PaywallOfferCardComponent,
      StepsFormWrapperComponent,
      ZakatCalculatorHomeComponent,
      CashDetailsComponent,
      GoldAndSilverDetailsComponent,
      BusinessAssetsComponent,
      PropertyComponent,
      LiabilitiesComponent,
      StockFundInvestmentsComponent,
      ZakatCalculationsComponent,
      OtherInvestmentsComponent,
      StepsCounterComponent,
      ZakatDashboardCardComponent,
      ViewSupportedInstitutionsCardComponent,
      MyBrokerageAccountsComponent,
      DefaultCountryComponent,
      TrimLeadingSpacesDirective,
      ElementLoadedDirective,
      SuperinvestorsStockOverviewListComponent,
      StringInitialsPipe,
      DurationPipe,
      TopCuratedListsComponent,
      AllListsHeaderComponent,
      ListsSectionComponent,
      ListSearchComponent,
      ListFilterComponent,
      ListTableComponent,
      CreateListPopupComponent,
      ChipInputComponent,
      ListDetailsHeaderComponent,
      ListDetailsTableComponent,
      ListDetailsPopupComponent,
      PrimaryFooterComponent,
      InvestBannerComponent,
      TradingToolsComponent,
      SuperInvestorsListComponent,
      MusaffaStatsComponent,
      AcademyComponent,
      TestimonialsComponent,
      TrendingHalalStocksComponent,
      StocksTableComponent,
      MarketNewsComponent,
      MarketSectionComponent,
      CarouselModule,
      MarketStatusComponent,
      HeroBannerComponent,
      CollectionsSectionComponent,
      PortfolioSectionComponent,
      LinkPortfolioComponent,
      SupportedBrokeragesSliderComponent,
      DownloadBannerComponent,
      TrendingHalalEtfsComponent,
      StocksNewsComponent,
      WatchlistSectionComponent,
      MarketHolidaysComponent,
      WatchlistButtonComponent,
      ModalWrapperComponent,
      TopUserCollectionsComponent,
      HeaderComponent,
      TopHeaderComponent,
      LatestFooterComponent,
      StocksAndEtfsSearchComponent,
      MobileSidenavComponent,
      CountriesDropdownComponent,
      // StockScreenerFiltersComponent,
      // StockScreenerListComponent,
      EtfNewsComponent,
      UserOnboardingServeComponent,
      EditUserEmailNumberPopupComponent,
      PlaceOrderComponent,
      BuysellComponent,
      EtfNewsComponent,
      AbsPipe,
      RankingGradePipe,
      KeyToLabelPipe,
      FormatValuePipe,
      ShariahCompliantStatusPipe,

      BottomSheetComponent,
      AuthPopupComponent,
      LoginFormComponent,
      SignupFormComponent,
      VerifyOtpComponent,
      ForgotPasswordFormComponent,
      LazyLoadImageDirective,
      CompanyTeamModalComponent,
      OnlyNumbersAndSingleDotDirective,
      OnlyNumberDirective,
      OnlyNumberTooltipDirective,
      PurificationCardComponent,
      EtfHoldingsComponent,
      CoveredCountriesComponent,
      CalculatePortfolioCardComponent,
      BrokerageCoveragesComponent,
      InfiniteStocksComponent,
      AddManualCardComponent,
      MyPortfolioInfoComponent,
      EtfComplianceStatusCardComponent,
      GlobalOfficesComponent,
      MusaffaHistoryComponent,
      FeaturedInComponent,
      ManageSubscriptionComponent,
      ClickStopPropagationDirective,
      ClipboardModule,
      TrendingStocksTableComponent,
      SwipeDirective,
      LimitReachedCardComponent,
      LimitReachedPopupComponent,
      StyleFeatureStringDirective,
      EnablePersonalizedAssistancePopupComponent,
      PlaidCardComponent,
      ShareButtonsModule,
      ShareIconsModule,
      ShareLinksPopupComponent,
      DynamicTableComponent,
      BucketCollectionChipsComponent,
      CopyToWatchlistPopupComponent,
      CrowdfundingBannerComponent,
      UnlockBrokerPopupComponent,
      PortfolioHeaderComponent,
      OverviewComponent,
      PortfolioValueComponent,
      PurenessComponent,
      MiniChartComponent,
      JoinReferralPopupComponent,
      AnalyticsPieChartComponent
   ],
   providers: [
      // {
      //    provide: 'SocialAuthServiceConfig',
      //    useValue: {
      //       autoLogin: false,
      //       providers: [
      //          {
      //             id: FacebookLoginProvider.PROVIDER_ID,
      //             provider: new FacebookLoginProvider('1375528286183693'),
      //          },
      //       ],
      //       onError: (err) => {
      //          console.error(err);
      //       },
      //    } as SocialAuthServiceConfig,
      // },
      DatePipe,
      screaningMillionPipe,
      AbsPipe,
      RankingGradePipe,
      KeyToLabelPipe,
      FormatValuePipe,
      ShariahCompliantStatusPipe
   ],
})
export class SharedModule { }
