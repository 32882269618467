<section class="">
   <!-- <h3 class="section-heading">Explore Musaffa</h3> -->
   <div class="cards-container">
      <div class="inner-wrapper">
         <a
            class="screener-card text-decoration-none card-1"
            routerLink="/cabinet/onboarding"
            (click)="homeStockScreenerTapped()"
         >
            <h6 class="mb-0">
               <span class="d-block fw-normal text-uppercase">Stock</span>
               <span class="d-block fw-bold">Screener</span>
            </h6>
            <div class="d-flex align-items-end justify-content-between">
               <svg
                  class="screener-image"
                  viewBox="0 0 81 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <rect
                     x="0.133301"
                     width="80"
                     height="80"
                     rx="40"
                     fill="#F6F9F6"
                  />
                  <g clip-path="url(#clip0_121_18)">
                     <path
                        d="M19.3333 18.5328C19.3894 18.4373 19.4456 18.3474 19.5018 18.2519C19.7883 17.7631 20.3446 17.5102 20.8671 17.6282C21.4289 17.7631 21.8279 18.2182 21.8672 18.7912C21.8784 18.9429 21.8672 19.1003 21.8672 19.2576C21.8672 32.0902 21.8672 44.9284 21.8672 57.761V58.3566H22.3841C29.3454 58.3566 36.3011 58.3566 43.2624 58.3678C43.5152 58.3678 43.8073 58.4802 44.0096 58.6375C45.2625 59.6039 46.6334 60.323 48.1617 60.7388C48.3077 60.7781 48.4482 60.8399 48.5887 60.8905H20.2659C19.8502 60.6882 19.5355 60.3792 19.3333 59.9578V18.5328Z"
                        fill="#53877C"
                     />
                     <path
                        d="M53.7521 60.8961C54.4993 60.6208 55.2803 60.4185 55.9882 60.0589C56.8254 59.6375 57.5895 59.0757 58.3929 58.5869C58.5727 58.4801 58.7918 58.379 58.9941 58.3734C59.6964 58.3453 60.4044 58.3734 61.1067 58.3565C61.7753 58.3397 62.2865 58.5532 62.5619 59.2049C62.5787 59.2498 62.663 59.2611 62.7192 59.2892V59.9634C62.6686 59.9746 62.5899 59.9802 62.5787 60.0083C62.4382 60.4522 62.0787 60.6826 61.7022 60.8961H53.7521Z"
                        fill="#53877C"
                     />
                     <path
                        d="M30.722 41.8832C31.9244 40.6021 33.0705 39.3829 34.2167 38.1581C35.6831 36.5962 37.1776 35.0623 38.5935 33.4554C39.425 32.5172 40.6836 32.8543 41.0151 34.0285C41.6724 36.3714 42.4478 38.6806 43.1782 40.9954C43.2568 41.2483 43.2512 41.4168 43.0433 41.6247C39.352 45.3835 38.6553 50.9008 41.2848 55.4799C41.3409 55.5754 41.3859 55.6709 41.4477 55.7945H24.4293V31.7755C25.0923 32.0396 25.7216 32.298 26.3621 32.5509C28.2162 33.2813 27.9521 33.0734 28.5364 34.8994C29.2275 37.085 29.9017 39.2762 30.5872 41.4618C30.6265 41.5798 30.6658 41.6977 30.7276 41.8832H30.722Z"
                        fill="#53877C"
                     />
                     <path
                        d="M60.0898 49.6762C60.101 54.598 56.095 58.6152 51.1789 58.6152C46.2627 58.6152 42.2455 54.6092 42.2511 49.693C42.2511 44.7881 46.2402 40.7878 51.1395 40.7709C56.0613 40.7597 60.0842 44.7544 60.0898 49.6706V49.6762ZM49.9316 54.4912C49.9316 54.7441 49.9203 54.9126 49.9316 55.0812C49.9709 55.7048 50.4485 56.2049 51.0553 56.2554C51.6901 56.3116 52.2408 55.952 52.3981 55.3396C52.4543 55.1093 52.4711 54.8677 52.4599 54.6317C52.443 54.3451 52.5385 54.1878 52.7914 54.0305C54.314 53.0754 54.6679 51.0583 53.5723 49.6481C52.9936 48.9065 52.2239 48.5244 51.28 48.4738C50.8586 48.4514 50.6676 48.2828 50.6788 47.9345C50.6901 47.5861 50.8923 47.44 51.2294 47.4344C51.8475 47.4344 52.4711 47.4625 53.0891 47.4063C53.7858 47.3389 54.2297 46.7714 54.2016 46.0972C54.1735 45.4286 53.6566 44.9342 52.9599 44.9061C52.797 44.9005 52.6284 44.9061 52.4093 44.9061C52.4093 44.6757 52.4205 44.4959 52.4093 44.3218C52.37 43.6981 51.8924 43.1981 51.2856 43.1475C50.6563 43.0913 50.1001 43.4565 49.9428 44.0633C49.8866 44.2937 49.8698 44.5353 49.881 44.7713C49.8922 45.0522 49.8023 45.2095 49.5551 45.3724C48.4989 46.0523 48.0381 47.0467 48.1617 48.2884C48.2797 49.4514 48.8978 50.2886 49.9653 50.7437C50.3417 50.9066 50.7856 50.9066 51.1901 51.019C51.3587 51.0696 51.5441 51.1988 51.6227 51.3449C51.6789 51.4572 51.6227 51.7045 51.5272 51.7944C51.4148 51.9011 51.2013 51.9461 51.0328 51.9517C50.426 51.9798 49.8192 51.9292 49.218 51.9966C48.5494 52.0697 48.1168 52.6371 48.1505 53.3001C48.1786 53.9462 48.6843 54.4519 49.3472 54.4912C49.527 54.5025 49.7068 54.4912 49.9372 54.4912H49.9316Z"
                        fill="#53877C"
                     />
                     <path
                        d="M60.0841 42.4675C57.1962 39.1976 53.6678 37.8491 49.3921 38.3436C49.4651 38.1975 49.5101 38.0851 49.5663 37.984C52.2463 33.1633 54.9263 28.3427 57.6007 23.5164C57.8479 23.0669 58.1794 22.7579 58.7019 22.6961C59.348 22.6231 59.9661 23.1287 60.0616 23.8142C60.0784 23.9378 60.0728 24.067 60.0728 24.1962C60.0728 30.1406 60.0728 36.0849 60.0728 42.0349V42.4675H60.0841Z"
                        fill="#53877C"
                     />
                  </g>
                  <defs>
                     <clipPath id="clip0_121_18">
                        <rect
                           width="43.3803"
                           height="43.296"
                           fill="#F6F9F6"
                           transform="translate(19.3333 17.6002)"
                        />
                     </clipPath>
                  </defs>
               </svg>
               <svg
                  class="arrow-svg"
                  viewBox="0 0 92 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M76.389 86.456L44.7184 86.3574C22.7297 86.2889 11.7354 86.2546 11.091 86.1684C0.863251 84.8003 -1.92232 77.1882 5.00663 69.5418C5.44324 69.06 15.4622 60.54 35.5 43.5C38.1483 41.2479 40.7606 38.7372 43.3226 36.0309C66.0472 12.027 77.4095 0.0249863 82.9183 1.22132C84.1708 1.49333 84.9127 1.79049 86.0067 2.45835C90.8181 5.39566 90.7598 20.2495 90.6433 49.9571L90.5553 72.389C90.5403 76.2233 90.5328 78.1405 90.0076 79.686C89.0002 82.6512 86.6618 84.9731 83.6897 85.9597C82.1404 86.474 80.2233 86.468 76.389 86.456Z"
                     fill="#53877C"
                     stroke="#53877C"
                  />
                  <mask
                     id="mask0_7777_3575"
                     style="mask-type: alpha"
                     maskUnits="userSpaceOnUse"
                     x="49"
                     y="47"
                     width="24"
                     height="24"
                  >
                     <rect
                        x="49"
                        y="47"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                     />
                  </mask>
                  <g mask="url(#mask0_7777_3575)">
                     <path
                        d="M63 65L61.6 63.55L65.15 60H53V58H65.15L61.6 54.45L63 53L69 59L63 65Z"
                        fill="white"
                     />
                  </g>
               </svg>
            </div>
         </a>

         <a
            class="screener-card text-decoration-none card-2"
            [routerLink]="etfUrl"
            (click)="homeETFScreenerTapped()"
         >
            <h6 class="mb-0">
               <span class="d-block fw-normal text-uppercase">Etf</span>
               <span class="d-block fw-bold">Screener</span>
            </h6>
            <div class="d-flex align-items-end justify-content-between">
               <svg
                  class="screener-image"
                  viewBox="0 0 81 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <rect
                     x="0.133301"
                     width="80"
                     height="80"
                     rx="40"
                     fill="#F6F9F6"
                  />
                  <g clip-path="url(#clip0_121_4)">
                     <path
                        d="M21.3697 17.5992C22.3811 17.9411 22.7323 18.6575 22.7323 19.7017C22.7136 31.6044 22.723 43.5071 22.723 55.4098C22.723 56.7865 23.3551 57.4186 24.727 57.4186C36.6297 57.4186 48.5324 57.4233 60.4352 57.4092C61.4793 57.4092 62.2004 57.7557 62.5376 58.7718V59.4461C62.1957 60.4575 61.4793 60.8087 60.4305 60.8087C48.509 60.7899 36.5876 60.7993 24.6662 60.7993C21.5102 60.7993 19.3423 58.6313 19.3423 55.4754C19.3423 43.5305 19.3469 31.595 19.3329 19.6595C19.3329 18.6247 19.7028 17.9317 20.6908 17.5992H21.3651H21.3697Z"
                        fill="#594E78"
                     />
                     <path
                        d="M35.0518 36.3711C36.4237 36.3805 37.6084 36.8487 38.587 37.8086C38.8164 38.0334 39.0318 38.2769 39.2472 38.5203C40.3195 39.7284 41.7242 39.5036 42.3423 38.024C43.4192 35.4346 44.4962 32.8406 45.5732 30.2465C45.7745 29.7689 45.9618 29.2819 46.1772 28.809C47.6381 25.6156 51.871 24.8617 54.3433 27.3574C54.8256 27.8444 55.2798 28.3688 55.72 28.898C56.324 29.6237 56.2397 30.6398 55.5467 31.2485C54.8537 31.8573 53.847 31.8057 53.1961 31.1174C52.8262 30.7288 52.4844 30.3167 52.1239 29.9234C51.1265 28.8371 49.7171 29.0712 49.1505 30.4338C47.9003 33.4259 46.6688 36.4226 45.414 39.4147C43.9015 43.0295 39.5141 43.7927 36.8545 40.9037C36.7093 40.7492 36.5735 40.59 36.4331 40.4355C35.4217 39.321 33.942 39.602 33.4316 41.0067C32.5232 43.5165 31.6289 46.0263 30.7299 48.536C30.1727 50.0953 29.6155 51.6545 29.0489 53.2091C28.7258 54.0941 27.8923 54.5483 27.0448 54.3282C26.0287 54.066 25.5183 53.0827 25.8883 52.0479C26.5953 50.0485 27.3164 48.0584 28.0328 46.0637C28.7914 43.952 29.5405 41.8308 30.3131 39.7237C31.067 37.6681 32.9072 36.3805 35.0611 36.3711H35.0518Z"
                        fill="#594E78"
                     />
                  </g>
                  <defs>
                     <clipPath id="clip0_121_4">
                        <rect
                           width="43.2"
                           height="43.2"
                           fill="#F6F9F6"
                           transform="translate(19.3329 17.5992)"
                        />
                     </clipPath>
                  </defs>
               </svg>
               <svg
                  class="arrow-svg"
                  viewBox="0 0 92 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M76.389 86.456L44.7184 86.3574C22.7297 86.2889 11.7354 86.2546 11.091 86.1684C0.863251 84.8003 -1.92232 77.1882 5.00663 69.5418C5.44324 69.06 15.4622 60.54 35.5 43.5C38.1483 41.2479 40.7606 38.7372 43.3226 36.0309C66.0472 12.027 77.4095 0.0249863 82.9183 1.22132C84.1708 1.49333 84.9127 1.79049 86.0067 2.45835C90.8181 5.39566 90.7598 20.2495 90.6433 49.9571L90.5553 72.389C90.5403 76.2233 90.5328 78.1405 90.0076 79.686C89.0002 82.6512 86.6618 84.9731 83.6897 85.9597C82.1404 86.474 80.2233 86.468 76.389 86.456Z"
                     fill="#594E78"
                     stroke="#594E78"
                  />
                  <mask
                     id="mask0_7777_3575"
                     style="mask-type: alpha"
                     maskUnits="userSpaceOnUse"
                     x="49"
                     y="47"
                     width="24"
                     height="24"
                  >
                     <rect
                        x="49"
                        y="47"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                     />
                  </mask>
                  <g mask="url(#mask0_7777_3575)">
                     <path
                        d="M63 65L61.6 63.55L65.15 60H53V58H65.15L61.6 54.45L63 53L69 59L63 65Z"
                        fill="white"
                     />
                  </g>
               </svg>
            </div>
         </a>

         <a
            class="screener-card text-decoration-none card-4"
            (click)="homeZakatTapped()"

         >
            <h6 class="mb-0">
               <span class="d-block fw-normal text-uppercase">Zakat</span>
               <span class="d-block fw-bold">Calculator</span>
            </h6>
            <div class="d-flex align-items-end justify-content-between">
               <!-- <svg class="screener-image" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="80" height="80" rx="40" fill="#F6F9F6"/>
                  <g clip-path="url(#clip0_121_4)">
                     <path d="M21.3697 17.5992C22.3811 17.9411 22.7323 18.6575 22.7323 19.7017C22.7136 31.6044 22.723 43.5071 22.723 55.4098C22.723 56.7865 23.3551 57.4186 24.727 57.4186C36.6297 57.4186 48.5324 57.4233 60.4352 57.4092C61.4793 57.4092 62.2004 57.7557 62.5376 58.7718V59.4461C62.1957 60.4575 61.4793 60.8087 60.4305 60.8087C48.509 60.7899 36.5876 60.7993 24.6662 60.7993C21.5102 60.7993 19.3423 58.6313 19.3423 55.4754C19.3423 43.5305 19.3469 31.595 19.3329 19.6595C19.3329 18.6247 19.7028 17.9317 20.6908 17.5992H21.3651H21.3697Z" fill="#AF9668"/>
                     <path d="M35.0518 36.3711C36.4237 36.3805 37.6084 36.8487 38.587 37.8086C38.8164 38.0334 39.0318 38.2769 39.2472 38.5203C40.3195 39.7284 41.7242 39.5036 42.3423 38.024C43.4192 35.4346 44.4962 32.8406 45.5732 30.2465C45.7745 29.7689 45.9618 29.2819 46.1772 28.809C47.6381 25.6156 51.871 24.8617 54.3433 27.3574C54.8256 27.8444 55.2798 28.3688 55.72 28.898C56.324 29.6237 56.2397 30.6398 55.5467 31.2485C54.8537 31.8573 53.847 31.8057 53.1961 31.1174C52.8262 30.7288 52.4844 30.3167 52.1239 29.9234C51.1265 28.8371 49.7171 29.0712 49.1505 30.4338C47.9003 33.4259 46.6688 36.4226 45.414 39.4147C43.9015 43.0295 39.5141 43.7927 36.8545 40.9037C36.7093 40.7492 36.5735 40.59 36.4331 40.4355C35.4217 39.321 33.942 39.602 33.4316 41.0067C32.5232 43.5165 31.6289 46.0263 30.7299 48.536C30.1727 50.0953 29.6155 51.6545 29.0489 53.2091C28.7258 54.0941 27.8923 54.5483 27.0448 54.3282C26.0287 54.066 25.5183 53.0827 25.8883 52.0479C26.5953 50.0485 27.3164 48.0584 28.0328 46.0637C28.7914 43.952 29.5405 41.8308 30.3131 39.7237C31.067 37.6681 32.9072 36.3805 35.0611 36.3711H35.0518Z" fill="#AF9668"/>
                  </g>
                  <defs>
                     <clipPath id="clip0_11490_7462">
                        <rect width="43.2" height="43.2" fill="white" transform="translate(19.3329 17.5992)"/>
                     </clipPath>
                  </defs>
               </svg> -->
               <svg
                  class="screener-image"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <rect width="50" height="50" rx="25" fill="#F6F9F6" />
                  <mask
                     id="mask0_11490_7462"
                     style="mask-type: alpha"
                     maskUnits="userSpaceOnUse"
                     x="9"
                     y="9"
                     width="32"
                     height="32"
                  >
                     <rect x="9" y="9" width="32" height="32" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_11490_7462)">
                     <path
                        d="M11.6666 35.6673V14.334H38.3333V35.6673H11.6666ZM14.3333 33.0007H35.6666V17.0007H14.3333V33.0007ZM19.6666 31.6673H22.3333V30.334H23.6666C24.0444 30.334 24.3611 30.2062 24.6166 29.9507C24.8722 29.6951 25 29.3784 25 29.0007V25.0007C25 24.6229 24.8722 24.3062 24.6166 24.0507C24.3611 23.7951 24.0444 23.6673 23.6666 23.6673H19.6666V22.334H25V19.6673H22.3333V18.334H19.6666V19.6673H18.3333C17.9555 19.6673 17.6388 19.7951 17.3833 20.0507C17.1277 20.3062 17 20.6229 17 21.0007V25.0007C17 25.3784 17.1277 25.6951 17.3833 25.9507C17.6388 26.2062 17.9555 26.334 18.3333 26.334H22.3333V27.6673H17V30.334H19.6666V31.6673ZM30.3333 30.6673L33 28.0007H27.6666L30.3333 30.6673ZM27.6666 22.334H33L30.3333 19.6673L27.6666 22.334Z"
                        fill="#AF9668"
                     />
                  </g>
               </svg>

               <svg
                  class="arrow-svg"
                  viewBox="0 0 92 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M76.389 86.456L44.7184 86.3574C22.7297 86.2889 11.7354 86.2546 11.091 86.1684C0.863251 84.8003 -1.92232 77.1882 5.00663 69.5418C5.44324 69.06 15.4622 60.54 35.5 43.5C38.1483 41.2479 40.7606 38.7372 43.3226 36.0309C66.0472 12.027 77.4095 0.0249863 82.9183 1.22132C84.1708 1.49333 84.9127 1.79049 86.0067 2.45835C90.8181 5.39566 90.7598 20.2495 90.6433 49.9571L90.5553 72.389C90.5403 76.2233 90.5328 78.1405 90.0076 79.686C89.0002 82.6512 86.6618 84.9731 83.6897 85.9597C82.1404 86.474 80.2233 86.468 76.389 86.456Z"
                     fill="rgba(175, 150, 104, 1)"
                     stroke="rgba(175, 150, 104, 1)"
                  />
                  <mask
                     id="mask0_7777_3575"
                     style="mask-type: alpha"
                     maskUnits="userSpaceOnUse"
                     x="49"
                     y="47"
                     width="24"
                     height="24"
                  >
                     <rect
                        x="49"
                        y="47"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                     />
                  </mask>
                  <g mask="url(#mask0_7777_3575)">
                     <path
                        d="M63 65L61.6 63.55L65.15 60H53V58H65.15L61.6 54.45L63 53L69 59L63 65Z"
                        fill="white"
                     />
                  </g>
               </svg>
            </div>
         </a>

         <a
            class="screener-card text-decoration-none card-3"
            routerLink="/cabinet/purification"
            (click)="homeStockPurificationTapped()"
         >
            <h6 class="mb-0">
               <span class="d-block fw-normal text-uppercase"
                  >Purification</span
               >
               <span class="d-block fw-bold">Calculator</span>
            </h6>
            <div class="d-flex align-items-end justify-content-between">
               <svg
                  class="screener-image"
                  viewBox="0 0 81 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <rect
                     x="0.133362"
                     width="80"
                     height="80"
                     rx="40"
                     fill="#f6f9f6"
                  />
                  <g clip-path="url(#clip0_121_35)">
                     <rect
                        width="41.6"
                        height="49.9993"
                        transform="translate(22.5334 16.0002)"
                        fill="#f6f9f6"
                     />
                     <path
                        d="M44.6311 38.8006C45.9289 39.2873 47.1725 39.7379 48.4162 40.2065C49.3535 40.549 50.3088 40.8914 51.246 41.2519C52.8682 41.8828 53.8235 43.0724 54.1299 44.7847C54.1479 44.9289 54.1479 45.0731 54.1659 45.2533C50.489 45.5777 47.533 47.1639 45.4422 50.1919C43.3514 53.22 42.9188 56.5545 43.9282 60.1053C43.045 60.1053 42.1978 60.1053 41.3327 60.1053C36.1237 60.1053 30.9146 60.1053 25.7056 60.1053C24.2637 60.1053 23.2003 59.132 23.1282 57.7081C22.9659 54.6259 22.8218 51.5257 22.6595 48.4436C22.6055 47.4703 22.5334 46.497 22.5334 45.5237C22.5153 43.559 23.5968 41.9549 25.4172 41.2519C27.5982 40.4228 29.7971 39.6478 31.9961 38.8367C32.0322 38.8367 32.0502 38.8367 32.1223 38.8006L36.3039 50.967H36.376C36.4301 50.3001 36.5022 49.6332 36.5562 48.9663C36.6824 47.5604 36.7906 46.1545 36.8987 44.7486C36.8987 44.5684 36.8446 44.3701 36.7725 44.2079C36.376 43.4869 35.9614 42.802 35.5649 42.081C35.1864 41.4141 35.5289 40.8193 36.3039 40.8193C37.6918 40.8193 39.0616 40.8193 40.4495 40.8193C41.2786 40.8193 41.603 41.4322 41.1885 42.1351C40.7739 42.8381 40.3594 43.541 39.9628 44.2439C39.8727 44.3881 39.8367 44.5864 39.8547 44.7666C39.9989 46.5691 40.1431 48.3895 40.2873 50.1919C40.3053 50.4443 40.3413 50.6966 40.4134 50.967L44.6491 38.7826L44.6311 38.8006Z"
                        fill="#DA808E"
                     />
                     <path
                        d="M38.2685 37.2153C36.5202 37.2513 35.0062 36.5303 33.7985 35.2146C32.3205 33.5924 31.1309 31.7899 30.5361 29.6451C30.5001 29.5369 30.41 29.4288 30.3018 29.3567C29.725 28.9241 29.4186 28.3473 29.3646 27.6444C29.3465 27.374 29.3285 27.0856 29.3646 26.8153C29.4006 26.4007 29.653 26.0763 30.0134 25.896C30.2117 25.7879 30.2478 25.6617 30.2478 25.4634C30.2117 24.7605 30.1576 24.0575 30.1576 23.3546C30.1576 22.1109 30.6984 21.0835 31.5815 20.2003C31.7077 20.0742 31.7978 19.912 31.8519 19.7497C32.2124 18.3078 33.0776 17.3345 34.4835 16.8478C36.0155 16.253 37.6017 15.8925 39.2419 16.0367C41.2606 16.199 43.1711 16.6676 44.6491 18.1997C46.019 19.6236 46.6679 21.3359 46.5417 23.3185C46.4876 24.0395 46.4696 24.7785 46.4696 25.4995C46.4696 25.6076 46.5597 25.7518 46.6498 25.8239C47.1725 26.1664 47.4068 26.635 47.3708 27.2478C47.3167 28.113 47.0824 28.888 46.3074 29.4107C46.2172 29.4648 46.1812 29.609 46.1452 29.7172C45.4783 32.0062 44.1985 33.9168 42.5223 35.5931C41.3687 36.7286 39.9809 37.2513 38.2866 37.2333L38.2685 37.2153ZM44.6491 28.5997C44.0003 28.4555 43.82 28.0229 43.838 27.4281C43.8561 26.5629 43.838 25.7158 43.838 24.8506C43.838 24.0936 43.2973 23.5168 42.5403 23.5168C39.7646 23.5168 36.9708 23.5168 34.1951 23.5168C33.402 23.5168 32.8793 24.0756 32.8793 24.8686C32.8793 25.7158 32.8613 26.5629 32.8793 27.4101C32.8973 28.0229 32.7531 28.4915 32.1043 28.6357C32.1043 28.7078 32.1043 28.7619 32.1043 28.8159C32.1223 28.9421 32.1583 29.0683 32.1944 29.1945C32.7531 31.1411 33.8346 32.7813 35.2224 34.2232C36.8987 35.9716 39.6564 35.8995 41.1524 34.4936C41.8374 33.8627 42.4141 33.1237 42.9729 32.3667C43.784 31.2492 44.3788 30.0055 44.6491 28.5816V28.5997Z"
                        fill="#DA808E"
                     />
                     <path
                        d="M45.4783 56.663C45.4422 51.454 49.768 47.2904 54.8689 47.3264C59.9698 47.3625 64.2595 51.6162 64.1514 56.8793C64.0432 61.854 59.9337 66.0176 54.7788 65.9995C49.6599 65.9815 45.4963 61.7999 45.4963 56.663H45.4783ZM54.7968 63.7285C58.7081 63.7285 61.8803 60.5742 61.8803 56.681C61.8803 52.7697 58.7081 49.5794 54.7968 49.5794C50.8675 49.5794 47.7313 52.7517 47.7313 56.681C47.7313 60.5742 50.8855 63.7105 54.7788 63.7105L54.7968 63.7285Z"
                        fill="#DA808E"
                     />
                     <path
                        d="M55.5178 61.187C55.5178 61.4214 55.5178 61.6377 55.5178 61.8539C55.4998 62.3406 55.1573 62.665 54.6887 62.665C54.2381 62.665 53.9136 62.3226 53.9136 61.8359C53.9136 61.4754 53.7334 61.2531 53.3729 61.169C53.2287 61.133 53.0665 61.0969 52.9223 61.0609C52.3996 60.8987 52.1473 60.43 52.3095 59.9434C52.4537 59.4747 52.9223 59.2765 53.445 59.3846C53.9136 59.4747 54.3823 59.5468 54.8509 59.5108C55.3015 59.5108 55.6259 59.2224 55.7161 58.7538C55.8062 58.3212 55.5899 57.9787 55.2294 57.7624C54.7788 57.5101 54.2921 57.3118 53.8235 57.0775C53.5712 56.9513 53.3008 56.8071 53.0665 56.6449C52.2374 56.0862 51.8408 55.3111 51.949 54.3198C52.0571 53.3465 52.5979 52.6616 53.4811 52.3011C53.8956 52.1389 54.0398 51.9406 54.0038 51.508C53.9677 51.0754 54.22 50.787 54.5986 50.6789C55.0852 50.5527 55.5538 50.8772 55.6079 51.3818C55.6079 51.5801 55.6079 51.7784 55.6079 51.9766C55.8422 52.0307 56.0405 52.0487 56.2388 52.1028C56.7434 52.229 57.0138 52.6976 56.8876 53.1663C56.7795 53.6169 56.3109 53.8512 55.8062 53.7791C55.4096 53.707 55.0131 53.6709 54.6166 53.689C54.2741 53.689 54.0038 53.9052 53.8956 54.2657C53.8055 54.5902 53.9317 54.8966 54.2921 55.1129C54.6346 55.3111 55.0131 55.4553 55.3556 55.6716C55.8963 55.9961 56.5091 56.2664 56.9597 56.699C58.0953 57.7444 58.0232 60.2318 55.8422 61.0429C55.7341 61.0789 55.6259 61.1149 55.4998 61.169L55.5178 61.187Z"
                        fill="#DA808E"
                     />
                  </g>
                  <defs>
                     <clipPath id="clip0_121_35">
                        <rect
                           width="41.6"
                           height="49.9993"
                           fill="#f6f9f6"
                           transform="translate(22.5334 16.0002)"
                        />
                     </clipPath>
                  </defs>
               </svg>
               <svg
                  class="arrow-svg"
                  viewBox="0 0 92 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M76.389 86.456L44.7184 86.3574C22.7297 86.2889 11.7354 86.2546 11.091 86.1684C0.863251 84.8003 -1.92232 77.1882 5.00663 69.5418C5.44324 69.06 15.4622 60.54 35.5 43.5C38.1483 41.2479 40.7606 38.7372 43.3226 36.0309C66.0472 12.027 77.4095 0.0249863 82.9183 1.22132C84.1708 1.49333 84.9127 1.79049 86.0067 2.45835C90.8181 5.39566 90.7598 20.2495 90.6433 49.9571L90.5553 72.389C90.5403 76.2233 90.5328 78.1405 90.0076 79.686C89.0002 82.6512 86.6618 84.9731 83.6897 85.9597C82.1404 86.474 80.2233 86.468 76.389 86.456Z"
                     fill="#DA808E"
                     stroke="#DA808E"
                  />
                  <mask
                     id="mask0_7777_3575"
                     style="mask-type: alpha"
                     maskUnits="userSpaceOnUse"
                     x="49"
                     y="47"
                     width="24"
                     height="24"
                  >
                     <rect
                        x="49"
                        y="47"
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                     />
                  </mask>
                  <g mask="url(#mask0_7777_3575)">
                     <path
                        d="M63 65L61.6 63.55L65.15 60H53V58H65.15L61.6 54.45L63 53L69 59L63 65Z"
                        fill="white"
                     />
                  </g>
               </svg>
            </div>
         </a>
      </div>
   </div>
</section>

<!-- <section class="section explore-section dashboard-item item-1">
   <h3 class="section-heading">Explore Musaffa</h3>

   <div class="explore-section-grid">
      <a routerLink="/cabinet/onboarding"
         (click)="homeStockScreenerTapped()"
         class="explore-card d-flex flex-column flex-xl-row align-items-center text-decoration-none">
         <img src="/assets/images/stock-screener.svg" alt="stock-screener"
            class="explore-card-image mb-2 mb-xl-0 me-xl-3">
         <div>
            <h6 class="explore-card-heading mb-0 mb-lg-2 text-white">Stock Screener</h6>
            <p class="d-none d-lg-block text-decoration-none text-white explore-card-link mb-0"><span
                  class="me-2">View</span> &#8594;</p>
         </div>
      </a>

      <a [routerLink]="etfUrl"
         (click)="homeETFScreenerTapped()"
         class="explore-card d-flex flex-column flex-xl-row align-items-center text-decoration-none">
         <img src="/assets/images/etf-screener.svg" alt="etf-screener"
            class="explore-card-image mb-2 mb-xl-0 me-xl-3">
         <div>
            <h6 class="explore-card-heading mb-0 mb-lg-2 text-white">ETF Screener</h6>
            <p class="d-none d-lg-block text-decoration-none text-white explore-card-link mb-0"><span
                  class="me-2">View</span> &#8594;</p>
         </div>
      </a>

      <a routerLink="/cabinet/purification"
         (click)="homeStockPurificationTapped()"
         class="explore-card d-flex flex-column flex-xl-row align-items-center text-decoration-none">
         <img src="/assets/images/stock-purification.svg" alt="stock-purification"
            class="explore-card-image mb-2 mb-xl-0 me-xl-3">
         <div>
            <h6 class="explore-card-heading mb-0 mb-lg-2 text-white">Stock Purification</h6>
            <p class="d-none d-lg-block text-decoration-none text-white explore-card-link mb-0"><span
                  class="me-2">View</span> &#8594;</p>
         </div>
      </a>
   </div>

</section> -->
