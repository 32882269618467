import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PublicList } from 'src/app/models/public-list/public-lists.model';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
   selector: 'app-list-table',
   templateUrl: './list-table.component.html',
   styleUrls: ['./list-table.component.scss'],
})
export class ListTableComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   userRole: string | null = null
   @Input() listLoader!: boolean;
   @Input() lists: PublicList[] = [];
   isMobile: boolean = false;
   @Input() page: number = 1;
   @Input() perPage: number = 10;
   @Input() totalItems: number = 0;
   @Output() sortEvent = new EventEmitter<{ column: string, sortOrder: 'asc' | 'desc' | '' }>();
   @Input() sortColumn: string = '';
   @Input() sortOrder?: 'asc' | 'desc' | '';
   listType!: string;

   constructor(
      private observer: BreakpointObserver,
      private router: Router,
      private route: ActivatedRoute
   ) {}

   ngOnInit(): void {
      SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
         this.userRole = data.role
      })

      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
         this.page = +params['page'] || 1;
         this.listType = params['lists'];
      });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByListId(index: number, list: PublicList): number | string {
      return list.id;
   }

   makeSlug(list: PublicList): string {
      return list.name.replace(/\s+/g, '-').toLowerCase() + '-' + list.id
   }

   navigateToPage(page: number): void {
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: { page },
         queryParamsHandling: 'merge',
      });
   }

   getSerialNumber(index: number): number {
      return (this.page - 1) * this.perPage + index + 1;
   }

   onSort(column: string) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.sortEvent.emit({ column, sortOrder: this.sortOrder });
   }

   getSortIcon(column: string) {
      return {
         'bi bi-sort-down-alt': this.sortColumn === column && this.sortOrder === 'asc',
         'bi bi-sort-up': this.sortColumn === column && this.sortOrder === 'desc',
         '': this.sortColumn === column && this.sortOrder === ""
      };
   }

}
