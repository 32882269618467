import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appStyleFeatureString]'
})
export class StyleFeatureStringDirective {
   @Input() appStyleFeatureString: string = ''; // Input text to process

   constructor(private el: ElementRef, private renderer: Renderer2) {}

   ngOnChanges(): void {
      if (!this.appStyleFeatureString) return;

      let processedText = this.appStyleFeatureString;

      processedText = processedText.replace(/\d+/g, match => `<b class="d-inline fw-600 text-black">${match}</b>`);

      processedText = processedText.replace(/\b(unlimited)\b/gi, match => `<span class="d-inline gradient-text fw-600">${match}</span>`);

      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');

      this.el.nativeElement.innerHTML = processedText;
   }

}
