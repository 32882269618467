import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import {
   CoverageBrokerage,
   BrokerageCoverageApiResponse,
} from 'src/app/features/pricing/models/brokerage-coverage.model';
import { MarketCoverage } from 'src/app/features/pricing/models/market-coverage.model';
import { PricingService } from 'src/app/features/pricing/services/pricing.service';
import { ConnectIframeModalComponent } from '../../connect-iframe-modal/connect-iframe-modal.component';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-brokerage-coverages',
   templateUrl: './brokerage-coverages.component.html',
   styleUrls: ['./brokerage-coverages.component.scss'],
})
export class BrokerageCoveragesComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   @Input() isFromPortfolio: boolean = false;
   features: string[] = [
      'Portfolio Tracking',
      'Trading',
      'Automated Purification',
   ];
   activeFeature: string = this.features[0];
   brokeragesLoader: boolean = true;
   countriesLoader: boolean = false;
   countries: MarketCoverage[] = [];
   selectedCountry: any;
   allBrokerages: CoverageBrokerage[] = [];
   brokerages: CoverageBrokerage[] = [];
   filteredBrokerages: CoverageBrokerage[] = [];
   isConnectLoading: boolean = false;
   currBrokerageId: string | null = null;
   connectIframeModalRef: any;
   redirectUrl: string | null = null;
   maxBrokergaeAccountCount: number = 0;

   constructor(
      private pricingService: PricingService,
      private modalService: NgbModal,
      private portfolioServices: PortfolioService,
      private authService: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private sharedService: SharedService,
      private accessControlService: AccessControlService
   ) {}

   ngOnInit(): void {
      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .subscribe((permissions) => {
            // this.isPremiumUser = permission;
            this.maxBrokergaeAccountCount =
               permissions.brokerage_connected_portfolios_count;
         });
      this.getCountries();
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getSelectedCountryBrokerages(country: MarketCoverage) {
      // this.getBrokerageCoverages(country.country_code)
      this.selectedCountry = country;
      this.filterBySupportedFeatures(this.activeFeature, this.allBrokerages);
   }

   filterBySupportedFeatures(feature: string, brokerages: CoverageBrokerage[]) {
      this.activeFeature = feature;
      if (this.selectedCountry.country_code === 'all') {
         this.brokerages = this.allBrokerages.sort(
            (a: CoverageBrokerage, b: CoverageBrokerage) =>
               a.name.localeCompare(b.name)
         );
      } else {
         this.brokerages = this.allBrokerages.filter((brokerage) =>
            brokerage.countries.some(
               (country_code: string) =>
                  country_code === this.selectedCountry.country_code
            )
         );
      }
      switch (feature) {
         case 'Portfolio Tracking':
            this.filteredBrokerages = this.brokerages.filter(
               (brokerage: CoverageBrokerage) =>
                  brokerage.supported_features.is_portfolio_linking_allowed
            );
            break;
         case 'Trading':
            this.filteredBrokerages = this.brokerages.filter(
               (brokerage: CoverageBrokerage) =>
                  brokerage.supported_features.is_trading_allowed
            );
            break;
         case 'Automated Purification':
            this.filteredBrokerages = this.brokerages.filter(
               (brokerage: CoverageBrokerage) =>
                  brokerage.supported_features.automated_purification
            );
            break;
      }
   }

   getCountries() {
      this.countriesLoader = true;
      this.pricingService
         .getBrokerageCountries()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.selectedCountry = {
                  country_code: 'all',
                  country_name: 'All Countries',
               };
               this.countries = [
                  this.selectedCountry,
                  ...response.sort(
                     (a: MarketCoverage, b: MarketCoverage) => a.id - b.id
                  ),
               ];

               this.countriesLoader = false;
               // const  countryCode = localStorage.getItem('countryCode')
               // if(!countryCode) {
               //    this.selectedCountry = this.countries[0]
               // } else {
               //    this.selectedCountry = this.countries.find((country) => country.country_code === countryCode) ?? this.countries[0];
               // }
               this.getAllBrokerageCoverageInfo(
                  this.selectedCountry.country_code
               );
            },
            error: (error) => {
               console.error(error);
               this.countriesLoader = false;
            },
         });
   }

   getAllBrokerageCoverageInfo(countryCode: string) {
      this.brokeragesLoader = true;
      this.pricingService
         .fetchBrokeragesCoverage<BrokerageCoverageApiResponse>(countryCode)
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.allBrokerages = response.data;
               if (this.allBrokerages) {
                  this.filterBySupportedFeatures(
                     this.activeFeature,
                     this.allBrokerages
                  );
               } else {
                  this.filteredBrokerages = [];
               }
               this.brokeragesLoader = false;
            },
            error: (error) => {
               console.error(error);
               this.brokeragesLoader = false;
            },
         });
   }

   openConnectIframeModal() {
      this.connectIframeModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
      this.connectIframeModalRef.componentInstance.module = 'portfolio';
   }

   closeConnectIframeModal() {
      if (this.connectIframeModalRef) {
         this.connectIframeModalRef.dismiss();
      }
   }

   initiateBrokerage(brokerage_id: any) {
      if (!this.isFromPortfolio) return;

      if (!this.maxBrokergaeAccountCount) {
         this.accessControlService.accessDenied();
         return;
      }
      this.currBrokerageId = brokerage_id;
      this.isConnectLoading = true;
      this.portfolioServices
         .getConnectUrl(brokerage_id, true)
         .then((resp: any) => {
            if (resp.status) {
               this.isConnectLoading = false;
               this.closeConnectIframeModal();
               this.redirectUrl = resp.data.redirect_url;
               this.openConnectIframeModal();
            }
         });
   }
}
