import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

interface TradingTool {
   id: string,
   image_url: string,
   link_url: string,
   heading: string,
   description: string
}

@Component({
   selector: 'app-trading-tools',
   templateUrl: './trading-tools.component.html',
   styleUrls: ['./trading-tools.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})

export class TradingToolsComponent implements OnInit {
   tradingTools: TradingTool[] = [
      {
         id: 'stock-screener',
         image_url: '/assets/images/dashboard/stock-screener.png',
         link_url: '/stock-screener',
         heading: 'Stock Screener',
         description: 'Explore stocks around the world with advanced filters & find their shariah compliance'
      },
      {
         id: 'etf-screener',
         image_url: '/assets/images/dashboard/etf-screener.png',
         link_url: '/etf-screener',
         heading: 'ETF Screener',
         description: 'Explore US ETFs and find the Halal ones using Musaffa\'s detailed shariah analysis report.'
      },
      {
         id: 'purification',
         image_url: '/assets/images/dashboard/purification-calculator.png',
         link_url: '/purification',
         heading: 'Purification Calculator',
         description: 'Did you know that investing in halal stocks still needs purification? Calculate your purification amount now.'
      },
      {
         id: 'zakat-calculator',
         image_url: '/assets/images/dashboard/zakat-calculator.png',
         link_url: '/zakat-calculator',
         heading: 'Zakat Calculator',
         description: 'With built-in portfolio import, Musaffa makes it easier to calculate your overall Zakat amount.'
      }
   ]

   constructor() { }

   ngOnInit(): void {
   }

   trackByFn(index: number, item: TradingTool) {
      return item.id;
   }

}
