<section>
   <ng-container>
      <div class="loader-container" *ngIf="loading">
         <ngx-skeleton-loader count="6" [theme]="{ 'border-radius': '5px', height: '70px', 'background-color': '#ededed' }" > </ngx-skeleton-loader>
      </div>
   </ng-container>

   <ng-container *ngIf="!loading">
      <ng-container *ngIf="canReadForecast || isSample">
         <div class="d-flex flex-column" *ngIf="isDataAvailable()">
            <div class="forecast-container-pricing" *ngIf="showPriceChart" >
               <app-forecast-price-data-section
                  heading="Price Forecast"
                  [currentYear]="currentYear"
                  [currentValue]="priceForecastData.current"
                  [nextYear]="nextYear"
                  [highValue]="priceForecastData.high"
                  [medianValue]="priceForecastData.median"
                  [lowValue]="priceForecastData.low"
                  [currency]="currency">
                     <app-forecast-price-chart
                        [priceChartData]="priceChartData"
                        [current]="priceForecastData.current"
                        [forecastHigh]="priceForecastData.high"
                        [forecastMedian]="priceForecastData.median"
                        [currency]="currency"
                        [forecastLow]="priceForecastData.low">
                     </app-forecast-price-chart>
               </app-forecast-price-data-section>
               <div class="bubble-wrapper" *ngIf="isSample">
                  <app-sample-bubble [bubbleContent]="priceForecastText" tooltipPosition="bottom" ></app-sample-bubble>
               </div>
            </div>

            <div class="forecast-container" *ngIf="showRevenueChart" >
               <app-forecast-data-section
                  [tableData]="revenueChartTableData"
                  heading="Revenue Forecast"
                  subheading="Revenue Forecasts"
                  subheadingPara="An earnings surprise, or unexpected earnings, in accounting, is the difference between the reported earnings and the expected earnings of an entity."
                  [currency]="currency">
                     <app-forecast-data-bar-chart
                        chartId="revenueChart"
                        [chartData]="revenueChartData" >
                     </app-forecast-data-bar-chart>
               </app-forecast-data-section>
               <div class="bubble-wrapper" *ngIf="isSample">
                  <app-sample-bubble
                     [bubbleContent]="revenueForecastText"
                     tooltipPosition="bottom">
                  </app-sample-bubble>
               </div>
            </div>

            <div class="forecast-container mb-0" *ngIf="showEarningsChart" >
               <app-forecast-data-section
                  heading="Earnings Per Share Forecast"
                  subheading="Earnings Surprises Amount"
                  [tableData]="earningsChartTableData"
                  subheadingPara="An earnings surprise, or unexpected earnings, in accounting, is the difference between the reported earnings and the expected earnings of an entity."
                  [currency]="currency">
                     <app-forecast-data-bar-chart
                        chartId="earningsChart"
                        [chartData]="earningsChartData">
                     </app-forecast-data-bar-chart>
               </app-forecast-data-section>
               <div class="bubble-wrapper" *ngIf="isSample" style="right: 36%">
                  <app-sample-bubble
                     [bubbleContent]="earningsForecastText"
                     tooltipPosition="bottom">
                  </app-sample-bubble>
               </div>
            </div>
         </div>

         <ng-container *ngIf="!isSample">
            <ng-container *ngIf="!isDataAvailable()">
               <app-no-data-card heading="Forecasts"></app-no-data-card>
            </ng-container>
         </ng-container>
      </ng-container>

      <ng-container *ngIf="!(canReadForecast || isSample)">
         <app-feature-locked-card
            heading="Stock Forecast"
            text="View the estimated PRICE, REVENUE & EARNINGS forecast of the stock. Make informed decision about your investment growth before investing!"
            (viewSampleClicked)="onViewSampleClicked('forecast')"
            componentPage="stock_forcast"
         >
         </app-feature-locked-card>
      </ng-container>
   </ng-container>
</section>

<!-- <section>
   <ng-container>
      <div class="loader-container" *ngIf="loading">
         <ngx-skeleton-loader
            count="6"
            [theme]="{
               'border-radius': '5px',
               height: '70px',
               'background-color': '#ededed'
            }">
         </ngx-skeleton-loader>
      </div>
   </ng-container>

   <ng-container *ngIf="userRole === 'PREMIUM' || isSample">
      <ng-container *ngIf="!loading && !isDataAvailable()">
         <app-no-data-card heading="Forecasts"></app-no-data-card>
      </ng-container>

      <ng-container *ngIf="!loading && isDataAvailable()">
         <div class="d-flex flex-column">

            <div class="forecast-container mb-4 px-2 pt-2" *ngIf="showPriceChart">
               <app-forecast-price-data-section
                  heading="Price Forecasts"
                  [currentYear]="currentYear"
                  [currentValue]="priceForecastData.current"
                  [nextYear]="nextYear"
                  [highValue]="priceForecastData.high"
                  [medianValue]="priceForecastData.median"
                  [lowValue]="priceForecastData.low"
                  [currency]="currency"
               >
                  <app-forecast-price-chart [priceChartData]="priceChartData"
                     [current]="priceForecastData.current"
                     [forecastHigh]="priceForecastData.high"
                     [forecastMedian]="priceForecastData.median"
                     [currency]="currency"
                     [forecastLow]="priceForecastData.low">
                  </app-forecast-price-chart>
               </app-forecast-price-data-section>
            </div>

            <div class="forecast-container mb-4 p-2 " *ngIf="revenueChartData?.labels?.length">
               <app-forecast-data-section
                  [tableData]="revenueChartTableData"
                  heading="Revenue Forecasts"
                  subheading="Revenue Forecasts"
                  subheadingPara="An earnings surprise, or unexpected earnings, in accounting, is the difference between the reported earnings and the expected earnings of an entity."
               >
                  <app-forecast-data-bar-chart
                     chartId="revenueChart"
                     [chartData]="revenueChartData">
                  </app-forecast-data-bar-chart>
               </app-forecast-data-section>
            </div>

            <div class="forecast-container mb-0 p-2" *ngIf="earningsChartData?.labels?.length">
               <app-forecast-data-section
                  heading="Earnings Forecasts"
                  subheading="Earnings Surprises Amount"
                  [tableData]="earningsChartTableData"
                  subheadingPara="An earnings surprise, or unexpected earnings, in accounting, is the difference between the reported earnings and the expected earnings of an entity."
               >
                  <app-forecast-data-bar-chart
                     chartId="earningsChart"
                     [chartData]="earningsChartData">
                  </app-forecast-data-bar-chart>
               </app-forecast-data-section>
            </div>

         </div>
      </ng-container>
   </ng-container>

   <ng-container *ngIf="!(userRole === 'PREMIUM' || isSample)">
      <app-feature-locked-card
         heading="Stock Forecast"
         text="View the estimated PRICE, REVENUE & EARNINGS forecast of the stock. Make informed decision about your investment growth before investing!"
         (viewSampleClicked)="onViewSampleClicked('forecast')">
      </app-feature-locked-card>
  </ng-container>

</section>
 -->
