import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-halal-circular-progress-card',
  templateUrl: './halal-circular-progress-card.component.html',
  styleUrls: ['./halal-circular-progress-card.component.scss']
})
export class HalalCircularProgressCardComponent implements OnInit {
  @Input() percentage: number | undefined;
  @Input() cardType! : string;
  constructor() { }

  ngOnInit(): void {
    
  }

  calculateDashOffset(): string {
    const circumference = Math.PI * 2 * 50; // Circumference of the circle
    const dashOffset = circumference - (circumference * (this.percentage || 0)) / 100;
    return dashOffset.toString();
  }

  get tabType(): string {
    if (this.cardType === 'light-green') {
      return 'light-card';
    } else if (this.cardType === 'dark-green') {
      return 'dark-card';
    } else if (this.cardType === 'small-green') {
      return 'small-green';
    } else {
      return ''; 
    }
  }

}