<div class="d-flex gap-1 align-items-center justify-content-center">


   <ng-container *ngIf="!onlyRanking && (preloaded == true || complianceStatus)">
      <div
         class="compliance-chip halal-chip d-flex align-items-center justify-content-center flex-column {{ alignment }}"
         *ngIf="complianceStatus == 'COMPLIANT'" [ngClass]="{ 'only-compliance-wrapper': onlyCompliance }">
         <span class="chip-text px-0" [ngClass]="{
               'lh-1 mb-1': showRanking && !onlyCompliance,
               'pt-0': !onlyCompliance
            }">Halal</span>
         <!-- <div *ngIf="!onlyCompliance">
            <div
               class="ranking-locked-wrapper d-flex align-items-center justify-content-center pb-1"
               *ngIf="!showRanking"
            >
               <i class="icon-lock"></i>
            </div>
         </div> -->
      </div>

      <div class="compliance-chip d-flex align-items-center justify-content-center not-halal-chip p-2 {{ alignment }}"
         *ngIf="complianceStatus == 'NON_COMPLIANT'" [ngClass]="{ 'only-compliance-wrapper': onlyCompliance }">
         <span class="chip-text" [ngClass]="{ 'py-2': !onlyCompliance }">Not Halal</span>
      </div>

      <div class="compliance-chip d-flex align-items-center justify-content-center doubtful-chip p-2 {{ alignment }}"
         *ngIf="complianceStatus == 'QUESTIONABLE'" [ngClass]="{ 'only-compliance-wrapper': onlyCompliance }">
         <span class="chip-text" [ngClass]="{ 'py-2': !onlyCompliance }">Doubtful</span>
      </div>

      <div class="compliance-chip d-flex align-items-center justify-content-center not-covered-chip p-2 {{ alignment }}"
         *ngIf="
            complianceStatus == 'NOT_UNDER_COVERAGE' ||
            complianceStatus == 'unknown' ||
            (preloaded && !complianceStatus)
         " [ngClass]="{ 'only-compliance-wrapper': onlyCompliance }">
         <span class="chip-text" [ngClass]="{ 'py-2': !onlyCompliance }">Not Covered</span>
      </div>
   </ng-container>


   <ng-container *ngIf="!onlyRanking && (preloaded == true || complianceStatus)">
      <div *ngIf="!onlyCompliance">
         <div class="ranking-locked-wrapper d-flex align-items-center justify-content-center pb-1"
            *ngIf="!showRanking && !showCompliance">
            <i class="icon-lock status_grade"></i>
         </div>
         <div class="ranking-display" *ngIf="showRanking || showCompliance">
            <span class="status_grade" [ngClass]="{'rating-bg': !!complianceRankingV2}">{{ complianceRankingV2 |
               rankingGrade }}</span>
         </div>
      </div>

      <div class="ranking-locked-wrapper d-flex align-items-center justify-content-center py-1 cursor-pointer"
         *ngIf="complianceStatus == 'LOCKED'" (click)="accessControlService.accessDenied()">
         <span class="d-flex align-items-center justify-content-center">
            <i class="icon-lock me-1"></i> Unlock
         </span>
      </div>
   </ng-container>
</div>




<ng-container *ngIf="preloaded == null && !complianceStatus && !onlyRanking">
   <div class="compliance-chip d-flex align-items-center justify-content-center loading-chip p-2"
      [ngClass]="{ 'only-compliance-wrapper': onlyCompliance }">
      <span class="chip-text" [ngClass]="{ 'py-2': !onlyCompliance }"></span>
   </div>
</ng-container>




<ng-container *ngIf="onlyRanking">
   <div class="ranking-locked-wrapper d-flex align-items-center justify-content-center py-1 cursor-pointer"
      placement="top" *ngIf="!showRanking; else rankingTemplate" (click)="accessControlService.accessDenied()">
      <i class="icon-lock me-1"></i>
      <span>Unlock</span>
   </div>
   <ng-template #rankingTemplate>
      <div class="ranking-display" *ngIf="complianceRankingV2 > 0">
         <span class="status_grade" [ngClass]="{'rating-bg': !!complianceRankingV2}">{{ complianceRankingV2 |
            rankingGrade }}</span>
      </div>
      <div *ngIf="!complianceRankingV2">-</div>
      <!-- <div class="d-inline-flex flex-shrink-0 rounded-3 align-items-center gap-1 star-chip"
         *ngIf="complianceRanking > 0">
         <ng-container *ngFor="let i of [1, 2, 3, 4, 5]">

            <svg *ngIf="complianceRanking >= i" class="flex-shrink-0" width="12" height="12"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#009000">
               <path
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
            </svg>


            <svg *ngIf="complianceRanking < i" class="flex-shrink-0" width="12" height="12"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#CCCCCC">
               <path
                  d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
            </svg>
         </ng-container>
      </div> -->
   </ng-template>
</ng-container>