import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SortService } from 'src/app/shared/services/sort.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';

@Component({
  selector: 'app-trending-stocks-table',
  templateUrl: './trending-stocks-table.component.html',
  styleUrls: ['./trending-stocks-table.component.scss']
})
export class TrendingStocksTableComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   isLoggedIn: boolean = false;
   canReadTrendingStocks: boolean = false;
   canReadStockComplianceRating: boolean = false;
   isMobile: boolean = false;

   @Input() data!: any[]
   @Input() loading!: boolean
   @Input() tableId!: string | undefined;
   @Input() originalStocks: any[] = [];

   sortColumn: string = '';
   sortOrder: 'asc' | 'desc' | '' = '';

   constructor(
      public auth: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService,
      private observer: BreakpointObserver,
      private sortService: SortService,
   ) { }

   ngOnInit(): void {
      this.isLoggedIn = !!this.auth.getUserId();
      this.permissionStore.select(({permissions}) => permissions.permissions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
         (permissions) => {
            this.isLoggedIn = !!this.auth.getUserId();
            this.canReadTrendingStocks = permissions.trending_halal_stocks;
            this.canReadStockComplianceRating = permissions.stock_halal_rating;
         }
      )

      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByStockId(index: number, stock: any) {
      return stock.identifier;
   }

   getColumnSortClasses(column: string) {
      return {
        'bi bi-sort-down-alt ms-2': this.sortColumn === column && this.sortOrder === 'asc',
        'bi bi-sort-up ms-2': this.sortColumn === column && this.sortOrder === 'desc',
        'ms-2': this.sortColumn === column && this.sortOrder === ''
      };
   }

   getPercentChangeClasses(stock: any) {
      return {
         'positive' : stock.percentChange >= 0,
         'negative' : stock.percentChange < 0
      }
   }

   getIconClasses(stock: any) {
      return {
         'bi-caret-up-fill positive' : stock.percentChange >= 0,
         'bi-caret-down-fill negative' : stock.percentChange < 0,
      }
   }

   filtereredStocks(): any[] {
      if (!this.canReadTrendingStocks) {
         return this.data.slice(0, 3);
      } else {
         return this.data;
      }
   }

   onSort(column: string, sortOrder?: string) {
      if(!this.isLoggedIn) return

      if (column !== this.sortColumn) {
         this.resetSorting();
      }

      this.sortColumn = column;

      if (sortOrder === 'asc') {
         this.data = this.sortService.sortData(this.data, column, 'asc');
         this.sortOrder = 'asc';
      } else if (sortOrder === 'desc') {
         this.data = this.sortService.sortData(this.data, column, 'desc');
         this.sortOrder = 'desc';
      } else {
         if (this.sortOrder === 'asc') {
            this.data = this.sortService.sortData(this.data, column, 'desc');
            this.sortOrder = 'desc';
         } else if (this.sortOrder === 'desc') {
            this.data = [...this.originalStocks];
            this.sortOrder = '';
         } else {
            this.data = this.sortService.sortData(this.data, column, 'asc');
            this.sortOrder = 'asc';
         }
      }
   }

   resetSorting() {
      this.data = [...this.originalStocks];
      this.sortOrder = '';
      this.sortColumn = '';
   }

   canSortCompliance() {
      return this.data.length > 1 && this.canReadStockComplianceRating && !this.isMobile
   }

   canSortPriceAndChange() {
      return this.data.length > 1 && this.canReadTrendingStocks
   }

   sortPrice(tableId: string) {
      if(tableId === 'most_active' || tableId === 'most_popular' || tableId === 'top_losers') {
         this.onSort('currentPrice')
         // this.onSort('price')
      } else {
         this.onSort('currentPrice')
         // this.onSort('lastClosingPrice')
      }
   }

   getPriceSortClasses(tableId: string) {
      if(tableId === 'most_active' || tableId === 'most_popular' || tableId === 'top_losers') {
         this.sortColumn === 'currentPrice'
         // this.sortColumn === 'price'
      } else {
         this.sortColumn === 'currentPrice'
         // this.sortColumn === 'lastClosingPrice'
      }

      return {
        'bi bi-sort-down-alt ms-2': this.sortColumn && this.sortOrder === 'asc',
        'bi bi-sort-up ms-2': this.sortColumn && this.sortOrder === 'desc',
        'ms-2': this.sortColumn && this.sortOrder === ''
      };
   }

}
