import {
   ChangeDetectionStrategy,
   Component,
   Input,
   OnInit,
   OnChanges,
   ViewEncapsulation,
   SimpleChanges,
   ChangeDetectorRef,
   NgZone,
   ElementRef,
   ViewChild,
   EventEmitter,
   Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '../../services/filter.service';
import { CollectionComplianceService } from '../../services/collection-compliance.service';
import { AuthService } from '../../services/auth.service';
import { AccessControlService } from '../../services/access-control.service';
import { Subject, takeUntil } from 'rxjs';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { getCountryNameByCode } from 'src/app/utils/countries';


@Component({
   selector: 'app-dynamic-table',
   templateUrl: './dynamic-table.component.html',
   styleUrls: ['./dynamic-table.component.scss'],
   //encapsulation: ViewEncapsulation.None,
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicTableComponent implements OnInit, OnChanges {
   @ViewChild('tableContainer') tableContainer!: ElementRef;
   @Input() showCompliance?: boolean;
   @Input() showComplianceRanking?: boolean;
   @Input() columns: {
      header: string;
      key: any;
      type?: string;
      showPositiveNegative?: boolean;
      commodity_type?: string;
      sortable?: boolean;
      key2?: string;
      type2?: string;
      showPositiveNegative2?: boolean;
      format?: (value: any) => string;
      filterResultFormatFn?: (value: string) => string;
   }[] = [];
   @Input() data!: any[];
   @Input() dataCount!: number;
   @Input() isDataLoading!: boolean;
   @Input() activeFiltersCount: number = 0;
   @Input() pageSize: number = 15;
   @Input() page: number = 1;
   @Input() showResetButton: boolean = false;

   @Input() hasAccess: boolean = false;

   @Input() sortColumn: string = '';
   @Input() sortOrder: string = '';
   @Input() isSortingEnabled: boolean = false;
   @Input() showSortingArrows: boolean = false;

   @Input() showCta: boolean = false;
   @Input() type: string = '';
   @Input() shouldShowCountAndActiveFilters: boolean = true;

   @Output() scrolled = new EventEmitter<void>();

   // public nameField: string = '';
   // public rankingField: string = 'ranking';
   // public complianceField: string = 'sharia_compliance';
   public complianceFields: string[] = ['shariahCompliantStatus', 'sharia_compliance'];
   public rankingFields: string[] = ['ranking', 'musaffaHalalRating', 'ranking_v2'];
   public isPaginationDisabled: boolean = false;

   onTableScroll(event: any): void {
      const element = event.target;

      // Calculate the exact position
      const scrollPosition = Math.ceil(
         element.scrollTop + element.clientHeight
      );
      const scrollHeight = element.scrollHeight;

      // Check if we've exactly reached the bottom
      if (scrollHeight - scrollPosition <= 1) {
         this.scrolled.emit();
      }
   }

   getRowType(row: any) {
      return (row?.commodity_type === 'etf' || this.type === 'etf') ? 'etf' : 'stock';
   }

   getRatingField() {
      return 'ranking_v2'
   }

   getComplianceField(row: any) {
      return this.getRowType(row) === 'etf' ? 'shariahCompliantStatus' : 'sharia_compliance'
   }

   getSymbolField(row: any) {
      return this.getRowType(row) === 'etf' ? 'symbol' : 'ticker'
   }

   getSymbol(row: any) {
      return row?.symbol ?? row?.ticker ?? row?.id
   }

   getName(row: any) {
      return row?.companyName ?? row?.name ?? row?.ticker
   }


   // renderCount = 0;
   // Range Slider
   greenValue = 40; // Initial value for green slider
   orangeValue = 30; // Initial value for orange slider


   redValue = 30; // Initial value for red slider

   // Minimum width in percentage
   greenMinWidth!: number;
   orangeMinWidth!: number;
   redMinWidth!: number;
   isLoggedIn: boolean = true;

   @Input() isUsedInWatchlist: boolean = false;
   @Output() deleteClicked = new EventEmitter<any>();

   // Calculate width based on min value
   calculateMinWidth(min: number, max: number): number {
      return (min / max) * 100; // Percentage calculation
   }

   constructor(
      private cdr: ChangeDetectorRef,
      private route: ActivatedRoute,
      private router: Router,
      private collectionCompliance: CollectionComplianceService,
      private filterService: FilterService,
      public accessControlService: AccessControlService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private authService: AuthService
   ) {
      this.page = this.route.snapshot.queryParams['page'] ?? 1;
   }

   destroy$ = new Subject<void>();
   allowHalalRating: boolean = true;

   ngOnInit(): void {
      //console.log("columns are",this.columns)
      // console.log("data is",this.data)

      this.permissionStore
         .select(
            ({ permissions }) => permissions.permissions.stock_halal_rating
         )
         .pipe(takeUntil(this.destroy$))
         .subscribe((permissions) => {
            this.isLoggedIn = !!this.authService.getUserId();
            this.allowHalalRating = this.isLoggedIn && permissions;
            this.getAccessibleComplianceSymbols();
         });
      this.route.queryParams.subscribe((params: any) => {
         // const filterCount = params
         //    ? Object.keys(params).filter(
         //         (key) =>
         //            key.startsWith('filters[') &&
         //            params[key] !== null &&
         //            params[key] !== undefined &&
         //            params[key] !== '' &&
         //            ((Array.isArray(params[key]) && params[key].length > 0) ||
         //               (!Array.isArray(params[key]) && params[key] !== ''))
         //      ).length
         //    : 0;
         this.filterService.counts$.pipe(takeUntil(this.destroy$)).subscribe((counts) => {
            this.activeFiltersCount = counts.total;
         });
         this.getAccessibleComplianceSymbols();
         // this.cdr.detectChanges();
      });

      this.greenMinWidth = this.calculateMinWidth(40, 100); // min, max
      this.orangeMinWidth = this.calculateMinWidth(30, 100); // min, max
      this.redMinWidth = this.calculateMinWidth(30, 100);
      this.currentSortColumn = this.sortColumn;
      this.isAscending = this.sortOrder === 'asc';
   }

   resetAllFilters() {
      const currentParams = { ...this.route.snapshot.queryParams };
      const updatedParams: { [key: string]: undefined } = {};

      Object.keys(currentParams)
         .filter((key) => key.startsWith('filters['))
         .forEach((key) => {
            updatedParams[key] = undefined;
         });
      this.filterService.clearAppliedFilters();
      this.router.navigate([], {
         queryParams: updatedParams,
         queryParamsHandling: 'merge',
         replaceUrl: true,
      });
      this.cdr.markForCheck();
   }

   getPositiveNegativeArrow(value: number): string {
      return value < 0 ? 'icon-caret-down' : 'icon-caret-up';
   }

   ngOnChanges(changes: SimpleChanges): void {
      // console.log("Data from dynamic Table :",this.data)

      if (changes['data'] && this.data) {
         setTimeout(() => {
            this.scrollToPosition();
         });
         this.getAccessibleComplianceSymbols();

         // const firstRow = this.data[0];

         // if (firstRow?.companyName) {
         //    this.nameField = 'companyName';
         // } else if (firstRow?.name) {
         //    this.nameField = 'name';
         // } else {
         //    this.nameField = 'ticker';
         // }

         // if (this.type === 'etf') {
         //    this.rankingField = 'musaffaHalalRating';
         //    this.complianceField = 'shariahCompliantStatus';
         // }
      }

      // const firstRow = this.data[0];
      // if (firstRow?.companyName) {
      //    this.nameField = 'companyName';
      // } else if (firstRow?.name) {
      //    this.nameField = 'name';
      // } else {
      //    this.nameField = 'ticker';
      // }
      // this.renderCount++;
      // console.log(`Render count: ${this.renderCount}`);
      // this.cdr.markForCheck();
      // console.log(this.data)
   }

   trackByFn(index: number, item: any): any {
      return item.id;
   }

   onDelete(stock: any) {
      this.deleteClicked.emit(stock);
   }

   getCellClass(
      columnField: string,
      value: any,
      showPositiveNegative?: boolean
   ): string {
      const complianceClasses: { [key: string]: string } = {
         COMPLIANT: 'compliance-chip halal-chip',
         NON_COMPLIANT: 'compliance-chip not-halal-chip',
         QUESTIONABLE: 'compliance-chip doubtful-chip',
         ranking_v2: 'rating_grade', // Ensure this class is used
         ranking : 'rating_grade',
         default: 'compliance-chip not-covered-chip',
      };

      const analystsRatingClasses: { [key: string]: string } = {
         'Strong sell': 'analysts-rating strong-sell-class',
         Sell: 'analysts-rating sell-class',
         SELL: 'analysts-rating sell-class',
         Hold: 'analysts-rating hold-class',
         Buy: 'analysts-rating buy-class',
         BUY: 'analysts-rating buy-class',
         'Strong buy': 'analysts-rating strong-buy-class',
      };

      // ✅ Explicitly check for ranking_v2 first
      if (columnField === 'ranking_v2') {
         return complianceClasses['ranking_v2']; // Will return 'ranking-text'
      }

      if (
         columnField === 'status' ||
         this.rankingFields.includes(columnField) ||
         this.complianceFields.includes(columnField) ||
         columnField === 'shariahStates'
      ) {
         return complianceClasses[value] || complianceClasses['default'];
      }

      if (columnField === 'recommendationWeightedAverage' || columnField === 'type') {
         return analystsRatingClasses[value] || '';
      }

      if (showPositiveNegative) {
         return value < 0 ? 'down_price' : 'up_price';
      }

      this.cdr.markForCheck();
      return '';
   }

   getFormattedValues(key: string, value: any, type?: string) {
      const complianceDisplayValues: { [key: string]: string } = {
         COMPLIANT: 'Halal',
         NON_COMPLIANT: 'Not Halal',
         QUESTIONABLE: 'Doubtful',
         default: 'Not Covered',
      };
      const analystsRatingDisplayValues: { [key: string]: string } = {
         'strong sell': 'Strong Sell',
         sell: 'Sold',
         hold: 'Hold',
         buy: 'Buy',
         'strong buy': 'Strong Buy',
      };

      // Handle numeric values - round to 2 decimal places
      if (value === null || value === undefined) return value;

      const numValue = typeof value === 'string' ? Number(value) : value;

      if (type === 'number' || type === 'percent') {
         return Number.isInteger(numValue) ? numValue : numValue.toFixed(2);
      }

      switch (key) {
         case 'aum':
            return (numValue / 1e9).toFixed(2);
         case 'lastPrice':
            return Number(numValue.toFixed(2));
         case 'priceChangePercent':
            return Number(numValue.toFixed(2));
         case 'status':
            return complianceDisplayValues[value] || value;
         case 'musaffaHalalRating':
            return complianceDisplayValues[value] || value;
         case 'shariahCompliantStatus':
            return complianceDisplayValues[value] || value;
         case 'shariahStates':
            return complianceDisplayValues[value] || value;
         case 'recommededStatus':
            return analystsRatingDisplayValues[value] || value;
         default:
            return value;
      }
   }


   isSortable(columnKey: string): boolean {
      if (
         this.rankingFields.includes(columnKey)
      ) {
         return this.allowHalalRating;
      }

      const column = this.columns.find((column) => column.key === columnKey);
      return this.isSortingEnabled && (column?.sortable ?? true);
   }

   onPageChange(page: number): void {
      if (!this.isPaginationDisabled) {
         this.isPaginationDisabled = true;
         this.router.navigate([], {
            queryParams: { page: page },
            queryParamsHandling: 'merge',
            replaceUrl: true,
         });
         setTimeout(() => {
            this.isPaginationDisabled = false;
            this.cdr.markForCheck();
         }, 300); // Delay to handle quick toggling
       }
   }

   private scrollToPosition() {
      if (this.data.length > 16 && this.tableContainer) {
         const container = this.tableContainer.nativeElement;
         const rowHeight = container.querySelector('tr')?.offsetHeight || 0;
         const scrollPosition = (this.data.length - 15) * rowHeight;
         container.scrollTop = scrollPosition;
      }
   }

   // getColumnClass(columnField: string): string {
   //    switch (columnField) {
   //       case 'compliance':
   //          return 'compliance-chip';
   //       case 'rating':
   //          return 'rating-column';
   //       case 'analysts_ratings':
   //          return 'analysts-rating';
   //       default:
   //          return '';
   //    }
   // }

   // TABLE SORTING
   currentSortColumn: string = '';
   isAscending: boolean = true;
   @Output() sortEvent = new EventEmitter<{
      column: string;
      sortOrder: string;
   }>();

   sortTable(column: string) {
      if (this.isSortable(column)) {
         if (this.currentSortColumn === column) {
            this.isAscending = !this.isAscending;
         } else {
            this.currentSortColumn = column;
            this.isAscending = true;
         }
         this.router.navigate([], {
            queryParams: {
               sortBy: column,
               sortOrder: this.isAscending ? 'asc' : 'desc',
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
         });
      }
      // this.sortEvent.emit({ column, sortOrder: this.isAscending ? 'asc' : 'desc' });
   }

   isSorted(column: string): boolean {
      return this.currentSortColumn === column;
   }

   // Table Filter Select Dropdown
   openFilters: { [key: string]: boolean } = {};

   toggleFilterDropdown(columnKey: string): void {
      this.openFilters[columnKey] = !this.openFilters[columnKey];
   }

   isFilterOpen(columnKey: string): boolean {
      return !!this.openFilters[columnKey];
   }

   accessibleComplianceSymbols: string[] = [];

   async getAccessibleComplianceSymbols() {
      const etfSymbols: string[] = [];
      const stockSymbols: string[] = [];
      this.data.forEach((row) => {
         if (this.getRowType(row) === 'etf') {
            etfSymbols.push(row['symbol']);
         } else {
            stockSymbols.push(row['ticker']);
         }
      });
      this.accessibleComplianceSymbols = [
         ...await this.collectionCompliance.getAccessibleComplianceSymbols(
            etfSymbols,
            'etfs'
         ),
         ...await this.collectionCompliance.getAccessibleComplianceSymbols(
            stockSymbols,
            'stocks'
         )
      ];

      this.cdr.markForCheck();
   }

   getCountryName(countryCode: string): string {
      return getCountryNameByCode(countryCode) ?? countryCode;
   }
}
