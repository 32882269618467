import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';


@Component({
  selector: 'app-portfolio-section',
  templateUrl: './portfolio-section.component.html',
  styleUrls: ['./portfolio-section.component.scss']
})
export class PortfolioSectionComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   userRole: string | null = null;
   sectionLoader: boolean = false;
   canHaveBrokerageAccounts: boolean = false
   hasPortfolioAccounts!: boolean
   userNewRole: Role = null;

   constructor(
      private subscriptionService: SubscriptionService,
      private portfolioService: PortfolioService,
      private authService: AuthService
   ) { }

   ngOnInit(): void {
      if(this.authService.getUserId()) {
         this.canHaveBrokerageAccounts = true
         this.getPortfolioList()
      }

      // SubscriptionService.subscriptionData .pipe(takeUntil(this.destroy$)) .subscribe((data: SubscriptionInfoModel) => {
      //    this.userNewRole = data.newRole;
      //    if(this.userNewRole === 'premium' || this.userNewRole === 'premium_plus') {
      //       this.canHaveBrokerageAccounts = true
      //       this.getPortfolioList()
      //    } 
      // });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   private getPortfolioList(): void {
      this.sectionLoader = true;
      this.portfolioService.getPortfoliosList().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            this.hasPortfolioAccounts = response && response.length > 0 ? true : false
            // this.portfolios = response;
            this.sectionLoader = false;
         },
         error: (error) => {
            console.error(error);
            this.sectionLoader = false;
         },
      });
   }

}
