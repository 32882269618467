import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import { ApiService } from '../../services/api.service'
import { AuthService } from '../../services/auth.service'
import { LoaderServices } from '../../services/loader.service'
import {LogOutFreeServices} from 'src/app/shared/services/log-out.service ';
import { UserApiService } from '../../services/user-api.service'

@Component({
   selector: 'app-stripe-header',
   templateUrl: './stripe-header.component.html',
   styleUrls: ['./stripe-header.component.scss']
})

export class StripeHeaderComponent implements OnInit, OnChanges {
   private subscriptions: Subscription[]
   public user: any
   public showMenu = false;

   constructor(
      private apiService: ApiService,
      private userApiService: UserApiService,
      private auth: AuthService,
      private toaster: ToastrService,
      private router: Router,
      private loader: LoaderServices,
      private logout:LogOutFreeServices
   ) {
      this.subscriptions = []
      this.user = ''
      router.events.subscribe((val) => {
         if(val instanceof NavigationEnd)
         {
            this.showMenu = false;
         }
     });
   }

   ngOnChanges(changes: SimpleChanges) {

   }

   ngOnInit(): void {
      this.usersGet();
   }

   async usersGet() {

      let data = await this.userApiService.fetchUserDetailsAndSave()
      
      this.auth.setRole(data.role);
      this.user = data;
      if(this.user && this.auth.checkRole('USER') && !this.user.is_active)
      {
         this.router.navigate(['/authentication/form']);
      }
                  
   }

   modalLogout(){
      this.logout.show();
   }

   navigate(link: string) {
      this.router.navigate([link])
   }

   checkRole(role:string):boolean
   {
      return this.auth.checkRole(role);
   }
}
