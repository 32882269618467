import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FirestoreService } from 'src/app/shared/services/firestore.service';
import { FirebaseRemoteConfigService } from './firebase-remote-config.service';
import { RemainingServices } from './remaining.service ';
import { TypesenseService } from './typesense.service';

@Injectable({
  providedIn: 'root'
})
export class ComplianceAccessCheckService {
   constructor(
      private auth: AuthService,
      private firestore: FirestoreService,
      private typesenseService: TypesenseService,
      private remainingService: RemainingServices,
      private firebaseRemoteConfig: FirebaseRemoteConfigService
   ) {}


   async searchesDoneThisMonth() {
      const userId = this.auth.getUserId() ?? null;

      var end = new Date();
      var start = new Date(end.getFullYear(), end.getMonth(), 1);

      var count = 0;

      count = await this.firestore.getUserViewedBetweenCount(start, end)

      return count;
   }

   async remainingSearches() {
      const nSearches = await this.searchesDoneThisMonth();
      let num = (await this.firebaseRemoteConfig.monthlyFreeCompliance()) - nSearches;
      if (num < 0) num = 0;
      return num;
   }

   async canViewMoreComplianceThisMonth() {
      const nSearches = await this.searchesDoneThisMonth();
      return nSearches < (await this.firebaseRemoteConfig.monthlyFreeCompliance());
   }

   async markNonFreeAsViewed(ticker: any, mainTicker: any, type: any) {
      const isViewed = await this.isAlreadyViewedStock(ticker);
      const isFree = await this.isStockFree(ticker);

      if (!isViewed && !isFree) {
         if(type === 'stock')
            this.firestore.storeUserViewedStock(ticker, mainTicker);
         else if(type === 'etf')
            this.firestore.storeUserViewedEtf(ticker, mainTicker);
         this.remainingService.update()
      }
   }

   async isEtfFree(symbol: string): Promise<boolean> {
      return (await this.firebaseRemoteConfig.getFreeEtfList()).includes(symbol)
   }

   async canViewEtfCompliance(symbol: any, ticker: any) {
      const role = this.auth.getRole()
      if(await this.isEtfFree(symbol) || role === 'PREMIUM') {
         return true;
      } else if(role === 'FREEMIUM') {
         if(await this.isAlreadyViewedEtf(symbol)) {
            return true;
         } else if(await this.canViewMoreComplianceThisMonth()) {
            this.markNonFreeAsViewed(symbol, ticker, 'etf')
            return true;
         }
      }
      return false;
   }

   async canViewEtfComplianceOnList(symbol: any) {
      const role = this.auth.getRole()
      if(await this.isEtfFree(symbol) || role === 'PREMIUM') {
         return true;
      } else if(role === 'FREEMIUM') {
         if(await this.isAlreadyViewedEtf(symbol)) {
            return true;
         }
      }
      return false;
   }
   async isAlreadyViewedEtf(symbol: any) {
      const userId = this.auth.getUserId() ?? null;
      return await this.firestore.getIsUserViewedEtf(symbol)
   }

   async isStockFree(mainTicker: string): Promise<boolean> {
      return (await this.firebaseRemoteConfig.getFreeStocksList()).includes(mainTicker)
   }

   async canViewStockCompliance(ticker: any, mainTicker: any) {
         const role = this.auth.getRole()
         if(await this.isStockFree(mainTicker) || role === 'PREMIUM') {
            return true;
         } else if(role === 'FREEMIUM') {
            if(await this.isAlreadyViewedStock(mainTicker)) {
               return true;
            } else if(await this.canViewMoreComplianceThisMonth()) {
               this.markNonFreeAsViewed(ticker, mainTicker, 'stock')
               return true;
            }
         }
         return false;
   }

   async isAlreadyViewedStock(mainTicker: any) {
      const userId = this.auth.getUserId() ?? null;
      return await this.firestore.getIsUserViewedStock(mainTicker)
   }
}
