import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { SupportedBrokerage } from 'src/app/models/supported-brokerages/supported-brokerages.model';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';

@Component({
  selector: 'app-supported-brokerages-slider',
  templateUrl: './supported-brokerages-slider.component.html',
  styleUrls: ['./supported-brokerages-slider.component.scss']
})
export class SupportedBrokeragesSliderComponent implements OnInit {
   @Input() isForBusiness: boolean = false
   private destroy$ = new Subject<void>();
   brokergesLoading: boolean = false
   brokerages: SupportedBrokerage[] = []
   customOptions: OwlOptions = {
      loop: true,
      pullDrag: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 500,
      autoWidth: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      autoplayMouseleaveTimeout: 500,
      navSpeed: 500,
      navText: ['', ''],
      responsive: {
         0: {
            items: 2,
            margin: 16,
         },
         576: {
            items: 4,
            margin: 16,
         },
         768: {
            items: 6,
            margin: 20,
         },
         992: {
            items: 8,
         },
         1680: {
            items: 10,
         },
      },
      nav: false
   }

   constructor(private portfolioService: PortfolioService) { }

   ngOnInit(): void {
      this.getSupportedBrokerages()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByBrokerageId(index: number, brokerage: SupportedBrokerage) {
      return brokerage.id;
   }

   async getSupportedBrokerages() {
      try {
         this.brokergesLoading = true
         const resp = await this.portfolioService.getSupportedBrokerages()
         this.brokerages = resp.data
      } catch (error) {
         console.error(error);
         this.brokergesLoading = false
      } finally {
         this.brokergesLoading = false
      }
   }

}
