
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryPipe'
})
export class countryPipe implements PipeTransform {

  transform(value: any, country: string): any {
    if (value) {

      // value = super.transform(value);
      // value = value.replace('$', '');

      let symbol;
      switch (country) {
        case 'US':
          symbol = 'United States';
          break;
        case 'MY':
          symbol = 'Malaysia';
          break;
        case 'ID':
          symbol = 'Indonesia';
          break;
        case 'SG':
          symbol = 'Singapore';
          break;
        case 'GB':
          symbol = 'United Kingdom';
          break;
        case 'CA':
          symbol = 'Canada';
          break;
          default:
            symbol = country;
          break;

      }

      value = symbol;

      return value;
    }
    else {
      return '--';
    }

  }

}
