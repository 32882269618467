<article class="br-10" [ngClass]="isInsideBrokeragesPopup ? 'popup-padding' : ''">
   <div class="text-center text-lg-start ">
      <div class="beta-chip-wrapper mb-3">
         <div class="btn shadow-none d-inline-flex align-items-center text-white gap-2 beta-chip">
            <span class="fw-600 lh-1">BETA</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="currentColor" fill="none">
               <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="2" />
               <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               <path d="M11.992 8H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </div>
         <span class="tooltip-span bg-dark p-2 rounded-3 text-white">
            This feature is now available in Open Beta! We'd love for you to try it out and share any feedback or issues you encounter. Your input will help us enhance the product quickly and effectively.
         </span>
      </div>
      <h3 class="text-black mb-3 fw-600">
         1,500+ more institutions now supported! <br>
         <!-- <span>Across US & Canada via<img src="/assets/images/plaid-logo.png" class="d-inline-block ms-2" alt="Plaid Logo"></span> -->
         <span>Across US & Canada</span>
      </h3>
      <p class="mb-0">
         Did not find your broker in the above list? Musaffa now supports connecting to more than 1500+ institutions in US and Canada via Plaid.
      </p>
   </div>
   <!-- <button type="button" class="btn mx-auto me-lg-0 text-white fw-500 rounded-3 shadow-none coming-soon-btn">
      Coming Soon...
   </button> -->
   <button class="btn mx-auto me-lg-0 text-white fw-500 rounded-3 shadow-none coming-soon-btn" (click)="initiatePlaid()">
      <span>Search Your Institution</span>
  </button>
</article>

