import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";

@Pipe({
   name: 'timeAgo',
   pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
   private timerSubscription: Subscription | undefined;

   constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}

   transform(value: string): string {
      this.removeTimer();
      const d = new Date(value);
      const now = new Date();
      const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

      if (Number.isNaN(seconds)) {
         return '';
      }

      const timeToUpdate = this.getSecondsUntilUpdate(seconds) * 1000;

      this.timerSubscription = this.ngZone.runOutsideAngular(() => {
         return interval(timeToUpdate).pipe(
         startWith(0),
         switchMap(() => {
            this.ngZone.run(() => this.changeDetectorRef.markForCheck());
            return [this.getElapsedTime(seconds)];
         })
         ).subscribe();
      });

      return this.getElapsedTime(seconds);
   }

   ngOnDestroy(): void {
      this.removeTimer();
   }

   private removeTimer() {
      if (this.timerSubscription) {
         this.timerSubscription.unsubscribe();
         this.timerSubscription = undefined;
      }
   }

   private getSecondsUntilUpdate(seconds: number): number {
      const min = 60;
      const hr = min * 60;
      const day = hr * 24;
      if (seconds < min) {
         return 2; // less than 1 min, update every 2 secs
      } else if (seconds < hr) {
         return 30; // less than an hour, update every 30 secs
      } else if (seconds < day) {
         return 300; // less than a day, update every 5 mins
      } else {
         return 3600; // update every hour
      }
   }

   private getElapsedTime(seconds: number): string {
      const minutes = Math.round(Math.abs(seconds / 60));
      const hours = Math.round(Math.abs(minutes / 60));
      const days = Math.round(Math.abs(hours / 24));
      const months = Math.round(Math.abs(days / 30.416));
      const years = Math.round(Math.abs(days / 365));

      if (seconds <= 45) {
         return 'a few seconds ago';
      } else if (seconds <= 90) {
         return 'a minute ago';
      } else if (minutes <= 45) {
         return `${minutes} minutes ago`;
      } else if (minutes <= 90) {
         return 'an hour ago';
      } else if (hours <= 22) {
         return `${hours} hours ago`;
      } else if (hours <= 36) {
         return 'a day ago';
      } else if (days <= 25) {
         return `${days} days ago`;
      } else if (days <= 45) {
         return 'a month ago';
      } else if (days <= 345) {
         return `${months} months ago`;
      } else if (days <= 545) {
         return 'a year ago';
      } else {
         return `${years} years ago`;
      }
   }
}
