import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ZakatService } from 'src/app/shared/services/zakat.service';
import {
   Currency,
   Checkbox,
   FormValues,
   ZakatFormsEnum,
} from 'src/app/models/zakat.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
   selector: 'app-zakat-calculator-home',
   templateUrl: './zakat-calculator-home.component.html',
   styleUrls: ['./zakat-calculator-home.component.scss'],
})
export class ZakatCalculatorHomeComponent implements OnInit {
   form: FormGroup;
   areCheckboxesChecked: boolean = false;
   private formSubscription!: Subscription;
   private currencySubscription!: Subscription;
   isLoading: boolean = false
   isLoggedIn!: boolean;

   currencies: Currency[] = [];
   @Input() selectedCurrency: string = 'USD';
   @Output() isCurrencyChanged = new EventEmitter<boolean>();

   userAssets: Checkbox[] = [
      { id: 'cash', inputs: '', name: 'Cash (In Hand / In Bank Account)' },
      { id: 'jwellery', inputs: '', name: 'Gold & Silver' },
      { id: 'stock', inputs: '', name: 'Stock/Fund Investments' },
      //  {id: 'other', inputs: '', name: 'Other Investments'},
      { id: 'business', inputs: '', name: 'Business Assets' },
      { id: 'property', inputs: '', name: 'Property (For Trading)' },
   ];

   userLiabilities: Checkbox[] = [
      { id: 'loans', inputs: 'loans', name: 'Loans' },
      { id: 'bills', inputs: 'bills', name: 'Immediate Bills / Taxes / Rent' },
      {
         id: 'wages',
         inputs: 'wages',
         name: 'Employee wages / Dealer payments',
      },
   ];

   selectedValues: FormValues = {
      currency: '',
      selectedCheckboxes: [],
   };

   @Output() onUpdateShowForm = new EventEmitter<void>();
   @Output() onTransformedCheckboxesArr = new EventEmitter<Checkbox[]>();

   constructor(
      private formBuilder: FormBuilder,
      private zakatService: ZakatService,
      private authService: AuthService
   ) {
      if(this.authService.getUserId()) {
         this.isLoggedIn = true
         this.fetchCurrencyCodes();
      } else {
         this.isLoggedIn = false
         this.currencies = [
            {
               "currency_code": "USD",
               "label": "USD($)",
               "symbol": "$"
            },
         ]
      }
      
      this.form = this.formBuilder.group({
         currency: this.selectedValues.currency,
         assets: new FormArray([]),
         liabilities: new FormArray([]),
      });

      this.addCheckboxes();
   }

   openLoginPopup() {
      this.authService.openAuthPopup()
   }

   ngOnInit(): void {
      this.formSubscription = this.form.valueChanges.subscribe(() => {
         this.areCheckboxesChecked = this.checkIfAnyCheckboxChecked();
      });

      const savedValues = localStorage.getItem('selectedValues');
      if (savedValues) {
         this.selectedValues = JSON.parse(savedValues);
         this.selectedCurrency = this.selectedValues.currency;
         localStorage.setItem(
            'selectedCurrency',
            JSON.stringify(this.selectedCurrency)
         );
         this.form.patchValue({
            currency: this.selectedValues.currency,
            assets: this.mapToCheckboxFormArray(
               this.selectedValues.selectedCheckboxes,
               this.userAssets
            ),
            liabilities: this.mapToCheckboxFormArray(
               this.selectedValues.selectedCheckboxes,
               this.userLiabilities
            ),
         });
      } else {
         this.selectedCurrency = 'USD';
      }
   }

   onCurrencyChange(event: Currency) {
      localStorage.setItem(
         'selectedCurrency',
         JSON.stringify(event.currency_code)
      );
      this.isCurrencyChanged.emit(true);
   }

   ngOnDestroy(): void {
      if (this.formSubscription) {
         this.formSubscription.unsubscribe();
      }
      if (this.currencySubscription) {
         this.currencySubscription.unsubscribe();
      }
   }

   get assetsFormArray() {
      return this.form.controls.assets as FormArray;
   }

   get liabilitiesFormArray() {
      return this.form.controls.liabilities as FormArray;
   }

   private addCheckboxes() {
      this.userAssets.forEach(() =>
         this.assetsFormArray.push(new FormControl(false))
      );
      this.userLiabilities.forEach(() =>
         this.liabilitiesFormArray.push(new FormControl(false))
      );
   }

   private mapToCheckboxFormArray(
      selectedCheckboxes: Checkbox[],
      allCheckboxes: Checkbox[]
   ): boolean[] {
      return allCheckboxes.map((checkbox) =>
         selectedCheckboxes.some((selected) => selected.id === checkbox.id)
      );
   }

   checkIfAnyCheckboxChecked(): boolean {
      const assetControls = this.form.get('assets') as FormArray;
      const liabilitiesControls = this.form.get('liabilities') as FormArray;

      const assetsChecked = assetControls.controls.some(
         (control) => control.value
      );
      const liabilitiesChecked = liabilitiesControls.controls.some(
         (control) => control.value
      );

      return assetsChecked || liabilitiesChecked;
   }

   getUserSelectedValues() {
      const selectedAssets = this.form.value.assets
         .map((checked: boolean, i: number) =>
            checked ? this.userAssets[i] : null
         )
         .filter((value: Checkbox) => value !== null);

      const selectedId = selectedAssets.findIndex(
         (asset: any) => asset.id === 'stock'
      );
      if (selectedId >= 0) {
         selectedAssets.splice(selectedId + 1, 0, {
            id: 'other',
            inputs: '',
            name: 'Other Investments',
         });
      }
      const selectedLiabilities = this.form.value.liabilities
         .map((checked: boolean, i: number) =>
            checked ? this.userLiabilities[i] : null
         )
         .filter((value: Checkbox) => value !== null);

      this.selectedValues.currency = this.form.value?.currency;
      this.selectedValues.selectedCheckboxes =
         selectedAssets.concat(selectedLiabilities);
      localStorage.setItem(
         'selectedValues',
         JSON.stringify(this.selectedValues)
      );

      let transformedArr = selectedAssets;

      if (selectedLiabilities.length > 0) {
         const selectedLiabilitiesObject = {
            id: 'liability',
            inputs: selectedLiabilities.map(
               (liability: Checkbox) => liability.inputs
            ),
            name: selectedLiabilities.map(
               (liability: Checkbox) => liability.name
            ),
         };
         transformedArr = transformedArr.concat(selectedLiabilitiesObject);
      }

      this.transformedCheckboxesArr(transformedArr);
      this.zakatService.setSelectedCurrencyAndFormsData(this.selectedValues);
      this.updateShowForm();
   }

   updateShowForm() {
      this.onUpdateShowForm.emit();
   }

   transformedCheckboxesArr(arr: Checkbox[]) {
      this.onTransformedCheckboxesArr.emit(arr);
   }

   fetchCurrencyCodes() {
      this.isLoading = true; 
      this.currencySubscription = this.zakatService
         .getCurrencyCodes()
         .subscribe(
            (data) => {
               this.currencies = data.data;
               this.isLoading = false; 
            },
            (error) => {
               console.error(error);
               this.isLoading = false; 
            }
         );
   }
}
