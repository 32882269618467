<div class="notifications-dropdown-wrapper" (document:click)="onDocumentClick($event)">
   <button class="btn shadow-none px-2 notification-btn" (click)="openNotificationsDropdown()">
      <img src="/assets/images/header/bell.svg" alt="Bell Icon">
      <span class="rounded-circle" *ngIf="isEveryNotificationRead"></span>
   </button>
   <ng-container *ngIf="!isMobile && isNotificationsMenuOpen">
      <div class="notifications-dropdown-menu overflow-hidden">
         <div class="d-flex align-items-center justify-content-between p-3">
            <h6 class="mb-0 p-0">Your Notifications</h6>
            <button (click)="markAllNotificationAsRead()" type="button"
               class="btn p-1 mark-all-read-btn shadow-none">Mark all as read</button>
         </div>

         <ng-container *ngIf="notificationsLoader">
            <div class="px-2">
               <ngx-skeleton-loader count="5" appearance="line" animation="progress"
                  [theme]="{ height: '60px', width: '100%', 'border-radius': '4px', 'margin-bottom': '0px'}">
               </ngx-skeleton-loader>
            </div>
         </ng-container>

         <ul role="list" class="notifications-dropdown-list d-grid gap-2" *ngIf="!notificationsLoader">
            <ng-container *ngIf="allNotifications.length > 0; else noNotification">
               <li class="d-block notifications-dropdown-item" *ngFor="let notification of allNotifications">
                  <button class="btn notification-item-btn d-grid w-100 shadow-none"
                     [ngClass]="!notification.is_read ? 'unread-bg' : '' " (click)="onNotificationClick(notification)">
                     <h6 class="mb-0 p-0 text-start notification-title">
                        {{ notification.notification_log.title }}
                     </h6>
                     <p class="mb-0 text-start notification-body">{{ notification.notification_log.body }}</p>
                     <span class="d-block text-end text-nowrap notification-time">{{ notification.created_at | timeAgo
                        }}</span>
                  </button>
               </li>
            </ng-container>
            <ng-template #noNotification>
               <p class="mb-0 p-3 text-center">You have <span class="fw-bold">0</span> notifications!</p>
            </ng-template>
         </ul>
      </div>
   </ng-container>
</div>

<ng-container *ngIf="isMobile">
   <div class="mobile-list-container" [ngClass]="isNotificationsMenuOpen ? 'menu-open' : ''">
      <header class="d-flex p-3 align-items-center justify-content-between">
         <h5 class="mb-0">Your Notifications</h5>
         <button class="btn bg-light shadow-none rounded-3 p-2" (click)="closeNotificationsMenu()">
            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
               <path fill="#000"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
         </button>
      </header>

      <div>
         <button (click)="markAllNotificationAsRead()" type="button"
            class="btn p-2 d-block ms-auto mark-all-read-btn shadow-none">Mark all as read</button>
         <ul role="list" class="d-grid gap-2 notifications-dropdown-list">
            <li class="d-block notifications-dropdown-item"
               *ngFor="let notification of allNotifications; trackBy: trackByNotificationId">
               <button type="button" class="btn notification-item-btn d-grid w-100 shadow-none"
                  [ngClass]="!notification.is_read ? 'unread-bg' : '' " (click)="onNotificationClick(notification)">
                  <div class="d-flex align-items-start gap-3 justify-content-between">
                     <h6 class="mb-0 p-0 text-start notification-title">
                        {{ notification.notification_log.title }}
                     </h6>
                  </div>
                  <p class="mb-0 text-start notification-body">{{ notification.notification_log.body }}</p>
                  <span class="d-block text-end text-nowrap notification-time">{{ notification.created_at | timeAgo
                     }}</span>
               </button>
            </li>
         </ul>
      </div>

   </div>
</ng-container>