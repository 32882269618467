import { Component, Input, OnInit } from '@angular/core';
import { PortfolioService } from '../../services/portfolio.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { ConnectIframeModalComponent } from '../connect-iframe-modal/connect-iframe-modal.component';
import { PaywallPopupComponent } from 'src/app/shared/components/paywall-popup/paywall-popup.component';
import { TradeService } from '../../services/trade.service';
import {
   SupportedTradingBrokerageData,
   SupportedTradingBrokeragesRes,
   TradingBrokerage,
} from 'src/app/models/trading.model';

@Component({
   selector: 'app-supported-brokerages',
   templateUrl: './supported-brokerages.component.html',
   styleUrls: ['./supported-brokerages.component.scss'],
})
export class SupportedBrokeragesComponent implements OnInit {
   @Input() exchange: string = '';

   supportedBrokerages: TradingBrokerage[] = [];
   notSupportedBrokerages: TradingBrokerage[] = [];
   supportedBrokeragesFilter: TradingBrokerage[] = [];
   notSupportedBrokeragesFilter: TradingBrokerage[] = [];
   loading: boolean = false;
   private subscriptions: Subscription[];
   userRole: string | null = null;
   redirectUrl: string | null = null;
   connectIframeModalRef: any;
   isConnectLoading: boolean = false;
   currBrokerageId: any;
   paywallModalRef: any;
   searchText: string = '';

   constructor(
      private portfolioServices: PortfolioService,
      private tradeApiService: TradeService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private router: Router,
      private subscriptionService: SubscriptionService
   ) {
      this.subscriptions = [];
   }

   ngOnInit(): void {
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );

      this.getAllBrokerages();
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   openPaywallModal() {
      this.activeModal.dismiss();
      this.paywallModalRef = this.modalService.open(PaywallPopupComponent, {
         centered: true,
         modalDialogClass: 'paywall-popup-modal',
         size: 'xl',
      });
   }

   getAllBrokerages() {
      this.loading = true;
      this.tradeApiService
         .getSupportedBrokerages<SupportedTradingBrokeragesRes>(this.exchange)
         .then(
            (results) => {
               if (results.status) {
                  this.supportedBrokerages = results.data.supported_brokerages;
                  this.supportedBrokeragesFilter =
                     results.data.supported_brokerages;
                  this.notSupportedBrokerages =
                     results.data.not_supported_brokerages;
                  this.notSupportedBrokeragesFilter =
                     results.data.not_supported_brokerages;
                  this.loading = false;
               }
            },
            (error) => {
               console.error('Error fetching data:', error);
               this.loading = false;
            }
         );
   }

   openConnectIframeModal() {
      this.connectIframeModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
      this.connectIframeModalRef.componentInstance.module = 'trade';
   }

   initiateBrokerage(brokerage: any) {
      // if (!this.showTitle) return;

      if (this.userRole === 'ANONYMOUS') {
         this.activeModal.dismiss();
         this.router.navigate(['authentication/sso-login']);
         return;
      }

      if (this.userRole !== 'PREMIUM') {
         this.openPaywallModal();
         return;
      }
      this.currBrokerageId = brokerage.id;
      this.isConnectLoading = true;
      this.portfolioServices
         .getConnectUrl(brokerage.id, true)
         .then((resp: any) => {
            if (resp.status) {
               this.isConnectLoading = false;
               this.activeModal.dismiss();
               this.redirectUrl = resp.data.redirect_url;
               this.openConnectIframeModal();
            }
         });
   }

   navigateToAddPortfolio() {
      this.portfolioServices.setBrokerageId('');
      this.portfolioServices.setHasBrokerageId(false);
      this.activeModal.dismiss();
      this.router.navigate(['/cabinet/add-portfolio']);
   }

   showPaywallPopup() {
      this.subscriptionService.openPaywallModal();
   }

   searchBrokerage() {
      const lowerCaseText = this.searchText.toLowerCase();
      this.supportedBrokeragesFilter = this.supportedBrokerages.filter(
         (item: TradingBrokerage) =>
            item.name.toLowerCase().includes(lowerCaseText)
      );
      this.notSupportedBrokeragesFilter = this.notSupportedBrokerages.filter(
         (item: TradingBrokerage) =>
            item.name.toLowerCase().includes(lowerCaseText)
      );
   }
}
