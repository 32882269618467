<div class="country-popup-container">
   <div class="country-popup-content">
      <div class="close" style="position: absolute; top: 0; right: 0;">
         <a class="btn end" (click)="checkCoveredCountries()"><img src="../../../assets/images/group-343.svg" alt=""></a>
      </div>
      <div *ngIf="type === 'proceed'">
         <header class="header">
            <h5 class="mb-0 text-center">{{configDisplayValues.title}}</h5>
         </header>
         <div class="content-container">
            <p class="mb-0 text-center modal-text">
               {{configDisplayValues.description_coverage_ui}}
            </p>
         </div>
         <div class="d-flex align-items-center justify-content-center btns-container">
            <button class="btn py-2 mx-auto shadow-none modal-btn modal-btn-cancel rounded"
               (click)="activeModal.dismiss(); paywallCountryConfirmCancel()">
               <span>Cancel</span>
            </button>
            <button class="btn py-2 mx-auto shadow-none modal-btn modal-btn-confirm rounded"
               (click)="continueClick.emit(); paywallCountryConfirmProceed()">
               <span>Continue</span>
            </button>
         </div>
      </div>
      <div *ngIf="type === 'check-country'">
         <header class="header">
            <h5 class="mb-0 text-center">{{configDisplayValues.title}}</h5>
         </header>
         <div class="content-container">
            <p class="mb-0 text-center modal-text">
               {{configDisplayValues.description_feature_ui}}
            </p>
         </div>
         <div class="d-flex align-items-center justify-content-center btns-container">
            <button class="btn py-2 mx-auto shadow-none modal-btn modal-btn-confirm rounded"
               (click)="checkCoveredCountries()">
               <span>Check Covered Countries</span>
            </button>
         </div>
      </div>
      <div *ngIf="type === 'covered-check-country'">
         <header class="header">
            <h5 class="mb-0 text-center">Check Countries Covered</h5>
         </header>
         <div class="content-container">
            <p class="mb-0 text-center modal-text">
               Before proceeding, please check country wise stock coverage.
            </p>
         </div>
         <div class="d-flex align-items-center justify-content-center btns-container">
            <button class="btn py-2 mx-auto shadow-none modal-btn modal-btn-confirm rounded"
               (click)="checkCoveredCountries()">
               <span>View Covered Countries</span>
            </button>
         </div>
      </div>
   </div>
</div>
