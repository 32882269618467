import { isDevMode, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './shared/http-interceptors/header-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { LoaderComponent } from './shared/components/loaders/loader.component';
import {
   AngularFireAuthModule,
   PERSISTENCE,
   TENANT_ID,
} from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { AngularFireModule, FirebaseApp } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
   AngularFireRemoteConfig,
   AngularFireRemoteConfigModule,
   DEFAULTS,
   SETTINGS,
} from '@angular/fire/compat/remote-config';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, DecimalPipe } from '@angular/common';
import { SumPipe } from './shared/pipes/sum.pipe';
import { screaningMillionPipe } from './shared/pipes/screaning-million.pipe';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DownloadPromptComponent } from './shared/components/download-prompt/download-prompt.component';
import { WebinarPopupComponent } from './shared/components/webinar-popup/webinar-popup.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxGaugeModule } from 'ngx-gauge';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartsModule } from 'ng2-charts';
// import { HighchartsChartModule } from 'highcharts-angular';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
// import { CancelSubscriptionPopupComponent } from './shared/components/cancel-subscription-popup/cancel-subscription-popup.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { watchlistSymbolsReducer } from './stores/watchlist-symbols/watchlist-symbols.reducers';
import { WatchlistSymbolsEffects } from './stores/watchlist-symbols/watchlist-symbols.effects';
import { PermissionsReducer } from './stores/access-control/access-control.reducers';
import { PermissionsEffects } from './stores/access-control/access-control.effects';
import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';

import { portfolioReducer } from './stores/portfolio/portfolio.reducer';
import { PortfolioEffects } from './stores/portfolio/portfolio.effects';
import { SharedModule } from "./shared/shared.module";
import { ShariahCompliantStatusPipe } from './shared/pipes/shariah-compliant-status.pipe';
import { sidebarReducer } from './stores/sidebar/sidebar.reducer';
// import { ComplianceRankingComponent } from './components/compliance-ranking/compliance-ranking.component';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
   bootstrap: [AppComponent],
   declarations: [
      AppComponent,
      LoaderComponent,
      DownloadPromptComponent,
      WebinarPopupComponent,
      DisclaimerComponent,
      // ComplianceRankingComponent,
      // ShariahCompliantStatusPipe

   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      AppRoutingModule,

      // NgbModal,
      // CommonModule,
      DragDropModule,
      HttpClientModule,
      NgxGaugeModule,
      CarouselModule,
      ChartsModule,
      NgbDatepickerModule,
      // HighchartsChartModule,
      Ng2GoogleChartsModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireAuthModule,
      AngularFireRemoteConfigModule,
      AngularFireDatabaseModule,
      ToastrModule.forRoot(),
      CookieModule.forRoot(),
      NgxMaskModule.forRoot(),
      MatProgressSpinnerModule,
      NgbModule,
      NgbNavModule,
      AngularFireAnalyticsModule,
      SocialLoginModule,
      NgSelectModule,
      StoreModule.forRoot({
         watchlistSymbols: watchlistSymbolsReducer,
         permissions: PermissionsReducer,
         portfolio: portfolioReducer,
         sidebar: sidebarReducer
      }),
      EffectsModule.forRoot([WatchlistSymbolsEffects, PermissionsEffects, PortfolioEffects]),
      SharedModule
   ],
   providers: [
      {
         provide: 'SocialAuthServiceConfig',
         useValue: {
            autoLogin: false,
            providers: [
               {
                  id: FacebookLoginProvider.PROVIDER_ID,
                  provider: new FacebookLoginProvider('1375528286183693'),
               },
            ],
            onError: (err) => {
               console.error(err);
            },
         } as SocialAuthServiceConfig,
      },
      // {
      //    provide: 'SocialAuthServiceConfig',
      //    useValue: {
      //    autoLogin: false,
      //    providers: [
      //       {
      //          id: FacebookLoginProvider.PROVIDER_ID,
      //          provider: new FacebookLoginProvider('956085695645228')
      //       }
      //    ],
      //    onError: (err) => {
      //       console.error(err);
      //    }
      //    } as SocialAuthServiceConfig,

      // },
      { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
      Title,
      { provide: PERSISTENCE, useValue: 'local' },

      //remote config
      { provide: DEFAULTS, useValue: environment.defaultRemoteConfig },
      {
         provide: SETTINGS,
         useFactory: () => {
            // minimumFetchIntervalMillis: 10_000
         },
      },
      DatePipe,
      SumPipe,
      screaningMillionPipe,
      DecimalPipe,
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
   ],
})
export class AppModule {
   constructor(private remoteConfig: AngularFireRemoteConfig) {
      this.remoteConfig.settings.then((setting) => {
         setting.fetchTimeoutMillis = 60000;
         setting.minimumFetchIntervalMillis = 120000;
      });
      this.remoteConfig.fetchAndActivate();
   }
}
