import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-mobile-sort-offcanvas',
  templateUrl: './mobile-sort-offcanvas.component.html',
  styleUrls: ['./mobile-sort-offcanvas.component.scss']
})
export class MobileSortOffcanvasComponent implements OnInit {
  isMobile: boolean = false;
  @Input() offcanvasId: string ='';
  @Input() isOpen: boolean = false;
  @Input() showChangeSort: boolean = false;

  @Output() close = new EventEmitter<void>();

  @Output() priceSortAsc = new EventEmitter<void>();
  @Output() priceSortDesc = new EventEmitter<void>();
  @Output() changeSortAsc = new EventEmitter<void>();
  @Output() changeSortDesc = new EventEmitter<void>();
  @Output() complianceSortAsc = new EventEmitter<void>();
  @Output() complianceSortDesc = new EventEmitter<void>();
  @Output() resetSort = new EventEmitter<void>();

  private subscriptions: Subscription[]
  userRole: string|null = null

  constructor(private observer: BreakpointObserver,) { 
    this.subscriptions = []
  }

  ngOnInit(): void {
    this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      this.userRole = data.role
    }))
    this.observer.observe('(max-width: 992px)').subscribe(result => {
      this.isMobile = result.matches         
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
       this.subscriptions.forEach((item) => {
          item.unsubscribe();
       });
    }
 }

  onCloseOffcanvas() {
    this.isOpen = false;
    this.close.emit();
  }

  openOffcanvas() {
    this.isOpen = true;
  }

  onPriceSortAsc(){
    this.priceSortAsc.emit()
  }

  onPriceSortDesc(){
    this.priceSortDesc.emit()
  }

  onChangeSortAsc(){
    this.changeSortAsc.emit()
  }

  onChangeSortDesc(){
    this.changeSortDesc.emit()
  }

  onComplianceSortAsc(){
    this.complianceSortAsc.emit()
  }

  onComplianceSortDesc(){
    this.complianceSortDesc.emit()
  }

  onSortReset(){
    this.resetSort.emit()
  }

}
