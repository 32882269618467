<header class="modal-header d-flex align-items-start gap-3 border-0 p-3 p-lg-4">
   <h4 class="modal-title text-black fw-600">
      {{title}}
   </h4>
   <button type="button" class="btn shadow-none p-1" (click)="dismissPopup()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#000000" fill="none">
         <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
   </button>
</header>
<div class="modal-body px-3 pb-3 px-lg-4 pb-lg-4 pt-0 border-0">
   <p class="mb-0">
      {{description}}
   </p>
   <div class="d-flex align-items-center justify-content-end gap-3 pt-3 pt-lg-4">
      <button type="button" class="btn shadow-none fw-500 rounded-3 btn-outline-danger px-4" (click)="dismissPopup()" *ngIf="showUpgradeSection || showCancelBtn">
         Cancel
      </button>
      <button class="btn shadow-none fw-500 rounded-3 btn-success px-4" type="button" (click)="openPaywall()" *ngIf="showUpgradeSection">
         Upgrade Now
      </button>
      <button class="btn shadow-none fw-500 rounded-3 btn-success px-4" type="button" (click)="okayPressed()" *ngIf="!showUpgradeSection">
         {{confirmationText}}
      </button>
   </div>
</div>



<!-- <section class="centered">
      <header class="heading px-3 px-lg-4 pt-3 pt-lg-4">
         <article class="article">
            <h4>{{title}}</h4>
         </article>
      </header>
      <div class="px-3 px-lg-4 py-1">
         <div>
            <ng-container>
               <p>
                  {{description}}
               </p>
            </ng-container>
         </div>
      </div>
      <div class="pb-3 pb-lg-4">
         <div class="d-flex align-items-center justify-content-center btns-container">
            <button type="button" class="btn me-1 shadow-none modal-btn modal-btn-cancel rounded"
               (click)="dismissPopup()" *ngIf="showUpgradeSection || showCancelBtn">
               <span>{{cancelText}}</span>
            </button><button type="button" class="btn ms-1 shadow-none modal-btn modal-btn-confirm rounded"
               (click)="openPaywall()" *ngIf="showUpgradeSection">
               <span>Upgrade Now</span>
            </button>
            <button type="button" class="btn mx-1 shadow-none modal-btn modal-btn-confirm rounded"
               (click)="okayPressed()" *ngIf="!showUpgradeSection">
               <span>{{confirmationText}}</span>
            </button>
         </div>
      </div>
</section> -->