import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Hit, MarketNews, MarketNewsResponse } from 'src/app/models/market-news/market-news.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

@Component({
  selector: 'app-market-news',
  templateUrl: './market-news.component.html',
  styleUrls: ['./market-news.component.scss']
})
export class MarketNewsComponent implements OnInit {
   singleNews: MarketNews = {
      Category: '',
      Datetime: null,
      Headline: '',
      ID: null,
      Image: '',
      Related: '',
      Source: '',
      Summary: '',
      URL: '',
      id: ''
   };
   marketNews: MarketNews[] = [];
   newsLoader: boolean = false;

   customOptions: OwlOptions = {
      loop: true,
      pullDrag: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 1000,
      autoWidth: true,
      autoplayHoverPause: true,
      autoplayMouseleaveTimeout: 750,
      navSpeed: 750,
      navText: ['', ''],
      responsive: {
         0: {
            items: 1.2,
            margin: 16,
         },
         576: {
            items: 3,
            margin: 16,
         },
         680: {
            items: 6,
            margin: 20,
         },
      },
      nav: false
   };

   constructor(
      private analyticsUtils: AnalyticsUtils,
      private typesenseService: TypesenseService
   ) {}

   ngOnInit(): void {
      this.getMarketNews();
   }

   trackByNewsId(index: number, news: MarketNews) {
      return news.id;
   }

   async getMarketNews() {
      try {
         this.newsLoader = true;
         const data = await this.typesenseService.getMatketNews<MarketNewsResponse>();
         const allNews = data.hits.map((obj: Hit) => obj.document);
         this.singleNews = allNews[0];
         this.marketNews = allNews.slice(1);
      } catch (error) {
         console.error('Error fetching market news:', error);
      } finally {
         this.newsLoader = false;
      }
   }

   formattedDate(date: any): Date {
      return new Date(date * 1000);
   }
}
