import { Component, OnInit } from '@angular/core';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
// import { StockBucket } from 'src/app/models/halal-stock-bucket.model';

@Component({
   selector: 'app-collections-list-card',
   templateUrl: './collections-list-card.component.html',
   styleUrls: ['./collections-list-card.component.scss']
})

export class CollectionsListCardComponent implements OnInit {
   collections: any[] = []
   cardLoader: boolean = false
   bucketList: any[] = []

   constructor(private typesenseService: TypesenseService) { }

   ngOnInit(): void {
      this.getBucketNamesList()
   }

   async getBucketNamesList() {
      try {
         this.cardLoader = true
         await this.typesenseService.getHalalStockBuckets('is_visible:=true', 250, 'sort_order:asc').then((data) => {
            const bucketData = data.hits.map((bucket: any) => {
               return {
                  image: bucket.document.image,
                  name: bucket.document.bucket_name,
                  id: bucket.document.id
               }
            })
            this.bucketList = bucketData 
            localStorage.setItem('bucketList', JSON.stringify(this.bucketList));
            this.cardLoader = false
         })
      } catch (error) {
         console.error(error);
         this.cardLoader = false
      } finally {
         this.cardLoader = false
      }
   }

}
