<section class="bg-white">
   <div class="container content-padding">
      <header class="d-flex align-items-center justify-content-between gap-3 section-header">
         <h2 class="mb-0 section-main-heading">Halal US ETFs</h2>
      </header>

      <div class="table-grid-container">
         <app-etfs-table 
            [loading]="etfsLoading.topGainers"
            [tableId]="'etfsTopGainers'" 
            [title]="'Top Gainers'"
            [data]="topGainers">
         </app-etfs-table>

         <app-etfs-table 
            [loading]="etfsLoading.topLosers"
            [tableId]="'etfsTopLosers'" 
            [title]="'Top Losers'"
            [data]="topLosers">
         </app-etfs-table>
      </div>
   </div>
</section>

