<div class="list-container">
   <div class="d-flex align-items-center justify-content-between mb-4">
      <h3 class="section-heading mb-0">Halal Stock Collections</h3>
      <a class="text-decoration-none shadow-none btn p-0 view-btn" routerLink="/cabinet/halal-stock-collections/{{bucketList[0]?.id}}">
         View All
      </a>
   </div>
   <ul class="card-list p-0 m-0">
      <ng-container *ngIf="cardLoader">
         <div *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
            <ngx-skeleton-loader
               count="1" appearance="line" animation="progress"
               [theme]="{ height: '90px', width: '100%', 'border-radius': '10px', margin: '0px'}">
            </ngx-skeleton-loader>
         </div>
      </ng-container>
      <ng-container *ngIf="!cardLoader">
         <li *ngFor="let bucket of bucketList">
            <a class="collection-card d-flex flex-column align-items-center gap-2 text-decoration-none"  routerLink="/cabinet/halal-stock-collections/{{bucket.id}}">
               <div class="img-wrapper d-flex align-items-center justify-content-center">
                  <img src="{{bucket.image}}" class="img-fluid" alt="{{bucket.name}}">
               </div>
               <span class="d-block text-center text-black">{{bucket.name}}</span>
            </a>
         </li>
      </ng-container>
   </ul>
</div>
