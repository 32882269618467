import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rankingGrade'
})
export class RankingGradePipe implements PipeTransform {
  transform(value: number | string): string | number {
    // If value is already an alphabetic ranking, return it as is
    if (typeof value === 'string' && /^[A-C][+-]?$/.test(value.trim())) {
      return value;
    }

    // Map numeric rankings to letter grades
    const rankingMap: { [key: number]: string } = {
      9: 'A+',
      8: 'A',
      7: 'A-',
      6: 'B+',
      5: 'B',
      4: 'B-',
      3: 'C+',
      2: 'C',
      1: 'C-'
    };

    return rankingMap[Number(value)] || value || '-'; // Default to '-' if value is not found
  }
}

