
<div class="modal-body position-relative border-0 padding">
   <button class="btn close-popup-btn shadow-none p-2" (click)="closePopup()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#000000" fill="none">
         <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
   </button>

   <ng-container *ngIf="!isWatchlistCopied; else successCard">
      <h4 class="fw-600">
         {{ mainHeading }}
      </h4>
      <p>
         {{ popupDescription }}
      </p>
      <form #copyToWatchlistForm="ngForm" (ngSubmit)="copyCollectionToWatchlist(copyToWatchlistForm)">
         <div class="d-grid gap-1">
            <label for="watchlistName" class="d-block lh-sm fw-500 input-label">Enter Watchlist Name</label>
            <div class="d-flex align-items-center watchlist-input-wrapper overflow-hidden">
               <svg class="ms-2 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="currentColor" fill="none">
                  <path d="M3.5 9V14C3.5 17.7712 3.5 19.6569 4.67157 20.8284C5.84315 22 7.72876 22 11.5 22H12.5C16.2712 22 18.1569 22 19.3284 20.8284C20.5 19.6569 20.5 17.7712 20.5 14V10C20.5 6.22876 20.5 4.34315 19.3284 3.17157C18.1569 2 16.2712 2 12.5 2H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.5 17H17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.5 7H17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.5 12H17.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M6.5 16.5C6.5 16.5 7.46758 16.7672 8 18C8 18 9 15 11 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10 5H3.5M10 5C10 4.15973 7.67332 2.58984 7.08333 2M10 5C10 5.84027 7.67331 7.41016 7.08333 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
               <input 
                  type="text" 
                  id="watchlistName" 
                  autocomplete="off"
                  [(ngModel)]="form.watchlistName" 
                  class="flex-grow-1 rounded-3 border-0 input-field" 
                  placeholder="For e.g. My Collection"
                  name="watchlistName"
                  required
               >
            </div>
            
            <small class="text-danger d-inline-flex fw-500 align-items-center gap-1 error-msg-text" *ngIf="errorMessage">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14" color="currentColor" fill="none">
                  <path d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z" stroke="currentColor" stroke-width="2" />
                  <path d="M11.992 16H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 13L12 8.99997" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
               {{ errorMessage }}
            </small> 
         </div>
   
         <div class="d-flex align-items-center popup-btns justify-content-end gap-3">
            <button type="button" class="btn shadow-none fw-500" (click)="closePopup()">
               Cancel
            </button>
            <button type="submit" class="btn d-inline-flex align-items-center gap-2 shadow-none add-btn fw-500 text-white" [disabled]="!copyToWatchlistForm.valid || buttonLoader">
               <div class="spinner-border spinner-border-sm" role="status" *ngIf="buttonLoader">
                  <span class="visually-hidden">Loading...</span>
               </div>
              <span>{{ buttonLoader ? 'Copying' : 'Copy' }}</span>
            </button>
         </div>
      </form>
   </ng-container>

   <ng-template #successCard>
      <article>
         <div class="d-flex align-items-center success-text-and-image gap-2">
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
               <g stroke-width="0"/>
               <g stroke-linecap="round" stroke-linejoin="round"/>
               <path fill="#1bad4e" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896m-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.27 38.27 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336z"/>
            </svg>
            <h3 class="mb-0 fw-600 success-text">Success</h3>
         </div>
         <h4 class="fw-600">
            {{ successHeading }}
         </h4>
         <p>
            {{ successDescription }}
         </p>
         <div class="d-flex align-items-center popup-btns justify-content-end gap-3">
            <button type="button" class="btn shadow-none fw-500" (click)="closePopup()">
               Cancel
            </button>
            <button (click)="navigateToWatchlistPage()" type="button" class="btn shadow-none add-btn fw-500 text-white">
               Open Watchlist
            </button>
         </div>
      </article>
   </ng-template>
   
</div>
