import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { LoaderServices } from '../../services/loader.service';
import firebase from 'firebase/compat/app';
import { HttpErrorResponse } from '@angular/common/http';
import { FirebaseCloudFunctionsService } from '../../services/firebase-cloud-functions.service';
import { lastValueFrom } from 'rxjs';
import { FirestoreService } from '../../services/firestore.service';
import { UserApiService } from '../../services/user-api.service';
import { SubscriptionService } from '../../services/subscription.service';
import { AnalyticsUtils } from '../../services/analytics-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserOnboardingServeComponent } from '../user-onboarding-serve/user-onboarding-serve.component';
import { SharedService } from '../../services/shared.service';

@Component({
   selector: 'app-google-signin',
   templateUrl: './google-signin.component.html',
   styleUrls: ['./google-signin.component.scss'],
})
export class GoogleSigninComponent implements OnInit {
   public redirectURL: any;

   public messag: any;
   @Input() click = '';
   @Input() tapEvent = '';
   @Input() completedEvent = '';
   @Input() failEvent = '';
   @Input() isGoogleBtnInsidePopup: boolean = false;

   constructor(
      private apiService: ApiService,
      private auth: AuthService,
      private firestoreService: FirestoreService,
      private firebaseCloudFunctionService: FirebaseCloudFunctionsService,
      private router: Router,
      private cookie: CookieService,
      private route: ActivatedRoute,
      private loader: LoaderServices,
      public authFire: AngularFireAuth,
      private subscriptionService: SubscriptionService,
      public userApiService: UserApiService,
      private analyticsUtils: AnalyticsUtils,
      private modalService: NgbModal,
      private sharedService: SharedService
   ) {
      this.redirectURL = localStorage.getItem('redirectUrl');
   }

   ngOnInit(): void {}

   async googleSign() {
      this.analyticsUtils.SOCIAL_AUTH_BTN_TAP(this.tapEvent);
      await this.logout();
      this.authFire
         .signInWithPopup(new firebase.auth.GoogleAuthProvider())
         .then(async (value: any) => {
            let accessToken = value.credential.accessToken;

            this.loader.show();
            const req = {
               token: accessToken,
            };
            let response: any = await this.userApiService.googleLogin(req);
            if (response.status) {
               let token = response.data;
               let isNew = response.is_new;
               await this.auth.setToken(token);
               const userData: any =
                  await this.userApiService.fetchUserDetailsAndSave();

               this.auth.setLoggedIn();
               if(!isNew) {
                  await this.subscriptionService.setUserSubscriptionInfo();
               }
               const subInfo = SubscriptionService.subscriptionData.getValue();
               if (subInfo.role === 'FREEMIUM') {
                  if (isNew) {
                     this.sharedService.afterSignUpActions(userData.email);
                     if(userData.sign_up_completed) {
                        this.modalService.open(UserOnboardingServeComponent, {
                           centered: true,
                           scrollable: true,
                           size: 'xl',
                           modalDialogClass: 'paywall-popup-modal',
                        });
                     }
                  }
                  // else this.subscriptionService.openPaywallModal();
               }
               this.analyticsUtils.SOCIAL_AUTH_COMPLETED(
                  this.completedEvent,
                  userData.email,
                  isNew,
                  AnalyticsUtils.METHOD_GOOGLE
               );
               if (this.isGoogleBtnInsidePopup) {
                  this.auth.closeAuthPopup()
                  window.location.reload();
               } else {
                  if (this.redirectURL) {
                     this.router.navigateByUrl(this.redirectURL).then();
                     this.loader.hide();
                     return;
                  }
                  this.router.navigateByUrl('/');
               }

               this.loader.hide();
            } else {
               this.loader.hide();

               this.messag = response.message;

               this.analyticsUtils.SOCIAL_AUTH_FAILED(
                  this.failEvent,
                  '',
                  response.message,
                  null
               );
            }
         })
         .catch((e) => {
            this.analyticsUtils.SOCIAL_AUTH_FAILED(
               this.failEvent,
               '',
               '',
               e.message
            );
         });
   }

   private closePopup() {
      this.auth.closeAuthPopup()
   }

   async logout() {
      // const user = await this.authFire.currentUser;
      this.auth.logout();
      this.subscriptionService.clearSubscriptionInfo();
      // user?.delete();
      // await this.authFire.signOut();
   }

   @HostBinding('class')
   get googleBtnStyling(): string {
      if (this.isGoogleBtnInsidePopup) {
         return 'btn-styling';
      } else {
         return '';
      }
   }
}
