import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analyst-rating-chip',
  templateUrl: './analyst-rating-chip.component.html',
  styleUrls: ['./analyst-rating-chip.component.scss']
})
export class AnalystRatingChipComponent implements OnInit {
  @Input() analystRating: string = ''
  @Input() analystRatingDisclaimer: string = ''
  @Input() complianceStatus:string = ''

  constructor() { }

  ngOnInit(): void {
  }

  changeClasses() {
    return {
      'buy': this.analystRating === 'Buy',
      'strong-buy': this.analystRating === 'Strong buy',
      'hold': this.analystRating === 'Hold',
      'sell': this.analystRating === 'Sell',
      'strong-sell': this.analystRating === 'Strong sell',
    }
  }

}
