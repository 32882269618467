<form action="" [formGroup]="calculateForm" (submit)="calculate()">
   <div class="purification-calculator-container">
      <h4 class="purification-heading mb-0">Purification Calculator</h4>
      <p class="calc-para">
         Earning from Halal Stocks also need to be purified based on their
         business and financial earnings. Calculate the amount using our
         Purification calculator
      </p>
      <a
         routerLink="/cabinet/purifications-methods"
         class="text-decoration-none calc-link my-3"
      >
         <!-- Find out more about Purification &#8594; -->
         Learn more
      </a>
      <div class="mt-3">
         <div class="mb-3">
            <div class="form-group">
               <label for="ticker">Company (Ticker or Name)</label>
               <input
                  id="ticker"
                  type="text"
                  name="ticker"
                  class="form-control shadow-none"
                  formControlName="symbol"
                  [ngbTypeahead]="search"
                  [resultFormatter]="formatSymbol"
                  [inputFormatter]="inputFormatBandListValue"
                  #instance="ngbTypeahead"
                  [resultTemplate]="rt"
                  (selectItem)="isStockSelected = true; onStockSelected($event)"
               />
               <small class="text-danger" *ngIf="showErrorMsg"
                  >Stock Purification is only applicable to Halal stocks</small
               >
            </div>
         </div>

         <div class="mb-3">
            <label for="count" class="mb-1">Shares Owned</label>
            <input
               type="number"
               min="1"
               maxlength="9"
               class="form-control num-input shadow-none"
               name=""
               id="count"
               placeholder=""
               formControlName="count"
            />
         </div>
         <div class="row mb-4">
            <div class="col-6">
               <label for="" class="d-block mb-1">Purchase Date</label>
               <input
                  class="rounded w-100"
                  type="date"
                  [max]="maxDate"
                  name=""
                  id=""
                  formControlName="start"
               />
            </div>
            <div class="col-6">
               <label for="" class="d-block mb-1">Sell Date</label>
               <input
                  class="rounded w-100"
                  type="date"
                  [max]="maxDate"
                  name=""
                  id=""
                  formControlName="end"
               />
            </div>
         </div>
         <div
            class="d-flex justify-content-center align-items-center flex-column"
         >
            <div
               class="spinner-border theme-spinner"
               role="status"
               *ngIf="loading"
            >
               <span class="visually-hidden">Loading...</span>
            </div>
            <button
               class="w-100 calc-button rounded"
               type="submit"
               *ngIf="!loading"
               [disabled]="!calculateForm.valid || disableButton"
               (click)="purificationCalculateTapped()"
            >
               {{
                  !checkRole("ANONYMOUS") ? "Calculate" : "Login to Calculate"
               }}
            </button>
         </div>
      </div>
   </div>
</form>

<ng-template #rt let-r="result" let-t="term" [resultTemplate]="rt" class="">
   <div class="d-flex align-items-center justify-content-between result-item">
      <div>
         <div class="d-flex align-items-center gap-3 stock-name">
            <span>{{ r.stockName }}</span>
         </div>
         <div class="company-name">
            {{ r.companyName }}
         </div>
      </div>
      <div class="stock-name">
         <span class="chip" [ngClass]="getStatusClasses(r.status)">{{
            convertComplianceStatus(r.status)
         }}</span>
      </div>
   </div>
</ng-template>
