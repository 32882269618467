<div class="modal-header p-0 border-0 d-flex align-items-center justify-content-end">
   <button class="btn p-2 shadow-none bg-transparent rounded-3" (click)="closeModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#5E5E5E" viewBox="0 0 384 512">
         <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
      </svg>
   </button>
</div>

<div class="modal-body custom-scroll-bar p-3 p-lg-4">
   <article class="modal-content-wrapper align-items-start">
      <img src="{{ image }}" class="img-fluid" [alt]="name">

      <div class="d-grid align-items-start">
         <h4 class="mb-1 fw-600">{{ name }}</h4>
         <h6 class="mb-3 fw-500">{{ title }}</h6>
         <p class="fw-400 mb-0">{{ discription }}</p>
      </div>
   </article>
</div>

