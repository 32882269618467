import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { PremiumMemberGreetingPopupComponent } from '../premium-member-greeting-popup/premium-member-greeting-popup.component';
import { MarketUtilsService } from '../../services/market-utils.service';
import { UserApiService } from '../../services/user-api.service';
import { CountryPromptPopupComponent } from '../country-prompt-popup/country-prompt-popup.component';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionPlansResponse } from 'src/app/models/subscription-plans.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoaderServices } from '../../services/loader.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';


interface Feature {
   feature_code: string;
   icon: string;
   title: string;
   description: string;
   video_url: string;
   detailed_info_url: string;
}

interface Plan {
   id: string,
   label: string,
   value: string,
   price: number,
   pricePerMonth: number | null,
   currency: string,
   canFreeTrial: boolean,
   trialDays: number | null,
   couponPercentOff: any,
}

@Component({
   selector: 'app-paywall-popup',
   templateUrl: './paywall-popup.component.html',
   styleUrls: ['./paywall-popup.component.scss'],
   animations: [
      trigger('slideLeft', [
         transition(':enter', [
            style({transform: 'translateX(-100%)'}),
            animate('500ms ease', style({transform: 'translateX(0%)'}))
         ]),
      ]),
      trigger('slideRight', [
         transition(':enter', [
            style({transform: 'translateX(+100%)'}),
            animate('500ms ease', style({transform: 'translateX(0%)'}))
         ]),
      ]),
   ]
})
export class PaywallPopupComponent implements OnInit {
   isPremium: boolean = false;
   isExistingUser: boolean = false;
   username: string = '';
   modalReference: any;
   @Input() countryCode!: string;
   promptModalReference: any;
   countryTabSeen: boolean = false;
   countryPopupOpened: boolean = false;

   payloadLoader: boolean = false;
   isUserCountryCovered: boolean = false;
   userCountry!: string;
   title: string = '';
   subTitle: string = '';
   disclaimer: string = '';
   featuresIntro: string = '';
   countryDisclaimer: string = '';
   countryValue: string = '';
   features: Feature[] = [];
   countryWiseCoverage: any = [];
   isCountryCovered: boolean = false;
   yearPrice: any;
   monthPrice: any;
   totalYearlyCostForMonthlyPlan!: number;
   // modalEventSubscribe:
   viewMode: string = 'featuresTab';
   countryMapping: any = {};
   selectedPlan!: string;
   plans: Plan[] = [];
   freeTrialDays: number | null = 0;
   yearlyDisplayFormat: any = 'highlighted_yearly'
   // offerPlans: Plan[]  = [
   //    { label: 'Yearly', value: 'yearly', price: '$47.99/Year', priceText: 'Renews at $59.99 after 1 year' },
   //    { label: 'Monthly', value: 'monthly', price: '$5.99/Month', priceText: 'Renews at $7.99 after 3 months' },
   // ]

   constructor(
      private configService: FirebaseRemoteConfigService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private loader: LoaderServices,
      private marketUtilsService: MarketUtilsService,
      private userApiService: UserApiService,
      private subscriptionService: SubscriptionService,
      private analyticsUtils:AnalyticsUtils,
   ) {}

   ngOnInit(): void {
      SubscriptionService.subscriptionPlans.subscribe(
         (data: SubscriptionPlansResponse) => {
            this.plans = [];
            for(let i in data.data) {
               if(i === 'year') {
                  this.freeTrialDays = (data.data[i].trial_info.trial_days && data.data[i].trial_info.trial_days) ? data.data[i].trial_info.trial_days : 0;
                  this.selectedPlan = data.data[i].id
                  const perMonth = parseInt(((data.data[i].price/12)*100).toString())/100;
                  this.plans.push(
                     {
                        id: data.data[i].id,
                        label: 'Yearly',
                        value: 'yearly',
                        price: data.data[i]?.coupon ? (data.data[i].price - ((data.data[i].price*data.data[i]?.coupon?.percent_off)/100)) : data.data[i].price,
                        pricePerMonth: data.data[i]?.coupon ? (perMonth - ((perMonth*data.data[i]?.coupon?.percent_off)/100)) : perMonth,
                        currency: data.data[i].currency.toUpperCase(),
                        canFreeTrial: data.data[i].trial_info.can_trial,
                        trialDays: data.data[i].trial_info.trial_days,
                        couponPercentOff: data.data[i]?.coupon?.percent_off
                     }
                  )
               } else if(i === 'month') {
                  this.plans.push(
                     {
                        id: data.data[i].id,
                        label: 'Monthly',
                        value: 'monthly',
                        price: data.data[i]?.coupon ? (data.data[i].price - ((data.data[i].price*data.data[i]?.coupon?.percent_off)/100)) : data.data[i].price,
                        pricePerMonth: null,
                        currency: data.data[i].currency.toUpperCase(),
                        canFreeTrial: data.data[i].trial_info.can_trial,
                        trialDays: data.data[i].trial_info.trial_days,
                        couponPercentOff: data.data[i]?.coupon?.percent_off
                     }
                  )
                  this.totalYearlyCostForMonthlyPlan = data.data[i].price*12
               }
            }
         }
      );
      this.getPaywallDefaults();
      this.fetchUsersCountry();
   }

   planChange(plan: Plan) {
      this.freeTrialDays = (plan.canFreeTrial && plan.trialDays) ? plan.trialDays : 0;
      // this.selectedPlan = plan.id
   }

   openGreetingsModal() {
      this.modalReference = this.modalService.open(
         PremiumMemberGreetingPopupComponent,
         { centered: true }
      );
   }

   async redirectToPaymentPage(priceId: string) {
      this.modalService.dismissAll();
      this.loader.show();
      window.location.href = (await this.subscriptionService.getPaymentLink(priceId)).url
      this.loader.hide();
   }

   async openPaymentLink() {
      const plan = this.plans.find((item: any) => item.id === this.selectedPlan)
      this.analyticsUtils.paywall_subscribe_tapped(plan?.label ?? '');
      if(this.isUserCountryCovered || !this.userCountry) {
         if(this.countryTabSeen) {
            this.analyticsUtils.paywall_begin_subscription_process(plan?.label ?? '')
            this.redirectToPaymentPage(this.selectedPlan)
         } else {
            this.analyticsUtils.paywall_check_coverage_prompted();
            this.promptModalReference = this.modalService.open(
               CountryPromptPopupComponent,
               {
                  centered: true,
                  backdrop: 'static',
                  animation: false,
               }
            );
            this.countryPopupOpened = true

            // this.promptModalReference.componentInstance["continueClick"].subscribe(() => {
            //    this.redirectToPaymentPage(this.selectedPlan)
            // });
            this.promptModalReference.componentInstance["viewCoveredCountriesClick"].subscribe(() => {
               this.openCountryTab()
               // this.promptModalReference.close()
            });

            this.promptModalReference.componentInstance["type"] = 'covered-check-country'
         }
      } else {
         this.openPromptModal(this.selectedPlan)
      }
   }

   openPromptModal(priceId: string) {
      if(this.countryTabSeen) {
         if(this.countryPopupOpened) {
            this.redirectToPaymentPage(priceId)
         } else {
            this.promptModalReference = this.modalService.open(
               CountryPromptPopupComponent,
               {
                  centered: true,
                  backdrop: 'static',
                  animation: false,
               }
            );
            this.countryPopupOpened = true

            this.promptModalReference.componentInstance["continueClick"].subscribe(() => {
               this.redirectToPaymentPage(priceId)
            });
            this.promptModalReference.componentInstance["viewCoveredCountriesClick"].subscribe(() => {
               this.openCountryTab()
               // this.promptModalReference.close()
            });

            this.promptModalReference.componentInstance["type"] = 'proceed'
         }
      } else {
         this.promptModalReference = this.modalService.open(
            CountryPromptPopupComponent,
            {
               centered: true,
               backdrop: 'static',
               animation: false,
            }
         );
         this.countryPopupOpened = true

         this.promptModalReference.componentInstance["continueClick"].subscribe(() => {
            this.redirectToPaymentPage(priceId)
         });
         this.promptModalReference.componentInstance["viewCoveredCountriesClick"].subscribe(() => {
            this.openCountryTab()
         });

         this.promptModalReference.componentInstance["type"] = 'check-country'
      }

   }

   openCountryTab() {
      this.viewMode = 'countryTab'
      this.countryTabSeen = true;
   }

   openSupportedBrokeragesTab() {
      this.viewMode = 'supportedTab'
      this.countryTabSeen = true;
   }

   closePromptModal() {
      this.promptModalReference.close();
   }

   async fetchUsersCountry() {
      const userData = await this.userApiService.fetchUserDetails();
      this.userCountry = userData.geo_ip_country;
      const countries: any = await this.marketUtilsService.fetchCountries();
      for(let country of countries) {
         this.countryMapping[country.countryCode] = country.country
      }

      const coveredCountryCodes = countries
         .filter((obj: any) => obj.detailedProfileAvailable)
         .map((obj: any) => obj.countryCode);

      this.isUserCountryCovered = coveredCountryCodes.includes(this.userCountry)
   }

   getPaywallDefaults() {
      this.payloadLoader = true;
      this.configService
         .getInAppSubscriptionDefaults()
         .then((data) => {
            this.title = data.paywall_defaults.intro.title;
            this.subTitle = data.paywall_defaults.sub_title;
            this.disclaimer = data.paywall_defaults.disclaimer_text;
            // this.featuresIntro = data['intro_description'];
            // this.countryDisclaimer = data['country_disclaimer_label'];
            // this.countryValue = data['country_disclaimer_value'];
            this.features = data.paywall_defaults.feature_list;
            this.countryWiseCoverage = data.paywall_defaults.country_wise_coverage;
            // console.log(this.features)
            this.configService.getPaywallExperiments().then((data: any) => {
               this.yearlyDisplayFormat = data?.yearly_pricing_display_format
            })
         })
         .catch((error) => {
            console.error(error);
         })
         .finally(() => {
            this.payloadLoader = false;
         });
   }

   paywallClosed(){
      this.analyticsUtils.paywall_closed();
   }
}
