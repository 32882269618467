import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country, Hit, MarketCollectionResponse } from 'src/app/models/market-collection/market-collection.model';
import { TYPESENSE_COLLECTIONS } from '../constants/typesense-collections';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FilterByCountryPopupComponent } from '../components/filter-by-country-popup/filter-by-country-popup.component';

@Injectable({
   providedIn: 'root'
})

export class CountryService {
   private countries$!: Observable<Country[]>;

   private selectedCountrySubject: BehaviorSubject<Country>;
   public selectedCountry$: Observable<Country>;

   filterByCountryPopupRef!: NgbModalRef;
   private filterCountrySubject!: BehaviorSubject<Country>;
   public filterCountry$!: Observable<Country>;

   constructor(private http: HttpClient, private modalService: NgbModal) {
      const defaultCountry: Country = {
         country: 'United States',
         countryCode: 'US',
         detailedProfileAvailable: true,
         etfEnabled: true,
         etfExchanges: ['NYSE ARCA', 'NASDAQ'],
         etfsCovered: true,
         id: 'US',
         sortOrder: 1,
         stockEnabled: true,
         stockExchanges: ['NYSE', 'NASDAQ'],
         stocksCovered: true
      };
      this.selectedCountrySubject = new BehaviorSubject<Country>(defaultCountry);
      this.selectedCountry$ = this.selectedCountrySubject.asObservable();

      this.filterCountrySubject = new BehaviorSubject<Country>(defaultCountry);
      this.filterCountry$ = this.filterCountrySubject.asObservable();
   }

   getCountries(): Observable<Country[]> {
      this.countries$ = this.countries$ ?? this.fetchCountries().pipe(shareReplay());
      return this.countries$;
   }

   private fetchCountries(): Observable<Country[]> {
      const queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return this.http.get<MarketCollectionResponse>(`${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.MARKET_COLLECTION}/documents/search`, { params: queryParams }).pipe(
         map((response) => response.hits
            .map((hits: Hit) => hits.document)
            .filter((country: Country) => country.countryCode !== 'IL') as Country[]
         )
      );
   }

   setSelectedCountry(country: Country): void {
      localStorage.setItem('dataFilterCountry', country.countryCode);
      this.selectedCountrySubject.next(country);
   }

   getSelectedCountry(): Country {
      return this.selectedCountrySubject.value;
   }

   openFilterByCountriesPopup() {
      this.filterByCountryPopupRef = this.modalService.open(FilterByCountryPopupComponent, {
         centered: true,
         scrollable: true,
         backdropClass: 'global-modal-backdrop',
         windowClass: 'global-modal',
         size: 'lg'
      });
   }

   closeFilterByCountriePopup() {
      if (this.filterByCountryPopupRef) {
         this.filterByCountryPopupRef.close()
      }
   }

   setFilterCountry(country: Country): void {
      // sessionStorage.setItem('selectedFilterCountry', JSON.stringify(country));
      return this.filterCountrySubject.next(country);
   }

   getFilterCountry(): Country {
      return this.filterCountrySubject.value;
   }
}


// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';

// @Injectable({
//    providedIn: 'root'
// })

// export class CountryService {
//    private selectedCountrySubject = new BehaviorSubject<string>('US');
//    selectedCountry$ = this.selectedCountrySubject.asObservable();

//    constructor() {}

//    setSelectedCountry(country: string): void {
//       this.selectedCountrySubject.next(country);
//    }
// }
