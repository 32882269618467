import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { ZakatService } from 'src/app/shared/services/zakat.service';
import { ZakatFormsEnum, Checkbox, FormValues, CalculatedZakat } from 'src/app/models/zakat.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-zakat-calculations',
  templateUrl: './zakat-calculations.component.html',
  styleUrls: ['./zakat-calculations.component.scss']
})
export class ZakatCalculationsComponent implements OnInit {
  isMobileScreen: boolean = false
  isZakatableAssetsCollapsed: boolean = true
  isLiabilitiesCollapsed: boolean = true
  ZakatFormEnum = ZakatFormsEnum;
  currentRouter = this.router.url;

  @Input() calculatedData!: CalculatedZakat;
  @Input() selectedFormSteps: Checkbox[] = []

  constructor(
    private observer: BreakpointObserver,
    private zakatService: ZakatService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
   // this.calculatedData.tradable_goods_amt
   // this.calculatedData.raw_materials_amt
    this.observer.observe('(max-width: 991px)').subscribe(result => {
      this.isMobileScreen = result.matches
    });
  }

  get ZakatEnum() {
    return this.zakatService.ZakatEnum;
  }

  getBusinessAssetsTotal(): number {
   const tradableGoodsAmt = parseFloat(this.calculatedData.tradable_goods_amt?.toString() ?? '0');
   const rawMaterialsAmt = parseFloat(this.calculatedData.raw_materials_amt?.toString() ?? '0');
   return tradableGoodsAmt + rawMaterialsAmt;
 }

  getShowForm(): ZakatFormsEnum {
    return this.zakatService.getShowForm();
  }

  goToHome() {
    this.router.navigate(['/'])
    // setTimeout(() => {
    //   this.router.navigate(['/cabinet/zakat-calculator'])
    // }, 100);
    // this.zakatService.setShowForm(this.ZakatEnum.Home);
    // setTimeout(() => {
    //   this.router.navigate([this.currentRouter])
    // }, 100);
  }

}
