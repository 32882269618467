<div class="halal_stock_collection" [ngClass]="{'collection_page': componentPath === 'collection'}">
   <!-- Only display the title row when not on the view more page -->
   <div class="container">
      <div class="title_row" *ngIf="componentPath !== 'collection'">
         <h3 class="fw-700">Halal Stock Collections</h3>
         <a class="all_news_link desktop_only" *ngIf="selectedCountryBuckets.length > 0"
            [routerLink]="['/halal-collection']" [queryParams]="{
               country: selectedCountry,
               collection: selectedCountryBuckets[0].id,
               page: 1
            }">
            View All
         </a>
      </div>
   </div>

   <div class="loader-grid" *ngIf="bucketLoader">
      <ng-container *ngIf="isMobile; else notMobile">
         <span class="skeleton-loader" *ngFor="let i of [].constructor(componentPath === 'home' ? 8 : 12)"></span>
      </ng-container>
      <ng-template #notMobile>
         <span class="skeleton-loader" *ngFor="let i of [].constructor(componentPath === 'home' ? 8 : 20)"></span>
      </ng-template>
   </div>

   <div *ngIf="componentPath === 'collection'">
      <ng-container *ngIf="!bucketLoader">
         <ul class="stock__listing" *ngIf="componentPath !== 'collection'">
            <li *ngFor="let bucket of selectedCountryBuckets; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                  country: selectedCountry,
                  collection: bucket.id,
                  page: 1
               }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
         <ul *ngIf="componentPath === 'collection'" role="list"
            class="d-flex align-items-center flex-wrap collections-list-container gap-3" [ngClass]="
            areBucketsVisible
               ? 'hidden-collection'
               : ''
         ">
            <li *ngFor="let bucket of selectedCountryBuckets; trackBy: trackByFn" class="rounded">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                  country: selectedCountry,
                  collection: bucket.id,
                  page: 1
               }" [fragment]="
                  componentPath === 'collection' ? 'infoCard' : undefined
               " [class.activeCollection]="bucket.id === selectedCollection"
                  class="btn fw-500 shadow-none rounded-pill d-inline-flex collection-button align-items-center gap-2">
                  <img [src]="bucket.image" style="width: 42px; height: 42px;"
                     class="img-fluid flex-shrink-0 rounded-circle bg-light collection-image" alt=""
                     *ngIf="bucket?.image; else noImage" />

                  <ng-template #noImage>
                     <span style="width: 42px; height: 42px;"
                        class="flex-shrink-0 d-inline-flex align-items-center justify-content-center rounded-circle fw-600 text-black bg-white collection-image">
                        {{ bucket?.bucket_name?.charAt(0) }}
                     </span>
                  </ng-template>
                  <span class="text-start lh-sm">{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
      </ng-container>
   </div>

   <article class="wrapper" *ngIf="componentPath !== 'collection'">
      <div class="marquee">
         <ul class="stock__listing marquee_group" >
            <li *ngFor="let bucket of selectedCountryBuckets; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                           country: selectedCountry,
                           collection: bucket.id,
                           page: 1
                        }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
         <ul aria-hidden="true" class="stock__listing marquee_group">
            <li *ngFor="let bucket of selectedCountryBuckets; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                           country: selectedCountry,
                           collection: bucket.id,
                           page: 1
                        }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
      </div>


      <div class="marquee marquee-reverse">
         <ul class="stock__listing marquee_group">
            <li *ngFor="let bucket of buckets1; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                           country: selectedCountry,
                           collection: bucket.id,
                           page: 1
                        }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
         <ul aria-hidden="true" class="stock__listing marquee_group">
            <li *ngFor="let bucket of buckets2; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                           country: selectedCountry,
                           collection: bucket.id,
                           page: 1
                        }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
      </div>

      <div class="marquee">
         <ul class="stock__listing marquee_group">
            <li *ngFor="let bucket of buckets3; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                           country: selectedCountry,
                           collection: bucket.id,
                           page: 1
                        }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
         <ul aria-hidden="true" class="stock__listing marquee_group">
            <li *ngFor="let bucket of selectedCountryBuckets; trackBy: trackByFn">
               <a [routerLink]="['/halal-collection']" [queryParams]="{
                           country: selectedCountry,
                           collection: bucket.id,
                           page: 1
                        }" [fragment]="undefined" [class.activeCollection]="bucket.id === selectedCollection">
                  <div class="stock__icon">
                     <ng-container *ngIf="bucket?.bucket_name; else noImage">
                        <img style="width: 42px; height: 42px;" *ngIf="bucket?.image; else noImage"
                           [src]="bucket?.image" alt="{{ bucket.bucket_name }}" />
                     </ng-container>
                     <ng-template #noImage>
                        <span>{{ bucket?.bucket_name?.charAt(0) || "N/A" }}</span>
                     </ng-template>
                  </div>
                  <span>{{ bucket?.bucket_name }}</span>
               </a>
            </li>
         </ul>
      </div>
   </article>

   <div class="text-center mt-4">
      <a class="all_news_link mobile_only" *ngIf="selectedCountryBuckets.length > 0 && componentPath !== 'collection'"
         [routerLink]="['/halal-collection']" [queryParams]="{
            country: selectedCountry,
            collection: selectedCountryBuckets[0].id,
            page: 1
         }">
         View All
      </a>
   </div>
</div>
