import {
   HttpHandler,
   HttpHeaders,
   HttpInterceptor,
   HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderServices } from '../services/loader.service';
import { StripeFreeServices } from '../services/stripe-free.service';
import { CookieService } from 'ngx-cookie';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SubscriptionService } from '../services/subscription.service';
import { SharedService } from '../services/shared.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
   constructor(
      private authService: AuthService,
      private subscriptionService: SubscriptionService,
      private fireAuth: AngularFireAuth,
      private router: Router,
      private toaster: ToastrService,
      private loader: LoaderServices,
      private stripeFree: StripeFreeServices,
      private cookie: CookieService,
      private sharedService: SharedService
   ) {}

   intercept(req: HttpRequest<any>, next: HttpHandler) {
      let token = this.authService.getToken();
      let baseHref = location.pathname.split('/').splice(1, 1)[0];
      if (baseHref !== 'ru' && baseHref !== 'en' && baseHref !== 'uz') {
         baseHref = 'ru';
      }

      const timezoneOffset = -1*((new Date()).getTimezoneOffset())
      let header = new HttpHeaders({
         'Content-Type': 'application/json',
         'Accept-Language': baseHref,
         // 'ngrok-skip-browser-warning':'true',
         Accept: 'application/json',
         Authorization: `Bearer ${this.authService.getToken()}`,
         "User-Timezone-Offset": timezoneOffset.toString()
      });

      // if(!req.url.includes(environment.firebaseCloudFunctionsUrl+'/userRegistrationWithEmailSubmitUserData')) {
      //    header = header.append('Accept', 'application/json');
      // }

      // console.log(JSON.stringify(this.cookie.getAll()))
      //
      //  header = header.append('Cookie', JSON.stringify(this.cookie.getAll()));

      if (req.url.includes(environment.typeSense.url)) {
         header = header.append(
            'X-TYPESENSE-API-KEY',
            environment.typeSense.searchApiKey
         );
      }
      // else if (req.url.includes(environment.uatTypeSense.url)) {
      //    header = header.append(
      //       'X-TYPESENSE-API-KEY',
      //       'bgUgo63f47A7TriwuS3OMgENTaJcv8SF'
      //    );
      // }
      else if (token && !req.url.includes('/api/v1/auth/login')) {
         token = localStorage.getItem('token') || '';
         header = header.set('Authorization', 'Bearer ' + token);
      }

      if (req.url.includes(environment.revenueCat.v1.url)) {
         header = header.set('Authorization', 'Bearer ' + environment.revenueCat.v1.apiKey);
      }

      if(req.url.includes('user/create-ticket')) {
         header = header.delete('content-type');
         // header = header.set('process-data', 'false');
         // header = header.set('Content-Type', 'multipart/form-data');
         // contentType: false, //this is requireded please see answers above
            // processData: false, //this is requireded please see answers above
      }

      // if(req.url.includes('portfolio-data/import-file-wrapper')) {
      //    header = header.delete('content-type');
      //    header = header.set('UPLOAD-TOKEN', `${environment.serviceCallToken}`);
      // }

      let authReq = req.clone({
         headers: header,
      });

      return next.handle(authReq).pipe(
         catchError((err: any) => {
            if(
               err.status !== 400
            ) {
               this.loader.reset();
            }
            if (
               err.status === 401
            ) {
               this.authService.logout();
               this.subscriptionService.clearSubscriptionInfo();
               window.location.href="/"
            }

            if(err.status === 403) {
               this.sharedService.closeAllPopup();
               window.location.href="/pricing"
               // switch(err.error.status_code) {
               //    case 'LIMIT_REACHED':
               //       this.sharedService.openLimitReachedPopup(err.error.title, err.error.message, err.error.can_upgrade);
               //       break;
               //    case 'UNAUTHORIZED':
               //       this.sharedService.openUpgradePopup();
               //       break;
               // }
            }

            // if (
            //    err.status === 402 ||
            //    (err.status === 0 && !req.url.includes('/api/v1/auth/login'))
            // ) {
            //    this.stripeFree.show(402);
            //    this.router.navigate(['/cabinet/']);
            // }
            // else if (
            //    err.status === 410 ||
            //    (err.status === 0 && !req.url.includes('/api/v1/auth/login'))
            // ) {
            //    this.stripeFree.show(410);

            // }
            // else if (
            //    err.status === 406 ||
            //    (err.status === 0 && !req.url.includes('/api/v1/auth/login'))
            // ) {
            //    this.stripeFree.show(406);

            // }
            // else if (err.status === 500 && !(req.url.includes('login') || req.url.includes('news'))) {

            //    this.router.navigate(['/pages/error-500']);

            // }
            // else if (
            //    err.status === 400 ||
            //    (err.status === 0 && !req.url.includes('/api/v1/auth/login'))
            // ) {
            // }
            // else {
            //    if (
            //       err &&
            //       err.error &&
            //       err.error.errors &&
            //       err.error.errors.length > 0
            //    ) {
            //       this.toaster.error(err.error.errors[0].message);
            //    } else {

            //    }
            // }
            return observableThrowError(() => err);
         })
      );
   }
}
