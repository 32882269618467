import { Component, OnInit } from '@angular/core';
import { TypesenseService } from '../../services/typesense.service';
import { CollectionComplianceService } from '../../services/collection-compliance.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
// import { WebsoketService } from '../../services/websoket.service';
import { MarketUtilsService } from '../../services/market-utils.service';
import { UserApiService } from '../../services/user-api.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-popular-stocks',
  templateUrl: './popular-stocks.component.html',
  styleUrls: ['./popular-stocks.component.scss']
})
export class PopularStocksComponent implements OnInit {
  public stocks: any = []
  public complianceInfo: any = []
  private subscriptions: Subscription[]
  public stocksLoading = false
  public result: any;

  constructor(
    private typesenseService: TypesenseService,
    private collectionCompliance: CollectionComplianceService,
   //  private ws: WebsoketService,
    private marketUtilsService:MarketUtilsService,
    private userApiService:UserApiService,
    private authService:AuthService,

  ) {
    this.subscriptions = []
   }

  async ngOnInit(): Promise<void> {
    this.fetchPopularStocks()
  }


  async fetchPopularStocks(){

   var selectedCountry = "us"
   if(this.authService.isLoggedIn()){
      var data:any = await this.marketUtilsService.fetchCountries()
      var allowedCountryCodes = data.filter((obj:any)=>obj.stockEnabled && obj.stocksCovered && obj.detailedProfileAvailable).map((obj:any)=>obj.countryCode)

      var userObj = await this.userApiService.fetchUserDetails()
      var userCountry = userObj.geo_ip_country

      if(userCountry){
         var found = allowedCountryCodes.find((item:any)=>item == userCountry)
         if(found)
         selectedCountry = userCountry
      }

   }

    let subscription = this.typesenseService.getPopularStocksTypeSense(1, "", [], ['COMPLIANT'], selectedCountry,10)
      .subscribe(async (data: any) => {
          this.stocks = data.items;
          this.stocksLoading = false
            // this.getData(data.items)
            let tickersArr = this.stocks.map((obj:any)=>obj.ticker)
            this.getPriceData(tickersArr)
            this.complianceInfo = await this.collectionCompliance.getAccessibleComplianceInfo(data.symbols, 'stocks')



         },
         (error: HttpErrorResponse) => {

         }

      )

      this.subscriptions.push(subscription)
  }


 getPriceData(symbols:string[]){
   this.typesenseService.getPrices(symbols).then((res)=>{
      for (const key in res) {
         let priceValue = res[key]
         let stock = this.stocks.find(
            (item:any) => item.stockName == priceValue.id
         )
         if (stock) {
            stock.status = priceValue.status
            if (stock.status != 'not found') {
               if (!stock.lastPrice) {
                  stock.lastPrice = priceValue.currentPrice
               }

               stock.price = priceValue.currentPrice
               stock.priceText = stock.currency+" "+priceValue.currentPrice
               stock.priceChange = priceValue.change.toFixed(2)
               stock.priceChangePercent = priceValue.percentChange.toFixed(2)
               stock.priceChangePercentText = priceValue.percentChange.toFixed(2)+"%"
            }
         }
      }
   })
}

 public handleMissingImage(event: Event) {
   (event.target as HTMLImageElement).style.display = 'none';
 }

 transformValue(value : any){
   return Math.abs(value)
 }
}
