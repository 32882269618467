<footer>
    <section class="container">
        <nav class="footer-nav-wrapper">
            <div>
                <h6 class="nav-heading">Quick Links</h6>
                <ul role="list" class="links-list">
                    <li class="link-item" *ngFor="let link of quickLinks">
                        <!-- <ng-container *ngIf="userRole === 'ANONYMOUS' && (link.title === 'Zakat Calculator'); else linkTemplate">
                      <button (click)="openAuth()" class=" footer-link d-flex align-items-center">
                         <i class="bi bi-arrow-right"></i>
                         <span>{{ link.title }}</span>
                      </button>
                   </ng-container> -->
                        <!-- <ng-template #linkTemplate> -->
                        <a class="d-flex align-items-center footer-link" [routerLink]="link.path">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>{{ link.title }}</span>
                        </a>
                        <!-- </ng-template> -->
                    </li>
                </ul>
            </div>


            <div>
                <h6 class="nav-heading">Company</h6>
                <ul role="list" class="links-list">
                    <li class="link-item">
                        <a class="d-flex align-items-center footer-link" routerLink="/about-us">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>About Us</span>
                        </a>
                    </li>
                    <li class="link-item">
                        <a class="d-flex align-items-center footer-link" routerLink="/for-business">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>For Business</span>
                        </a>
                    </li>
                    <li class="link-item">
                        <a class="d-flex align-items-center footer-link" routerLink="/ambassador-program">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>Ambassador Program</span>
                        </a>
                    </li>
                    <li class="link-item">
                        <a class="d-flex align-items-center footer-link" routerLink="/halal-investing">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>Screening Methodology</span>
                        </a>
                    </li>

                    <li class="link-item">
                        <a class="d-flex align-items-center footer-link" routerLink="/contact-us">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>Contact Us</span>
                        </a>
                    </li>
                    <!-- <li class="link-item">
                   <a class="d-flex align-items-center footer-link" href="mailto:info@musaffa.com">
                      <i class="bi bi-arrow-right"></i>
                      <span>Contact Us</span>
                   </a>
                </li> -->
                </ul>
            </div>


            <div>
                <h6 class="nav-heading">Account</h6>

                <ul role="list" class="links-list">
                    <li class="link-item" *ngIf="userRole === 'ANONYMOUS'">
                        <a class="d-flex align-items-center footer-link" routerLink="/authentication/login">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>Login</span>
                        </a>
                    </li>
                    <li class="link-item" *ngFor="let link of premiumLinks">
                        <a class="d-flex align-items-center footer-link" [routerLink]="link.path">
                            <!-- <i class="bi bi-arrow-right"></i> -->
                            <span>{{ link.title }}</span>
                        </a>
                    </li>
                </ul>
            </div>


            <div>
               <h6 class="nav-heading">Legal</h6>
               <ul role="list" class="links-list">
                   <li class="link-item">
                       <a class="d-flex align-items-center footer-link" routerLink="/terms-of-use">
                           <!-- <i class="bi bi-arrow-right"></i> -->
                           <span>Terms of Use</span>
                       </a>
                   </li>
                   <li class="link-item">
                       <a class="d-flex align-items-center footer-link" routerLink="/privacy-policy">
                           <!-- <i class="bi bi-arrow-right"></i> -->
                           <span>Privacy Policy</span>
                       </a>
                   </li>
                   <li class="link-item">
                       <a class="d-flex align-items-center footer-link" routerLink="/disclaimer">
                           <!-- <i class="bi bi-arrow-right"></i> -->
                           <span>General Disclaimer</span>
                       </a>
                   </li>
                   <li class="link-item">
                       <a class="d-flex align-items-center footer-link" routerLink="/brand-guidelines">
                           <!-- <i class="bi bi-arrow-right"></i> -->
                           <span>Brand Guidelines</span>
                       </a>
                   </li>

                   <!-- <li class="link-item">
                  <a class="d-flex align-items-center footer-link" href="mailto:info@musaffa.com">
                     <i class="bi bi-arrow-right"></i>
                     <span>Contact Us</span>
                  </a>
               </li> -->
               </ul>
           </div>


            <div>
                <h6 class="nav-heading">Install Our App</h6>
                <p *ngIf="!isMobile" class="mb-3 d-none d-lg-block scan-text">Scan QR code to install app</p>
                <div class="store_badges">
                    <div class="qr_code" *ngIf="!isMobile">
                        <img src="/assets/images/footer/qr-code.png" alt="qr-code" class="img-fluid d-none d-lg-block">
                    </div>
                    <div class="app_store_row">
                        <a href="https://app.musaffa.com/wKcRlYiPpKb" target="_blank">
                            <img src="/assets/images/home/footer-ios-app.svg" alt="app-store-badge" class="img-fluid">
                        </a>
                        <a href="https://app.musaffa.com/mcJZc85OpKb" target="_blank">
                            <img src="/assets/images/home/footer-gplay.svg" alt="play-store-badge" class="img-fluid">
                        </a>
                        <a *ngIf="!isMobile" href="https://chromewebstore.google.com/detail/musaffa-halal-stock-scann/khhlfpiekiijfkpcobnfcagchfepnfla"
                            target="_blank" class="d-inline-flex text-decoration-none align-items-center">
                            <img src="/assets/images/home/footer-chrome-ext.svg" alt="Google Chrome Extension"
                                class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>

        </nav>
        <div class="mb-0 footer-para">
            <div class="py-1">
                The term halal, when applied to investments, is subject to multiple interpretations. We define halal
                investments as investments permitted by Shariah law. Shariah-compliant investments are investment products
                governed by the requirements of Shariah law and the principles of the Islamic religion.
            </div>
            <div class="py-1">
               #1 Website: Per web analytics of Similarweb, Semrush, and Similar Sites in comparison to competitors for the year of 2024.
            </div>
         </div>
        <div class="d-flex align-items-center justify-content-between flex-wrap gap-2 border-0 copyright-wrapper">
            <div class="d-flex align-items-center flex-wrap gap-2 gap-md-3">
                <a href="//www.dmca.com/Protection/Status.aspx?ID=79d2fbda-fa14-4e0b-b7a3-015a110e3e9a"
                    title="DMCA.com Protection Status" class="dmca-badge">
                    <img src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-01.png?ID=79d2fbda-fa14-4e0b-b7a3-015a110e3e9a"
                        alt="DMCA.com Protection Status" />
                </a>
                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
                <!-- <a href="//www.dmca.com/Protection/Status.aspx?ID=fe41b1de-e760-4095-b8c4-d14320644505" title="DMCA.com Protection Status" class="dmca-badge">
                <img src ="https://images.dmca.com/Badges/dmca-badge-w100-5x1-01.png?ID=fe41b1de-e760-4095-b8c4-d14320644505"  alt="DMCA.com Protection Status" />
             </a>
             <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script> -->
                <p class="mb-0">© {{ currentYear }} Musaffa | All rights reserved.</p>
            </div>
            <ul role="list" class="d-flex align-items-center gap-3 gap-lg-4">
                <li class="social-link-item">
                    <a class="d-flex align-items-center" href="https://www.linkedin.com/company/musaffa"
                        target="_blank">
                        <i class="bi bi-linkedin"></i>
                    </a>
                </li>
                <li class="social-link-item">
                    <a class="d-flex align-items-center" href="https://www.facebook.com/musaffa.fintech"
                        target="_blank">
                        <i class="bi bi-facebook"></i>
                    </a>
                </li>
                <li class="social-link-item">
                    <a class="d-flex align-items-center" href="https://twitter.com/Musaffa_Fintech" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white"
                            class="bi bi-twitter-x" viewBox="0 0 16 16">
                            <path
                                d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                        </svg>
                    </a>
                </li>
                <li class="social-link-item">
                    <a class="d-flex align-items-center" href="https://www.instagram.com/musaffa.fintech/"
                        target="_blank">
                        <i class="bi bi-instagram"></i>
                    </a>
                </li>
                <li class="social-link-item">
                    <a class="d-flex align-items-center" href="https://www.youtube.com/@musaffafintech" target="_blank">
                        <i class="bi bi-youtube"></i>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</footer>
