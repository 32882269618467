<div class="px-2" *ngIf="loading">
   <ngx-skeleton-loader
      count="8" appearance="line" animation="progress"
      [theme]="{ height: '50px', width: '100%', 'border-radius': '8px'}">
   </ngx-skeleton-loader>
</div>

<div class="table-responsive br-10 stock-table-container" *ngIf="!loading">
   <table class="table mb-0">
      <thead>
         <tr>
            <th class="text-uppercase fw-normal" scope="col" *ngIf="!isMobile"></th>
            <th class="text-uppercase fw-normal" scope="col">Name</th>
            <th class="text-uppercase fw-normal text-end" scope="col" *ngIf="isMobile">Price & 1D CHG</th>
            <th
               class="text-uppercase fw-normal text-end"
               scope="col"
               *ngIf="!isMobile"
               [class.colsort]="canSortPriceAndChange()"
               (click)="canSortPriceAndChange() ? sortPrice(tableId || '') : null"
            >
               Price <i [ngClass]="getPriceSortClasses(tableId || '')"></i>
            </th>
            <th
               class="text-uppercase fw-normal text-end"
               scope="col"
               *ngIf="!isMobile"
               [class.colsort]="canSortPriceAndChange()"
               (click)="canSortPriceAndChange() ? onSort('percentChange') : null"
            >
               1D CHANGE <i [ngClass]="getColumnSortClasses('percentChange')"></i>
            </th>
            <th
               class="text-uppercase fw-normal text-center"
               scope="col"
               [class.colsort]="canSortCompliance()"
               (click)="canSortCompliance() ? onSort('compliantRanking') : null"
            >
               Compliance <i [ngClass]="getColumnSortClasses('compliantRanking')"></i>
            </th>
            <th
               class="text-uppercase fw-normal text-center"
               scope="col"
               [class.colsort]="canSortCompliance()"
               (click)="canSortCompliance() ? onSort('compliantRanking') : null"
            >
               Rating <i [ngClass]="getColumnSortClasses('compliantRanking')"></i>
            </th>
         </tr>
      </thead>
      <tbody>
         <ng-container *ngIf="data && data.length > 0">
            <tr *ngFor="let stock of filtereredStocks(); let i = index; trackBy: trackByStockId">
               <td class="align-middle p-0" *ngIf="!isMobile">
                  <app-watchlist-button [stock]="stock"></app-watchlist-button>
               </td>
               <td class="align-middle p-0">
                  <a class="d-flex align-items-center gap-2 text-decoration-none stock-image-and-name" routerLink="/stock/{{stock.ticker}}">
                     <div class="stock-image-wrapper border flex-shrink-0 bg-light rounded-circle overflow-hidden">
                        <img [appLazyLoadImage]="stock.logo" placeholder="/assets/images/dashboard/stock-lowres.png" [alt]="stock.name" class="img-fluid" *ngIf="stock.logo">
                        <div *ngIf="!stock.logo" class="d-flex h-100 text-black align-items-center justify-content-center fs-5 fw-bold">
                           {{ stock?.name?.charAt(0)}}
                        </div>
                     </div>
                     <div class="d-block">
                        <h5 class="mb-0 line-clamp-1 stock-name">{{ stock?.name }}</h5>
                        <p class="mb-0 line-clamp-1 stock-symbol">{{ stock?.ticker }}</p>
                     </div>
                  </a>
               </td>

               <ng-container *ngIf="!isMobile">
                  <td class="align-middle p-0 stock-price-td">
                     <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-price-a" routerLink="/stock/{{stock.ticker}}">
                        <ng-container *ngIf="tableId === 'most_active' || tableId === 'most_popular' || tableId === 'top_losers'">
                           <span class="stock-price text-end" *ngIf="stock.currentPrice; else noPrice">
                              {{ stock.currency ? ( stock.currentPrice | sumPipe: stock.currency ) : stock.currentPrice }}
                           </span>
                           <ng-template #noPrice>-</ng-template>
                        </ng-container>

                        <ng-container *ngIf="tableId === 'top_gainers' || tableId === '52_week_high' || tableId === '52_week_low'">
                           <span class="stock-price text-end" *ngIf="stock.currentPrice; else noCurrentPrice">
                              {{ stock.currency ? ( stock.currentPrice | sumPipe: stock.currency ) : stock.currentPrice }}
                           </span>
                           <ng-template #noCurrentPrice>-</ng-template>
                        </ng-container>
                     </a>
                  </td>

                  <td class="align-middle p-0 stock-change-td">
                     <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-change-a" routerLink="/stock/{{stock.ticker}}">
                        <div class="stock-change d-flex align-items-center justify-content-end gap-1" *ngIf="stock.percentChange; else noPercentChange" [ngClass]="getPercentChangeClasses(stock)">
                           <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                           <span> {{ stock.percentChange | abs | number : "1.2-2" }}% </span>
                        </div>
                        <ng-template #noPercentChange>-</ng-template>
                     </a>
                  </td>
               </ng-container>

               <td class="align-middle p-0 stock-price-and-change-td" *ngIf="isMobile">
                  <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-price-and-change" routerLink="/stock/{{stock.ticker}}">
                     <div class="d-grid">
                        <ng-container *ngIf="tableId === 'most_active' || tableId === 'most_popular' || tableId === 'top_losers'">
                           <span class="stock-price text-end" *ngIf="stock.currentPrice; else noPrice">
                              {{ stock.currency ? ( stock.currentPrice | sumPipe: stock.currency ) : stock.currentPrice }}
                           </span>
                           <ng-template #noPrice>-</ng-template>
                        </ng-container>

                        <ng-container *ngIf="tableId === 'top_gainers' || tableId === '52_week_high' || tableId === '2_week_low'">
                           <span class="stock-price text-end" *ngIf="stock.lastPrice; else noCurrentPrice">
                              {{ stock.currency ? ( stock.lastPrice | sumPipe: stock.currency ) : stock.lastPrice }}
                           </span>
                           <ng-template #noCurrentPrice>-</ng-template>
                        </ng-container>

                        <div class="stock-change d-flex align-items-center justify-content-end gap-1" *ngIf="stock.percentChange; else noPercentChange" [ngClass]="getPercentChangeClasses(stock)">
                           <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                           <span> {{ stock.percentChange | abs | number : "1.2-2" }}% </span>
                        </div>
                        <ng-template #noPercentChange>-</ng-template>
                     </div>
                  </a>
               </td>
               <td class="align-middle p-0 stock-compliance-td">
                  <a class="d-flex align-items-center justify-content-center text-decoration-none" routerLink="/stock/{{stock.ticker}}">
                     <app-compliance-chip
                        [showCompliance]="i < 3"
                        [symbol]="stock?.mainTicker"
                        [complianceStatus]="stock.sharia_compliance"
                        [complianceRanking]="stock.ranking"
                        [complianceRankingV2]="stock.ranking_v2"
                        [preloaded]="true"
                        [onlyCompliance]="true"
                     >
                     </app-compliance-chip>
                  </a>
               </td>
               <td class="align-middle p-0 stock-rating-td">
                  <a class="d-flex align-items-center justify-content-center text-decoration-none" routerLink="/stock/{{stock.ticker}}">
                     <app-compliance-chip
                        [showCompliance]="i < 3"
                        [symbol]="stock?.mainTicker"
                        [complianceStatus]="stock.sharia_compliance"
                        [complianceRanking]="stock.ranking"
                        [complianceRankingV2]="stock.ranking_v2"
                        [preloaded]="true"
                        [onlyRanking]="true"
                     >
                     </app-compliance-chip>
                  </a>
               </td>
            </tr>

            <tr class="lock-overlay"  *ngIf="!isLoggedIn && data.length > 3">
               <td colspan="5"class="align-middle">
                  <button (click)="accessControlService.accessDenied();" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                     <img src="/assets/icons/lock-black.svg" width="16" height="16" alt="lock image" class="img-fluid">
                     <!-- <span>Sign-up & Upgrade to Unlock!</span> -->
                     <span>Unlock</span>
                  </button>
               </td>
            </tr>

            <tr class="lock-overlay" *ngIf="isLoggedIn && !canReadTrendingStocks && data.length > 3">
               <td colspan="5"class="align-middle">
                  <button (click)="accessControlService.accessDenied();" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                     <img src="/assets/icons/lock-black.svg" width="16" height="16" alt="lock image" class="img-fluid">
                     <!-- <span>Upgrade & Unlock!</span> -->
                     <span>Unlock</span>
                  </button>
               </td>
            </tr>

         </ng-container>

         <ng-container *ngIf="!loading && (!data || data.length === 0)">
            <tr class="nostocks-tr">
               <td colspan="5" class="align-middle">
                  <p class="mb-0 d-flex justify-content-center align-items-center">No stocks available for the selected country</p>
               </td>
            </tr>
         </ng-container>

      </tbody>
   </table>
 </div>


