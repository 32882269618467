import {
   Component,
   EventEmitter,
   HostBinding,
   Input,
   OnInit,
   Output,
} from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';
import { PortfolioService } from '../../services/portfolio.service';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsUtils } from '../../services/analytics-utils';

@Component({
   selector: 'app-sync-button',
   templateUrl: './sync-button.component.html',
   styleUrls: ['./sync-button.component.scss'],
})
export class SyncButtonComponent implements OnInit {
   syncLoader: boolean = false;
   syncTimeAndDate: Date | null = null;
   accountId: string = '';
   @Input() btnColor!: string;
   @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
   @Input() parentComponent!: string;
   @Input() accounts: any;
   @Input() connectionStatus: string = '';
   @Input() isManual: boolean = false;
   private subscriptions: Subscription[] = [];

   constructor(
      public portfolioService: PortfolioService,
      private analyticsUtils: AnalyticsUtils,
      private toaster: ToastrService
   ) {}

   async ngOnInit() {
      await this.fetchAccounts();

      this.subscriptions.push(
         PortfolioService.selectedAccountId.subscribe((accountId: string) => {
            this.accountId = accountId;
            const account = this.accounts.find(
               (account: any) => account.id == accountId
            );
            this.syncTimeAndDate = account?.last_sync_at;
            this.portfolioService.updateDate(account?.last_sync_at);
            if (account.is_manual) {
               this.isManual = true;
            }
         })
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item: any) => {
            item.unsubscribe();
         });
      }
   }

   async fetchAccounts() {
      this.portfolioService.showSyncLoading();
      const response = await lastValueFrom(this.portfolioService.getAccounts());
      if (response.status) {
         this.accounts = response.data;
      }
      this.portfolioService.hideSyncLoading();
   }

   async syncPortfolio() {
      this.analyticsUtils.portfolio_sync_tapped();
      this.portfolioService.showSyncLoading();
      var resp: any = '';
      try {
         if (!this.isManual) {
            resp = await lastValueFrom(
               this.portfolioService.syncPortfolio(this.accountId)
            );
         } else {
            resp = await lastValueFrom(
               this.portfolioService.syncManualPortfolio(this.accountId)
            );
         }

         if (resp.status && resp.status_code === 'SUCCESS') {
            this.toaster.success(resp.message);
            this.portfolioService.updateDate(resp.data.last_sync_at);
            this.syncTimeAndDate = resp.data.last_sync_at;
            this.portfolioService.hideSyncLoading();
            if (this.parentComponent === 'portfolio-purification') {
               this.portfolioService.triggerDataReload();
            } else {
               this.buttonClicked.emit();
            }
         } else {
            this.syncTimeAndDate = null;
         }
      } catch (error) {
         this.portfolioService.hideSyncLoading();
         this.toaster.error('Sync failed');
         this.syncTimeAndDate = null;
      }
   }

   @HostBinding('class')
   get btnType(): string {
      if (this.btnColor === 'white') {
         return 'pill-btn-white';
      } else if (this.btnColor === 'green') {
         return 'pill-btn-green';
      } else {
         return ''; // Default class if not inside any specific container
      }
   }
}
