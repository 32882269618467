import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '../../services/user-api.service';
import { AuthService } from '../../services/auth.service';
import { LoaderServices } from '../../services/loader.service';
import { SubscriptionService } from '../../services/subscription.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { UserOnboardingServeComponent } from '../user-onboarding-serve/user-onboarding-serve.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../services/shared.service';

declare var AppleID: any;

@Component({
   selector: 'app-apple-login',
   templateUrl: './apple-login.component.html',
   styleUrls: ['./apple-login.component.scss'],
})
export class AppleLoginComponent implements OnInit {
  public redirectURL: any;
  @Input() click = '';
  @Input() tapEvent = '';
  @Input() completedEvent = '';
  @Input() failEvent = '';
  @Input() isAppleBtnInsidePopup: boolean = false

  constructor(
   private router: Router,
   public auth: AuthService,
   public subscriptionService: SubscriptionService,
   private loader: LoaderServices,
   private modalService: NgbModal,
   public userApiService: UserApiService,
   private analyticsUtils: AnalyticsUtils,
   private sharedService: SharedService
) {

  }

   ngOnInit(): void {}

   parseJwt(token: any) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
         atob(base64)
            .split('')
            .map(function (c) {
               return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
      );

      return JSON.parse(jsonPayload);
   }

   public async signInWithApple() {
      try {
         this.analyticsUtils.SOCIAL_AUTH_BTN_TAP(this.tapEvent);
         AppleID.auth.init({
            clientId: 'com.musaffa.web.dev',
            scope: 'name email',
            redirectURI: `${window.location.origin}/authentication/login`,
            state: 'init',
            nonce: 'test',
            usePopup: true, //or false defaults to false
         });
         const data = await AppleID.auth.signIn();

         this.loader.show();
         let resp: any = await this.userApiService.appleLogin({
            token: data.authorization.id_token,
         });
         if (resp.status) {
            let token = resp.data;
            let isNew = resp.is_new;
            await this.auth.setToken(token);
            await this.subscriptionService.setUserSubscriptionInfo();
            const subInfo = SubscriptionService.subscriptionData.getValue();
            const userData: any =
               await this.userApiService.fetchUserDetailsAndSave();
            if (subInfo.role === 'FREEMIUM') {
               if (isNew) {
                  this.sharedService.afterSignUpActions(userData.email);
                  this.modalService.open(UserOnboardingServeComponent, {
                     centered: true,
                     scrollable: true,
                     size: 'xl',
                     modalDialogClass: 'paywall-popup-modal',
                  });
               }
               // else this.subscriptionService.openPaywallModal();
            }

            this.analyticsUtils.SOCIAL_AUTH_COMPLETED(
               this.completedEvent,
               userData.email,
               resp.is_new,
               AnalyticsUtils.METHOD_APPLE
            );

            if (this.isAppleBtnInsidePopup) {
               this.auth.closeAuthPopup()
               window.location.reload();
            } else {
               this.auth.setLoggedIn();
               if (this.redirectURL) {
                  this.router.navigate([this.redirectURL]).then();
                  this.loader.hide();
                  return;
               }
               this.router.navigate(['/']);
            }
            // if (this.redirectURL) {
            // this.router.navigate([this.redirectURL]).then();
            this.loader.hide();
            // return;
            //  }
            //  this.router.navigate(['/cabinet']).then();
         }
         this.loader.hide();
      } catch (error: any) {
         this.analyticsUtils.SOCIAL_AUTH_FAILED(
            this.failEvent,
            '',
            '',
            error.message
         );

         //handle error.
      }
      this.loader.hide();
    } catch (error : any) {
      this.analyticsUtils.SOCIAL_AUTH_FAILED(
        this.failEvent,
        "",
        "",
        error.message
     )

      //handle error.
    }
    @HostBinding('class')
    get appleBtnStyling(): string {
       if (this.isAppleBtnInsidePopup) {
          return 'btn-styling';
       } else {
          return '';
       }
    }
  }
