import { Component, Input, OnInit } from '@angular/core';
import { Subject, interval, takeUntil } from 'rxjs';
import {
   trigger,
   state,
   style,
   animate,
   transition,
} from '@angular/animations';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConnectIframeModalComponent } from '../../connect-iframe-modal/connect-iframe-modal.component';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-link-portfolio',
   templateUrl: './link-portfolio.component.html',
   styleUrls: ['./link-portfolio.component.scss'],
   animations: [
      trigger('fade', [
         state('void', style({ opacity: 0 })),
         state('*', style({ opacity: 1 })),
         transition('void <=> *', [animate('.3s ease-in-out')]),
      ]),
   ],
})
export class LinkPortfolioComponent implements OnInit {
   private destroy$ = new Subject<void>();
   imageIndex: number = 1;
   accountsConnected: string | null = null;
   assetsLinked: string | null = null;
   userRole: string | null = null;
   hasPortfolioAccounts!: boolean;
   isPremiumUser: boolean = false;
   connectAccountModalRef!: NgbModalRef;
   linkLoader: boolean = false;
   redirectUrl: string = '';

   @Input() isForPortfolio: boolean = false;
   maxManualPortfoliosCount: number = 0;
   maxBrokeragePortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;
   currentBrokeragePortfoliosCount: number = 0;

   constructor(
      private typesenseService: TypesenseService,
      private portfolioService: PortfolioService,
      private authService: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private sharedService: SharedService,
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils,
      public accessControlService: AccessControlService
   ) {}

   ngOnInit(): void {
      this.getPortfolioStats();
      interval(10000)
         .pipe(takeUntil(this.destroy$))
         .subscribe(() => {
            this.changeImage();
         });

      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .subscribe((permissions) => {
            // this.isPremiumUser = permission;
            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;
            this.maxBrokeragePortfoliosCount = permissions.brokerage_connected_portfolios_count;
         });

      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;

            if (this.userRole !== 'ANONYMOUS') {
               this.getPortfolioList();
            }
         });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   changeImage() {
      this.imageIndex++;
      if (this.imageIndex > 3) {
         this.imageIndex = 1;
      }
   }

   setImageIndex(index: number) {
      this.imageIndex = index;
   }

   openAuthPopup() {
      // this.authService.openAuthPopup();
      this.accessControlService.accessDenied();
   }

   private getPortfolioList(): void {
      this.portfolioService
         .getPortfoliosList()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.hasPortfolioAccounts =
                  response && response.length > 0 ? true : false;
            },
            error: (error) => {
               console.error(error);
            },
         });
   }

   getPortfolioStats() {
      this.typesenseService
         .getPortfolioStats()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response: any) => {
               this.accountsConnected = response.accounts_connected;
               this.assetsLinked = response.assets_linked;
            },
            error: (error) => {
               console.error(error);
            },
         });
   }

   openConnectIframeModal() {
      this.connectAccountModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectAccountModalRef.componentInstance.iframeUrl =
         this.redirectUrl;
   }

   closeConnectIframeModal() {
      if (this.connectAccountModalRef) {
         this.connectAccountModalRef.dismiss();
      }
   }

   linkPortfolioAccount() {
      this.linkLoader = true;
      this.portfolioService
         .getAuthUrl()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response: any) => {
               if (response.status) {
                  this.analyticsUtils.portfolio_link_account_tapped();
                  this.redirectUrl = response.data.redirect_url;
                  this.linkLoader = false;
                  this.openConnectIframeModal();
               }
            },
            error: (error) => {
               console.log(error);
               this.linkLoader = false;
            },
         });
   }

   openSupportedBrokerages() {
      this.portfolioService.openSupportedBrokeragesModal(true);
   }

   handleConnectBtn() {
      if (!this.authService.getUserId()) {
         // this.openAuthPopup();
         this.accessControlService.accessDenied();
      } else {
         this.portfolioService.openConnectPortfolioPopup()
         // this.openSupportedBrokerages();
      }
   }

   getAccountsCount() {
      this.portfolioService.getAccountCounts().pipe(takeUntil(this.destroy$)).subscribe((data) => {
         this.currentManualPortfoliosCount = data.manual_connected_count;
         this.currentBrokeragePortfoliosCount = data.brokerage_connected_count;
      })
   }
}
