<section>
   <div class="container">
      <article class="d-grid text-center">
         <p class="mb-0 fw-700 join-text" *ngIf="!alreadyJoined">
            Join our new <span class="gradient-text">Referral Program</span>
         </p>
         <p class="mb-0 fw-700 join-text" *ngIf="alreadyJoined">
            Musaffa's new <span class="gradient-text">Referral Program</span>
         </p>
         <p class="mb-0  fw-600 join-description">
            Start earning cash for every new subscriber you refer
         </p>
         <div class="btns-wrap mx-auto" *ngIf="!alreadyJoined">
            <button class="btn shadow-none rounded-3 fw-500 text-white join-btn" (click)="openConfirmationPopup(joinProgramConfirmation)">
               Join Program
            </button>
            <button class="btn shadow-none rounded-3 fw-500 know-btn" (click)="openJoinProgramPopup(joinProgramContent)">
               Know More
            </button>
         </div>
         <div class="btns-wrap mx-auto" *ngIf="alreadyJoined">
            <button class="btn shadow-none rounded-3 fw-500 text-white join-btn" (click)="getFirstPrompter()">
               {{ isLoading ? 'Opening Tracking Portal...' : 'Tracking Portal' }}
            </button>
            <button class="btn shadow-none rounded-3 fw-500 know-btn" (click)="openJoinProgramPopup(joinProgramContent)">
               Program Details
            </button>
         </div>
      </article>
   </div>
</section>


<ng-template #joinProgramConfirmation let-modal>
   <div class="modal-body d-flex flex-column w-full p-0">
      <div class="d-flex flex-column p-3 p-lg-4 modal-content">
         <h2 class="mb-0 text-start fw-600 text-dark">Join Cash Based Referral Program</h2>
         <p class="mb-1 text-start">
            You would be routed to our referral portal where you'll get a unique link which can be shared with others. You can view all your referrals, track commission and payouts from that portal
         </p>
         <div class="d-flex align-items-centre justify-content-end gap-2 gap-lg-3">
            <button class="btn shadow-none rounded-3 fw-500 cancel-btn" (click)="modal.dismiss()">
               Cancel
            </button>
            <button class="btn shadow-none rounded-3 fw-500 text-white join-btn" (click)="getFirstPrompter()" [disabled]="isLoading">
               {{ isLoading ? 'Joining...' : 'Join Program' }}
               <div class="spinner-border spinner-border-sm text-white" role="status" *ngIf="isLoading">
                  <span class="visually-hidden">Loading...</span>
               </div>
            </button>
         </div>
      </div>
   </div>
</ng-template>


<ng-template #joinProgramContent let-modal>
   <div class="modal-body d-flex flex-column w-full p-0">
      <div class="overflow-hidden rounded-3 bg-light border-bottom">
         <iframe class="d-block" [src]="sanitizedIframeSrc" frameborder="0" width="100%" height="500px"></iframe>
      </div>
      <div class="d-flex flex-column p-3 p-lg-4 modal-content">
         <!-- <h2 class="mb-0 text-start fw-600 text-dark">Join Program</h2> -->
         <!-- <p class="mb-1 text-start">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam nam a vitae ullam voluptatum aperiam odio blanditiis error nesciunt quis.
         </p> -->
         <div class="d-flex align-items-centre justify-content-end gap-2 gap-lg-3">
            <button class="btn shadow-none rounded-3 fw-500 cancel-btn" (click)="modal.dismiss()">
               Cancel
            </button>
            <button *ngIf="!alreadyJoined" class="btn shadow-none rounded-3 fw-500 text-white join-btn" (click)="getFirstPrompter()" [disabled]="isLoading">
               {{ isLoading ? 'Joining...' : 'Join Program' }}
               <div class="spinner-border spinner-border-sm text-white" role="status" *ngIf="isLoading">
                  <span class="visually-hidden">Loading...</span>
               </div>
            </button>
            <button *ngIf="alreadyJoined" class="btn shadow-none rounded-3 fw-500 text-white join-btn" (click)="getFirstPrompter()" [disabled]="isLoading">
               {{ isLoading ? 'Opening Tracking Portal...' : 'Tracking Portal' }}
               <div class="spinner-border spinner-border-sm text-white" role="status" *ngIf="isLoading">
                  <span class="visually-hidden">Loading...</span>
               </div>
            </button>
         </div>
      </div>
   </div>
</ng-template>





<!-- <div class="position-relative referral-container mx-auto">
   <article class="container">
      <button type="button" class="position-absolute close-popup-btn text-black p-1 rounded-3 btn shadow-none" (click)="activeReferralModal.dismiss()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="currentColor" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>

      <div class="d-grid gap-3 gap-lg-4">
         <img src="/assets/images/header/musaffa-logo-black.webp" alt="Musaffa Logo" class="header-logo d-block img-fluid" />

         <p class="mb-0 fw-600 join-text">
            <span class="fw-400">Join our new</span> <br>
            <span class="gradient-text">Referral Program</span> and start earning cash for every new subscriber you refer
         </p>

         <div class="btns-wrap">
            <button class="btn shadow-none rounded-3 fw-500 text-white know-btn" (click)="navigate()">
               Know More
            </button>
            <button class="btn shadow-none rounded-3 fw-500 text-white join-btn" (click)="redirectToFirstprompter()">
               Join Program
            </button>
         </div>
      </div>

      <div class="img-wrap"></div>
   </article>

</div> -->
