<header class="modal-header border-0 d-flex justify-content-between gap-2 gap-lg-3">
   <h4 class="modal-title mb-0">
      {{ portfolioType === 'manual' ? 'Manual Portfolio Connections Limit Reached' : 'Broker Linked Portfolio Connections Limit Reached' }}
   </h4>
</header>
<div class="modal-body">
   <p class="modal-para mb-2">
      {{ portfolioType === 'manual' ? 'You have reached your limits for manual portfolio connections on your plan.' : 'You have reached your limits for broker linked portfolio connections on your plan.' }}
         
   </p>
   <p class="modal-para mb-0">
      Upgrade to unlock more.
   </p>
   
   <div class="d-flex align-items-center justify-content-end gap-2 gap-lg-3 mt-3">
      <button class="btn shadow-none popup-btn cancel-btn" (click)="activeModal.dismiss()">
         Cancel
      </button>
      <button class="btn shadow-none popup-btn details-btn" (click)="closeAllModalsAndNavigate()">
         Upgrade
      </button>
   </div>
</div>
   

