import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { PortfolioActions } from './portfolio.actions';
import { PortfolioService } from 'src/app/features/our-portfolio/services/portfolio.service';

@Injectable()
export class PortfolioEffects {
  loadAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PortfolioActions.loadAccounts),
      switchMap(() => {
        return this.portfolioService.getPortfolioAccounts().pipe(
          map(accounts => PortfolioActions.loadAccountsSuccess({ accounts: accounts.data })),
          catchError(error => of(PortfolioActions.loadAccountsFailure({
            error: error?.message || 'Failed to load accounts'
          })))
        )
      })
    );
  });

  constructor(
    private actions$: Actions,
    private portfolioService: PortfolioService
  ) {}
}
