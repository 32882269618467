import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, lastValueFrom, map, Observable, of, shareReplay, Subject } from 'rxjs';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SectorIndustries, SectorIndustriesCollectionResponse, SectorIndustriesHits } from 'src/app/models/sector-industries/sector-industries.model';
import { TYPESENSE_COLLECTIONS } from 'src/app/shared/constants/typesense-collections';
import { FilterService } from 'src/app/shared/services/filter.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { CountryFilterPopupComponent } from '../../stock-screener/components/country-filter-popup/country-filter-popup.component';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
@Injectable({
   providedIn: 'root'
})

export class EtfFilterService {
   apiUrl: string = environment.apiUrl;

   // advance filter offcanvas
   private showAdvanceFilterOffcanvasSource = new Subject<void>();
   showOffcanvas$ = this.showAdvanceFilterOffcanvasSource.asObservable();

   // fount etfs count
   private filteredETFsCountSubject = new BehaviorSubject<number | string>(0);
   filteredETFsCount$ = this.filteredETFsCountSubject.asObservable();


   // countries modal
   countriesModalRef!: NgbModalRef;

   private sectorIndustries$!: Observable<SectorIndustries[]>;

   constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      private filterService: FilterService,
      private typesenseService: TypesenseService,
      private marketUtilsService: MarketUtilsService
   ) {}

   getFilterCategories() {
      return this.filterService.getFilterCategories('etf');
   }

   getFilterGroups() {
      return this.filterService.getFilterGroups(null, 'etf');
   }

   getFilters() {
      return this.filterService.getFilters(null, 'etf');
   }

   public async getEtfData(
      page: number,
      perPage: number,
      filters: any,
      sortColumn: string,
      sortOrder: string
   ): Promise<any> {

      const fixedFilters: any[] = [
         // {
         //    field: 'status',
         //    value: 'PUBLISH',
         // }
      ];

      const filterBy = this.filterService.buildFilterConditions(
         [...fixedFilters, ...filters], 'etf'
      );

      const sortBy = [];
      if (sortColumn && sortOrder) {
         sortBy.unshift(`${sortColumn}:${sortOrder}`);
      }

      const queryParams = new HttpParams()
         .set('page', page)
         .set('per_page', perPage ?? 15)
         .set('q', '*')
         .set('filter_by', filterBy.filter((filter: any) => filter !== '').join('&&'))
         .set('sort_by', sortBy.join(','));

      return await lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.ETFS_DATA}/documents/search`,
               { params: queryParams }
            )
            .pipe(
               map((response) => {
                  const data = response.hits;
                  const items: any = {};
                  data.forEach((x: any) => {
                     const doc = x.document;
                     items[doc.id] = this.typesenseService.mapPricesData(doc);
                  });
                  const number_of_pages = Math.ceil(
                     response.found / response.request_params.per_page
                  );
                  return {
                     currentPage: response.page,
                     hasNext: response.page < number_of_pages,
                     hasPrevious: response.page > 1,
                     itemCount: response.request_params.per_page,
                     pageQuantity: number_of_pages,
                     totalCount: response.found,
                     items: items,
                  };
               })
            )
      );
   }

   showAdvanceFilter() {
      this.showAdvanceFilterOffcanvasSource.next();
   }

   openCountriesPopup() {
      this.marketUtilsService.fetchEnabledEtfCountriesCode().then((countries) => {
         this.countriesModalRef = this.modalService.open(CountryFilterPopupComponent, {
            centered: true,
            scrollable: true,
            backdropClass: 'global-modal-backdrop',
            windowClass: 'global-modal',
            size: 'lg'
         });
         this.countriesModalRef.componentInstance.countrySet = countries;
      });
   }

   closeCountriesPopup() {
      if (this.countriesModalRef) {
         this.countriesModalRef.close()
      }
   }

   setFilteredETFsCount(count: string | number): void {
      this.filteredETFsCountSubject.next(count);
   }

   getOverviewColumns() {
      return [
         { header: 'Domicile', key: 'domicile', type: 'country', sortable: false },
         { header: 'Asset Class', key: 'assetClass', type: 'assetClass' },
         { header: 'Holdings', key: 'numberOfHoldings', type: 'number' },
         { header: 'Investment Segment', key: 'investmentSegment', type: 'investmentSegment' },
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getComplianceColumns() {
      return [
         { header: 'Halal Revenue %', key: 'businessCompliantRatio', type: 'percent' },
         { header: 'Not Halal Revenue %', key: 'businessNonCompliantRatio', type: 'percent' },
         { header: 'Doubtful Revenue %', key: 'businessQuestionableRatio', type: 'percent' },
         { header: 'Interest-bearing Assets %', key: 'interestBearingAssetsRatio', type: 'percent' },
         { header: 'Interest-bearing Debt %', key: 'interestBearingDebtRatio', type: 'percent' },
         // { header: 'Purification per Share', key: 'purificationPerShare', type: 'amount' }
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   getPriceColumns() {
      return [
         { header: 'Price', key: 'price', type: 'amount' },
         { header: 'Open Price', key: 'open', type: 'amount' },
         { header: 'Previous Close', key: 'PreviousClosePrice', type: 'amount' },
         { header: 'Low Price', key: 'low', type: 'amount' },
         { header: 'High Price', key: 'high', type: 'amount' },
         { header: '52-Week High', key: '52WeekHigh', type: 'amount' },
         { header: '52-Week Low', key: '52WeekLow', type: 'amount' },
         { header: 'Price Change 1D', key: 'priceChange1DPercent', type: 'percent', showPositiveNegative: true },
         { header: 'Price Change 1Y', key: 'priceChange1YPercent', type: 'percent', showPositiveNegative: true },
         { header: 'Avg. Volume', key: 'volume', type: 'number' }
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   // getDistributionColumns() {
   //    return [];
   // }

   getPerformanceColumns() {
      return [
         { header: 'AUM', key: 'aum', type: 'marketcap' },
         { header: 'NAV', key: 'nav', type: 'amount' },
         // { header: 'Market Cap', key: 'marketCap', type: 'marketcap' },
         { header: 'Expense Ratio', key: 'expense_ratio', type: 'percent' },
         // { header: 'P/E Ratio', key: 'peRatio', type: 'number' }
      ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   }

   // getReturnColumns() {
   //    return [
   //       { header: 'Total Return 1W', key: 'totalReturnOneWeek', type: 'percent', showPositiveNegative: true },
   //       { header: 'Total Return 1M', key: 'totalReturnOneMonth', type: 'percent', showPositiveNegative: true },
   //       { header: 'Total Return YTD', key: 'totalReturnYTD', type: 'percent', showPositiveNegative: true },
   //       { header: 'Total Return 1Y', key: 'totalReturnOneYear', type: 'percent', showPositiveNegative: true },
   //       { header: 'Total Return 3Y', key: 'totalReturnThreeYears', type: 'percent', showPositiveNegative: true },
   //       { header: 'Total Return 5Y', key: 'totalReturnFiveYears', type: 'percent', showPositiveNegative: true }
   //    ] as Array<{ header: string; key: string, type?: string, showPositiveNegative?: boolean }>;
   // }

   async getHalalScoreData() {
      let halalScoreJson: { key: string, display_value: string }[] = [
         { key: '1', display_value: '1', },
         { key: '2', display_value: '2', },
         { key: '3', display_value: '3', },
         { key: '4', display_value: '4', },
         { key: '5', display_value: '5', },
      ];
      return halalScoreJson;
   }

   async getExchangesData(country: string = 'US') {
      return await this.filterService.getOptionsData('getExchangeList', [country]);
   //    return [{
   //       "country": "United States",
   //       "countryCode": "US",
   //       "detailedProfileAvailable": true,
   //       "etfEnabled": true,
   //       "etfExchanges": [
   //           "NYSE ARCA",
   //           "NASDAQ",
   //           "BATS",
   //           "NYSE"
   //       ],
   //       "etfsCovered": true,
   //       "id": "US",
   //       "sortOrder": 1,
   //       "stockEnabled": true,
   //       "stockExchanges": [
   //           "NYSE",
   //           "NASDAQ"
   //       ],
   //       "stocksCovered": true
   //   }]
   }

   async getComplianceData() {
      let complianceJson: { key: string, display_value: string, isAll?: boolean }[] = [
         // { key: '', display_value: 'All', isAll: true },
         { key: 'COMPLIANT', display_value: 'Halal' },
         { key: 'NON_COMPLIANT', display_value: 'Not Halal' },
         { key: 'QUESTIONABLE', display_value: 'Doubtful' },
      ];
      return complianceJson;
   }

   async getEtfClass() {
      let analystRatingsJson: { key: string, display_value: string }[] = [
         { key: 'Fixed Income', display_value: 'Fixed Income' },
         { key: 'Equity', display_value: 'Equity' },
      ];
      return analystRatingsJson;
   }

   private fetchSectorIndustries(): Observable<SectorIndustries[]> {
      const queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return this.http.get<SectorIndustriesCollectionResponse>(`${environment.typeSense.url}/collections/${TYPESENSE_COLLECTIONS.MUSAFFA_SECTOR}/documents/search`, { params: queryParams }).pipe(
         map((response) => response.hits.map((hits: SectorIndustriesHits) => hits.document) as SectorIndustries[])
      );
   }
   getSectorIndustries(): Observable<SectorIndustries[]> {
      this.sectorIndustries$ = this.sectorIndustries$ ?? this.fetchSectorIndustries().pipe(shareReplay());
      return this.sectorIndustries$;
   }
}
