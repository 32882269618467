import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import {
   ModalDismissReasons,
   NgbActiveModal,
   NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from '../../services/subscription.service';
import { UserApiService } from '../../services/user-api.service';
import { ToastrService } from 'ngx-toastr';
// @ts-expect-error
import moengage from '@moengage/web-sdk';
import { AnalyticsUtils } from '../../services/analytics-utils';
@Component({
   selector: 'app-user-onboarding-serve',
   templateUrl: './user-onboarding-serve.component.html',
   styleUrls: ['./user-onboarding-serve.component.scss'],
})
export class UserOnboardingServeComponent implements OnInit {
   public serveForm: FormGroup;
   public ifShow: boolean = false;
   question: any[] = [];
   interested_markets!: any;
   brokerages!: any;
   user_description!: any;
   investor_type!: any;
   signup_reason!: any;
   first_point_attribution!: any;
   selectedPeople = [];
   updatedData: any = {};
   @ViewChild('onboardingFormContent', { static: true })
   onboardingFormContent!: TemplateRef<any>;
   onboardingFormModalRef: any;
   isLoading: boolean = false;
   shouldShowSkipBtn: boolean = true;

   constructor(
      private configService: FirebaseRemoteConfigService,
      private modalService: NgbModal,
      private subscriptionService: SubscriptionService,
      public activeModal: NgbActiveModal,
      private userApiService: UserApiService,
      private toaster: ToastrService,
      private analyticsUtils: AnalyticsUtils
   ) {
      this.serveForm = new FormGroup({
         interested_markets: new FormControl(''),
         other_interested_markets: new FormControl(''),
         brokerages: new FormControl(''),
         other_brokerages: new FormControl(''),
         user_description: new FormControl('', Validators.required),
         investor_type: new FormControl('', Validators.required),
         signup_reason: new FormControl(''),
         other_signup_reason: new FormControl(''),
         first_point_attribution: new FormControl(''),
         other_first_point_attribution: new FormControl(''),
      });
   }

   ngOnInit(): void {
      this.getUserOnboardingDefaults();
      this.openonboardingFormModal();
   }

   openonboardingFormModal() {
      this.onboardingFormModalRef = this.modalService.open(
         this.onboardingFormContent,
         {
            centered: true,
            scrollable: true,
            backdrop: 'static',
            size: 'xl',
            modalDialogClass: 'paywall-popup-modal',
         }
      );
   }

   closeonboardingFormModal() {
      this.activeModal.dismiss();
      if (this.onboardingFormModalRef) {
         this.onboardingFormModalRef.dismiss();
      }
      this.analyticsUtils.onboarding_questions_skip_tapped();
      this.subscriptionService.openPaywallModal();
   }

   submitOnboardingForm() {
      this.activeModal.dismiss();
      if (this.onboardingFormModalRef) {
         this.onboardingFormModalRef.dismiss();
      }
      this.subscriptionService.openPaywallModal();
   }

   getUserOnboardingDefaults() {
      this.configService.userOnboardingdefaults().then((data) => {
         this.question = data.onboarding_qna;

         this.interested_markets = this.question[0];
         this.brokerages = this.question[1];
         this.user_description = this.question[2];
         this.investor_type = this.question[3];
         this.signup_reason = this.question[4];
         this.first_point_attribution = this.question[5];
         this.shouldShowSkipBtn = data.onboarding_skip_config.show_skip_onboarding_qna
      });
   }

   // private getDismissReason(reason: any): void {
   //    if (reason === ModalDismissReasons.ESC) {
   //       //   return 'by pressing ESC';
   //       this.subscriptionService.openPaywallModal();
   //    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
   //       //   return 'by clicking on a backdrop';
   //       this.subscriptionService.openPaywallModal();
   //    } else {
   //       //   return `with: ${reason}`;
   //       this.subscriptionService.openPaywallModal();
   //    }
   // }

   async onSubmit() {
      this.isLoading = true;
      let params = {
         platform: this.serveForm.value.first_point_attribution,
         other_platform: this.serveForm.value.other_first_point_attribution,
         interested_markets: this.serveForm.value.interested_markets,
         other_interested_markets:
            this.serveForm.value.other_interested_markets,
         brokerages: this.serveForm.value.brokerages,
         other_brokerages: this.serveForm.value.other_brokerages,
         user_description: this.serveForm.value.user_description,
         investor_type: this.serveForm.value.investor_type,
         signup_reason: this.serveForm.value.signup_reason,
         other_signup_reason: this.serveForm.value.other_signup_reason,
      };
      this.analyticsUtils.onboarding_questions_submit_tapped();
      this.convertArraysToStrings(params);
      try {
         let data: any = await this.userApiService.submitUserOnboardingServe(
            this.updatedData
         );
         if (data.status) {
            this.toaster.success(data.message);
            this.updateUserProperties();
            this.submitOnboardingForm();
         } else {
            this.toaster.error(data.message);
         }
      } catch (err) {
         console.log(err);
      } finally {
         this.isLoading = false;
      }
   }

   convertArraysToStrings(data: any) {
      for (let key in data) {
         if (Array.isArray(data[key])) {
            this.updatedData[key] = data[key].join(', ');
         } else {
            this.updatedData[key] = data[key];
         }
      }
   }

   updateUserProperties() {
      if ((this.updatedData.platform = 'other')) {
         moengage.add_user_attribute(
            'acquisition_platform',
            this.updatedData.other_platform
         );
      } else {
         moengage.add_user_attribute(
            'acquisition_platform',
            this.updatedData.platform
         );
      }
      moengage.add_user_attribute(
         'interested_markets',
         this.updatedData.interested_markets ?? ''
      );
      moengage.add_user_attribute(
         'other_interested_markets',
         this.updatedData.other_interested_markets ?? ''
      );
      moengage.add_user_attribute(
         'brokerages',
         this.updatedData.brokerages ?? ''
      );
      moengage.add_user_attribute(
         'other_brokerages',
         this.updatedData.other_brokerages ?? ''
      );
      moengage.add_user_attribute(
         'user_proficiency',
         this.updatedData.user_description ?? ''
      );
      moengage.add_user_attribute(
         'investor_type',
         this.updatedData.investor_type ?? ''
      );
      moengage.add_user_attribute(
         'signup_reason',
         this.updatedData.signup_reason ?? ''
      );
      moengage.add_user_attribute(
         'other_signup_reason',
         this.updatedData.other_signup_reason ?? ''
      );
   }
}
