import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-forecast-earnings-chart',
  templateUrl: './forecast-earnings-chart.component.html',
  styleUrls: ['./forecast-earnings-chart.component.scss']
})
export class ForecastEarningsChartComponent implements OnInit {
  @Input() earningsChartData: any;
  earningsChart: any;

  constructor() { }

  ngOnInit(): void {
    this.generateChart();
  }

  private generateChart(): void {
    this.earningsChart = new Chart("earningsChart", {
      type: 'bar',
      data: this.earningsChartData,
      options: {
        legend: {
          display: false
        },
        maintainAspectRatio: true,
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true }]
        }
      }
    });
  }

}













// import { Component, OnInit } from '@angular/core';
// import { Chart } from 'chart.js';

// @Component({
//   selector: 'app-forecast-earnings-chart',
//   templateUrl: './forecast-earnings-chart.component.html',
//   styleUrls: ['./forecast-earnings-chart.component.scss']
// })
// export class ForecastEarningsChartComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//     var myChart = new Chart("earningsChart", {
//       type: 'bar',
//       data: {
//         labels: ['Q4, 2022', 'Q1, 2023', 'Q2, 2023', 'Q3, 2023', 'Q4, 2023'],
//         datasets: [{
//           data: [12000, 19000, 3000, 5000, -5000],
//           barThickness: 40,
//           backgroundColor: [ '#D9D9D9', '#D9D9D9', '#D9D9D9', '#ACE8DC', '#EAC4D5' ],
//         },
//         {
//           data: [0, 0, 0, 0, -34564],
//           barThickness: 40,
//           backgroundColor: [ '#D9D9D9', '#D9D9D9', '#D9D9D9', '#ACE8DC', '#D6EADF' ],
//         },{
//           data: [0, 0, 0, 0, -2456],
//           barThickness: 40,
//           backgroundColor: [ '#D9D9D9', '#D9D9D9', '#D9D9D9', '#ACE8DC', '#95B8D1' ],
//         }]
//       },
//       options: {
//         legend: {
//           display: false
//         },
//         maintainAspectRatio: true,
//         scales: {
//           xAxes: [{
//               stacked: true
//           }],
//           yAxes: [{
//               stacked: true
//           }]
//         }
//       }
//     });
//   }

// }
