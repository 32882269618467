import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Article } from 'src/app/models/academy/academy.modal';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

@Component({
   selector: 'app-academy',
   templateUrl: './academy.component.html',
   styleUrls: ['./academy.component.scss']
})

export class AcademyComponent implements OnInit {
   private destroy$ = new Subject<void>();
   articles: Article[] = [];
   loading: boolean = false;
   isMobile: boolean = false;
   perPage!: number

   constructor(
      private typesenseService: TypesenseService,
      private observer: BreakpointObserver,
   ) { }

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.getAcademyArticles()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByAcademyId(index: number, list: Article): number | string {
      return list.post_id;
   }

   async getAcademyArticles(): Promise<void> {
      try {
         this.loading = true
         const resp = await this.typesenseService.getAcademyArticles( '*', 'status:=publish', 6, 1, 'published_at:desc' );
         this.articles = resp.hits.map((hit: any) => hit.document)
      } catch (error) {
         console.log(error);
         this.loading = false
      } finally {
         this.loading = false
      }
   } 
}
