<!-- <div *ngIf="showBanner" class="promo-banner text-center d-none d-lg-block">
    <img src="/assets/images/promo-gift.png" alt=""> {{ bannerText }} <a [href]="bannerLink" class="banner-link">{{ bannerLinkText }}</a>
</div> -->
<router-outlet *ngIf="fireAuthUserLoaded"></router-outlet>
<app-loader></app-loader>
<app-download-prompt></app-download-prompt>
<!-- Maintenance Overlay -->
<div *ngIf="isUnderMaintenance" class="maintenance-overlay">
  <div class="maintenance-container d-flex flex-column align-items-center justify-content-center">
    <div class="maintenance-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="12" cy="12" r="7"></circle>
        <path d="M12 9v2"></path>
        <path d="M12 15h.01"></path>
      </svg>
    </div>
    <h2>System Under Maintenance</h2>
    <p>Assalaamualikum, we're currently performing scheduled maintenance to improve your experience. Please check back soon.</p>
    <!-- <p class="maintenance-time">Estimated completion time: {{maintenanceEndTime | date:'medium'}}</p> -->
    <!-- <button class="refresh-button" (click)="checkMaintenanceStatus()">Refresh</button> -->
  </div>
</div>

<style>
  .maintenance-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .maintenance-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }

  .maintenance-icon {
    color: #e74c3c;
    margin-bottom: 1rem;
  }

  .maintenance-container h2 {
    color: #333;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .maintenance-container p {
    color: #666;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  .maintenance-time {
    font-weight: 500;
    color: #333;
    background-color: #f8f9fa;
    padding: 0.5rem;
    border-radius: 4px;
    margin-top: 1rem;
  }

  .refresh-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .refresh-button:hover {
    background-color: #2980b9;
  }
</style>
