
import { BreakpointObserver } from '@angular/cdk/layout';
import { DecimalPipe } from '@angular/common';
import { Component, ElementRef,  Input,  OnInit, ViewChild } from '@angular/core';
import { Chart } from "chart.js";
// @ts-ignore
import 'chartjs-gauge/dist/chartjs-gauge.js';
import { lastValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-debt-chart',
  templateUrl: './debt-chart.component.html',
  styleUrls: ['./debt-chart.component.scss']
})
export class DebtChartComponent implements OnInit {
@ViewChild('debtChart',{static:true}) debtChart: ElementRef;
   
data = [30, 100];
value = null
type = ""
config!:any
isMobile = false
  @Input() set gauge(gaugeValue : any) {
      if(gaugeValue>=0){
        
         this.value = gaugeValue.toFixed(2);
         // this.value = this.decimalPipe.transform(gaugeValue,'1.2-2')       
         this.initChart()
      }      
  }

  @Input() set status(statusVal: any) {
   if(statusVal){
      this.type = statusVal
      this.initChart()
   }
      
         
}

  constructor(
   private decimalPipe:DecimalPipe,
   private observer: BreakpointObserver
  ) {
   this.debtChart = new ElementRef({ static: true })  
  }
  


  ngOnInit(): void {
    
  }

  async initChart() {
    if(this.value == null || this.type == null)
      return
    
    // var isMobile = await lastValueFrom(this.observer.observe('(max-width: 767px)').pipe(map(result => result.matches)))
    this.observer.observe('(max-width: 768px)').subscribe(result => {
     var isMobile = result.matches
     var bgColor=""
    //  if(this.type == "Pass")
    //     bgColor = "#F6D8D8";
    //  else
    //     bgColor = "#D43A3A";

      var chartValue:any = this.value!
        if(chartValue>100)
          chartValue = 100
     this.config = {
       type: "gauge",
       data: {
         labels: ["", ""],
         datasets: [
           {
             label: "",
             data: this.data,
             value: chartValue,
             minValue: 0,
             backgroundColor: ["#F6D8D8", "#D43A3A"],
             borderWidth: 2
           }
         ]
       },
       options: {
        cutoutPercentage:55,
        valueLabel: {
          fontSize: isMobile ? 20 : 32,
          fontWeight:600,
          backgroundColor:'transparent',
          color:'#212121',
          formatter: (cValue:any, context:any) =>{
            // debugger;
            return this.value! + "%";
            // return '< ' + Math.round(value);
          }
        },
         legend: {
           display: true,
           position: "bottom",
           labels: {
             generateLabels: {}
           }
         },
         responsive: true,
         title: {
           display: false,
           text: ""
         },
         layout: {
           padding: {
             bottom: 20
           }
         },
         needle: {
           radiusPercentage: 1,
           widthPercentage: 1,
           lengthPercentage: 60,
           color: "#212121"
         },       
         plugins: {
           datalabels: {
             display: "auto",
             formatter: function (value:any, context:any) {
               // debugger;
               return context.chart.data.labels[context.dataIndex];
               // return context.dataIndex===0?'Normal':context.dataIndex===1?'Warning':'Critical';
               // return '< ' + Math.round(value);
             },
             color: function (context:any) {
               return "white";
             },
             //color: 'rgba(255, 255, 255, 1.0)',
             // backgroundColor: 'rgba(0, 0, 0, 1.0)',
             // borderWidth: 0,
             // borderRadius: 5,
             font: function (context:any) {
               var innerRadius = Math.round(context.chart.innerRadius);
               isMobile
               var size = 42
               if(isMobile)
                size = 12
     
               return {
                family:"Montserrat",
                 weight: "600",
                 size: size
               };
             }
             // font: {
             //   weight: 'normal',
             //   size:16
             // }
           }
         }
       }
     };
     var ctx = this.debtChart.nativeElement.getContext("2d");
     
      const myGauge = new Chart(ctx, this.config);
      myGauge.update();
      
    });
    
   

  }

}
