import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
   selector: 'input[onlyNumbersTooltip]',
   providers: [NgbTooltip], // Injecting NgbTooltip for tooltip functionality
})
export class OnlyNumberTooltipDirective {
   constructor(
      private _el: ElementRef,
      private renderer: Renderer2,
      private tooltip: NgbTooltip // Injecting NgbTooltip
   ) {}

   @HostListener('input', ['$event']) onInputChange(event: any) {
      const inputValue = this._el.nativeElement.value;

      // Show tooltip if the input contains a dot
      if (inputValue.includes('.')) {
         this.showTooltip(
            "Your brokerage doesn't support fractional shares.Please enter a whole number."
         );
      } else {
         this.hideTooltip();
      }

      // Only keep numbers, remove any non-numeric characters
      this._el.nativeElement.value = inputValue.replace(/[^0-9]*/g, '');

      // Prevent further event propagation if any changes were made
      if (inputValue !== this._el.nativeElement.value) {
         event.stopPropagation();
      }
   }

   private showTooltip(message: string) {
      this.tooltip.ngbTooltip = message;
      this.tooltip.open();
   }

   private hideTooltip() {
      this.tooltip.close();
   }
}
