<section class="explore-section content-padding">
   <div class="container">
      <h2 class="section-main-heading">Explore Collections</h2>
      <div class="collections-grid-container">
         <div class="d-grid gap-4">
            <app-bucket-collection-chips [selectedCountry]="selectedCountry" [componentPath]="'home'"></app-bucket-collection-chips>
            <app-super-investors-list></app-super-investors-list>
         </div>
         <div class="bg-white br-10 user-collection-wrapper">
            <app-top-user-collections [sectionHeading]="'Community-Curated'"></app-top-user-collections>
         </div>
      </div>
   </div>
</section>
