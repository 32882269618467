import { Component, OnInit, Type } from '@angular/core';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { SuperInvestor } from 'src/app/models/superInvestor.model';

@Component({
  selector: 'app-investors-portfolio-list',
  templateUrl: './investors-portfolio-list.component.html',
  styleUrls: ['./investors-portfolio-list.component.scss']
})

export class InvestorsPortfolioListComponent implements OnInit {
  investorsCardLoading: boolean = false
  investors: SuperInvestor[] = []

  constructor(private typesenseService: TypesenseService) { }

  ngOnInit(): void {
    this.fetchSuperInvestors()
  }

  async fetchSuperInvestors(): Promise<void> {
    try {
      this.investorsCardLoading = true
      await this.typesenseService.getSuperInvestors("total_amount:desc", 3, 1).then((data) => {
        this.investors = data.hits.map((hit: any) => hit.document)
        this.investorsCardLoading = false;
      })
    } catch (error) {
      console.log(error);
      this.investorsCardLoading = false
    } finally {
      this.investorsCardLoading = false
    }
  }

}
