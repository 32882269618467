import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { MillionPipe } from '../../pipes/million.pipe';

@Component({
  selector: 'app-forecast-data-bar-chart',
  templateUrl: './forecast-data-bar-chart.component.html',
  styleUrls: ['./forecast-data-bar-chart.component.scss']
})
export class ForecastDataBarChartComponent implements OnInit, AfterViewInit {

  @Input() chartData: any;
  @Input() chartId!: string;
  barChart: any;
  millionPipe: MillionPipe = new MillionPipe;

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.generateChart();
  }

  private getMinValue(datasets: any) {
      let minVal = 0;
      for(let dataset of datasets) {
         const data = dataset.data.filter((item: any) => item !== null);
         if(minVal === 0)
            minVal = Math.min(...data)
         else
            minVal = Math.min(...data, minVal)
      }
      return minVal;
  }

  private generateChart(): void {
   //  const minVal = this.getMinValue(this.chartData.datasets)
    this.barChart = new Chart(this.chartId, {
      type: 'bar',
      data: this.chartData,
      options: {
        legend: {
          display: false
        },
        tooltips: {
         mode:'index',
          backgroundColor: '#ffffff',
          borderColor: "#333333",
          borderWidth: 1,
          titleFontColor: '#858585',
          xPadding: 12,
          yPadding: 12,
          displayColors: false,
          callbacks: {
            title: (tooltipItems, data) => {
              return '';
            },
            labelTextColor: function(tooltipItem, chart) {
              return '#333333';
            },
            label: (tooltipItem, data) => {
              if (data.datasets && data.datasets.length > 0) {
                const datasetIndex = tooltipItem.datasetIndex;
                // @ts-ignore
                const datasetLabel = data.datasets[datasetIndex].label;
                // @ts-ignore
                const value = data.datasets[datasetIndex].data[tooltipItem.index];

                return `${datasetLabel}: ${this.millionPipe.transform(value)}`;
              }
              return "";
            }

          }
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            ticks: {
               beginAtZero: true,
               // min: minVal*0.5,
               callback : (value,index,array) => { return this.millionPipe.transform(value) }
           },
            gridLines: {
              drawBorder: false
            },
          }],
        }
      },

    });
  }



}
