import { Component, Input, OnInit } from '@angular/core';
import {
   StockSellTransaction,
   StockSellTracking,
} from 'src/app/models/StockSellTracking';
import { PurificationMethod } from 'src/app/models/purification-method.model';

@Component({
   selector: 'app-purification-transaction',
   templateUrl: './purification-transaction.component.html',
   styleUrls: ['./purification-transaction.component.scss'],
})
export class PurificationTransactionComponent implements OnInit {
   @Input() transaction!: StockSellTransaction;
   @Input() purificationTracking!: StockSellTracking;
   @Input() purificationMethod!: PurificationMethod;

   constructor() {}

   ngOnInit(): void {
      // console.log('nskd', this.purificationTracking);
   }
}
