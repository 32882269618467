import {
   Component,
   Input,
   OnInit,
   OnChanges,
   SimpleChanges,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PublicListsService } from 'src/app/shared/services/public-lists.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ListDetails } from 'src/app/models/public-list/list-details.model';
import { lastValueFrom } from 'rxjs';
import { WatchlistService } from 'src/app/shared/services/watchlist.service';

interface watchlistForm {
   watchlistName: string;
}

@Component({
   selector: 'app-list-details-popup',
   templateUrl: './list-details-popup.component.html',
   styleUrls: ['./list-details-popup.component.scss'],
})
export class ListDetailsPopupComponent implements OnInit, OnChanges {
   @Input() content!: 'deleteList' | 'copyToWatchlist';
   @Input() listInfo!: ListDetails;
   isCopiedToWatchlist: boolean = false;
   copyToWatchlistError: string = '';
   buttonLoader: boolean = false;
   form: watchlistForm = {
      watchlistName: '',
   };

   constructor(
      public activeModal: NgbActiveModal,
      private listService: PublicListsService,
      private toaster: ToastrService,
      private location: Location,
      private route: Router,
      private watchlistService: WatchlistService
   ) { }

   ngOnInit(): void {
      this.prefilledForm();
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.listInfo && !changes.listInfo.firstChange) {
         this.prefilledForm();
      }
   }

   private prefilledForm(): void {
      if (this.listInfo) {
         this.form.watchlistName = this.listInfo.name;
      }
   }

   async deleteList() {
      this.buttonLoader = true;
      try {
         const response = await this.listService.deleteList<{
            status: boolean;
            message: string;
         }>(this.listInfo.id as string);
         if (response.status) {
            this.toaster.success(response.message);
            this.activeModal.dismiss();
            this.route.navigate(['/public-lists'], {
               queryParams: { lists: 'created' },
            });
         } else {
            this.toaster.error(response.message);
            this.activeModal.dismiss();
         }
      } catch (error) {
         console.error(error);
      } finally {
         this.buttonLoader = false;
      }
   }

   async createWatchlist(addToWatchlistForm: any) {
      this.buttonLoader = true;
      try {
         const response = await this.listService.addPublicListToWatchlist<{
            status: boolean;
            message: string;
         }>(this.listInfo.id as string, addToWatchlistForm.value.watchlistName);
         if (response.status) {
            // this.toaster.success(response.message);
            await this.fetchAllWatchList();
            this.isCopiedToWatchlist = true;
         } else {
            this.copyToWatchlistError = 'Watchlist name already exist';
            // this.toaster.error(response.message);
            this.isCopiedToWatchlist = false;
         }
      } catch (error) {
         console.error(error);
      } finally {
         this.buttonLoader = false;
      }
   }

   async fetchAllWatchList() {
      let res: any = await lastValueFrom(
         this.watchlistService.fetchAllWatchlist()
      );
      if (res.status) {
         this.watchlistService.setWatchlistCount(res.data.length);
      }
   }

   navigateToWatchlist() {
      this.activeModal.dismiss();
      this.route.navigate(['/watchlist-lists']);
   }
}
