import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { AllSubscriptionData, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { LoaderServices } from '../../services/loader.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss']
})

export class ManageSubscriptionComponent implements OnInit {

   @Input() isPopup: boolean = false;
   @Input() priceId: string|undefined;
   @Output() proceedToPayment: EventEmitter<void> = new EventEmitter<void>();

   constructor(private subscriptionService: SubscriptionService, private loader: LoaderServices) { }

   public source: string|null = null;
   public expirationDate: string|null = null;
   public planName: string|null = null;
   public isMobileSubscription: boolean = false;
   public nonPromotionalSubscription: AllSubscriptionData|undefined = undefined;
   public allActiveSubscriptions: AllSubscriptionData[] = [];
   private subscriptions: Subscription[] = [];
   currentSubscriptionPeriodEnd: string|null = null;
   cancelAtSubscriptionEnd: boolean = false;
   subscriptionInterval: string|null = null;
   subscriptionStatus: string|null = null;
   infoLoaded: boolean = false;

   ngOnInit(): void {
      this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((subscriptionData: SubscriptionInfoModel) => {
         this.allActiveSubscriptions = subscriptionData.allActiveSubscriptions;
         this.source = subscriptionData.subscriptionSource;
         this.isMobileSubscription = this.source ? ['app_store', 'play_store'].includes(this.source) : false;
         this.nonPromotionalSubscription = this.allActiveSubscriptions.find(subscription => subscription.subscriptionSource !== 'promotional');
         this.expirationDate = subscriptionData.expiresDate
                  ? new Date(subscriptionData.expiresDate).toLocaleDateString()
                  : null;
         switch(subscriptionData.newRole) {
            case 'starter':
               this.planName = 'Starter';
               break;
            case 'premium':
               this.planName = 'Premium';
               break;
            case 'premium_plus':
               this.planName = 'Premium Plus';
               break;
            case 'professional':
               this.planName = 'Professional';
               break;
         }
      }))
   }

   public getManageLink()
   {
      return environment.stripe.manageBillingLink;
   }

   async getStripeSubsInfo() {
      this.subscriptionService.setStripeSubscriptionInfo();
      this.subscriptions.push(
         SubscriptionService.stripeSubscriptionInfo.subscribe((data: any) => {
            if (Object.keys(data).length) {
               this.currentSubscriptionPeriodEnd = new Date(
                  data.current_period_end * 1000
               ).toLocaleDateString();
               this.cancelAtSubscriptionEnd = data.cancel_at_period_end;
               this.subscriptionInterval = data?.plan?.interval;
               this.subscriptionStatus = data.status;
               this.infoLoaded = true;
            }
         })
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   formatRole(role: string | undefined, source: string | null): string {
      let formattedRole = role?.replace(/_/g, ' ')
      return formattedRole ? 'Musaffa ' + (formattedRole
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ') + (source === 'promotional' ? ' (PROMO)' : '')) : 'N/A';
    }

    getSubscriptionSource(source: string | undefined): string {
      switch (source) {
        case 'stripe': return 'Web';
        case 'promotional': return '-';
        case 'app_store': return 'Apple';
        case 'play_store': return 'Android';
        default: return 'N/A';
      }
    }

    formatDate(date: string | undefined): string {
      return date ? new Date(date).toLocaleDateString() : 'N/A';
    }

   public getDeviceName(source: string | null)
   {
      if(source === 'app_store') {
         return 'Apple';
      }
      if(source === 'play_store') {
         return 'Android';
      }
      return null;
   }

   public getStoreLink(source: string | null)
   {
      if(source === 'app_store') {
         return 'https://app.musaffa.com/wKcRlYiPpKb';
      }
      if(source === 'play_store') {
         return 'https://app.musaffa.com/mcJZc85OpKb';
      }
      return null;
   }

   public async redirectToPaymentLink(): Promise<void> {
      if (this.priceId) {
         this.proceedToPayment.emit();
      //    this.loader.show();
      //    const paymentLink = await this.subscriptionService.getPaymentLink(
      //       this.priceId
      //    );
      //    window.location.href = paymentLink.url;
      //    this.loader.hide();
      }
   }

}
