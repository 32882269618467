import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   @Output() filterEvent = new EventEmitter<string>();
   filterBySymbolForm!: FormGroup;
   isMobile: boolean = false;

   constructor(private observer: BreakpointObserver, private fb: FormBuilder) { }

   ngOnInit(): void {
      this.filterBySymbolForm = this.fb.group({
         stockSymbol: [''],
      });

      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe(result => {
         this.isMobile = result.matches;
      });

      this.filterBySymbolForm.get('listName')?.valueChanges
         .pipe(
            debounceTime(300),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
         )
         .subscribe(value => {
            this.onSymbolSearch(value);
         });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   onSymbolSearch(name: string) {
      this.filterEvent.emit(name);
   }

}
