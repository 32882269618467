import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { MillionPipe } from '../../pipes/million.pipe';
import { currencyMilionPipe } from '../../pipes/currencyMilion.pipe';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-forecast-price-chart',
  templateUrl: './forecast-price-chart.component.html',
  styleUrls: ['./forecast-price-chart.component.scss']
})
export class ForecastPriceChartComponent implements OnInit {
  @Input() priceChartData: any;
  priceChart: any;
  millionPipe: MillionPipe = new MillionPipe;
  @Input() forecastHigh?: number;
  @Input() forecastMedian?: number;
  @Input() forecastLow?: number;
  @Input() current?: number;
  @Input() currency!: string;
  currencyMillion: currencyMilionPipe = new currencyMilionPipe(this.currency);
  currencyPipe: CurrencyPipe = new CurrencyPipe(this.currency);

  diffHigh?: number;
  diffMedian?: number;
  diffLow?: number;

  constructor() {}

  ngOnInit(): void {
   if(this.forecastHigh && this.current)
      this.diffHigh = this.forecastHigh - this.current;
   if(this.forecastLow && this.current)
      this.diffLow = this.forecastLow - this.current;
   if(this.forecastMedian && this.current)
      this.diffMedian = this.forecastMedian - this.current;
    this.generateChart().then((priceChart: any) => {
      // console.log(priceChart)
      // priceChart.register({
      //    afterDatasetsDraw: function (chart: any) {
      //       console.log(chart)
      //      var dataset = chart.data.datasets[0];
      //      var lastPoint = dataset.data[dataset.data.length - 1];

      //      // Define label text and position
      //      var label = "Last Point: " + lastPoint;
      //      var x = chart.scales['x-axis-0'].getPixelForTick(dataset.data.length - 1);
      //      var y = chart.scales['y-axis-0'].getPixelForValue(lastPoint);

      //      // Style the label
      //      chart.ctx.fillStyle = 'red';
      //      chart.ctx.font = '12px Arial';

      //      // Draw the label
      //      chart.ctx.fillText(label, x + 10, y - 10);
      //    }
      //  });
    })
  }

  private generateChart() {
   return new Promise<void>((resolve) => {
      this.priceChart = new Chart("priceChart", {
         type: 'line',
         data: this.priceChartData,
         options: {
           elements: {
             line: { tension: 0 },
             point:{
               radius: 0
           }
           },
           legend: {
             display: false
           },
           spanGaps: true,
           maintainAspectRatio: true,
           tooltips: {
            intersect: false,
            filter: function(tooltipItem, data) {
               const midPoint = Math.ceil((data.labels?.length ?? 0)/2) - 1;
               if(tooltipItem.index === midPoint) {
                  return tooltipItem.datasetIndex === 0;
               }
               return true;
            },
            callbacks: {
               label: (tooltipItem, data) => {
                  if (data.datasets && data.datasets.length > 0) {
                    const datasetIndex = tooltipItem.datasetIndex;
                    // @ts-ignore
                    const datasetLabel = data.datasets[datasetIndex].label;
                    // @ts-ignore
                    const value = data.datasets[datasetIndex].data[tooltipItem.index];
                     //datasetLabel
                    return `${this.currencyMillion.transform(value, this.currency)}`;
                  }
                  return "";
                }
            }
           },
           scales: {
             yAxes: [{
               stacked: false,
               ticks: {
                  beginAtZero: false,
                  callback : (value,index,array) => { return `${this.currencyMillion.transform(value, this.currency)}` }
              },
               gridLines: {
                  // display: false
               }
             }],
             xAxes: [{
               ticks: {
                  beginAtZero: true,
                  callback: (value, index, values) => {
                     if((values.length - 1) === index || ((values.length/2) - 1) === index){
                        return ''
                     }
                     return null
                  }
                }
             }]
           }
         }
       });
       resolve(this.priceChart)
   })
  }

}











// import { Component, OnInit } from '@angular/core';
// import { Chart } from 'chart.js';

// @Component({
//   selector: 'app-forecast-price-chart',
//   templateUrl: './forecast-price-chart.component.html',
//   styleUrls: ['./forecast-price-chart.component.scss']
// })
// export class ForecastPriceChartComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//     var myChart = new Chart("priceChart", {
//       type: 'line',
//       data: {
//         labels: ['', 'Last 12 months', 'Next 12 months'],
//         datasets: [
//           {
//             label: 'High',
//             data: [3, 12, 8],
//             borderColor: '#EAC4D5',
//             fill: false,
//           },
//           {
//             label: 'Median',
//             data: [3, 12, 2],
//             borderColor: '#D6EADF',
//             fill: false
//           },
//           {
//             label: 'Low',
//             data: [3, 12, 15],
//             borderColor: '#95B8D1',
//             fill: false
//           }
//         ],

//       },
//       options: {
//         elements: {
//           line: {
//             tension: 0
//           }
//         },
//         legend: {
//           display: false
//         },
//         maintainAspectRatio: true,
//         scales: {
//           yAxes: [{
//             stacked: false,
//             ticks: {
//               beginAtZero: true
//             }
//           }]
//         }
//       }
//     });
//   }

// }
