import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'cutText' })

export class CutTextPipe implements PipeTransform {
   transform(word: string) {
      if (word.length > 15) {
         return word.slice(0, 15) + '...'
      }
      return word
   }
}
