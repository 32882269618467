import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnInit,
   Output,
   Renderer2,
   TemplateRef,
   ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { PublicListsService } from 'src/app/shared/services/public-lists.service';
import { CreateListPopupComponent } from '../create-list-popup/create-list-popup.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ListDetails } from 'src/app/models/public-list/list-details.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { ListHolding } from 'src/app/models/public-list/list-holdings.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { WatchlistService } from 'src/app/shared/services/watchlist.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
   selector: 'app-list-details-header',
   templateUrl: './list-details-header.component.html',
   styleUrls: ['./list-details-header.component.scss'],
})
export class ListDetailsHeaderComponent implements OnInit {
   private destroy$ = new Subject<void>();
   @Input() isMyCreatedList!: boolean;
   @Input() listId: string | number = '';
   @Input() listInfo!: ListDetails;
   @Input() listInfoLoader: boolean = false;
   @Input() isFollowing: boolean = false;
   @Input() holdings: ListHolding[] = [];
   @Output() toggleIsFollow = new EventEmitter<any>();
   buttonLoader: boolean = false;
   isPublicListAddedToWatchlist!: boolean;
   isTruncated: boolean = true;
   readonly charactersLimit: number = 200;
   userRole: string | null = null;
   isMobile: boolean = false;
   isDropdownMenuOpen: boolean = false;

   private subscriptions: Subscription[] = [];
   maxWatchlistCount: number = 0;
   maxWatchlistItemsCount: number = 0;
   watchlistItems: number = 0;

   @ViewChild('followSuccessContent') followSuccessContent!: TemplateRef<any>;
   followSuccessModalRef!: NgbModalRef;

   constructor(
      private listService: PublicListsService,
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private router: Router,
      private observer: BreakpointObserver,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService,
      private watchlistService: WatchlistService,
   ) {}

   async ngOnInit() {
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;
            if(this.userRole !== 'ANONYMOUS') {
               this.fetchAllWatchList();
            }
         });
      this.observer
         .observe('(max-width: 991px)')
         .pipe(takeUntil(this.destroy$))
         .subscribe((result) => {
            this.isMobile = result.matches;
         });
      this.renderer.listen('window', 'click', (e: Event) => {
         if (!this.elementRef.nativeElement.contains(e.target)) {
            this.isDropdownMenuOpen = false;
         }
      });

      this.subscriptions.push(
         this.permissionStore
            .select(({ permissions }) => permissions.permissions)
            .subscribe(async (permissions) => {
               this.maxWatchlistCount = permissions.watchlist_count;
               // this.maxWatchlistItemsCount = permissions.watchlist_items_count;
            })
      );

      this.watchlistService.watchListCount$.subscribe((count: number) => {
         this.watchlistItems = count;
      });
   }

   async fetchAllWatchList() {
      let res: any = await lastValueFrom(
         this.watchlistService.fetchAllWatchlist()
      );
      if (res.status) {
         this.watchlistService.setWatchlistCount(res.data.length);
      }
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   get displayDescription(): string {
      return this.isTruncated &&
         this.listInfo.description.length > this.charactersLimit
         ? this.listInfo.description.slice(0, this.charactersLimit) + '...'
         : this.listInfo.description;
   }

   toggleTruncate() {
      this.isTruncated = !this.isTruncated;
   }

   openDeleteListPopup() {
      this.listService.openActionsModal(this.listInfo, 'deleteList');
   }

   openCopyToWatchlistPopup() {
      // if (this.watchlistItems < this.maxWatchlistCount) {
         this.listService.openActionsModal(this.listInfo, 'copyToWatchlist');
      // } else {
      //    this.accessControlService.accessDenied();
      // }
   }

   getFillColor(): string {
      if (this.isMobile) {
         return this.isFollowing ? '#FFFFFF' : '#009000';
      } else {
         return this.isFollowing ? '#FFFFFF' : '#1bad4e';
      }
   }

   toggleDropdownMenu() {
      this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
   }

   async toggleFollow() {
      try {
         this.buttonLoader = true;
         const response = await this.listService.toggleListFollow<{
            status: boolean;
            message: string;
         }>(this.listId);
         if (response.status) {
            if (response.message === 'following') {
               this.openfollowSuccessModal(this.followSuccessContent);
               this.listInfo.followers_count =
                  this.listInfo.followers_count + 1;
            } else {
               this.listInfo.followers_count =
                  this.listInfo.followers_count - 1;
            }
            this.toggleIsFollow.emit();
         }
      } catch (error) {
         console.log(error);
      } finally {
         this.buttonLoader = false;
      }
   }

   openCreateListPopup(listData: ListDetails) {
      const modalRef = this.modalService.open(CreateListPopupComponent, {
         size: 'lg',
      });
      modalRef.componentInstance.listDetails = listData;
      modalRef.componentInstance.listHoldings = this.holdings.map(
         (holding: ListHolding) => {
            return {
               id: holding.id,
               name: holding.symbol,
               type: holding.type,
            };
         }
      );
   }

   navigateToLoginScreen() {
      this.router.navigate(['/authentication/login']);
   }

   openfollowSuccessModal(modal: any): void {
      this.followSuccessModalRef = this.modalService.open(modal, {
         centered: true,
      });
   }

   closefollowSuccessModal() {
      if (this.followSuccessModalRef) {
         this.followSuccessModalRef.dismiss();
      }
   }
}
