import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';

@Component({
   selector: 'app-unlink-confirmation',
   templateUrl: './unlink-confirmation.component.html',
   styleUrls: ['./unlink-confirmation.component.scss'],
})
export class UnlinkConfirmationComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   @Input() selectedAccount!: PortfolioAccount;
   @Input() closeUnlinkConfirmationModal!: Function;
   @Input() unlinkSuccess!: Function;
   unlinkLoader: boolean = false;

   portfolioAccounts: PortfolioAccount[] = [];
   constructor(
      private router: Router,
      public portfolioService: PortfolioService,

      private route: ActivatedRoute,
      private analyticsUtils: AnalyticsUtils,
      private toaster: ToastrService
   ) {}

   ngOnInit(): void {}

   private async getPortfolioList() {
      this.portfolioService
         .getPortfoliosList()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.portfolioAccounts = response;
            },
            error: (error) => {
               console.error(error);
            },
         });
   }

   async unlinkBrokerage(id: string,accountId: string) {
      this.unlinkLoader = true;
      try {
         const response = await this.portfolioService.unlinkBrokerage(id,accountId);
         if (response.status && response.status_code === 'SUCCESS') {
            this.analyticsUtils.portfolio_unlink_brokerage();
            await lastValueFrom(this.portfolioService.refetchPortfoliosList());
            await lastValueFrom(this.portfolioService.refetchAccountCounts());
            await this.getPortfolioList();
            if (this.portfolioAccounts.length === 0) {
               const currentUrl = this.router.url;
               this.router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                     this.router.navigate([currentUrl]);
                  });
            }
            this.unlinkLoader = false;
            this.toaster.success(response.message);
            this.unlinkSuccess();
            // this.router.navigateByUrl('/cabinet/portfolio-dashboard', { skipLocationChange: true }).then(() => {
            //    this.router.navigate([this.router.url]);
            // });
            this.closeUnlinkConfirmationModal();
         } else {
            this.unlinkLoader = false;
            this.toaster.error(response.message);
            this.closeUnlinkConfirmationModal();
         }
      } catch (error) {
         this.unlinkLoader = false;
         this.toaster.error('Something went wrong');
         this.closeUnlinkConfirmationModal();
      }
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }
}
