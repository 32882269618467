<main class="content-padding">
    <section class="container mb-5">
        <div class="thankyou">
            <h1>Thank You</h1>
            <p>Thank you for using our web form. We acknowledge receipt of your claim.</p>
            <p>Pending review to ensure that you have provided sufficient information, Musaffa may contact you for
                further handling of your claim. Please be assured that Musaffa's Legal Team will take appropriate action
                concerning your report.</p>
            <p>
                Best Regards,<br />
                Copyright Team<br />
                Musaffa – IP & Licensing
            </p>
        </div>
    </section>
</main>