<article class="bg-white d-flex align-items-start justify-content-between reconnect-card">
   <div class="d-flex align-items-start gap-2 gap-lg-3">
      <svg class="flex-shrink-0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <mask id="mask0_10325_2056" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="100%" height="100%" fill="#D9D9D9"/>
         </mask>
         <g mask="url(#mask0_10325_2056)">
            <path d="M17 13V10C17 9.71667 17.0958 9.47917 17.2875 9.2875C17.4792 9.09583 17.7167 9 18 9C18.2833 9 18.5208 9.09583 18.7125 9.2875C18.9042 9.47917 19 9.71667 19 10V13C19 13.2833 18.9042 13.5208 18.7125 13.7125C18.5208 13.9042 18.2833 14 18 14C17.7167 14 17.4792 13.9042 17.2875 13.7125C17.0958 13.5208 17 13.2833 17 13ZM3.025 12C2.65833 11.4167 2.39583 10.8375 2.2375 10.2625C2.07917 9.6875 2 9.09167 2 8.475C2 6.90833 2.525 5.60417 3.575 4.5625C4.625 3.52083 5.93333 3 7.5 3C8.35 3 9.17083 3.18333 9.9625 3.55C10.7542 3.91667 11.4333 4.43333 12 5.1C12.5667 4.43333 13.2417 3.91667 14.025 3.55C14.8083 3.18333 15.6333 3 16.5 3C18.0667 3 19.375 3.52917 20.425 4.5875C21.475 5.64583 22 6.95833 22 8.525C21.4667 8.04167 20.8583 7.66667 20.175 7.4C19.4917 7.13333 18.7667 7 18 7C16.5 7 15.2 7.47917 14.1 8.4375C13 9.39583 12.3333 10.5833 12.1 12H10.55L8.85 9.45C8.75 9.3 8.62917 9.1875 8.4875 9.1125C8.34583 9.0375 8.19167 9 8.025 9C7.85833 9 7.7 9.0375 7.55 9.1125C7.4 9.1875 7.275 9.3 7.175 9.45L5.45 12H3.025ZM8.825 18.15C7.80833 17.2333 6.9625 16.4458 6.2875 15.7875C5.6125 15.1292 5.03333 14.5333 4.55 14H6C6.16667 14 6.325 13.9583 6.475 13.875C6.625 13.7917 6.74167 13.6833 6.825 13.55L8 11.8L9.175 13.55C9.25833 13.6833 9.375 13.7917 9.525 13.875C9.675 13.9583 9.83333 14 10 14H12.1C12.25 14.9167 12.5875 15.7417 13.1125 16.475C13.6375 17.2083 14.2917 17.7917 15.075 18.225L13.325 19.8C13.1417 19.9667 12.9333 20.0917 12.7 20.175C12.4667 20.2583 12.2333 20.3 12 20.3C11.7667 20.3 11.5333 20.2583 11.3 20.175C11.0667 20.0917 10.8583 19.9667 10.675 19.8L8.825 18.15ZM18 17C17.7167 17 17.4792 16.9042 17.2875 16.7125C17.0958 16.5208 17 16.2833 17 16C17 15.7167 17.0958 15.4792 17.2875 15.2875C17.4792 15.0958 17.7167 15 18 15C18.2833 15 18.5208 15.0958 18.7125 15.2875C18.9042 15.4792 19 15.7167 19 16C19 16.2833 18.9042 16.5208 18.7125 16.7125C18.5208 16.9042 18.2833 17 18 17Z" fill="#F25022"/>
         </g>
      </svg>
      <div class="d-flex flex-column gap-1">
         <h6 class="mb-0 reconnect-heading">Reconnect Portfolio:</h6>
         <p class="mb-0 reconnect-para">
            Reconnection with the brokerage required to sync the latest portfolio details.
         </p>
      </div>
   </div>
   <div class="flex-shrink-0">
      <app-reconnect-brokerage-btn [brokerageConnectionStatus]="brokerageConnectionStatus" [brokerageId]="brokerageId"></app-reconnect-brokerage-btn>
   </div>
</article>
