import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class AppNotificationsService {
   private notificationData = new BehaviorSubject<boolean>(false);
   currentNotifications = this.notificationData.asObservable();

   constructor(private http: HttpClient) {}

   private api = environment.apiUrl;

   public async getNotifications() {
      return await lastValueFrom(
         this.http.get<any>(this.api + '/api/user/notifications')
      );
   }

   public async markNotificationRead(ids: Array<number>) {
      return await lastValueFrom(
         this.http.post<any>(
            this.api + '/api/user/notifications/mark-as-read',
            {
               ids: ids,
            }
         )
      );
   }

   updateNotificationRead(data: boolean) {
      this.notificationData.next(data);
   }

   async fetchUserNotofications<T>(): Promise<T> {
      return await lastValueFrom(
         this.http.get<T>(this.api + '/api/user/notifications')
      );
   }

   async markUserNotificationAsRead<T>(notificationIds: number[]): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(this.api + '/api/user/notifications/mark-as-read', {
            ids: notificationIds,
         })
      );
   }

   async markAllNotificationAsRead<T>(): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(
            this.api + '/api/user/notifications/mark-all-as-read',
            {}
         )
      );
   }
}
