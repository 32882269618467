import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { LogOutFreeServices } from '../../services/log-out.service ';
import { SubscriptionService } from '../../services/subscription.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
      

@Component({
   selector: 'app-log-out-modale',
   templateUrl: './log-out-modale.component.html',
   styleUrls: ['./log-out-modale.component.scss'],
})
export class LogOutModaleDayComponent implements OnInit {
   public subscriptions: Subscription[] = [];

   public showLog: boolean = false;

   constructor(private subscriptionService: SubscriptionService, private auth: AuthService, private logout: LogOutFreeServices,private analyticsUtils:AnalyticsUtils, private router: Router,    public authFire: AngularFireAuth ) {}

   ngOnInit(): void {
      let subscription = this.logout.LogOutFreeServices.subscribe(
         (status: boolean) => {
            this.showLog = status;
         }
      );

      this.subscriptions.push(subscription);
      // this.logoutGoogle();
   }

   hideLog() {
      this.logout.hide();
   }

   async goToLogin() {
      await this.logoutGoogle()
      this.analyticsUtils.User_Logged_Out();
      this.hideLog();
      var base_url = window.location.origin;
      window.location.href = base_url
   }

   async logoutGoogle() {
      const user = await this.authFire.currentUser
      this.auth.logout();
      this.subscriptionService.clearSubscriptionInfo();
      user?.delete();
      // await this.authFire.signOut();
      localStorage.clear()
    }
}
