<div class="disclaimer-wrapper" *ngIf="isManualSet">
   <div class="disclaimer-content">
      <h3>Disclaimer</h3>
      <p>The following ETF has undergone the Shariah screening process and has been approved by the Shariah board of the issuing body or company. The compliance status of this ETF is based on the standards and methodologies adopted by the respective issuing entity; therefore, Musaffa does not issue an independent Shariah Compliance Report for these ETFs. Investors are encouraged to review the Shariah certification and screening criteria provided by the issuing body for further clarification</p>
   </div>
</div>

<ng-container *ngIf="!isManualSet">
<div class="loader-wrapper border br-10 p-3 p-lg-4" *ngIf="isLoading">
   <div class="top gap-2 gap-lg-4" >
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
   </div>
   <div class="middle gap-3 mx-auto gap-lg-5">
      <span class="d-block skeleton-loader rounded-circle cr p-4 w-100"></span>
      <div class="d-flex flex-column align-items-center gap-3 gap-lg-4">
         <span class="d-block skeleton-loader rounded-3 pt-2 labl pb-3 py-lg-4 w-100"></span>
         <span class="d-block skeleton-loader rounded-3 pt-2 labl pb-3 py-lg-4 w-100"></span>
         <span class="d-block skeleton-loader rounded-3 pt-2 labl pb-3 py-lg-4 w-100"></span>
      </div>
   </div>
   <div class="bottom d-grid gap-2 gap-lg-4">
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
      <span class="d-block skeleton-loader rounded-3 w-100"></span>
   </div>
</div>
<app-limit-reached-card *ngIf="isLimitReached && !isLoading" [permissionKey]="'etf_compliance_report'"></app-limit-reached-card>
<div class="pb-5" *ngIf="!isLimitReached && !isLoading">
   <!-- <h1>
      {{ data.name }} <span class="h4 text-muted">({{ data.symbol }})</span>
   </h1> -->
   <!-- <hr class="style-two mb-5 d-none d-md-block" /> -->
   <!-- <section class="company-name-section">
      <div class="container">
         <h2 class="company-title mb-2 mb-md-4 text-center text-md-start">
            {{ etfProfile?.name }} ({{ etfProfile?.symbol }})
            <span
               class="d-block text-center d-md-inline mt-2 mt-md-0"
               [ngClass]="{
                  'halal-status': data?.actualComplianceStatus == 'COMPLIANT',
                  'not-halal-status':
                     data?.actualComplianceStatus == 'NON_COMPLIANT',
                  'doubtful-status':
                     data?.actualComplianceStatus == 'QUESTIONABLE'
               }"
            >
               {{ getStatusDisplayValue() }}
            </span>
         </h2>
         <div
            class="rating-container d-md-flex"
            *ngIf="data?.actualComplianceStatus == 'COMPLIANT'"
         >
            <span class="d-block text-center text-md-start"
               >Musaffa Halal Rating</span
            >
            <div class="status__svg">
               <div
                  class="halalstoks"
                  *ngIf="data?.actualComplianceStatus == 'COMPLIANT'"
               >
                  <div class="status__starts">
                     <div
                        class="stars"
                        [ngClass]="{ active: data?.ranking >= 1 }"
                     ></div>
                     <div
                        class="stars"
                        [ngClass]="{ active: data?.ranking >= 2 }"
                     ></div>
                     <div
                        class="stars"
                        [ngClass]="{ active: data?.ranking >= 3 }"
                     ></div>
                     <div
                        class="stars"
                        [ngClass]="{ active: data?.ranking >= 4 }"
                     ></div>
                     <div
                        class="stars"
                        [ngClass]="{ active: data?.ranking >= 5 }"
                     ></div>
                  </div>
               </div>
            </div>
         </div>
         <div class="d-lg-flex align-items-center cba-comment-text my-3 p-3" *ngIf="canShowCbaComment()">
            <div class="icon-wrapper d-none d-lg-flex align-items-center justify-content-center me-3">
               <i class="bi bi-exclamation-triangle"></i>
            </div>
            <p class="text-center text-md-start mb-0 comment-text">
               One of the holdings of this ETF fails from Core Business Activity (CBA) screening. The core business activity of this holding is strictly
               not allowed according to Shariah rules. Thus, this ETF is categorized as Not Halal. Please, check the Detailed report section for
               Core Business Activity (CBA) screening results.
            </p>
         </div>
         <p class="mb-3 based_on mt-3">
            Based on AAOIFI Shariah stock screening methodology
         </p>

         <div class="d-flex flex-column flex-md-row">
            <div class="d-flex align-items-center last-updated mb-2 mb-md-0">
               <i class="fa-regular fa-clock mr-2"></i>
               <span class="ms-2"> Last updated: {{ getLastUpdated() }} </span>
            </div>
            <div class="currency__stock ms-md-3 d-flex align-items-center">
               <span class="material-symbols-outlined me-2 report-bill">
                  payments
               </span>
               <span>Currency in {{ currency }}</span>
            </div>
         </div>
      </div>
   </section> -->

   <div class="modal-wrapper">
      <!-- <div>
         <app-report-compliance-status-card
            [complianceStatus]="data?.actualComplianceStatus"
            [complianceRanking]="data?.ranking"
            [quarterText]="getQuarterText()"
            [lastUpdated]="getLastUpdated()"
            [statusValue]="getStatusDisplayValue()"
            [stockType]="'etf'"
            [cbaComment]="canShowCbaComment()"
            [isSample]="isSample"
            [page]="'etf'"
         >
         </app-report-compliance-status-card>
      </div> -->

      <div class="charts-section border p-3 p-lg-4">
         <!-- <div class="container" *ngIf="isSample && isMobile">
            <div class="row">
               <div class="col col-4 text-center">
                  <app-sample-bubble bubbleContent="This is a Business Activity" tooltipPosition="right"></app-sample-bubble>
               </div>
               <div class="col col-4 text-center">
                  <app-sample-bubble bubbleContent="This is a Interest-bearing securities and assets" tooltipPosition="bottom"></app-sample-bubble>
               </div>
               <div class="col col-4 text-center">
                  <app-sample-bubble bubbleContent="This is a Interest-bearing deb" tooltipPosition="left"></app-sample-bubble>
               </div>
            </div>
         </div> -->
         <p class="text-center text-md-start mb-3 mb-lg-4 p-2 p-lg-3 rounded-3 comment-para" *ngIf="canShowCbaComment()">
            One of the holdings of this ETF fails from Core Business Activity (CBA) screening. The core business activity of this holding is strictly
            not allowed according to Shariah rules. Thus, this ETF is categorized as Not Halal. Please, check the Detailed report section for
            Core Business Activity (CBA) screening results.
         </p>

         <div class="container" *ngIf="isMobile">
            <div class="row">
               <div class="col px-0">
                  <div class="mobile__tabs-main">
                     <div class="tabs">
                        <button class="btn left" [ngClass]="{ active: active == 1, pas: data?.revenueBreakdownStatus == 'PASS', fail: data?.revenueBreakdownStatus == 'FAIL' }" type="button" (click)="setActive(1)" *ngIf="isBusinessActivityAvailable()" >
                           <span>Revenue</span>
                           <i class="bi bi-check-circle" *ngIf="data?.revenueBreakdownStatus == 'PASS'" ></i>
                           <i class="bi bi-x-circle" *ngIf="data?.revenueBreakdownStatus == 'FAIL'" ></i>
                        </button>
                        <button class="btn center" [ngClass]="{ active: active == 2, pas: data?.securitiesAndAssetsStatus == 'PASS', fail: data?.securitiesAndAssetsStatus == 'FAIL' }" type="button" (click)="setActive(2)" *ngIf="isSecuritiesAvailable()" >
                           <span>Securities</span>
                           <i class="bi bi-check-circle" *ngIf="data?.securitiesAndAssetsStatus == 'PASS'" ></i>
                           <i class="bi bi-x-circle" *ngIf="data?.securitiesAndAssetsStatus == 'FAIL'" ></i>
                        </button>
                        <button class="btn right" [ngClass]="{ active: active == 3, pas: data?.debtStatus == 'PASS', fail: data?.debtStatus == 'FAIL' }" type="button" (click)="setActive(3)" *ngIf="isDebtAvailable()" >
                           <span>Debt</span>
                           <i class="bi bi-check-circle" *ngIf="data?.debtStatus == 'PASS'" ></i>
                           <i class="bi bi-x-circle" *ngIf="data?.debtStatus == 'FAIL'" ></i>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <section class="tabs-section">
            <div class="">
               <ul *ngIf="!isMobile" ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="tabChange()" class="nav nav-pills nav-fill nav-tab-grid" id="pills-tab" role="tablist" >
                  <li class="grid-item-1" *ngIf="isBusinessActivityAvailable()">
                     <h6 class="text-start screener-section-title"> Business Screening </h6>
                     <hr class="mb-0" />
                  </li>
                  <li [ngbNavItem]="1" class="grid-item-2" role="presentation" *ngIf="isBusinessActivityAvailable()" >
                     <!-- <div class="text-center" *ngIf="isSample">
                     <app-sample-bubble bubbleContent="This is a Business Activity" tooltipPosition="right"></app-sample-bubble>
                  </div> -->
                     <a ngbNavLink class="w-100 text-start tab-btn d-flex flex-column p-3" id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" [ngClass]="{ 'grid-green-btn': data?.revenueBreakdownStatus == 'PASS', 'grid-red-btn': data?.revenueBreakdownStatus == 'FAIL' }" >
                        <span class="triangle tri-1"></span>
                        <span class="w-fit rounded-pill text-white px-3 py-1 tabs-badge mb-2" >{{ data?.revenueBreakdownStatus }}</span >
                        <span class="">Business Activity</span>
                        <i class="fa-solid fa-briefcase tab-icon"></i>
                     </a>
                  </li>
                  <li class="grid-item-3 align-self-end" *ngIf="isSecuritiesAvailable() || isDebtAvailable()" >
                     <h6 class="text-start screener-section-title">
                        Financial Screening
                     </h6>
                     <hr class="mb-0" />
                  </li>
                  <li [ngbNavItem]="2" class="grid-item-4" role="presentation" *ngIf="isSecuritiesAvailable()" >
                     <!-- <div class="text-center" *ngIf="isSample">
                     <app-sample-bubble bubbleContent="This is a Interest-bearing securities and assets" tooltipPosition="bottom"></app-sample-bubble>
                  </div> -->
                     <a [ngClass]="{ 'grid-green-btn': data?.securitiesAndAssetsStatus == 'PASS', 'grid-red-btn': data?.securitiesAndAssetsStatus == 'FAIL' }" ngbNavLink class="w-100 text-start tab-btn d-flex flex-column p-3" id="pills-profile-tab" >
                        <span class="triangle tri-2"></span>
                        <span class="w-fit rounded-pill text-white px-3 py-1 tabs-badge mb-2" >{{ data?.securitiesAndAssetsStatus }}</span >
                        <span> Interest-bearing <br /> securities and assets </span>
                        <i class="fa-solid fa-arrow-trend-up tab-icon icon-green" ></i>
                     </a>
                  </li>
                  <li [ngbNavItem]="3" class="grid-item-5" role="presentation" *ngIf="isDebtAvailable()" >
                     <!-- <div class="text-center" *ngIf="isSample">
                     <app-sample-bubble bubbleContent="This is a Interest-bearing deb" tooltipPosition="left"></app-sample-bubble>
                  </div> -->
                     <a ngbNavLink class="w-100 text-start tab-btn d-flex flex-column triangle tri-3 p-3" id="pills-contact-tab" data-toggle="pill" data-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" [ngClass]="{ 'grid-green-btn': data?.debtStatus == 'PASS', 'grid-red-btn': data?.debtStatus == 'FAIL' }" >
                        <span class="triangle tri-3"></span>
                        <span class="w-fit rounded-pill text-white px-3 py-1 tabs-badge mb-2" >{{ data?.debtStatus }}</span >
                        Interest-bearing debt
                        <i class="fa-solid fa-arrow-trend-up tab-icon icon-red" ></i>
                     </a>
                  </li>
               </ul>

               <div class="tab-content mt-4" id="pills-tabContent">
                  <div class="border-danger pb-4" id="pills-home" *ngIf="active == 1" >
                     <div class="row">
                        <div class="col-12 col-lg-9">
                           <h3 class="d-flex align-items-center mb-0">
                              <span class="section-title" >Business Activity</span >
                              <span class="ms-2 rounded-pill ms-3 status-pill-large" [ngClass]="{ 'status-pill-large-pass': data?.revenueBreakdownStatus == 'PASS', 'status-pill-large-fail': data?.revenueBreakdownStatus == 'FAIL' }" >{{ data?.revenueBreakdownStatus }}</span >
                           </h3>
                        </div>
                     </div>

                     <div class="">
                        <div class="row mt-5 chart-content-wrapper justify-content-center mx-auto" >
                           <div class="col-7 col-md-6 col-lg-8 px-0 px-md-5 px-lg-0 align-self-center mx-lg-4 graph-wrapper">
                              <div class="revenue-graph">
                                 <app-revenue-breakdown-chart [screeningInfo]="revenueChartData" ></app-revenue-breakdown-chart>
                              </div>
                           </div>
                           <div class="col-5 col-md-6 col-lg-4 distribution-container ps-sm-4 ps-lg-5 d-flex flex-column gap-3 gap-lg-4 align-self-center" >
                              <div class="d-flex align-items-center" >
                                 <div class="distribution-elem halal"></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title">Halal</span>
                                    <span class="percent"> {{data?.['totalHalalRatio'] | number: "1.2-2" }}% </span>
                                 </div>
                              </div>
                              <div class="d-flex align-items-center" >
                                 <div class="distribution-elem doubtful"></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title">Doubtful</span>
                                    <span class="percent"> {{data?.['totalDoubtfulRatio'] | number: "1.2-2" }}% </span>
                                 </div>
                              </div>
                              <div class="d-flex align-items-center">
                                 <div class="distribution-elem not-halal"></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title">Not Halal</span>
                                    <span class="percent"> {{data?.['totalNotHalalRatio'] | number: "1.2-2" }}% </span>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12">
                              <div class="screening-desc my-4">
                                 The Aggregate value of Non-Halal and Doubtful sources from all holdings of the ETF <b>should not exceed 5%</b> of an ETF's aggregate Revenue.
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="row">
                        <div class="col-12 text-end">
                           <label class="toggle-switch">
                              <input type="checkbox" [(ngModel)]="businessObj.showPercent" />
                              <span class="toggle-slider bg-light pill-slider d-flex align-items-center justify-content-between" >
                                 <span class="toggle-symbols d-flex align-items-center justify-content-start" ><i class="material-symbols-outlined toggle-bill" >payments</i ></span >
                                 <span class="toggle-symbols d-flex align-items-center justify-content-end" >%</span >
                              </span>
                           </label>
                        </div>
                     </div>
                     <div class="mt-4">
                        <ngb-accordion #acc="ngbAccordion" class="calculation-accordion bg-primary" (shown)="onPanelShown($event)" (hidden)="onPanelHidden($event)" >
                           <ngb-panel [cardClass]=" getCardClass( panelStatus.businessPanel, data?.revenueBreakdownStatus ) " class="calculation-panel-container" id="business-panel" >
                              <ng-template ngbPanelHeader>
                                 <div class="calculation-panel">
                                    <div class="p-0 bg-transparent" id="headingOne" >
                                       <h2 class="mb-0">
                                          <button ngbPanelToggle class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3" type="button" >
                                             <span class="accordion-btn-title flex-grow-1 text-start" >Calculation</span >
                                             <i class="fa-solid fa-chevron-up accordion-icon" ></i>
                                          </button>
                                       </h2>
                                    </div>
                                 </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                 <div class="row">
                                    <div class="col-md-6">
                                       <div class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mt-2" >
                                          <p class="mb-0 calculation-line-item-title" >
                                             Aggregate Halal Sales & Income of
                                             ETF
                                          </p>
                                          <span class="fw-500 calc-revenue calculation-line-item-val" >
                                             {{ getBusinessValueOrPercent(data?.['totalHalalRevenue'], data?.['totalHalalRatio']) }}
                                          </span>
                                       </div>

                                       <div class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mt-2" >
                                          <p class="mb-0 calculation-line-item-title" >
                                             Aggregate Doubtful Sales & Income of ETF
                                          </p>
                                          <span class="fw-500 calc-revenue calculation-line-item-val" >
                                             {{ getBusinessValueOrPercent(data?.['totalDoubtfulRevenue'], data?.['totalDoubtfulRatio']) }}
                                          </span>
                                       </div>

                                       <div class="d-flex calculation-line-item-container p-2 rounded align-items-center justify-content-between mt-2" >
                                          <p class="mb-0 calculation-line-item-title" >
                                             Aggregate Non Halal Sales & Income of ETF
                                          </p>
                                          <span class="fw-500 calc-revenue calculation-line-item-val" >
                                             {{ getBusinessValueOrPercent(data?.['totalNotHalalRevenue'], data?.['totalNotHalalRatio']) }}
                                          </span>
                                       </div>

                                       <div class="d-flex p-2 mt-2 rounded align-items-center justify-content-between calculation-line-item-container-total" >
                                          <p class="mb-0 calculation-line-item-title" >
                                             Aggregate Revenue of ETF
                                          </p>
                                          <span class="fw-500 calc-revenue calculation-line-item-val" >
                                             {{ getTotalBusinessAct() }}
                                          </span>
                                       </div>
                                    </div>
                                    <div class="col-md-6 mt-3 mt-md-0">
                                       <div class="calc-percent text-center py-4" >
                                          <span >Not Halal Business Activity Percentage of ETF = </span>
                                          <br /> <br />
                                          <span >( Aggregate Not Halal Sales & Income of ETF + Aggregate Doubtful Sales & Income of ETF )</span >
                                          <br />
                                          <span class="border-t d-block mx-3 mx-lg-5" >( Aggregate Revenue of ETF )</span >
                                          <br /> <br />
                                          <div class="d-flex align-items-center justify-content-center" >
                                             <div class="d-flex flex-column align-items-center" >
                                                <!-- <span class=""> {{ getConvertedAmount(data?.['totalNotHalalRevenue']) + getConvertedAmount(data?.['totalDoubtfulRevenue']) | screaningMillionPipe}}</span> -->
                                                <span class=""> {{ data?.impermissibleAmount | screaningMillionPipe }}</span >
                                                <!-- <span class="border-t"> {{ getConvertedAmount(data?.['etfTotalRevenue']) | screaningMillionPipe }}</span> -->
                                                <span class="border-t"> {{ data?.['etfTotalRevenue'] | screaningMillionPipe }}</span >
                                             </div>
                                             <div class="mx-2">=</div>
                                             <div>
                                                {{ data?.impermissibleRatio | number : "1.2-2" }}%
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </ng-template>
                           </ngb-panel>

                           <ngb-panel [cardClass]=" getCardClass( panelStatus.detailedReportPanel, data?.revenueBreakdownStatus ) " class="calculation-panel-container detailed-report-panel mt-3 mt-lg-4" id="detailed-report-panel" >
                              <ng-template ngbPanelHeader>
                                 <div class="calculation-panel">
                                    <div class="p-0 bg-transparent" id="headingTwo" >
                                       <h2 class="mb-0">
                                          <button ngbPanelToggle class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3" type="button" >
                                             <span class="accordion-btn-title flex-grow-1 text-start" >Holdings Status</span >
                                             <i class="fa-solid fa-chevron-up accordion-icon" ></i>
                                          </button>
                                       </h2>
                                       <p *ngIf=" data?.actualComplianceStatus === 'COMPLIANT' " [ngClass]=" acc.isExpanded( 'detailed-report-panel' ) ? 'd-block' : 'd-none' " class="halal-container p-3" >
                                          <small> {{ cbaStatusDisclaimer }}</small >
                                       </p>
                                    </div>
                                 </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                 <div class="row report-body">
                                    <app-etf-screening-detailed-report [symbol]="etfProfile?.symbol" >
                                    </app-etf-screening-detailed-report>
                                 </div>
                              </ng-template>
                           </ngb-panel>

                           <!-- <ngb-panel
                             [cardClass]="getCardClass(panelStatus.businessPanel2,data?.revenueBreakdownStatus) +' mt-5'"
                             class="calculation-panel-container" id="business-panel2"
                          >
                             <ng-template ngbPanelHeader>
                                <div class="calculation-panel">
                                   <div
                                      class="p-0 bg-transparent"
                                      id="headingOne"
                                   >
                                      <h2 class="mb-0">
                                         <button
                                            ngbPanelToggle
                                            class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3"
                                            type="button"
                                         >
                                            <span
                                               class="accordion-btn-title flex-grow-1 text-start"
                                               >Detailed Report</span
                                            >
                                            <i class="fa-solid fa-chevron-up accordion-icon"></i>
                                         </button>
                                      </h2>
                                   </div>
                                </div>
                             </ng-template>
                             <ng-template ngbPanelContent>
                                <div class="row">

                                   <div class="col-12 col-lg-6">
                                      <h3 class="gross-heading py-2 py-lg-3">Gross Sales</h3>
                                      <div class="mb-4" *ngIf="data?.['revenueBreakdownJson.halalAmountInOnes'] && data?.['revenueBreakdownJson.halalPercentage']">
                                         <app-income-breakdown
                                            class="mb-4"
                                            [showPercent]="businessObj.showPercent"
                                            [type]="'halal'"
                                            [data]="data"
                                            [rate]="rate"
                                            [usdRate]="usdRate"
                                            [currency]="etfProfile?.currency"
                                            title="Halal Revenue"
                                            [items]="grossIncomeObj.halal.items"
                                            [overallAmount]="data?.['revenueBreakdownJson.halalAmountInOnes']"
                                            [overallPercent]="data?.['revenueBreakdownJson.halalPercentage']"
                                         ></app-income-breakdown>
                                      </div>
                                      <div class="mb-4" *ngIf="data?.['revenueBreakdownJson.questionableAmountInOnes'] && data?.['revenueBreakdownJson.questionablePercentage']">
                                         <app-income-breakdown
                                            class="mb-4"
                                            [showPercent]="businessObj.showPercent"
                                            type="doubtful"
                                            [data]="data"
                                            [rate]="rate"
                                            [usdRate]="usdRate"
                                            [currency]="etfProfile?.currency"
                                            title="Doubtful Revenue"
                                            [items]="grossIncomeObj.doubtful.items"
                                            [overallAmount]="data?.['revenueBreakdownJson.questionableAmountInOnes']"
                                            [overallPercent]="data?.['revenueBreakdownJson.questionablePercentage']"
                                         ></app-income-breakdown>
                                      </div>
                                      <div class="mb-4" *ngIf="data?.['revenueBreakdownJson.notHalalAmountInOnes'] && data?.['revenueBreakdownJson.notHalalPercentage']">
                                         <app-income-breakdown
                                            class="mb-4"
                                            [showPercent]="businessObj.showPercent"
                                            type="not_halal"
                                            [data]="data"
                                            [rate]="rate"
                                            [usdRate]="usdRate"
                                            [currency]="etfProfile?.currency"
                                            title="Not Halal Revenue"
                                            [items]="grossIncomeObj.notHalal.items"
                                            [overallAmount]="data?.['revenueBreakdownJson.notHalalAmountInOnes']"
                                            [overallPercent]="data?.['revenueBreakdownJson.notHalalPercentage']"
                                         ></app-income-breakdown>
                                      </div>

                                   </div>
                                   <div class="col-12 col-lg-6" *ngIf="data?.['interestIncomeJson.halalAmountInOnes'] && data?.['interestIncomeJson.halalPercentage'] || data?.['interestIncomeJson.questionableAmountInOnes'] && data?.['interestIncomeJson.questionablePercentage'] || data?.['interestIncomeJson.notHalalAmountInOnes'] && data?.['interestIncomeJson.notHalalPercentage']">
                                      <h3 class="gross-heading py-2 py-lg-3">Other Income</h3>
                                      <div class="mb-4" *ngIf="data?.['interestIncomeJson.halalAmountInOnes'] && data?.['interestIncomeJson.halalPercentage']">
                                         <app-income-breakdown
                                            class="mb-4"
                                            [showPercent]="businessObj.showPercent"
                                            [type]="'halal'"
                                            [data]="data"
                                            [rate]="rate"
                                            [usdRate]="usdRate"
                                            [currency]="etfProfile?.currency"
                                            title="Halal Income"
                                            [items]="otherIncomeObj.halal.items"
                                            [overallAmount]="data?.['interestIncomeJson.halalAmountInOnes']"
                                            [overallPercent]="data?.['interestIncomeJson.halalPercentage']"
                                         ></app-income-breakdown>
                                      </div>
                                      <div class="mb-4" *ngIf="data?.['interestIncomeJson.questionableAmountInOnes'] && data?.['interestIncomeJson.questionablePercentage']">
                                         <app-income-breakdown
                                            class="mb-4"
                                            [showPercent]="businessObj.showPercent"
                                            type="doubtful"
                                            [data]="data"
                                            [rate]="rate"
                                            [usdRate]="usdRate"
                                            [currency]="etfProfile?.currency"
                                            title="Doubtful Income"
                                            [items]="otherIncomeObj.doubtful.items"
                                            [overallAmount]="data?.['interestIncomeJson.questionableAmountInOnes']"
                                            [overallPercent]="data?.['interestIncomeJson.questionablePercentage']"
                                         ></app-income-breakdown>
                                      </div>
                                      <div class="mb-4" *ngIf="data?.['interestIncomeJson.notHalalAmountInOnes'] && data?.['interestIncomeJson.notHalalPercentage']">
                                         <app-income-breakdown
                                            class="mb-4"
                                            [showPercent]="businessObj.showPercent"
                                            type="not_halal"
                                            [data]="data"
                                            [rate]="rate"
                                            [usdRate]="usdRate"
                                            [currency]="etfProfile?.currency"
                                            title="Not Halal Income"
                                            [items]="otherIncomeObj.notHalal.items"
                                            [overallAmount]="data?.['interestIncomeJson.notHalalAmountInOnes']"
                                            [overallPercent]="data?.['interestIncomeJson.notHalalPercentage']"
                                         ></app-income-breakdown>
                                      </div>
                                   </div>
                                </div>
                             </ng-template>
                          </ngb-panel> -->
                        </ngb-accordion>
                     </div>
                  </div>

                  <div class="border-success pb-5" id="pills-profile" *ngIf="active == 2" >
                     <div class="row">
                        <div class="col-12 col-lg-9">
                           <h3 class="d-flex align-items-center mb-0">
                              <span class="section-title" >Interest-bearing securities and assets</span >
                              <span class="ms-2 rounded-pill ms-3 status-pill-large" [ngClass]="{ 'status-pill-large-pass': data?.securitiesAndAssetsStatus == 'PASS', 'status-pill-large-fail': data?.securitiesAndAssetsStatus == 'FAIL' }" >{{ data?.securitiesAndAssetsStatus }}</span >
                           </h3>
                        </div>
                     </div>

                     <div>
                        <div class="row mt-5 chart-content-wrapper mx-auto">
                           <div class="row mx-auto">
                              <div class="col-12 col-sm-6 col-md-6 px-0 pie-graph">
                                 <div class="revenue-graph mt-4">
                                    <app-debt-chart [gauge]="data?.totalIbSecAssetRatio" [status]=" data?.securitiesAndAssetsStatus " ></app-debt-chart>
                                 </div>
                              </div>
                              <div class="col-12 col-sm-6 col-md-6 distribution-container d-flex flex-column gap-3 gap-lg-4 align-self-lg-end pb-lg-4" >
                                 <div class="d-flex align-items-center" >
                                    <div class="distribution-elem compliant" ></div>
                                    <div class="d-flex flex-column ms-2">
                                       <span class="legend-title" >Shariah-compliant</span >
                                       <span class="percent light" >Less than 30%</span >
                                    </div>
                                 </div>
                                 <div class="d-flex align-items-center">
                                    <div class="distribution-elem non-compliant" ></div>
                                    <div class="d-flex flex-column ms-2">
                                       <span class="legend-title" >Non-shariah compliant</span >
                                       <span class="percent light" >Greater than 30%</span >
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div class="screening-desc my-4">
                                 The aggregate amount of interest-bearing securities and assets of all holdings of ETF <b >should not exceed 30% of the Trailing 36-month average market capitalization</b > of ETF to be Shariah compliant.
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-12 text-end">
                              <label class="toggle-switch">
                                 <input type="checkbox" [(ngModel)]="securitiesObj.showPercent" />
                                 <span class="toggle-slider bg-light pill-slider d-flex align-items-center justify-content-between" >
                                    <span class="toggle-symbols d-flex align-items-center justify-content-start" ><i class="material-symbols-outlined toggle-bill" >payments</i ></span >
                                    <span class="toggle-symbols d-flex align-items-center justify-content-end" >%</span >
                                 </span>
                              </label>
                           </div>
                        </div>
                        <div class="mt-4">
                           <ngb-accordion #acc="ngbAccordion" class="calculation-accordion bg-primary" (shown)="onPanelShown($event)" (hidden)="onPanelHidden($event)" >
                              <ngb-panel [cardClass]=" getCardClass( panelStatus.interestCalculationPanel, data?.securitiesAndAssetsStatus ) " class="calculation-panel-container" id="interest-calculation-panel" >
                                 <ng-template ngbPanelHeader>
                                    <div class="calculation-panel">
                                       <div class="p-0 bg-transparent" id="headingOne" >
                                          <h2 class="mb-0">
                                             <button ngbPanelToggle class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3" type="button" >
                                                <span class="accordion-btn-title flex-grow-1 text-start" >Calculation</span >
                                                <i class="fa-solid fa-chevron-up accordion-icon" ></i>
                                             </button>
                                          </h2>
                                       </div>
                                    </div>
                                 </ng-template>
                                 <ng-template ngbPanelContent>
                                    <div class="row">
                                       <div class="col-md-6">
                                          <div class="d-flex p-2 mt-2 rounded align-items-center justify-content-between calculation-line-item-container-total" >
                                             <p class="mb-0 calculation-line-item-title" >
                                                The aggregate amount of Interest-bearing securities and assets of ETF
                                             </p>
                                             <span class="fw-500 calc-revenue calculation-line-item-val" >
                                                {{ getTotalSecuritiesAndAssets() }}
                                             </span>
                                          </div>
                                       </div>
                                       <div class="col-md-6 mt-3 mt-md-0">
                                          <div class="calc-percent text-center py-4" >
                                             <span> The aggregate amount of Interest-bearing securities and assets percentage of ETF = </span>
                                             <br /> <br />
                                             <span> ( Total Interest-bearing securities and assets of ETF) </span>
                                             <br />
                                             <span class="border-t d-inline-block mx-3" >
                                                ( Trailing 36-month average market capitalization of ETF )
                                             </span>
                                             <br /> <br />
                                             <div class="d-flex align-items-center justify-content-center" >
                                                <div class="d-flex flex-column align-items-center" >
                                                   <span class="">
                                                      <!-- {{ getConvertedAmount((data?.['totalIbSecAssetRevenue'] ?? 0) * data?.units) | screaningMillionPipe }} -->
                                                      {{ data?.['totalIbSecAssetRevenue'] ?? 0 * data?.units | screaningMillionPipe }}
                                                   </span>
                                                   <span class="border-t">
                                                      <!-- {{ getConvertedAmount(data?.marketValue)  | screaningMillionPipe }} -->
                                                      {{ data?.marketValue | screaningMillionPipe }}
                                                   </span>
                                                </div>
                                                <div class="mx-2">=</div>
                                                <div>
                                                   {{ data?.totalIbSecAssetRatio | number : "1.2-2" }}%
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </ng-template>
                              </ngb-panel>
                           </ngb-accordion>
                        </div>
                     </div>
                  </div>

                  <div class="border-danger pb-5" *ngIf="active == 3">
                     <div class="row">
                        <div class="col-lg-9">
                           <h3 class="d-flex align-items-center mb-0">
                              <span class="section-title" >Interest-bearing debt</span >
                              <span class="ms-2 rounded-pill ms-3 status-pill-large" [ngClass]="{ 'status-pill-large-pass': data?.debtStatus == 'PASS', 'status-pill-large-fail': data?.debtStatus == 'FAIL' }" >{{ data?.debtStatus }}</span >
                           </h3>
                        </div>
                     </div>

                     <div>
                        <div class="row mt-5 chart-content-wrapper mx-auto">
                           <div class="col-12 col-sm-7 col-md-8 px-0 pie-graph">
                              <div class="revenue-graph mt-4">
                                 <app-debt-chart [gauge]="data?.totalDebtRatio" [status]="data?.debtStatus" ></app-debt-chart>
                              </div>
                           </div>
                           <div class="col-12 col-sm-5 col-md-4 distribution-container d-flex flex-column gap-3 gap-lg-4 align-self-lg-end pb-lg-4" >
                              <div class="d-flex align-items-center" >
                                 <div class="distribution-elem compliant"></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title" >Shariah compliant</span > <span class="percent light" >Less than 30%</span >
                                 </div>
                              </div>
                              <div class="d-flex align-items-center">
                                 <div class="distribution-elem non-compliant" ></div>
                                 <div class="d-flex flex-column ms-2">
                                    <span class="legend-title" >Non-shariah compliant</span >
                                    <span class="percent light" >Greater than 30%</span >
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div class="screening-desc my-4">
                                 The aggregate amount of interest-bearing debt of all holdings of ETF <b >should not exceed 30% of the Trailing 36-month average market capitalization</b > of ETF to be Shariah compliant.
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-12 text-end">
                              <label class="toggle-switch">
                                 <input type="checkbox" [(ngModel)]="debtObj.showPercent" />
                                 <span class="toggle-slider bg-light pill-slider d-flex align-items-center justify-content-between" >
                                    <span class="toggle-symbols d-flex align-items-center justify-content-start" ><i class="material-symbols-outlined toggle-bill" >payments</i ></span >
                                    <span class="toggle-symbols d-flex align-items-center justify-content-end" >%</span >
                                 </span>
                              </label>
                           </div>
                        </div>
                        <div class="mt-4">
                           <ngb-accordion #acc="ngbAccordion" class="calculation-accordion" (shown)="onPanelShown($event)" (hidden)="onPanelHidden($event)" >
                              <ngb-panel [cardClass]=" getCardClass( panelStatus.debtCalculationPanel, data?.debtStatus ) " class="calculation-panel-container" id="debt-calculation-panel" >
                                 <ng-template ngbPanelHeader>
                                    <div class="calculation-panel">
                                       <div class="p-0 bg-transparent" id="headingOne" >
                                          <h2 class="mb-0">
                                             <button ngbPanelToggle class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-0 px-lg-3" type="button" >
                                                <span class="accordion-btn-title flex-grow-1 text-start" >Calculation</span >
                                                <i class="fa-solid fa-chevron-up accordion-icon" ></i>
                                             </button>
                                          </h2>
                                       </div>
                                    </div>
                                 </ng-template>
                                 <ng-template ngbPanelContent>
                                    <div class="row">
                                       <div class="col-md-6">
                                          <div class="d-flex calculation-line-item-container-total p-2 pr-lg-3 mt-2 rounded align-items-center justify-content-between" >
                                             <h4 class="mb-0 calc-heading py-2 calculation-line-item-title" >
                                                The aggregate amount of Interest-bearing debt of ETF
                                             </h4>
                                             <span class="fw-500 calc-revenue calculation-line-item-val" >
                                                {{ getTotalInterestBearingDebt() }}
                                             </span>
                                          </div>
                                       </div>

                                       <div class="col-md-6 mt-3 mt-md-0">
                                          <div class="calc-percent text-center py-4" >
                                             <span >The aggreate amount of Interest-bearing debt percentage of ETF =</span >
                                             <br /> <br />
                                             <span> ( Total Interest-bearing debt of ETF)</span >
                                             <br />
                                             <span class="border-t d-inline-block mx-3" >
                                                ( Trailing 36-month average market capitalization of ETF )
                                             </span>
                                             <br />
                                             <br />
                                             <div class="d-flex align-items-center justify-content-center" >
                                                <div class="d-flex flex-column align-items-center" >
                                                   <span class="">
                                                      <!-- {{ getConvertedAmount((data?.['totalIbDebtRevenue'] ?? 0) * data?.units) | screaningMillionPipe }} -->
                                                      {{ data?.['totalIbDebtRevenue'] ?? 0 * data?.units | screaningMillionPipe }}
                                                   </span>
                                                   <span class="border-t">
                                                      <!-- {{ getConvertedAmount(data?.marketValue) | screaningMillionPipe }}</span> -->
                                                      {{ data?.marketValue | screaningMillionPipe }}
                                                   </span >
                                                </div>
                                                <div class="mx-2">=</div>
                                                <div>
                                                   {{ data?.totalDebtRatio | number : "1.2-2" }}%
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </ng-template>
                              </ngb-panel>
                           </ngb-accordion>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </div>
</div>
</ng-container>
