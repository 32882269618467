import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PortfolioService } from '../../services/portfolio.service';
import { SupportedBrokerages } from 'src/app/models/supported-institution.model';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { ConnectIframeModalComponent } from '../connect-iframe-modal/connect-iframe-modal.component';

import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { CountryService } from '../../services/country.service';
import {
   BrokerageCoverageApiResponse,
   CoverageBrokerage,
} from 'src/app/features/pricing/models/brokerage-coverage.model';
import { PricingService } from 'src/app/features/pricing/services/pricing.service';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from '../../services/access-control.service';
import { LoaderServices } from '../../services/loader.service';
@Component({
   selector: 'app-supported-institutions',
   templateUrl: './supported-institutions.component.html',
   styleUrls: ['./supported-institutions.component.scss'],
})
export class SupportedInstitutionsComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   features: string[] = [
      'Portfolio Tracking',
      'Trading',
      'Automated Purification',
   ];
   activeFeature: string = this.features[0];
   allSupportedBrokerages: CoverageBrokerage[] = [];
   supportedBrokerages: CoverageBrokerage[] = [];
   filteredBrokerages: CoverageBrokerage[] = [];
   loading: boolean = false;
   private subscriptions: Subscription[];
   userRole: string | null = null;
   redirectUrl: string | null = null;
   connectIframeModalRef: any;
   isConnectLoading: boolean = false;
   currBrokerageId: any;
   paywallModalRef: any;
   searchText: string = '';
   sectionLoader: boolean = true;

   portfolioAccounts: PortfolioAccount[] = [];

   @Input() view: boolean = true;
   @Input() showTitle: boolean = true;
   @Input() perRow: number = 3;

   maxManualPortfoliosCount: number = 0;
   maxBrokeragePortfoliosCount: number = 0;
   currentManualPortfoliosCount: number = 0;
   currentBrokeragePortfoliosCount: number = 0;
   brokeragePortfolioAvailable: boolean = false;
   userNewRole: Role = null;
   private messagePopupRef: NgbModalRef | null = null;
   portfolioType!: 'broker' | 'manual';

   constructor(
      private portfolioServices: PortfolioService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private router: Router,
      private authService: AuthService,
      private countryService: CountryService,
      private sharedService: SharedService,
      private subscriptionService: SubscriptionService,
      private pricingService: PricingService,
      private portfolioService: PortfolioService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService,
      private loaderService: LoaderServices
   ) {
      this.subscriptions = [];
   }

   async ngOnInit() {
      this.loadPlaidScript();
      this.permissionStore
         .select(({ permissions }) => permissions.permissions)
         .pipe(takeUntil(this.destroy$))
         .subscribe((permissions) => {
            this.maxManualPortfoliosCount = permissions.manual_portfolio_count;
            this.maxBrokeragePortfoliosCount = permissions.brokerage_connected_portfolios_count;
            this.brokeragePortfolioAvailable = permissions.brokerage_connected_portfolios
         })

      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userNewRole = data.newRole;
            // console.log(this.userNewRole)
         });

      await this.getPortfolioList();
      await this.fetchBrokerageCoverages()
      // this.getAccountsCount()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   private async getPortfolioList() {
      this.sectionLoader = true;
      this.portfolioService
         .getPortfoliosList()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.portfolioAccounts = response;
               this.sectionLoader = false;
            },
            error: (error) => {
               console.error(error);
               this.sectionLoader = false;
            },
         });
   }

   private async fetchBrokerageCoverages() {
      this.loading = true;
      try {
         const res = await lastValueFrom(
            this.pricingService.fetchBrokeragesCoverage<BrokerageCoverageApiResponse>(
               'all'
            )
         );
         this.allSupportedBrokerages = res.data;
         this.filterBrokerageByFeature(this.activeFeature);
      } catch (err: any) {
         console.log(err);
      } finally {
         this.loading = false;
      }
   }

   filterBrokerageByFeature(feature: string) {
      this.activeFeature = feature;
      switch (feature) {
         case 'Portfolio Tracking': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.is_portfolio_linking_allowed
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
         case 'Trading': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.is_trading_allowed
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
         case 'Automated Purification': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.automated_purification
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
      }
   }

   private debounceTimer: any;
   public searching: any;
   public searchLoading: any;
   public searchBrokerages: any;

   searchBrokerage() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
         const lowerCaseText = this.searchText.toLowerCase();
         if(lowerCaseText.length >= 1){
            this.searching = true;
            this.searchLoading = true;
         }else{
            this.searching = false;
            this.searchLoading = false;
         }
         this.searchBrokerages = this.supportedBrokerages.filter(
            (item: CoverageBrokerage) =>
               item.name.toLowerCase().includes(lowerCaseText)
         );
         const institutions = await this.fetchInstitutions(lowerCaseText);
         this.processInstitutions(institutions, this.searchBrokerages);
         this.loadPlaidScript();
         this.searchLoading = false;
      }, 1000);
   }

   convertToUrlFormat(name: string): string {
      return name.replace(/\s+/g, '+');
   }

   processInstitutions(institutions: Array<any>, searchBrokerages: Array<any>) {
      institutions.forEach((institution) => {
         const exists = searchBrokerages.some(
            (brokerage) => brokerage.name === institution.display_name
         );

         if (!exists) {
            searchBrokerages.push({
               brokerage_id: institution.id,
               provider: institution.provider_name,
               provider_record_id: institution.provider_record_id,
               name: institution.display_name,
               slug: institution.display_name.toUpperCase(),
               url: '',
               logo: institution.logo,
               square_logo: institution.square_logo,
               supported_exchanges: [],
               countries: [],
               supported_features: {
                  is_trading_allowed: false,
                  is_portfolio_linking_allowed: true,
                  automated_purification: institution.is_transactions_available ?? true,
               }
            });
         }
      });
   }

   private async fetchInstitutions(lowerCaseText: string) {
      // this.loading = true;
      try {
         const res = await this.pricingService.fetchInstitutions(lowerCaseText);
         const institutions = res?.data?.institutions || [];
         return institutions;
      } catch (err: any) {
         console.log(err);
         return [];
      } finally {
         this.loading = false;
      }
   }

   openConnectIframeModal() {
      this.connectIframeModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
      this.connectIframeModalRef.componentInstance.module = 'portfolio';
   }

   loadPlaidScript() {
      if (document.querySelector('script[src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"]')) {
         console.log('Plaid script is already loaded');
         return;
      }
      const script = document.createElement('script');
      script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
         console.log('Plaid script loaded successfully');
      };

      script.onerror = () => {
         console.error('Failed to load the Plaid script');
      };
   }

   openPlaid() {
      const buttonContainer = document.createElement('div');
      buttonContainer.id = 'plaidButtonContainer';
      if (window.Plaid) {
         (async () => {
            try {
               const response = await this.pricingService.createLinkToken();
               const { link_token } = await response;
               const handler = window.Plaid.create({
                  token: link_token,
                  onSuccess: async (public_token: string, metadata: any) => {
                     const params = {
                        public_token,
                        webhook_type: 'CONNECTION_ADDED',
                        userId: this.authService.getUserId(),
                        link_token
                     };
                     let exchangeResponse;
                     let data;
                     let retries = 3;
                     while (retries > 0) {
                        try {
                           this.loading = true;
                           this.loaderService.show();
                           // alert("Please Wait for Few Minutes...");
                           exchangeResponse = await this.pricingService.plaidWebhook(params);
                           data = exchangeResponse;
                           if (data?.status === true) {
                              break;
                           }
                           console.warn("Webhook failed, retrying...");
                           retries--;

                        } catch (error) {
                           console.error("Error in plaidWebhook call:", error);
                           if (retries <= 1) {
                              throw error;
                           }
                           retries--;
                        }
                     }
                     if (!data || data?.status !== true) {
                        console.error("Webhook failed after all retries.");
                        throw new Error("Webhook retry attempts exhausted.");
                     }
                     if (data.status == true) {
                        this.loading = false;
                        this.loaderService.hide();
                        this.isConnectLoading = false;
                        alert("Webhook Status : " + data.message);
                        window.location.reload();
                     }

                  },
                  onExit: (err: any, metadata: any) => {
                     if (err) console.error('Exit Error:', err);
                     this.loaderService.hide();
                  },
               });
               handler.open();
            } catch (error) {
               console.error('Error initializing Plaid Link:', error);
               this.loaderService.hide();
            }
         })();
      } else {
         console.error("Plaid library is not loaded");
         this.loaderService.hide();
      }
   }

   initiatePlaid() {
      const supported_country_arr = ["US", "CA"];
      let country = this.countryService.getSelectedCountry().countryCode;
      this.activeModal.dismiss()
      if (true) {
         this.loadPlaidScript();
         this.openPlaid();
         return;
      } else {
         alert("Plaid is not Supported In Your Country Please Reselect Your Country to ['US','CA']");
      }
   }

   openUpgradeBrokeragePopup() {
      this.portfolioService.closeSupportedBrokeragesModal()
      this.portfolioService.openUnlockBrokeragePopup()
   }

   initiateBrokerage(brokerage: any) {
      if (!this.brokeragePortfolioAvailable) {
         this.openUpgradeBrokeragePopup()
         return
      }

      if (this.currentBrokeragePortfoliosCount < this.maxBrokeragePortfoliosCount) {
         if (brokerage?.provider && brokerage?.provider == 'PLAID') {
            this.isConnectLoading = true;
            this.initiatePlaid();
            return;
         }
         this.currBrokerageId = brokerage.brokerage_id;
         this.isConnectLoading = true;
         this.portfolioServices
            .getConnectUrl(brokerage.brokerage_id, false)
            .then((resp: any) => {
               if (resp.status) {
                  this.isConnectLoading = false;
                  this.activeModal.dismiss();
                  this.redirectUrl = resp.data.redirect_url;
                  this.openConnectIframeModal();
               }
            })
            .catch((err: any) => {
               console.log(err);
               this.isConnectLoading = false;
            });
      } else {
         this.openMessagePopup('broker');
      }
   }

   navigateToPricingPage() {
      this.activeModal.dismiss()
      this.router.navigate(['/pricing'])
   }

   plaidLoading(isLoading: boolean) {
      this.loading = isLoading;
   }

   openMessagePopup(type: 'broker' | 'manual') {
      this.portfolioService.openMessagePopup(type)
   }

   getAccountsCount() {
      this.portfolioService.getAccountCounts().pipe(takeUntil(this.destroy$)).subscribe((data) => {
         // console.log(data);
         this.currentManualPortfoliosCount = data.manual_connected_count;
         this.currentBrokeragePortfoliosCount = data.brokerage_connected_count;
      })
   }

   canCreateManualPortfolio(): boolean {
      return this.currentManualPortfoliosCount < this.maxManualPortfoliosCount;
   }

   canConnectBrokeragePortfolio(): boolean {
      return this.currentBrokeragePortfoliosCount < this.maxBrokeragePortfoliosCount
   }

}
