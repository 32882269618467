<div class="halal-percent-wrapper d-flex flex-column align-items-center justify-content-evenly position-relative" [ngClass]="cardType">
   <div class="progress-circle mx-auto" [attr.data-percentage]="percentage">
      <svg class="progress-circle__svg" viewBox="0 0 100 100">
         <circle class="progress-circle__stroke" r="50%" cx="50%" cy="50%" stroke-width="20"></circle>
         <circle class="progress-circle__stroke" r="50%" cx="50%" cy="50%" stroke-width="20" [ngStyle]="{ 'stroke-dasharray': '314.1592%', 'stroke-dashoffset': calculateDashOffset()}"></circle>
      </svg>
      <span class="percentage-text">{{percentage | number:'1.0-0'}}%</span>
   </div>
   <span class="halal-text d-block lh-1">Halal</span>
</div>
