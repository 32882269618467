import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { ZakatFormsEnum } from 'src/app/models/zakat.model';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { ZakatService } from 'src/app/shared/services/zakat.service';

@Component({
  selector: 'app-zakat-dashboard-card',
  templateUrl: './zakat-dashboard-card.component.html',
  styleUrls: ['./zakat-dashboard-card.component.scss']
})
export class ZakatDashboardCardComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  ZakatFormEnum = ZakatFormsEnum;
  userRole: string | null = null

  constructor(
    private zakatService: ZakatService,
    public subscriptionService: SubscriptionService,
    private router: Router,
    public accessControlService: AccessControlService
  ) { }


  ngOnInit(): void {
    this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      this.userRole = data.role
    }))
    this.zakatService.setShowForm(this.ZakatEnum.Home);
  }

  get ZakatEnum() {
    return this.zakatService.ZakatEnum;
  }

  getShowForm(): ZakatFormsEnum {
    return this.zakatService.getShowForm();
  }

  navigateToZakatPage() {
    if(this.userRole === 'ANONYMOUS') {
      this.accessControlService.accessDenied();
    } else {
      this.router.navigate(['/zakat-calculator']);
    }
  }

}
