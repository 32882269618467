import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
import { NewsService } from 'src/app/shared/services/news.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';

type StockLoadingType = 'topGainers' | 'topLosers';

@Component({
  selector: 'app-trending-halal-etfs',
  templateUrl: './trending-halal-etfs.component.html',
  styleUrls: ['./trending-halal-etfs.component.scss']
})
export class TrendingHalalEtfsComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   isMobile: boolean = false;
   userRole: string | null = null;
   isLoggedIn: boolean = false;
   selectedCountry: string = 'US';
   perPage: number = 6

   topGainers: any[] = [];
   topLosers: any[] = [];

   etfsLoading: Record<StockLoadingType, boolean> = {
      topGainers: true,
      topLosers: true,
   };

   constructor(
      private typesenseService: TypesenseService,
      private observer: BreakpointObserver,
      private marketUtilsService: MarketUtilsService,
      private authService: AuthService,
      private userApiService: UserApiService,
      private countryService: CountryService,
      private newsService: NewsService
   ) { }

   ngOnInit(): void {
      this.isLoggedIn = this.authService.isLoggedIn();
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;
         });

      this.observer
         .observe('(max-width: 991px)')
         .pipe(takeUntil(this.destroy$))
         .subscribe((result) => {
            this.isMobile = result.matches;
         });

      // this.countryService.selectedCountry$.pipe(takeUntil(this.destroy$)).subscribe(country => {
      //    this.selectedCountry = country;
      //    this.init();
      // });
      this.init();
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async init() {
      this.topGainers = await this.getETFsCollectionData('topGainers');
      this.topLosers = await this.getETFsCollectionData('topLosers');

      let allCollectionsSData = [...this.topGainers, ...this.topLosers];

      this.newsService.setETFsSymbols(allCollectionsSData)
   }

   async getETFsCollectionData(type: StockLoadingType) {
      try {
         this.etfsLoading[type] = true;
         let apiResponse;
         switch (type) {
         case 'topGainers':
            apiResponse = await this.typesenseService.getEtfsData(this.selectedCountry, 1, this.perPage, 'priceChange1DPercent:desc', 'domicile:=US&&shariahCompliantStatus:=COMPLIANT');
            break;
         case 'topLosers':
            apiResponse = await this.typesenseService.getEtfsData(this.selectedCountry, 1, this.perPage, 'priceChange1DPercent:asc', 'domicile:=US&&shariahCompliantStatus:=COMPLIANT');
            break;
         }

         // let etfsData = [];
         // if (apiResponse) {
         //    etfsData = await this.processApiResponse(apiResponse);
         // }
         return Object.values(apiResponse);
      } catch (error) {
         console.error(error);
         return [];
      } finally {
         this.etfsLoading[type] = false;
      }
   }

   async processApiResponse(apiResponse: any) {
      let stocksData: any[] = [];
      const resp = apiResponse;
      stocksData = resp.hits.map((item: any) => item.document)
      const stockSymbols = resp.hits.map((item: any) => item.document.symbol);

      // await this.getPriceData(stocksData, stockSymbols);
      return stocksData;
   }

   async getPriceData(etfs: any[], symbols: string[]) {
      try {
         const res = await this.typesenseService.getEtfPrices(symbols);
         for (const key in res) {
            let priceValue = res[key];
            let etf = etfs.find(
               (item: any) => item.symbol === key
            );
            if (etf) {
               if (!etf.lastPrice) {
                  etf.lastPrice = priceValue.currentPrice;
               }
               etf.price = priceValue.currentPrice;
               etf.priceChange = priceValue.change.toFixed(2);
               etf.priceChangePercent = priceValue.percentChange.toFixed(2);
            }
         }
      } catch (error) {
         console.error(error);
      }
   }
}
