<section class="invest-section content-padding" [ngClass]="isBannerCollapsed ? 'collapsed-spacing': ''">
   <div class="container">
      <div class="invest-banner" [ngClass]="isBannerCollapsed ? 'collapsed-card': ''">
         <button class="toggle-window-btn d-flex align-items-center shadow-none justify-content-center p-0 bg-white btn rounded-circle" (click)="toggleBannerCollapse()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#28a3be" fill="none" *ngIf="isBannerCollapsed">
               <path d="M12 4V20M20 12H4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
           </svg>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="#28a3be" fill="none" *ngIf="!isBannerCollapsed">
               <path d="M20 12L4 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button>

         <ng-container *ngIf="!isBannerCollapsed">
            <div class="d-none d-lg-block image-item">
               <img appLazyLoadImage="/assets/images/dashboard/founders-image.webp" placeholder="/assets/images/dashboard/founders-image-lowres.png" class="img-fluid d-block mx-auto founders-image" alt="Musaffa Founders Image">
            </div>
            <div class="banner-grid">
               <div class="d-lg-none image-item">
                  <img appLazyLoadImage="/assets/images/dashboard/founders-image.webp" placeholder="/assets/images/dashboard/founders-image-lowres.png" class="img-fluid d-block mx-auto founders-image" alt="Musaffa Founders Image">
               </div>
               <div class="progress-cards-grid">
                  <h3 class="mb-0 fw-600 text-center text-lg-start text-uppercase">
                     {{ countdownComplete ? 'Crowdfunding Closed' : 'Crowdfunding closing soon:' }}
                  </h3>

                  <article class="progress-card">
                     <ng-container>

                        <!-- <ng-container *ngIf="!countdownComplete"> -->
                        <ng-container>
                           <div class="clock-div" *ngIf="countdown$ | async as countdown; else showLoader">
                              <div class="d-flex flex-column align-items-center single-countdown-div">
                                 <span class="d-block fw-600">{{ !countdownComplete ? (countdown.days | number: '2.0') : '00' }}</span>
                                 <div class="smalltext fw-500 text-uppercase">Days</div>
                              </div>
                              <div class="d-flex flex-column align-items-center single-countdown-div">
                                 <span class="d-block fw-600">{{ !countdownComplete ? (countdown.hours | number: '2.0') : '00' }}</span>
                                 <div class="smalltext fw-500 text-uppercase">Hrs</div>
                              </div>
                              <div class="d-flex flex-column align-items-center single-countdown-div">
                                 <span class="d-block fw-600">{{ !countdownComplete ? (countdown.minutes | number: '2.0') : '00' }}</span>
                                 <div class="smalltext fw-500 text-uppercase">Mins</div>
                              </div>
                              <div class="d-flex flex-column align-items-center single-countdown-div">
                                 <span class="d-block fw-600">{{ !countdownComplete ? (countdown.seconds | number: '2.0') : '00' }}</span>
                                 <div class="smalltext fw-500 text-uppercase">Secs</div>
                              </div>
                           </div>

                           <ng-template #showLoader>
                              <ngx-skeleton-loader
                                 count="1" appearance="line" animation="progress"
                                 [theme]="{ height: '100%', 'min-height': '90px', width: '100%', margin: '0px'}">
                              </ngx-skeleton-loader>
                           </ng-template>

                        </ng-container>

                        <!-- <ng-container *ngIf="countdownComplete">
                           <div class="closed-wrapper overflow-hidden">
                              <p class="mb-0 d-flex align-items-center justify-content-center">
                                 Crowdfunding Round Closed
                              </p>
                           </div>
                        </ng-container> -->


                     </ng-container>

                     <div class="seed-wrapper">
                        <h5 class="mb-0">Seed Round</h5>

                        <div class="progress-wrapper rounded-pill">
                           <div class="progress rounded-pill">
                              <div class="progress-bar rounded-pill" role="progressbar" [style.width.%]="investmentData.v2_progressbar_percent" [attr.aria-valuenow]="investmentData.v2_progressbar_percent" aria-valuemin="0" aria-valuemax="100"></div>
                           </div>
                        </div>

                        <div class="d-flex align-items-start justify-content-between">
                           <div class="d-flex flex-column align-items-start amount-wrapper">
                              <span class="progress-amount flex-shrink-0">{{ investmentData.v2_amount_received }}</span>
                              <span class="inv-span">raised from <span class="fw-600">{{ investmentData.v2_number_of_investors }}</span> investors</span>
                           </div>
                           <div class="d-flex align-items-center gap-1">
                              <span class="goal-span fw-600 goal-amount">{{ investmentData.amount_to_be_raised }}</span>
                              <span class="d-inline-block flex-shrink-0" placement="left" ngbTooltip="Amount raised includes proceeds via both Regulation CF and Regulation D">
                                 <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="#1bad4e">
                                    <path d="M480-280q17 0 28.5-11.5T520-320v-160q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480v160q0 17 11.5 28.5T480-280Zm0-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                                 </svg>
                              </span>
                           </div>
                        </div>
                     </div>

                  </article>

                  <a [href]="getInvestmentLink()" target="_blank" class="btn mx-auto mt-lg-2 invest-now-btn">
                     {{ countdownComplete ? 'Join the Waitlist' : 'Invest Now' }}
                  </a>
               </div>
            </div>
         </ng-container>

         <ng-container *ngIf="isBannerCollapsed">
            <div class="d-flex align-items-center justify-content-center gap-2 gap-lg-3 py-3 flex-wrap">
               <h3 class="mb-0 fw-600 text-uppercase">
                  {{ countdownComplete ? 'Crowdfunding Closed' : 'Crowdfunding closing soon:' }}
               </h3>
               <a [href]="getInvestmentLink()" target="_blank" class="btn rounded-3 invest-now-btn">
                  {{ countdownComplete ? 'Join the Waitlist' : 'Invest Now' }}
               </a>
            </div>
         </ng-container>

      </div>
   </div>
</section>

