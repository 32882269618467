<article class="subscription-card">
   <h4 class="subscription-card--heading" *ngIf="(!isMobileSubscription && isPopup && source === 'promotional') else elseBlock;">
      Confirm & Proceed
   </h4>
   <ng-template #elseBlock>
      <h4 class="subscription-card--heading" >
         Manage Subscription
      </h4>
   </ng-template>
   <div *ngIf="!isPopup && allActiveSubscriptions.length > 0" class="my-2">
      <b class="text-lg">Active Plan<span *ngIf="allActiveSubscriptions.length > 1">s</span></b>
   </div>
   <div *ngIf="!isPopup && allActiveSubscriptions.length > 0" class="d-flex flex-column gap-0 mb-3">
      <div *ngFor="let subscription of allActiveSubscriptions" class="subs-details-wrapper border border-1 p-3 rounded-3">
            <div class="subs-details-section">
               {{ subscription.newRole ? formatRole(subscription.newRole, subscription.subscriptionSource) : 'N/A' }}
            </div>
            <div class="subs-details-section">
               Valid till: {{ subscription.expiresDate ? formatDate(subscription.expiresDate) : 'N/A' }}
            </div>
            <div class="subs-details-section">
               Subscribed from: {{ subscription.subscriptionSource ? getSubscriptionSource(subscription.subscriptionSource) : 'N/A' }}
            </div>
      </div>
   </div>
   <div class="border border-1 rounded-3 p-3">
      <div *ngIf="source === 'promotional' && !nonPromotionalSubscription">
         <div *ngIf="isPopup"> <!--PRICING PAGE-->
            <p class="mb-2 subscription-card--para">
               You are currently entitled to Promotional Subscription of {{planName}} plan.
               It will expire on {{expirationDate}}. Are you sure you want to proceed and buy the subscription?
            </p>
            <a (click)="redirectToPaymentLink()" target="_blank" class="btn shadow-none py-2 px-4 text-decoration-none gradient-btn mt-4 text-white d-flex align-items-center gap-2 subscription-card--button">
               <span>Proceed</span>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
                  <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
            </a>
         </div>
         <div *ngIf="!isPopup"> <!--PROFILE PAGE-->
            <p class="mb-2 subscription-card--para">
               You currently have access to a promotional subscription of {{planName}} plan.
               It will expire on {{expirationDate}}. If you'd like to buy our subscription,
               please click below and select a plan from the pricing page.
            </p>
            <a href="/pricing" target="_blank" class="btn shadow-none py-2 px-4 text-decoration-none gradient-btn mt-4 text-white d-flex align-items-center gap-2 subscription-card--button">
               <span>Buy Subscription</span>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
                  <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
            </a>
         </div>
      </div>
      <div *ngIf="nonPromotionalSubscription">
         <div *ngIf="isMobileSubscription">
            <div *ngIf="isPopup"> <!--PRICING PAGE-->
               <p class="mb-2 subscription-card--para">
                  It looks like you're subscribed through {{getDeviceName(nonPromotionalSubscription.subscriptionSource)}} device.
                  To manage your subscription, please visit the respective app store.
                  All updates and changes can be handled directly there.
               </p>
               <a href="https://app.musaffa.com/W1VHlyU6YMb" target="_blank" class="btn shadow-none py-2 px-4 text-decoration-none gradient-btn mt-4 text-white d-flex align-items-center gap-2 subscription-card--button">
                  <span>Open App</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
                     <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </a>
            </div>
            <div *ngIf="!isPopup"> <!--PROFILE PAGE-->
               <p class="mb-2 subscription-card--para">
                  It looks like you're subscribed through {{getDeviceName(nonPromotionalSubscription.subscriptionSource)}} device.
                  To manage your subscription, please visit the respective app store.
                  All updates and changes can be handled directly there.
               </p>
               <a [href]="getStoreLink(nonPromotionalSubscription.subscriptionSource)" target="_blank" class="btn shadow-none py-2 px-4 text-decoration-none gradient-btn mt-4 text-white d-flex align-items-center gap-2 subscription-card--button">
                  <span>Visit Store</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
                     <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </a>
            </div>
         </div>
         <div *ngIf="!isMobileSubscription">
            <div *ngIf="nonPromotionalSubscription?.subscriptionSource === 'stripe'">
               <p class="mb-2 subscription-card--para">
                  You can manage your Musaffa subscription (Switch plans, View invoice history, Cancel subscription)
                  directly from the Stripe customer portal.
               </p>
               <p class="mb-0 subscription-card--note">
                  <strong>NOTE: </strong>You would be asked to login when you click the below link.
                  Please login via the same email id using which you have logged in to Musaffa.
               </p>
               <a href="https://billing.stripe.com/p/login/8wMcPNfbH0ZTgso8ww" target="_blank" class="btn shadow-none py-2 px-4 text-decoration-none gradient-btn mt-4 text-white d-flex align-items-center gap-2 subscription-card--button">
                  <span>Manage Subscription</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="currentColor" fill="none">
                     <path d="M20.0001 11.9998L4.00012 11.9998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                     <path d="M15.0003 17C15.0003 17 20.0002 13.3176 20.0002 12C20.0002 10.6824 15.0002 7 15.0002 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
               </a>
            </div>
         </div>
      </div>
   </div>
</article>
