<ng-container>
   <div class="holding-details-popup-container">
      <div class="content-container py-0">
         <div
            class="d-flex align-items-center mb-0 justify-content-between gap-4"
         >
            <h5 class="fw-bold lh-1 mb-0">Holding Details</h5>
            <button
               class="btn p-0 shadow-none lh-1"
               (click)="activeModal.dismiss()"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  height="24"
                  width="24"
                  viewBox="0 0 384 512"
               >
                  <path
                     d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                  />
               </svg>
            </button>
         </div>
      </div>
      <div class="d-flex flex-column content-container">
         <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
               <img
                  *ngIf="logo"
                  src="{{ logo }}"
                  class="bg-light flex-shrink-0 border img-fluid rounded-circle stock-image"
                  alt=""
               />
               <span
                  *ngIf="!logo"
                  class="bg-white border fs-4 fw-bold flex-shrink-0 img-fluid rounded-circle d-flex align-items-center justify-content-center stock-image"
               >
                  {{ stockName.charAt(0) | uppercase }}
               </span>
               <div class="d-block stock-info mt-2">
                  <span class="holding-name">{{ stockName }}</span>
                  <span class="holding-symbol" *ngIf="stockSymbol"
                     >&nbsp;({{ stockSymbol }})</span
                  >
               </div>
            </div>
            <app-compliance-chip
               [preloaded]="true"
               [showCompliance]="true"
               [symbol]="stockIsin"
               [complianceStatus]="complianceStatus"
               [complianceRanking]="complianceRanking"
               [complianceRankingV2]="ranking_v2"
            >
            </app-compliance-chip>
         </div>
      </div>
      <div class="gap-3 rounded-3 p-2 px-lg-3 details-container">
         <div class="d-flex flex-column">
            <div class="shared-owned d-flex justify-content-between pb-2">
               <span>Total Shares Owned</span>
               <span>{{ sharesOwned | number }}</span>
            </div>
            <div class="d-flex flex-column info-container">
               <div class="d-flex justify-content-between pt-2 pb-1 pb-lg-1">
                  <div class="details-label">Percentage of Portfolio</div>
                  <div class="details-value" *ngIf="portfolioPercentage >= 0.01">
                     {{ portfolioPercentage | number : "1.2-2" }}%
                  </div>
                  <span class="" *ngIf="portfolioPercentage < 0.01">
                     <app-less-than-tooltip
                        [amount]="portfolioPercentage"
                        [currency]="' '"
                        [tooltipPosition]="'right'"
                        [amountStyle]="{
                           fontWeight: '700'
                        }"
                        [tooltipContent]="portfolioPercentage"
                     >
                     </app-less-than-tooltip>
                  </span>
               </div>
               <div class="d-flex justify-content-between py-1 py-lg-1">
                  <div class="details-label">Current Value</div>
                  <div class="details-value">${{ currentValue | number }}</div>
               </div>
               <div class="d-flex justify-content-between py-1 py-lg-1">
                  <div class="details-label">Report Date</div>
                  <div class="details-value">
                     {{ reportDate | date : "mediumDate" }}
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- <ng-container *ngIf="holding.complianceStatus === 'NON_COMPLIANT' || holding.complianceStatus === 'QUESTIONABLE'">
         <p class="mb-0 collapse-btn">
            <button type="button" class="btn shadow-none py-2 text-start d-flex rounded-3 align-items-center justify-content-between gap-2 w-100" (click)="collapse.toggle()" [attr.aria-expanded]="!isQuestionCollapsed" aria-controls="collapseExample">
               <span [ngClass]="{'span-text' : !isQuestionCollapsed}">
                  How to deal with Non-Halal stocks as per the Shariah ruling?
               </span>
               <svg width="16px" height="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [ngClass]="{'rotate' : !isQuestionCollapsed}">
                  <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
               </svg>
            </button>
         </p>
         <div #collapse="ngbCollapse" [(ngbCollapse)]="isQuestionCollapsed">
            <div class="card border-0">
               <div class="card-body pt-0 answer-text">
                  {{nonPurifiableText}}
               </div>
            </div>
         </div>
      </ng-container> -->
      <button
         class="btn w-100 fw-bold text-white mark-purified-btn rounded"
         (click)="goToDetails()" *ngIf="stockType === 'stock' || stockType === 'etf'"
      >
         <span *ngIf="stockType === 'stock'">VIEW STOCK DETAILS</span>
         <span *ngIf="stockType === 'etf'">VIEW ETF DETAILS</span>
      </button>
   </div>
</ng-container>
