<div class="pt-3 px-3" *ngIf="tableLoader">
   <ngx-skeleton-loader count="8" appearance="line" animation="progress" [theme]="{ height: '50px', width: '100%', 'border-radius': '8px' }" > </ngx-skeleton-loader>
</div>
<table class="table mb-0" *ngIf="!tableLoader">
   <thead>
      <tr class="d-none d-lg-table-row">
         <th class="text-start"></th>
         <th class="text-start">Name</th>
         <th class="text-end">Recent Activity</th>
         <th class="text-end d-none d-lg-table-cell">% Portfolio</th>
         <th class="text-end">Price</th>
         <th class="text-end">1D Change</th>
         <th class="text-center">Compliance</th>
         <th class="text-center">Rating</th>
      </tr>
   </thead>
   <tbody class="">
      <ng-container *ngIf="investorStocks && investorStocks.length > 0">
         <ng-container *ngFor="let stock of filtereredStocks(); let i = index">
            <tr class="stock border-bottom single-row">
               <td class="align-middle p-0">
                  <div class="star-wrapper">
                     <app-watchlist-button [stock]="stock"></app-watchlist-button>
                  </div>
               </td>
               <td class="table-data align-middle" (click)="openStockDetailsPopup(stock)" >
                  <div class="text-start d-flex align-items-center">
                     <div class="me-2 me-lg-3 logo-box bg-light rounded-circle">
                        <img *ngIf="stock.logo" src="{{ stock.logo }}" alt="" class="logo-img" />
                        <div *ngIf="!stock.logo" class="no-logo fw-bold d-flex align-items-center justify-content-center fs-4" >
                           {{ stock?.name?.substr(0, 1) }}
                        </div>
                     </div>
                     <div>
                        <p class="mb-0 line-clamp-1 company--name">
                           {{ stock.name }}
                        </p>
                        <span class="line-clamp-1 d-lg-none stock--name"
                           >% Portfolio:
                           <app-less-than-tooltip
                              [amount]="stock.percentage"
                              [currency]="' '"
                              [tooltipPosition]="'right'"
                              [amountStyle]="{ color: '#545454', fontWeight: '700' }"
                              [tooltipContent]="stock.percentage"
                           >
                           </app-less-than-tooltip>
                           <span class="fw-bold">%</span>
                        </span>
                        <span class="line-clamp-1 d-none d-lg-block stock--name" >
                           {{ stock.ticker }}
                        </span>
                     </div>
                  </div>
               </td>
               <td class="table-data d-none text-end d-lg-table-cell align-middle" (click)="openStockDetailsPopup(stock)" >
                  <div class="d-flex align-items-center justify-content-end gap-2" >
                     <p class="mb-0 d-flex align-items-center gap-2 ms-lg-1 ps-lg-2" >
                        <span class="fw-600" [ngClass]="getTextClasses(stock)" *ngIf="stock.percentageChange" >
                           {{ stock.percentageChange | number : "1.2-2" }}%
                        </span>
                        <span [ngClass]="getTextClasses(stock)" *ngIf="!stock.percentageChange" >-</span >
                        <span>
                           <svg width="14" height="14" class="lh-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="stock.percentageChange > 0" >
                              <path d="M6.33398 6.86671V9.00004C6.33398 9.18893 6.39787 9.34726 6.52565 9.47504C6.65343 9.60282 6.81176 9.66671 7.00065 9.66671C7.18954 9.66671 7.34787 9.60282 7.47565 9.47504C7.60343 9.34726 7.66732 9.18893 7.66732 9.00004V6.86671L8.26732 7.46671C8.38954 7.58893 8.5451 7.65004 8.73398 7.65004C8.92287 7.65004 9.07843 7.58893 9.20065 7.46671C9.32287 7.34448 9.38398 7.18893 9.38398 7.00004C9.38398 6.81115 9.32287 6.6556 9.20065 6.53337L7.46732 4.80004C7.33398 4.66671 7.17843 4.60004 7.00065 4.60004C6.82287 4.60004 6.66732 4.66671 6.53398 4.80004L4.80065 6.53337C4.67843 6.6556 4.61732 6.81115 4.61732 7.00004C4.61732 7.18893 4.67843 7.34448 4.80065 7.46671C4.92287 7.58893 5.07843 7.65004 5.26732 7.65004C5.45621 7.65004 5.61176 7.58893 5.73398 7.46671L6.33398 6.86671ZM7.00065 13.6667C6.07843 13.6667 5.21176 13.4917 4.40065 13.1417C3.58954 12.7917 2.88398 12.3167 2.28398 11.7167C1.68398 11.1167 1.20898 10.4112 0.858984 9.60004C0.508984 8.78893 0.333984 7.92226 0.333984 7.00004C0.333984 6.07782 0.508984 5.21115 0.858984 4.40004C1.20898 3.58893 1.68398 2.88337 2.28398 2.28337C2.88398 1.68337 3.58954 1.20837 4.40065 0.858374C5.21176 0.508374 6.07843 0.333374 7.00065 0.333374C7.92287 0.333374 8.78954 0.508374 9.60065 0.858374C10.4118 1.20837 11.1173 1.68337 11.7173 2.28337C12.3173 2.88337 12.7923 3.58893 13.1423 4.40004C13.4923 5.21115 13.6673 6.07782 13.6673 7.00004C13.6673 7.92226 13.4923 8.78893 13.1423 9.60004C12.7923 10.4112 12.3173 11.1167 11.7173 11.7167C11.1173 12.3167 10.4118 12.7917 9.60065 13.1417C8.78954 13.4917 7.92287 13.6667 7.00065 13.6667Z" fill="#1B5B44" />
                           </svg>
                           <svg width="14" height="14" class="lh-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="stock.percentageChange < 0" >
                              <path d="M7.66602 7.13329V4.99996C7.66602 4.81107 7.60213 4.65274 7.47435 4.52496C7.34657 4.39718 7.18824 4.33329 6.99935 4.33329C6.81046 4.33329 6.65213 4.39718 6.52435 4.52496C6.39657 4.65274 6.33268 4.81107 6.33268 4.99996V7.13329L5.73268 6.53329C5.61046 6.41107 5.4549 6.34996 5.26602 6.34996C5.07713 6.34996 4.92157 6.41107 4.79935 6.53329C4.67713 6.65552 4.61602 6.81107 4.61602 6.99996C4.61602 7.18885 4.67713 7.3444 4.79935 7.46663L6.53268 9.19996C6.66602 9.33329 6.82157 9.39996 6.99935 9.39996C7.17713 9.39996 7.33268 9.33329 7.46602 9.19996L9.19935 7.46663C9.32157 7.3444 9.38268 7.18885 9.38268 6.99996C9.38268 6.81107 9.32157 6.65552 9.19935 6.53329C9.07713 6.41107 8.92157 6.34996 8.73268 6.34996C8.54379 6.34996 8.38824 6.41107 8.26602 6.53329L7.66602 7.13329ZM6.99935 0.333294C7.92157 0.333294 8.78824 0.508294 9.59935 0.858294C10.4105 1.20829 11.116 1.68329 11.716 2.28329C12.316 2.88329 12.791 3.58885 13.141 4.39996C13.491 5.21107 13.666 6.07774 13.666 6.99996C13.666 7.92218 13.491 8.78885 13.141 9.59996C12.791 10.4111 12.316 11.1166 11.716 11.7166C11.116 12.3166 10.4105 12.7916 9.59935 13.1416C8.78824 13.4916 7.92157 13.6666 6.99935 13.6666C6.07713 13.6666 5.21046 13.4916 4.39935 13.1416C3.58824 12.7916 2.88268 12.3166 2.28268 11.7166C1.68268 11.1166 1.20768 10.4111 0.857683 9.59996C0.507684 8.78885 0.332684 7.92218 0.332684 6.99996C0.332684 6.07774 0.507684 5.21107 0.857683 4.39996C1.20768 3.58885 1.68268 2.88329 2.28268 2.28329C2.88268 1.68329 3.58824 1.20829 4.39935 0.858294C5.21046 0.508294 6.07713 0.333294 6.99935 0.333294Z" fill="#C70707" />
                           </svg>
                        </span>
                     </p>
                  </div>
               </td>
               <td class="d-none text-end d-lg-table-cell align-middle" (click)="openStockDetailsPopup(stock)" >
                  <span class="">
                     <app-less-than-tooltip
                        [amount]="stock.percentage"
                        [currency]="' '"
                        [tooltipPosition]="'left'"
                        [amountStyle]="{ color: '#000000', fontWeight: '400' }"
                        [tooltipContent]="stock.percentage"
                     >
                     </app-less-than-tooltip>
                  </span>
               </td>
               <td class="table-data text-end align-middle d-lg-none" (click)="openStockDetailsPopup(stock)" >
                  <div>
                     <span class="stock--price" *ngIf="stock.price">
                        {{ stock.price | sumPipe : (stock.currency || '') }}
                     </span>
                     <span *ngIf="!stock.price">-</span>
                  </div>
                  <div class="stock--change">
                     <span *ngIf="stock.percentChange" [ngClass]="getPercentChangeClasses(stock)" >
                        <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                        {{ stock.percentChange | number : "1.2-2" }}%
                     </span>
                     <span *ngIf="!stock.percentChange">-</span>
                  </div>
               </td>
               <td class="text-end align-middle d-none d-lg-table-cell" (click)="openStockDetailsPopup(stock)" >
                  <span class="stock--price" *ngIf="stock.price">{{ stock.price | sumPipe : (stock.currency || '') }}</span>
                  <span *ngIf="!stock.price">-</span>
               </td>
               <td class="text-end align-middle stock--change d-none d-lg-table-cell" >
                  <span *ngIf="stock.percentChange" [ngClass]="getPercentChangeClasses(stock)" >
                     <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                     {{ stock.percentChange | number : "1.2-2" }}%
                  </span>
                  <span *ngIf="!stock.percentChange">-</span>
               </td>
               <td class="table-data align-middle" (click)="openStockDetailsPopup(stock)" >
                  <div class="d-flex justify-content-center text-nowrap">
                     <app-compliance-chip
                        [preloaded]="true"
                        [showCompliance]="i < 3"
                        [symbol]="stock.isin"
                        [complianceStatus]="stock.complianceStatus"
                        [complianceRanking]="stock.complianceRanking"
                        [complianceRankingV2]="stock.complianceRankingV2"
                        [onlyCompliance]="true"
                     >
                     </app-compliance-chip>
                  </div>
               </td>
               <td class="table-data align-middle" (click)="openStockDetailsPopup(stock)" >
                  <div class="d-flex justify-content-center text-nowrap">
                     <app-compliance-chip
                        [preloaded]="true"
                        [showCompliance]="i < 3"
                        [symbol]="stock.isin"
                        [complianceStatus]="stock.complianceStatus"
                        [complianceRanking]="stock.complianceRanking"
                        [complianceRankingV2]="stock.complianceRankingV2"
                        [onlyRanking]="true"
                     >
                     </app-compliance-chip>
                  </div>
               </td>
            </tr>
            <tr class="activity-row red-bg" *ngIf="stock.percentageChange && isMobile" (click)="openStockDetailsPopup(stock)" >
               <td colspan="5" class="align-middle py-1">
                  <div class="d-flex align-items-center gap-2">
                     <!-- <svg class="pulse-svg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M361-80q-14 0-24.5-7.5T322-108L220-440H80v-80h170q13 0 23.5 7.5T288-492l66 215 127-571q3-14 14-23t25-9q14 0 25 8.5t14 22.5l87 376 56-179q4-13 14.5-20.5T740-680q13 0 23 7t15 19l50 134h52v80h-80q-13 0-23-7t-15-19l-19-51-65 209q-4 13-15 21t-25 7q-14-1-24-9.5T601-311l-81-348-121 548q-3 14-13.5 22T361-80Z"/>
                     </svg> -->
                     <p class="mb-0 d-flex align-items-center gap-2 ms-lg-1 ps-lg-2" >
                        <span>Recent Activity: </span>
                        <span class="fw-600" [ngClass]="getTextClasses(stock)">
                           {{ stock.percentageChange > 0 ? "Increased" : "Decreased" }} {{ stock.percentageChange | number : "1.2-2" }}%
                        </span>
                        <span>
                           <svg width="14" height="14" class="lh-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="stock.percentageChange > 0" >
                              <path d="M6.33398 6.86671V9.00004C6.33398 9.18893 6.39787 9.34726 6.52565 9.47504C6.65343 9.60282 6.81176 9.66671 7.00065 9.66671C7.18954 9.66671 7.34787 9.60282 7.47565 9.47504C7.60343 9.34726 7.66732 9.18893 7.66732 9.00004V6.86671L8.26732 7.46671C8.38954 7.58893 8.5451 7.65004 8.73398 7.65004C8.92287 7.65004 9.07843 7.58893 9.20065 7.46671C9.32287 7.34448 9.38398 7.18893 9.38398 7.00004C9.38398 6.81115 9.32287 6.6556 9.20065 6.53337L7.46732 4.80004C7.33398 4.66671 7.17843 4.60004 7.00065 4.60004C6.82287 4.60004 6.66732 4.66671 6.53398 4.80004L4.80065 6.53337C4.67843 6.6556 4.61732 6.81115 4.61732 7.00004C4.61732 7.18893 4.67843 7.34448 4.80065 7.46671C4.92287 7.58893 5.07843 7.65004 5.26732 7.65004C5.45621 7.65004 5.61176 7.58893 5.73398 7.46671L6.33398 6.86671ZM7.00065 13.6667C6.07843 13.6667 5.21176 13.4917 4.40065 13.1417C3.58954 12.7917 2.88398 12.3167 2.28398 11.7167C1.68398 11.1167 1.20898 10.4112 0.858984 9.60004C0.508984 8.78893 0.333984 7.92226 0.333984 7.00004C0.333984 6.07782 0.508984 5.21115 0.858984 4.40004C1.20898 3.58893 1.68398 2.88337 2.28398 2.28337C2.88398 1.68337 3.58954 1.20837 4.40065 0.858374C5.21176 0.508374 6.07843 0.333374 7.00065 0.333374C7.92287 0.333374 8.78954 0.508374 9.60065 0.858374C10.4118 1.20837 11.1173 1.68337 11.7173 2.28337C12.3173 2.88337 12.7923 3.58893 13.1423 4.40004C13.4923 5.21115 13.6673 6.07782 13.6673 7.00004C13.6673 7.92226 13.4923 8.78893 13.1423 9.60004C12.7923 10.4112 12.3173 11.1167 11.7173 11.7167C11.1173 12.3167 10.4118 12.7917 9.60065 13.1417C8.78954 13.4917 7.92287 13.6667 7.00065 13.6667Z" fill="#1B5B44" />
                           </svg>
                           <svg width="14" height="14" class="lh-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="stock.percentageChange < 0" >
                              <path d="M7.66602 7.13329V4.99996C7.66602 4.81107 7.60213 4.65274 7.47435 4.52496C7.34657 4.39718 7.18824 4.33329 6.99935 4.33329C6.81046 4.33329 6.65213 4.39718 6.52435 4.52496C6.39657 4.65274 6.33268 4.81107 6.33268 4.99996V7.13329L5.73268 6.53329C5.61046 6.41107 5.4549 6.34996 5.26602 6.34996C5.07713 6.34996 4.92157 6.41107 4.79935 6.53329C4.67713 6.65552 4.61602 6.81107 4.61602 6.99996C4.61602 7.18885 4.67713 7.3444 4.79935 7.46663L6.53268 9.19996C6.66602 9.33329 6.82157 9.39996 6.99935 9.39996C7.17713 9.39996 7.33268 9.33329 7.46602 9.19996L9.19935 7.46663C9.32157 7.3444 9.38268 7.18885 9.38268 6.99996C9.38268 6.81107 9.32157 6.65552 9.19935 6.53329C9.07713 6.41107 8.92157 6.34996 8.73268 6.34996C8.54379 6.34996 8.38824 6.41107 8.26602 6.53329L7.66602 7.13329ZM6.99935 0.333294C7.92157 0.333294 8.78824 0.508294 9.59935 0.858294C10.4105 1.20829 11.116 1.68329 11.716 2.28329C12.316 2.88329 12.791 3.58885 13.141 4.39996C13.491 5.21107 13.666 6.07774 13.666 6.99996C13.666 7.92218 13.491 8.78885 13.141 9.59996C12.791 10.4111 12.316 11.1166 11.716 11.7166C11.116 12.3166 10.4105 12.7916 9.59935 13.1416C8.78824 13.4916 7.92157 13.6666 6.99935 13.6666C6.07713 13.6666 5.21046 13.4916 4.39935 13.1416C3.58824 12.7916 2.88268 12.3166 2.28268 11.7166C1.68268 11.1166 1.20768 10.4111 0.857683 9.59996C0.507684 8.78885 0.332684 7.92218 0.332684 6.99996C0.332684 6.07774 0.507684 5.21107 0.857683 4.39996C1.20768 3.58885 1.68268 2.88329 2.28268 2.28329C2.88268 1.68329 3.58824 1.20829 4.39935 0.858294C5.21046 0.508294 6.07713 0.333294 6.99935 0.333294Z" fill="#C70707" />
                           </svg>
                        </span>
                     </p>
                  </div>
               </td>
            </tr>
         </ng-container>
         <tr class="tbody-overlay" *ngIf="!canReadSuperInvestorPortfolio" >
            <td colspan="7" class="align-middle">
               <button class="btn mx-auto shadow-none rounded-pill card-btn" (click)="accessControlService.accessDenied()" *ngIf="!isLoggedIn" >
                  <img src="/assets/images/complanise-locked-black.svg" width="16" height="16" alt="lock image" class="lock-image-black" />
                  <!-- <span>Sign up & upgrade to unlock</span> -->
                  <span>Unlock</span>
               </button>
               <button class="btn mx-auto shadow-none rounded-pill card-btn" (click)="openPaywallModal()" *ngIf="isLoggedIn" >
                  <img src="/assets/images/complanise-locked-black.svg" width="16" height="16" alt="lock image" class="lock-image-black" />
                  <!-- <span>Upgrade & unlock</span> -->
                  <span>Unlock</span>
               </button>
            </td>
         </tr>
      </ng-container>
      <ng-container *ngIf="!tableLoader && (!investorStocks || investorStocks.length === 0)">
         <tr class="nostocks-tr">
            <td colspan="7" class="align-middle">
               <p class="mb-0 d-flex justify-content-center align-items-center">
                  No stocks available
               </p>
            </td>
         </tr>
      </ng-container>
   </tbody>
</table>
