import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringInitials'
})
export class StringInitialsPipe implements PipeTransform {

   transform(text: string): string {
      if (!text) {
         return '';
      }
      const initials = text.match(/\b\w/g)?.join("") || '';
      return initials.slice(0, 2).toUpperCase();
   }

}