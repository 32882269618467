import { Component, Input, OnInit } from '@angular/core';
import { PortfolioService } from '../../../services/portfolio.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
   selector: 'app-reconnect-brokerage-btn',
   templateUrl: './reconnect-brokerage-btn.component.html',
   styleUrls: ['./reconnect-brokerage-btn.component.scss'],
})
export class ReconnectBrokerageBtnComponent implements OnInit {
   loader: boolean = false;
   connectBrokerageLink: string = '';
   @Input() brokerageConnectionStatus: string = 'CONNECTING';
   @Input() brokerageId!: string;

   constructor(private portfolioService: PortfolioService) {}

   ngOnInit(): void {}

   reconnectBrokenBrokerage() {
      this.loader = true;
      this.portfolioService
         .getReconnectUrl(this.brokerageId, true)
         .subscribe((resp: any) => {
            if (resp.status) {
               this.loader = false;
               this.portfolioService.openConnectBrokerageIframeModal(
                  resp.data.redirect_url
               );
            }
            this.loader = false;
         }),
         (error: HttpErrorResponse) => {
            console.log(error);
            this.loader = false;
         };
   }
}
