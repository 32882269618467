<section class="content-padding bg-white">
   <div class="container">
      <h2 class="text-center section-main-heading">Explore Our Investment Tools</h2>
      <ul role="list" class="gap-3 gap-lg-4 gap-xl-5 tool-cards-container">
         <li *ngFor="let tool of tradingTools; trackBy: trackByFn" class="d-block tool-card">
            <a class="text-decoration-none text-center" [routerLink]="[tool.link_url]" >
               <div class="tool-image-wrapper">
                  <img [src]="tool.image_url" [alt]="tool.heading " class="d-block mx-auto img-fluid">
               </div>
               <h3 class="lh-1 tool-card-heading px-3 px-xxl-4">{{ tool.heading }}</h3>
               <p class="mb-0 tool-card-description d-none d-md-block pb-2 pb-md-3 pb-xl-4 px-sm-3 px-xxl-4">{{ tool.description }}</p>
            </a>
         </li>
      </ul>
   </div>
</section>
