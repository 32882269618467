import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { StocksSuperInvestor } from 'src/app/models/superInvestor.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { PaywallPopupComponent } from '../../paywall-popup/paywall-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
  selector: 'app-superinvestors-stock-overview-list',
  templateUrl: './superinvestors-stock-overview-list.component.html',
  styleUrls: ['./superinvestors-stock-overview-list.component.scss']
})

export class SuperinvestorsStockOverviewListComponent implements OnInit, OnChanges {
   private destroy$: Subject<void> = new Subject<void>();
   listLoader: boolean = true;
   superInvestorsList: StocksSuperInvestor[] = [];
   stockSymbol: string = ''
   displaySymbol: string = ''
   filteredSuperInvestorsList: StocksSuperInvestor[] = [];
   viewAll: boolean = false;
   userRole: string | null = null;
   private subscriptions: Subscription[] = [];
   @Input() queryParam!: string
   @Input() viewRoute: string = ''
   paywallModalRef: any;

   constructor(
      private modalService: NgbModal,
      private analyticsUtils:AnalyticsUtils,
      private typesenseService: TypesenseService,
      private route: ActivatedRoute,
      private router: Router,
      public authService: AuthService,
      private sharedService: SharedService,
      public accessControlService: AccessControlService
   ) { }

   ngOnInit(): void {
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: any) => {
         this.stockSymbol = params['id'];
         if (this.stockSymbol) {
            this.getSuperInvestorsStocks(this.stockSymbol);
         }
      });


      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );

      // if (this.queryParam) {
      //    this.viewAll = true;
      //    this.getSuperInvestorsStocks(this.queryParam);
      // }

   }

   ngOnChanges(changes: SimpleChanges): void {
      if(changes.queryParam.currentValue) {
         this.viewAll = true;
         this.getSuperInvestorsStocks(changes.queryParam.currentValue);
      }
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   async getSuperInvestorsStocks(symbol: string) {
      this.displaySymbol = symbol
      this.listLoader = true;
      try {
         const data = await this.typesenseService.getSuperInvestorsByStocks('*', symbol, 250, 1, 'value:desc');
         const hits = data.hits;

         const mapFromCikIds = new Map(
            hits.map((hit: any) => [hit.document.cik_id, hit])
         )
         const uniqueIds =  [...mapFromCikIds.values()];

         const superInvestorsStocks = uniqueIds.map((stock: any) => ({
            cik: stock.document.cik_id,
            percentageChange: stock.document.percentage_change,
            percentage: stock.document.percentage
         }));

         const superInvestorsIds = superInvestorsStocks.map((investor: any) => investor.cik);
         const superInvestorsInfo = await this.typesenseService.getSuperInvestorsDataFromIds(superInvestorsIds);

         const list = superInvestorsStocks.map((investor: any) => {

            const additionalData = superInvestorsInfo.hits
               .map((hit: any) => hit.document)
               .find((data: any) => data.cik === investor.cik)

            return {
               ...investor,
               name: additionalData.manager,
               profileImage: additionalData.profileImg,
               halalPercentage: additionalData.halal_percentage,
               totalAmount: additionalData.total_amount
            };
         });

         this.superInvestorsList = list.sort((a: StocksSuperInvestor, b: StocksSuperInvestor) => {
            return b.percentage - a.percentage;
         });

         this.filteredSuperInvestorsList = this.filterSuperInvestors(this.superInvestorsList)

      } catch (error) {
        console.error(error);
      } finally {
        this.listLoader = false;
      }
    }

   getPercentChangeClasses(stock: any) {
      return {
         'green' : stock.percentageChange >= 0,
         'red' : stock.percentageChange < 0
      }
   }

   filterSuperInvestors(investors: StocksSuperInvestor[] ): StocksSuperInvestor[] {
      if(this.userRole !== 'PREMIUM' ) {
         return investors.slice(0, 4)
      }
      else if (!this.viewAll) {
         return investors.slice(0, 6);
      } else {
         return investors;
      }
   }

   openPaywallModal() {
      this.sharedService.openUpgradePopup()
      // this.paywallModalRef = this.modalService.open(PaywallPopupComponent, {
      //   centered: true,
      //   modalDialogClass: 'paywall-popup-modal',
      //   size: 'xl'
      // });
    }

   @HostBinding('class')
   get superInvestorsStyle(): string {
      if (this.viewRoute === 'view-all') {
        return 'view-all-style';
      } else {
        return '';
      }
   }

}

