import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'profileText' })

export class ProfileTextPipe implements PipeTransform {
  transform(value: string, showText: boolean): string {
    if (!showText && value && value.length > 200) {
      return value.substring(0,200);
    } else {
      return value;
    }
  }
}



