import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ETFNews, ETFNewsResponse } from 'src/app/models/news/etf-news.model';
import { NewsService } from 'src/app/shared/services/news.service';

@Component({
  selector: 'app-etf-news',
  templateUrl: './etf-news.component.html',
  styleUrls: ['./etf-news.component.scss']
})
export class EtfNewsComponent implements OnInit {
   private destroy$ = new Subject<void>();
   isMobile: boolean = false;
   etfsLoader: boolean = false;
   allSymbols: string[] = [];
   newsData: ETFNews[] = []

   constructor(
      private newsService: NewsService,
      private observer: BreakpointObserver
   ) {}

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.newsService.etfSymbols$.pipe(takeUntil(this.destroy$)).subscribe(data => {
         const symbols = data.map((etf: any) => etf.symbol)
         this.allSymbols = Array.from(new Set(symbols));

         if(this.allSymbols.length > 0) {
            this.fetchETFNews(this.allSymbols);
         }


      })
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByNewsId(index: number, news: ETFNews) {
      return news.Id;
   }

   async fetchETFNews(symbols: string[]) {
      try {
         this.etfsLoader = true
         const resp = await this.newsService.fetchETFNews<ETFNewsResponse>(symbols)
         if(resp.status) {
            const uniqueNews = Array.from(new Map(resp.data.map(news => [news.Id, news])).values());
            const allNews = uniqueNews.sort((a: ETFNews, b: ETFNews) => b.Datetime - a.Datetime);
            this.newsData = allNews;
         }

      } catch (error) {
         this.etfsLoader = false
      } finally {
         this.etfsLoader = false
      }
   }

   formattedDate(date: any): Date {
      return new Date(date * 1000);
   }

}
