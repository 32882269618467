<section class="bg-white ">
   <div class="container content-padding">
      <header class="d-flex align-items-center justify-content-between gap-3 section-header">
         <h2 class="mb-0 section-main-heading">Stock News</h2>
         <!-- <a class="btn d-flex align-items-center gap-2 text-nowrap py-2 px-3 view-all-btn" *ngIf="!isMobile"  routerLink="/news">
            <span>Read More News</span>
            <i class="bi bi-arrow-right arrow-icon"></i>
         </a> -->
      </header>
      
      <div class="loader-grid" *ngIf="stocksLoader">
         <div class="loader-item"  *ngFor="let i of [1,2,3,4,5,6,7,8]">
            <div class="skeleton-loader rounded-3 image-loader"></div>
            <div class="d-grid gap-2">
               <span class="skeleton-loader rounded-3 d-block headline-loader"></span>
               <span class="skeleton-loader rounded-3 d-block summary-loader"></span>
               <span class="skeleton-loader rounded-3 d-block summary-loader"></span>
            </div>
         </div>
      </div>
      
      <ng-container *ngIf="!stocksLoader">
         <div class="news-grid-container">
            <a class="d-block text-decoration-none" *ngFor="let news of newsData; trackBy: trackByNewsId" target="_blank" [href]="news.Url">
               <article class="d-flex align-items-start gap-2 gap-lg-3 news-card">
                  <div class="bg-light flex-shrink-0 w-100 overflow-hidden news-image-wrapper">
                     <img class="img-fluid news-image" [appLazyLoadImage]="news.Image" placeholder="/assets/images/dashboard/news-lowres.png" [src]="news.Image" [alt]="news.Headline" *ngIf="news.Image; else noImage">
                     <ng-template #noImage>
                        <img class="img-fluid news-image" appLazyLoadImage="/assets/images/dashboard/stock-news.jpg" placeholder="/assets/images/dashboard/news-lowres.png" [alt]="news.Headline">
                     </ng-template>
                  </div>
                  <div class="flex-grow-1">
                     <h6 class="mb-0 news-headline line-clamp-2">
                        {{ news.Headline }}
                     </h6>
                     <p class="my-2 news-description line-clamp-2">
                        {{ news.Summary }}
                     </p>
                     <p class="mb-0 news-date">
                        By {{ news.Source }} | {{ formattedDate(news.Datetime) | date : "mediumDate" }}
                     </p>
                  </div>
               </article>
            </a>
         </div>
         
         <!-- <a class="btn d-flex align-items-center gap-2 mx-auto text-nowrap py-2 px-3 view-all-btn mt-4" *ngIf="isMobile" routerLink="/news">
            <span>Read More News</span>
            <i class="bi bi-arrow-right arrow-icon"></i>
         </a> -->
         
      </ng-container>
   </div>
</section>
