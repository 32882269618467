<ng-container *ngIf="userRole !== 'ANONYMOUS' || listType === 'all'">
   <div class="p-2" *ngIf="listLoader">
      <ngx-skeleton-loader
         count="10" appearance="line" animation="progress"
         [theme]="{ height: '50px', width: '100%', 'border-radius': '8px', margin: '0px' }">
      </ngx-skeleton-loader>
   </div>

   <ng-container *ngIf="!listLoader">
      <ng-container *ngIf="lists && lists.length > 0">
         <!-- for screens lg and up -->
         <div class="table-responsive" *ngIf="!isMobile">
            <table class="table lg-and-up-table mb-0">
               <thead>
                  <tr>
                     <th scope="col">No.</th>
                     <th scope="col">Name</th>
                     <th scope="col">Created By</th>
                     <th scope="col" class="text-center">
                        <div class="d-flex align-items-center justify-content-center gap-2" >
                           <span>No. of Followers </span>
                        </div>
                     </th>
                     <th scope="col" class="text-center">
                        <div class="d-flex align-items-center justify-content-center gap-2" >
                           <span>No. of Stocks </span>
                        </div>
                     </th>
                     <th scope="col" class="text-center">Stocks</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor=" let list of lists; index as i; trackBy: trackByListId " >
                     <td class="align-middle">
                        <a class="text-decoration-none fw-normal clr-black list-number" routerLink="/list-details/{{ makeSlug(list) }}" >
                           {{ getSerialNumber(i) }}.
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="text-decoration-none clr-black text-capitalize list-name" routerLink="/list-details/{{ makeSlug(list) }}" >
                           <span class="line-clamp-1">{{ list.name }}</span>
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="text-decoration-none list-creator-name" routerLink="/list-details/{{ makeSlug(list) }}" >
                           <span class="text-capitalize line-clamp-1" *ngIf="list.user?.firstname" >
                              {{ list.user?.firstname }} {{ list.user?.lastname }}</span >
                           <span *ngIf="!list.user?.firstname">-</span>
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="text-decoration-none justify-content-center list-followers-count" routerLink="/list-details/{{ makeSlug(list) }}" >
                           {{ list.followers_count }}
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="text-decoration-none justify-content-center clr-black list-stocks-count" routerLink="/list-details/{{ makeSlug(list) }}" >
                           {{ list.public_list_holdings_count }}
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="d-flex align-items-center justify-content-center gap-1 text-decoration-none" routerLink="/list-details/{{ makeSlug(list) }}" >
                           <ng-container *ngIf=" list.public_list_holdings_count > 0; else noHoldings " >
                              <ul role="list" class="d-flex align-items-center justify-content-end stocks-list" >
                                 <li class="bg-light d-flex align-items-center justify-content-center overflow-hidden" *ngFor=" let holding of list.public_list_holdings.slice( 0, 5 ) " >
                                    <img [src]="holding.logo_url" [alt]="holding.holding_name" class="d-block img-fluid" *ngIf="holding.logo_url" />
                                    <span class="d-flex align-items-center justify-content-center py-1 fs-6 text-black fw-bold" *ngIf="!holding.logo_url" >
                                       {{ holding.holding_name?.charAt(0) }}
                                    </span>
                                 </li>
                              </ul>
                              <span *ngIf="list.public_list_holdings_count > 5" class="d-block text-end more-span" >
                                 +{{ list.public_list_holdings_count - 5 }} more
                              </span>
                           </ng-container>
                           <ng-template #noHoldings>
                              <span class="d-block text-end more-span" >0 stocks</span >
                           </ng-template>
                        </a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>

         <!-- for screens below lg -->
         <div class="table-responsive" *ngIf="isMobile">
            <table class="table lg-and-below-table mb-0">
               <tbody>
                  <tr *ngFor=" let list of lists; index as i; trackBy: trackByListId " >
                     <td class="align-middle">
                        <a class="text-decoration-none d-flex flex-column justify-content-start" routerLink="/list-details/{{ makeSlug(list) }}" >
                           <span class="line-clamp-1 lh-base clr-black text-capitalize list-name" >{{ list.name }}</span >
                           <span class="line-clamp-1 lh-base clr-black text-capitalize list-creator-name" >{{ list.user?.firstname }} {{ list.user?.lastname }}</span >
                           <span class="list-followers-count lh-base" >{{ list.followers_count }} Followers</span >
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="text-decoration-none d-flex flex-column align-items-center justify-content-center clr-black fw-500 list-stocks-count" routerLink="/list-details/{{ makeSlug(list) }}" >
                           <span class="lh-base">{{ list.public_list_holdings_count }}</span>
                           <span class="lh-base">stocks</span>
                        </a>
                     </td>
                     <td class="align-middle">
                        <a class="d-flex flex-column align-items-center justify-content-center gap-1 text-decoration-none" routerLink="/list-details/{{ makeSlug(list) }}" >
                           <ng-container *ngIf=" list.public_list_holdings_count > 0; else noHoldings " >
                              <ul role="list" class="d-flex align-items-center justify-content-end stocks-list" >
                                 <li class="bg-light d-flex align-items-center justify-content-center overflow-hidden" *ngFor=" let holding of list.public_list_holdings.slice( 0, 3 ) " >
                                    <img [src]="holding.logo_url" [alt]="holding.holding_name" class="d-block img-fluid" *ngIf="holding.logo_url" />
                                    <span class="d-flex align-items-center justify-content-center fs-6 py-1 text-black fw-bold" *ngIf="!holding.logo_url" >
                                       {{ holding.holding_name?.charAt(0) }}
                                    </span>
                                 </li>
                              </ul>
                              <span *ngIf="list.public_list_holdings_count > 3" class="d-block text-end more-span" >
                                 +{{ list.public_list_holdings_count - 3 }} more
                              </span>
                           </ng-container>
                           <ng-template #noHoldings>
                              <span class="d-block text-end more-span" >0 stocks</span >
                           </ng-template>
                        </a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>

         <div class="mt-4">
            <ngb-pagination
               [collectionSize]="totalItems"
               [(page)]="page"
               [pageSize]="perPage"
               [maxSize]="3"
               (pageChange)="navigateToPage($event)"
            >
            </ngb-pagination>
         </div>
      </ng-container>

      <ng-container *ngIf="lists.length === 0">
         <div class="d-flex flex-column align-items-center justify-content-center no-list-container" >
            <img src="/assets/images/no-holdings.svg" class="img-fluid" alt="No Data Image" />
            <h5 class="mb-0 text-center" *ngIf="listType === 'followed'">
               Looks empty! Start following some collections for them to appear here.
            </h5>
            <h5 class="mb-0 text-center" *ngIf="listType === 'created'">
               Looks empty! Create some collections for them to appear here.
            </h5>
         </div>
      </ng-container>
   </ng-container>
</ng-container>

<ng-container *ngIf="userRole === 'ANONYMOUS' && listType !== 'all'">
   <div class="d-flex flex-column align-items-center px-4 justify-content-center no-list-container" >
      <img src="/assets/images/list-signup.svg" class="img-fluid" alt="No Data Image" />
      <h5 class="mb-0 text-center" *ngIf="listType === 'followed'">
         Sign up to start following user curated collections
      </h5>
      <h5 class="mb-0 text-center" *ngIf="listType === 'created'">
         Sign up to start creating & publishing collections
      </h5>
      <a class="btn rounded-pill d-block text-decoration-none text-white sign-up-btn" [routerLink]="['/authentication/login']" >
         Sign up for free
      </a>
   </div>
</ng-container>
