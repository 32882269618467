import { BreakpointObserver } from '@angular/cdk/layout';
import {
   ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output,
   AfterViewInit, ElementRef, Renderer2, ViewChildren, QueryList
} from '@angular/core';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { CollectionBucket } from 'src/app/features/halal-collection/models/buckets-collection-api-response.model';
import { HalalCollectionService } from 'src/app/features/halal-collection/services/halal-collection.service';
import { CountryService } from '../../services/country.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
   selector: 'app-bucket-collection-chips',
   templateUrl: './bucket-collection-chips.component.html',
   styleUrls: ['./bucket-collection-chips.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BucketCollectionChipsComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   isMobile: boolean = false;

   bucketLoader!: boolean;
   allBuckets: CollectionBucket[] = [];
   buckets1: CollectionBucket[] = [];
   buckets2: CollectionBucket[] = [];
   buckets3: CollectionBucket[] = [];
   selectedCountryBuckets: CollectionBucket[] = [];
   selectedBucket: CollectionBucket | undefined = undefined;
   selectedCollection!: string;
   @Input() selectedCountry!: string;
   @Input() componentPath!: 'home' | 'collection';
   @Input() areBucketsVisible!: boolean;

   @ViewChildren('scroller') scrollers!: QueryList<ElementRef>;

   constructor(
      private observer: BreakpointObserver,
      private cdr: ChangeDetectorRef,
      private halalCollectionService: HalalCollectionService,
      private countryService: CountryService,
      private router: Router,
      private route: ActivatedRoute,
      private renderer: Renderer2
   ) { }

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      if (this.componentPath === 'home') {
         this.countryService.selectedCountry$.pipe(takeUntil(this.destroy$)).subscribe((country) => {
            this.selectedCountry = country.countryCode;
            this.getCollectionBuckets(this.selectedCountry);
            this.selectedCountryBuckets = this.selectedCountryBuckets.slice(0, 50);
         });
      }

      if (this.componentPath === 'collection') {
         this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
            this.selectedCountry = params.get('country') as string;
            this.selectedCollection = params.get('collection') as string;
            this.getCollectionBuckets(this.selectedCountry, this.selectedCollection);
         })
      }

   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   trackByFn(index: number, item: any): any {
      return item.id;
   }

   // bucketImages: { [key: string]: string } = {
   //    'Technology': '../../../../../../assets/images/home/hstocks/technology-solutions.svg',
   //    'Semiconductor stocks': '../../../../../../assets/images/home/hstocks/Semiconductor.svg',
   //    'Industrials': '../../../../../../assets/images/home/hstocks/industrials.svg',
   //    'Financials': '../../../../../../assets/images/home/hstocks/financials.svg',
   //    'Energy': '../../../../../../assets/images/home/hstocks/energy.svg',
   //    'Health Care': '../../../../../../assets/images/home/hstocks/Health.svg',
   //    'Consumer Goods': '../../../../../../assets/images/home/hstocks/consumer-behaviour.svg',
   //    'Communications': '../../../../../../assets/images/home/hstocks/communications.svg',
   //    'Real Estate': '../../../../../../assets/images/home/hstocks/real-state.svg',
   //    'Biotechnology': '../../../../../../assets/images/home/hstocks/biotechnology.svg'
   //  };


   getCollectionBuckets(countryCode: string, collectionId?: string) {
      this.bucketLoader = true;
      this.halalCollectionService.getCollectionBuckets().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            this.allBuckets = response
            // .filter((bucket) => bucket.type !== 'etf')
            this.selectedCountryBuckets = this.allBuckets.filter((bucket) => bucket.countries.includes(countryCode));

            if (this.componentPath === 'collection') {
               if (!collectionId) {
                  this.navigateToRoute(this.selectedCountry, this.selectedCountryBuckets[0]?.id)
               } else {
                  const isCollectionAvailable = this.selectedCountryBuckets.some((bucket) => bucket.id === collectionId)
                  if (!isCollectionAvailable) {
                     this.navigateToRoute(this.selectedCountry, this.selectedCountryBuckets[0]?.id)
                  }
               }
            } else {
               this.buckets1 = [...this.selectedCountryBuckets].sort((a, b) => {
                  const aIndex = this.selectedCountryBuckets.indexOf(a);
                  const bIndex = this.selectedCountryBuckets.indexOf(b);
                  return ((aIndex + 0) % this.selectedCountryBuckets.length) - ((bIndex + 0) % this.selectedCountryBuckets.length);
               });

               this.buckets2 = [...this.selectedCountryBuckets].sort((a, b) => {
                  const aIndex = this.selectedCountryBuckets.indexOf(a);
                  const bIndex = this.selectedCountryBuckets.indexOf(b);
                  return ((aIndex + 4) % this.selectedCountryBuckets.length) - ((bIndex + 4) % this.selectedCountryBuckets.length);
               });

               this.buckets3 = [...this.selectedCountryBuckets].sort((a, b) => {
                  const aIndex = this.selectedCountryBuckets.indexOf(a);
                  const bIndex = this.selectedCountryBuckets.indexOf(b);
                  return ((aIndex + 9) % this.selectedCountryBuckets.length) - ((bIndex + 9) % this.selectedCountryBuckets.length);
               });
            }

            this.bucketLoader = false;
            this.cdr.markForCheck();
         },
         error: (error) => {
            console.log(error)
            this.bucketLoader = false
            this.cdr.markForCheck();
         }
      })
   }

   private navigateToRoute(country: string, collection: string) {
      this.router.navigate(['/halal-collection'],
         {
            queryParams: {
               country: country,
               collection: collection,
               page: 1
            },
            queryParamsHandling: 'merge'
         }
      );
   }


   ngAfterViewInit() {
      this.scrollers.forEach(scroller => {
         this.renderer.setAttribute(scroller.nativeElement, 'data-animated', 'true');
         const scrollerInner = scroller.nativeElement.querySelector('.scroller-inner');
         const scrollerContent = Array.from(scrollerInner.children);

         scrollerContent.forEach((item: any) => {
            const duplicatedItem = item.cloneNode(true);
            this.renderer.setAttribute(duplicatedItem, 'aria-hidden', 'true');
            this.renderer.appendChild(scrollerInner, duplicatedItem);
         });
      });
   }

}
