import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';

@Component({
   selector: 'app-stocks-table',
   templateUrl: './stocks-table.component.html',
   styleUrls: ['./stocks-table.component.scss']
})

export class StocksTableComponent implements OnInit {
   private destroy$ = new Subject<void>();
   @Input() showTitle: boolean = true
   @Input() tableId!: string;
   @Input() title!: string;
   @Input() data!: any[]
   @Input() loading!: boolean
   @Input() redirectUrl: string = ''
   // @Input() state: number | string | null = null;
   userRole: string | null = null;
   isLoggedIn: boolean = false;
   canReadTrendingStocks: boolean = false;
   canReadStockComplianceRating: boolean = false;

   constructor(
      public auth: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService,
      private router: Router
   ) { }

   ngOnInit(): void {
      // SubscriptionService.subscriptionData
      //    .pipe(takeUntil(this.destroy$))
      //    .subscribe((data: SubscriptionInfoModel) => {
      //       this.userRole = data.role;
      //    });
      this.isLoggedIn = !!this.auth.getUserId();
      this.permissionStore.select(({permissions}) => permissions.permissions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
         (permissions) => {
            this.isLoggedIn = !!this.auth.getUserId();
            this.canReadTrendingStocks = permissions.trending_halal_stocks;
            this.canReadStockComplianceRating = permissions.stock_halal_rating;
         }
      )
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }


   trackByStockId(index: number, stock: any) {
      return stock?.identifier;
   }

   // navigateToUrl() {
   //    if (this.redirectUrl) {
   //       this.router.navigate([this.redirectUrl]);
   //    }

   // }

   getPercentChangeClasses(stock: any) {
      return {
         'positive' : stock?.percentChange >= 0,
         'negative' : stock?.percentChange < 0
      }
   }

   getIconClasses(stock: any) {
      return {
         'bi-caret-up-fill positive' : stock?.percentChange >= 0,
         'bi-caret-down-fill negative' : stock?.percentChange < 0,
      }
   }

   filtereredStocks(): any[] {
      if (!this.canReadTrendingStocks) {
         return this.data.slice(0, 3);
      } else {
         return this.data;
      }
   }

}
