<a class="super-investor-card text-decoration-none p-3 d-flex align-items-center justify-content-between gap-3"
   routerLink="/super-investor-portfolio/{{investor.cik}}">
   <div class="d-flex align-items-center gap-3">
      <img *ngIf="investor.profileImg" src="{{investor.profileImg}}"
         class="rounded-circle bg-light border investor-image" alt="">
      <div *ngIf="!investor.profileImg"
         class="investor-image rounded-circle border-3 border-white bg-light fw-bold d-flex align-items-center justify-content-center fs-3">
         {{ investor.manager.substr(0, 1) }}</div>
      <div>
         <h6 class="super-investor-name" [innerHTML]="investor.manager | highlightSearch: highlightSearch"></h6>
         <p class="mt-2 mb-0 grey-text lh-1 amount">Amount: <span class="fw-normal">${{investor?.total_amount |
               number}}</span></p>
      </div>
   </div>
   <app-halal-circular-progress-card [cardType]="'small-green'"
      [percentage]="investor?.halal_percentage"></app-halal-circular-progress-card>
</a>