<aside class="compliance-history-container">
   <header class="d-flex align-items-start bg-white justify-content-between px-3 pt-3 pb-2 px-lg-4 pt-lg-4">
      <div class="d-flex flex-column">
         <h3 class="mb-1">Current Shariah Compliance</h3>
         <p class="mb-0">Screening Methodology: <strong class="fw-600">AAOIFI</strong></p>
      </div>
      <button type="button" class="btn ms-auto flex-shrink-0 shadow-none rounded-3 bg-light p-1 close-btn" (click)="closeComplianceHistory()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#0e1c36" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
   </header>

   <section>
      <article class="d-grid gap-3 gap-lg-4 p-3 p-lg-4">
         <div class="rounded-3 compliance-status-chip" [ngClass]="getComplianceClasses(currentComplianceStatus)">
            <span class="d-block">{{ getComplianceText(currentComplianceStatus) }}</span>

            <div class="d-flex align-items-center justify-content-center gap-2" *ngIf="currentComplianceStatus === 'COMPLIANT'">
               <ng-container *ngFor="let i of [1,2,3,4,5]">
                  <svg class="star-filled-svg" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="currentComplianceRating >= i">
                     <path d="m10.487 0 2.641 6.814 7.297.406-5.665 4.617 1.869 7.065-6.142-3.96-6.141 3.96 1.868-7.065L.55 7.22l7.296-.406z" fill="#009000"/>
                  </svg>
               </ng-container>

               <ng-container *ngFor="let i of [1,2,3,4,5]">
                  <svg class="star-outlined-svg" width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="currentComplianceRating < i">
                     <path d="m10.743 1.446 2.154 5.557.121.314.337.018 5.95.332-4.62 3.765-.26.213.086.326 1.524 5.761-5.009-3.23-.283-.182-.283.183-5.009 3.23 1.524-5.762.086-.326-.261-.213-4.62-3.765 5.951-.332.336-.018.122-.314z" stroke="#009000" stroke-width="1.045"/>
                  </svg>
               </ng-container>
            </div>
         </div>

         <div class="rounded-3 last-updated-grid p-3 px-lg-4">
            <p class="mb-0">
               Last Updated: <br>
               <span>{{ lastUpdated }}</span>
            </p>
            <p class="mb-0">
               Report Source: <br>
               <span>{{ reportSource }}</span>
            </p>
         </div>
      </article>

      <div class=" p-3 p-lg-4 history-list-container">
         <h3 class="history-heading">Compliance History</h3>

         <ngx-skeleton-loader
            *ngIf="historyLoader"
            count="3" appearance="line" animation="progress"
            [theme]="{ height: '100px', width: '100%', margin: 'auto'}">
         </ngx-skeleton-loader>

         <ul role="list" class="compliance-history-list" *ngIf="!historyLoader">
            <li class="compliance-history-item" *ngFor="let compliance of compliances">
               <svg class="history-svg" [ngClass]="getComplianceClasses(compliance.compliance_status)" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect class="rect-fill" x="2.5" y="2.5" width="21" height="21" rx="10.5" fill="currentColor"/>
                  <rect class="rect-stroke" x="2.5" y="2.5" width="21" height="21" rx="10.5" stroke="currentColor" stroke-width="5"/>
                  <circle cx="13" cy="13" r="3.5" fill="#fff"/>
               </svg>

               <article class="compliance-history-card overflow-hidden" [ngClass]="getComplianceClasses(compliance.compliance_status)">
                  <div class="d-flex align-items-center justify-content-between inner-card-wrapper">
                     <div class="p-3">
                        <span class="d-block fw-600 year-span">{{ compliance.start_date | date: 'MMM yyyy' }} to {{ compliance.end_date | date: 'MMM yyyy' }}</span>
                        <p class="mb-0 quarter-text">Report Source: {{ compliance.reported_year }} {{ getQuarterText(compliance.reported_quarter) }} Report</p>
                     </div>
                     <div>
                        <span class="d-block ms-auto history-chip" [ngClass]="getComplianceClasses(compliance.compliance_status)">
                           {{ getComplianceText(compliance.compliance_status) }}
                        </span>
                        <!-- <p class="mb-0 ms-auto date-text">{{ compliance.report_date | date : 'mediumDate'  }}</p> -->
                     </div>
                  </div>
               </article>
            </li>
         </ul>
      </div>

   </section>
</aside>

<div class="compliance-history-backdrop" (click)="closeComplianceHistory()"></div>

