<div class="breakdown-card"
[ngClass]="{
   'halal' : type == 'halal',
   'not-halal' : type.includes('not_halal'),
   'doubtful' : type == 'doubtful'
 }"
 
>
    <div class="p-0">
       <div
          class=" py-3 px-3 text-white d-flex align-items-center justify-content-between h6 mb-0"
          [ngClass]="{
            'halal-distribution-section' : type == 'halal',
            'not-halal-distribution-section' : type.includes('not_halal'),
            'doubtful-distribution-section' : type == 'doubtful'
          }"
       >
          <span class="title">{{title}}</span>
          <span class="percent">{{getOverallValue()}}</span>
       </div>

       <ngb-accordion
          #acc="ngbAccordion"
          *ngIf="items.length"
          class="px-3 pt-3 distribution-accordion"
       >
          <ngb-panel
          *ngFor="let obj of items;let i = index;"
          [disabled]="!getComments(obj).length"
          class="distribution-panel-container"
          >
             <ng-template ngbPanelHeader>

                <div class="distribution-panel halal-panel"
                    [ngClass]="{
                        'halal-panel' : type == 'halal',
                        'not-halal-panel' : type.includes('not_halal'),
                        'doubtful-panel' : type == 'doubtful',
                        'mt-2': i >0
                    }"
                >
                   <div class="p-0 bg-transparent" id="headingOne">
                     <h2 class="mb-0">
                       <button ngbPanelToggle  class="btn w-100 btn-link btn-block d-flex align-items-center shadow-none accordion-btn px-3" type="button"
                       [ngClass]="{
                        'accordion-btn-disabled':!getComments(obj).length,
                        'accordion-btn-halal' : type == 'halal',
                        'accordion-btn-not-halal' : type.includes('not_halal'),
                        'accordion-btn-doubtful' : type == 'doubtful'
                       }"
                       >
                         <span class="accordion-btn-title flex-grow-1 text-start">{{ obj?.name }}</span>
                         <span class="pl-3 ml-auto accordion-btn-val">{{ getItemValue(obj) }}</span>
                       </button>
                     </h2>
                   </div>
                </div>
             </ng-template>


                <ng-template ngbPanelContent>
                   <div class="py-3"
                   [ngClass]="{
                        'halal-panel-content' : type == 'halal',
                        'not-halal-panel-content' : type.includes('not_halal'),
                        'doubtful-panel-content' : type == 'doubtful'
                    }"
                   >

                      <div
                        *ngFor="let commentObj of getComments(obj);let i=index;"
                         class="bg-white rounded mx-3 px-2 py-2 comment"
                         [ngClass]="{
                           'mt-3':i>0
                         }"
                      >
                         {{ commentObj?.body }}
                      </div>


                   </div>
                </ng-template>


          </ngb-panel>


       </ngb-accordion>
    </div>
 </div>
