import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FirestoreService } from 'src/app/shared/services/firestore.service';
import { LoaderServices } from 'src/app/shared/services/loader.service';
import { WatchlistService } from 'src/app/shared/services/watchlist.service';

@Component({
   selector: 'app-add-lists-create',
   templateUrl: './add-lists-create.component.html',
   styleUrls: ['./add-lists-create.component.scss'],
})
export class AddListsCreateComponent implements OnInit {
   private subscriptions: Subscription[];
   public form: FormGroup;
   public notifiy: true;
   public checkAllowedToAdd: boolean | undefined;
   @Input() allWatchlists: any;
   showErrorMsg!: boolean;
   loading = false;

   @Input() showList: boolean = false;
   @Output() close: EventEmitter<void> = new EventEmitter();

   @Output() onChange: EventEmitter<void> = new EventEmitter();
   constructor(
      private apiservise: ApiService,
      private auth: AuthService,
      private firestoreService: FirestoreService,
      private toaster: ToastrService,
      private watchlistService: WatchlistService,
      private loader: LoaderServices
   ) {
      this.subscriptions = [];

      this.notifiy = true;
      this.form = new FormGroup({
         name: new FormControl('', [Validators.required]),
      });
   }

   ngOnInit(): void {
      this.isAllowedToAdd();
   }

   ngOnChanges(changes: any): void {
      this.isAllowedToAdd();
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   async isAllowedToAdd() {
      if (this.auth.checkRole('PREMIUM')) {
         this.checkAllowedToAdd = true;
      } else if (this.auth.checkRole('FREEMIUM')) {
         const data = await lastValueFrom(
            this.firestoreService.watchlistList()
         );
         this.checkAllowedToAdd = data.itemCount < 2;
      } else {
         this.checkAllowedToAdd = false;
      }
   }

   validateName() {
      const watchlistNames = this.allWatchlists.map((item: any) =>
         item?.name.toLowerCase()
      );

      if (watchlistNames.includes(this.form.value.name.toLowerCase())) {
         this.showErrorMsg = true;
      } else {
         this.showErrorMsg = false;
      }
      return this.showErrorMsg;
   }

   preProcessText() {
      // this.form.controls['name'].setValue(this.form.value.name.replace(/^\s+|\s+$|\s+(?=\s)/g, ""))
      if (this.form.value.name.startsWith(' ')) {
         this.form.controls['name'].setValue('');
      } else {
         this.form.controls['name'].setValue(
            this.form.controls['name'].value.replaceAll('  ', ' ')
         );
      }
   }

   watchlistCreate() {
      this.form.controls['name'].setValue(
         this.form.value.name.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
      );
      if (this.validateName()) return;

      if (this.form.valid) {
         this.loading = true;
         this.watchlistService
            .createWatchlist(this.form.value.name)
            .then((data: any) => {
               this.toaster.success('Watchlist Created');
               this.isAllowedToAdd();
               this.closeMe();
               this.onChange.emit();
               this.loader.hide();
               this.loading = false;
               this.form.reset();
            })
            .catch((error: HttpErrorResponse) => {
               this.loader.hide();
               this.loading = false;
            });
         // this.subscriptions.push(subscription);
      }
   }

   closeMe() {
      this.close.emit();
   }
}
