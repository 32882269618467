import { Component, OnInit, Input, Output, EventEmitter, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FirestoreService } from 'src/app/shared/services/firestore.service';
import { from, lastValueFrom, Observable } from 'rxjs';
import { MarketUtilsService } from '../../services/market-utils.service';
import { Router } from '@angular/router';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { AccessControlService } from '../../services/access-control.service';
@Component({
  selector: 'app-details-header',
  templateUrl: './details-header.component.html',
  styleUrls: ['./details-header.component.scss']
})
export class DetailsHeaderComponent implements OnInit, OnChanges {

   @Input() item!: any;
  @Input() logoUrl!: string;
  @Input() stockName!: string;
  @Input() stockTicker!: string;
  @Input() currentPrice!: number;
  @Input() currency!: string;
  @Input() priceChange!: number
  @Input() percentChange!: number;
  @Input() countryName!: string;
  @Input() sectorName!: string;
  @Input() marketCap!: string;
  @Input() lastDate!: Date;
  @Input() stockExchange!: string;
  @Input() stockId!: string;
  isMobile: boolean = false;
  @Input() isAddedInWatchlist: boolean = false;
  @Input() isShown: boolean = false;
  @Input() id!: string;
  @Input() countryCode!: string
  @Input() stockType!: 'Stock' | 'ETF';
  @Input() chartTicker!: string
  @Input() showTradingViewTicker!: boolean
  enabledCountries: any = []
  sectorList: any
  showPrices!: boolean;

  priceDelayText: string = ''


  @Output() openAddLists = new EventEmitter<void>();
  public currentCountry: any
  constructor(
    private observer: BreakpointObserver,
    private auth: AuthService,
    private firestoreService: FirestoreService,
    private marketUtilsService: MarketUtilsService,
    private router: Router,
    private configService: FirebaseRemoteConfigService,
    // private filterService: FilterService
    private renderer: Renderer2,
    public accessControlService: AccessControlService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.observer.observe('(max-width: 992px)').subscribe(result => {
      this.isMobile = result.matches
    });
    this.sectorList = JSON.parse(sessionStorage.getItem('sector-list') ?? "[]");
    this.getPriceDelays()
  }

  ngAfterViewInit(): void {
    if (this.showTradingViewTicker) {
      this.addWidgetScript();
    }
  }


  ngOnChanges() {
    this.getCountry()
  }


  getFirstLetter(stockName: string) {
    return stockName?.charAt(0)
  }

  openAddListsModal() {
    this.openAddLists.emit();
  }

  checkRole(role: string): boolean {
    return this.auth.checkRole(role);
  }

  async chkIsAddedInWatchlist() {
    this.isAddedInWatchlist = await lastValueFrom(this.firestoreService.isSymbolExistInAnyWatchlist(this.id, true))
  }

  findClickedCountry() {
    return this.enabledCountries.find((item: any) => item.display_value === this.countryName)
  }

  findClickedSector() {
    return this.sectorList.find((item: any) => item.display_value === this.sectorName)
  }

  filterByCountry(): void {
    this.router.navigate(['/cabinet/onboarding'], { queryParams: { state: '1' } })

    const existingObject = this.getSessionObject('stock-filter');

    if (existingObject) {
      const updatedObject = this.setCountry(existingObject, this.findClickedCountry());
      this.setSessionObject('stock-filter', updatedObject);
    }
  }

  filterBySector() {
    this.router.navigate(['/cabinet/onboarding'], { queryParams: { state: '1' } })

    const existingObject = this.getSessionObject('stock-filter');

    if (existingObject) {
      const updatedObject = this.setSector(existingObject, this.findClickedSector());
      this.setSessionObject('stock-filter', updatedObject);
    }
  }

  private getSessionObject(key: string): any {
    const storedObject = sessionStorage.getItem(key);
    return storedObject ? JSON.parse(storedObject) : null;
  }

  private setCountry(object: any, obj: any): any {
    if (object) {
      object.country = obj;
      object.countryValue = obj.key;
    }
    return object;
  }

  private setSector(object: any, obj: any): any {
    if (object) {
      object.sector = obj;
      object.sectorValue = obj.key;
    }
    return object;
  }

  private setSessionObject(key: string, value: any): void {
    const jsonString = JSON.stringify(value);
    sessionStorage.setItem(key, jsonString);
  }

  async getCountry() {
    let countriesData: any = await this.marketUtilsService.fetchCountries();

    this.enabledCountries = countriesData
      .filter((obj: any) => obj.stockEnabled === true)
      .map((obj: any) => {
        return {
          key: obj.countryCode,
          display_value: obj.country
        }
      })


    let currentCountry = countriesData
      .find((obj: any) => obj.countryCode == this.countryCode)

    if (currentCountry && currentCountry.detailedProfileAvailable) {
      this.showPrices = true;
    } else {
      this.showPrices = false;
    }

  }

  getPriceDelays(): Observable<any> {
    const promise = this.configService.getConfigDefault().then(response => {
      const priceDelaysObj = response.price_delay_defaults;
      const currentCountryObj = priceDelaysObj.country_delays.find((obj: any) => obj.country_code === this.countryCode);

      if (this.stockType === 'Stock') {
        const text = priceDelaysObj.stock_message

        this.priceDelayText = text
          .replace("{{COUNTRY_CODE}}", currentCountryObj.country_code)
          .replace("{{DURATION}}", currentCountryObj.stock_price_delay);

      } else {
        const text = priceDelaysObj.etf_message

        this.priceDelayText = text
          .replace("{{COUNTRY_CODE}}", currentCountryObj.country_code)
          .replace("{{DURATION}}", currentCountryObj.etf_price_delay);

      }
    })

    return from(promise)

  }

  closeWatchListUi() {
    this.isShown = false
    this.chkIsAddedInWatchlist()
  }

  openLoginPopup() {
   //  this.auth.openAuthPopup()
   this.accessControlService.accessDenied();
  }

  private addWidgetScript(): void {
    const widgetContainer = this.el.nativeElement.querySelector('.tradingview-widget-container__widget');

    const widgetScript = this.renderer.createElement('script');
    widgetScript.type = 'text/javascript';
    widgetScript.async = true;
    widgetScript.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';

    const widgetSettings = {
      "symbol": this.chartTicker,
      "width": "100%",
      "colorTheme": "light",
      "isTransparent": true,
      "locale": "en",
    };

    widgetScript.textContent = JSON.stringify(widgetSettings);

    this.renderer.appendChild(widgetContainer, widgetScript);
  }
}
