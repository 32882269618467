<ng-template #content let-modal>
   <div class="modal-header border-0 p-2 pb-lg-0">
      <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
      <button type="button" class="btn shadow-none p-1 bg-light rounded-3 modal-header-close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <img src="/assets/images/header/close.svg" alt="Close">
      </button>
   </div>
   <div class="modal-body border-0 p-0">
      <ng-content></ng-content>
   </div>
   <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Save</button>
   </div> -->
</ng-template>


