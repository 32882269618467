<section class="px-3 px-lg-0">
   <div class="card-inner-container py-4 px-3 p-lg-4">
      <!-- <button (click)="openComplianceHistory()">OPen</button> -->
      <header class="mb-4">
         <h4 class="mb-1 header-h4">Shariah Compliance</h4>
         <p class="mb-0 header-para" *ngIf="complianceStatus !== 'NOT_UNDER_COVERAGE'">
            <ng-container *ngIf="!isManualSet">
               Screening Methodology: <span class="fw-600">AAOIFI</span>
            </ng-container>
            <ng-container *ngIf="isManualSet">
               Source: <span class="fw-600">Issuer's Shariah Board</span>
            </ng-container>
         </p>
      </header>

      <ng-container *ngIf="complianceStatus">
         <div>
            <!-- locked status start -->
            <ng-container *ngIf="complianceStatus === 'LOCKED'">
               <div class="d-flex align-items-center justify-content-center py-3 mb-4 compliance-chip locked-chip" >
                  <img src="/assets/images/complanise-locked.svg" alt="lock image" class="lock-image me-3" />
                  <h5 class="mb-0 status-text">LOCKED</h5>
               </div>
            </ng-container>
            <!-- locked status end -->

            <!-- halal status start -->
            <ng-container *ngIf="complianceStatus === 'COMPLIANT'">
               <div class="d-flex align-items-center justify-content-center compliance-chip halal-chip" >
                  <h5 class="mb-0 status-text">HALAL</h5>
               </div>
            </ng-container>
            <!-- halal status end -->

            <!-- not-halal status start -->
            <ng-container *ngIf="complianceStatus === 'NON_COMPLIANT'">
               <div class="d-flex align-items-center justify-content-center mb-4 compliance-chip not-halal-chip" >
                  <h5 class="mb-0 status-text">NOT HALAL</h5>
               </div>
            </ng-container>
            <!-- not-halal status end -->

            <!-- doubtful status start -->
            <ng-container *ngIf="complianceStatus === 'QUESTIONABLE'">
               <div class="d-flex align-items-center justify-content-center mb-4 compliance-chip doubtful-chip" >
                  <h5 class="mb-0 status-text">DOUBTFUL</h5>
               </div>
            </ng-container>
            <!-- doubtful status end -->

            <!-- not-covered status start -->
            <ng-container *ngIf="complianceStatus === 'NOT_UNDER_COVERAGE'">
               <div class="d-flex align-items-center justify-content-center mb-4 compliance-chip not-covered-chip">
                  <h5 class="mb-0 status-text">NOT COVERED</h5>
               </div>
            </ng-container>
            <!-- not-covered status end -->
         </div>

         <div class="d-flex align-items-center justify-content-center ratings-container" *ngIf="complianceStatus == 'COMPLIANT' && !isManualSet">
            <div class="d-flex align-items-center justify-content-center gap-3" *ngIf="showRanking; else notRanking">
               <!-- Replace stars with the complianceRanking value -->
               <div class="rating_grade_status" style="font-size: 18px;">
                   {{ complianceRanking | rankingGrade }} <!-- Display the alphabetical ranking value -->
               </div>

               <!-- Info button with tooltip -->
               <button class="bg-transparent border-0 rounded-circle" [ngbTooltip]="tipContent" placement="left-top">
                   <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 24 24" width="20" height="20" color="#7e7e7e" fill="none">
                       <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                       <path d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                       <path d="M11.992 8H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                   </svg>
               </button>

               <!-- Tooltip content -->
               <ng-template #tipContent>
                   <ng-container>
                     Musaffa Halal Rating is a proprietary ranking metric that compares all stocks in a given country from lowest to highest Shariah compliance standing.
                     The higher the rating, the greater the adherence to Shariah regulations, resulting in a reduced requirement to purify Not Halal income following the sale of these stock holdings.
                   </ng-container>
               </ng-template>
           </div>

           <ng-template #notRanking>
               <!-- Fallback content if showRanking is false -->
               <div class="no-ranking">
                   No ranking available.
               </div>
           </ng-template>

            <ng-template #notRanking>
               <button class="btn p-0 d-flex shadow-none align-items-center mx-auto card-colored-btn gap-2" (click)="handleRatingPopup()">
                  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.845 6.627H11.2v-2.27A4.36 4.36 0 0 0 6.843 0a4.36 4.36 0 0 0-4.356 4.357v2.27h-.645a.923.923 0 0 0-.923.923v7.527c0 .51.414.923.923.923h10.002c.51 0 .924-.413.924-.923V7.55a.923.923 0 0 0-.923-.923m-4.02 6.61H5.862l.352-1.675a1.176 1.176 0 0 1 .63-2.172 1.176 1.176 0 0 1 .631 2.172zm1.82-6.61H4.043v-2.27a2.805 2.805 0 0 1 2.802-2.803 2.805 2.805 0 0 1 2.802 2.803z" fill="#26A79C"/>
                  </svg>
                  <span class="fw-500">Musaffa Halal Ratings</span>
               </button>
            </ng-template>
         </div>
         <div *ngIf="isManualSet">
            &nbsp;
         </div>

         <ng-container *ngIf="stockType === 'stock'">
            <ng-container *ngIf="canViewScreeningReport">
               <button class="btn d-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3" (click)="handleButtonClick()" >
                  <span>View Detailed Report</span>
               </button>
            </ng-container>

            <ng-container *ngIf="!canViewScreeningReport && !isUserLoggedIn">
               <button (click)="accessControlService.accessDenied()" *ngIf="etfAssetClass !== 'Fixed Income'"  class="btn d-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3" >
                  <!-- Sign up for free & Unlock! -->
                  <img src="/assets/icons/lock-white.svg" width="16" height="16" alt="lock image" class="img-fluid">
                  <span>Unlock</span>
               </button>
            </ng-container>
            <div *ngIf="complianceStatus !== 'NOT_UNDER_COVERAGE' && complianceStatus !== 'LOCKED'">
               <hr class="my-4">
               <div class="mb-4">
                  <p class="last-update-para mb-1">Last Updated: <span class="fw-600"> {{ getLastUpdated() }}</span></p>
                  <ng-container *ngIf="minDuration < diffDays">
                     <button class="btn p-0 d-flex shadow-none align-items-center card-colored-btn gap-2" *ngIf="!canRequestStockComplianceUpdate; else updateRequest" (click)="handleRatingPopup()">
                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M11.845 6.627H11.2v-2.27A4.36 4.36 0 0 0 6.843 0a4.36 4.36 0 0 0-4.356 4.357v2.27h-.645a.923.923 0 0 0-.923.923v7.527c0 .51.414.923.923.923h10.002c.51 0 .924-.413.924-.923V7.55a.923.923 0 0 0-.923-.923m-4.02 6.61H5.862l.352-1.675a1.176 1.176 0 0 1 .63-2.172 1.176 1.176 0 0 1 .631 2.172zm1.82-6.61H4.043v-2.27a2.805 2.805 0 0 1 2.802-2.803 2.805 2.805 0 0 1 2.802 2.803z" fill="#26A79C"/>
                        </svg>
                        <span>Request Report Update</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" fill="#26A79C">
                           <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                        </svg>
                     </button>

                     <ng-template #updateRequest>
                        <button class="btn p-0 d-flex shadow-none align-items-center card-colored-btn gap-2" (click)="openConfirmationPopup(updateRequestConfirmationPopup)">
                           <span>Request Report Update</span>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" fill="#26A79C">
                              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                           </svg>
                        </button>
                     </ng-template>
                  </ng-container>
               </div>
               <div class="">
                  <p class="last-update-para mb-1">Report Source: <span class="fw-600"> {{ getQuarterText() }}</span></p>
                  <button class="btn p-0 d-flex shadow-none align-items-center card-colored-btn gap-2" *ngIf="!canReadComplianceHistory; else viewHistory" (click)="handleRatingPopup()">
                     <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.845 6.627H11.2v-2.27A4.36 4.36 0 0 0 6.843 0a4.36 4.36 0 0 0-4.356 4.357v2.27h-.645a.923.923 0 0 0-.923.923v7.527c0 .51.414.923.923.923h10.002c.51 0 .924-.413.924-.923V7.55a.923.923 0 0 0-.923-.923m-4.02 6.61H5.862l.352-1.675a1.176 1.176 0 0 1 .63-2.172 1.176 1.176 0 0 1 .631 2.172zm1.82-6.61H4.043v-2.27a2.805 2.805 0 0 1 2.802-2.803 2.805 2.805 0 0 1 2.802 2.803z" fill="#26A79C"/>
                     </svg>
                     <span>View Compliance History</span>
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" fill="#26A79C">
                        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                     </svg>
                  </button>
                  <ng-template #viewHistory>
                     <button class="btn p-0 d-flex shadow-none align-items-center card-colored-btn gap-2" (click)="openComplianceHistory()">
                        <span>View Compliance History</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" fill="#26A79C">
                           <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/>
                        </svg>
                     </button>
                  </ng-template>
               </div>
            </div>

            <div *ngIf="complianceStatus === 'NOT_UNDER_COVERAGE'">
               <ng-container *ngIf="!isCoverageRequested" >
                  <button class="btn" [ngClass]="handleCoverageBtnStyling()" *ngIf="!canRequestStockCoverage" (click)="openPaywallModal('request_coverage')" >
                     <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.845 6.627H11.2v-2.27A4.36 4.36 0 0 0 6.843 0a4.36 4.36 0 0 0-4.356 4.357v2.27h-.645a.923.923 0 0 0-.923.923v7.527c0 .51.414.923.923.923h10.002c.51 0 .924-.413.924-.923V7.55a.923.923 0 0 0-.923-.923m-4.02 6.61H5.862l.352-1.675a1.176 1.176 0 0 1 .63-2.172 1.176 1.176 0 0 1 .631 2.172zm1.82-6.61H4.043v-2.27a2.805 2.805 0 0 1 2.802-2.803 2.805 2.805 0 0 1 2.802 2.803z" fill="#26A79C"/>
                     </svg>
                     <span>Request Coverage</span>
                  </button>
                  <button class="btn" [ngClass]="handleCoverageBtnStyling()" *ngIf="canRequestStockCoverage" [disabled]="isCoverageRequested" (click)="openCoverageModal()" >
                     <span>Request Coverage</span>
                  </button>
               </ng-container>
               <p class="mb-0 mx-auto mt-3 request-text" *ngIf="isCoverageRequested" >
                  You have requested for the coverage of this stock. Our Financial Analyst team are reviewing the stocks and you'll get an email notification as soon as the coverage is done.
               </p>
            </div>
         </ng-container>

         <ng-container *ngIf="stockType === 'etf'">
            <div class="" *ngIf="etfAssetClass === 'Fixed Income' && !isManualSet && isUserLoggedIn">
               <p class="mb-0 mx-auto request-text">
                  The inherent guarantee of returns in fixed-income ETFs is considered non-compliant with Shariah's emphasis on shared profit and risk.
               </p>
            </div>

            <ng-container *ngIf="handleETFComplianceReportBtnVisibility() && etfAssetClass">
               <button class="btn d-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3" (click)="handleButtonClick()" >
                  <span>View Detailed Report</span>
               </button>
            </ng-container>

            <ng-container *ngIf="!handleETFComplianceReportBtnVisibility() && !isUserLoggedIn">
               <button (click)="accessControlService.accessDenied()" *ngIf="etfAssetClass !== 'Fixed Income'"  class="btn d-flex shadow-none align-items-enter justify-content-center gap-2 gradient-btn text-uppercase text-white card-btn py-2 px-4 rounded-3" >
                  Sign up for free & Unlock!
               </button>
            </ng-container>


            <ng-container *ngIf="!(etfAssetClass === 'Fixed Income' || (etfAssetClass === 'Equity' && complianceStatus === 'NOT_UNDER_COVERAGE')) && getLastUpdated()">
               <hr class="my-4">

               <p class="last-update-para mb-1">Last Updated: <span class="fw-600"> {{ getLastUpdated() }}</span></p>
            </ng-container>
         </ng-container>

      </ng-container>
   </div>
</section>

<app-compliance-history
   [ticker]="ticker"
   [currentComplianceStatus]="complianceStatus"
   [currentComplianceRating]="complianceRanking"
   [lastUpdated]="getLastUpdated()"
   [reportSource]="getQuarterText()">
</app-compliance-history>

<ng-template #updateRequestConfirmationPopup let-modal>
   <section class="p-3 p-lg-4 m-0">
      <h5 class="mb-4 header-h4 modal-h4 fw-500 text-center">
         Send a request to update Compliance Report of <strong class="fw-700">{{ ticker }}</strong>?
      </h5>

      <div class="">
         <div class="d-flex align-items-center gap-3 justify-content-center btns-container" >
            <button type="button" class="btn py-2 px-4 text-white fw-500 shadow-none modal-btn bg-danger rounded-3" [disabled]="requestLoading" (click)="closeConfirmationPopup()" >
               <span>Cancel</span>
            </button>
            <button type="button" class="btn py-2 px-4 text-white fw-500 shadow-none modal-btn bg-success rounded-3" [disabled]="requestLoading" (click)="reportRequest()" >
               <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="requestLoading">
                  <span class="visually-hidden">Loading...</span>
               </div>
               <span>{{ requestLoading ? 'Sending Request...' : 'Send Request' }}</span>
            </button>
         </div>
      </div>
   </section>
</ng-template>


