<div [ngClass]="otherInvestments.length > 0 ? '' : 'p-top'">
   <div class="d-flex align-items-center gap-2 step-info-wrapper" (click)="openInvestmentInfoModal()">
      <svg class="flex-shrink-0 info-svg" width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M10.5 15C10.7833 15 11.0208 14.9042 11.2125 14.7125C11.4042 14.5208 11.5 14.2833 11.5 14V10C11.5 9.71667 11.4042 9.47917 11.2125 9.2875C11.0208 9.09583 10.7833 9 10.5 9C10.2167 9 9.97917 9.09583 9.7875 9.2875C9.59583 9.47917 9.5 9.71667 9.5 10V14C9.5 14.2833 9.59583 14.5208 9.7875 14.7125C9.97917 14.9042 10.2167 15 10.5 15ZM10.5 7C10.7833 7 11.0208 6.90417 11.2125 6.7125C11.4042 6.52083 11.5 6.28333 11.5 6C11.5 5.71667 11.4042 5.47917 11.2125 5.2875C11.0208 5.09583 10.7833 5 10.5 5C10.2167 5 9.97917 5.09583 9.7875 5.2875C9.59583 5.47917 9.5 5.71667 9.5 6C9.5 6.28333 9.59583 6.52083 9.7875 6.7125C9.97917 6.90417 10.2167 7 10.5 7ZM10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM10.5 18C12.7333 18 14.625 17.225 16.175 15.675C17.725 14.125 18.5 12.2333 18.5 10C18.5 7.76667 17.725 5.875 16.175 4.325C14.625 2.775 12.7333 2 10.5 2C8.26667 2 6.375 2.775 4.825 4.325C3.275 5.875 2.5 7.76667 2.5 10C2.5 12.2333 3.275 14.125 4.825 15.675C6.375 17.225 8.26667 18 10.5 18Z" />
      </svg>
      <div>
         <p class="mb-0 step-info-text">
            How is Zakat Calculated for these investments?
         </p>
      </div>
   </div>
   <button class="d-flex align-items-center shadow-none justify-content-between btn investment-btn p-3 p-lg-4" type="button" (click)="openInvestmentDetailsModal()">
      <span>Add Investment</span>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M12.666 15.3333V19.3333C12.666 19.7111 12.7938 20.0277 13.0493 20.2833C13.3049 20.5388 13.6216 20.6666 13.9993 20.6666C14.3771 20.6666 14.6938 20.5388 14.9493 20.2833C15.2049 20.0277 15.3327 19.7111 15.3327 19.3333V15.3333H19.3327C19.7105 15.3333 20.0271 15.2055 20.2827 14.95C20.5382 14.6944 20.666 14.3777 20.666 14C20.666 13.6222 20.5382 13.3055 20.2827 13.05C20.0271 12.7944 19.7105 12.6666 19.3327 12.6666H15.3327V8.66663C15.3327 8.28885 15.2049 7.97218 14.9493 7.71663C14.6938 7.46107 14.3771 7.33329 13.9993 7.33329C13.6216 7.33329 13.3049 7.46107 13.0493 7.71663C12.7938 7.97218 12.666 8.28885 12.666 8.66663V12.6666H8.66602C8.28824 12.6666 7.97157 12.7944 7.71601 13.05C7.46046 13.3055 7.33268 13.6222 7.33268 14C7.33268 14.3777 7.46046 14.6944 7.71601 14.95C7.97157 15.2055 8.28824 15.3333 8.66602 15.3333H12.666ZM13.9993 27.3333C12.1549 27.3333 10.4216 26.9833 8.79935 26.2833C7.17713 25.5833 5.76602 24.6333 4.56602 23.4333C3.36602 22.2333 2.41602 20.8222 1.71602 19.2C1.01602 17.5777 0.666016 15.8444 0.666016 14C0.666016 12.1555 1.01602 10.4222 1.71602 8.79996C2.41602 7.17774 3.36602 5.76663 4.56602 4.56663C5.76602 3.36663 7.17713 2.41663 8.79935 1.71663C10.4216 1.01663 12.1549 0.666626 13.9993 0.666626C15.8438 0.666626 17.5771 1.01663 19.1993 1.71663C20.8216 2.41663 22.2327 3.36663 23.4327 4.56663C24.6327 5.76663 25.5827 7.17774 26.2827 8.79996C26.9827 10.4222 27.3327 12.1555 27.3327 14C27.3327 15.8444 26.9827 17.5777 26.2827 19.2C25.5827 20.8222 24.6327 22.2333 23.4327 23.4333C22.2327 24.6333 20.8216 25.5833 19.1993 26.2833C17.5771 26.9833 15.8438 27.3333 13.9993 27.3333Z" fill="#53877C"/>
      </svg>
   </button>
   <div class="investments-list-container" *ngIf="otherInvestments.length > 0">
      <div class="single-investment-wrapper" *ngFor="let investment of otherInvestments">
         <div class="d-flex align-items-center justify-content-between mb-2">
            <h6 class="mb-0 investment-name">{{investment.name}}</h6>
            <button class="remove-btn btn lh-1 p-0" (click)="removeOtherInvestment(investment)">Remove</button>
         </div>
         <div class="investment-field-wrapper">
            <div class="">
               <label for="total" class="form-label mb-2 sr-only">Total Value of Investment:</label>
               <div class="d-flex align-items-center currency-input">
                  <span class="d-inline-block lh-1 currency">{{ selectedCurrency }}</span>
                  <input type="text" inputmode="numeric" autocomplete="off" class="form-control py-2 pe-2 ps-0 shadow-none" [(ngModel)]="investment.amount" id="total" placeholder="" onlyNumbersAndDot>
               </div>
            </div>
            <div>
               <ng-select class="custom-stock-select" labelForId="investment" [searchable]="false" [clearable]="false" [(ngModel)]="investment.investment_type">
                  <ng-option *ngFor="let investment of investments" [value]="investment.id">{{investment.name}}</ng-option>
               </ng-select>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template #addInvestmentDetailContent let-modal>
   <form class="modal-wrapper" [formGroup]="addOtherInvestmentForm" (ngSubmit)="addOtherInvestment()">
      <div class="investment-modal-header bg-white d-flex align-items-center justify-content-between">
         <h5 class="mb-0 text-start">Add Investment Details</h5>
         <button class="btn lh-1 p-0 shadow-none" (click)="closeInvestmentDetailsModal()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512">
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
            </svg>
         </button>
      </div>
      <div class="investment-modal-body p-0 bg-transparent">
         <div class="form-input-wrapper">
            <label for="otherInvestmentName" class="form-label mb-2">Enter Name:</label>
            <div class="d-flex align-items-center p-0 form-control-container">
               <input type="text" autocomplete="off" class="form-control bg-transparent shadow-none" id="otherInvestmentName" formControlName="otherInvestmentName" placeholder="">
            </div>
         </div>
         <div class="form-input-wrapper mb-4">
            <label for="otherInvestmentValue" class="form-label mb-2">Total Value of Investment:</label>
            <div class="d-flex align-items-center form-control-container">
               <span class="d-inline-block lh-1 currency">{{ selectedCurrency }}</span>
               <input type="text" inputmode="numeric" autocomplete="off" class="form-control bg-transparent ps-0 shadow-none" id="otherInvestmentValue" formControlName="otherInvestmentValue" placeholder="" onlyNumbersAndDot>
            </div>
         </div>
         <div class="form-input-wrapper mb-4">
            <label for="totalSharesAmount" class="form-label mb-2">Type of Investment:</label>
            <ng-select class="custom-stock-select" labelForId="totalSharesAmount" [searchable]="false" [clearable]="false" formControlName="investmentType" [ngModel]="formInvestmentType">
               <ng-option *ngFor="let investment of investments" [value]="investment.id">{{investment.name}}</ng-option>
            </ng-select>
         </div>
      </div>
      <div class="investment-modal-btns">
         <button class="btn shadow-none modal-btn cancel-btn" (click)="closeInvestmentDetailsModal()">
            CANCEL
         </button>
         <button class="btn shadow-none modal-btn add-btn" [disabled]="!addOtherInvestmentForm.valid">
            ADD HOLDING
         </button>
      </div>
   </form>
</ng-template>

<!-- <ng-template #investmentInfoModalContent let-modal>
   <div class="modal-wrapper">
      <div class="investment-modal-header bg-white d-flex align-items-center justify-content-between">
         <h5 class="mb-0 text-center">Calculation Methodology</h5>
         <button class="btn lh-1 p-0 shadow-none" (click)="closeInvestmentInfoModal()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512">
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
            </svg>
         </button>
      </div>
      <div class="investment-modal-body">
         <h6 class="text-black">Current market value of shares * 25 * 2.5%</h6>
         <p class="mb-0">
            These investments are the one which you do with the intent of long-term capital appreciation. Usually this are the one’s which one holds for more than 1 year. When the information about underlying Zakatable assets of the company is unavailable, 25% of current market value of shares is treated as liquid and 2.5% on that amount is calculated as Zakat.
         </p>
      </div>
   </div>
 </ng-template> -->
 <ng-template #investmentInfoModalContent let-modal>
   <div class="modal-wrapper">
      <div
         class="investment-modal-header bg-white d-flex align-items-center justify-content-between"
      >
         <h5 class="mb-0 text-start">
            Short-Term / Long-Term Calculation Methodology
         </h5>
         <button
            class="btn lh-1 p-0 shadow-none"
            (click)="closeInvestmentInfoModal()"
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               height="24"
               width="24"
               viewBox="0 0 384 512"
            >
               <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
               />
            </svg>
         </button>
      </div>
      <div class="investment-modal-body">
         <div class="text-container">
            <h4 class="text-black">Short-Term (For Trade)</h4>
            <h6 class="text-black">Current market value of shares * 2.5%</h6>
            <p class="mb-0">
               These investments are the one which you do with the intent of
               trading. Usually this are the one's which one holds for less than
               1 year. These holdings are treated as liquid/cash and zakat is to
               be paid as 2.5% on the total amount.
            </p>
         </div>
         <div>
            <h4 class="text-black">Long-Term (For Capital Appreciation)</h4>
            <h6 class="text-black">
               Current assets of the company / Shares outstanding of the company
               * Number of long term shares held
            </h6>
            <p class="mb-0">
               These investments are the one which you do with the intent of
               long-term capital appreciation. Usually this are the one's which
               one holds for more than 1 year <b>AND WHEN</b> the information about underlying Zakatable assets of the company
               <b>IS UNAVAILABLE THEN:</b> 25% of the current value of the shares is treated as liquid and 2.5% on that amount is
               calculated as Zakat.
            </p>
         </div>
      </div>
   </div>
</ng-template>
