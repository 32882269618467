<form class="rounded-3 d-flex align-items-center search-container" [formGroup]="listSearchForm">
   <i class="bi bi-search"></i>
   <input 
      type="search" 
      class="search-input w-100 border-0 pe-3" 
      placeholder="Search lists by name"
      formControlName="listName"
   >
</form>


