<div class="forecast-card-container p-3 p-lg-4">
   <h4 class="forecast-card-heading">{{heading}}</h4>
   <div class="chart-and-prices-container">
      <div class="prices-wrapper">
         <article class="price-card current-price-card" style="    display: flex;
         flex-direction: column;
         justify-content: end;">
            <!-- <h5 class="year-text">{{currentYear}}</h5> -->
            <div class="d-flex align-items-center">
               <span class="d-inline-block box current-price-box me-2"></span>
               <p class="mb-0 price-text">Current Price</p>
            </div>
            <span class="price-value">{{currentValue | currencyMilion: currency}}</span>
         </article>
         <div class="vr d-xl-none"></div>
         <div class="flex-fill">
            <h5 class="forecast-text mt-3">Forecast for {{nextYear}}</h5>
            <div class="d-flex gap-2 gap-xl-3 align-items-center flex-wrap">
               <article class="price-card current-price-card flex-grow-1">
                  <div class="d-flex align-items-center">
                     <span class="d-inline-block box high-price-box me-2"></span>
                     <p class="mb-0 price-text">High</p>
                  </div>
                  <span class="price-value">{{highValue | currencyMilion: currency}}</span>
               </article>
               <article class="price-card current-price-card flex-grow-1">
                  <div class="d-flex align-items-center">
                     <span class="d-inline-block box median-price-box me-2"></span>
                     <p class="mb-0 price-text">Median</p>
                  </div>
                  <span class="price-value">{{medianValue | currencyMilion: currency}}</span>
               </article>
               <article class="price-card current-price-card flex-grow-1">
                  <div class="d-flex align-items-center">
                     <span class="d-inline-block box low-price-box me-2"></span>
                     <p class="mb-0 price-text">Low</p>
                  </div>
                  <span class="price-value">{{lowValue | currencyMilion: currency}}</span>
               </article>
            </div>
         </div>
      </div>

      <div class="chart-wrapper">
         <ng-content></ng-content>
      </div>
   </div>

</div>
