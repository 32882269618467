<div class="login-form-container" [ngClass]="formStyling">
   <h3 class="form-heading" *ngIf="verificationRequired">Join Musaffa!</h3>
   <p class="mb-0 form-welcome-text" *ngIf="verificationRequired">
      Sign up with email
   </p>
   <h3 class="form-heading" *ngIf="!verificationRequired">
      Welcome to Musaffa!
   </h3>
   <ngx-skeleton-loader
      *ngIf="!formInitComplete"
      count="5"
      [theme]="{
         backgroundColor: '#f0f0f0',
         highlightColor: '#e0e0e0',
         animation: 'pulse',
         height: '60px',
         width: '100%',
         margin: '12px 0'
      }"
   ></ngx-skeleton-loader>
   <!-- <app-container-loader *ngIf="!formInitComplete" width="100%" height="100%"></app-container-loader> -->

   <form
      *ngIf="formInitComplete"
      class="login-form d-grid"
      [formGroup]="signupForm"
      (ngSubmit)="activationCode($event)"
      (keydown.enter)="$event.preventDefault()"
   >
      <div>
         <div
            class="d-flex align-items-center input-group-wrapper"
            [ngClass]="{
               invalid:
                  signupForm.controls.firstName.touched &&
                  signupForm.controls.firstName.invalid
            }"
         >
            <svg
               class="input-svg"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24"
               width="24"
               height="24"
               color="#A8A8A8"
               fill="none"
            >
               <path
                  d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
               <path
                  d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                  stroke="currentColor"
                  stroke-width="1.5"
               />
            </svg>
            <input
               [readonly]="!verificationRequired"
               type="text"
               id="firstName"
               name="firstName"
               class="input-field"
               placeholder=">First Name"
               formControlName="firstName"
            />
            <label for="firstName" class="input-label">First Name</label>
         </div>

         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.firstName.touched &&
               signupForm.controls.firstName.errors?.required
            "
         >
            First name is required
         </small>
      </div>

      <div>
         <div
            class="d-flex align-items-center input-group-wrapper"
            [ngClass]="{
               invalid:
                  signupForm.controls.lastName.touched &&
                  signupForm.controls.lastName.invalid
            }"
         >
            <svg
               class="input-svg"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24"
               width="24"
               height="24"
               color="#A8A8A8"
               fill="none"
            >
               <path
                  d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
               <path
                  d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                  stroke="currentColor"
                  stroke-width="1.5"
               />
            </svg>
            <input
               [readonly]="!verificationRequired"
               type="text"
               id="lastName"
               name="lastName"
               class="input-field"
               placeholder=">Last Name"
               formControlName="lastName"
            />
            <label for="lastName" class="input-label">Last Name</label>
         </div>

         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.lastName.touched &&
               signupForm.controls.lastName.errors?.required
            "
         >
            Last name is required
         </small>
      </div>

      <div>
         <div class="d-flex align-items-center gap-2">
            <div
               class="d-flex align-items-center input-group-wrapper w-100"
               [ngClass]="{
                  invalid:
                     (signupForm.controls.email.touched &&
                     signupForm.controls.email.invalid) || (showUnverifiedError && !isEmailVerified)
               }"
            >
               <svg
                  class="input-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#A8A8A8"
                  fill="none"
               >
                  <path
                     d="m7 7.5 2.942 1.74c1.715 1.014 2.4 1.014 4.116 0L17 7.5"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M10.5 19.5a116 116 0 0 1-1.401-.027c-3.149-.079-4.723-.118-5.854-1.255-1.131-1.136-1.164-2.67-1.23-5.737a69 69 0 0 1 0-2.953c.066-3.067.099-4.6 1.23-5.737C4.376 2.655 5.95 2.616 9.099 2.537a115 115 0 0 1 5.802 0c3.149.079 4.723.118 5.854 1.254s1.164 2.67 1.23 5.737c.009.455.014.668.015.972"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M19 17a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0 0v.5a1.5 1.5 0 0 0 3 0V17a4.5 4.5 0 1 0-4.5 4.5"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <div class="d-flex align-items-center flex-grow-1">
                  <input
                     [readonly]="isEmailVerified"
                     [disabled]="isEmailVerified"
                     type="email"
                     id="email"
                     name="email"
                     class="input-field"
                     placeholder="Email ID"
                     formControlName="email"
                  />
                  <!-- <app-verify-otp
                  (isVerified)="(isEmailVerified)"
                  (isVerifiedChange)="onEmailVerifiedChange($event)"
                  [inputValue]="signupForm.get('email')?.value"
                  [verificationType]="'email'"
                  [disabled]="!!(signupForm.get('email')?.invalid)"
               ></app-verify-otp> -->
               </div>
               <label for="email" class="input-label">Email ID</label>
            </div>
            <app-verify-otp
               [isVerified]="(isEmailVerified)"
               (isVerifiedChange)="onEmailVerifiedChange($event)"
               [inputValue]="signupForm.get('email')?.value"
               [verificationType]="'email'"
               [disabled]="!!signupForm.get('email')?.invalid"
            ></app-verify-otp>
         </div>
         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.email.touched &&
               signupForm.controls.email.errors?.required
            "
         >
            Email is required
         </small>
         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.email.touched &&
               signupForm.controls.email.errors?.pattern
            "
         >
            Email is Invalid
         </small>
         <small
            class="text-danger error-msg-text"
            *ngIf="showUnverifiedError && !isEmailVerified"
         >
            Please verify your email
         </small>
      </div>

      <div>
         <div class="d-flex align-items-center gap-2">
            <div
               class="d-flex align-items-center input-group-wrapper w-100"
               style="z-index: 6"
               [ngClass]="{
                  invalid:
                     (signupForm.controls.mobile.touched &&
                     signupForm.controls.mobile.invalid) || (showUnverifiedError && !isMobileVerified)
               }"
            >
               <div
                  class="input-group d-flex align-items-center flex-grow-1 gap-2"
               >
                  <svg
                     class="input-svg"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24"
                     width="24"
                     height="24"
                     color="#A8A8A8"
                     fill="none"
                  >
                     <path
                        d="M16 3H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V6C19 4.34315 17.6569 3 16 3Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                     />
                     <path
                        d="M12 18H12.01"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                     />
                     <path
                        d="M10 5H14"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                     />
                  </svg>
                  <div class="input-group-prepend">
                     <ng-select
                        [disabled]="isMobileVerified"
                        show-arrows
                        [clearable]="false"
                        placeholder="+"
                        class="country-code-select"
                        [items]="countries"
                        [bindValue]="'dial_code'"
                        [bindLabel]="'dial_code'"
                        (change)="onCountryCodeChange($event)"
                        [formControlName]="'countryCode'"
                     >
                     </ng-select>
                  </div>
                  <div class="d-flex align-items-center flex-grow-1">
                     <input
                        [disabled]="isMobileVerified"
                        type="tel"
                        id="mobile"
                        name="mobile"
                        class="input-field"
                        placeholder="Mobile Number"
                        formControlName="mobile"
                     />
                  </div>
               </div>
               <label for="mobile" class="input-label">Mobile Number</label>
            </div>

            <app-verify-otp
               *ngIf="isMobileVerificationEnabled"
               [isVerified]="(isMobileVerified)"
               (isVerifiedChange)="onMobileVerifiedChange($event)"
               [verificationType]="'mobile'"
               [inputValue]="
                  signupForm.get('countryCode')?.value +
                  signupForm.get('mobile')?.value
               "
               [disabled]="
                  !!(
                     signupForm.get('mobile')?.invalid ||
                     signupForm.get('countryCode')?.invalid
                  )
               "
            ></app-verify-otp>
         </div>

         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.countryCode.touched &&
               signupForm.controls.countryCode.errors?.required
            "
         >
            Country code is required
         </small>
         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.mobile.touched &&
               signupForm.controls.mobile.errors?.required
            "
         >
            Mobile number is required
         </small>
         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.mobile.touched &&
               signupForm.controls.mobile.errors?.pattern
            "
         >
            Please enter a valid mobile number
         </small>
         <small
            class="text-danger error-msg-text"
            *ngIf="showUnverifiedError && !isMobileVerified"
         >
            Please verify your mobile number
         </small>
      </div>

      <div *ngIf="verificationRequired">
         <div
            class="d-flex align-items-center input-group-wrapper"
            [ngClass]="{
               invalid:
                  signupForm.controls.password.touched &&
                  signupForm.controls.password.invalid
            }"
         >
            <svg
               class="input-svg"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24"
               width="24"
               height="24"
               color="#A8A8A8"
               fill="none"
            >
               <path
                  d="M12 16.5v-2"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
               />
               <path
                  d="M4.268 18.845c.225 1.67 1.608 2.979 3.292 3.056 1.416.065 2.855.099 4.44.099s3.024-.034 4.44-.1c1.684-.076 3.067-1.385 3.292-3.055.147-1.09.268-2.207.268-3.345s-.121-2.255-.268-3.345c-.225-1.67-1.608-2.979-3.292-3.056A95 95 0 0 0 12 9c-1.585 0-3.024.034-4.44.1-1.684.076-3.067 1.385-3.292 3.055C4.12 13.245 4 14.362 4 15.5s.121 2.255.268 3.345Z"
                  stroke="currentColor"
                  stroke-width="1.5"
               />
               <path
                  d="M7.5 9V6.5a4.5 4.5 0 0 1 9 0V9"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
            </svg>
            <input
               type="{{ showPassword ? 'text' : 'password' }}"
               id="password"
               name="password"
               class="input-field"
               placeholder="Password"
               formControlName="password"
            />
            <label for="password" class="input-label">Password</label>
            <button
               type="button"
               class="btn shadow-none bg-transparent eye-btn p-0"
               (click)="togglePasswordVisibility()"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#A8A8A8"
                  fill="none"
                  *ngIf="!showPassword"
               >
                  <path
                     d="M19.439 15.439a19.5 19.5 0 0 0 2.105-2.484c.304-.426.456-.64.456-.955 0-.316-.152-.529-.456-.955C20.178 9.129 16.689 5 12 5c-.908 0-1.77.155-2.582.418m-2.67 1.33c-2.017 1.36-3.506 3.195-4.292 4.297-.304.426-.456.64-.456.955 0 .316.152.529.456.955C3.822 14.871 7.311 19 12 19c1.99 0 3.765-.744 5.253-1.747"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M9.858 10A2.929 2.929 0 1 0 14 14.142"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                  />
                  <path
                     d="m3 3 18 18"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#A8A8A8"
                  fill="none"
                  *ngIf="showPassword"
               >
                  <path
                     d="M21.544 11.045C21.848 11.4713 22 11.6845 22 12C22 12.3155 21.848 12.5287 21.544 12.955C20.1779 14.8706 16.6892 19 12 19C7.31078 19 3.8221 14.8706 2.45604 12.955C2.15201 12.5287 2 12.3155 2 12C2 11.6845 2.15201 11.4713 2.45604 11.045C3.8221 9.12944 7.31078 5 12 5C16.6892 5 20.1779 9.12944 21.544 11.045Z"
                     stroke="currentColor"
                     stroke-width="1.5"
                  />
                  <path
                     d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z"
                     stroke="currentColor"
                     stroke-width="1.5"
                  />
               </svg>
            </button>
         </div>
         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.password.touched &&
               signupForm.controls.password.errors?.required
            "
         >
            Password is required
         </small>
         <small
            class="text-danger error-msg-text"
            *ngIf="
               signupForm.controls.password.touched &&
               signupForm.controls.password.errors?.pattern
            "
         >
            Password must contain at least 7 characters, one uppercase letter
            and one number
         </small>
      </div>

      <div
         class="alert d-flex align-items-center gap-2 alert-danger br-10 mb-0 p-2"
         role="alert"
         *ngIf="errorMessage"
      >
         <svg
            class="flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="22"
            height="22"
            color="#dc3545"
            fill="none"
         >
            <path
               d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
               stroke="currentColor"
               stroke-width="1.5"
            />
            <path
               d="M11.9998 16H12.0088"
               stroke="currentColor"
               stroke-width="2"
               stroke-linecap="round"
               stroke-linejoin="round"
            />
            <path
               d="M12 13L12 7"
               stroke="currentColor"
               stroke-width="1.5"
               stroke-linecap="round"
               stroke-linejoin="round"
            />
         </svg>
         <small class="err-msg">{{ errorMessage }}</small>
      </div>

      <button
         [disabled]="!isFormValid() || showMailLoader"
         type="button"
         class="border-0 d-block send-pin-btn gradient-bg-btn"
         *ngIf="!isOTPMailSent"
         (click)="submitSignupForm($event)"
      >
         <div
            class="spinner-border spinner-border-sm me-2"
            role="status"
            *ngIf="showMailLoader"
         >
            <span class="visually-hidden">Loading...</span>
         </div>

         <span *ngIf="verificationRequired">{{
            showMailLoader ? "Processing..." : "Signup"
         }}</span>
         <span *ngIf="!verificationRequired">{{
            showMailLoader ? "Processing..." : "Save"
         }}</span>
      </button>

      <div>
         <article
            class="alert alert-info d-flex my-3 p-2 gap-2"
            *ngIf="showPinInput"
         >
            <svg
               class="flex-shrink-0"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24"
               width="22"
               height="22"
               color="#055160"
               fill="none"
            >
               <path
                  d="M2.5 12c0-4.478 0-6.718 1.391-8.109S7.521 2.5 12 2.5c4.478 0 6.718 0 8.109 1.391S21.5 7.521 21.5 12c0 4.478 0 6.718-1.391 8.109S16.479 21.5 12 21.5c-4.478 0-6.718 0-8.109-1.391S2.5 16.479 2.5 12Z"
                  stroke="currentColor"
                  stroke-width="2"
               />
               <path
                  d="M12 16h.009"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
               <path
                  d="M12 13V7"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
               />
            </svg>
            <p class="mb-0">
               If you do not see the email in a few minutes, check your
               "Promotions" or “junk/spam mail” folder.
            </p>
         </article>

         <div
            class="d-flex align-items-stretch gap-2 gap-lg-3"
            *ngIf="isOTPMailSent"
         >
            <div
               class="d-flex flex-grow-1 align-items-center input-group-wrapper"
            >
               <svg
                  class="input-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#A8A8A8"
                  fill="none"
               >
                  <path
                     d="M18.6436 4C18.4955 3.61742 18.2933 3.29898 18.0175 3.02513C16.9851 2 15.3234 2 12 2C8.67661 2 7.01491 2 5.98247 3.02513C5.70666 3.29898 5.50453 3.61742 5.3564 4M5 18C5.08715 19.4194 5.32629 20.3233 5.98247 20.9749C7.01491 22 8.67661 22 12 22C15.3234 22 16.9851 22 18.0175 20.9749C18.6737 20.3233 18.9128 19.4194 19 18"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                  />
                  <path
                     d="M6 10L8 12M8 10L6 12"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M11 10L13 12M13 10L11 12"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M16 10L18 12M18 10L16 12"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M17 7H7C5.11438 7 4.17157 7 3.58579 7.58579C3 8.17157 3 9.11438 3 11C3 12.8856 3 13.8284 3.58579 14.4142C4.17157 15 5.11438 15 7 15H17C18.8856 15 19.8284 15 20.4142 14.4142C21 13.8284 21 12.8856 21 11C21 9.11438 21 8.17157 20.4142 7.58579C19.8284 7 18.8856 7 17 7Z"
                     stroke="currentColor"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M12 19V19.01"
                     stroke="currentColor"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <input
                  type="text"
                  id="verificationPin"
                  name="verificationPin"
                  class="input-field fw-bold"
                  autocomplete="off"
                  mask="0  0  0  0"
                  placeholder="0 0 0 0"
                  formControlName="code"
               />
               <label for="verificationPin" class="input-label"
                  >Enter 4 digit Pin</label
               >
            </div>
            <button
               class="flex-grow-1 d-block code-btn"
               [disabled]="showPinInput || showOTPLoader"
               (click)="resendOTPMail()"
            >
               <div
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  *ngIf="showOTPLoader"
               >
                  <span class="visually-hidden">Loading...</span>
               </div>
               <span>{{ showOTPLoader ? "Sending.." : "Resend Code" }}</span>
            </button>
         </div>
         <small *ngIf="showPinInput">
            Request a new pin in {{ this.seconds }} seconds
         </small>
      </div>

      <button
         type="submit"
         class="border-0 d-block w-100 sign-up-btn gradient-bg-btn"
         [disabled]="showSignUpLoader"
         *ngIf="isOTPMailSent"
      >
         <div
            class="spinner-border spinner-border-sm me-2"
            role="status"
            *ngIf="showSignUpLoader"
         >
            <span class="visually-hidden">Loading...</span>
         </div>
         {{ showSignUpLoader ? "Signing up..." : "Signup" }}
      </button>
   </form>

   <div
      class="d-flex align-items-center justify-content-between mx-auto flex-wrap not-account-para"
      *ngIf="verificationRequired"
   >
      <p class="mb-0" *ngIf="!isInsidePopup">
         Already have an account?
         <a
            class="text-decoration-underline login-link d-inline-block"
            routerLink="/authentication/login"
            >Log in</a
         >
      </p>
      <p class="mb-0" *ngIf="isInsidePopup">
         Already have an account?
         <button
            class="border-0 bg-transparent p-0 login-link text-decoration-underline d-inline-block"
            (click)="signIn()"
         >
            Log in
         </button>
      </p>
   </div>

   <div class="social-login-btns-wrapper" *ngIf="verificationRequired">
      <app-google-signin
         [isGoogleBtnInsidePopup]="isInsidePopup"
         [tapEvent]="analyticsUtils.LoginScreen_ContinueGoogle_Tapped_EVENT"
         [completedEvent]="
            analyticsUtils.LoginScreen_ContinueGoogle_Completed_EVENT
         "
         [failEvent]="analyticsUtils.LoginScreen_ContinueGoogle_Failed_EVENT"
      >
      </app-google-signin>

      <app-apple-login
         [isAppleBtnInsidePopup]="isInsidePopup"
         [tapEvent]="analyticsUtils.LoginScreen_ContinueApple_Tapped_EVENT"
         [completedEvent]="
            analyticsUtils.LoginScreen_ContinueApple_Completed_EVENT
         "
         [failEvent]="analyticsUtils.LoginScreen_ContinueApple_Failed_EVENT"
      >
      </app-apple-login>
   </div>
</div>
