<div class="container p-3 p-lg-4 loader-grid" *ngIf="isLoading">
   <div *ngFor="let i of [1, 2, 3, 4]">
      <ngx-skeleton-loader
         count="1" appearance="line" animation="progress"
         [theme]="{ height: '250px', width: '100%', 'border-radius': '8px', margin: '0px' }">
      </ngx-skeleton-loader>
   </div>
</div>

<ng-container *ngIf="!isLoading">
   <form class="form-wrapper" [formGroup]="form" (ngSubmit)="getUserSelectedValues()" >
      <div class="form-inner-wrapper">
         <div class="heading-text-wrapper">
            <h5 class="steps-form-heading">
               Select all the items that you think may apply to you
            </h5>
            <p class="steps-form-description">
               Based on the selections, you would be presented to enter the details
               in the next steps.
            </p>
         </div>
         <div class="d-flex align-items-center justify-content-between currency-container self-align-start" >
            <h6 class="mb-0 heading">Select your currency:</h6>
            <label for="currency"></label>
            <ng-select
               class="custom-currency-select"
               [items]="currencies"
               bindLabel="label"
               bindValue="currency_code"
               [clearable]="false"
               formControlName="currency"
               [searchable]="false"
               [hideSelected]="true"
               placeholder="USD"
               (click)="!isLoggedIn ? openLoginPopup() : null"
               (change)="onCurrencyChange($event)"
               [(ngModel)]="selectedCurrency"
            >
            </ng-select>
         </div>
         <div class="checkbox-group-container">
            <div class="checkbox-group assets-group">
               <h6 class="heading">Select what you own:</h6>
               <div class="checkbox-list-container">
                  <label class="checkbox-tile" formArrayName="assets" *ngFor="let item of assetsFormArray.controls; let i = index">
                     <input class="checkbox-input" type="checkbox" [formControlName]="i" />
                     <div class="checkbox-container assets" [class.checked]="assetsFormArray.controls[i].value" >
                        <svg class="empty-box-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!assetsFormArray.controls[i].value" >
                           <path d="M8 24C5.77778 24 3.88889 23.2222 2.33333 21.6667C0.777778 20.1111 0 18.2222 0 16V8C0 5.77778 0.777778 3.88889 2.33333 2.33333C3.88889 0.777778 5.77778 0 8 0H16C18.2222 0 20.1111 0.777778 21.6667 2.33333C23.2222 3.88889 24 5.77778 24 8V16C24 18.2222 23.2222 20.1111 21.6667 21.6667C20.1111 23.2222 18.2222 24 16 24H8ZM8 21.3333H16C17.4667 21.3333 18.7222 20.8111 19.7667 19.7667C20.8111 18.7222 21.3333 17.4667 21.3333 16V8C21.3333 6.53333 20.8111 5.27778 19.7667 4.23333C18.7222 3.18889 17.4667 2.66667 16 2.66667H8C6.53333 2.66667 5.27778 3.18889 4.23333 4.23333C3.18889 5.27778 2.66667 6.53333 2.66667 8V16C2.66667 17.4667 3.18889 18.7222 4.23333 19.7667C5.27778 20.8111 6.53333 21.3333 8 21.3333Z" fill="#1C1B1F" />
                        </svg>
                        <svg class="check-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="assetsFormArray.controls[i].value" >
                           <path d="M8 24C5.77778 24 3.88889 23.2222 2.33333 21.6667C0.777778 20.1111 0 18.2222 0 16V8C0 5.77778 0.777778 3.88889 2.33333 2.33333C3.88889 0.777778 5.77778 0 8 0H16C18.2222 0 20.1111 0.777778 21.6667 2.33333C23.2222 3.88889 24 5.77778 24 8V16C24 18.2222 23.2222 20.1111 21.6667 21.6667C20.1111 23.2222 18.2222 24 16 24H8ZM10.6667 17.3333L18.6667 9.33333L16.8 7.46667L10.6667 13.6L7.73333 10.6667L5.86667 12.5333L10.6667 17.3333ZM8 21.3333H16C17.4667 21.3333 18.7222 20.8111 19.7667 19.7667C20.8111 18.7222 21.3333 17.4667 21.3333 16V8C21.3333 6.53333 20.8111 5.27778 19.7667 4.23333C18.7222 3.18889 17.4667 2.66667 16 2.66667H8C6.53333 2.66667 5.27778 3.18889 4.23333 4.23333C3.18889 5.27778 2.66667 6.53333 2.66667 8V16C2.66667 17.4667 3.18889 18.7222 4.23333 19.7667C5.27778 20.8111 6.53333 21.3333 8 21.3333Z" fill="white" />
                        </svg>
                        <span>{{ userAssets[i].name }}</span>
                     </div>
                  </label>
               </div>
            </div>
            <div class="checkbox-group liabilities-group">
               <h6 class="heading">Select your liabilities:</h6>
               <div class="checkbox-list-container">
                  <label class="checkbox-tile" formArrayName="liabilities" *ngFor=" let item of liabilitiesFormArray.controls; let i = index">
                     <input class="checkbox-input" type="checkbox" [formControlName]="i" />
                     <div class="checkbox-container liabilities" [class.checked]="liabilitiesFormArray.controls[i].value" >
                        <svg class="empty-box-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!liabilitiesFormArray.controls[i].value" >
                           <path d="M8 24C5.77778 24 3.88889 23.2222 2.33333 21.6667C0.777778 20.1111 0 18.2222 0 16V8C0 5.77778 0.777778 3.88889 2.33333 2.33333C3.88889 0.777778 5.77778 0 8 0H16C18.2222 0 20.1111 0.777778 21.6667 2.33333C23.2222 3.88889 24 5.77778 24 8V16C24 18.2222 23.2222 20.1111 21.6667 21.6667C20.1111 23.2222 18.2222 24 16 24H8ZM8 21.3333H16C17.4667 21.3333 18.7222 20.8111 19.7667 19.7667C20.8111 18.7222 21.3333 17.4667 21.3333 16V8C21.3333 6.53333 20.8111 5.27778 19.7667 4.23333C18.7222 3.18889 17.4667 2.66667 16 2.66667H8C6.53333 2.66667 5.27778 3.18889 4.23333 4.23333C3.18889 5.27778 2.66667 6.53333 2.66667 8V16C2.66667 17.4667 3.18889 18.7222 4.23333 19.7667C5.27778 20.8111 6.53333 21.3333 8 21.3333Z" fill="#1C1B1F" />
                        </svg>
                        <svg class="check-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="liabilitiesFormArray.controls[i].value" >
                           <path d="M8 24C5.77778 24 3.88889 23.2222 2.33333 21.6667C0.777778 20.1111 0 18.2222 0 16V8C0 5.77778 0.777778 3.88889 2.33333 2.33333C3.88889 0.777778 5.77778 0 8 0H16C18.2222 0 20.1111 0.777778 21.6667 2.33333C23.2222 3.88889 24 5.77778 24 8V16C24 18.2222 23.2222 20.1111 21.6667 21.6667C20.1111 23.2222 18.2222 24 16 24H8ZM10.6667 17.3333L18.6667 9.33333L16.8 7.46667L10.6667 13.6L7.73333 10.6667L5.86667 12.5333L10.6667 17.3333ZM8 21.3333H16C17.4667 21.3333 18.7222 20.8111 19.7667 19.7667C20.8111 18.7222 21.3333 17.4667 21.3333 16V8C21.3333 6.53333 20.8111 5.27778 19.7667 4.23333C18.7222 3.18889 17.4667 2.66667 16 2.66667H8C6.53333 2.66667 5.27778 3.18889 4.23333 4.23333C3.18889 5.27778 2.66667 6.53333 2.66667 8V16C2.66667 17.4667 3.18889 18.7222 4.23333 19.7667C5.27778 20.8111 6.53333 21.3333 8 21.3333Z" fill="white" />
                        </svg>
                        <span>{{ userLiabilities[i].name }}</span>
                     </div>
                  </label>
               </div>
            </div>
         </div>
      </div>

      <div class="btn-container" *ngIf="isLoggedIn">
         <button class="btn d-flex align-items-center justify-content-center text-white rounded gap-3 start-btn" [disabled]="!areCheckboxesChecked" >
            <span>START</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
               <path d="M12.175 9H0V7H12.175L6.575 1.4L8 0L16 8L8 16L6.575 14.6L12.175 9Z" fill="white" />
            </svg>
         </button>
      </div>
      <div class="btn-container" *ngIf="!isLoggedIn">
         <button type="button" class="btn d-flex align-items-center justify-content-center text-white rounded gap-3 start-btn" (click)="openLoginPopup()" >
            <span>START</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
               <path d="M12.175 9H0V7H12.175L6.575 1.4L8 0L16 8L8 16L6.575 14.6L12.175 9Z" fill="white" />
            </svg>
         </button>
      </div>
   </form>
</ng-container>
