<div class="chart-container">
   <canvas class="chart-data-canvas" [id]="chartId">{{barChart}}</canvas>
</div>
<div class="legend-wrapper">
   <div class="legend-item me-3">
      <span class="d-inline-block box estimate-box me-1"></span>
      <span class="legend-text">Estimate</span>
   </div>
   <div class="legend-item me-3">
      <span class="d-inline-block box actual-box me-1"></span>
      <span class="legend-text">Actual</span>
   </div>
</div>
