<div class="main-wrapper p-lg-4 bg-white">
   <div class="details-header mb-2 lh-1 mb-lg-4">
      <h4 class="mb-0 section-heading">
         All Stocks
         <span *ngIf="listInfo?.public_list_holdings_count" class="list-count">
            ({{ listInfo.public_list_holdings_count }})
         </span>
      </h4>
   </div>

   <div class="p-2" *ngIf="listLoader">
      <ngx-skeleton-loader
         count="10"
         appearance="line"
         animation="progress"
         [theme]="{
            height: '50px',
            width: '100%',
            'border-radius': '8px',
            margin: '0px'
         }"
      >
      </ngx-skeleton-loader>
   </div>

   <ng-container *ngIf="!listLoader && allholdingsList.length > 0">
      <!-- for screens lg and up -->
      <div class="table-responsive" *ngIf="!isMobile">
         <table class="table lg-and-up-table mb-0">
            <thead>
               <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th
                     scope="col"
                     class="text-center"
                     [class.clickable]="
                        allholdingsList.length > 1 &&
                        (userRole !== null || userRole !== 'free')
                     "
                     (click)="
                        allholdingsList.length > 1 &&
                        (userRole !== null || userRole !== 'free')
                           ? onSort('price')
                           : null
                     "
                  >
                     <div
                        class="d-flex align-items-center justify-content-center gap-2"
                     >
                        <span class="">Price </span>
                        <i
                           class="text-secondary"
                           [ngClass]="getColumnSortClasses('price')"
                        ></i>
                     </div>
                  </th>
                  <th
                     scope="col"
                     class="text-center"
                     [class.clickable]="
                        allholdingsList.length > 1 &&
                        (userRole !== null || userRole !== 'free')
                     "
                     (click)="
                        allholdingsList.length > 1 &&
                        (userRole !== null || userRole !== 'free')
                           ? onSort('priceChange')
                           : null
                     "
                  >
                     <div
                        class="d-flex align-items-center text-nowrap justify-content-center gap-2"
                     >
                        <span class="">1D Change</span>
                        <i
                           class="text-secondary"
                           [ngClass]="getColumnSortClasses('priceChange')"
                        ></i>
                     </div>
                  </th>
                  <th
                     scope="col"
                     class="text-center"
                     [class.clickable]="
                        allholdingsList.length > 1 &&
                        (userRole !== null || userRole !== 'free')
                     "
                     (click)="
                        allholdingsList.length > 1 &&
                        (userRole !== null || userRole !== 'free')
                           ? onSort('priceChangePercent')
                           : null
                     "
                     *ngIf="isMyCreatedList"
                  >
                     <div
                        class="d-flex align-items-center justify-content-center gap-2"
                     >
                        <span class="">Change% </span>
                        <i
                           class="text-secondary"
                           [ngClass]="
                              getColumnSortClasses('priceChangePercent')
                           "
                        ></i>
                     </div>
                  </th>
                  <th scope="col" class="text-center">Compliance</th>
                  <th scope="col" class="text-center">Rating</th>
                  <th
                     scope="col"
                     class="text-center"
                     *ngIf="isMyCreatedList"
                  ></th>
               </tr>
            </thead>
            <tbody>
               <tr
                  *ngFor="
                     let holding of filtereredHoldings()
                        | slice : (page - 1) * pageSize : page * pageSize;
                     let i = index;
                     trackBy: trackByListSymbol
                  "
               >
                  <td class="align-middle p-0">
                     <div class="star-wrapper">
                        <app-watchlist-button
                           [stock]="holding"
                        ></app-watchlist-button>
                     </div>
                  </td>
                  <td class="align-middle">
                     <a
                        class="text-decoration-none gap-2 line-clamp-1 stock-info"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <img
                           *ngIf="holding.logo"
                           [src]="holding.logo"
                           [alt]="holding.name"
                           class="d-block bg-light rounded-circle rounded-circle border img-fluid stock-logo"
                        />
                        <span
                           *ngIf="!holding.logo"
                           class="d-flex align-items-center flex-shrink-0 justify-content-center fs-5 clr-black bg-light rounded-circle rounded-circle border stock-logo"
                        >
                           {{ holding.name?.charAt(0) }}
                        </span>
                        <div class="d-flex flex-column">
                           <h6 class="mb-0 stock-name clr-black line-clamp-1">
                              {{ holding.name }}
                           </h6>
                           <span class="stock-ticker fw-normal">{{
                              holding.symbol
                           }}</span>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle">
                     <a
                        class="text-decoration-none justify-content-center clr-black stock-price"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        {{
                           holding.price
                              ? (holding.price
                                | sumPipe : holding.currency || "")
                              : "-"
                        }}
                     </a>
                  </td>
                  <td class="align-middle">
                     <a
                        class="text-decoration-none fw-normal justify-content-center clr-black stock-change"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <span
                           *ngIf="holding.priceChange"
                           [ngClass]="
                              getPercentChangeClasses(holding.priceChange)
                           "
                        >
                           <i
                              class="bi"
                              [ngClass]="getIconClasses(holding.priceChange)"
                           ></i>
                           {{ holding.priceChange | number : "1.2-2" }}
                        </span>
                        <span *ngIf="!holding.priceChange">-</span>
                     </a>
                  </td>
                  <td class="align-middle" *ngIf="isMyCreatedList">
                     <a
                        class="text-decoration-none fw-normal justify-content-center clr-black stock-change-percent"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <span
                           *ngIf="holding.priceChangePercent"
                           [ngClass]="
                              getPercentChangeClasses(
                                 holding.priceChangePercent
                              )
                           "
                        >
                           <i
                              class="bi"
                              [ngClass]="
                                 getIconClasses(holding.priceChangePercent)
                              "
                           ></i>
                           {{ holding.priceChangePercent | number : "1.2-2" }}
                        </span>
                        <span *ngIf="!holding.priceChangePercent">-</span>
                     </a>
                  </td>
                  <td class="align-middle">
                     <a
                        class="d-flex align-items-center justify-content-center gap-1 text-decoration-none stock-compliance"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <ng-container
                           *ngIf="
                              userRole !== null || userRole !== 'free';
                              else hideLock
                           "
                        >
                           <app-compliance-chip
                              [showCompliance]="
                                 !(page > 1 || (page === 1 && i >= 3))
                              "
                              [symbol]="holding.isin"
                              [align]="'center'"
                              [complianceStatus]="holding.complianceStatus"
                              [complianceRankingV2]="holding.complianceRanking"
                              [preloaded]="true"
                              [onlyCompliance]="true"
                           >
                           </app-compliance-chip>
                        </ng-container>
                        <ng-template #hideLock>
                           <app-compliance-chip
                              [symbol]="holding.isin"
                              [align]="'center'"
                              [complianceStatus]="holding.complianceStatus"
                              [complianceRankingV2]="holding.complianceRanking"
                              [preloaded]="true"
                              [onlyCompliance]="true"
                           >
                           </app-compliance-chip>
                        </ng-template>
                     </a>
                  </td>
                  <td class="align-middle">
                     <a
                        class="d-flex align-items-center justify-content-center gap-1 text-decoration-none stock-compliance"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <ng-container
                           *ngIf="
                              userRole !== null || userRole !== 'free';
                              else hideLock
                           "
                        >
                           <app-compliance-chip
                              [showCompliance]="
                                 !(page > 1 || (page === 1 && i >= 3))
                              "
                              [symbol]="holding.isin"
                              [align]="'center'"
                              [complianceStatus]="holding.complianceStatus"
                              [complianceRankingV2]="holding.complianceRanking"
                              [preloaded]="true"
                              [onlyRanking]="true"
                           >
                           </app-compliance-chip>
                        </ng-container>
                        <ng-template #hideLock>
                           <app-compliance-chip
                              [symbol]="holding.isin"
                              [align]="'center'"
                              [complianceStatus]="holding.complianceStatus"
                              [complianceRankingV2]="holding.complianceRanking"
                              [preloaded]="true"
                              [onlyRanking]="true"
                           >
                           </app-compliance-chip>
                        </ng-template>
                     </a>
                  </td>
                  <td class="align-middle" *ngIf="isMyCreatedList">
                     <button
                        class="btn p-2 shadow-none"
                        (click)="
                           openDeleteHoldingModal(deleteHoldingContent, holding)
                        "
                     >
                        <svg
                           width="19"
                           height="18"
                           viewBox="0 0 19 18"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M15.828 2.11h-2.637v-.528C13.191.71 12.481 0 11.61 0H7.391c-.873 0-1.582.71-1.582 1.582v.527H3.172c-.872 0-1.582.71-1.582 1.582 0 .701.458 1.296 1.09 1.504l.94 11.354c.068.814.76 1.451 1.577 1.451h8.606c.816 0 1.509-.637 1.577-1.45l.94-11.355a1.585 1.585 0 0 0 1.09-1.504c0-.872-.71-1.582-1.582-1.582m-8.965-.528c0-.29.237-.527.528-.527h4.218c.291 0 .528.236.528.527v.527H6.863zm7.466 14.88a.53.53 0 0 1-.526.483H5.197a.53.53 0 0 1-.525-.483L3.745 5.273h11.51zm1.5-12.243H3.171a.528.528 0 0 1 0-1.055h12.656a.528.528 0 0 1 0 1.055"
                              fill="#E72025"
                           />
                           <path
                              d="m7.39 15.33-.528-8.507a.528.528 0 0 0-1.052.065l.527 8.508a.527.527 0 1 0 1.053-.065M9.5 6.328a.527.527 0 0 0-.527.527v8.508a.527.527 0 0 0 1.054 0V6.855a.527.527 0 0 0-.527-.527m3.197.002a.53.53 0 0 0-.56.493l-.527 8.508a.527.527 0 0 0 1.053.065l.527-8.508a.527.527 0 0 0-.493-.559"
                              fill="#E72025"
                           />
                        </svg>
                     </button>
                  </td>
               </tr>
               <tr
                  class="tbody-overlay"
                  *ngIf="userRole === null && allholdingsList.length > 3"
               >
                  <td colspan="5" class="align-middle">
                     <button
                        (click)="accessControlService.accessDenied()"
                        class="btn d-flex align-items-center justify-content-center mx-auto shadow-none rounded-pill card-btn"
                     >
                        <img
                           width="16"
                           height="16"
                           src="/assets/images/complanise-locked-black.svg"
                           alt="lock image"
                           class="lock-image-black"
                        />
                        <!-- <span>Sign up for free & unlock!</span> -->
                        <span>Unlock</span>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>

      <!-- for screens below lg -->
      <div class="table-responsive" *ngIf="isMobile">
         <table class="table lg-and-below-table mb-0">
            <tbody>
               <tr
                  *ngFor="
                     let holding of filtereredHoldings()
                        | slice : (page - 1) * pageSize : page * pageSize;
                     let i = index;
                     trackBy: trackByListSymbol
                  "
               >
                  <td class="align-middle p-0">
                     <div class="star-wrapper" style="width: 1.5rem">
                        <app-watchlist-button
                           [stock]="holding"
                        ></app-watchlist-button>
                     </div>
                  </td>
                  <td class="align-middle">
                     <a
                        class="text-decoration-none d-flex align-items-center gap-2 stock-info"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <img
                           *ngIf="holding.logo"
                           [src]="holding.logo"
                           [alt]="holding.name"
                           class="d-block bg-light rounded-circle rounded-circle border img-fluid stock-logo"
                        />
                        <span
                           *ngIf="!holding.logo"
                           class="d-flex align-items-center justify-content-center fs-5 clr-black bg-light rounded-circle rounded-circle border stock-logo"
                        >
                           {{ holding.name?.charAt(0) }}
                        </span>
                        <div class="d-flex flex-column">
                           <h6 class="mb-0 stock-name line-clamp-1 clr-black">
                              {{ holding.name }}
                           </h6>
                           <span class="stock-ticker line-clamp-1 fw-normal">{{
                              holding.symbol
                           }}</span>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle">
                     <a
                        class="text-decoration-none d-flex flex-column align-items-center justify-content-center clr-black fw-500 list-stocks-count"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <ng-container
                           *ngIf="
                              userRole == null || userRole !== 'free';
                              else hideLock
                           "
                        >
                           <app-compliance-chip
                              [showCompliance]="
                                 !(page > 1 || (page === 1 && i >= 3))
                              "
                              [symbol]="holding.isin"
                              [align]="'center'"
                              [complianceStatus]="holding.complianceStatus"
                              [complianceRankingV2]="holding.complianceRanking"
                           >
                           </app-compliance-chip>
                        </ng-container>
                        <ng-template #hideLock>
                           <app-compliance-chip
                              [symbol]="holding.isin"
                              [align]="'center'"
                              [complianceStatus]="holding.complianceStatus"
                              [complianceRankingV2]="holding.complianceRanking"
                           >
                           </app-compliance-chip>
                        </ng-template>
                     </a>
                  </td>
                  <td class="align-middle">
                     <a
                        class="d-flex flex-column align-items-end justify-content-center text-decoration-none stock-price-info"
                        [routerLink]="
                           holding.type === 'stock'
                              ? ['/stock/' + holding.symbol]
                              : ['/etf/' + holding.symbol]
                        "
                     >
                        <span class="stock-price">{{
                           holding.price
                              ? (holding.price
                                | sumPipe : holding.currency || "")
                              : "-"
                        }}</span>
                        <span
                           class="stock-change"
                           [ngClass]="
                              getPercentChangeClasses(holding.priceChange)
                           "
                        >
                           <i
                              class="bi"
                              [ngClass]="getIconClasses(holding.priceChange)"
                           ></i>
                           {{
                              holding.priceChange
                                 ? (holding.priceChange | number : "1.2-2")
                                 : "-"
                           }}
                        </span>
                     </a>
                  </td>
                  <td class="align-middle" *ngIf="isMyCreatedList">
                     <button
                        class="btn py-2 px-1 shadow-none"
                        (click)="
                           openDeleteHoldingModal(deleteHoldingContent, holding)
                        "
                        style="width: 1.5rem"
                     >
                        <svg
                           width="15"
                           height="14"
                           viewBox="0 0 19 18"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M15.828 2.11h-2.637v-.528C13.191.71 12.481 0 11.61 0H7.391c-.873 0-1.582.71-1.582 1.582v.527H3.172c-.872 0-1.582.71-1.582 1.582 0 .701.458 1.296 1.09 1.504l.94 11.354c.068.814.76 1.451 1.577 1.451h8.606c.816 0 1.509-.637 1.577-1.45l.94-11.355a1.585 1.585 0 0 0 1.09-1.504c0-.872-.71-1.582-1.582-1.582m-8.965-.528c0-.29.237-.527.528-.527h4.218c.291 0 .528.236.528.527v.527H6.863zm7.466 14.88a.53.53 0 0 1-.526.483H5.197a.53.53 0 0 1-.525-.483L3.745 5.273h11.51zm1.5-12.243H3.171a.528.528 0 0 1 0-1.055h12.656a.528.528 0 0 1 0 1.055"
                              fill="#E72025"
                           />
                           <path
                              d="m7.39 15.33-.528-8.507a.528.528 0 0 0-1.052.065l.527 8.508a.527.527 0 1 0 1.053-.065M9.5 6.328a.527.527 0 0 0-.527.527v8.508a.527.527 0 0 0 1.054 0V6.855a.527.527 0 0 0-.527-.527m3.197.002a.53.53 0 0 0-.56.493l-.527 8.508a.527.527 0 0 0 1.053.065l.527-8.508a.527.527 0 0 0-.493-.559"
                              fill="#E72025"
                           />
                        </svg>
                     </button>
                  </td>
               </tr>
               <tr
                  class="tbody-overlay"
                  *ngIf="userRole === null && allholdingsList.length > 3"
               >
                  <td colspan="5" class="align-middle px-4">
                     <button
                        (click)="accessControlService.accessDenied()"
                        class="btn d-flex align-items-center justify-content-center mx-auto shadow-none rounded-pill card-btn"
                     >
                        <img
                           width="16"
                           height="16"
                           src="/assets/images/complanise-locked-black.svg"
                           alt="lock image"
                           class="lock-image-black"
                        />
                        <!-- <span>Sign up for free & unlock!</span> -->
                        <span>Unlock</span>
                     </button>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>

      <div class="mt-4" *ngIf="userRole !== null">
         <ngb-pagination
            [collectionSize]="allholdingsList.length"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="3"
            (pageChange)="onPageChange($event)"
         >
         </ngb-pagination>
      </div>
   </ng-container>

   <ng-container *ngIf="!listLoader && allholdingsList.length === 0">
      <div
         class="d-flex flex-column align-items-center justify-content-center no-list-container"
      >
         <img
            src="/assets/images/no-data.svg"
            class="img-fluid"
            alt="No Data Image"
         />
         <p class="mb-0 text-center">No Stocks Added</p>
      </div>
   </ng-container>
</div>

<ng-template #deleteHoldingContent let-modal>
   <div class="modal-body text-center">
      <ng-container *ngIf="allholdingsList.length > 10; else minimumMessage">
         <h4 class="mb-0 mx-auto">
            Are you sure you want to delete this Stock?
         </h4>
         <p class="mb-0 mx-auto">You can add it again later.</p>
         <div
            class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3 gap-lg-4 mt-2"
         >
            <button
               class="btn rounded-3 shadow-none outlined-button"
               (click)="closeDeleteHoldingModal()"
            >
               Cancel
            </button>
            <button
               type="button"
               class="btn rounded-3 shadow-none filled-button"
               [disabled]="buttonLoader"
               (click)="deleteStock()"
            >
               <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                  *ngIf="buttonLoader"
               >
                  <span class="visually-hidden">Loading...</span>
               </div>
               {{ buttonLoader ? "Deleting" : "Delete" }}
            </button>
         </div>
      </ng-container>
      <ng-template #minimumMessage>
         <h4 class="mb-0 mx-auto">List should have minimum 10 holdings</h4>
         <div
            class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3 gap-lg-4 mt-2"
         >
            <button
               type="button"
               class="btn rounded-3 shadow-none filled-button"
               (click)="closeDeleteHoldingModal()"
            >
               Ok
            </button>
         </div>
      </ng-template>
   </div>
</ng-template>
