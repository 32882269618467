import { Directive, HostListener, ElementRef, ChangeDetectorRef, NgZone, OnDestroy } from '@angular/core';

@Directive({
   selector: '[appSwipe]'
})
export class SwipeDirective implements OnDestroy {
   private startX: number = 0;
   private currentX: number = 0;
   private isSwiping: boolean = false;
   private momentum: number = 0;
   private requestId: number | null = null;
   private readonly minSwipeDistance = 5; // Threshold for swipe activation
   private readonly momentumDecay = 0.95; // Controls momentum decay speed
   private readonly swipeSensitivity: number; // Sensitivity factor

   private readonly scrollContainer: HTMLElement;

   constructor(private el: ElementRef, private cdRef: ChangeDetectorRef, private zone: NgZone) {
      this.scrollContainer = this.el.nativeElement;
      // Set swipe sensitivity based on screen width (mobile screens get lower sensitivity)
      const screenWidth = window.innerWidth;
      this.swipeSensitivity = screenWidth < 600 ? 0.25 : 1.5; // Scale for mobile, higher for larger screens
   }

   @HostListener('touchstart', ['$event'])
   onTouchStart(event: TouchEvent) {
      this.startSwipe(event.touches[0].clientX);
   }

   @HostListener('touchmove', ['$event'])
   onTouchMove(event: TouchEvent) {
      this.continueSwipe(event.touches[0].clientX);
   }

   @HostListener('touchend')
   onTouchEnd() {
      this.endSwipe();
   }

   @HostListener('mousedown', ['$event'])
   onMouseDown(event: MouseEvent) {
      event.preventDefault();
      this.startSwipe(event.clientX);
   }

   @HostListener('mousemove', ['$event'])
   onMouseMove(event: MouseEvent) {
      this.continueSwipe(event.clientX);
   }

   @HostListener('mouseup')
   onMouseUp() {
      this.endSwipe();
   }

   @HostListener('mouseleave')
   onMouseLeave() {
      this.endSwipe();
   }

   // Start swipe handler
   private startSwipe(startX: number) {
      this.startX = startX;
      this.currentX = startX;
      this.isSwiping = true;
      this.momentum = 0;
      this.cancelAnimation();
   }

   // Continue swipe handler
   private continueSwipe(currentX: number) {
      if (!this.isSwiping) return;

      let diffX = this.startX - currentX;
      diffX *= this.swipeSensitivity; // Apply swipe sensitivity adjustment

      // Ignore small movements below the threshold
      if (Math.abs(diffX) < this.minSwipeDistance) return;

      this.scroll(diffX);
      this.startX = currentX;
   }

   // End swipe handler
   private endSwipe() {
      if (this.isSwiping) {
         this.isSwiping = false;
         this.applyMomentum();
      }
   }

   // Scroll method with requestAnimationFrame for smooth scrolling
   private scroll(diffX: number) {
      const newScrollLeft = this.scrollContainer.scrollLeft + diffX;

      // Throttle DOM update by using NgZone to run outside change detection
      this.zone.runOutsideAngular(() => {
         this.requestId = requestAnimationFrame(() => {
            // Update scroll position in a performant manner without triggering re-render
            this.scrollContainer.scrollLeft = newScrollLeft;
            this.momentum = diffX;
         });
      });
   }

   // Apply momentum-based scrolling
   private applyMomentum() {
      if (Math.abs(this.momentum) > 1) {
         this.cancelAnimation();
         this.scrollContainer.scrollLeft += this.momentum;
         this.momentum *= this.momentumDecay;

         // Recurse with requestAnimationFrame
         this.zone.runOutsideAngular(() => {
            this.requestId = requestAnimationFrame(() => this.applyMomentum());
         });
      } else {
         this.cancelAnimation();
      }
   }

   // Cancel animation frame
   private cancelAnimation() {
      if (this.requestId) {
         cancelAnimationFrame(this.requestId);
         this.requestId = null;
      }
   }

   // Scroll to a specific button (center it in the container)
   scrollToButton(buttonIndex: number) {
      const buttons = this.scrollContainer.querySelectorAll('button');
      if (buttonIndex >= 0 && buttonIndex < buttons.length) {
         const targetButton = buttons[buttonIndex] as HTMLElement;
         this.scrollToElement(targetButton);
      }
   }

   // Scroll to an element (center it in the container)
   private scrollToElement(target: HTMLElement) {
      const containerWidth = this.scrollContainer.offsetWidth; // Width of the container
      const buttonWidth = target.offsetWidth; // Width of the target button
      const targetOffsetLeft = target.offsetLeft; // Position of the target button from the left of the container
      const scrollLeft = targetOffsetLeft - (containerWidth / 2) + (buttonWidth / 2); // Calculate the scroll position

      // Smooth scroll to the calculated position, outside Angular's zone to avoid re-render
      this.zone.runOutsideAngular(() => {
         this.scrollContainer.scrollTo({
            left: scrollLeft,
            behavior: 'smooth' // Smooth scrolling
         });
      });
   }

   ngOnDestroy() {
      // Clean up to prevent memory leaks
      this.cancelAnimation();
   }
}







// import { Directive, HostListener, ElementRef } from '@angular/core';

// @Directive({
//   selector: '[appSwipe]'
// })
// export class SwipeDirective {
//    private startX: number = 0;
//    private currentX: number = 0;
//    private isSwiping: boolean = false;
//    private momentum: number = 0;
//    private requestId: number | null = null;
//    private readonly minSwipeDistance = 5; // Threshold for swipe activation
//    private readonly momentumDecay = 0.95; // Controls momentum decay speed
//    private readonly swipeSensitivity: number; // Sensitivity factor

//    constructor(private el: ElementRef) {
//       // Set swipe sensitivity based on screen width (mobile screens get lower sensitivity)
//       const screenWidth = window.innerWidth;
//       this.swipeSensitivity = screenWidth < 600 ? 0.25 : 1.5; // Scale for mobile, higher for larger screens
//    }

//    @HostListener('touchstart', ['$event'])
//    onTouchStart(event: TouchEvent) {
//       this.startSwipe(event.touches[0].clientX);
//    }

//    @HostListener('touchmove', ['$event'])
//    onTouchMove(event: TouchEvent) {
//       this.continueSwipe(event.touches[0].clientX);
//    }

//    @HostListener('touchend')
//    onTouchEnd() {
//       this.endSwipe();
//    }

//    @HostListener('mousedown', ['$event'])
//    onMouseDown(event: MouseEvent) {
//       event.preventDefault();
//       this.startSwipe(event.clientX);
//    }

//    @HostListener('mousemove', ['$event'])
//    onMouseMove(event: MouseEvent) {
//       this.continueSwipe(event.clientX);
//    }

//    @HostListener('mouseup')
//    onMouseUp() {
//       this.endSwipe();
//    }

//    @HostListener('mouseleave')
//    onMouseLeave() {
//       this.endSwipe();
//    }

//    // Start swipe handler
//    private startSwipe(startX: number) {
//       this.startX = startX;
//       this.currentX = startX;
//       this.isSwiping = true;
//       this.momentum = 0;
//       this.cancelAnimation();
//    }

//    // Continue swipe handler
//    private continueSwipe(currentX: number) {
//       if (!this.isSwiping) return;

//       let diffX = this.startX - currentX;
//       diffX *= this.swipeSensitivity; // Apply swipe sensitivity adjustment

//       // Ignore small movements below the threshold
//       if (Math.abs(diffX) < this.minSwipeDistance) return;

//       this.scroll(diffX);
//       this.startX = currentX;
//    }

//    // End swipe handler
//    private endSwipe() {
//       if (this.isSwiping) {
//          this.isSwiping = false;
//          this.applyMomentum();
//       }
//    }

//    // Scroll method with requestAnimationFrame for smooth scrolling
//    private scroll(diffX: number) {
//       const newScrollLeft = this.el.nativeElement.scrollLeft + diffX;
//       this.requestId = requestAnimationFrame(() => {
//          this.el.nativeElement.scrollLeft = newScrollLeft;
//          this.momentum = diffX;
//       });
//    }

//    // Apply momentum-based scrolling
//    private applyMomentum() {
//       if (Math.abs(this.momentum) > 1) {
//          this.cancelAnimation();
//          this.el.nativeElement.scrollLeft += this.momentum;
//          this.momentum *= this.momentumDecay;

//          this.requestId = requestAnimationFrame(() => this.applyMomentum());
//       } else {
//          this.cancelAnimation();
//       }
//    }

//    // Cancel animation frame
//    private cancelAnimation() {
//       if (this.requestId) {
//          cancelAnimationFrame(this.requestId);
//          this.requestId = null;
//       }
//    }

//    // Scroll to a specific button (center it in the container)
//    scrollToButton(buttonIndex: number) {
//       const buttons = this.el.nativeElement.querySelectorAll('button');
//       if (buttonIndex >= 0 && buttonIndex < buttons.length) {
//          const targetButton = buttons[buttonIndex] as HTMLElement;
//          this.scrollToElement(targetButton);
//       }
//    }

//    // Scroll to an element (center it in the container)
//    private scrollToElement(target: HTMLElement) {
//       const container = this.el.nativeElement;
//       const containerWidth = container.offsetWidth; // Width of the container
//       const buttonWidth = target.offsetWidth; // Width of the target button
//       const targetOffsetLeft = target.offsetLeft; // Position of the target button from the left of the container
//       const scrollLeft = targetOffsetLeft - (containerWidth / 2) + (buttonWidth / 2); // Calculate the scroll position

//       // Scroll to the calculated position
//       container.scrollTo({
//          left: scrollLeft,
//          behavior: 'smooth' // Smooth scrolling
//       });
//    }
// }

