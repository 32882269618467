import { Component, OnInit } from '@angular/core';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';

@Component({
  selector: 'app-paywall-offer-card',
  templateUrl: './paywall-offer-card.component.html',
  styleUrls: ['./paywall-offer-card.component.scss']
})
export class PaywallOfferCardComponent implements OnInit {
  offer: any = {}
  offerDescription: string = '';
  isExpiring: boolean = false

  constructor(private configService: FirebaseRemoteConfigService,) { }

  async ngOnInit(): Promise<void> {
    await this.getOffers() 
  }

  
  async getOffers() {
    const offers = await this.configService.getPromoOffers()
    if(!this.isObjectEmpty(offers[0]) && offers[0].show_offer) {
      this.offer = offers[0]
      this.offerDescription = this.offer.description.replace(/{{END_DATE_DAYS}}/gi, this.calculateRemainingDays(this.offer.end_date))
      // this.offerDescription = this.offer.description.replace(/{{END_DATE_DAYS}}/gi, this.calculateRemainingDays("2024-02-17"))
    }
  }

  isObjectEmpty(obj :any) {
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
      }
      return true;
  }

  calculateRemainingDays(date: any) {
    const currentDate = new Date();
    const offerEndDate = new Date(date);
    const timeDiff = offerEndDate.getTime() - currentDate.getTime()

    const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    // console.log(remainingDays);

    if(remainingDays <= 0) {
      this.isExpiring = true
      return
    } else if(remainingDays === 1) {
      this.isExpiring = false
      return `1 day`
    } else {
      this.isExpiring = false
      return `${remainingDays} days`
    }
  }

}
