<div
   class="add-watchlist d-flex align-items-center justify-content-center"
   *ngIf="ifShow"
>
   <div class="container">
      <div class="model-liner">
         <div class="close text-end">
            <button type="button" class="btn end">
               <img
                  src="/assets/images/group-343.svg"
                  alt=""
                  (click)="closeMe()"
               />
            </button>
         </div>
         <div class="page">
            <form
               [formGroup]="emailForm"
               (ngSubmit)="sendOtp()"
               *ngIf="!showOtp"
            >
               <div class="watch-creates">
                  <label for="exampleFormControlInput1" class="form-label mb-1"
                     >Email</label
                  >
                  <input
                     type="text"
                     class="form-control"
                     id="exampleFormControlInput1"
                     maxlength="35"
                     placeholder="Enter Email"
                     formControlName="email"
                     [ngClass]="{ active: emailForm.valid }"
                     autocomplete="off"
                  />
               </div>
               <small class="error-msg text-danger" *ngIf="errorMsg"
                  ><i class="bi bi-exclamation-triangle me-2"></i
                  >{{ errorMsg }}</small
               >
               <div class="watchlist-button mx-auto mt-3">
                  <!-- <mat-spinner
                     *ngIf="loading"
                     [diameter]="40"
                     class="custom-spinner mx-auto"
                  ></mat-spinner> -->
                  <div *ngIf="!loading" class="btn-cont">
                     <button
                        type="button"
                        class="btn cancel"
                        (click)="closeMe()"
                     >
                        Cancel
                     </button>
                     <button
                        type="submit"
                        class="btn list"
                        [disabled]="!emailForm.valid"
                     >
                        <span *ngIf="!isSubmitLoad">Send Otp</span>
                        <div
                           class="spinner-border spinner-border-sm text-light"
                           role="status"
                           *ngIf="isSubmitLoad"
                        >
                           <span class="visually-hidden">Loading...</span>
                        </div>
                     </button>
                  </div>
               </div>
            </form>
            <form
               [formGroup]="otpForm"
               (ngSubmit)="verifyOtp()"
               *ngIf="showOtp"
            >
               <div class="watch-creates">
                  <label for="exampleFormControlInput1" class="form-label mb-1"
                     >OTP</label
                  >
                  <input
                     type="text"
                     class="form-control"
                     id="exampleFormControlInput1"
                     maxlength="6"
                     placeholder="Enter OTP"
                     formControlName="otp"
                     [ngClass]="{ active: otpForm.valid }"
                     autocomplete="off"
                  />
               </div>
               <small class="error-msg text-danger" *ngIf="errorMsg"
                  ><i class="bi bi-exclamation-triangle me-2"></i
                  >{{ errorMsg }}</small
               >
               <!-- <small class="error-msg text-danger" *ngIf="showErrorMsg"
                  ><i class="bi bi-exclamation-triangle me-2"></i>This name
                  already exists. Please, enter a different name.</small
               > -->
               <div class="watchlist-button mx-auto mt-3">
                  <!-- <mat-spinner
                     *ngIf="loading"
                     [diameter]="40"
                     class="custom-spinner mx-auto"
                  ></mat-spinner> -->
                  <div *ngIf="!loading" class="btn-cont">
                     <button
                        type="button"
                        class="btn cancel"
                        (click)="closeMe()"
                     >
                        Cancel
                     </button>
                     <button
                        type="submit"
                        class="btn list"
                        [disabled]="!otpForm.valid"
                     >
                        <span *ngIf="!isSubmitLoad">Send</span>
                        <div
                           class="spinner-border spinner-border-sm text-light"
                           role="status"
                           *ngIf="isSubmitLoad"
                        >
                           <span class="visually-hidden">Loading...</span>
                        </div>
                     </button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
