<div class="br-10 border bg-white p-3 p-lg-4 d-flex flex-column gap-3 gap-lg-4" *ngIf="listInfoLoader">
   <div class="d-flex align-items-center justify-content-between">
      <div class="skeleton-loader w-25 p-3 p-lg-4 rounded-3"></div>
      <div class="d-flex align-items-center w-100 justify-content-end gap-3 gap-lg-4">
         <div class="skeleton-loader w-25 p-3 p-lg-4 rounded-3"></div>
      </div>
   </div>
   <div class="d-flex flex-column align-items-center justify-content-between gap-2">
      <div class="skeleton-loader w-100 py-2 py-lg-3 rounded-3"></div>
      <div class="skeleton-loader w-100 py-2 py-lg-3 rounded-3"></div>
   </div>
</div>

<header class="list-header" *ngIf="!listInfoLoader">
   <div class="d-flex align-items-center justify-content-between gap-4 mb-2 mb-lg-4">
      <h2 class="page-main-heading d-flex flex-grow-1 align-items-center flex-wrap text-capitalize mb-0">
         {{ listInfo?.name }}
      </h2>
      <app-share-links-popup *ngIf="isMobile"></app-share-links-popup>


      <ng-container *ngIf="isMyCreatedList && isMobile">
         <div class="btns-wrapper" *ngIf="userRole === 'ANONYMOUS'">
            <button
               class="btn rounded-circle shadow-none d-flex align-items-center justify-content-center p-0 round-btn"
               (click)="navigateToLoginScreen()">
               <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#009000"
                     d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
               </svg>
            </button>
         </div>
         <div class="btns-wrapper" *ngIf="userRole !== 'ANONYMOUS'">
            <div class="menu-btn-wrapper">
               <button
                  class="btn rounded-circle shadow-none d-flex align-items-center justify-content-center p-0 round-btn"
                  (click)="toggleDropdownMenu()">
                  <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M1.999 13.667q-.688 0-1.177-.49A1.6 1.6 0 0 1 .332 12q0-.687.49-1.177.489-.49 1.177-.49.687 0 1.177.49T3.666 12t-.49 1.177-1.177.49m0-5q-.688 0-1.177-.49A1.6 1.6 0 0 1 .332 7q0-.687.49-1.177.489-.49 1.177-.49.687 0 1.177.49T3.666 7t-.49 1.177-1.177.49m0-5q-.688 0-1.177-.49A1.6 1.6 0 0 1 .332 2q0-.687.49-1.177.489-.49 1.177-.49.687 0 1.177.49T3.666 2t-.49 1.177-1.177.49"
                        fill="#1BAD4E" />
                  </svg>
               </button>
               <ul role="list" *ngIf="isDropdownMenuOpen" class="dropdown-list shadow">
                  <li>
                     <button class="btn d-flex align-items-center gap-2" (click)="openCreateListPopup(listInfo)">
                        <svg viewBox="0 0 20 20" height="16" width="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g clip-path="url(#clip0_13469_29163)" fill="#000000">
                              <path
                                 d="M15.833 10.038a.833.833 0 0 0-.833.833v6.667a.834.834 0 0 1-.833.833H2.5a.834.834 0 0 1-.833-.833V5.87c0-.459.373-.833.833-.833h6.667a.833.833 0 1 0 0-1.667H2.5A2.503 2.503 0 0 0 0 5.87v11.667c0 1.378 1.122 2.5 2.5 2.5h11.667c1.378 0 2.5-1.122 2.5-2.5V10.87a.833.833 0 0 0-.834-.833" />
                              <path
                                 d="M7.813 9.279a.4.4 0 0 0-.114.212l-.59 2.947a.418.418 0 0 0 .492.49l2.945-.59a.4.4 0 0 0 .214-.113l6.593-6.594-2.946-2.945zM19.388.648a2.085 2.085 0 0 0-2.946 0L15.29 1.802l2.946 2.946 1.153-1.154c.394-.392.61-.916.61-1.472a2.07 2.07 0 0 0-.61-1.474" />
                           </g>
                        </svg>
                        <span>Edit</span>
                     </button>
                  </li>
                  <li>
                     <button class="btn d-flex align-items-center text-danger gap-2" (click)="openDeleteListPopup()">
                        <svg viewBox="0 0 16 16" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g clip-path="url(#clip0_13469_22961)" fill="#E72025" fill-opacity=".6">
                              <path
                                 d="M13.625 1.875h-2.344v-.469C11.281.631 10.651 0 9.875 0h-3.75C5.35 0 4.719.63 4.719 1.406v.469H2.375a1.407 1.407 0 0 0-.437 2.743l.836 10.093c.06.723.676 1.289 1.401 1.289h7.65c.726 0 1.341-.566 1.401-1.29l.836-10.093c.562-.184.97-.713.97-1.336 0-.775-.632-1.406-1.407-1.406m-7.969-.469a.47.47 0 0 1 .469-.468h3.75a.47.47 0 0 1 .469.468v.469H5.656zm6.636 13.227a.47.47 0 0 1-.467.43h-7.65a.47.47 0 0 1-.467-.43l-.824-9.945h10.232zM13.625 3.75H2.375a.47.47 0 0 1 0-.937h11.25a.47.47 0 0 1 0 .937" />
                              <path
                                 d="m6.124 13.627-.469-7.562a.47.47 0 0 0-.935.058l.468 7.562a.469.469 0 0 0 .936-.058M8 5.625a.47.47 0 0 0-.469.469v7.562a.469.469 0 1 0 .938 0V6.094A.47.47 0 0 0 8 5.625m2.842.001a.47.47 0 0 0-.497.439l-.47 7.562a.469.469 0 0 0 .937.058l.468-7.562a.47.47 0 0 0-.438-.497" />
                           </g>
                        </svg>
                        <span>Delete</span>
                     </button>
                  </li>
               </ul>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="!isMyCreatedList && !isMobile">
         <div class="d-flex align-items-center gap-4">
            <button
               class="btn d-flex align-items-center shadow-none justify-content-center gap-2 header-btn follower-btn"
               [ngClass]="isFollowing ? 'is-following' : 'follower-btn'" (click)="toggleFollow()"
               *ngIf="userRole !== 'ANONYMOUS'; else lockDesktopFollowBtn">
               <svg width="18" height="16" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M10 18.325q-.349 0-.713-.125a1.65 1.65 0 0 1-.637-.4l-1.725-1.575a68 68 0 0 1-4.787-4.813Q0 9.026 0 6.15 0 3.8 1.575 2.225T5.5.65q1.325 0 2.5.562t2 1.538a6 6 0 0 1 2-1.538A5.7 5.7 0 0 1 14.5.65q2.35 0 3.925 1.575T20 6.15q0 2.875-2.125 5.275a60 60 0 0 1-4.825 4.825l-1.7 1.55a1.65 1.65 0 0 1-.637.4q-.363.125-.713.125"
                     [attr.fill]="getFillColor()" />
               </svg>
               <span>
                  {{ isFollowing ? "Following" : "Follow" }}
               </span>
            </button>
            <ng-template #lockDesktopFollowBtn>
               <button
                  class="btn d-flex align-items-center shadow-none justify-content-center gap-2 header-btn follower-btn"
                  (click)="navigateToLoginScreen()">
                  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                     <path fill="#1bad4e"
                        d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                  </svg>
                  <span> Follow </span>
               </button>
            </ng-template>

            <button
               class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn copy-to-watchlist-btn"
               (click)="openCopyToWatchlistPopup()" *ngIf="userRole !== 'ANONYMOUS'; else lockWatchlistBtn">
               <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#1bad4e">
                  <path
                     d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
               </svg>
               <span class="text-nowrap"> Copy to Watchlist </span>
            </button>
            <ng-template #lockWatchlistBtn>
               <button
                  class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn copy-to-watchlist-btn lock-btn"
                  (click)="navigateToLoginScreen()">
                  <svg width="16" height="16" class="lock-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                     <path fill="#1bad4e"
                        d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                  </svg>
                  <span class="d-none d-lg-block"> Copy to Watchlist </span>
               </button>
            </ng-template>

            <app-share-links-popup></app-share-links-popup>

         </div>
      </ng-container>

      <ng-container *ngIf="isMyCreatedList && !isMobile">
         <div class="d-flex align-items-center gap-4">
            <button
               class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn edit-btn"
               (click)="openCreateListPopup(listInfo)">
               <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_13469_29163)" fill="#1BAD4E">
                     <path
                        d="M15.833 10.038a.833.833 0 0 0-.833.833v6.667a.834.834 0 0 1-.833.833H2.5a.834.834 0 0 1-.833-.833V5.87c0-.459.373-.833.833-.833h6.667a.833.833 0 1 0 0-1.667H2.5A2.503 2.503 0 0 0 0 5.87v11.667c0 1.378 1.122 2.5 2.5 2.5h11.667c1.378 0 2.5-1.122 2.5-2.5V10.87a.833.833 0 0 0-.834-.833" />
                     <path
                        d="M7.813 9.279a.4.4 0 0 0-.114.212l-.59 2.947a.418.418 0 0 0 .492.49l2.945-.59a.4.4 0 0 0 .214-.113l6.593-6.594-2.946-2.945zM19.388.648a2.085 2.085 0 0 0-2.946 0L15.29 1.802l2.946 2.946 1.153-1.154c.394-.392.61-.916.61-1.472a2.07 2.07 0 0 0-.61-1.474" />
                  </g>
               </svg>
               <span class="d-none d-lg-block">Edit</span>
            </button>
            <button
               class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn delete-btn"
               (click)="openDeleteListPopup()">
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_13469_22961)" fill="#E72025" fill-opacity=".6">
                     <path
                        d="M13.625 1.875h-2.344v-.469C11.281.631 10.651 0 9.875 0h-3.75C5.35 0 4.719.63 4.719 1.406v.469H2.375a1.407 1.407 0 0 0-.437 2.743l.836 10.093c.06.723.676 1.289 1.401 1.289h7.65c.726 0 1.341-.566 1.401-1.29l.836-10.093c.562-.184.97-.713.97-1.336 0-.775-.632-1.406-1.407-1.406m-7.969-.469a.47.47 0 0 1 .469-.468h3.75a.47.47 0 0 1 .469.468v.469H5.656zm6.636 13.227a.47.47 0 0 1-.467.43h-7.65a.47.47 0 0 1-.467-.43l-.824-9.945h10.232zM13.625 3.75H2.375a.47.47 0 0 1 0-.937h11.25a.47.47 0 0 1 0 .937" />
                     <path
                        d="m6.124 13.627-.469-7.562a.47.47 0 0 0-.935.058l.468 7.562a.469.469 0 0 0 .936-.058M8 5.625a.47.47 0 0 0-.469.469v7.562a.469.469 0 1 0 .938 0V6.094A.47.47 0 0 0 8 5.625m2.842.001a.47.47 0 0 0-.497.439l-.47 7.562a.469.469 0 0 0 .937.058l.468-7.562a.47.47 0 0 0-.438-.497" />
                  </g>
               </svg>
               <span class="d-none d-lg-block">Delete</span>
            </button>
         </div>
      </ng-container>
   </div>

   <p class="mb-3 description-para" *ngIf="listInfo?.description">
      {{ displayDescription }}
      <button *ngIf="listInfo.description.length > charactersLimit" (click)="toggleTruncate()"
         class="bg-transparent border-0 fw-bold text-decoration-underline p-0">
         {{ isTruncated ? "read more" : "read less" }}
      </button>
   </p>

   <div
      class="border-top d-flex align-items-center pt-3 pt-lg-4 justify-content-between justify-content-lg-start gap-lg-4">
      <p class="mb-0 list-creater-name lh-1">
         By
         <span class="fw-bold" *ngIf="listInfo?.user?.firstname">{{ listInfo?.user?.firstname }}
            {{ listInfo?.user?.lastname }}</span>
         <span class="fw-bold" *ngIf="!listInfo?.user?.firstname">-</span>
      </p>
      <div class="vr d-none d-lg-inline-block"></div>
      <div class="d-flex align-items-center gap-2 lh-1 followers-count">
         <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
            <path
               d="M10 18.325q-.349 0-.713-.125a1.65 1.65 0 0 1-.637-.4l-1.725-1.575a68 68 0 0 1-4.787-4.813Q0 9.026 0 6.15 0 3.8 1.575 2.225T5.5.65q1.325 0 2.5.562t2 1.538a6 6 0 0 1 2-1.538A5.7 5.7 0 0 1 14.5.65q2.35 0 3.925 1.575T20 6.15q0 2.875-2.125 5.275a60 60 0 0 1-4.825 4.825l-1.7 1.55a1.65 1.65 0 0 1-.637.4q-.363.125-.713.125"
               fill="#E82127" />
         </svg>
         <span class="text-nowrap">
            {{
            listInfo.followers_count > 1
            ? listInfo.followers_count + " Followers"
            : listInfo.followers_count + " Follower"
            }}
         </span>
      </div>
   </div>
</header>

<!-- fixed buttons for mobile -->
<div class="fixed-bottom bg-white" *ngIf="!isMyCreatedList && isMobile">
   <div class="footer-btns-wrapper">
      <ng-container *ngIf="userRole !== 'ANONYMOUS'; else lockWatchlistBtn">
         <button class="btn shadow-none d-flex align-items-center justify-content-center gap-2 copy-watchlist-btn"
            (click)="openCopyToWatchlistPopup()">
            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M6 16q-.824 0-1.412-.588A1.93 1.93 0 0 1 4 14V2q0-.824.588-1.412A1.93 1.93 0 0 1 6 0h9q.824 0 1.413.588Q17 1.175 17 2v12q0 .825-.587 1.412A1.93 1.93 0 0 1 15 16zm0-2h9V2H6zm-4 6q-.824 0-1.412-.587A1.93 1.93 0 0 1 0 18V5q0-.424.288-.713A.97.97 0 0 1 1 4q.424 0 .712.287Q2 4.576 2 5v13h10q.424 0 .713.288.287.287.287.712 0 .424-.287.712A.97.97 0 0 1 12 20z"
                  fill="#1C1B1F" />
            </svg>
            <span>Watchlist</span>
         </button>
      </ng-container>
      <ng-template #lockWatchlistBtn>
         <button class="btn d-flex align-items-center shadow-none justify-content-center gap-2 copy-watchlist-btn"
            (click)="navigateToLoginScreen()">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="#000000"
                  d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
            </svg>
            <span>Watchlist</span>
         </button>
      </ng-template>

      <ng-container *ngIf="userRole !== 'ANONYMOUS'; else lockFollowBtn">
         <button class="btn d-flex shadow-none align-items-center justify-content-center gap-2"
            [ngClass]="isFollowing ? 'is-following' : 'follower-btn'" (click)="toggleFollow()">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M10 18.325q-.349 0-.713-.125a1.65 1.65 0 0 1-.637-.4l-1.725-1.575a68 68 0 0 1-4.787-4.813Q0 9.026 0 6.15 0 3.8 1.575 2.225T5.5.65q1.325 0 2.5.562t2 1.538a6 6 0 0 1 2-1.538A5.7 5.7 0 0 1 14.5.65q2.35 0 3.925 1.575T20 6.15q0 2.875-2.125 5.275a60 60 0 0 1-4.825 4.825l-1.7 1.55a1.65 1.65 0 0 1-.637.4q-.363.125-.713.125"
                  [attr.fill]="getFillColor()" />
            </svg>
            <span>{{ isFollowing ? "Following" : "Follow" }}</span>
         </button>
      </ng-container>
      <ng-template #lockFollowBtn>
         <button class="btn d-flex align-items-center shadow-none justify-content-center gap-2 follower-btn"
            (click)="navigateToLoginScreen()">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="#009000"
                  d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
            </svg>
            <span>Follow</span>
         </button>
      </ng-template>
   </div>
</div>

<ng-template #followSuccessContent let-modal>
   <div class="modal-body text-center">
      <div class="d-flex align-items-center justify-content-center mb-3 success-image">
         <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
               d="M50 0C22.4299 0 0 22.4299 0 50C0 77.5701 22.4299 100 50 100C77.5701 100 100 77.5701 100 50C100 22.4299 77.5701 0 50 0ZM50 96.875C24.1529 96.875 3.125 75.8469 3.125 50C3.125 24.1531 24.1529 3.125 50 3.125C75.8471 3.125 96.875 24.1531 96.875 50C96.875 75.8469 75.8471 96.875 50 96.875ZM75.0691 32.8617C75.3621 33.1547 75.5267 33.5521 75.5267 33.9665C75.5267 34.3809 75.3621 34.7783 75.0691 35.0713L45.1506 64.9898C45.0055 65.135 44.8332 65.2501 44.6436 65.3286C44.454 65.4072 44.2508 65.4476 44.0456 65.4476C43.8404 65.4476 43.6372 65.4072 43.4476 65.3286C43.258 65.2501 43.0857 65.135 42.9406 64.9898L27.0793 49.1285C26.7863 48.8355 26.6217 48.438 26.6217 48.0236C26.6218 47.6092 26.7864 47.2118 27.0795 46.9188C27.3726 46.6257 27.77 46.4612 28.1844 46.4612C28.5988 46.4612 28.9963 46.6259 29.2893 46.9189L44.0457 61.6754L72.8594 32.8617C73.0045 32.7166 73.1767 32.6015 73.3663 32.523C73.5559 32.4445 73.7591 32.404 73.9643 32.404C74.1695 32.404 74.3726 32.4445 74.5622 32.523C74.7518 32.6015 74.924 32.7166 75.0691 32.8617Z"
               fill="#1BAD4E" />
         </svg>
      </div>
      <h4 class="mb-0 mx-auto">Successfully followed</h4>
      <p class="mb-0 mx-auto">
         You are now following <span>{{ listInfo?.name }}</span> list
      </p>
      <div class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3 gap-lg-4 mt-2">
         <button class="btn rounded-3 shadow-none filled-button" (click)="closefollowSuccessModal()">
            Okay
         </button>
      </div>
   </div>
</ng-template>

<!-- <div
   class="br-10 border bg-white p-3 p-lg-4 d-flex flex-column gap-3 gap-lg-4"
   *ngIf="listInfoLoader"
>
   <div class="d-flex align-items-center justify-content-between">
      <div class="skeleton-loader w-25 p-3 p-lg-4 rounded-3"></div>
      <div class="d-flex align-items-center w-100 justify-content-end gap-3 gap-lg-4" >
         <div class="skeleton-loader width-10 p-3 p-lg-4 rounded-3"></div>
         <div class="skeleton-loader width-10 p-3 p-lg-4 rounded-3"></div>
         <div class="skeleton-loader width-10 p-3 p-lg-4 rounded-3"></div>
      </div>
   </div>
   <div class="d-flex flex-column align-items-center justify-content-between gap-2" >
      <div class="skeleton-loader w-100 py-2 py-lg-3 rounded-3"></div>
      <div class="skeleton-loader w-100 py-2 py-lg-3 rounded-3"></div>
   </div>
</div>

<header class="list-header" *ngIf="!listInfoLoader">
   <div class="d-flex align-items-start justify-content-between gap-2 gap-lg-5">
      <div class="d-flex align-items-start flex-grow-1 justify-content-between gap-3">
         <div class="grid">
            <h2 class="page-main-heading d-flex align-items-center flex-wrap gap-3 text-capitalize mb-2">
               {{ listInfo?.name }}
               <span class="mb-0 d-none d-lg-inline py-1 px-2 py-lg-2 px-lg-4 rounded-pill followers-chip" >
                  <div class="d-flex align-item-center gap-2">
                     <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M6.7.568a3.71 3.71 0 0 0-3.698 3.704 3.7 3.7 0 0 0 1.571 3.02c-2.211.886-3.78 3.048-3.78 5.57a.583.583 0 0 0 1.167 0 4.827 4.827 0 0 1 4.836-4.835c.935 0 1.802.267 2.54.724a.585.585 0 1 0 .616-.996 6 6 0 0 0-1.066-.506 3.7 3.7 0 0 0 1.518-2.977A3.715 3.715 0 0 0 6.7.568m0 1.168a2.53 2.53 0 0 1 2.538 2.536A2.526 2.526 0 0 1 6.7 6.804a2.52 2.52 0 0 1-2.53-2.532A2.525 2.525 0 0 1 6.7 1.736m5.904 7.001a.58.58 0 0 0-.474.268l-2.01 3.014-1.262-.845a.586.586 0 1 0-.65.974l1.753 1.167a.584.584 0 0 0 .809-.164l2.335-3.501a.583.583 0 0 0-.5-.913" fill="#1BAD4E" />
                     </svg>
                     <span class="text-nowrap">
                        {{ listInfo.followers_count > 1 ? listInfo.followers_count + " Followers" : listInfo.followers_count + " Follower" }}
                     </span>
                  </div>
               </span>
            </h2>
            <p class="mb-0 d-lg-none list-creater-name lh-lg">
               By {{ listInfo?.user?.firstname }} {{ listInfo?.user?.lastname }}
            </p>
            <p class="mb-0 d-none d-lg-block list-creater-name lh-lg">
               Created By {{ listInfo?.user?.firstname }}
               {{ listInfo?.user?.lastname }} 
            </p>
         </div>
         
      </div>
      <div class="d-flex align-items-center btns-wrapper" *ngIf="!isMyCreatedList" >

         <ng-container *ngIf="userRole !== 'ANONYMOUS'; else lockWatchlistBtn">
            <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn copy-to-watchlist-btn" (click)="openCopyToWatchlistPopup()" >
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="#1bad4e" >
                  <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
               </svg>
               <span class="d-none d-lg-block">
                  {{ isPublicListAddedToWatchlist ? "Copied to Watchlist" : "Copy to Watchlist" }}
               </span>
            </button>
         </ng-container>
         <ng-template #lockWatchlistBtn>
            <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn copy-to-watchlist-btn lock-btn" (click)="navigateToLoginScreen()">
               <svg class="lock-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#1bad4e" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
               </svg>
               <span class="d-none d-lg-block">
                  Copy to Watchlist
               </span>
            </button>
         </ng-template>

         <ng-container *ngIf="userRole !== 'ANONYMOUS'; else lockShareBtn1">
            <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn share-btn" >
               <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M11.338 10.762a2.6 2.6 0 0 0-1.936.858L5.09 8.95a2.6 2.6 0 0 0 0-1.9l4.312-2.67c.479.526 1.17.858 1.936.858a2.62 2.62 0 0 0 2.62-2.62A2.62 2.62 0 0 0 11.337 0a2.622 2.622 0 0 0-2.44 3.568l-4.311 2.67a2.6 2.6 0 0 0-1.937-.857A2.62 2.62 0 0 0 .031 8a2.62 2.62 0 0 0 2.62 2.619c.766 0 1.456-.331 1.936-.858l4.311 2.67A2.622 2.622 0 0 0 11.338 16a2.62 2.62 0 0 0 2.62-2.619 2.62 2.62 0 0 0-2.62-2.619M9.674 2.62c0-.918.747-1.664 1.664-1.664.918 0 1.664.746 1.664 1.664s-.746 1.664-1.664 1.664A1.666 1.666 0 0 1 9.674 2.62M2.65 9.664A1.666 1.666 0 0 1 .986 8c0-.918.747-1.664 1.664-1.664.918 0 1.664.746 1.664 1.664S3.568 9.664 2.65 9.664m7.024 3.717c0-.917.747-1.664 1.664-1.664.918 0 1.664.747 1.664 1.664 0 .918-.746 1.664-1.664 1.664a1.666 1.666 0 0 1-1.664-1.664" fill="#1BAD4E" />
               </svg>
               <span class="d-none d-lg-block">Share</span>
            </button>
         </ng-container>
         <ng-template #lockShareBtn1>
            <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn share-btn lock-btn" (click)="navigateToLoginScreen()">
               <svg class="lock-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#1bad4e" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
               </svg>
               <span class="d-none d-lg-block">Share</span>
            </button>
         </ng-template>

      </div>
      <div class="d-flex align-items-center btns-wrapper" *ngIf="isMyCreatedList" >
         <button (click)="openCreateListPopup(listInfo)" class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn edit-btn" >
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
               <g clip-path="url(#clip0_13469_29163)" fill="#1BAD4E">
                  <path d="M15.833 10.038a.833.833 0 0 0-.833.833v6.667a.834.834 0 0 1-.833.833H2.5a.834.834 0 0 1-.833-.833V5.87c0-.459.373-.833.833-.833h6.667a.833.833 0 1 0 0-1.667H2.5A2.503 2.503 0 0 0 0 5.87v11.667c0 1.378 1.122 2.5 2.5 2.5h11.667c1.378 0 2.5-1.122 2.5-2.5V10.87a.833.833 0 0 0-.834-.833" />
                  <path d="M7.813 9.279a.4.4 0 0 0-.114.212l-.59 2.947a.418.418 0 0 0 .492.49l2.945-.59a.4.4 0 0 0 .214-.113l6.593-6.594-2.946-2.945zM19.388.648a2.085 2.085 0 0 0-2.946 0L15.29 1.802l2.946 2.946 1.153-1.154c.394-.392.61-.916.61-1.472a2.07 2.07 0 0 0-.61-1.474" />
               </g>
            </svg>
            <span class="d-none d-lg-block">Edit</span>
         </button>

         <ng-container *ngIf="userRole !== 'ANONYMOUS'; else lockShareBtn2">
            <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn share-btn" >
               <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M11.338 10.762a2.6 2.6 0 0 0-1.936.858L5.09 8.95a2.6 2.6 0 0 0 0-1.9l4.312-2.67c.479.526 1.17.858 1.936.858a2.62 2.62 0 0 0 2.62-2.62A2.62 2.62 0 0 0 11.337 0a2.622 2.622 0 0 0-2.44 3.568l-4.311 2.67a2.6 2.6 0 0 0-1.937-.857A2.62 2.62 0 0 0 .031 8a2.62 2.62 0 0 0 2.62 2.619c.766 0 1.456-.331 1.936-.858l4.311 2.67A2.622 2.622 0 0 0 11.338 16a2.62 2.62 0 0 0 2.62-2.619 2.62 2.62 0 0 0-2.62-2.619M9.674 2.62c0-.918.747-1.664 1.664-1.664.918 0 1.664.746 1.664 1.664s-.746 1.664-1.664 1.664A1.666 1.666 0 0 1 9.674 2.62M2.65 9.664A1.666 1.666 0 0 1 .986 8c0-.918.747-1.664 1.664-1.664.918 0 1.664.746 1.664 1.664S3.568 9.664 2.65 9.664m7.024 3.717c0-.917.747-1.664 1.664-1.664.918 0 1.664.747 1.664 1.664 0 .918-.746 1.664-1.664 1.664a1.666 1.666 0 0 1-1.664-1.664" fill="#1BAD4E" />
               </svg>
               <span class="d-none d-lg-block">Share</span>
            </button>
         </ng-container>
         <ng-template #lockShareBtn2>
            <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn share-btn lock-btn" (click)="navigateToLoginScreen()">
               <svg class="lock-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="#1bad4e" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
               </svg>
               <span class="d-none d-lg-block">Share</span>
            </button>
         </ng-template>

         <button class="btn d-flex align-items-center justify-content-center shadow-none gap-lg-2 header-btn delete-btn" (click)="openDeleteListPopup()" >
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
               <g clip-path="url(#clip0_13469_22961)" fill="#E72025" fill-opacity=".6" >
                  <path d="M13.625 1.875h-2.344v-.469C11.281.631 10.651 0 9.875 0h-3.75C5.35 0 4.719.63 4.719 1.406v.469H2.375a1.407 1.407 0 0 0-.437 2.743l.836 10.093c.06.723.676 1.289 1.401 1.289h7.65c.726 0 1.341-.566 1.401-1.29l.836-10.093c.562-.184.97-.713.97-1.336 0-.775-.632-1.406-1.407-1.406m-7.969-.469a.47.47 0 0 1 .469-.468h3.75a.47.47 0 0 1 .469.468v.469H5.656zm6.636 13.227a.47.47 0 0 1-.467.43h-7.65a.47.47 0 0 1-.467-.43l-.824-9.945h10.232zM13.625 3.75H2.375a.47.47 0 0 1 0-.937h11.25a.47.47 0 0 1 0 .937" />
                  <path d="m6.124 13.627-.469-7.562a.47.47 0 0 0-.935.058l.468 7.562a.469.469 0 0 0 .936-.058M8 5.625a.47.47 0 0 0-.469.469v7.562a.469.469 0 1 0 .938 0V6.094A.47.47 0 0 0 8 5.625m2.842.001a.47.47 0 0 0-.497.439l-.47 7.562a.469.469 0 0 0 .937.058l.468-7.562a.47.47 0 0 0-.438-.497" />
               </g>
            </svg>
            <span class="d-none d-lg-block">Delete</span>
         </button>
      </div>
   </div>
   
   <p class="mb-0 d-flex d-lg-none align-items-center py-1 px-3 gap-2 rounded-pill followers-chip" >
      <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M6.7.568a3.71 3.71 0 0 0-3.698 3.704 3.7 3.7 0 0 0 1.571 3.02c-2.211.886-3.78 3.048-3.78 5.57a.583.583 0 0 0 1.167 0 4.827 4.827 0 0 1 4.836-4.835c.935 0 1.802.267 2.54.724a.585.585 0 1 0 .616-.996 6 6 0 0 0-1.066-.506 3.7 3.7 0 0 0 1.518-2.977A3.715 3.715 0 0 0 6.7.568m0 1.168a2.53 2.53 0 0 1 2.538 2.536A2.526 2.526 0 0 1 6.7 6.804a2.52 2.52 0 0 1-2.53-2.532A2.525 2.525 0 0 1 6.7 1.736m5.904 7.001a.58.58 0 0 0-.474.268l-2.01 3.014-1.262-.845a.586.586 0 1 0-.65.974l1.753 1.167a.584.584 0 0 0 .809-.164l2.335-3.501a.583.583 0 0 0-.5-.913" fill="#1BAD4E" />
      </svg>
      <span class="text-nowrap">
         {{ listInfo.followers_count > 1 ? listInfo.followers_count + " Followers" : listInfo.followers_count + " Follower" }}
      </span>
   </p>
   <div class="d-flex align-items-center gap-2 gap-lg-3 mt-lg-2" *ngIf="!isMyCreatedList" >

      <ng-container *ngIf="userRole !== 'ANONYMOUS'; else lockFollowBtn">
         <button class="btn d-flex align-items-center shadow-none justify-content-center gap-2 header-btn follow-btn" (click)="toggleFollow()" >
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="buttonLoader" >
               <span class="visually-hidden">Loading...</span>
            </div>
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!isFollowing" >
               <g clip-path="url(#clip0_13469_21572)" fill="#fff">
                  <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18.45c-4.66 0-8.45-3.79-8.45-8.45S5.34 1.55 10 1.55s8.45 3.79 8.45 8.45-3.79 8.45-8.45 8.45" />
                  <path d="M13.872 9.156h-3.098V6.058a.774.774 0 1 0-1.55 0v3.098H6.127a.774.774 0 1 0 0 1.55h3.099v3.098a.774.774 0 1 0 1.549 0v-3.099h3.098a.774.774 0 1 0 0-1.549" />
               </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff" *ngIf="isFollowing" >
               <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
            </svg>
            <span>
               {{ isFollowing ? "Following" : "Follow" }}
            </span>
         </button>
      </ng-container>
      <ng-template #lockFollowBtn>
         <button class="btn d-flex align-items-center shadow-none justify-content-center gap-2 header-btn follow-btn lock-btn" (click)="navigateToLoginScreen()">
            <svg class="lock-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="white" d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
            </svg>
            <span> Follow </span>
         </button>
      </ng-template>

      <button type="button" class="btn shadow-none toltip-btn p-0" placement="top" ngbTooltip="Tooltip on top">
         <i class="bi bi-question-circle"></i>
      </button>
   </div>
   <p class="mb-0 description-para" *ngIf="listInfo.description">
      {{ displayDescription }}
      <button *ngIf="listInfo.description.length > charactersLimit" (click)="toggleTruncate()" class="bg-transparent border-0 fw-bold text-decoration-underline p-0">
         {{ isTruncated ? 'read more' : 'read less' }}
      </button>
   </p>
</header> -->