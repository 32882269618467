<section
   class="section"
   [ngClass]="showSymbolsList ? 'mt-4' : ''"
   *ngIf="topLists && topLists.length > 0"
>
   <header
      class="d-flex align-items-center mb-3 mb-lg-4 gap-3 justify-content-between"
   >
      <ng-container *ngIf="!showSymbolsList">
         <h3 class="section-heading mb-0 lh-1">
            {{ sectionHeading }}
         </h3>
         <a
            class="btn d-flex align-items-center gap-2 text-nowrap py-2 px-3 view-all-btn"
            [routerLink]="['/public-lists']"
            [queryParams]="{ lists: 'all' }"
            queryParamsHandling="merge"
         >
            <span>View All</span>
            <i class="bi bi-arrow-right"></i>
         </a>
      </ng-container>
      <ng-container *ngIf="showSymbolsList">
         <h3 class="section-heading mb-0 lh-base">User Collections</h3>
         <a
            class="btn p-0 shadow-none text-nowrap view-all-btn"
            [routerLink]="['/public-lists']"
            [queryParams]="{ lists: 'all' }"
            queryParamsHandling="merge"
         >
            View All <i class="bi bi-arrow-right"></i>
         </a>
      </ng-container>
   </header>
   <!-- <article class="text-container mb-3 p-3" *ngIf="showMessage">
      <div class="d-flex align-items-start mb-2 gap-2">
         <p class="mb-0">
            Curated lists are <span>Public created lists</span> which includes exclusive stocks selected by the creator.
         </p>
         <button class="btn p-0 shadow-none" (click)="hideMessage()">
            <i class="bi bi-x-lg"></i>
         </button>
      </div>
      <small class="d-block">You can follow, share and add this list to your watchlist.</small>
   </article> -->
   <div class="curated-list-container table-responsive">
      <div class="p-2" *ngIf="listLoader">
         <ngx-skeleton-loader
            count="10"
            appearance="line"
            animation="progress"
            [theme]="{
               height: '50px',
               width: '100%',
               'border-radius': '8px',
               margin: '0px'
            }"
         >
         </ngx-skeleton-loader>
      </div>

      <table class="table mb-0" *ngIf="!listLoader">
         <thead>
            <tr>
               <th scope="col">Name</th>
               <th scope="col" class="d-none d-md-table-cell">Followers</th>
               <th scope="col" class="text-end">Stocks</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let list of topLists; trackBy: trackByFn">
               <td class="align-middle">
                  <a
                     class="d-flex flex-column justify-content-center text-decoration-none"
                     routerLink="/list-details/{{ makeSlug(list) }}"
                  >
                     <h6
                        class="mb-0 line-clamp-1 lh-base text-capitalize list-name"
                     >
                        {{ list.name }}
                     </h6>
                     <p
                        class="mb-0 line-clamp-1 lh-base list-creator-name"
                        *ngIf="list.user?.firstname"
                     >
                        By {{ list.user?.firstname }} {{ list.user?.lastname }}
                     </p>
                     <p
                        class="mb-0 line-clamp-1 lh-base list-creator-name"
                        *ngIf="!list.user?.firstname"
                     >
                        -
                     </p>
                     <p class="mb-0 list-followers d-md-none">
                        {{ list.followers_count }} Followers
                     </p>
                  </a>
               </td>
               <td class="align-middle d-none d-md-table-cell">
                  <a
                     class="d-flex align-items-center justify-content-center text-decoration-none"
                     routerLink="/list-details/{{ makeSlug(list) }}"
                  >
                     <span class="list-followers">{{
                        list.followers_count
                     }}</span>
                  </a>
               </td>
               <td class="align-middle">
                  <a
                     class="d-flex flex-column justify-content-center gap-1 text-decoration-none"
                     routerLink="/list-details/{{ makeSlug(list) }}"
                  >
                     <ng-container
                        *ngIf="
                           list.public_list_holdings.length > 0;
                           else noHoldings
                        "
                     >
                        <ul
                           role="list"
                           class="d-flex align-items-center justify-content-end stocks-list"
                        >
                           <li
                              class="bg-light d-block overflow-hidden"
                              *ngFor="
                                 let holding of list.public_list_holdings.slice(
                                    0,
                                    3
                                 )
                              "
                           >
                              <img
                                 [src]="holding.logo_url"
                                 [alt]="holding.holding_name"
                                 class="d-block img-fluid"
                                 *ngIf="holding.logo_url"
                              />
                              <span
                                 class="d-flex align-items-center justify-content-center fs-6 text-black fw-bold"
                                 *ngIf="!holding.logo_url"
                              >
                                 {{ holding.holding_name?.charAt(0) }}
                              </span>
                           </li>
                        </ul>
                        <span
                           *ngIf="list.public_list_holdings.length > 3"
                           class="d-block text-end more-span"
                           >+{{
                              list.public_list_holdings.length - 3
                           }}
                           more</span
                        >
                     </ng-container>
                     <ng-template #noHoldings>
                        <span class="d-block text-end more-span">0 stocks</span>
                     </ng-template>
                  </a>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</section>
