<!-- <section class="company-name-section overflow-hidden" *ngIf="complianceStatus == 'LOCKED'">
    <div>
        <h2 class="mb-0 p-3 p-lg-4 screener-section-title">Compliance Status</h2>
        <div class=" d-flex align-items-center justify-content-center status-div text-center locked-status">
            <img src="../../../../assets/images/complanise-locked.svg" alt="locke image" class="lock-image me-3">
            <div class="locked-text">Locked</div>
        </div>
        <div class="status-text-container">
           <p class="mb-0">
               Sign up now and get Premium access
           </p>
        </div>
        <div class="btns-container">
            <button class="btn report-button w-100 shadow-none text-white fw-bold" [routerLink]="['/authentication/sso-login']">
               Sign up for free
            </button>
        </div>
     </div>
</section> -->

<section
   class="company-name-section overflow-hidden"
   *ngIf="complianceStatus != 'LOCKED'"
>
   <div>
      <div class="d-flex alin-items-center">
         <h2 class="mb-0 p-4 d-none d-lg-block screener-section-title">
            Compliance Status
         </h2>
      </div>
      <div
         class="status-div text-center"
         [ngClass]="{
            'halal-status': complianceStatus == 'COMPLIANT',
            'not-halal-status': complianceStatus == 'NON_COMPLIANT',
            'doubtful-status': complianceStatus == 'QUESTIONABLE',
            'not-covered-status': complianceStatus == 'NOT_UNDER_COVERAGE'
         }"
      >
         {{ statusValue }}
         <div
            class="mt-2 d-none d-lg-block"
            *ngIf="complianceStatus == 'COMPLIANT'"
         >
            <div class="halalstoks">
               <div class="status__starts">
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 1 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 2 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 3 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 4 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 5 }"
                  ></div>
               </div>
            </div>
         </div>
         <div class="bubble-wrapper" *ngIf="isSample && stockType === 'stock'">
            <app-sample-bubble
               [bubbleContent]="statusText"
               tooltipPosition="bottom"
            ></app-sample-bubble>
         </div>
      </div>
      <div
         class="d-lg-none halal-ratings-div"
         *ngIf="complianceStatus == 'COMPLIANT'"
      >
         <h6
            class="lh-1 d-flex align-items-center justify-content-center halal-ratings-text"
         >
            <span>Musaffa Halal Rating</span>
            <i
               class="bi bi-info-circle ms-2"
               alt="info-icon"
               ngbTooltip="Based on AAOIFI Methodology"
               placement="top"
               tooltipClass="hover__toltip"
            >
            </i>
         </h6>
         <div>
            <div class="halalstoks">
               <div class="status__starts">
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 1 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 2 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 3 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 4 }"
                  ></div>
                  <div
                     class="stars"
                     [ngClass]="{ active: complianceRanking >= 5 }"
                  ></div>
               </div>
            </div>
         </div>
      </div>

      <ng-container *ngIf="stockType === 'etf'">
         <div
            class="d-lg-flex align-items-start cba-comment-text pt-3 pt-lg-4 px-3 px-lg-4"
            *ngIf="cbaComment"
         >
            <!-- <div class="icon-wrapper d-none d-lg-flex align-items-center justify-content-center me-3">
               <i class="bi bi-exclamation-triangle"></i>
            </div> -->
            <p
               class="text-center text-md-start mb-0 p-3 rounded-3 comment-text"
            >
               One of the holdings of this ETF fails from Core Business Activity
               (CBA) screening. The core business activity of this holding is
               strictly not allowed according to Shariah rules. Thus, this ETF
               is categorized as Not Halal. Please, check the Detailed report
               section for Core Business Activity (CBA) screening results.
            </p>
         </div>
      </ng-container>

      <!-- <div
         class="text-center info-div"
         *ngIf="complianceStatus != 'NOT_UNDER_COVERAGE'"
      >
         <button
            *ngIf="page === 'stock'"
            (click)="openComplianceHistoryModal()"
            class="history-button"
         >
            View Compliance History
         </button>
         <p class="mb-0">Based on AAOIFI standards</p>
         <p class="mb-0">Last updated: {{ lastUpdated }}</p>
         <p class="mb-0" *ngIf="stockType === 'stock'">
            Source: {{ quarterText }}
         </p>
      </div> -->

      <ng-container *ngIf="stockType === 'stock'">
         <div
            class="d-none d-lg-block status-text-container"
            *ngIf="handleStatusParaVisibility(complianceStatus)"
         >
            <p class="mb-0" *ngIf="complianceStatus == 'COMPLIANT'">
               Musaffa Halal Rating is a proprietary ranking metric that
               compares all stocks in a given country from lowest to highest
               Shariah compliance standing.
            </p>
            <p class="mb-0" *ngIf="complianceStatus == 'NON_COMPLIANT'">
               This stock is currently categorized as NOT HALAL so Musaffa does
               not recommend investors to trade this stock at this moment.
            </p>
            <p class="mb-0" *ngIf="complianceStatus == 'QUESTIONABLE'">
               This stock is currently categorized as DOUBTFUL so Musaffa does
               not recommend investors to trade this stock at this moment.
            </p>
         </div>
      </ng-container>
      <ng-container
         *ngIf="
            userRole === 'PREMIUM' &&
            stockType === 'stock' &&
            complianceStatus !== 'NOT_UNDER_COVERAGE' &&
            minDuration < diffDays
         "
      >
         <div class="d-none d-lg-block status-text-container">
            <button
               [disabled]="loading"
               class="btn mx-auto card-btn"
               (click)="openConfirmationPopup(updateRequestConfirmationPopup)"
            >
               <span *ngIf="!loading">Request Report Update</span>
               <div class="d-flex justify-content-center" *ngIf="loading">
                  <div class="spinner-border" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
               </div>
            </button>
         </div>
      </ng-container>
   </div>
</section>
<ng-template #updateRequestConfirmationPopup let-modal>
   <section class="centered">
      <header class="heading px-3 px-lg-4 pt-3 pt-lg-4">
         <article class="article text-center">
            <h5>
               Send a request to update Compliance Report of <b>{{ ticker }}</b
               >?
            </h5>
         </article>
      </header>
      <!-- <div class="px-3 px-lg-4 py-1">
         <div>
            <ng-container>
               <p>
                  Send a request to update Compliance Report of <b>{{ticker}}</b>?
               </p>
            </ng-container>
         </div>
      </div> -->
      <div class="">
         <div
            class="d-flex align-items-center justify-content-center btns-container"
         >
            <button
               type="button"
               class="btn mx-1 shadow-none modal-btn modal-btn-cancel rounded"
               (click)="closeConfirmationPopup()"
            >
               <span>Cancel</span>
            </button>
            <button
               type="button"
               class="btn mx-1 shadow-none modal-btn modal-btn-confirm rounded"
               (click)="reportRequest()"
            >
               <span>Okay</span>
            </button>
         </div>
      </div>
   </section>
</ng-template>

<!-- <ng-template #showCompliantHistory let-modal>
   <app-compliance-history
      [symbol]="ticker"
      (closeModalEmitter)="closeComplianceHistoryModal()"
      [complianceHistorydata]="complianceHistorydata"
      [isLoading]="isComplianceHistoryLoading"
   ></app-compliance-history>
</ng-template> -->
