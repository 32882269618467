<section class="content-padding">
   <div class="container">
      <header class="d-flex align-items-center justify-content-between gap-3 section-header">
         <h2 class="mb-0 section-main-heading">Academy</h2>
         <!-- <a class="btn rounded-3 text-white text-nowrap py-2 px-3 see-all-btn" routerLink="/academy-articles">See All</a> -->
      </header>
      <ng-container *ngIf="loading">
         <ul role="list" class="px-md-4 px-xxl-0 gap-3 gap-lg-4 gap-xl-5 loader-container">
            <li *ngFor="let i of [1,2,3,4,5,6]" class="loader-card">
               <ngx-skeleton-loader
                  count="1" appearance="line" animation="progress"
                  [theme]="{ height: '180px', width: '100%' }" >
               </ngx-skeleton-loader>
               <ngx-skeleton-loader
                  count="1" appearance="line" animation="progress"
                  [theme]="{ height: '20px', width: '100%' }" >
               </ngx-skeleton-loader>
               <ngx-skeleton-loader
                  count="1" appearance="line" animation="progress"
                  [theme]="{ height: '50px', width: '100%', 'margin-bottom': '0px' }" >
               </ngx-skeleton-loader>
            </li>
         </ul>
      </ng-container>
      <ul role="list" class="d-flex px-xxl-0 gap-3 gap-lg-4 gap-xl-5 article-cards-container" *ngIf="!loading">
         <li *ngFor="let article of articles; trackBy: trackByAcademyId" class="bg-white d-block article-card">
            <a class="text-decoration-none" [href]="article.link" target="_blank">
               <div class="article-image-wrapper overflow-hidden">
                  <img [src]="article.image_url" [alt]="article.title" class="d-block mx-auto img-fluid" *ngIf="article.image_url && article.image_url !== 'false'">
                  <img src="/assets/images/dashboard/article-placeholder-image.jpg" [alt]="article.title" class="d-block mx-auto img-fluid" *ngIf="!article.image_url || article.image_url === 'false'">
               </div>
               <div class="d-flex align-items-center justify-content-between pt-1 px-3">
                  <h3 class="lh-1 article-date">{{ article.published_at | date : "medium" }}</h3>
                  <!-- <svg class="bookmark-svg" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M16.899 24.229c-.58 0-1.142-.206-1.586-.578l-5.469-4.6-5.47 4.6a2.47 2.47 0 0 1-2.625.348 2.47 2.47 0 0 1-1.423-2.233V4.461c0-1.158.452-2.248 1.27-3.067A4.3 4.3 0 0 1 4.665.123h10.36a4.3 4.3 0 0 1 3.067 1.27 4.3 4.3 0 0 1 1.27 3.068v17.305c0 .954-.558 1.83-1.423 2.233a2.44 2.44 0 0 1-1.04.23m-7.055-7.052c.186 0 .372.064.523.19l5.992 5.04a.85.85 0 0 0 .894.119.84.84 0 0 0 .484-.76V4.46c0-.725-.282-1.406-.795-1.918a2.7 2.7 0 0 0-1.918-.795H4.664c-.724 0-1.406.282-1.918.795a2.7 2.7 0 0 0-.795 1.918v17.305c0 .325.19.623.484.76a.85.85 0 0 0 .893-.119l5.993-5.04a.8.8 0 0 1 .523-.19" fill="#CACACA"/>
                  </svg> -->
               </div>
               <p class="mb-0 article-title px-3 pb-3">{{ article.title }}</p>
            </a>
         </li>
      </ul>

      <a class="btn d-block rounded-3 text-white text-nowrap py-2 px-3 mt-4 mx-auto see-all-btn" routerLink="/academy-articles">
         <span>Read More</span>
      </a>
   </div>
   
</section>