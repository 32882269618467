import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccountDetailsData } from '../../../models/account-details-response.model';

@Component({
   selector: 'app-portfolio-value',
   templateUrl: './portfolio-value.component.html',
   styleUrls: ['./portfolio-value.component.scss'],
})
export class PortfolioValueComponent implements OnInit {
   @Input() accountDetails: AccountDetailsData | undefined;
   _loading: boolean = false;
   @Input() set loading(value: boolean) {
      this._loading = value;
   }
   get loading(): boolean {
      return this._loading;
   }

   constructor(private cdr: ChangeDetectorRef) {}

   ngOnInit(): void {}
}
