import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { WatchlistService } from '../../services/watchlist.service';
import { HalalCollectionService } from 'src/app/features/halal-collection/services/halal-collection.service';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
   selector: 'app-copy-to-watchlist-popup',
   templateUrl: './copy-to-watchlist-popup.component.html',
   styleUrls: ['./copy-to-watchlist-popup.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyToWatchlistPopupComponent implements OnInit {
   private destroy$ = new Subject<void>();
   @Input() mainHeading: string = 'Heading'
   @Input() popupDescription: string = 'Description'
   @Input() successHeading: string = 'Success Heading'
   @Input() successDescription: string = 'Success Description'
   buttonLoader: boolean = false;
   errorMessage: string = '';
   selectedCountry!: string;
   selectedCollection!: string
   form = {
      watchlistName: '',
   };
   isWatchlistCopied: boolean = false

   constructor(
      private watchlistService: WatchlistService,
      private halalCollection: HalalCollectionService,
      private route: ActivatedRoute,
      private cdr: ChangeDetectorRef,
      private router: Router,
   ) { }

   async ngOnInit(): Promise<void> {

      this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
         this.selectedCountry = params.get('country') as string;
         this.selectedCollection = params.get('collection') as string;

         this.form.watchlistName = `${this.selectedCollection[0].toUpperCase()}${this.selectedCollection.slice(1).toLowerCase()} Collection`
      })
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   closePopup() {
      this.watchlistService.closeCopyToWatchlistPopup()
   }

   async copyCollectionToWatchlist(copyToWatchlistForm: any) {
      this.buttonLoader = true;
      this.errorMessage = ''

      const watchList = copyToWatchlistForm.value.watchlistName

      try {
         const response: any = await lastValueFrom(this.halalCollection.copyToWatchlist(this.selectedCollection, watchList, this.selectedCountry))

         if (response.status) {
            await this.fetchAllWatchList();
            this.isWatchlistCopied = true
         } else {
            this.errorMessage = 'Watchlist name already exist';
            this.isWatchlistCopied = false
         }

      } catch (error) {
         console.error(error);
      } finally {
         this.buttonLoader = false;
         this.cdr.markForCheck()
      }
   }

   async fetchAllWatchList() {
      let res: any = await lastValueFrom(
         this.watchlistService.fetchAllWatchlist()
      );
      if (res.status) {
         this.watchlistService.setWatchlistCount(res.data.length);
      }
   }

   navigateToWatchlistPage() {
      this.closePopup()
      this.router.navigate(['/watchlist-lists']);
   }
}
