import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StockFilterService } from '../../services/stock-filter.service';
import { Subject, takeUntil } from 'rxjs';
import { Country } from 'src/app/models/market-collection/market-collection.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from 'src/app/shared/services/country.service';
import { EtfFilterService } from 'src/app/features/etf-screener/services/etf-filter.service';

@Component({
  selector: 'app-country-filter-popup',
  templateUrl: './country-filter-popup.component.html',
  styleUrls: ['./country-filter-popup.component.scss']
})
export class CountryFilterPopupComponent implements OnInit, OnDestroy {
   @Input() countrySet: string[] = [];

   private destroy$ = new Subject<void>();
   countriesLoader: boolean = false;
   allCountries: Country[] = []
   coveredCountries: Country[] = []
   notCoveredCountries: Country[] = []
   countryForm!: FormGroup
   // countries modal
   countriesModalRef!: NgbModalRef;

   constructor(
      public activeModal: NgbActiveModal,
      private etfFilterService: EtfFilterService,
      private stockFilterService: StockFilterService,
      private formBuilder: FormBuilder,
      private router: Router,
      private route: ActivatedRoute,
      private countryService: CountryService
   ) { }

   ngOnInit(): void {
      this.getCountriesData()

      this.countryForm = this.formBuilder.group({
         country: [localStorage.getItem('dataFilterCountry') || 'US']
      })

      this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
         if (params['country']) {
            this.countryForm.patchValue({ country: params['country'] });
         }
      });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getCountriesData() {
      this.countriesLoader = true;
      this.countryService.getCountries().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            if(response) {
               this.allCountries = response

               if(!this.countrySet.length) {
                  this.coveredCountries = this.allCountries
                     .filter((country: Country) => country.stocksCovered)
                     .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0))

                  this.notCoveredCountries = this.allCountries
                     .filter((country: Country) => !country.stocksCovered)
                     .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0))
               } else {
                  this.coveredCountries = this.allCountries
                     .filter((country: Country) => country.stocksCovered && this.countrySet.includes(country.countryCode))
                     .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0))
                  this.notCoveredCountries = this.allCountries
                     .filter((country: Country) => !country.stocksCovered && this.countrySet.includes(country.countryCode))
                     .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0))
               }
               this.countriesLoader = false;
            }
         },
         error: (error) => {
            console.error(error);
            this.countriesLoader = false;
         },
         complete: () => {
            this.countriesLoader = false;
         },
      })
   }

   onCountryChange() {
      this.updateQueryParams();
   }

   onSubmit() {
      this.updateQueryParams();
   }

   updateQueryParams() {
      const selectedCountry = this.countryForm.get('country')?.value || 'US';

      const foundCountry = this.allCountries.find((country: Country) => country.countryCode === selectedCountry);

      if(foundCountry) {
         this.countryService.setSelectedCountry(foundCountry);
      }

      this.etfFilterService.closeCountriesPopup()
      this.stockFilterService.closeCountriesPopup()

      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: { country: selectedCountry, page: 1, exchanges: null },
         queryParamsHandling: 'merge'
      });
   }

}
