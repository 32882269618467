<section class="container-fluid rounded paywall-container">
   <header>
      <div class="d-lg-none">
         <div class="d-flex align-items-start justify-content-between mb-3">
            <img src="/assets/images/crown-icon.svg" alt="crown-icon" class="img-fluid crown-image">
            <button class="btn p-2 rounded-circle shadow-none close-btn" (click)="activeModal.dismiss(); paywallClosed()">
               <svg xmlns="http://www.w3.org/2000/svg" fill="#5E5E5E" height="2rem" viewBox="0 0 512 512">
                  <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/>
               </svg>
            </button>
         </div>
         <div class="text-wrapper">
            <h2 class="mb-1 premium-heading">{{ title }} </h2>
            <p class="mb-0 decision-text">{{ subTitle }}</p>
         </div>
         <!-- <div class="text-wrapper">
            <h2 class="mb-0 intro-heading"><span class="intro d-block">Introducing</span> Musaffa Premium</h2>
            <p class="decision-text mt-2 mb-4">{{ subTitle }}</p>
            <h3 class="mb-1 user-greeting py-1 px-2 bg-white rounded">Hello, {{ username }}</h3>
            <p class=" into-para py-1 px-2 bg-white rounded">
               {{ featuresIntro }}
            </p>
         </div> -->
      </div>

      <div class="d-flex d-none d-lg-flex align-items-start justify-content-between mb-3 gap-3">
         <div class="d-flex flex-wrap align-items-center gap-3">
            <img src="/assets/images/crown-icon.svg" alt="crown-icon" class="img-fluid crown-image">
            <div class="text-wrapper">
               <h2 class="mb-1 premium-heading">{{ title }} </h2>
               <p class="mb-0 decision-text">{{ subTitle }}</p>
            </div>
            <!-- <div class="text-wrapper">
               <h2 class="mb-0 intro-heading"><span class="intro">Introducing</span> Musaffa Premium</h2>
               <p class="decision-text mt-2 mb-0">{{ subTitle }}</p>
            </div> -->
         </div>
         <button class="btn p-2 rounded-circle shadow-none close-btn" (click)="activeModal.dismiss(); paywallClosed()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#5E5E5E" height="2rem" viewBox="0 0 512 512">
               <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/>
            </svg>
         </button>
      </div>

      <div class="row">
         <div class="col-12 col-xl-8 features-wrapper">
            <!-- <div class="d-none d-lg-block">
               <h3 class="mb-0 user-greeting py-1 px-2 bg-white rounded">Hello, {{ username }}</h3>
               <p class="into-para py-1 px-2 bg-white rounded">
                  {{ featuresIntro }}
               </p>
            </div> -->
            <div class="d-lg-none">
               <app-paywall-offer-card></app-paywall-offer-card>
            </div>
            <div class="paywall-tabs-container overflow-hidden" [ngSwitch]="viewMode">
               <div *ngSwitchCase="'featuresTab'" [@slideRight]>
                  <h5 class="features-heading px-2 py-1 mb-3 mt-2 mt-lg-2 mb-lg-4 rounded">MUSAFFA Premium Features</h5>
                  <div class="features-grid">
                     <article class="d-flex align-items-start gap-3 feature p-3 rounded" *ngFor="let feature of features">
                        <img src="/assets/images/{{feature.icon}}" [alt]="feature.title" class="feature-img">
                        <div>
                           <h6 class="feature-heading">{{ feature.title }}</h6>
                           <p class="mb-0 feature-info">
                              {{ feature.description }}
                           </p>
                        </div>
                     </article>
                  </div>
                  <div class="d-flex justify-content-center align-items-center my-2 paywall-tab-container">
                     <button class="btn shadow-none paywall-tab-btn d-flex align-items-center" (click)="openCountryTab()">
                        <span>View Country Wise Stock Coverage</span>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#212529" viewBox="0 0 448 512">
                           <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                        </svg> -->
                     </button>
                     <div class="paywall-tab-separator"> | </div>
                     <button class="btn shadow-none paywall-tab-btn d-flex align-items-center" (click)="openSupportedBrokeragesTab()">
                        <span>View Supported Brokerages</span>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="#212529" viewBox="0 0 448 512">
                           <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                        </svg> -->
                     </button>
                  </div>
               </div>
               <div class="countries-tab" *ngSwitchCase="'countryTab'" [@slideLeft]>
                  <h5 class="features-heading px-2 py-1 mb-3 mt-4 mt-lg-2 mb-lg-4 rounded">MUSAFFA Country Coverage</h5>
                  <div class="countries-table table-responsive">
                     <table class="table mb-0 bg-white">
                        <thead>
                          <tr class="align-middle">
                            <th class="text-uppercase">Country</th>
                            <th class="text-uppercase">Coverage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="align-middle" *ngFor="let countryInfo of countryWiseCoverage">
                            <th class="px-3 text-nowrap border-end">{{countryMapping[countryInfo.country_code]}}</th>
                            <td class="px-3 text-nowrap">{{countryInfo.coverage}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                           <tr class="align-middle">
                             <td colspan="2" class="text-center p-3">If a stock from these markets isn't covered on Musaffa, you can request its coverage (up to 3 stocks daily) ✅</td>
                           </tr>
                         </tfoot>
                      </table>
                  </div>
                  <button class="btn shadow-none mx-auto paywall-tab-btn d-flex align-items-center justify-content-center gap-2 my-2" (click)="viewMode ='featuresTab'">
                     <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#212529">
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                     </svg>
                     <span>View Musaffa Premium Features</span>
                  </button>
               </div>

               <div class="supported-tab" *ngSwitchCase="'supportedTab'" [@slideLeft]>
                  <h5 class="features-heading px-2 py-1 mb-3 mt-4 mt-lg-2 mb-lg-4 rounded">Supported Brokerages</h5>
                  <div class="supported-container">
                     <app-supported-institutions [showTitle]="false"></app-supported-institutions>
                  </div>
                  <button class="btn shadow-none mx-auto paywall-tab-btn d-flex align-items-center justify-content-center gap-2 my-2" (click)="viewMode ='featuresTab'">
                     <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#212529">
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                     </svg>
                     <span>View Musaffa Premium Features</span>
                  </button>
               </div>
            </div>
         </div>
         <div class="col-12 col-xl-4 pb-3 pb-lg-0 align-self-center btns-and-disclaimer">
            <div class="d-none d-lg-block">
               <app-paywall-offer-card></app-paywall-offer-card>
            </div>
            <div class="d-flex flex-column gap-3">
               <div *ngFor="let plan of plans; let i = index">
                  <label class="radio-price-card d-flex align-items-center w-100" [ngClass]="{'checked-card': selectedPlan === plan.id}">
                     <input (change)="planChange(plan)" type="radio" [value]="plan.id" class="price-input" [(ngModel)]="selectedPlan">
                     <div class="d-flex align-items-center justify-content-between flex-grow-1">
                        <div class="d-flex align-items-center radio-card-text">
                           <h6 class="mb-0 text-uppercase">{{plan.label}}</h6>
                           <div class="d-flex flex-column" *ngIf="plan.value === 'yearly' && yearlyDisplayFormat === 'highlighted_monthly' else defaultPriceFormat;">
                              <span class="year-price" *ngIf="plan.pricePerMonth">{{plan.pricePerMonth | currency: plan.currency}}/Month</span>
                              <span class="month-price">~Billed Annually</span>
                           </div>
                           <ng-template #defaultPriceFormat>
                              <div class="d-flex flex-column">
                                 <span class="year-price">{{plan.price | currency: plan.currency}}</span>
                                 <span class="month-price" *ngIf="plan.pricePerMonth">~{{plan.pricePerMonth | currency: plan.currency}}/Month</span>
                              </div>
                           </ng-template>
                        </div>
                        <div class="ratio ratio-1x1 rounded-circle border border-2 radio-card-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" height="0.625rem" width="0.625rem" viewBox="0 0 448 512" fill="#FFFFFF" *ngIf="selectedPlan">
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                           </svg>
                        </div>
                     </div>
                     <div class="discount" *ngIf="plan.value === 'yearly'">Save {{((totalYearlyCostForMonthlyPlan - plan.price)/totalYearlyCostForMonthlyPlan)*100 | number: "1.0-0"}}%</div>
                  </label>
               </div>
            </div>

            <!-- <div class="d-flex flex-column gap-3">
               <label class="radio-price-card d-flex align-items-center w-100" *ngFor="let plan of offerPlans; let i = index" [ngClass]="{'checked-card': selectedPlan === plan.value}">
                  <input type="radio" [value]="plan.value" class="price-input" [(ngModel)]="selectedPlan">
                  <div class="d-flex align-items-center justify-content-between flex-grow-1">
                     <div class="d-flex align-items-center radio-card-text">
                        <h6 class="mb-0 text-uppercase">{{plan.label}}</h6>
                        <div class="d-flex flex-column">
                           <span class="year-price">{{plan.price}}</span>
                           <span class="month-price">{{plan.priceText}}</span>
                        </div>
                     </div>
                     <div class="ratio ratio-1x1 rounded-circle border border-2 radio-card-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="0.625rem" width="0.625rem" viewBox="0 0 448 512" fill="#FFFFFF" *ngIf="selectedPlan">
                           <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                        </svg>
                     </div>
                  </div>
                  <div class="discount" *ngIf="i === 0">Save 37% + Extra 20% Off</div>
               </label>
            </div> -->

            <div class="mt-4">
               <button (click)="openPaymentLink()" *ngIf="freeTrialDays && freeTrialDays > 0" class="btn rounded-pill paywall-btn shadow-none paywall-btn-filled d-flex align-items-center justify-content-center">
                  Start {{freeTrialDays}}-days Free Trial
               </button>
               <button (click)="openPaymentLink()" *ngIf="freeTrialDays === 0" class="btn rounded-pill paywall-btn shadow-none paywall-btn-filled d-flex align-items-center justify-content-center">
                  Subscribe Now
               </button>
               <!-- <button class="btn rounded-pill paywall-btn shadow-none paywall-btn-filled d-flex align-items-center justify-content-center">
                  Claim Offer & Subscribe
               </button> -->
            </div>

            <p class="text-center disclaimer-text">
               {{ countryDisclaimer }} <br>
               <span>
                  {{ countryValue }}
               </span>
            </p>
            <p class="mb-0 text-center lh-base subscription-text">
               {{ disclaimer }}.
               <span>
                  For information regarding refund and payment policy, please refer to our <a href="https://musaffa.com/terms-conditions.php" target="_blank" class="text-reset text-decoration-underline">Terms of Use</a>
               </span>
            </p>

         </div>
      </div>
   </header>
</section>
