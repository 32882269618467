<section class="bg-white px-3 py-4 p-lg-4 br-10">
   <header class="d-flex align-items-center justify-content-between gap-2 collection-heading">
      <h2 class="mb-0 text-start section-subheading">Super Investors Portfolio</h2>
      <a class="btn d-flex align-items-center gap-1 gap-md-2 shadow-none text-nowrap p-0 view-all-btn" routerLink="/super-investors">
         <span>View All</span>
         <i class="bi bi-arrow-right"></i>
      </a>
   </header>
   <ng-container *ngIf="loading">
      <ul role="list" class="w-100 superinvestors-cards-container" *ngIf="isMobile">
         <li *ngFor="let i of [].constructor(9)" class="br-10 skeleton-loader loader-card"></li>
      </ul>
      <ul role="list" class="w-100 superinvestors-cards-container" *ngIf="!isMobile">
         <li *ngFor="let i of [].constructor(18)" class="br-10 skeleton-loader loader-card"></li>
      </ul>
   </ng-container>
   <ng-container *ngIf="!loading">
      <ul role="list" class="w-100 superinvestors-cards-container">
         <li *ngFor="let superInvestor of superInvestors; trackBy: trackBySuperInvestorsId" class="p-0 d-flex align-items-center overflow-hidden superinvestor-card">
            <a class="text-decoration-none d-flex align-items-center justify-content-between" routerLink="/super-investor-portfolio/{{superInvestor.cik}}">
               <div class="d-flex align-items-center gap-2 gap-lg-3">
                  <div class="superinvestor-image-wrapper bg-light rounded-circle border flex-shrink-0 rounded-2 overflow-hidden">
                     <img [appLazyLoadImage]="superInvestor.profileImg" placeholder="/assets/images/dashboard/stock-lowres.png" [alt]="superInvestor.manager" class="d-block mx-auto img-fluid">
                  </div>
                  <h5 class="mb-0 text-start line-clamp-1 superinvestor-name">{{ superInvestor.manager }}</h5>
               </div>
               <app-halal-circular-progress-card [cardType]="'small-green'" [percentage]="superInvestor.halal_percentage"></app-halal-circular-progress-card>
            </a>
         </li>
      </ul>
      <!-- <a class="btn d-flex align-items-center mx-auto gap-2 shadow-none border-green text-nowrap py-2 px-3 view-all-btn mt-4" *ngIf="isMobile" routerLink="/cabinet/super-investors">
         <span>View All</span>
         <i class="bi bi-arrow-right arrow-icon"></i>
      </a> -->
   </ng-container>
</section>


