<div>
   <!-- <div *ngIf="isPopupOpen">
      <div (click)="setPopup()" class="overlay"></div>
      <ng-container *ngIf="userRole!=='PREMIUM'">

      </ng-container>
      <ng-container *ngIf="userRole==='PREMIUM'">
         <app-place-order [userRole]="userRole" [symbol]="symbol" [exchangeCode]="exchange" [action]="action">
         </app-place-order>
      </ng-container>
   </div> -->


   <div>
      <div class="action-button_container px-3 px-lg-4">
         <button (click)="handlePopup('BUY')" class="btn text-uppercase text-center d-block action-button rounded-3 gradient-btn text-white fw-500 action-button_buy">
            Buy
         </button>
         <button (click)="handlePopup('SELL')" class="btn text-uppercase text-center d-block action-button rounded-3 text-white fw-500 action-button_sell">
            Sell
         </button>
      </div>
   </div>
</div>