import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaywallPopupComponent } from '../paywall-popup/paywall-popup.component';
import { ConditionalRenderingService } from '../../services/conditional-rendering.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { TypesenseService } from '../../services/typesense.service';
import { ApiService } from '../../services/api.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { Router } from '@angular/router';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { AccessControlService } from '../../services/access-control.service';
@Component({
   selector: 'app-premium-sample-popup',
   templateUrl: './premium-sample-popup.component.html',
   styleUrls: ['./premium-sample-popup.component.scss'],
})
export class PremiumSamplePopupComponent implements OnInit {
   componentType$ = this.conditionalRenderingService.componentType$;
   @Input() title: string = '';
   @Input() stockName: string = 'Apple';
   @Input() stockTicker: string = 'AAPL';

   paywallModalRef: any;
   // private componentTypeSubscription!: Subscription;
   reportLoader: boolean = false;
   etfReportLoader: boolean = false

   calculationData: any = null;

   stock: any;
   fundamentals: any;
   complianceData: any;
   results: any;
   renenueRatio: number = 0;
   interestBearingSecurities: number = 0;
   interestBearingDebt: number = 0;
   private subscriptions: Subscription[];
   userRole: string | null = null;

   etfStockName: string = 'SPDR S&P 500 ETF Trust';
   etfId: string = 'VTV'
   etf: any ;
   etfFundamentals: any;
   storedScreenerResponse: any;

   constructor(
      private conditionalRenderingService: ConditionalRenderingService,
      public sampleModal: NgbActiveModal,
      private modalService: NgbModal,
      private typesenseService: TypesenseService,
      private apiService: ApiService,
      private router: Router,
      private analyticsUtils: AnalyticsUtils,
      private sharedService: SharedService,
      private authService: AuthService,
      public accessControlService: AccessControlService
   ) {
      this.subscriptions = [];
      this.fundamentals = {};
      this.stock = {
         companyProfile: {},
         fundamentals: {},
      };
      this.complianceData = {};
      this.etf = {
         etfProfileInfo: {},
         compliance: {}
       };
       this.fundamentals = {};
   }

   ngOnInit(): void {
      this.changeTitle();
      this.getSampleStockInfo();

      this.subscriptions.push(
         this.componentType$.subscribe((data) => {
            if(data === 'etfReport') {
               this.getSampleEtfInfo()
            }

            if(data === 'report') {
               this.getSampleStockInfo();
            }
         })
      )

      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );
   }

   goToLoginPage() {
      this.sampleModal.dismiss();
      this.authService.openAuthPopup()
      // this.router.navigate(['/authentication/login']);
   }

   openPaywallModal() {
      this.sharedService.openUpgradePopup()
      // this.paywallModalRef = this.modalService.open(PaywallPopupComponent, {
      //    centered: true,
      //    modalDialogClass: 'paywall-popup-modal',
      //    size: 'xl',
      // });
   }

   screenerResponseLoaded(event: any) {
      this.storedScreenerResponse = event;
   }

   closeActiveModal() {
      // this.sampleModal.dismiss();
      // console.log('logout');
      this.goToLoginPage();
   }

   ngOnDestroy(): void {
      // if (this.componentTypeSubscription) {
      //   this.componentTypeSubscription.unsubscribe();
      // }

      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   getPriceData(symbols: string[]) {
      this.typesenseService.getPrices(symbols).then((res) => {
         if ([symbols].length > 0) {
            for (const key in res) {
               let priceValue = res[key];
               this.fundamentals = priceValue;
            }
         }
      });
   }

   changeTitle() {
      this.componentType$.subscribe((componentType) => {
         switch (componentType) {
            case 'forecast':
               this.title = 'Stock Forecast';
               this.analyticsUtils.sample_feature_upgrade_tapped(
                  'stock_forecast'
               );
               break;
            case 'checklist':
               this.title = 'Investment Checklist';
               this.analyticsUtils.sample_feature_upgrade_tapped(
                  'investment_checklist'
               );
               break;
            case 'purification':
               this.title = 'Stock Purification';
               this.analyticsUtils.sample_feature_upgrade_tapped(
                  'stock_purification'
               );
               break;
            case 'report':
               this.title = 'Shariah Compliance Report';
               this.analyticsUtils.sample_feature_upgrade_tapped(
                  'shariah_compliance_report'
               );
               break;
            case 'etfReport':
               this.title = 'Shariah Compliance Report';
               this.analyticsUtils.sample_feature_upgrade_tapped(
                  'shariah_compliance_report'
               );
               break;
            default:
               this.title = '';
         }
      });
   }

   async getSampleStockComplianceInfo(companyProfile: any) {
      let data = null;
      try {
         // data = await this.typesenseService.getStockComplianceInfo(
         //    companyProfile.mainTicker
         // );
         const response: any = await this.apiService.getStockComplianceMeta(companyProfile.mainTicker)
         const data: any = response.data
      } catch (e) {
         console.log(e);
      }
      if (!data) {
         this.complianceData = {
            status: 'NOT_UNDER_COVERAGE',
         };
      } else {
         this.complianceData = data;
      }
      // this.getResults(data);
   }

   async getSampleStockInfo() {
      try {
         this.reportLoader = true;
         const companyProfile = await lastValueFrom(
            this.typesenseService.getCompanyProfile(this.stockTicker)
         );

         if (companyProfile) {
            this.stock.companyProfile = companyProfile;
            await this.getSampleStockComplianceInfo(companyProfile);
            this.getPriceData([this.stockTicker]);
         }

         const fundamentals = await lastValueFrom(
            this.typesenseService.getCompanyFundamentals(this.stockTicker)
         );
         this.stock.fundamentals = fundamentals;
      } catch (error) {
         console.error(error);
      } finally {
         this.reportLoader = false;
      }
   }

   // getResults(data: any) {
   //    this.results = {
   //       companyName: this.complianceData.companyName,
   //       compliantRanking: this.complianceData.ranking,
   //       debt: {
   //          debt: this.complianceData.debt,
   //          status: this.complianceData.debt < 30 ? 'Pass' : 'Fail',
   //       },
   //       identifier: this.complianceData.identifier,
   //       impermissibleRevenue:
   //          (this.complianceData.totalRevenue * this.complianceData.notHalal) /
   //          100,
   //       interestBearingAssets:
   //          (this.complianceData.trailing36MonAvrCap *
   //             this.complianceData.securitiesAndAssets) /
   //          100,
   //       interestBearingDebt:
   //          (this.complianceData.trailing36MonAvrCap *
   //             this.complianceData.debt) /
   //          100,
   //       lastUpdate: this.complianceData.lastUpdate * 1000,
   //       questionableRevenue:
   //          (this.complianceData.totalRevenue *
   //             this.complianceData.questionable) /
   //          100,
   //       reportSource: this.complianceData.reportSource,
   //       screeningInfo: {
   //          halal:
   //             100 -
   //             (this.complianceData.notHalal +
   //                this.complianceData.questionable),
   //          notHalal: this.complianceData.notHalal,
   //          questionable: this.complianceData.questionable,
   //          status: null,
   //       },
   //       securitiesAndAssets: {
   //          credit: this.complianceData.securitiesAndAssets,
   //          status:
   //             this.complianceData.securitiesAndAssets < 30 ? 'Pass' : 'Fail',
   //       },
   //       shariahStates: this.complianceData.status,
   //       reportDate: this.complianceData.reportDate,
   //       stock: this.complianceData.stockName,
   //       totalRevenue: this.complianceData.totalRevenue,
   //       trailing36MonAvrCap: this.complianceData.trailing36MonAvrCap,
   //    };

   //    if (this.results.screeningInfo.halal > 100) {
   //       this.results.screeningInfo.halal = -1;
   //    }

   //    let interestBearingAssets = this.results?.interestBearingAssets;
   //    let trailing36MonAvrCap = this.results?.trailing36MonAvrCap;
   //    let interestBearingDebt = this.results?.interestBearingDebt;

   //    this.renenueRatio =
   //       this.complianceData.questionable + this.complianceData.notHalal;

   //    if (
   //       this.complianceData.notHalal != null &&
   //       this.complianceData.questionable != null &&
   //       this.complianceData.notHalal >= 0 &&
   //       this.complianceData.questionable >= 0
   //    ) {
   //       this.results.screeningInfo.status =
   //          this.renenueRatio < 5 ? 'Pass' : 'Fail';
   //    } else {
   //       this.results.screeningInfo = null;
   //    }

   //    if (
   //       this.complianceData.securitiesAndAssets == null ||
   //       this.complianceData.securitiesAndAssets < 0
   //    ) {
   //       this.results.securitiesAndAssets = null;
   //    }

   //    if (this.complianceData.debt == null || this.complianceData.debt < 0) {
   //       this.results.debt = null;
   //    }

   //    this.interestBearingSecurities =
   //       (interestBearingAssets / trailing36MonAvrCap) * 100;
   //    this.interestBearingDebt =
   //       (interestBearingDebt / trailing36MonAvrCap) * 100;
   // }

   calculationFetched(event: any) {
      if (event == null) {
         this.calculationData = null;
      } else {
         this.calculationData = event.data;
      }
   }

   async getSampleEtfInfo() {
      try {
         this.etfId;
         this.etfReportLoader = true;

         const etfProfileInfo = await lastValueFrom(this.typesenseService.etfGetInfo(this.etfId));
         this.etf.etfProfileInfo = etfProfileInfo;

         try {
            const etfComplianceInfo = await this.typesenseService.etfComplianceInfo(this.etfId);
            this.etf.compliance = etfComplianceInfo;
         } catch (error) {
            console.log(error);
         } finally {
            this.etfReportLoader = false;
         }
      } catch (error) {
        console.log(error);
        this.etfReportLoader = false;
      }
  }
}
