<ng-container *ngIf="stockType === 'stock'">
   <section class="profile-card d-flex p-3 p-lg-4 flex-column">
      <h4 class="section-heading">Profile</h4>
   
      <div>
         <p class="mb-3 description-text">
            {{ profileDescription }}
         </p>
         <div class="about-company-container mb-4">
            <p class="mb-2 text-black">
               <span class="fw-600 text-nowrap">Sector :</span> <span class="about-company"> {{ sector ? sector : '-'}}</span>
            </p>
            <p class="mb-0 text-black">
               <span class="fw-600 text-nowrap">Industry :</span> <span class="about-company"> {{ industry ? industry : '-'}}</span>
            </p>
         </div>
         <div class="about-company-container">
            <h4 class="section-subheading">Contact Information</h4>
            <div class="d-flex flex-column align-items-start">
               <p class="mb-2 text-black">
                  <span class="fw-600 text-nowrap">Phone :</span> <span class="about-company"> {{ formattedPhone ? formattedPhone : '-'}}</span>
               </p>
               <p class="mb-2 text-black">
                  <span class="fw-600 text-nowrap">Website :</span> <a class="about-company text-black text-decoration-underline" [href]="website" target="_blank"> {{ website ? website : '-'}}</a>
               </p>
               <p class="mb-0 text-black d-flex align-items-start">
                  <span class="fw-600 text-nowrap">Address :</span> <span class="about-company ms-2"> {{ address ? address : '-'}}</span>
               </p>
            </div>
         </div>
      </div>
   </section>
</ng-container>

<ng-container *ngIf="stockType === 'etf'">
   <section class="profile-card d-flex p-3 p-lg-4 flex-column">
      <h4 class="section-heading">ETF Profile</h4>

      <div>
         <p class="description-text mb-0">
            {{ etfDescription | profileText : showText }}
            <button *ngIf="!showText" (click)="showText = !showText" class="d-inline show-text-btn shadow-none fw-500 ms-1 btn p-0">show more</button >
            <button *ngIf="showText" (click)="showText = !showText" class="d-inline show-text-btn shadow-none fw-500 ms-1 btn p-0" >show less</button >
         </p>
      </div>
   </section>
</ng-container>
