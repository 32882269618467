<section class="content-padding">
   <div class="container">
      <div class="banner px-3 px-md-4 d-flex flex-column align-items-center flex-lg-row justify-content-center">
         <div class="d-grid gap-4 pt-5 pt-lg-0">
            <h2 class="text-center text-lg-start section-main-heading mb-0">
               Download Musaffa <br> on your phone
            </h2>
            <p class="text-center text-lg-start mb-0">
               Start making halal investment decisions now!
            </p>
            <div class="d-flex align-items-center gap-3 gap-lg-4">
               <a class="d-block" href="https://app.musaffa.com/wKcRlYiPpKb" target="_blank">
                  <img src="/assets/images/dashboard/app-store-badge.png" alt="App Store Badge" class="img-fluid">
               </a>
               <a class="d-block" href="https://app.musaffa.com/mcJZc85OpKb" target="_blank">
                  <img src="/assets/images/dashboard/play-store-badge.png" alt="Play Store Badge" class="img-fluid">
               </a>
            </div>
         </div>
         <div class="pt-lg-5 mobile-image-wrapper">
            <img src="/assets/images/dashboard/download-mobile-image.png" alt="Mobile Image" class="img-fluid">
         </div>
      </div>
   </div>
</section>
