import { ViewEncapsulation,Input } from '@angular/core';
import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import Chart from 'chart.js';

@Component({
  selector: 'app-analytics-pie-chart',
  templateUrl: './analytics-pie-chart.component.html',
  styleUrls: ['./analytics-pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsPieChartComponent implements AfterViewInit {
  @ViewChild('chartCanvas') chartCanvas!: ElementRef;
  chart!: Chart;
  @Input() data: any;
  _loading: boolean = false;

  ngAfterViewInit() {
    this.createChart();
  }

  createChart() {
   const ctx = this.chartCanvas.nativeElement.getContext('2d');
   this.chart = new Chart(ctx, {
     type: 'doughnut',
     data: {
       labels: ['Purification Due', 'Purified', 'Remaining'],
       datasets: [{
         data: [4830, 1239, 3600],
         backgroundColor: ['#79CDCA', '#6CE79F', '#A7EACD'],
         borderWidth: 0
       }]
     },
     options: {
       responsive: true,
       maintainAspectRatio: false,
       cutoutPercentage: 75, // Inner circle size
       legend: {
         display: false
       },
       tooltips: {
         enabled: true
       }
     }
   });
 }

 @Input() set loading(value: boolean) {
         this._loading = value;
         if (!this._loading) {
            if (this.data?.length === 0) {
               return;
            }
            this.shariahData = [];
            this.data.forEach((item: any) => {
               if (item.label === 'COMPLIANT') {
                  this.halalData.value = item.value;
               }
               this.shariahData.push({
                  value: item.value,
                  label: this.convertShariahStatus(item.label),
                  color: this.getShariahStatusColor(item.label),
               });
            });
         }
      }
      get loading() {
         return this._loading;
      }

      constructor() {}

      shariahData: any = [];

      halalData: any = {
         value: 0,
         label: 'Halal',
      };

      ngOnInit(): void {}

      getShariahStatusColor(status: string): string {
         switch (status) {
            case 'COMPLIANT':
               return '#53B653';
            case 'NON_COMPLIANT':
               return '#DB494A';
            case 'QUESTIONABLE':
               return '#E9B847';
            case 'NOT_COVERED':
               return '#448DD1';
            default:
               return '';
         }
      }

      convertShariahStatus(status: string): string {
         switch (status) {
            case 'COMPLIANT':
               return 'Halal';
            case 'NON_COMPLIANT':
               return 'Not Halal';
            case 'QUESTIONABLE':
               return 'Doubtful';
            case 'NOT_COVERED':
               return 'Not Covered';
            default:
               return '';
         }
      }


//   // Custom center text
//   centerText = `<div>
//                     <div class="label__chart" style="">22.6</div>
//                     <div class="status halal_status">%HALAL</div>
//                 </div>`;
}
