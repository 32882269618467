import { Component, OnInit, Input } from '@angular/core';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { SocialUser } from '@abacritt/angularx-social-login';
import { LoaderServices } from '../../services/loader.service';
import { UserApiService } from '../../services/user-api.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { FirebaseCloudFunctionsService } from '../../services/firebase-cloud-functions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from '../../services/subscription.service';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { UserOnboardingServeComponent } from '../user-onboarding-serve/user-onboarding-serve.component';

@Component({
   selector: 'app-facebook-login',
   templateUrl: './facebook-login.component.html',
   styleUrls: ['./facebook-login.component.scss'],
})
export class FacebookLoginComponent implements OnInit {
   user!: SocialUser;
   loggedIn!: boolean;
   public redirectURL: any;
   public showButton: boolean = false;
   @Input() click = '';
   @Input() tapEvent = '';
   @Input() completedEvent = '';
   @Input() failEvent = '';

   constructor(
      private authService: SocialAuthService,
      private loader: LoaderServices,
      public userApiService: UserApiService,
      private auth: AuthService,
      private router: Router,
      public authFire: AngularFireAuth,
      private firebaseConfigService: FirebaseRemoteConfigService,
      private subscriptionService: SubscriptionService,
      private analyticsUtils: AnalyticsUtils,
      private modalService: NgbModal
   ) {}

   async ngOnInit() {
      const config = await this.firebaseConfigService.getConfigDefault();
      this.showButton = !!config?.show_fb_login;
      this.authService.authState.subscribe((user) => {
         // localStorage.setItem('authtoken', user.authToken)
         this.user = user;
         this.loggedIn = user != null;
      });
   }

   async signInWithFB() {
      this.analyticsUtils.SOCIAL_AUTH_BTN_TAP(this.tapEvent);
      this.authService
         .signIn(FacebookLoginProvider.PROVIDER_ID)

         // this.authFire.signInWithPopup( new firebase.auth.FacebookAuthProvider())
         .then(async (user: SocialUser) => {
            //   console.log(user);
            let accessToken = user.authToken;

            this.loader.show();
            const req = {
               token: accessToken,
            };
            let response: any = await this.userApiService.facebookLogin(req);
            if (response.status) {
               let token = response.data;
               let isNew = response.is_new;
               await this.auth.setToken(token);
               const userData: any =
                  await this.userApiService.fetchUserDetailsAndSave();

               await this.subscriptionService.setUserSubscriptionInfo();
               const subInfo = SubscriptionService.subscriptionData.getValue();
               if (subInfo.role === 'FREEMIUM') {
                  if (isNew)
                     this.modalService.open(UserOnboardingServeComponent, {
                        centered: true,
                        scrollable: true,
                        size: 'xl',
                        modalDialogClass: 'paywall-popup-modal',
                     });
                  else this.subscriptionService.openPaywallModal();
               }

               this.auth.setLoggedIn();
               this.analyticsUtils.SOCIAL_AUTH_COMPLETED(
                  this.completedEvent,
                  userData.email,
                  response.is_new,
                  AnalyticsUtils.METHOD_FACEBOOK
               );

               if (this.redirectURL) {
                  this.router.navigate([this.redirectURL]).then();
                  this.loader.hide();
                  return;
               }
               this.router.navigate(['/']).then();

               this.loader.hide();
            } else {
               this.loader.hide();
               this.analyticsUtils.SOCIAL_AUTH_FAILED(
                  this.failEvent,
                  '',
                  response.message,
                  null
               );
            }
         });
   }

   signOut(): void {
      this.authService.signOut();
   }
}
