import { BreakpointObserver } from '@angular/cdk/layout';
import {
   Component,
   Input,
   OnInit,
   TemplateRef,
   ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PublicListsService } from 'src/app/shared/services/public-lists.service';
import { CreateListPopupComponent } from '../create-list-popup/create-list-popup.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SortService } from 'src/app/shared/services/sort.service';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { ListDetails } from 'src/app/models/public-list/list-details.model';
import { HoldingData } from 'src/app/models/public-list/list-holdings.model';
import { ToastrService } from 'ngx-toastr';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-list-details-table',
   templateUrl: './list-details-table.component.html',
   styleUrls: ['./list-details-table.component.scss'],
})
export class ListDetailsTableComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();

   @Input() allholdingsList: HoldingData[] = [];
   @Input() listLoader!: boolean;
   @Input() listId: string | number = '';
   @Input() listInfo!: ListDetails;
   isMobile: boolean = false;
   @Input() page: number = 1;
   @Input() pageSize: number = 10;
   @Input() totalHoldingsCount!: number;

   @Input() isMyCreatedList!: boolean;

   userRole: Role = null;
   sortColumn: string = '';
   sortOrder: 'asc' | 'desc' | '' = '';

   deleteHoldingModalRef!: NgbModalRef;
   buttonLoader: boolean = false;
   selectedHolding!: HoldingData;

   @Input() originalholdingsList: HoldingData[] = [];

   constructor(
      private observer: BreakpointObserver,
      private listService: PublicListsService,
      private modalService: NgbModal,
      private router: Router,
      private route: ActivatedRoute,
      private sortService: SortService,
      private toaster: ToastrService,
      public accessControlService: AccessControlService
   ) {}

   ngOnInit(): void {
      console.log("allholdingsList : ",this.allholdingsList);
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.newRole;
         });
      this.observer
         .observe('(max-width: 991px)')
         .pipe(takeUntil(this.destroy$))
         .subscribe((result) => {
            this.isMobile = result.matches;
         });

      // this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      //    this.page = +params['page'] || 1;
      // });
   }


   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }
   trackByListSymbol(index: number, list: HoldingData): number | string {
      return list.symbol;
   }

   onSort(column: string, sortOrder?: string) {
      if (this.userRole === null || this.userRole === 'free') return;

      if (column !== this.sortColumn) {
         this.resetSorting();
      }

      this.sortColumn = column;
      this.page = 1;

      if (sortOrder === 'asc') {
         this.allholdingsList = this.sortService.sortData( this.allholdingsList, column, 'asc' );
         this.sortOrder = 'asc';
      } else if (sortOrder === 'desc') {
         this.allholdingsList = this.sortService.sortData( this.allholdingsList, column, 'desc' );
         this.sortOrder = 'desc';
      } else {
         if (this.sortOrder === 'asc') {
            this.allholdingsList = this.sortService.sortData( this.allholdingsList, column, 'desc' );
            this.sortOrder = 'desc';
         } else if (this.sortOrder === 'desc') {
            this.allholdingsList = [...this.originalholdingsList];
            this.sortOrder = '';
         } else {
            this.allholdingsList = this.sortService.sortData( this.allholdingsList, column, 'asc' );
            this.sortOrder = 'asc';
         }
      }
   }

   resetSorting() {
      this.allholdingsList = [...this.originalholdingsList];
      this.sortOrder = '';
      this.sortColumn = '';
   }

   getColumnSortClasses(column: string) {
      return {
         'bi bi-sort-down-alt': this.sortColumn === column && this.sortOrder === 'asc',
         'bi bi-sort-up': this.sortColumn === column && this.sortOrder === 'desc',
         '': this.sortColumn === column && this.sortOrder === '',
      };
   }

   filtereredHoldings(): any[] {


      if (this.userRole === null) {
         const filteredData = this.allholdingsList.slice(0, 3);
         // console.log("Filtered Holdings (Free User):", filteredData);
         return filteredData;
      } else {
         // console.log("Filtered Holdings (Subscribed User):", this.allholdingsList);
         return this.allholdingsList;
      }
   }


   getPercentChangeClasses(stock: any) {
      return {
         green: stock >= 0,
         red: stock < 0,
      };
   }

   getIconClasses(stock: any) {
      return {
         'bi-caret-up-fill green': stock >= 0,
         'bi-caret-down-fill red': stock < 0,
      };
   }

   openCreateListPopup() {
      const modalRef = this.modalService.open(CreateListPopupComponent, {
         size: 'lg',
      });
      modalRef.componentInstance.data = {
         name: 'update-holdings',
         list: this.listInfo.name,
         id: this.listInfo.id,
         stocks: this.allholdingsList.map((stock: any) => {
            return {
               id: stock.id,
               name: stock.symbol,
               type: stock.type,
            };
         }),
      };
   }

   onPageChange(page: number): void {
      // this.router.navigate([], {
      //    relativeTo: this.route,
      //    queryParams: { page },
      //    queryParamsHandling: 'merge',
      // });
   }

   async deleteStock() {
      this.buttonLoader = true;
      try {
         const response = await this.listService.deleteStock<{
            status: boolean;
            message: string;
         }>(this.selectedHolding.id as string);
         if (response.status) {
            this.toaster.success(response.message);
            this.closeDeleteHoldingModal();
            this.listService.triggerListsReload();
         } else {
            this.toaster.error(response.message);
            this.closeDeleteHoldingModal();
         }
      } catch (error) {
         console.error(error);
      } finally {
         this.buttonLoader = false;
      }
   }

   openDeleteHoldingModal(modal: any, holding: HoldingData): void {
      this.selectedHolding = holding;
      this.deleteHoldingModalRef = this.modalService.open(modal, {
         centered: true,
         windowClass: 'list-details-popup-size',
      });
   }

   closeDeleteHoldingModal() {
      if (this.deleteHoldingModalRef) {
         this.deleteHoldingModalRef.dismiss();
      }
   }
}
