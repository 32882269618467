import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserApiService } from '../../services/user-api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-edit-user-email-number-popup',
   templateUrl: './edit-user-email-number-popup.component.html',
   styleUrls: ['./edit-user-email-number-popup.component.scss'],
})
export class EditUserEmailNumberPopupComponent implements OnInit {
   @Output() close: EventEmitter<void> = new EventEmitter();
   public emailForm: FormGroup;
   public otpForm: FormGroup;

   public isSubmitLoad: boolean = false;
   @Input() allWatchlists: any;
   errorMsg: string = '';
   showErrorMsg: boolean = false;
   showOtp: boolean = false;
   loading = false;
   identifier: string = '';
   @Input() ifShow: boolean = false;
   constructor(
      private userApiService: UserApiService,
      private toaster: ToastrService
   ) {
      this.emailForm = new FormGroup({
         email: new FormControl('', [
            Validators.email,
            Validators.required,
            Validators.pattern(
               /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-z]{1,5})\b/
            ),
         ]),
      });
      this.otpForm = new FormGroup({
         otp: new FormControl('', [
            Validators.required,
            Validators.pattern('[0-9 ]{6}'),
         ]),
      });
   }

   ngOnInit(): void {}

   async sendOtp() {
      this.isSubmitLoad = true;
      try {
         let data: any = await this.userApiService.editUserEmail(
            this.emailForm.value.email
         );

         if (data.status) {
            this.isSubmitLoad = false;
            this.identifier = data.identifier;
            this.toaster.success(data.message);
            this.showOtp = true;
            this.isSubmitLoad = true;
         } else {
            this.errorMsg = data.message;
            // this.showErrorMsg = true;
            this.isSubmitLoad = false;
         }
      } catch (error) {
         console.error(error);
         this.errorMsg = 'Something went wrong';
      } finally {
         this.isSubmitLoad = false;
      }

      // if (data.status) {
      //    this.isSubmitLoad = false;
      //    this.identifier = data.identifier;
      //    this.toaster.success(data.message);
      //    this.showOtp = true;
      // } else if (data.exception === 'Error') {
      //    this.errorMsg = 'something went wrong';
      //    this.showErrorMsg = true;
      //    this.isSubmitLoad = false;
      // } else {
      //    this.errorMsg = data.message;
      //    this.showErrorMsg = true;
      //    this.isSubmitLoad = false;
      // }
   }

   async verifyOtp() {
      this.isSubmitLoad = true;
      try {
         let data: any = await this.userApiService.verifyOtpForUserEmailChange(
            this.identifier,
            this.otpForm.value.otp
         );
         if (data.status) {
            this.isSubmitLoad = false;
            this.toaster.success(data.message);
            this.closeMe();
         } else {
            this.errorMsg = data.message;
            this.showErrorMsg = true;
            this.isSubmitLoad = false;
         }
      } catch (error) {
         console.error(error);
         this.errorMsg = 'Something went wrong';
      } finally {
         this.isSubmitLoad = false;
      }
   }

   addNewMail() {
      this.isSubmitLoad = true;
      if (this.emailForm.valid) {
         this.loading = true;
      } else {
         this.isSubmitLoad = false;
      }
   }

   closeMe() {
      this.close.emit();
      this.emailForm.reset();
      this.otpForm.reset();
      this.showOtp = false;
   }
}
