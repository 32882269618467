<button class="btn text-white rounded-3 d-flex align-items-center shadow-none justify-content-center gap-2 p-0 filter-list-btn" *ngIf="isMobile">
   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#ffffff">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 18c0 .55.45 1 1 1h5v-2H4c-.55 0-1 .45-1 1M3 6c0 .55.45 1 1 1h9V5H4c-.55 0-1 .45-1 1m10 14v-1h7c.55 0 1-.45 1-1s-.45-1-1-1h-7v-1c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1M7 10v1H4c-.55 0-1 .45-1 1s.45 1 1 1h3v1c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1m14 2c0-.55-.45-1-1-1h-9v2h9c.55 0 1-.45 1-1m-5-3c.55 0 1-.45 1-1V7h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1"/>
   </svg>
</button>

<form class="rounded-3 d-flex align-items-center search-container" [formGroup]="filterBySymbolForm" *ngIf="!isMobile">
   <svg class="mx-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 18c0 .55.45 1 1 1h5v-2H4c-.55 0-1 .45-1 1M3 6c0 .55.45 1 1 1h9V5H4c-.55 0-1 .45-1 1m10 14v-1h7c.55 0 1-.45 1-1s-.45-1-1-1h-7v-1c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1M7 10v1H4c-.55 0-1 .45-1 1s.45 1 1 1h3v1c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1m14 2c0-.55-.45-1-1-1h-9v2h9c.55 0 1-.45 1-1m-5-3c.55 0 1-.45 1-1V7h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1"/>
   </svg>
   <input 
      type="input" 
      class="search-input w-100 border-0 pe-3" 
      placeholder="Filter list by symbol"
      formControlName="stockSymbol"
   >
</form>















<!-- <div ngbDropdown class="d-inline-block rounded-3 filter-dropdown-container" *ngIf="!isMobile" display="dynamic" placement="bottom-right" >
   <button class="btn d-flex align-items-center justify-content-between shadow-none" id="filtersDropdown" ngbDropdownToggle>
      <div class="d-flex align-items-center gap-2">
         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#1bad4e">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 18c0 .55.45 1 1 1h5v-2H4c-.55 0-1 .45-1 1M3 6c0 .55.45 1 1 1h9V5H4c-.55 0-1 .45-1 1m10 14v-1h7c.55 0 1-.45 1-1s-.45-1-1-1h-7v-1c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1M7 10v1H4c-.55 0-1 .45-1 1s.45 1 1 1h3v1c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1m14 2c0-.55-.45-1-1-1h-9v2h9c.55 0 1-.45 1-1m-5-3c.55 0 1-.45 1-1V7h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1"/>
         </svg>
         <span>Filter by Symbols</span>
      </div>
      <i class="bi bi-chevron-down"></i>
   </button>
   <div class="rounded-3 mt-2 border-0 filter-dropdown-menu" ngbDropdownMenu aria-labelledby="filtersDropdown">
      <button ngbDropdownItem>Action - 1</button>
      <button ngbDropdownItem>Another Action</button>
      <button ngbDropdownItem>Something else is here</button>
   </div>
</div> -->
