import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reconnect-portfolio-card',
  templateUrl: './reconnect-portfolio-card.component.html',
  styleUrls: ['./reconnect-portfolio-card.component.scss']
})
export class ReconnectPortfolioCardComponent implements OnInit {
  @Input() brokerageConnectionStatus: string = ''
  @Input() brokerageId: string = ''

  constructor() { }

  ngOnInit(): void {
  }

}
