<div class="compliance-chip halal-chip mx-auto d-flex align-items-center justify-content-center flex-column p-2" *ngIf="etFComplianceStatus == 'COMPLIANT'">
   <span class="chip-text mb-1">Halal</span>
   <div class="halal-ratings d-flex align-items-center justify-content-center">
      <!-- <div class="stars" [ngClass]="{ active: etfComplianceRanking >= 1 }"></div>
      <div class="stars" [ngClass]="{ active: etfComplianceRanking >= 2 }"></div>
      <div class="stars" [ngClass]="{ active: etfComplianceRanking >= 3 }"></div>
      <div class="stars" [ngClass]="{ active: etfComplianceRanking >= 4 }"></div>
      <div class="stars" [ngClass]="{ active: etfComplianceRanking >= 5 }"></div> -->
      <i *ngFor="let i of [1,2,3,4,5]" class="fa-star " [ngClass]="{
         'fa-solid': etfComplianceRanking >= i,
         'fa-regular': etfComplianceRanking < i
      }"></i>
   </div>
   
</div>

<div class="compliance-chip not-halal-chip mx-auto p-2" *ngIf="etFComplianceStatus == 'NON_COMPLIANT'">
   <span class="chip-text">Not Halal</span>
</div>

<div class="compliance-chip doubtful-chip mx-auto p-2" *ngIf="etFComplianceStatus == 'QUESTIONABLE'">
   <span class="chip-text">Doubtful</span>
</div>

<div class="compliance-chip locked-chip d-flex align-items-center justify-content-center mx-auto" *ngIf="etFComplianceStatus == 'LOCKED'">
   <img src="/assets/images/complanise-locked.svg" alt="Lock Img" class="img__locked me-2" />
   <span class="chip-text">Locked</span>
</div>

<div class="compliance-chip not-covered-chip mx-auto p-2" *ngIf="etFComplianceStatus == 'NOT_UNDER_COVERAGE' || etFComplianceStatus == 'unknown' || !etFComplianceStatus">
   <span class="chip-text">Not Covered</span>
</div>




<!-- <div class="compliance-chip halal-chip mx-auto d-flex align-items-center justify-content-center flex-column p-2" *ngIf="etFComplianceStatus == 'COMPLIANT'">
   <span class="chip-text mb-0">Halal</span>
   <div class="halal-ratings d-flex align-items-center justify-content-center">
      <i *ngFor="let i of [1,2,3,4,5]" class="fa-star" [ngClass]="{
         'fa-solid': complianceRanking >= i,
         'fa-regular': complianceRanking < i
       }"></i>
   </div>
</div>

<div class="compliance-chip not-halal-chip mx-auto p-2" *ngIf="etFComplianceStatus == 'NON_COMPLIANT'">
   <span class="chip-text">Not Halal</span>
</div>

<div class="compliance-chip doubtful-chip mx-auto p-2" *ngIf="etFComplianceStatus == 'QUESTIONABLE'">
   <span class="chip-text">Doubtful</span>
</div>

<div class="compliance-chip locked-chip d-flex align-items-center justify-content-center mx-auto" *ngIf="etFComplianceStatus == 'LOCKED' || etFComplianceStatus == 'DEFAULT'">
   <img src="/assets/images/complanise-locked.svg" alt="Lock Img" class="img__locked me-2" />
   <span class="chip-text">Locked</span>
</div>

<div class="compliance-chip not-covered-chip mx-auto p-2" *ngIf="etFComplianceStatus == 'NOT_UNDER_COVERAGE' || etFComplianceStatus == 'unknown' || !etFComplianceStatus">
   <span class="chip-text">Not Covered</span>
</div> -->
