import { Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js';
// import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { Store } from '@ngrx/store';
import { selectSidebarExpanded } from 'src/app/stores/sidebar/sidebar.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
   selector: 'app-mini-chart',
   templateUrl: './mini-chart.component.html',
   styleUrls: ['./mini-chart.component.scss'],
})
export class MiniChartComponent implements OnInit, OnChanges, OnDestroy {
   @Input() chartData: any;
   @Input() currency: string = 'USD';
   @Input() loading: boolean = false;
   @Input() selectedRange: string = '1Y';
   @Input() smoothLine: boolean = false;
   // isSidebarOpen: boolean = false;
   private destroy$ = new Subject<void>();

   constructor(private store: Store) { }
   chart: any;
   filteredData: any[] = [];
   timeRanges = [
      '1W',
      '1M',
      '3M',
      '6M',
      '1Y',
   ];
   private resizing = false;
   // selectedRange = '1Y';

   ngOnInit(): void {
      this.store.select(selectSidebarExpanded).pipe(takeUntil(this.destroy$)).subscribe((isExpanded) => {
      if (this.chart) {
        setTimeout(() => {
          this.chart.resize();
        }, 200);
      }
      })
      // Handle window resize events
      window.addEventListener('resize', () => {
         if (this.chart) {
            if (!this.resizing) {
               this.resizing = true;
               setTimeout(() => {
                  this.chart.resize();
                  this.resizing = false;
                  this.chart.resize();
               }, 500);
            }
         }
      });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes['chartData']) {
         this.filterData(this.selectedRange).then(() => {
            this.createChart();
         });
      }
   }

   filterData(range: string) {
      return new Promise((resolve, reject) => {
         this.filteredData = [];
         if (!this.chartData?.length) {
            resolve([]);
         }
         const now = new Date();
         let filteredDates: any[] = [];

         switch (range) {
            // case '1D':
            //    filteredDates = this.chartData.slice(-1);
            //    break;
            case '1W':
               const oneWeekAgo = new Date();
               oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
               filteredDates = this.chartData.filter(
                  (item: any) => new Date(item.date) >= oneWeekAgo
               );
               break;
            case '1M':
               const oneMonthAgo = new Date();
               oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
               filteredDates = this.chartData.filter(
                  (item: any) => new Date(item.date) >= oneMonthAgo
               );
               break;
            case '3M':
               const threeMonthsAgo = new Date();
               threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
               filteredDates = this.chartData.filter(
                  (item: any) => new Date(item.date) >= threeMonthsAgo
               );
               break;
            case '6M':
               const sixMonthsAgo = new Date();
               sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
               filteredDates = this.chartData.filter(
                  (item: any) => new Date(item.date) >= sixMonthsAgo
               );
               break;
            case '1Y':
               filteredDates = this.chartData;
               break;
         }
         this.filteredData = filteredDates;
         resolve(filteredDates);
      })
   }

   createChart() {
      if (!this.filteredData?.length) {
         return;
      }
      const labels = this.filteredData.map((data) => data.date);
      const dataPoints = this.filteredData.map((data) => data.amount);

      if (this.chart) {
         this.chart.destroy();
      }
      this.chart = new Chart('lineChart', {
         type: 'line',
         data: {
            labels: labels,

            datasets: [
               {
                  label: 'Amount',
                  data: dataPoints,
                  borderColor: 'green',
                  backgroundColor: 'transparent', // Shaded area
                  // pointBackgroundColor: 'white',
                  // pointBorderColor: '#fff',
                  borderWidth: 1,
                  // pointRadius: 3, // Smaller points
                  // pointHoverRadius: 5,
               },
            ],
         },
         options: {
            plugins: {
               crosshairLine: true, // Custom plugin to enable the crosshair
            },
            elements: {
               line: { tension: this.smoothLine ? 0.5 : 0 },
               point: {
                  radius: 0,
               },
            },
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
               enabled: true,
               mode: 'index',
               intersect: false,
               callbacks: {
                  title: function (tooltipItems: any) {
                     // Format the date (assuming `labels` contains ISO date strings)
                     const date = new Date(tooltipItems[0].label);
                     return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).format(date);
                  },
                  label: (tooltipItem: any) => {
                     return `${tooltipItem.yLabel.toLocaleString('en-US', { style: 'currency', currency: this.currency })}`;
                  },
               },

            },
            legend: {
               display: false,
            },
            hover: {
               mode: 'index', // Highlight points along the vertical line
               intersect: false,
            },
            scales: {
               xAxes: [
                  {
                     gridLines: {
                        display: false,
                     },
                     ticks: {
                        display: false,
                        //   autoSkip: true, // Automatically skip labels
                        //   maxTicksLimit: 10, // Limit the number of ticks
                        //   maxRotation: 45, // Rotate labels to avoid overlap
                        //   minRotation: 45
                     },
                  },
               ],
               yAxes: [
                  {
                     gridLines: {
                        display: false,
                     },
                     ticks: {
                        display: false,
                        //   callback: function(value: number) {
                        //     return value.toLocaleString(); // Format y-axis values
                        //   }
                     },
                  },
               ],
            },
         },
         plugins: [
            {
               afterDraw: function (chart: any) {
                  if (chart.tooltip._active && chart.tooltip._active.length) {
                     const ctx = chart.ctx;
                     const activePoint = chart.tooltip._active[0];
                     const position = activePoint.tooltipPosition(); // Get tooltip position
                     const x = position.x; // X-coordinate of the tooltip point
                     const topY = chart.chartArea.top;
                     const bottomY = chart.chartArea.bottom;

                     // Draw the vertical line
                     ctx.save();
                     ctx.beginPath();
                     ctx.moveTo(x, topY);
                     ctx.lineTo(x, bottomY);
                     ctx.lineWidth = 1; // Thin line
                     ctx.strokeStyle = 'rgba(0, 0, 0, 0.4)'; // Light gray color
                     ctx.stroke();
                     ctx.restore();
                  }
               }
            }
         ]
      });
   }

   onRangeChange(range: string) {
      this.selectedRange = range;
      this.filterData(range).then(() => {
         this.createChart();
      });
   }
}
