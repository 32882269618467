import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Investment, InvestmentType } from 'src/app/models/zakat.model';
import { ZakatService } from 'src/app/shared/services/zakat.service';

@Component({
  selector: 'app-other-investments',
  templateUrl: './other-investments.component.html',
  styleUrls: ['./other-investments.component.scss']
})

export class OtherInvestmentsComponent implements OnInit, OnDestroy {
   @Input() existingHoldings: Investment[]  = []
  addOtherInvestmentForm!: FormGroup
  otherInvestments: Investment[] = []
  selectedCurrency!: string | null
  isUsersFirstVisit: boolean = true
  selectedInvestmentType: string | null = null;
  investmentInfoModalRef!: NgbModalRef
  formInvestmentType: string | null = null;
  investments: InvestmentType[] = [
   { id: 'short', name: 'Short Term' },
   { id: 'long', name: 'Long Term' },
 ]
  @ViewChild('investmentInfoModalContent', { static: true })
  investmentInfoModalContent!: TemplateRef<NgbModal>;

  addInvestmentDetailsRef!: NgbModalRef
  @ViewChild('addInvestmentDetailContent', { static: true })
  addInvestmentDetailContent!: TemplateRef<NgbModal>;

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private zakatService: ZakatService) {
   this.selectedInvestmentType = this.investments[0].id;
  }

  ngOnInit(): void {
    const hasOpenedPrompt = sessionStorage.getItem('isFirstVisitOnOtherInvestments');
    if (!hasOpenedPrompt) {
      this.openInvestmentInfoModal()
      sessionStorage.setItem('isFirstVisitOnOtherInvestments', 'true');
    }

    this.isUsersFirstVisit = false
    this.selectedCurrency = localStorage.getItem('selectedCurrency');
    if(this.selectedCurrency) {
      this.selectedCurrency = this.selectedCurrency?.replace(/^"|"$/g, '')
    }
    this.addOtherInvestmentForm = this.formBuilder.group({
      otherInvestmentName: ['', Validators.required],
      otherInvestmentValue: ['', Validators.required],
      investmentType: ['', Validators.required],
    })
    this.otherInvestments = this.zakatService.getOtherInvestment().investments;
  }

  ngOnDestroy(): void {
    this.zakatService.setOtherInvestment(this.otherInvestments);
  }

  addOtherInvestment(){
    if (this.addOtherInvestmentForm.valid) {
      const newInvestment = {
        name: this.addOtherInvestmentForm.controls['otherInvestmentName'].value,
        amount: +this.addOtherInvestmentForm.controls['otherInvestmentValue'].value,
        investment_type: this.addOtherInvestmentForm.controls['investmentType'].value
      }

      this.otherInvestments.push(newInvestment);
      this.zakatService.setOtherInvestment(this.otherInvestments);
      this.closeInvestmentDetailsModal()
      this.addOtherInvestmentForm.reset()
    }
  }

  removeOtherInvestment(investment: any) {
    const index = this.otherInvestments.indexOf(investment);
    if (index !== -1) {
      this.otherInvestments.splice(index, 1);
    }
  }

  openInvestmentInfoModal() {
    this.investmentInfoModalRef = this.modalService.open(this.investmentInfoModalContent, {
      centered: true,
      size: 'lg'
    })
  }

  closeInvestmentInfoModal() {
    if (this.investmentInfoModalRef) {
       this.investmentInfoModalRef.dismiss();
    }
  }

  openInvestmentDetailsModal() {
    this.addInvestmentDetailsRef = this.modalService.open(this.addInvestmentDetailContent, {
      centered: true,
    })
  }

  closeInvestmentDetailsModal() {
    if (this.addInvestmentDetailsRef) {
       this.addInvestmentDetailsRef.dismiss();
    }
  }

}
