<div class="upgrade-now">
    <div class="container">
        <div class="model-liner">
            <div class="close text-end">
                <button type="button" class="btn end" (click)="closeMe()"><img src="../../../assets/images/group-343.svg" alt=""></button>
            </div>
            <div class="page">
                <h2>You have reached Starter plan limit</h2>
                <p>You cannot add more than 2 Watchlist with your Starter plan. If you wish to add more Watchlists, please upgrade to our premium plan.</p>
                <div class="watchlist-button ">
                    <button type="button" class="btn cancel" (click)="closeMe()">Cancel</button>
                    <button type="submit" class="btn list" [routerLink]="['/pages/stripe-premium-mobile']" >Upgrade Now!</button>
                </div>
            </div>

        </div>
    </div>
</div>