import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-loader',
  templateUrl: './container-loader.component.html',
  styleUrls: ['./container-loader.component.scss']
})
export class ContainerLoaderComponent implements OnInit {

  constructor() { }

  @Input() width: string = '100%';
  @Input() height: string = '100%';

  ngOnInit(): void {
  }

}
