<div
   class="p-3 p-lg-4 bg-light w-100 rounded-3 d-lg-flex align-items-lg-start gap-4"
   *ngIf="loader"
>
   <div
      class="skeleton-loader rounded-circle"
      style="width: 5rem; aspect-ratio: 1"
   ></div>
   <div class="flex-lg-grow-1">
      <div
         class="skeleton-loader my-3 mt-lg-0 rounded-1"
         style="width: 100%; height: 2rem"
      ></div>
      <div
         class="skeleton-loader rounded-1"
         style="width: 100%; height: 4rem"
      ></div>
   </div>
</div>

<article class="super-investor-card overflow-hidden" *ngIf="!loader">
   <div
      class="invester-details-wrapper d-flex flex-column align-items-start gap-3 gap-lg-4"
   >
      <div class="d-flex w-100 align-items-center justify-content-between">
         <img
            src="{{ investor?.profileImg }}"
            class="rounded-circle investor-image"
            alt=""
         />
         <button
            class="border-0 bg-transparent p-0"
            (click)="openHalalPercentModal(halalPercentModalContent)"
         >
            <app-halal-circular-progress-card
               [cardType]="'light-card'"
               [percentage]="investor?.halal_percentage"
            ></app-halal-circular-progress-card>
         </button>
      </div>
      <div>
         <h6 class="super-investor-name">{{ investor?.manager }}</h6>
         <p
            class="mb-0 super-investor-para"
            [ngClass]="{ collapsed: isCollapsed }"
         >
            {{ displayedDescription }}
            <button
               *ngIf="showButton"
               class="bg-transparent border-0 fw-bold text-decoration-underline p-0"
               (click)="toggleReadMore()"
            >
               {{ isCollapsed ? "read more" : "read less" }}
            </button>
         </p>
      </div>
      <button
         (click)="openCopyToHalalWatchListModal(copyToWatchListModalContent)"
         class="copy-watchlist"
      >
         <svg
            class="star-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            fill="#fff"
         >
            <path
               d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
            />
         </svg>
         Copy To Watchlist
      </button>
   </div>
   <div
      class="portfolio-info-wrapper d-lg-flex justify-content-lg-between align-items-center flex-lg-wrap"
   >
      <div class="d-flex justify-content-between">
         <p class="mb-0 portfolio-info">
            <span class="portfolio-info-span">Portfolio date </span>
            <span class="portfolio-info-span text-black fw-bold"
               >: {{ investor?.report_date | date : "mediumDate" }}</span
            >
         </p>
         <p class="mb-0 portfolio-info">
            <span class="portfolio-info-span">Period </span>
            <span class="portfolio-info-span text-black fw-bold"
               >: Q{{ dateQuarter }}
               {{ investor?.report_date | date : "YYYY" }}</span
            >
         </p>
      </div>
      <p class="mb-0">
         <span class="portfolio-info-span">Portfolio value </span>
         <span class="portfolio-info-span text-black fw-bold"
            >: ${{ investor?.total_amount | number }}</span
         >
      </p>
   </div>
</article>

<ng-template #halalPercentModalContent let-modal>
   <div
      class="modal-header border-0 px-lg-4 bg-light d-flex align-items-center justify-content-between"
   >
      <h5 class="mb-0 fs-5 fw-bold text-center">Holdings Shariah Overview</h5>
      <button
         class="btn lh-1 p-0 shadow-none"
         (click)="closeHalalPercentModal()"
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            viewBox="0 0 384 512"
         >
            <path
               d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
         </svg>
      </button>
   </div>
   <div class="modal-body p-lg-4">
      <app-halal-circular-progress-card
         [cardType]="'dark-card'"
         [percentage]="investor?.halal_percentage"
      ></app-halal-circular-progress-card>
      <div class="mt-3 mt-lg-4">
         <p class="text-black halal-modal-para">
            <span class="modal-percentage"
               >{{ investor?.halal_percentage | number : "1.0-0" }}%</span
            >
            of the entire portfolio value is Halal
         </p>
         <p class="mb-0 calc-para">
            The number is calculated based on the shariah compliance and the
            percentage exposure of each of the holdings
         </p>
      </div>
   </div>
</ng-template>

<ng-template #copyToWatchListModalContent let-modal>
   <ng-container *ngIf="!isCopiedToWatchlist">
      <div class="modal-header border-0 px-lg-4 bg-light d-flex align-items-center justify-content-between" >
         <h5 class="mb-0 fs-5 fw-bold text-center">Create Watchlist</h5>
         <button class="btn lh-1 p-0 shadow-none" (click)="closeCopyToWatchList()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512" >
               <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
         </button>
      </div>
      <div class="p-4">
         <form [formGroup]="copyWatchlistForm" (ngSubmit)="copyWatchlistSubmit()">
            <div class="form-input-wrapper mb-2">
               <label for="watchlist-name" class="form-label mb-2" >Watchlist Name:</label >
               <div class="d-flex align-items-center form-control-container">
                  <!-- <span class="d-inline-block lh-1 currency d-none"></span> -->
                  <input type="text" autocomplete="off" class="form-control bg-transparent ps-0 shadow-none" id="watchlist-name" formControlName="name" />
               </div>
            </div>
            <div class="form-control-checkbox mb-2">
               <input #copyWatchlistName type="checkbox" id="only-halal" class="checkbox" formControlName="onlyHalal" />
               <label for="only-halal" class="form-label mt-1" >Only halal stocks</label >
            </div>
            <button type="submit" class="btn shadow-none modal-btn add-btn" [disabled]="!copyWatchlistForm.valid || copyWatchlistLoader" >
               <div *ngIf="!copyWatchlistLoader">Create</div>
               <div *ngIf="copyWatchlistLoader">
                  <div class="spinner-border text-white" style="width: 1.5rem; height: 1.5rem" role="status" >
                     <span class="visually-hidden">Loading...</span>
                  </div>
               </div>
            </button>
         </form>
         <div *ngIf="copyWatchlistError" class="error-container p-1">
            {{ copyWatchlistError }}
         </div>
      </div>
   </ng-container>

   <ng-container *ngIf="isCopiedToWatchlist">
      <div class="p-3 p-lg-4 success-popup">
         <div class="d-flex align-items-center justify-content-center mb-3 success-image">
            <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M50 0C22.4299 0 0 22.4299 0 50C0 77.5701 22.4299 100 50 100C77.5701 100 100 77.5701 100 50C100 22.4299 77.5701 0 50 0ZM50 96.875C24.1529 96.875 3.125 75.8469 3.125 50C3.125 24.1531 24.1529 3.125 50 3.125C75.8471 3.125 96.875 24.1531 96.875 50C96.875 75.8469 75.8471 96.875 50 96.875ZM75.0691 32.8617C75.3621 33.1547 75.5267 33.5521 75.5267 33.9665C75.5267 34.3809 75.3621 34.7783 75.0691 35.0713L45.1506 64.9898C45.0055 65.135 44.8332 65.2501 44.6436 65.3286C44.454 65.4072 44.2508 65.4476 44.0456 65.4476C43.8404 65.4476 43.6372 65.4072 43.4476 65.3286C43.258 65.2501 43.0857 65.135 42.9406 64.9898L27.0793 49.1285C26.7863 48.8355 26.6217 48.438 26.6217 48.0236C26.6218 47.6092 26.7864 47.2118 27.0795 46.9188C27.3726 46.6257 27.77 46.4612 28.1844 46.4612C28.5988 46.4612 28.9963 46.6259 29.2893 46.9189L44.0457 61.6754L72.8594 32.8617C73.0045 32.7166 73.1767 32.6015 73.3663 32.523C73.5559 32.4445 73.7591 32.404 73.9643 32.404C74.1695 32.404 74.3726 32.4445 74.5622 32.523C74.7518 32.6015 74.924 32.7166 75.0691 32.8617Z" fill="#1BAD4E"/>
            </svg>
         </div>
         <h4 class="mb-0 text-center mx-auto">
            List copied to your Watchlist!
         </h4>
         <p class="my-3 text-center mx-auto">
            A new watchlist is created and all the stocks from this public list are now copied to that watchlist.
         </p>
         <div class="popup-btns-wrapper d-flex align-items-center justify-content-center gap-3">
            <button type="button" class="btn rounded-3 shadow-none outlined-button" (click)="closeCopyToWatchList()">
               Close
            </button>
            <button type="button" class="btn rounded-3 shadow-none filled-button" (click)="navigateToWatchlist()">
               Open Watchlist
            </button>
         </div>
      </div>
   </ng-container>
</ng-template>
