import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'categoryToText' })

export class CategoryToTextPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      value = value.replace('_', ' ')
      return value.toLowerCase()
    } else {
      return ''
    }
  }
}



