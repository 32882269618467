import {
   Component,
   ElementRef,
   EventEmitter,
   Input,
   OnInit,
   Output,
   ViewChild,
   forwardRef,
   AfterViewInit,
   ChangeDetectorRef,
   TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
   Observable,
   debounceTime,
   distinctUntilChanged,
   switchMap,
   of,
   tap,
   catchError,
} from 'rxjs';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';

@Component({
   selector: 'app-chip-input',
   templateUrl: './chip-input.component.html',
   styleUrls: ['./chip-input.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => ChipInputComponent),
         multi: true,
      },
   ],
})
export class ChipInputComponent
   implements OnInit, ControlValueAccessor, AfterViewInit
{
   @Input() chips: { name: string; type: string }[] = [];
   @Input() placeholder: string = '';
   @Input() removable: boolean = true;
   @Input() multiple!: boolean;
   @Input() inputLabel: string = '';
   @Output() chipsChange = new EventEmitter<{ name: string; type: string }[]>();

   symbol: string = '';
   searching = false;
   searchFailed = false;
   private stockCountries: string[] = [];
   private etfCountries: string[] = [];
   private configInitDone = false;
   userCountry: any = '';
   errorMsg: boolean = false;

   private onChange = (chips: { name: string; type: string }[]) => {};
   private onTouched = () => {};

   @ViewChild('chipInputElement') chipInputElement!: ElementRef | undefined;

   constructor(
      private cdr: ChangeDetectorRef,
      private typesenseService: TypesenseService,
      private marketUtilsService: MarketUtilsService,
      private userApiService: UserApiService
   ) {}

   ngOnInit(): void {
      this.init();
      this.getUserDetails();
   }

   ngAfterViewInit() {
      this.focusInput();
   }

   async getUserDetails() {
      const Data = await this.userApiService.fetchUserDetails();
      if(Data) {
         this.userCountry = Data.geo_ip_country;
      }
   }

   async init() {
      try {
         let countriesData: any =
            await this.marketUtilsService.fetchCountries();

         this.stockCountries = countriesData
            .filter((obj: any) => obj.stockEnabled)
            .map((obj: any) => obj.countryCode);

         this.etfCountries = countriesData
            .filter((obj: any) => obj.etfEnabled)
            .map((obj: any) => obj.countryCode);

         this.configInitDone = true;
      } catch (e) {
         console.log(e);
      }
   }

   search: any = (text$: Observable<string>) =>
      text$.pipe(
         debounceTime(200),
         distinctUntilChanged(),
         tap(() => {
            this.searching = true;
            this.errorMsg = false;
         }),
         switchMap((term) =>
            this.performSearch(term).pipe(
               catchError(() => {
                  this.searchFailed = true;
                  return of([]);
               })
            )
         ),
         tap(() => (this.searching = false))
      );

   private performSearch(term: string): Observable<any> {
      if (
         this.stockCountries &&
         !this.stockCountries.includes(this.userCountry)
      ) {
         this.userCountry = '';
      }

      return this.typesenseService
         .searchStocksAndEtfs(
            term,
            this.stockCountries,
            this.etfCountries,
            this.userCountry
         )
         .pipe(
            tap((results) => {
               this.searchFailed = false;
               if (this.userCountry) {
                  // console.log(results);
                  results = this.sortData(results);
               }
            })
         );
   }

   formatSymbol(value: any) {
      return value.stockName;
   }

   inputFormatBandListValue(value: any) {
      return value.stockName;
   }

   sortData(data: any) {
      let topCountry = data.filter(
         (obj: any) => obj.countryName == this.userCountry
      );
      let notTheCountry = data.filter(
         (obj: any) => obj.countryName !== this.userCountry
      );
      let myArray = topCountry.concat(notTheCountry);
      myArray = myArray.filter(
         (value: any, index: any, self: any) =>
            index ===
            self.findIndex((t: any) => t.stockName === value.stockName)
      );
      return myArray;
   }

   selectSymbol($event: any) {
      const selectedItem = $event.item;
      this.symbol = selectedItem.stockName;
      this.addChip(selectedItem);
   }

   addChip(selectedItem: any) {
      const value = selectedItem.stockName.trim();
      if (value) {
         if (this.chips.some((chip) => chip.name === value)) {
            this.errorMsg = true;
            return;
         }
         if (this.multiple || this.chips.length === 0) {
            this.chips = [
               ...this.chips,
               { name: value, type: selectedItem.type },
            ];
         } else {
            if (this.chips.length === 0) {
               this.chips = [{ name: value, type: selectedItem.type }];
            }
         }
         this.chipsChange.emit(this.chips);
         this.symbol = '';
         this.onChange(this.chips);
         this.cdr.detectChanges();
         if (this.multiple || this.chips.length === 0) {
            this.focusInput();
         }
      }
   }

   focusInput() {
      if (this.chipInputElement && this.chipInputElement.nativeElement) {
         this.chipInputElement.nativeElement.focus();
      }
   }

   removeChip(chip: { name: string; type: string }) {
      this.chips = this.chips.filter((c) => c.name !== chip.name);
      this.chipsChange.emit(this.chips);
      this.onChange(this.chips);
   }

   writeValue(chips: { name: string; type: string }[]): void {
      this.chips = chips || [];
   }

   registerOnChange(
      fn: (chips: { name: string; type: string }[]) => void
   ): void {
      this.onChange = fn;
   }

   registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
   }

   getChangeClasses(stock: any) {
      return {
         green: stock >= 0,
         red: stock < 0,
      };
   }

   getIconClasses(stock: any) {
      return {
         'bi-caret-up-fill green': stock >= 0,
         'bi-caret-down-fill red': stock < 0,
      };
   }
}
