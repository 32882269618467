import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
import { Country } from 'src/app/models/market-collection/market-collection.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';
import { User } from 'src/app/models/user/user.model';
import { CollectionComplianceService } from 'src/app/shared/services/collection-compliance.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { NewsService } from 'src/app/shared/services/news.service';

type StockLoadingType = 'mostActive' | 'mostPopular' | 'topGainers' | 'topLosers' | 'weekHigh' | 'weekLow';

@Component({
   selector: 'app-trending-halal-stocks',
   templateUrl: './trending-halal-stocks.component.html',
   styleUrls: ['./trending-halal-stocks.component.scss'],
})
export class TrendingHalalStocksComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   isMobile: boolean = false;
   userRole: string | null = null;
   isLoggedIn: boolean = false;
   selectedCountry!: string;
   perPage: string = '6';

   stocksLoading: Record<StockLoadingType, boolean> = {
      mostActive: true,
      mostPopular: true,
      topGainers: true,
      topLosers: true,
      weekHigh: true,
      weekLow: true,
   };

   mostActive: any[] = [];
   mostPopular: any[] = [];
   topGainers: any[] = [];
   topLosers: any[] = [];
   weekHigh: any[] = [];
   weekLow: any[] = [];

   constructor(
      private typesenseService: TypesenseService,
      private observer: BreakpointObserver,
      private marketUtilsService: MarketUtilsService,
      private authService: AuthService,
      private userApiService: UserApiService,
      private collectionCompliance: CollectionComplianceService,
      private countryService: CountryService,
      private newsService: NewsService
   ) {}

   ngOnInit(): void {
      this.isLoggedIn = this.authService.isLoggedIn();
      SubscriptionService.subscriptionData
         .pipe(takeUntil(this.destroy$))
         .subscribe((data: SubscriptionInfoModel) => {
            this.userRole = data.role;
         });

      this.observer
         .observe('(max-width: 991px)')
         .pipe(takeUntil(this.destroy$))
         .subscribe((result) => {
            this.isMobile = result.matches;
         });

      this.countryService.selectedCountry$.pipe(takeUntil(this.destroy$)).subscribe(country => {
         this.selectedCountry = country.countryCode;
         this.init();
      });

   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async init() {
      await this.getUsersCountry();
      this.mostActive = await this.getStockCollectionData('mostActive');
      this.mostPopular = await this.getStockCollectionData('mostPopular');
      this.topGainers = await this.getStockCollectionData('topGainers');
      this.topLosers = await this.getStockCollectionData('topLosers');
      this.weekHigh = await this.getStockCollectionData('weekHigh');
      this.weekLow = await this.getStockCollectionData('weekLow');

      let allCollectionsSData = [...this.mostActive, ...this.mostPopular, ...this.topGainers, ...this.topLosers, ...this.weekHigh, ...this.weekLow];

      this.newsService.setStocksSymbols(allCollectionsSData)
   }

   async getUsersCountry() {
      const countries: Country[] | null = await this.marketUtilsService.fetchCountries();
      let userCountry = '';
      const allowedCountries = countries
         ?.filter((country: Country) => country.stockEnabled && country.stocksCovered && country.detailedProfileAvailable )
         .map((country: Country) => country.countryCode);

      if (this.isLoggedIn) {
         let userInfo: User = await this.userApiService.fetchUserDetails();
      }
      userCountry = this.selectedCountry;
   }

   async getStockCollectionData(type: StockLoadingType) {
      try {
         this.stocksLoading[type] = true;
         let apiResponse;
         switch(type) {
            case 'mostActive':
               apiResponse = await this.typesenseService.getMostActiveStocks(this.selectedCountry, this.perPage);
               break;
            case 'mostPopular':
               apiResponse = await this.typesenseService.getMostPopularStocks(this.selectedCountry, this.perPage);
               break;
            case 'topGainers':
               apiResponse = await this.typesenseService.getTopGainer(this.selectedCountry, this.perPage);
               break;
            case 'topLosers':
               apiResponse = await this.typesenseService.getTopLosers(this.selectedCountry, this.perPage);
               break;
            case 'weekHigh':
               apiResponse = await this.typesenseService.getYearHigh(this.selectedCountry, this.perPage);
               break;
            case 'weekLow':
               apiResponse = await this.typesenseService.getYearLow(this.selectedCountry, this.perPage);
               break;
         }

         let stocksData = [];
         if (apiResponse) {
            stocksData = await this.processApiResponse(apiResponse);
         }
         return stocksData;
      } catch (error) {
         console.error(error);
         this.stocksLoading[type] = false;
         return [];
      } finally {
         this.stocksLoading[type] = false;
      }
   }

   async processApiResponse(apiResponse: any) {
      let stocksData: any[] = [];
      const resp = apiResponse;
      const stockSymbols = resp.hits.map((item: any) => item.document.ticker);

      const allData = stockSymbols.reduce((acc: any, ticker: any) => {
         const hit = resp.hits.find((h: any) => h.document.ticker === ticker);
         if (hit) {
            acc[ticker] = {
               change: hit.document.change,
               percentChange: hit.document.percentChange,
               lastClosingPrice: hit.document.lastClosingPrice,
               ticker: hit.document.ticker,
            };
         }
         return acc;
      }, {});

      if (stockSymbols.length) {
         // const stockData = await this.typesenseService.getStocksDataBySymbols(stockSymbols);
         // let processedData = await this.collectionCompliance.processComplianceForStocksEtfMap(stockData, "stocks");
         // for (const key in processedData) {
         //    if (allData[key]) {
         //       Object.assign(allData[key], processedData[key]);
         //    }
         // }

         stocksData = Object.values(allData);
         stocksData = await this.getStocksData(stocksData, stockSymbols);
      }
      return stocksData;
   }

   async getStocksData(stocks: any[], symbols: string[]) {
      let stocksData: any[] = [];
      // get prices returns stocks_data
      const res = await this.typesenseService.getCompanyProfileAndDataFromSymbols(symbols);
      for (const key in res) {
         let priceValue = res[key];
         let stock = stocks.find(
            (item: any) => item.ticker == priceValue.id
         );
         if (stock) {
            stock.status = priceValue.status;
            if (stock.status != 'not found') {
               stock = priceValue
               if (!stock.lastPrice) {
                  stock.lastPrice = priceValue.currentPrice;
               }
               stock.price = priceValue.currentPrice;
               stock.percentChange = priceValue.percentChange.toFixed(2);
            }
            stocksData.push(stock);
         }
      }
      return stocksData;
   }
}
