import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sample-bubble',
  templateUrl: './sample-bubble.component.html',
  styleUrls: ['./sample-bubble.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SampleBubbleComponent implements OnInit {
  @Input() bubbleContent: string = '';
  @Input() tooltipPosition: string = 'top';
  isHovered: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onTooltipShown() {
    if(!this.isHovered) {
      this.isHovered = true
    }
  }

}
