import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[onlyNumbersAndDot]'
})
export class OnlyNumbersAndSingleDotDirective {

  constructor(private element: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const input = this.element.nativeElement as HTMLInputElement;
    const cursorPosition = input.selectionStart;

    // If the key pressed is not a number or a dot and not a navigation key or backspace/delete
    if (!this.isValidKey(event.key) && !this.isNavigationKey(event.key)) {
      event.preventDefault();
    } else if (event.key === '.' && input.value.includes('.')) {
      // Prevents multiple dots
      event.preventDefault();
    } else if (event.key === '.' && cursorPosition === 0) {
      // Allows dot at the beginning of the input
      event.preventDefault();
      input.value = '0' + input.value;
    }
  }

  private isValidKey(key: string): boolean {
    // Allow digits, dot, backspace, delete, arrow keys, home, and end
    return /^\d$/.test(key) || key === '.' || key === 'Backspace' || key === 'Delete' ||
      key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Home' || key === 'End';
  }

  private isNavigationKey(key: string): boolean {
    // Check if the key is a navigation key (arrow keys, home, end)
    return key.startsWith('Arrow') || key === 'Home' || key === 'End';
  }

}
