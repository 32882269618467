import { Component, OnInit, Input } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
  selector: 'app-stock-profile',
  templateUrl: './stock-profile.component.html',
  styleUrls: ['./stock-profile.component.scss']
})
export class StockProfileComponent implements OnInit {
  @Input() currentPrice! : any
  @Input() profileDescription!: string
  @Input() market!: string
  @Input() sector!: string
  @Input() industry!: string
  @Input() exchange!: string
  @Input() website!: string
  @Input() phone!: any
  @Input() address!: string
  @Input() stockType: 'stock' | 'etf' = 'stock'

  @Input() etfTicker!: string
  @Input() etfDescription!: string
  formattedPhone = ""
  showText: boolean = false;

  constructor() { }

  ngOnInit(): void {
    const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    try{
      let parsedNo = phoneUtil.parse(this.phone,this.market)      
      this.formattedPhone = phoneUtil.format(parsedNo, PhoneNumberFormat.INTERNATIONAL)
      
    }
    catch(e){console.log("FORMATED PHONE EXCEPTIN")
      this.formattedPhone = this.phone
      console.log(e)
    }

  }

}
