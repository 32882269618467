import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PortfolioState } from './portfolio.reducer';

export const selectPortfolioState =
   createFeatureSelector<PortfolioState>('portfolio');

export const allAccounts = createSelector(
   selectPortfolioState,
   (state) => state?.accounts ?? []
);

export const allPortfolioLoading = createSelector(
   selectPortfolioState,
   (state) => state?.loading ?? false
);

export const allPortfolioError = createSelector(
   selectPortfolioState,
   (state) => state?.error ?? null
);

export const selectedAccount = createSelector(
   selectPortfolioState,
   (state) => state?.selectedAccount ?? null
);

export const selectedAccountDetails = createSelector(
   selectPortfolioState,
   (state) => state?.accounts?.find((account) => account.id === state?.selectedAccount) ?? null
);
