import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reset-password-modale',
  templateUrl: './reset-password-modale.component.html',
  styleUrls: ['./reset-password-modale.component.scss']
})
export class ResetPasswordModaleComponent implements OnInit {


  @Input() showlist: boolean = false
  @Output() close: EventEmitter<void> = new EventEmitter()

  constructor(private location: Location) { }

  ngOnInit(): void {
  }
  resetPassword() {
    this.closene()
  }
  closene() {
    this.close.emit()
 }
 goBack(){
  this.location.back()
 }
}
