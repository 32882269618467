import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';


@Component({
  selector: 'app-analyst-rating-guage-chart',
  templateUrl: './analyst-rating-guage-chart.component.html',
  styleUrls: ['./analyst-rating-guage-chart.component.scss']
})
export class AnalystRatingGuageChartComponent implements  OnInit, AfterViewInit {

  private chart!: Chart;
  @Input() value!: number;
  @Input() color!: string;

  constructor() { }

  ngOnInit(): void {
    this.createGaugeChart();
  }

  ngAfterViewInit(): void {
    // this.createGaugeChart();
  }

  private createGaugeChart() {
    const ctx: any = document.getElementById('gaugeChart');
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        // labels: ['Strong Sell', 'Sell', 'Neutral', 'Buy', 'Strong Buy'],
        datasets: [{
          data: [this.value, 180 - this.value],
          backgroundColor: (context) => {
            
            const chart = context.chart
              if (!chart) {
                return '#D9D9D9';
              }

              const { ctx, chartArea } = chart;
              if (!chartArea || !ctx) {
                return '#D9D9D9';
              }

              if (context.dataIndex === 0) {
                return this.getGradient(chart);
              } else {
                return '#D9D9D9';
              }
            
          },
          borderWidth: 0
        }],
      },
      options: {
        aspectRatio: 2 / 1,
        maintainAspectRatio: true,
        cutoutPercentage: 80,
        rotation: Math.PI,
        circumference: Math.PI,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        animation: {
          duration: 1000,
        },
      },
    });

    
    let dataValue = this.value;

    const needleRotation = ((dataValue - 0) / (180 - 0)) * 180 - 90
    this.chart.update();
    const needleElement = document.querySelector('.needle') as HTMLElement | null;
    const labelElement = document.querySelectorAll('.guage-label') as NodeListOf<HTMLElement>;
    
    if (needleElement) {
      needleElement.style.transform = `translate(-50%, -100%) rotate(${needleRotation}deg)`;
  
      if (dataValue > 0 && dataValue <= 36) {
        needleElement.style.backgroundColor = '#E42020';
        labelElement[0].style.color = '#FFFFFF';
        labelElement[0].style.backgroundColor = '#E42020';
      } else if (dataValue > 37 && dataValue <= 72) {
        needleElement.style.backgroundColor = '#E0675F';
        labelElement[1].style.color = '#FFFFFF';
        labelElement[1].style.backgroundColor = '#E0675F';
      } else if (dataValue > 73 && dataValue <= 108) {
        needleElement.style.backgroundColor = '#E0B45F';
        labelElement[2].style.color = '#FFFFFF';
        labelElement[2].style.backgroundColor = '#E0B45F';
      } else if (dataValue > 109 && dataValue <= 144) {
        needleElement.style.backgroundColor = '#99CE43';
        labelElement[3].style.color = '#FFFFFF';
        labelElement[3].style.backgroundColor = '#99CE43';
      } else if (dataValue > 145 && dataValue <= 180) {
        needleElement.style.backgroundColor = '#1BAD4E';
        labelElement[4].style.color = '#FFFFFF';
        labelElement[4].style.backgroundColor = '#1BAD4E';
      }
        
      // if (dataValue <= 22.5) {
      //   needleElement.style.backgroundColor = '#E42020';
      //   labelElement[0].style.color = '#FFFFFF';
      //   labelElement[0].style.backgroundColor = '#E42020';
      // } else if (dataValue > 22.5 && dataValue <= 67.5) {
      //   needleElement.style.backgroundColor = '#E0675F';
      //   labelElement[1].style.color = '#FFFFFF';
      //   labelElement[1].style.backgroundColor = '#E0675F';
      // } else if (dataValue > 67.5 && dataValue <= 112.5) {
      //   needleElement.style.backgroundColor = '#E0B45F';
      //   labelElement[2].style.color = '#FFFFFF';
      //   labelElement[2].style.backgroundColor = '#E0B45F';
      // } else if (dataValue > 112.5 && dataValue <= 157.5) {
      //   needleElement.style.backgroundColor = '#99CE43';
      //   labelElement[3].style.color = '#FFFFFF';
      //   labelElement[3].style.backgroundColor = '#99CE43';
      // } else if (dataValue > 157.5) {
      //   needleElement.style.backgroundColor = '#1BAD4E';
      //   labelElement[4].style.color = '#FFFFFF';
      //   labelElement[4].style.backgroundColor = '#1BAD4E';
      // }
    }
  }

  getGradient(chart:  any) {
    const {ctx, chartArea: {top, bottom, left, right} } = chart
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
    gradientSegment.addColorStop(0, '#E42020')
    gradientSegment.addColorStop(0.25, '#E0675F')
    gradientSegment.addColorStop(0.50, '#E0B45F')
    gradientSegment.addColorStop(0.75, '#99CE43')
    gradientSegment.addColorStop(1, '#1BAD4E')
    return gradientSegment
  }
}
