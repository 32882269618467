<div class="d-flex align-items-center justify-content-center logout-modal-backdrop px-3" *ngIf="showLog">
    <div class="bg-white logout-modal-container">
        <!-- <header class="d-flex align-items-center p-2 justify-content-end logout-modal-header">
            <button type="button" class="btn shadow-none p-1 bg-light rounded-3 modal-header-close-btn" aria-label="Close"  (click)="hideLog()">
                <img src="/assets/images/header/close.svg" alt="Close">
            </button>
        </header> -->
        <div class="logout-modal-body">
            <h2 class="text-center fw-600">Do you wish to log out?</h2>
            <div class="logout-modal-btns-wrapper">
                <button type="button" class="btn fw-600 shadow-none cancel-btn" (click)="hideLog()">Cancel</button>
                <button type="button" class="btn fw-600 shadow-none confirm-btn" (click)="goToLogin()">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="stripe-free" *ngIf="showLog">
    <div class="px-4">
        <div class="model-liner">
           <div class="cancel-img text-end">
               <img src="../../../../assets/images/group-343.svg" alt="" (click)="hideLog()">
           </div>
           <div class="stripe-main">
               <h2>Do you wish to log out?</h2>

               <div class="stripe-buttons">
                   <button type="button" class="btn cancel" (click)="hideLog()">Cancel</button>
                   <button type="button" class="btn" (click)="goToLogin()">Yes</button>

               </div>
           </div>

        </div>
    </div>
</div> -->
