import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Country } from 'src/app/models/market-collection/market-collection.model';
import { CountryService } from 'src/app/shared/services/country.service';


@Component({
  selector: 'app-covered-countries',
  templateUrl: './covered-countries.component.html',
  styleUrls: ['./covered-countries.component.scss']
})
export class CoveredCountriesComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   coveredCountriesLoader: boolean = false;
   allCountries: Country[] = [];
   coveredCountries: Country[] = [];

   constructor(private countryService: CountryService) { }

   ngOnInit(): void {
      this.getCountriesData()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getCountriesData(): void {
      this.coveredCountriesLoader = true;
      this.countryService.getCountries().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            if (response) {
               this.allCountries = response

               this.coveredCountries = this.allCountries
                  .filter((country: Country) => country.stocksCovered)
                  .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0))
               
              
               this.coveredCountriesLoader = false;   
            }
         },
         error: (error) => {
            console.error(error);
            this.coveredCountriesLoader = false;
         },
         complete: () => {
            this.coveredCountriesLoader = false;
         },
      });
   }

}
