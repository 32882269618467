import {
   Component,
   OnInit,
   OnChanges,
   Input,
   SimpleChanges,
} from '@angular/core';
import { TypesenseService } from '../../services/typesense.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { ConditionalRenderingService } from '../../services/conditional-rendering.service';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from '../../services/access-control.service';

@Component({
   selector: 'app-forecasts',
   templateUrl: './forecasts.component.html',
   styleUrls: ['./forecasts.component.scss'],
})
export class ForecastsComponent implements OnInit, OnChanges {
   public forecast: any;

   @Input() ticker!: string;
   @Input() fundamentals: any | undefined;
   @Input() currency!: string;
   loading: boolean = false;
   private subscriptions: Subscription[];
   noData: string = 'NO-DATA';
   showPriceRow: boolean = false;
   showFirstPrice: boolean = false;
   showFirstChip: boolean = false;
   showRevenueRow: boolean = false;
   showEarningsRow: boolean = false;
   isLoggedIn: boolean = false;
   canReadForecast: boolean = false;
   apiDataAvailable = false;
   showPriceChart = false;
   revenueChart: string = 'revenueChart';
   earningsChart: string = 'earningsChart';
   earningsChartTableData: any = [];
   revenueChartTableData: any = [];
   quarterDateMapping: any = {};
   currentYear: any;
   nextYear: any;
   priceForecastData: any = {
      current: null,
      low: null,
      median: null,
      high: null,
   };

   showRevenueChart!: boolean;
   showEarningsChart!: boolean;

   @Input() priceChartData: any = {
      labels: [],
      datasets: [],
   };

   @Input() revenueChartData: any = {};

   @Input() earningsChartData: any = {};
   userRole: string | null = null;
   @Input() isSample: boolean = false;
   priceForecastText: string =
      'Get a glimpse into the future with our price forecast feature, assisting you in making investment decisions in various stocks. It predicts potential price movements, helping you choose the right assets. For instance, it might show that Stock X is expected to rise by 10% in the next quarter based on historical trends.';
   revenueForecastText: string =
      "Enhance your stock investment strategies with our app's revenue forecast feature. It provides insights into the anticipated revenue of companies, aiding you in selecting stocks for your portfolio. As an example, it may project that Company Y is set to achieve a 20% revenue growth in the upcoming year.";
   earningsForecastText: string =
      "Empower your investment choices with our app's earnings forecast tool, designed to guide your decisions in various stocks. It forecasts the potential earnings of companies, helping you identify opportunities. For instance, it may estimate that Company Z is likely to exceed earnings expectations in the next quarter, making it a compelling investment option.";

   constructor(
      private typesenseService: TypesenseService,
      private apiService: ApiService,
      private auth: AuthService,
      private conditionalRenderingService: ConditionalRenderingService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      public accessControlService: AccessControlService
   ) {
      this.subscriptions = [];
      this.currentYear = new Date().getFullYear();
      this.nextYear = new Date().getFullYear() + 1;
   }

   getQuarterAndYearFromDate(date: string | Date) {
      let dateObj;
      if (typeof date === 'string') dateObj = new Date(date);
      else dateObj = date;
      return {
         quarter: Math.floor(dateObj.getMonth() / 3 + 1),
         year: dateObj.getFullYear(),
      };
   }

   getQuarterYearLabel(date: string, quarterCorrection: number = 0) {
      const qYear = this.getQuarterAndYearFromDate(date);
      let q = qYear.quarter + quarterCorrection;
      if (q > 4) {
         q = 1;
         qYear.year++;
      } else if (q < 1) {
         q = 4;
         qYear.year--;
      }
      return `Q${q}, ${qYear.year}`;
   }

   getPrevKeys(currentQ: number, currentQYear: string) {
      const maxPrevQuarterCount = 4;
      let year = parseInt(currentQYear);
      let quarter = currentQ;
      let data: any = [];

      for (let i = maxPrevQuarterCount; i > 0; i--) {
         let tempQ = quarter - 1;
         if (tempQ > 0) {
            quarter = tempQ;
         } else {
            quarter = 4 + tempQ;
            year--;
         }
         data.push({
            label: `Q${quarter}, ${year}`,
            sortBy: `${year}${quarter}`,
            surprise: null,
            surprisePercent: null,
            actual: null,
            estimate: null,
         });
      }
      return data.sort((a: any, b: any) => a.sortBy - b.sortBy);
   }

   getCurrentAndFutureKeys(currentQ: number, currentQYear: string) {
      const maxFutureQuarterCount = 3;
      let year = parseInt(currentQYear);
      let quarter = currentQ;
      let data: any = [
         {
            label: `Q${quarter}, ${year}`,
            sortBy: `${year}${quarter}`,
            surprise: null,
            surprisePercent: null,
            actual: null,
            estimate: null,
         },
      ];

      for (let i = 0; i < maxFutureQuarterCount; i++) {
         let tempQ = quarter + 1;
         if (tempQ <= 4) {
            quarter = tempQ;
         } else {
            quarter = tempQ - 4;
            year++;
         }
         data.push({
            label: `Q${quarter}, ${year}`,
            sortBy: `${year}${quarter}`,
            surprise: null,
            surprisePercent: null,
            actual: null,
            estimate: null,
         });
      }
      return data.sort((a: any, b: any) => a.sortBy - b.sortBy);
   }

   async ngOnInit(): Promise<void> {
      // this.subscriptions.push(
      //    SubscriptionService.subscriptionData.subscribe(
      //       (data: SubscriptionInfoModel) => {
      //          this.userRole = data.role;
      //       }
      //    )
      // );
      this.isLoggedIn = this.auth.isLoggedIn();
      this.subscriptions.push(
         this.permissionStore.select(({permissions}) => permissions.permissions).subscribe(
            (permissions) => {
               this.isLoggedIn = this.auth.isLoggedIn();
               this.canReadForecast = permissions.stock_forecast;
            }
         )
      )
      this.setPriceData();
      this.loadData();
      this.setEarningsData();
      this.setRevenueData();
   }

   async setPriceData() {
      let data = {
         // customFilter: {
            symbol: this.ticker,
            resolution: '1Y',
         // },
      };
      this.priceChartData.datasets = [];
      const d = new Date();
      d.setFullYear(d.getFullYear() - 1);
      const ts = d.valueOf();
      let candleData: any = await lastValueFrom(
         this.apiService.stockCandles(data)
      );
      candleData = candleData.filter((item: any) => item.time >= ts);
      candleData.push({
         close: this.fundamentals?.currentPrice,
      });
      this.priceChartData.labels = [
         ...new Array(candleData.length).fill(''),
         // 'Last 12 months',
         ...new Array(candleData.length).fill(''),
         // 'Next 12 months',
      ];
      this.priceChartData.datasets = [
         {
            label: '',
            data: candleData.map((item: any) => item.close),
            borderColor: '#1BAD4E',
            fill: false,
            borderWidth: 1,
         },
      ];
      const startOffsetArray = new Array(candleData.length - 1).fill(NaN);
      const endOffsetArray = new Array(candleData.length - 1).fill(null);
      this.typesenseService
         .getPriceTarget(this.ticker)
         .then((data) => {
            if (!data.targetHigh || !data.targetLow || !data.targetMedian) {
               return;
            }
           
            this.priceForecastData.current =
               this.priceChartData.datasets[0].data[
                  this.priceChartData.datasets[0].data.length - 1
               ];
            this.priceForecastData.high = data.targetHigh;
            this.priceForecastData.low = data.targetLow;
            this.priceForecastData.median = data.targetMedian;
            this.priceChartData.datasets.push({
               borderWidth: 1,
               label: 'Median',
               data: [
                  ...startOffsetArray,
                  this.priceForecastData.current,
                  ...endOffsetArray,
                  data.targetMedian,
               ],
               borderColor: '#9ACBB0',
               fill: false,
            });

            this.priceChartData.datasets.push({
               borderWidth: 1,
               label: 'High',
               data: [
                  ...startOffsetArray,
                  this.priceForecastData.current,
                  ...endOffsetArray,
                  data.targetHigh,
               ],
               borderColor: '#4D88B2',
               borderDash: [2, 2],
               fill: '+1',
               backgroundColor: 'rgba(27, 173, 78, 0.05)',
            });

            this.priceChartData.datasets.push({
               borderWidth: 1,
               label: 'Low',
               data: [
                  ...startOffsetArray,
                  this.priceForecastData.current,
                  ...endOffsetArray,
                  data.targetLow,
               ],
               borderColor: '#E4B4C9',
               borderDash: [2, 2],
               fill: false,
            });

            this.showPriceChart = true;

         })
         .finally(() => {
            // this.showPriceChart = true;
         });
   }

   async setRevenueData(): Promise<void> {
      let forecastRevenueItemMax: number = 0;
      let isYearCorrectionRequired: boolean = false;
      this.typesenseService
         .getEarningCalendars(this.ticker)
         .then(async (data) => {
            data.earningsCalendar = data.earningsCalendar
               .filter((item: any) => !!item.revenueActual)
               .sort((a: any, b: any) => a.date.localeCompare(b.date));
            const lastQInfo =
               data.earningsCalendar[data.earningsCalendar.length - 1];
            const lastQ = lastQInfo.quarter;
            const lastQYear = lastQInfo.year;
            const currentQ = this.getQuarterAndYearFromDate(new Date());
            isYearCorrectionRequired =
               `${lastQYear}`.localeCompare(`${currentQ.year}`) > 0;
            let temp: any;
            if (isYearCorrectionRequired) {
               temp = {
                  q: lastQ === 4 ? 1 : lastQ + 1,
                  y: lastQ === 4 ? lastQYear - 1 + 1 : lastQYear - 1,
               };
            } else {
               temp = {
                  q: lastQ === 4 ? 1 : lastQ + 1,
                  y: lastQ === 4 ? lastQYear + 1 : lastQYear,
               };
            }
            this.revenueChartTableData = await this.getPrevKeys(
               temp.q,
               temp.y.toString()
            ).concat(
               await this.getCurrentAndFutureKeys(temp.q, temp.y.toString())
            );
            for (let item of data.earningsCalendar) {
               const label: string = `Q${item.quarter}, ${
                  isYearCorrectionRequired ? item.year - 1 : item.year
               }`;
               const forecastRevenueItemIndex =
                  this.revenueChartTableData.findIndex(
                     (revenueItem: any) => revenueItem.label === label
                  );
               if (this.revenueChartTableData[forecastRevenueItemIndex]) {
                  if (!item.revenueActual) {
                     this.revenueChartTableData.splice(
                        forecastRevenueItemIndex,
                        1
                     );
                  } else {
                     this.revenueChartTableData[
                        forecastRevenueItemIndex
                     ].actual = item.revenueActual;
                     this.revenueChartTableData[
                        forecastRevenueItemIndex
                     ].estimate = item.revenueEstimate;
                     this.revenueChartTableData[
                        forecastRevenueItemIndex
                     ].surprisePercent =
                        ((item.revenueActual - item.revenueEstimate) /
                           item.revenueEstimate) *
                        100;
                     forecastRevenueItemMax++;
                  }
               }
            }
         })
         .then(() => {
            let deleteCount = 1;
            for (let i in this.revenueChartTableData) {
               if (this.revenueChartTableData[i].actual || deleteCount === 0) {
                  break;
               } else {
                  // console.log(this.revenueChartTableData)
                  this.revenueChartTableData.splice(i, 1);
                  deleteCount--;
               }
            }
            this.typesenseService
               .getRevenueEstimates(this.ticker)
               .then((data) => {
                  for (let item of data.data) {
                     // const label: string = this.getQuarterYearLabel(
                     //    item.period, isYearCorrectionRequired ? -1 : 1
                     // );
                     const label: string = `Q${item.quarter}, ${
                        isYearCorrectionRequired ? item.year - 1 : item.year
                     }`;
                     const forecastRevenueItemIndex =
                        this.revenueChartTableData.findIndex(
                           (revenueItem: any) => revenueItem.label === label
                        );
                     // console.log(forecastRevenueItemIndex)
                     if (
                        this.revenueChartTableData[forecastRevenueItemIndex] &&
                        this.revenueChartTableData[forecastRevenueItemIndex]
                           .estimate === null
                     )
                        // if (forecastRevenueItemIndex > -1)
                        this.revenueChartTableData[
                           forecastRevenueItemIndex
                        ].estimate = item.revenueAvg;
                     // else
                     //    break;
                  }
               })
               .finally(() => {
                  this.revenueChartTableData =
                     this.revenueChartTableData.filter((item: any) => {
                        return item.estimate !== null || item.actual !== null;
                     });
                  const labels = this.revenueChartTableData.map(
                     (item: any) => item.label
                  );
                  const estimated = this.revenueChartTableData.map(
                     (item: any) => item.estimate
                  );
                  const actual = this.revenueChartTableData.map(
                     (item: any) => item.actual
                  );

                  this.showRevenueChart = estimated.length > 0 && actual.length > 0

                  this.revenueChartData = {
                     labels: labels,
                     datasets: [
                        {
                           label: 'Estimated',
                           data: estimated,
                           backgroundColor: '#4D88B2',
                           categoryPercentage: 0.5,
                        },
                        {
                           label: 'Actual',
                           data: actual,
                           backgroundColor: '#ACE8DC',
                           categoryPercentage: 0.5,
                        },
                     ],
                  };
               });
         });
   }

   async setEarningsData(): Promise<void> {
      let isArrayTruncated: boolean = false;
      let forecastEarningItemMax: number = 0;
      let isYearCorrectionRequired: boolean = false;
      this.typesenseService
         .getEarningCalendars(this.ticker)
         .then(async (data) => {
            data.earningsCalendar = data.earningsCalendar
               .filter((item: any) => !!item.epsActual)
               .sort((a: any, b: any) => a.date.localeCompare(b.date));
            const lastQInfo =
               data.earningsCalendar[data.earningsCalendar.length - 1];
            const lastQ = lastQInfo.quarter;
            const lastQYear = lastQInfo.year;
            const currentQ = this.getQuarterAndYearFromDate(new Date());
            isYearCorrectionRequired =
               `${lastQYear}`.localeCompare(`${currentQ.year}`) > 0;
            let temp: any;
            if (isYearCorrectionRequired) {
               temp = {
                  q: lastQ === 4 ? 1 : lastQ + 1,
                  y: lastQ === 4 ? lastQYear - 1 + 1 : lastQYear - 1,
               };
            } else {
               temp = {
                  q: lastQ === 4 ? 1 : lastQ + 1,
                  y: lastQ === 4 ? lastQYear + 1 : lastQYear,
               };
            }
            this.earningsChartTableData = await this.getPrevKeys(
               temp.q,
               temp.y.toString()
            ).concat(
               await this.getCurrentAndFutureKeys(temp.q, temp.y.toString())
            );
            for (let item of data.earningsCalendar) {
               const label: string = `Q${item.quarter}, ${
                  isYearCorrectionRequired ? item.year - 1 : item.year
               }`;
               const forecastEarningItemIndex =
                  this.earningsChartTableData.findIndex(
                     (earningItem: any) => earningItem.label === label
                  );
               if (this.earningsChartTableData[forecastEarningItemIndex]) {
                  this.earningsChartTableData[forecastEarningItemIndex].actual =
                     item.epsActual;
                  this.earningsChartTableData[
                     forecastEarningItemIndex
                  ].estimate = item.epsEstimate;
                  this.earningsChartTableData[
                     forecastEarningItemIndex
                  ].surprise = item.epsActual - item.epsEstimate;
                  this.earningsChartTableData[
                     forecastEarningItemIndex
                  ].surprisePercent =
                     ((item.epsActual - item.epsEstimate) / item.epsEstimate) *
                     100;
                  forecastEarningItemMax++;
               }
            }
         })
         .then(() => {
            let deleteCount = 1;
            for (let i in this.earningsChartTableData) {
               if (this.earningsChartTableData[i].actual || deleteCount === 0) {
                  break;
               } else {
                  this.earningsChartTableData.splice(i, 1);
                  deleteCount--;
               }
            }
            this.typesenseService
               .getEarningEstimates(this.ticker)
               .then((data) => {
                  for (let item of data.data) {
                     const label: string = `Q${item.quarter}, ${
                        isYearCorrectionRequired ? item.year - 1 : item.year
                     }`;
                     const forecastEarningItemIndex =
                        this.earningsChartTableData.findIndex(
                           (earningItem: any) => earningItem.label === label
                        );
                     if (
                        this.earningsChartTableData[forecastEarningItemIndex] &&
                        this.earningsChartTableData[forecastEarningItemIndex]
                           .estimate === null
                     )
                        this.earningsChartTableData[
                           forecastEarningItemIndex
                        ].estimate = item.epsAvg;
                  }
               })
               .finally(() => {
                  this.earningsChartTableData =
                     this.earningsChartTableData.filter(
                        (item: any) =>
                           item.estimate !== null || item.actual !== null
                     );
                  const labels = this.earningsChartTableData.map(
                     (item: any) => item.label
                  );
                  const estimated = this.earningsChartTableData.map(
                     (item: any) => item.estimate
                  );
                  const actual = this.earningsChartTableData.map(
                     (item: any) => item.actual
                  );

                  this.showEarningsChart = estimated.length > 0 && actual.length > 0

                  this.earningsChartData = {
                     labels: labels,
                     datasets: [
                        {
                           label: 'Estimated',
                           data: estimated,
                           backgroundColor: '#4D88B2',
                           categoryPercentage: 0.5,
                        },
                        {
                           label: 'Actual',
                           data: actual,
                           backgroundColor: '#ACE8DC',
                           categoryPercentage: 0.5,
                        },
                     ],
                  };
               });
         });
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes?.ticker?.currentValue) {
         this.forecastsCollection(this.ticker);
         // this.setPriceData()
      }
      this.updateUi();
   }

   loadData() {
      this.loading = true;
      this.apiDataAvailable = false;

      if (this.ticker) {
         this.forecastsCollection(this.ticker);
      }
      // this.getPriceData(this.ticker)
   }

   isDataAvailable() {
      var available =
         this.showPriceChart ||
         this.showRevenueChart ||
         this.showEarningsChart;
      return available;
   }

   updateUi() {
      this.handlePriceRow();
      this.handleRevenueRow();
      this.handleEarningsRow();
   }

   handlePriceRow() {
      if (this.fundamentals) {
         if (
            this.fundamentals?.forecast != null &&
            this.fundamentals?.statusForecast != this.noData
         ) {
            this.showFirstPrice = true;
         } else {
            this.showFirstPrice = false;
         }

         if (
            this.fundamentals?.targetMedianPrice != null &&
            this.fundamentals?.statusTargetMedianPrice != this.noData
         ) {
            this.showFirstChip = true;
         } else {
            this.showFirstChip = false;
         }

         this.showPriceRow = this.showFirstPrice || this.showFirstChip;
      }
   }

   handleRevenueRow() {
      if (
         this.forecast &&
         (this.forecast?.revenueAvgPerShareDataStatus != this.noData ||
            this.forecast?.revenuePercentageChangeDataStatus != this.noData)
      ) {
         this.showRevenueRow = true;
      } else {
         this.showRevenueRow = false;
      }
   }

   handleEarningsRow() {
      if (
         this.forecast &&
         (this.forecast?.epsAvgDataStatus != this.noData ||
            this.forecast?.earningPercentageChangeDataStatus != this.noData)
      ) {
         this.showEarningsRow = true;
      } else {
         this.showEarningsRow = false;
      }
   }

   private async forecastsCollection(ticker: string) {
      try {
         this.loading = true;
         let data = await this.typesenseService.forecastCollection(ticker);
         this.forecast = data;
         this.updateUi();
         this.apiDataAvailable = true;
         this.loading = false;
      } catch (e) {
         console.log(e);
         this.apiDataAvailable = false;
         this.loading = false;
      } finally {
         this.loading = false;
      }
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   onViewSampleClicked(componentType: string) {
      this.conditionalRenderingService.setComponentType(componentType);
   }
}
