<div class="px-3 px-lg-0 checklist-wrapper" *ngIf="showInvestmentChecklist">
   <div class="loader-wrapper" *ngIf="loading">
      <ngx-skeleton-loader
         count="4"
         [theme]="{ 'border-radius': '5px', height: '50px', 'background-color': '#ededed' }">
      </ngx-skeleton-loader>
   </div>

   <ng-container *ngIf="!(canReadInvestmentChecklist || isSample)">
      <app-feature-locked-card
         heading="Investment Checklist"
         text="A thorough checklist of Expected Returns, Risk Factor, Sharpe ratio & Dividend Yield to help you take a better decision in investing!"
         (viewSampleClicked)="onViewSampleClicked('checklist')"
         componentPage="investment_checklist">
      </app-feature-locked-card>
   </ng-container>

   <ng-container *ngIf="canReadInvestmentChecklist || isSample">
      <div class="" *ngIf="!loading">
         <div class="checklist-section" *ngIf="showRow1 || showRow2 || showRow3 || showRow4">
            <div>
               <div class="d-flex align-items-center justify-content-between section-heading-div">
                  <h2 class="key-heading mb-0 fw-600">Investment Checklist</h2>
                  <img src="/assets/images/section-icon-checklist.svg" alt=" icon" width="24" class="d-lg-none"/>
               </div>

               <!-- annual return item start -->
               <div class="checklist-item" *ngIf="showRow1">
                  <div class="d-flex align-items-center justify-content-between justify-content-lg-start gap-3 gap-lg-5 mb-2">
                     <h3 class="mb-0 d-flex align-items-center checklist-heading fw-600">
                        <span class="icon-span pos icon-caret d-flex align-items-center justify-content-center me-2" [ngClass]="getAnnualReturnClass(fundamentals?.annualReturn)">
                           <i class="fa-solid fa-play" [ngClass]="getAnnualReturnClass(fundamentals?.annualReturn)"></i>
                        </span>
                        {{ fundamentals?.annualReturn | number : "1.2-2" }}% Expected Annual Return
                     </h3>
                     <div *ngIf="isSample">
                        <app-sample-bubble [bubbleContent]="annualText" tooltipPosition="left"></app-sample-bubble>
                     </div>
                  </div>
                  <p class="mb-0 checklist-para d-inline-block">
                     Based on 1 year median target stock price of {{ currency }} {{ fundamentals?.targetMedianPrice | number : "1.2-2" }} and annual dividend yield of {{ fundamentals?.dividendYield | number : "1.2-2" }}%
                  </p>
               </div>
               <!-- annual return item end -->

               <!-- risk level item start -->
               <div class="checklist-item" *ngIf="showRow2">
                  <div class="d-flex align-items-center justify-content-between justify-content-lg-start gap-3 gap-lg-5 mb-2">
                     <h3 class="mb-0 d-flex align-items-center checklist-heading fw-600">
                        <div class="icon-span icon-caret d-flex align-items-center justify-content-center me-2" [ngClass]="getRiskLevelStatusClass(data?.riskLevelStatus)">
                           <i class="fa-solid fa-exclamation" [ngClass]="getRiskLevelStatusClass(data?.riskLevelStatus)"></i>
                        </div>
                        <span *ngIf="data?.riskLevelStatus; else noData">
                           {{ data?.riskLevelStatus ? data?.riskLevelStatus : "-"}}
                        </span>
                        <ng-template #noData> - </ng-template>
                     </h3>
                     <div class="ms-lg-3" *ngIf="isSample">
                        <app-sample-bubble [bubbleContent]="riskText" tooltipPosition="left"></app-sample-bubble>
                     </div>
                  </div>
                  <p class="mb-0 checklist-para d-inline-block">
                     This stock is {{ data?.riskLevel | number : "1.2-2" }}x as volatile as the {{investmentBenchmark ? investmentBenchmark.benchmark : ''}}
                  </p>
               </div>
               <!-- risk level item end -->

               <!-- sharpe ration item start -->
               <div class="checklist-item" *ngIf="showRow3">
                  <div class="d-flex align-items-center justify-content-between justify-content-lg-start gap-3 gap-lg-5 mb-2">
                     <h3 class="mb-0 d-flex align-items-center checklist-heading fw-600">
                        <span class="icon-span pos icon-caret d-flex align-items-center justify-content-center me-2" [ngClass]="getSharpieRatioStatusClass(data?.sharpeRatioStatus)">
                           <i class="fa-solid fa-scale-balanced" [ngClass]="getSharpieRatioStatusClass(data?.sharpeRatioStatus)"></i>
                        </span>
                        {{ data?.sharpeRatioStatus }} Sharpe Ratio
                     </h3>
                     <div *ngIf="isSample">
                        <app-sample-bubble [bubbleContent]="sharpeRatioText" tooltipPosition="left"></app-sample-bubble>
                     </div>
                  </div>
                  <p class="mb-0 checklist-para d-inline-block">
                     This stock has a Sharpe ratio of {{ data?.sharpeRatioValue | number : "1.2-2" }} and expected to give {{ getSharpeRatioClass(data?.sharpeRatioStatus) }} returns compared to the risk involved.
                  </p>
               </div>
               <!-- sharpe ration item end -->

               <!-- dividendYield item start -->
               <div class="checklist-item" *ngIf="showRow4">
                  <div class="d-flex align-items-center justify-content-between justify-content-lg-start gap-3 gap-lg-5 mb-2">
                     <h3 class="mb-0 d-flex align-items-center checklist-heading fw-600">
                        <span class="icon-span icon-caret d-flex align-items-center justify-content-center me-2" *ngIf=" fundamentals?.dividendYield != null && marketDividendYield != null" [ngClass]="getDividendYieldClass(fundamentals?.dividendYield, marketDividendYield)">
                           <i class="fa-solid fa-play" [ngClass]="getDividendYieldClass(fundamentals?.dividendYield, marketDividendYield)"></i>
                        </span>
                        {{ fundamentals?.dividendYield | number: '1.2-2' }}% Dividend Yield
                     </h3>
                     <div *ngIf="isSample">
                        <app-sample-bubble [bubbleContent]="dividendYieldText" tooltipPosition="left"></app-sample-bubble>
                     </div>
                  </div>
                  <p class="mb-0 checklist-para d-inline-block" *ngIf="fundamentals?.dividendYield != 0; else noValue">
                     <span *ngIf=" fundamentals?.dividendYield >= marketDividendYield">
                        This stock offers higher dividend yield compared to the market dividend yield of {{ marketDividendYield }}%
                     </span>
                     <span *ngIf=" fundamentals?.dividendYield < marketDividendYield">
                        This stock offers lower dividend yield compared to the market dividend yield of {{ marketDividendYield }}%
                     </span>
                  </p>
                  <ng-template #noValue>
                     This stock does not pay dividends
                  </ng-template>
               </div>
               <!-- dividendYield item end -->
            </div>
         </div>
      </div>
   </ng-container>
</div>

<!-- <ng-container *ngIf="!showInvestmentChecklist && userRole === 'PREMIUM'">
   <div class="mt-4" *ngIf="!loading && !(showRow1 || showRow2 || showRow3 || showRow4)">
      <app-no-data-card heading="Investment Checklist"></app-no-data-card>
   </div>
</ng-container> -->

<!-- <ng-template #noChecklist>
   <div class="mt-4">
      <app-no-data-card heading="Investment Checklist"></app-no-data-card>
   </div>
</ng-template> -->
