<div class="left__col_pvalue"  *ngIf="!loading; else loadingTemplate">
   <div class="pvalue__title mb-2 mb-lg-3">
      <h5 class="mb-0">Portfolio Value</h5>
      <!-- <span><i>$</i>500 000.00</span> -->
      <div class="portfolio-value fw-600 text-black">
         {{
            accountDetails?.portfolio_value
               | currency : accountDetails?.currency
         }}
      </div>
   </div>
   <ul class="mb-0">
      <li>
         <span>Stocks</span>
         <!-- <span class="semiBold"><i>$</i>300 000.00</span> -->
         <div class="acc-value fw-600 text-black">
            {{
               accountDetails?.total_current
                  | currency : accountDetails?.currency
            }}
         </div>
      </li>
      <li>
         <span>Cash</span>
         <!-- <span class="semiBold"><i>$</i>150 000.00</span> -->
         <div class="acc-value fw-600 text-black">
            {{
               accountDetails?.cash_balance
                  | currency : accountDetails?.currency
            }}
         </div>
      </li>
   </ul>
</div>
<ng-template #loadingTemplate>
   <!-- <div class="d-flex flex-column gap-2"> -->
      <ngx-skeleton-loader
         count="3"
         appearance="line"
         animation="progress"
         [theme]="{ height: '28px', width: '100%', maxWidth: '450px', margin: '5px 0', display: 'block' }"
      >
      </ngx-skeleton-loader>
   <!-- </div> -->
</ng-template>
