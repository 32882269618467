import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
   providedIn: 'root'
})

export class NewsService {
   url: string = environment.apiUrl;

   private stockSymbolsSubject = new BehaviorSubject<string[]>([]);
   stockSymbols$ = this.stockSymbolsSubject.asObservable();

   private etfSymbolsSubject = new BehaviorSubject<string[]>([]);
   etfSymbols$ = this.etfSymbolsSubject.asObservable();

   constructor(
      private http: HttpClient
   ) {}

   setStocksSymbols(data: string[]) {
      this.stockSymbolsSubject.next(data)
   }

   getStocksSymbols() {
      this.stockSymbolsSubject.value
   }

   setETFsSymbols(data: string[]) {
      this.etfSymbolsSubject.next(data)
   }

   getETFsSymbols() {
      this.etfSymbolsSubject.value
   }

   async fetchStockNews<T>(symbols: string[]): Promise<T>  {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + '/api/data/get-relevant-stock-news', { symbols }
         )
      );
   }

   async fetchETFNews<T>(symbols: string[]): Promise<T>  {
      return await lastValueFrom(
         this.http.post<T>(
            this.url + '/api/data/get-relevant-etf-news', { symbols }
         )
      );
   }

   // async getAllLists<T>(
   //    page: number,
   //    per_page: number = 10,
   //    sort_by: string,
   //    sort_order: string,
   //    name?: string, 
   //    filter_by?: string[],
   // ): Promise<T> {
   //    let params = new HttpParams()
   //       .set('page', page)
   //       .set('per_page', per_page)
   //       .set('sort_by', sort_by)
   //       .set('sort_order', sort_order);

   //    if (filter_by && filter_by.length > 0) {
   //       const filterByString = `symbols:['${filter_by.join("','")}']`;
   //       params = params.set('filter_by', filterByString);
   //    }

   //    if (name) {
   //       params = params.set('name', name);
   //    }

   //    return await lastValueFrom(
   //       this.http.get<T>(this.url + '/api/public-lists', { params })
   //    );
   // }

}