<div class="chart-container" *ngIf="!loading; else loadingTemplate">
   <ng-container *ngIf="filteredData && filteredData.length > 0">
      <!-- Time range buttons -->
      <div class="time-range-selector d-flex align-items-center fles-wrap">
         <button
            class="fw-500"
            *ngFor="let range of timeRanges"
            (click)="onRangeChange(range)"
            [class.active]="selectedRange === range"
         >
            {{ range }}
         </button>
      </div>
      <!-- Chart -->
      <canvas
         id="lineChart"
         [style.display]="loading ? 'none' : 'block'"
      ></canvas>
   </ng-container>
   <!-- <ng-container *ngIf="filteredData?.length === 0">
      <div
         class="d-flex justify-content-center align-items-center pt-5"
         style="height: 100%"
      >
         <p>No Data</p>
      </div>
   </ng-container> -->
</div>
<ng-template #loadingTemplate>
   <app-container-loader
      [width]="'100%'"
      [height]="'20rem'"
   ></app-container-loader>
</ng-template>
