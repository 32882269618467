import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TypesenseService } from './typesense.service';
import {
   Observable,
   BehaviorSubject,
   Subject,
   lastValueFrom,
   shareReplay,
   catchError,
   map,
   throwError,
   MonoTypeOperatorFunction,
} from 'rxjs';
import { PurificationMethod } from 'src/app/models/purification-method.model';
import { FirebaseRemoteConfigService } from './firebase-remote-config.service';
import { StockSellTracking } from 'src/app/models/StockSellTracking';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConnectIframeModalComponent } from '../components/connect-iframe-modal/connect-iframe-modal.component';
import { RequestHolding, TransactionObj } from 'src/app/models/portfolio.model';
import { SupportedInstitutionsComponent } from '../components/supported-institutions/supported-institutions.component';
import { BrokersPopupComponent } from '../components/brokers-popup/brokers-popup.component';
import { PortfolioLimitMessagePopupComponent } from '../components/portfolio/portfolio-limit-message-popup/portfolio-limit-message-popup.component';
import {
   PortfolioAccount,
   PortfolioListApiResponse,
} from 'src/app/models/portfolio/portfolio-list.model';
import { ConnectedCountApiResponse, ConnectedCountData } from 'src/app/models/portfolio/connected-count.model';
import { ConnectPortfolioPopupComponent } from 'src/app/features/portfolio/components/connect-portfolio-popup/connect-portfolio-popup.component';
import { UnlockBrokerPopupComponent } from '../components/unlock-broker-popup/unlock-broker-popup.component';

@Injectable({
   providedIn: 'root',
})
export class PortfolioService {
   pipe(arg0: MonoTypeOperatorFunction<unknown>) {
      throw new Error('Method not implemented.');
   }
   url: string = environment.apiUrl;
   private loadingSubject = new BehaviorSubject<boolean>(false);
   private trackingsData = new BehaviorSubject<{
      pending: StockSellTracking[];
      purified: StockSellTracking[];
      notPurifiable: StockSellTracking[];
   }>({
      pending: [],
      purified: [],
      notPurifiable: [],
   });

   // <---- new ------->
   private selectedAccountIdSubject!: BehaviorSubject<string>;
   public accountId$!: Observable<string>;
   // <-----  new ------>

   private holdingsSource = new BehaviorSubject<any[]>([]);
   currentHoldings = this.holdingsSource.asObservable();

   public static purificationMethods: Array<PurificationMethod> = [];

   public static selectedPurificationMethod =
      new BehaviorSubject<PurificationMethod>({
         name: '',
         value: '',
         description: '',
      });

   public static selectedAccountId = new BehaviorSubject<string>(
      sessionStorage.getItem('selectedAccount') ?? ''
   );
   public static pendingPurificationTrackings: Array<StockSellTracking> = [];

   public loading$ = this.loadingSubject.asObservable();
   private dateSubject = new BehaviorSubject<Date | null>(null);
   public date$ = this.dateSubject.asObservable();

   private syncSubject = new Subject<void>();
   dataSync$ = this.syncSubject.asObservable();
   iframeBrokerageModalRef!: NgbModalRef;
   suppotedBrokeragesModalRef!: NgbModalRef;
   myBrokeragesModalRef!: NgbModalRef;

   private hasBrokerageIdSubject: BehaviorSubject<boolean> =
      new BehaviorSubject<boolean>(false);
   public hasBrokerageId$: Observable<boolean> =
      this.hasBrokerageIdSubject.asObservable();
   private brokerageIdSubject: BehaviorSubject<string | null> =
      new BehaviorSubject<string | null>(null);
   public brokerageId$: Observable<string | null> =
      this.brokerageIdSubject.asObservable();

   public addedHolding = [];

   private transactionPopup = new BehaviorSubject<boolean>(false);
   private brokerageScreen = new BehaviorSubject<string>('default');
   private isFromManageTransactions = new BehaviorSubject<boolean>(false);
   private chartDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
   private selectedOptionSubject: BehaviorSubject<any> =
      new BehaviorSubject<any>(null);

   private portfolioAccounts$: Observable<PortfolioAccount[]> | null = null;
   private portfolioMessageModalRef: NgbModalRef | undefined;

   private connectedCount$: Observable<ConnectedCountData> | null = null;
   private connectPortfolioModalRef: NgbModalRef | undefined;
   private unlockBrokerageModalRef: NgbModalRef | undefined;

   constructor(
      private http: HttpClient,
      private configService: FirebaseRemoteConfigService,
      private modalService: NgbModal
   ) {
      this.getMethodologyDefaults();
      this.selectedAccountIdSubject = new BehaviorSubject<string>('');
      this.accountId$ = this.selectedAccountIdSubject.asObservable();
   }

   setChartData(data: any) {
      this.chartDataSubject.next(data);
   }

   getChartData(): Observable<any> {
      return this.chartDataSubject.asObservable();
   }

   getAuthUrl() {
      return this.http.get(this.url + '/api/snaptrade/auth-url');
   }

   getReconnectUrl(brokerage_id: any, is_for_trade: boolean) {
      return this.http.post(this.url + '/api/snaptrade/reconnect-brokerage', {
         brokerage_id: brokerage_id,
         // is_for_trade: is_for_trade === true ? true : false,
         is_for_trade: true,
      });
   }

   getConnectUrl(brokerage_id: string, is_for_trade: boolean | undefined) {
      return lastValueFrom(
         this.http.post(this.url + '/api/portfolio/connect', {
            id: brokerage_id,
            // is_for_trade: is_for_trade === true ? true : false,
            is_for_trade: true,
         })
      );
   }

   getBrokerages(): Observable<any> {
      return this.http.post(this.url + '/api/portfolio/brokerages', {});
   }

   getAccounts(): Observable<any> {
      return this.http.post(this.url + '/api/portfolio/accounts', {});
   }

   portfolio(account_id: string) {
      return this.http.post(this.url + '/api/portfolio', {
         account_id: account_id,
      });
   }

   purificationOverview(
      account_id: string,
      purification_method?: string
   ): Observable<any> {
      return this.http.post(
         this.url + '/api/portfolio/v2/purification-overview',
         {
            account_id: account_id,
            ...(purification_method
               ? { purification_method: purification_method }
               : {}),
         }
      );
   }

   syncPortfolio(accountId: string = '') {
      return this.http.get(
         this.url +
         '/api/portfolio/sync' +
         (accountId ? '?account_id=' + accountId : '')
      );
   }

   syncManualPortfolio(accountId: string) {
      return this.http.post(
         this.url + '/api/portfolio/sync-account-current-price',
         { account_id: accountId }
      );
   }

   showSyncLoading() {
      this.loadingSubject.next(true);
   }

   hideSyncLoading() {
      this.loadingSubject.next(false);
   }

   updateDate(date: Date | null) {
      this.dateSubject.next(date);
   }

   triggerDataReload() {
      this.syncSubject.next();
   }

   setPendingPurifications(trackings: Array<StockSellTracking>) {
      PortfolioService.pendingPurificationTrackings = trackings;
   }

   setPurificationMethod(methodValue: string) {
      const purificationMethod = PortfolioService.purificationMethods.find(
         // (item: PurificationMethod) => item.value == methodValue
         (item: PurificationMethod) => item.value == 'haram_purification'
      );
      if (purificationMethod)
         PortfolioService.selectedPurificationMethod.next(purificationMethod);
   }

   setAccountId(accountId: string) {
      if (accountId) {
         sessionStorage.setItem('selectedAccount', accountId);
      } else {
         sessionStorage.removeItem('selectedAccount');
      }
      PortfolioService.selectedAccountId.next(accountId);
   }

   setAddedHolding(holdings: any) {
      this.addedHolding = holdings;
   }

   public async getPurificationOverview(
      account_id: string,
      purificationMethod?: string
   ) {
      return await lastValueFrom(
         this.http.post<any>(
            this.url + '/api/portfolio/v2/purification-overview',
            {
               account_id: account_id,
               ...(purificationMethod
                  ? { purification_method: purificationMethod }
                  : {}),
            }
         )
      );
   }

   public async getPurificationActivities(account_id: string) {
      return await lastValueFrom(
         this.http.post<any>(
            this.url + '/api/portfolio/v2/purification-activities',
            {
               account_id: account_id,
            }
         )
      );
   }

   public async markPurified(id: number, purificationMethod: string) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/v2/mark-purified', {
            id: id,
            purification_method: purificationMethod,
         })
      );
   }

   public async markAllPurified(
      account_id: string,
      purificationMethod?: string
   ) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/v2/mark-all-purified', {
            account_id: account_id,
            ...(purificationMethod
               ? { purification_method: purificationMethod }
               : {}),
         })
      );
   }

   async getDefaultPurificationMethod() {
      return await lastValueFrom(
         this.http.get<any>(this.url + '/api/portfolio/settings')
      );
   }

   async setDefaultPurificationMethod(
      setting_key: string,
      setting_value: string
   ) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/settings', {
            setting_key: setting_key,
            setting_value: setting_value,
         })
      );
   }

   async getAllowedBrokerages() {
      return await lastValueFrom(
         this.http.get<any>(this.url + '/api/snap-trade/allowed-brokerages')
      );
   }

   async getSupportedBrokerages() {
      return await lastValueFrom(
         this.http.get<any>(this.url + '/api/supported-brokerages/snaptrade')
      );
   }

   async getConvertedPrice(ticker: string, currency: string) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/fetch-converted-price', {
            ticker: ticker,
            currency: currency,
         })
      );
   }

   async getHistoricalPrice(ticker: string, currency: string, date: string) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/stocks/' + encodeURIComponent(ticker) + '/price', {
            date,
            currency,
         })
      );
   }

   async getTradingOrders<T>(accountId: string) {
      return await lastValueFrom(
         // this.http.post<T>(this.url + '/api/trading/get-portfolio-orders', {
         //    account_id: accountId,
         // })
         this.http.get<T>(
            this.url +
            '/api/trading/get-portfolio-orders?account_id=' +
            accountId
         )
      );
   }

   async getMethodologyDefaults() {
      let purificationDefaults =
         await this.configService.getPurificationDefaults();
      // PortfolioService.purificationMethods =
      //    purificationDefaults.purification_methods.map((method: any) => ({
      //       name: method.title,
      //       value: method.type,
      //       description: method.description,
      //    }));

      // console.log(purificationDefaults.purification_methods);

      PortfolioService.purificationMethods =
         purificationDefaults.purification_methods
            .filter((item: any) => item.type == 'haram_purification')
            .map((method: any) => ({
               name: method.title,
               value: method.type,
               description: method.description,
            }));

      if (PortfolioService.selectedPurificationMethod.getValue()?.name) {
         PortfolioService.selectedPurificationMethod.next(
            PortfolioService.purificationMethods[0]
         );
      }
   }

   getTrackingsData() {
      return this.trackingsData.asObservable();
   }

   setTrackingsData(data: {
      pending: StockSellTracking[];
      purified: StockSellTracking[];
      notPurifiable: StockSellTracking[];
   }) {
      this.trackingsData.next(data);
   }

   async unlinkBrokerage(brokerage_id: string, account_id: string) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/unlink-brokerage', {
            brokerage_id: brokerage_id,
            account_id: account_id
         })
      );
   }

   openConnectBrokerageIframeModal(url: string) {
      this.iframeBrokerageModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.iframeBrokerageModalRef.componentInstance.iframeUrl = url;
   }

   closeConnectBrokerageIframeModal() {
      if (this.iframeBrokerageModalRef) {
         this.iframeBrokerageModalRef.dismiss();
      }
   }

   setHasBrokerageId(hasId: boolean) {
      this.hasBrokerageIdSubject.next(hasId);
   }

   setBrokerageId(id: string | null) {
      this.brokerageIdSubject.next(id);
   }

   getTransactionState(): Observable<boolean> {
      return this.transactionPopup.asObservable();
   }

   setTransactionState(isOpen: boolean): void {
      this.transactionPopup.next(isOpen);
   }

   getIsFromManageTransactions(): Observable<boolean> {
      return this.isFromManageTransactions.asObservable();
   }

   setIsFromManageTransactions(value: boolean): void {
      this.isFromManageTransactions.next(value);
   }

   setBrokerageScreen(value: string) {
      this.brokerageScreen.next(value);
   }

   getBrokerageScreen() {
      return this.brokerageScreen.asObservable();
   }

   async addPortfolio(name: string, currency: string) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/manual', {
            name,
            currency,
         })
      );
   }

   async saveHoldings(account_id: string, holdings: RequestHolding[]) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/manual/holdings', {
            account_id,
            holdings,
         })
      );
   }

   async getAccountHoldings(account_id: string) {
      return await lastValueFrom(
         this.http.post<any>(this.url + '/api/portfolio/account-holdings', {
            account_id,
         })
      );
   }

   async deleteTransaction(id: string) {
      return await lastValueFrom(
         this.http.post<any>(
            this.url + '/api/portfolio/holding-transactions/delete',
            {
               id,
            }
         )
      );
   }

   async saveTransaction(transactionData: TransactionObj) {
      return await lastValueFrom(
         this.http.post<any>(
            this.url + '/api/portfolio/manual/holdings/transactions',
            transactionData
         )
      );
   }

   public openSupportedBrokeragesModal(isView: boolean) {
      this.suppotedBrokeragesModalRef = this.modalService.open(
         SupportedInstitutionsComponent,
         {
            centered: true,
            size: 'xl',
            scrollable: true,
            backdrop: 'static',
            windowClass: 'rounded-modal',
         }
      );
      this.suppotedBrokeragesModalRef.componentInstance.view = isView;
   }

   public closeSupportedBrokeragesModal() {
      if (this.suppotedBrokeragesModalRef) {
         this.suppotedBrokeragesModalRef.close();
      }
   }

   public openMyBrokeragesModal(accounts: any) {
      this.myBrokeragesModalRef = this.modalService.open(
         BrokersPopupComponent,
         {
            centered: true,
            scrollable: true,
         }
      );
      this.myBrokeragesModalRef.componentInstance.accounts = accounts;
   }

   public closeMyBrokeragesModal() {
      if (this.myBrokeragesModalRef) {
         this.myBrokeragesModalRef.close();
      }
   }

   setSelectedOption(option: any) {
      this.selectedOptionSubject.next(option);
   }

   getSelectedOption() {
      return this.selectedOptionSubject.asObservable();
   }

   updateHoldings(data: any[]) {
      this.holdingsSource.next(data);
   }

   getPortfoliosList(): Observable<PortfolioAccount[]> {
      this.portfolioAccounts$ =
         this.portfolioAccounts$ ??
         this.fetchPortfoliosList().pipe(shareReplay());
      return this.portfolioAccounts$;
   }

   refetchPortfoliosList(): Observable<PortfolioAccount[]> {
      this.portfolioAccounts$ = null; // Clear the cache
      this.portfolioAccounts$ = this.fetchPortfoliosList().pipe(shareReplay()); // Re-fetch the data and cache it again
      return this.portfolioAccounts$;
   }

   private fetchPortfoliosList() {
      return this.http
         .get<PortfolioListApiResponse>(this.url + '/api/v2/portfolio/accounts')
         .pipe(
            map((response) => response.data),
            catchError((error) => {
               console.error('Error fetching portfolio list', error);
               return throwError(() => error);
            })
         );
   }

   // fetchPortfoliosList<T>(): Observable<T> {
   //    return this.http.get<T>(this.url + '/api/v2/portfolio/accounts');
   // }

   fetchSelectedPortfolioDetails<T>(id: string): Observable<T> {
      return this.http.get<T>(`${this.url}/api/v2/portfolio/accounts/${id}`);
   }

   fetchBrokeragesCoverage<T>(countryCode: string): Observable<T> {
      return this.http.get<T>(
         `${this.url}/api/trading/pricing/brokerage-coverage`
         // ?country_code=${countryCode}
      );
   }

   // <---- New Data ----->
   setSelectedAccountId(id: string): void {
      this.selectedAccountIdSubject.next(id);
   }

   getSelectedAccountId(): string {
      return this.selectedAccountIdSubject.value;
   }

   fetchPortfolioAnalytics<T>(account_id: string): Observable<T> {
      return this.http.post<T>(`${this.url}/api/portfolio/account-analytics`, {
         account_id,
      });
   }

   // connect count api functions start
   fetchAccountCounts(): Observable<ConnectedCountData> {
      return this.http
         .get<ConnectedCountApiResponse>(`${this.url}/api/portfolio/connected-count`)
         .pipe(
            map((response) => response.data),
            catchError((error) => {
               console.error('Error fetching connected counts', error);
               return throwError(() => error);
            })
         )
   }

   getAccountCounts(): Observable<ConnectedCountData> {
      this.connectedCount$ = this.connectedCount$ ?? this.fetchAccountCounts().pipe(shareReplay());
      return this.connectedCount$;
   }

   refetchAccountCounts(): Observable<ConnectedCountData> {
      this.connectedCount$ = this.fetchAccountCounts().pipe(shareReplay());
      return this.connectedCount$;
   }
   // connect count api functions end

   openMessagePopup(type: 'broker' | 'manual') {
      this.portfolioMessageModalRef = this.modalService.open(PortfolioLimitMessagePopupComponent, {
         centered: true,
         backdrop: 'static',
         backdropClass: 'nested-modal-backdrop',
         windowClass: 'nested-modal'
      });
      this.portfolioMessageModalRef.componentInstance.portfolioType = type;
   }


   openConnectPortfolioPopup() {
      this.connectPortfolioModalRef = this.modalService.open(ConnectPortfolioPopupComponent, {
         centered: true,
         scrollable: true,
         // backdrop: 'static',
         backdropClass: 'global-modal-backdrop',
         windowClass: 'connect-portfolio-popup',
         size: 'xl'
      });
      this.connectPortfolioModalRef.componentInstance.getAccountsCount();
   }

   closeConnectPortfolioPopup() {
      if (this.connectPortfolioModalRef) {
         this.connectPortfolioModalRef.close();
      }
   }

   openUnlockBrokeragePopup() {
      this.unlockBrokerageModalRef = this.modalService.open(UnlockBrokerPopupComponent, {
         centered: true,
         scrollable: true,
         // backdrop: 'static',
         backdropClass: 'global-modal-backdrop',
         windowClass: 'connect-portfolio-popup',
      });
      this.unlockBrokerageModalRef.componentInstance.getAccountsCount();
   }

   closeUnlockBrokeragePopup() {
      if (this.unlockBrokerageModalRef) {
         this.unlockBrokerageModalRef.close();
      }
   }
}
