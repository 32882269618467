import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AnalyticsUtils } from '../../services/analytics-utils';
import { SubscriptionService } from '../../services/subscription.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AccessControlService } from '../../services/access-control.service';

@Component({
   selector: 'app-dashboard-screener-cards',
   templateUrl: './dashboard-screener-cards.component.html',
   styleUrls: ['./dashboard-screener-cards.component.scss'],
})
export class DashboardScreenerCardsComponent implements OnInit {
   etfUrl: string = '/cabinet/etf-srcreener';
   userRole: string | null = null;
   private subscriptions: Subscription[] = [];

   constructor(
      private observer: BreakpointObserver,
      private analyticsUtils: AnalyticsUtils,
      public subscriptionService: SubscriptionService,
      private router: Router,
      public accessControlService: AccessControlService
   ) {}

   ngOnInit(): void {
      this.getEtfLink();
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );
   }

   getEtfLink() {
      this.observer.observe('(min-width: 992px)').subscribe((result) => {
         if (result.matches) {
            this.etfUrl = '/cabinet/etf-srcreener';
         } else {
            this.etfUrl = '/cabinet/onboarding-etf';
         }
      });
   }

   homeStockScreenerTapped() {
      this.analyticsUtils.Home_StockScreener_Tapped();
   }

   homeETFScreenerTapped() {
      this.analyticsUtils.Home_ETFScreener_Tapped();
   }

   homeStockPurificationTapped() {
      this.analyticsUtils.Home_StockPurification_Tapped();
   }

   homeZakatTapped() {
      this.analyticsUtils.Home_Zakat_Tapped();
      if (this.userRole === 'ANONYMOUS') {
         this.accessControlService.accessDenied();
      } else {
         this.router.navigate(['/zakat-calculator']);
      }
   }
}
