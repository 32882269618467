<div [ngClass]="['brokerages-container', isForBusiness ? 'pt-0' : 'pt-5']">
   <h4 class="text-center mb-4 text-uppercase" *ngIf="!isForBusiness">Supported Brokerages</h4>
   <div class="brokerages-slider-container py-3">
      <owl-carousel-o [options]="customOptions">

         <ng-container *ngFor="let brokerage of brokerages; trackBy: trackByBrokerageId">
            <ng-template carouselSlide [id]="brokerage.id">
               <article class="text-decoration-none brokerage-card rounded-3">
                  <div class="brokerage-image-wrapper d-flex align-items-center justify-content-center rounded-3 overflow-hidden">
                     <img [src]="brokerage.logo" [alt]="brokerage.name" class="brokerage-image img-fluid">
                  </div>
               </article>
            </ng-template>
         </ng-container>

      </owl-carousel-o>
   </div>
</div>
