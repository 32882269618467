import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { InvestmentChecklistVariableModel } from 'src/app/models/investment-checklist-variables.model';
import { TypesenseService } from './typesense.service';

@Injectable({
   providedIn: 'root',
})
export class InvestmentChecklistService {
   constructor(private typesenseService: TypesenseService) {}

   private static isFetched: boolean = false;

   public static investmentChecklistVariables: Array<InvestmentChecklistVariableModel> = [];

   public async getCountryStockBenchmark(countryCode: string): Promise<InvestmentChecklistVariableModel|null> {
      if(!InvestmentChecklistService.isFetched) {
         await this.setInvestmentChecklistVariables()
      }
      
      return InvestmentChecklistService.investmentChecklistVariables
            .find((item: InvestmentChecklistVariableModel) => item.country === countryCode) ?? null
   }

   public async setInvestmentChecklistVariables() {
      InvestmentChecklistService.investmentChecklistVariables = await this.typesenseService.getInvestmentChecklistVariables()
      InvestmentChecklistService.isFetched = true
   }
}
