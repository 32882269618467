import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'app-forecast-data-section',
   templateUrl: './forecast-data-section.component.html',
   styleUrls: ['./forecast-data-section.component.scss'],
})
export class ForecastDataSectionComponent implements OnInit {
   @Input() heading: string = '';
   @Input() subheading: string = '';
   @Input() subheadingPara: string = '';
   @Input() tableData: any = [];
   @Input() currency: any = '';

   constructor() {}

   ngOnInit(): void {
      // console.log(this.tableData)
   }
}
