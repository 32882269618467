import { Component, OnInit, OnDestroy, Renderer2, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Country } from 'src/app/models/market-collection/market-collection.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
   selector: 'app-countries-dropdown',
   templateUrl: './countries-dropdown.component.html',
   styleUrls: ['./countries-dropdown.component.scss']
})
export class CountriesDropdownComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   userRole: string | null = null;
   isMobile: boolean = false;
   countriesLoader: boolean = false;
   isCountriesMenuOpen: boolean = false;
   selectedCountryCode!: string;

   // Default country data
   defaultCountry: Country = {
      country: 'United States',
      countryCode: 'US',
      detailedProfileAvailable: true,
      etfEnabled: true,
      etfExchanges: ['NYSE ARCA', 'NASDAQ'],
      etfsCovered: true,
      id: 'US',
      sortOrder: 1,
      stockEnabled: true,
      stockExchanges: ['NYSE', 'NASDAQ'],
      stocksCovered: true
   };

   allCountries: Country[] = [];
   coveredCountries: Country[] = [];
   otherCountries: Country[] = [];

   countriesModalRef!: NgbModalRef;
   settingsContent!: TemplateRef<any>;
   @ViewChild('countriesModalcontent', { static: true }) countriesModalcontent!: ElementRef;

   constructor(
      private observer: BreakpointObserver,
      private userApiService: UserApiService,
      private countryService: CountryService,
      private modalService: NgbModal,
      private authService: AuthService
   ) { }

   ngOnInit(): void {
      SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
         this.userRole = data.role;
      });

      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.countryService.selectedCountry$.pipe(takeUntil(this.destroy$)).subscribe(country => {
         this.selectedCountryCode = country.countryCode;
      });

      this.getCountriesData();
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   getCountriesData(): void {
      this.countriesLoader = true;
      this.countryService.getCountries().pipe(takeUntil(this.destroy$)).subscribe({
         next: (response) => {
            if (response) {
               this.allCountries = response

               this.coveredCountries = this.allCountries
                  .filter((country: Country) => country.stocksCovered)
                  .sort((countryA: Country, countryB: Country) => (countryA.sortOrder ?? 0) - (countryB.sortOrder ?? 0))

               this.setInitialSelectedCountry();
               this.countriesLoader = false;

            }
         },
         error: (error) => {
            console.error(error);
            this.countriesLoader = false;
         },
         complete: () => {
            this.countriesLoader = false;
         },
      });
   }

   async setInitialSelectedCountry(): Promise<void> {
      try {
         this.countriesLoader = true;
         const resp = await this.userApiService.fetchUserDetails();
         if (resp && resp.default_country) {
            const foundCountry = this.allCountries.find((country: Country) => country.countryCode === resp.default_country);
            if (foundCountry) {
               this.countryService.setSelectedCountry(foundCountry);
               this.countryService.setFilterCountry(foundCountry);
            } else {
               this.countryService.setSelectedCountry(this.defaultCountry);
               this.countryService.setFilterCountry(this.defaultCountry);
            }
         } else {
            this.countryService.setSelectedCountry(this.defaultCountry);
            this.countryService.setFilterCountry(this.defaultCountry);
         }
      } catch (error) {
         console.error(error);
         this.countryService.setSelectedCountry(this.defaultCountry);
         this.countryService.setFilterCountry(this.defaultCountry);
      } finally {
         this.countriesLoader = false;
      }
   }

   onCountrySelect(country: Country): void {
      this.updateUsersDefaultCountry(country);
      this.closeCountriesModal();
   }

   async updateUsersDefaultCountry(country: Country): Promise<void> {
      // this.countryService.setSelectedCountry(country);
      try {
         if (!!this.authService.getUserId()) {
            const resp = await this.userApiService.updateUsersDefaultCountry(country.countryCode);
            if (resp.status) {
               // this.toaster.success(resp.message);
               this.countryService.setSelectedCountry(country);
            } else {
               // this.toaster.error('Something went wrong');
            }
         } else {
            this.countryService.setSelectedCountry(country);
         }
      } catch (error) {
         console.error(error);
      }
   }

   openCountriesModal() {
      this.countriesModalRef = this.modalService.open(this.countriesModalcontent, {
         centered: true,
         scrollable: true,
         size: 'lg'
      });
   }

   closeCountriesModal(): void {
      if (this.countriesModalRef) {
         this.countriesModalRef.dismiss();
      }
   }

}




















// import { Component, OnInit, ElementRef, Renderer2, ViewChild  } from '@angular/core';
// import { Country } from 'src/app/models/market-collection/market-collection.model';
// import { SubscriptionService } from 'src/app/shared/services/subscription.service';
// import { Subject, takeUntil } from 'rxjs';
// import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
// import { BreakpointObserver } from '@angular/cdk/layout';
// import { MarketUtilsService } from 'src/app/shared/services/market-utils.service';
// import { UserApiService } from 'src/app/shared/services/user-api.service';
// import { CountryService } from 'src/app/shared/services/country.service';
// import { ToastrService } from 'ngx-toastr';
// import { Router } from '@angular/router';

// @Component({
//   selector: 'app-countries-dropdown',
//   templateUrl: './countries-dropdown.component.html',
//   styleUrls: ['./countries-dropdown.component.scss']
// })
// export class CountriesDropdownComponent implements OnInit {
//    private destroy$: Subject<void> = new Subject<void>();
//    userRole: string | null = null;
//    isMobile: boolean = false;
//    countriesLoader: boolean = false;
//    isCountriesMenuOpen: boolean = false;
//    selectedCountry!: string
//    coveredCountries: Country[] = []
//    otherCountries: Country[] = []

//    constructor(
//       private observer: BreakpointObserver,
//       private marketUtilsService: MarketUtilsService,
//       private userApiService: UserApiService,
//       private countryService: CountryService,
//       private toaster: ToastrService,
//       private renderer: Renderer2,
//       private elementRef: ElementRef,
//       private router: Router
//    ) { }

//    ngOnInit(): void {
//       SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
//          this.userRole = data.role
//       })

//       this.countryService.selectedCountry$.pipe(takeUntil(this.destroy$)).subscribe(country => {
//          this.selectedCountry = country;
//       });

//       this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
//          this.isMobile = result.matches;
//       });

//       this.renderer.listen('window', 'click', (event: Event) => {
//          this.onDocumentClick(event);
//       });

//       this.getCountries()
//       this.getUsersDefaultCountry()
//    }

//    ngOnDestroy(): void {
//       this.destroy$.next();
//       this.destroy$.complete();
//    }

//    async getCountries() {
//       try {
//          this.countriesLoader = true
//          const resp = await this.marketUtilsService.fetchCountries()
//          if (resp) {
//             this.coveredCountries = resp.filter( (country: Country) => country.detailedProfileAvailable === true );
//             this.otherCountries = resp.filter( (country: Country) => country.detailedProfileAvailable === false );
//          }
//       } catch (error) {
//          console.error(error);
//          this.countriesLoader = false
//       } finally {
//          this.countriesLoader = false
//       }
//    }

//    async getUsersDefaultCountry() {
//       try {
//          const resp = await this.userApiService.fetchUserDetails();
//          if (resp && resp.default_country) {
//             this.countryService.setSelectedCountry(resp.default_country);
//          } else {
//             this.countryService.setSelectedCountry('US');
//          }
//       } catch (error) {
//         this.countryService.setSelectedCountry('US');
//       }
//    }

//    onCountrySelect(country: string): void {
//       this.updateUsersDefaultCountry(country)
//       this.closeCountriesMenu()
//    }

//    updateUsersDefaultCountry(countryCode: string) {
//       this.countryService.setSelectedCountry(countryCode);
//       // try {
//       //    const resp = await this.userApiService.updateUsersDefaultCountry(countryCode)
//       //    if (resp.status) {
//       //       this.toaster.success(resp.message);
//       //       this.countryService.setSelectedCountry(countryCode);
//       //    } else {
//       //       this.toaster.error('Something went wrong');
//       //    }
//       // } catch (error) {
//       //    console.error(error);
//       // } finally {

//       // }
//    }

//    openCountriesDropdown() {
//       return
//       this.isCountriesMenuOpen = true
//       // if(this.userRole !== 'ANONYMOUS') {
//       //    this.isCountriesMenuOpen = true
//       // } else {
//       //    this.router.navigate(['/authentication/login'])
//       // }
//    }

//    closeCountriesMenu() {
//       this.isCountriesMenuOpen = false
//    }

//    onDocumentClick(event: Event): void {
//       if (this.isCountriesMenuOpen && !this.elementRef.nativeElement.contains(event.target)) {
//          this.closeCountriesMenu()
//       }
//    }

// }
