<div class="right_col_row">
   <div class="chart_tab">
       <button class="btn_tab active">Pureness</button>
       <!-- <button class="btn_tab">Allocation</button> -->
   </div>

   <div class="chart-container">
      <canvas #chartCanvas></canvas>

      <!-- Custom Center Text -->
      <div class="custom-label">
         <div class="label__chart">{{halalData.value}}</div>
         <div class="status halal_status">{{halalData.label}}%</div>
      </div>
  </div>


   <div class="chart_text_row">
       <div class="chart_text_col">
           <p>Purification Due</p>
           <h4>$4830</h4>
       </div>
       <span>-</span>
       <div class="chart_text_col">
           <p>Purifed</p>
           <h4>$1239</h4>
       </div>
       <span>=</span>
       <div class="chart_text_col">
           <p>Remaining</p>
           <h4>$3600</h4>
       </div>
   </div>
</div>
