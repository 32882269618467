<div class="modal-header border-0 d-block padding">
   <div class="d-flex align-items-center gap-3 justify-content-between">
      <h2 class="text-start mb-0 fw-600" *ngIf="!etf?.etfCompany">
         Add <span>{{ _stock?.ticker }}</span> to Your Lists
      </h2>
      <h2 class="text-start mb-0 fw-600" *ngIf="etf?.etfCompany">
         Add <span>{{ etf?.symbol }}</span> to Your Lists
      </h2>
      <button type="button" class="btn ms-3 float-end shadow-none p-1 bg-light rounded-3" aria-label="Close" (click)="closeMe()">
         <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="#000000" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
         </svg>
      </button>
   </div>
</div>
<div class="modal-body list-wrapper p-0">

   <div class="pt-0 padding">
      <ng-container *ngIf="!showList">
         <form class="d-grid gap-4" (submit)="watchlistAdd()">
            <button type="button" class="d-flex align-items-center justify-content-center gap-2 fw-600 w-100 rounded-3 create-btn border-0" (click)="createNewList()">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512">
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
               </svg>
               <span>Create new list</span>
            </button>

            <ng-container *ngIf="fetchLoading">
               <ngx-skeleton-loader count="4" appearance="line" animation="progress"
                  [theme]="{ height: '45px', width: '100%', margin: '0px'}">
               </ngx-skeleton-loader>
            </ng-container>

            <ul role="list" class="list-grid custom-scroll-bar" *ngIf="!fetchLoading">
               <li class="form-check d-flex align-items-start py-2 m-0 gap-3" *ngFor="let item of items; trackBy: trackByFn; let i = index">
                  <input 
                     type="checkbox" 
                     class="form-check-input d-inline-block p-0 rounded watchlist-input shadow-none" 
                     [value]="true" 
                     [id]="'checkbox_' + i" 
                     [(ngModel)]="item.checked" 
                     [name]="'checkbox_' + i"
                     [disabled]="!item.checked && ((item?.watchlist_symbols?.length ?? 0) >= maxWatchlistItemsCount) "
                  >
                  <label [for]="'checkbox_' + i" class="flex-grow-1">
                     <h6 class="mb-0 lh-base fw-500 list-name">{{ item.name }}</h6>
                     <p class="mb-0 list-count">
                        <span>{{ item?.watchlist_symbols?.length ?? 0 }}{{(maxWatchlistItemsCount > 0 && maxWatchlistItemsCount != 9999) ? ('/' + maxWatchlistItemsCount) : ''}}</span> Symbol
                     </p>
                  </label>
               </li>
            </ul>

            <button type="submit" class="d-flex align-items-center m-0 justify-content-center gap-2 fw-500 w-100 rounded-3 save-btn border-0" [disabled]="submitLoading">
               <div class="d-flex justify-content-center" *ngIf="submitLoading">
                  <div class="spinner-border spinner-border-sm" role="status">
                     <span class="visually-hidden">Loading...</span>
                  </div>
               </div>
               <span> {{ submitLoading ? 'Saving...' : 'Save Changes' }}</span>
            </button>

         </form>
      </ng-container>

      <app-add-lists-create 
         [showList]="showList" 
         [allWatchlists]="items" 
         (onChange)="getData()"
         (close)="closeAddForm()">
      </app-add-lists-create>

   </div>
   
</div>










<!-- <div class="add-list d-flex align-items-center justify-content-center p-3 p-lg-4" *ngIf="appearance">
   <div class="modal-inner bg-white">
      <header class="d-flex align-items-center justify-content-end w-100 p-2">
         <button type="button" class="btn shadow-none p-1 bg-light rounded-3" aria-label="Close" (click)="closeMe()">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
               <path fill="#000000"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
         </button>
      </header>

      <div class="modal-content-wrapper pb-3 pb-lg-4">
         <h2 class="text-center fw-600 px-3 px-lg-4 modal-heading mb-3 mb-lg-4" *ngIf="!etf?.etfCompany">
            Add <span>{{ _stock?.ticker }}</span> to Your Lists
         </h2>
         <h2 class="text-center fw-600 px-3 px-lg-4 modal-heading mb-3 mb-lg-4" *ngIf="etf?.etfCompany">
            Add <span>{{ etf?.symbol }}</span> to Your Lists
         </h2>

         <ng-container *ngIf="!showList">
            <form class="watchlists-form d-grid gap-4" (submit)="watchlistAdd()">

               <div class="px-3 px-lg-4">
                  <button [disabled]="fetchLoading" type="button"
                     class="d-flex align-items-center justify-content-center gap-2 fw-600 w-100 rounded-3 create-btn border-0"
                     (click)="createNewList()">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512">
                        <path
                           d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                     </svg>
                     <span>Create new list</span>
                  </button>
               </div>

               <div class="lists-wrapper px-3 px-lg-4">
                  <ng-container *ngIf="fetchLoading">
                     <ngx-skeleton-loader count="5" appearance="line" animation="progress"
                        [theme]="{ height: '50px', width: '100%', margin: '0px'}">
                     </ngx-skeleton-loader>
                  </ng-container>

                  <ul role="list" class="d-grid" *ngIf="!fetchLoading">
                     <li class="form-check m-0 d-flex gap-3" *ngFor="let item of items; let i = index">
                        <input class="form-check-input p-0 rounded watchlist-input shadow-none" type="checkbox"
                           [value]="true" [id]="'checkbox_' + i" [(ngModel)]="item.checked" [name]="'checkbox_' + i"
                           [disabled]="!item.checked && ((item?.watchlist_symbols?.length ?? 0) >= maxWatchlistItemsCount) " />
                        <label class="form-check-label m-0 flex-grow-1 form-text" [for]="'checkbox_' + i">
                           <p class="list-name lh-sm fw-600 mb-0">{{ item.name }}</p>
                           <span class="d-inline-block lh-sm list-count">
                              {{ item?.watchlist_symbols?.length ?? 0 }}{{(maxWatchlistItemsCount > 0 && maxWatchlistItemsCount != 9999) ? ('/' + maxWatchlistItemsCount) : ''}} symbols
                           </span>
                        </label>
                     </li>
                  </ul>

               </div>

               <div class="px-3 px-lg-4">
                  <button type="submit"
                     class="d-flex align-items-center justify-content-center gap-2 fw-600 w-100 rounded-3 save-btn border-0"
                     [disabled]="submitLoading">
                     <div class="d-flex justify-content-center" *ngIf="submitLoading">
                        <div class="spinner-border spinner-border-sm" role="status">
                           <span class="visually-hidden">Loading...</span>
                        </div>
                     </div>
                     <span> {{ submitLoading ? 'Saving...' : 'Save Changes' }}</span>
                  </button>
               </div>

            </form>
         </ng-container>

         <app-add-lists-create [showList]="showList" [allWatchlists]="items" (onChange)="getData()"
            (close)="closeAddForm()">
         </app-add-lists-create>

      </div>
   </div>
</div> -->
