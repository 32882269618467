import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, map, Observable, shareReplay, Subject, timer } from 'rxjs';
import { FirebaseRemoteConfigService } from 'src/app/shared/services/firebase-remote-config.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

interface InvestmentData {
   "amount_received": string | number,
   "amount_to_be_raised": string | number,
   "id": string | number,
   "number_of_investors": number,
   "progressbar_percent": number,
   "v2_amount_received": string,
   "v2_amount_to_be_raised": string,
   "v2_investment_countdown": string,
   "v2_investment_end_date": string,
   "v2_number_of_investors": number,
   "v2_progressbar_percent": number
}

export interface TimeRemaining {
   total: number;
   days: number;
   hours: number;
   minutes: number;
   seconds: number;
}

interface PreSeed {
   progress_percent : number | undefined,
   subtitle : string
   title : string
   value : string
}

@Component({
   selector: 'app-invest-banner',
   templateUrl: './invest-banner.component.html',
   styleUrls: ['./invest-banner.component.scss']
})

export class InvestBannerComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   loader: boolean = false;
   investmentData: InvestmentData = {
      amount_received: '',
      amount_to_be_raised: '',
      id: '',
      number_of_investors: 0,
      progressbar_percent: 0,
      v2_amount_received: '',
      v2_amount_to_be_raised: '',
      v2_investment_countdown: '',
      v2_investment_end_date: '',
      v2_number_of_investors: 0,
      v2_progressbar_percent: 0
   };
   cardHeading: string = ''
   preSeedData: PreSeed = { progress_percent: 0, subtitle: '', title: '', value: '' };
   isBannerCollapsed: string = sessionStorage.getItem('isBannerCollapsed') || '';

   countdown$: Observable<TimeRemaining> | undefined;
   countdownComplete: boolean = false;

   constructor(
      private typesenseService: TypesenseService,
      private remoteConfigService: FirebaseRemoteConfigService,
   ) { }

   ngOnInit(): void {
      this.getCrowdfundingsDefaults()
      this.getInvestmentData()
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async getCrowdfundingsDefaults() {
      const data = await this.remoteConfigService.getCrowdfundingDefaults()
      this.cardHeading = data.title
      this.preSeedData = data.preseed_defaults
   }

   async getInvestmentData(){
      try {
         this.loader = true
         await this.typesenseService.getInvestmentProgress().then(data => {
            this.investmentData = data

            if (this.investmentData.v2_investment_countdown) {
               const localEndTime = this.convertToLocalTime(this.investmentData.v2_investment_countdown).toISOString();
               // const localEndTime = this.convertToLocalTime('2024-10-26 05:00:00').toISOString();
               this.countdown$ = this.startCountdown(localEndTime);
             }
         })
         this.loader = false
      } catch (error) {
         this.loader = false
         console.error(error);
      } finally {
         this.loader = false
      }

   }

   toggleBannerCollapse() {
      if (!this.isBannerCollapsed) {
         this.isBannerCollapsed = 'true';
         sessionStorage.setItem('isBannerCollapsed', 'true');
      } else {
         this.isBannerCollapsed = '';
         sessionStorage.setItem('isBannerCollapsed', '');
      }
   }

   // Function to calculate time remaining
   getTimeRemaining(endtime: string): TimeRemaining {
      const total = Date.parse(endtime) - Date.parse(new Date().toString());
      // const total = 0;

      if (total <= 0) {
         this.countdownComplete = true
         return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return { total, days, hours, minutes, seconds };
   }

   // Function to convert UTC date string to local Date object
   convertToLocalTime(apiDateStr: string): Date {
      const isoDateStr = apiDateStr.replace(' ', 'T') + 'Z';  // Assume the API time is UTC
      return new Date(isoDateStr);
   }

   // Start countdown timer that emits values every second
   startCountdown(endtime: string): Observable<TimeRemaining> {
      return timer(0, 1000).pipe(
         map(() => this.getTimeRemaining(endtime))
      );
   }

   getInvestmentLink(): string {
      if (this.countdownComplete) {
         return `https://forms.gle/Dz3BoFGmm7s2deXW7`
      } else {
         return `https://invest.musaffa.com?utm_source=musaffa_web_app`
      }
   }
}
