<div class="onboarding-statistics">
    <div class="row">
       <div class="col-md-12">
          <h2>Most Popular Halal Stocks</h2>
          <div class="states-link" #menu *ngIf="role == 'PREMIUM'">
             <button type="button" class="btn" [routerLink]="['/cabinet/onboarding']" [routerLinkActive]="['active']">United States</button>
             <button type="button" class="btn" [routerLink]="['/cabinet/malaysia']" [routerLinkActive]="['active']">Malaysia</button>
             <button type="button" class="btn" [routerLink]="['/cabinet/indonesia']" [routerLinkActive]="['active']">Indonesia</button>
             <button type="button" class="btn" [routerLink]="['/cabinet/united-kingdom']" [routerLinkActive]="['active']">United Kingdom</button>
             <button type="button" class="btn" [routerLink]="['/cabinet/canada']" [routerLinkActive]="['active']">Canada</button>
             <button type="button" class="btn" [routerLink]="['/cabinet/singapure']" [routerLinkActive]="['active']" >Singapore</button>
          </div>
       </div>
   
    </div>
 </div>
