<div class="input-container p-0">
   <div class="d-flex align-items-center justify-content-between mb-2">
      <label>{{ inputLabel }}</label>
      <small class="text-danger" *ngIf="errorMsg">Stock already exists!</small>
   </div>
   <div class="inner-container">
      <ul
         role="list"
         class="chip-bar d-flex align-items-center gap-1 flex-wrap oveflow-hidden"
      >
         <li
            class="symbol-chip d-flex align-items-center rounded-3"
            *ngFor="let chip of chips"
         >
            <span class="d-flex align-items-center lh-base text-uppercase">{{
               chip.name
            }}</span>
            <button
               class="btn shadow-none d-flex align-items-center p-0"
               (click)="removeChip(chip)"
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 384 512"
                  fill="#717171"
               >
                  <path
                     d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                  />
               </svg>
            </button>
         </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between">
         <input
            [(ngModel)]="symbol"
            type="text"
            class="rounded-0 w-100 border-0 p-2 bg-white"
            autocomplete="off"
            [placeholder]="placeholder"
            [ngbTypeahead]="search"
            (selectItem)="selectSymbol($event)"
            #instance="ngbTypeahead"
            [inputFormatter]="inputFormatBandListValue"
            [resultFormatter]="formatSymbol"
            [resultTemplate]="rt"
            [editable]="false"
            (keydown.enter)="$event.preventDefault()"
         />
         <div class="searchLoader mx-1 mx-lg-2">
            <div
               class="spinner-border spinner-border-sm"
               role="status"
               *ngIf="searching"
            >
               <span class="visually-hidden">Loading...</span>
            </div>
         </div>
         <!-- <button type="button" class="btn text-nowrap d-flex align-items-center justify-content-center gap-1 py-1 rounded-3 shadow-none add-btn">
            <span class="d-inline-block fs-4">+</span> Add
         </button> -->
      </div>
   </div>

   <ng-template #rt let-r="result" let-t="term">
      <div class="d-flex flex-column align-items-start justify-content-center gap-125" >
         <h6 class="mb-0 stockNname">{{ r.companyName }}</h6>
         <span class="stockSymbol">{{ r.stockName }}</span>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2 gap-lg-5" >
         <div class="d-flex flex-column align-items-end gap-125 ">
            <p class="stockPrice mb-0 text-end">{{ r.price ? ( r.price | sumPipe : (r.currency || '' )) : '-' }}</p>
            <p class="stockPercent mb-0 text-end" [ngClass]=" getChangeClasses(r.change) ">
               <i class="bi" [ngClass]="getIconClasses(r.change)" ></i>
               {{ r.change ? ( r.change | number : "1.2-2" ) : '-' }}
            </p>
         </div>
         <div>
            <app-compliance-chip
               [align]="'right'"
               [complianceStatus]="r.complianceStatus"
               [complianceRanking]="r.ranking"
               [complianceRankingV2]="r.ranking_v2"
            >
            </app-compliance-chip>
         </div>
      </div>
   </ng-template>
</div>
