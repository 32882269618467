import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

declare const TradingView: any;
@Component({
  selector: 'app-trading-view',
  templateUrl: './trading-view.component.html',
  styleUrls: ['./trading-view.component.scss']
})
export class TradingViewComponent implements OnInit,OnChanges {

  constructor() { }

  @Input() symbol:any
  // @Input() country:any
  // @Input() exchange:any
  @Input() isMobile:any

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.symbol)  
      this.initChart()
  }

  // getSymbol(){
    
  //   var mainTicker = this.symbol.split(".")?.[0]
  //   if(this.country == 'US')
  //     return mainTicker
  //   else if(this.country == 'IN'){
  //     return `BSE:${mainTicker}`
  //   }  
  // }

  initChart(){
    
    // var chartSymbol = this.getSymbol()
    // console.log(chartSymbol);
    
    new TradingView.MediumWidget(
        {
        "width": "100%",
        "height": this.isMobile ? 250 : 300,        
        "locale": "in",        

        "symbols": [
          [ this.symbol + '|1M' ]
        ],
        "chartOnly": true,        
        "colorTheme": "light",
        "autosize": false,
        "showVolume": false,
        "hideDateRanges": false,
        "hideMarketStatus": true,
        "hideSymbolLogo": true,
        "scalePosition": "right",
        "scaleMode": "Normal",
        "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
        "fontSize": "10",
        "noTimeScale": false,
        "valuesTracking": "1",
        "changeMode": "price-and-percent",
        "chartType": "line",
        "linewidth":"1",
        "color": "#1BAD4E",
        "container_id": "trading-view-chart"
      }
    );
  }


}
