import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-market-indices',
  templateUrl: './market-indices.component.html',
  styleUrls: ['./market-indices.component.scss']
})
export class MarketIndicesComponent implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    this.loadTradingViewWidget();
  }

  loadTradingViewWidget(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
    script.async = true;
    script.text = JSON.stringify({
      // title: "Indices",
      width: "100%",
      height: "100%",
      locale: "en",
      showSymbolLogo: true,
      symbolsGroups: [
        {
          name: "US & Canada",
          symbols: [
            { name: "FOREXCOM:SPXUSD", displayName: "S&P 500" },
            { name: "FOREXCOM:NSXUSD", displayName: "US 100" },
            { name: "CME_MINI:ES1!", displayName: "S&P 500" },
            { name: "INDEX:DXY", displayName: "U.S. Dollar Currency Index" },
            { name: "FOREXCOM:DJI", displayName: "Dow 30" }
          ]
        },
        {
          name: "Europe",
          symbols: [
            { name: "INDEX:SX5E", displayName: "Euro Stoxx 50" },
            { name: "FOREXCOM:UKXGBP", displayName: "UK 100" },
            { name: "INDEX:DEU40", displayName: "DAX Index" },
            { name: "INDEX:CAC40", displayName: "CAC 40 Index" },
            { name: "INDEX:SMI", displayName: "SWISS MARKET INDEX SMI® PRICE" }
          ]
        },
        {
          name: "Asia/Pacific",
          symbols: [
            { name: "INDEX:NKY", displayName: "Nikkei 225" },
            { name: "INDEX:HSI", displayName: "Hang Seng" },
            { name: "BSE:SENSEX", displayName: "Sensex" },
            { name: "BSE:BSE500", displayName: "S&P BSE 500 INDEX" },
            { name: "INDEX:KSIC", displayName: "Kospi Composite" }
          ]
        }
      ],
      colorTheme: "light"
    });

    this.renderer.appendChild(this.el.nativeElement.querySelector('.tradingview-widget-container'), script);
  }

}
