import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   @Input() isOpen: boolean = false;
   @Input() title: string = '';
   @Output() close = new EventEmitter<void>();
   isMobile: boolean = true;

   constructor(private observer: BreakpointObserver) { }

   ngOnInit(): void {
      this.observer.observe('(max-width: 991px)').pipe(takeUntil(this.destroy$)).subscribe((result) => {
         this.isMobile = result.matches;
      });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   closeBottomSheet() {
      this.close.emit();
   }

}
