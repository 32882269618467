import { HttpErrorResponse } from '@angular/common/http';
import {
   Component,
   OnDestroy,
   OnInit,
   ViewChild,
   ElementRef,
   Input,
   Output,
   EventEmitter,
} from '@angular/core'; // Add ElementRef
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Chart from 'chart.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject, takeUntil, timer } from 'rxjs';
import { PortfolioSettingsComponent } from 'src/app/features/portfolio/components/portfolio-settings/portfolio-settings.component';
import { UnlinkConfirmationComponent } from 'src/app/features/portfolio/components/unlink-confirmation/unlink-confirmation.component';
import { MyPortfolioService } from 'src/app/features/portfolio/services/my-portfolio.service';
import { CoverageBrokerage } from 'src/app/features/pricing/models/brokerage-coverage.model';
import {
   PortfolioDetails,
   PortfolioDetailsApiResponse,
   Highlight,
} from 'src/app/models/portfolio/portfolio-details.model';
import {
   PortfolioAccount,
   PortfolioListApiResponse,
   PortfolioPercentage,
} from 'src/app/models/portfolio/portfolio-list.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { PortfolioService } from 'src/app/shared/services/portfolio.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
   selector: 'app-my-portfolio-info',
   templateUrl: './my-portfolio-info.component.html',
   styleUrls: ['./my-portfolio-info.component.scss'],
})
export class MyPortfolioInfoComponent implements OnInit, OnDestroy {
   private destroy$: Subject<void> = new Subject<void>();
   @Input() isFromPortfolio: boolean = false;
   listLoader: boolean = false;
   syncLoader: boolean = false;
   detailsLoader: boolean = false;
   portfolios: PortfolioAccount[] = [];
   selectedPortfolio!: PortfolioAccount | null;
   selectedPortfolioDetails!: PortfolioDetails | null;
   highlights: Highlight[] = [];
   totalReturns: number | null = null;
   activeAnalysis: 'shariah' | 'sector' | 'more' = 'shariah';
   lastSelectedChart: 'shariah' | 'sector' = 'shariah';
   selectedId: string = '';
   @Output() scrollToTabsEvent: EventEmitter<void> = new EventEmitter();

   shariahLabels: string[] = [];
   shariahData: number[] = [];
   shariahColors: string[] = ['#53B653', '#DB494A', '#E9B847', '#448DD1'];

   sectorsLabels: string[] = [];
   sectorsData: number[] = [];
   sectorsColors: string[] = [
      '#3366CC',
      '#DC3912',
      '#FF9900',
      '#109618',
      '#990099',
      '#3B3EAC',
      '#0099C6',
      '#DD4477',
      '#66AA00',
      '#B82E2E',
      '#316395',
      '#994499',
      '#22AA99',
      '#AAAA11',
      '#6633CC',
      '#E67300',
      '#8B0707',
      '#329262',
      '#5574A6',
      '#651067',
   ];

   analysisChart: any;
   hasData!: boolean;

   supportedBrokerages: CoverageBrokerage[] = [];
   isTransactionAvailable: boolean | undefined = false;

   syncTimeAndDate: any = null;
   reconnectLoader: boolean = false;

   @ViewChild('analysisChartCanvas', { static: false })
   analysisChartCanvas!: ElementRef; // Add ViewChild

   portfolioSettingsRef: any;
   accountUnlinkRef: any;

   customOptions: OwlOptions = {
      loop: false,
      pullDrag: false,
      dots: false,
      autoplay: false,
      autoWidth: true,
      autoplayHoverPause: true,
      navSpeed: 500,
      navText: ['', ''],
      responsive: {
         0: {
            items: 1.2,
            margin: 16,
         },
         576: {
            items: 2,
            margin: 20,
         },
      },
      nav: false,
   };

   constructor(
      public portfolioService: PortfolioService,
      private router: Router,
      private route: ActivatedRoute,
      private analyticsUtils: AnalyticsUtils,
      private toaster: ToastrService,
      private modalService: NgbModal,
      private sharedService: SharedService,
      private myPortfolioService: MyPortfolioService
   ) { }

   async ngOnInit() {
      // this.route.queryParams
      //    .pipe(takeUntil(this.destroy$))
      //    .subscribe((params) => {
      //       this.selectedId = params['id'];
      //    });
      this.selectedId = history.state.id;
      await this.getPortfolioList();
      await this.getSupportedBrokerages();
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
      if (this.analysisChart) {
         this.analysisChart.destroy();
      }
   }

   openPortfolioSetting() {
      this.portfolioSettingsRef = this.modalService.open(PortfolioSettingsComponent, {
         centered: true,
         windowClass: 'portfolio-setting-popup',
         scrollable: true,
      }
      );
      this.portfolioSettingsRef.componentInstance.selectedAccount = this.selectedPortfolio;
      this.portfolioSettingsRef.componentInstance.selectedPortfolioDetails = this.selectedPortfolioDetails;
      this.portfolioSettingsRef.componentInstance.isTransactionAvailable = this.isTransactionAvailable;
      this.portfolioSettingsRef.componentInstance.syncPortfolioLoader = this.syncLoader;
      this.portfolioSettingsRef.componentInstance.refreshData = () => {
         this.refreshGetPortfolioDetails();
      };

      this.portfolioSettingsRef.componentInstance.closeSettingPopup = () => {
         this.closePortfolioSettingPopup();
      };

      this.portfolioSettingsRef.componentInstance.openUnlinkConfirmation =
         () => {
            this.openUnlinkConfirmation();
         };
   }

   closePortfolioSettingPopup() {
      if (this.portfolioSettingsRef) {
         this.portfolioSettingsRef.dismiss();
      }
   }

   openUnlinkConfirmation() {
      this.accountUnlinkRef = this.modalService.open(
         UnlinkConfirmationComponent,
         {
            centered: true,
            windowClass: 'portfolio-setting-popup',
            scrollable: true,
         }
      );
      this.accountUnlinkRef.componentInstance.selectedAccount =
         this.selectedPortfolio;

      this.accountUnlinkRef.componentInstance.closeUnlinkConfirmationModal =
         () => {
            this.closeUnlinkConfirmation();
         };

      this.accountUnlinkRef.componentInstance.unlinkSuccess = () => {
         this.unlinkSuccess();
      };
   }

   closeUnlinkConfirmation() {
      if (this.accountUnlinkRef) {
         this.accountUnlinkRef.dismiss();
      }
   }

   trackById(acc: PortfolioAccount) {
      return acc.id;
   }

   createChart(
      analysisType: 'shariah' | 'sector',
      labels: string[],
      data: number[],
      colorData: string[]
   ) {
      if (!this.analysisChartCanvas || !this.analysisChartCanvas.nativeElement)
         return; // Ensure element exists
      if (this.analysisChart) this.analysisChart.destroy();
      this.analysisChart = new Chart(this.analysisChartCanvas.nativeElement, {
         type: 'pie',
         data: {
            labels: labels,
            datasets: [
               {
                  label: 'My First Dataset',
                  data: data,
                  backgroundColor: colorData,
                  borderWidth: 0,
               },
            ],
         },
         options: {
            aspectRatio: 1,
            cutoutPercentage: 55,
            legend: {
               display: false,
            },
         },
      });
   }

   reconnectBrokenBrokerage() {
      this.reconnectLoader = true;
      this.portfolioService
         .getReconnectUrl(this.selectedPortfolio?.brokerage_id, true)
         .subscribe((resp: any) => {
            if (resp.status) {
               this.reconnectLoader = false;
               this.portfolioService.openConnectBrokerageIframeModal(
                  resp.data.redirect_url
               );
            }
            this.reconnectLoader = false;
         }),
         (error: HttpErrorResponse) => {
            console.log(error);
            this.reconnectLoader = false;
         };
   }

   private async getSupportedBrokerages() {
      this.sharedService
         .getBrokerageCoverages()
         ?.pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.supportedBrokerages = response.data;
            },
            error: (error) => {
               console.error(error);
            },
         });
   }

   checkIsTransactionAvailable() {
      let currBrokerage = this.supportedBrokerages.find(
         (item: CoverageBrokerage) =>
            item.provider_record_id ===
            this.selectedPortfolio?.provider_brokerage_id
      );

      this.isTransactionAvailable =
         currBrokerage?.supported_features?.automated_purification;
   }

   private async getPortfolioList() {
      this.listLoader = true;
      this.portfolioService
         .getPortfoliosList()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.portfolios = response;
               if (this.isFromPortfolio && this.selectedId) {
                  let selectedAccount = this.portfolios.find(
                     (item: PortfolioAccount) => item.id === this.selectedId
                  );
                  this.selectedPortfolio = selectedAccount || this.portfolios[0];
               } else {
                  this.selectedPortfolio = this.portfolios[0] || null;
               }
               this.getSelectedPortfolioDetails(this.selectedPortfolio);
               this.listLoader = false;
            },
            error: (error) => {
               console.error(error);
               this.listLoader = false;
            },
         });
   }

   getSelectedPortfolioDetails(portfolio: PortfolioAccount) {
      this.detailsLoader = true;
      this.portfolioService
         .fetchSelectedPortfolioDetails<PortfolioDetailsApiResponse>(
            portfolio.id
         )
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.selectedPortfolioDetails = response.data;
               this.portfolioService.setSelectedAccountId(
                  this.selectedPortfolioDetails.id
               );
               this.totalReturns =
                  this.selectedPortfolioDetails.total_current -
                  this.selectedPortfolioDetails.total_invested;
               this.highlights = this.selectedPortfolioDetails.highlights;

               this.shariahLabels = this.convertShariahStatus(
                  Object.keys(
                     this.selectedPortfolioDetails.distribution.shariah
                  )
               );
               this.shariahData = this.getPercentageValue(
                  this.selectedPortfolioDetails.total_current,
                  Object.values(
                     this.selectedPortfolioDetails.distribution.shariah
                  )
               );

               this.sectorsLabels = Object.keys(
                  this.selectedPortfolioDetails.distribution.sector
               );
               this.sectorsData = this.getPercentageValue(
                  this.selectedPortfolioDetails.total_current,
                  Object.values(
                     this.selectedPortfolioDetails.distribution.sector
                  )
               );

               this.hasData =
                  this.checkDataValidity(this.shariahData) ||
                  this.checkDataValidity(this.sectorsData);

               timer(50)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(() => {
                     if (this.activeAnalysis === 'sector') {
                        this.getSelectedAnalysisChart(
                           'sector',
                           this.sectorsLabels,
                           this.sectorsData,
                           this.sectorsColors
                        );
                     } else {
                        this.getSelectedAnalysisChart(
                           'shariah',
                           this.shariahLabels,
                           this.shariahData,
                           this.shariahColors
                        );
                     }
                  });

               this.checkIsTransactionAvailable();
               this.detailsLoader = false;
            },
            error: (error) => {
               console.error(error);
               this.detailsLoader = false;
            },
         });
   }

   refreshGetPortfolioDetails() {
      this.getSelectedPortfolioDetails(this.selectedPortfolio!);
   }

   unlinkSuccess() {
      this.getPortfolioList();
   }

   getSelectedAnalysisChart(
      analysis: 'shariah' | 'sector',
      labels?: string[],
      data?: number[],
      colors?: string[]
   ) {
      this.activeAnalysis = analysis;
      const chartLabels = labels ? labels : [];
      const chartData = data ? data : [];
      const chartColors = colors ? colors : [];
      this.createChart(
         this.activeAnalysis,
         chartLabels,
         chartData,
         chartColors
      );
   }

   convertShariahStatus(status: string[]): string[] {
      return status.map((s) => {
         switch (s) {
            case 'COMPLIANT':
               return 'Halal';
            case 'NON_COMPLIANT':
               return 'Not Halal';
            case 'QUESTIONABLE':
               return 'Doubtful';
            case 'NOT_UNDER_COVERAGE':
               return 'Not Covered';
            default:
               return '';
         }
      });
   }

   getIconClasses(data: any) {
      return {
         'bi-caret-up-fill positive': data >= 0,
         'bi-caret-down-fill negative': data < 0,
      };
   }

   transformValue(value: any) {
      return Math.abs(value);
   }

   getPercentageValue(totalValue: number, values: number[]): number[] {
      return values.map((value) =>
         parseFloat(((value / totalValue) * 100).toFixed(2))
      );
   }

   calculatePercentage(
      value: number | null,
      total: number | undefined
   ): number {
      if (value === null || total === undefined || total === 0) {
         return 0;
      }
      const percentage = (value / total) * 100;
      return parseFloat(Math.abs(percentage).toFixed(2));
   }

   async syncPortfolio() {
      this.analyticsUtils.portfolio_sync_tapped();
      this.syncLoader = true;
      var resp: any = '';
      try {
         if (!this.selectedPortfolioDetails?.is_manual) {
            resp = await lastValueFrom(
               this.portfolioService.syncPortfolio(
                  this.selectedPortfolioDetails?.id
               )
            );
         } else {
            resp = await lastValueFrom(
               this.portfolioService.syncManualPortfolio(
                  this.selectedPortfolioDetails?.id
               )
            );
         }

         if (resp.status && resp.status_code === 'SUCCESS') {
            this.toaster.success(resp.message);
            this.portfolioService.updateDate(resp.data.last_sync_at);
            this.syncTimeAndDate = resp.data.last_sync_at;
            this.syncLoader = false;
            this.getSelectedPortfolioDetails(this.selectedPortfolio!);
            // if (this.parentComponent === 'portfolio-purification') {
            //    this.portfolioService.triggerDataReload();
            // } else {
            //    this.buttonClicked.emit();
            // }
         } else {
            this.syncTimeAndDate = null;
         }
      } catch (error) {
         this.syncLoader = false;
         this.toaster.error('Sync failed');
         this.syncTimeAndDate = null;
      }
   }

   navigateToPortfolio() {
      this.router.navigate(['/portfolio-dashboard'], {
         state: { id: this.selectedPortfolio?.id },
      });
   }

   checkDataValidity(data: number[]): boolean {
      return data.length > 0 && data.some((item) => !isNaN(item));
   }

   openSupportedBrokerages() {
      this.portfolioService.openConnectPortfolioPopup()
      // this.portfolioService.openSupportedBrokeragesModal(true);
   }

   gotoAnalyticsTab() {
      this.myPortfolioService.setTab('analysis');
      this.scrollToTabsEvent.emit();
   }

   openBrokeragesPopup() {
      this.portfolioService.openSupportedBrokeragesModal(true);
      // this.portfolioService.closeConnectPortfolioPopup()

      // if(this.currentBrokeragePortfoliosCount >= this.maxBrokeragePortfoliosCount) {
      //    this.sharedService.openUpgradePopup('portfolio');
      // } else {
      //    this.portfolioService.openSupportedBrokeragesModal(true);
      // }
   }
}
