<!-- <ng-container *ngIf="(checkRole('ANONYMOUS') || checkRole('FREEMIUM')) && show">
   <section class="anonymus">
      <div class="container">
         <div class="anonymus__link-information">
            <div class="anonymus__main" (click)="onBannerClick()" >
               <img src="/assets/images/clarity_sign-in-solid.svg" alt="">
               <div class="anonymus__title">
                  <p>{{ bannerTitle }}</p>
                  <span>{{ bannerSubtitle }}</span>
               </div>
            </div>
            <div class="anonymus__cancsel" (click)="show = false">
               <i class="bi bi-x-lg"></i>
            </div>
         </div>
      </div>
   </section>

</ng-container> -->

<nav class="mobile-nav">
   <ul class="mobile-nav--list">
      <li class="mobile-nav--item">
         <a class="mobile-nav--link" routerLink="/cabinet/dashboard" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9"/>
               <path d="M9 22V12h6v10M2 10.6L12 2l10 8.6"/>
            </svg>
            <span class="lh-1">Home</span>
         </a>
      </li>
      <li class="mobile-nav--item">
         <a class="mobile-nav--link" routerLink="/cabinet/onboarding" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <circle cx="11" cy="11" r="8"></circle>
               <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <span class="lh-1">Screener</span>
         </a>
      </li>
      <!-- <li class="mobile-nav--item">
         <a class="mobile-nav--link" routerLink="/cabinet/recently" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <circle cx="12" cy="12" r="10"></circle>
               <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            <span class="lh-1">Recent</span>
         </a>
      </li> -->
      <li class="mobile-nav--item">
         <a class="mobile-nav--link portfolio-link" routerLink="/portfolio-dashboard" routerLinkActive="active">
            <svg class="portfolio-svg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
               <path d="M520-520h278q-15-110-91.5-186.5T520-798v278Zm-80 358v-636q-121 15-200.5 105.5T160-480q0 122 79.5 212.5T440-162Zm80 0q110-14 187-91t91-187H520v278Zm-40-318Zm0 400q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z"/>
            </svg>
            <span class="lh-1">Portfolio</span>
         </a>
      </li>
      <li class="mobile-nav--item" *ngIf="!checkRole('ANONYMOUS')">
         <a class="mobile-nav--link" routerLink="/watchlist-lists" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
            <span class="lh-1">Watchlist</span>
         </a>
      </li>
      <li class="mobile-nav--item"*ngIf="checkRole('ANONYMOUS')">
         <a class="mobile-nav--link" routerLink="/authentication/sso-login" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
            <span class="lh-1">Watchlist</span>
         </a>
      </li>
      <li class="mobile-nav--item" *ngIf="!checkRole('ANONYMOUS')">
         <a class="mobile-nav--link" routerLink="/users-profile-mpbile" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
               <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
            </svg>
            <span class="lh-1">Profile</span>
         </a>
      </li>
      <li class="mobile-nav--item" *ngIf="checkRole('ANONYMOUS')">
         <a class="mobile-nav--link" routerLink="/authentication/login" routerLinkActive="active">
            <svg class="mobile-nav--svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
               <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
            </svg>
            <span class="lh-1">Profile</span>
         </a>
      </li>
   </ul>
</nav>

<!-- <section class="footer-main">
   <div class="container">
      <div class=""></div>
      <div class="navigation-bar">
         <div class="navigation" routerLink="/cabinet/dashboard" routerLinkActive="active">
            <img src="../../../../assets/images/markets.svg" alt="">
            <p>Home</p>
         </div>

         <div class="navigation" routerLink="/cabinet/onboarding" routerLinkActive="active">
            <img src="../../../../assets/images/search-new.svg" alt="">
            <p>Screener</p>
         </div>

         <div class="navigation" routerLink="/cabinet/recently" routerLinkActive="active">
            <img src="../../../../assets/images/resently.svg" alt="">
            <p>Recent</p>
         </div>
         <div class="navigation" routerLink="/cabinet/watchlist-lists" routerLinkActive="active"
            *ngIf="!checkRole('ANONYMOUS')">
            <img src="../../../../assets/images/wotchlist-new.svg" alt="">
            <p>Watchlist</p>
         </div>
         <div class="navigation" routerLink="/cabinet/anonymus-watchlist" routerLinkActive="active"
            *ngIf="checkRole('ANONYMOUS')">
            <img src="../../../../assets/images/wotchlist-new.svg" alt="">
            <p>Watchlist</p>
         </div>

         <div class="navigation" routerLink="/cabinet/users-profile-mpbile" routerLinkActive="active"  *ngIf="!checkRole('ANONYMOUS')">
            <img src="../../../../assets/images/users-mobile-profile.svg" alt="">
            <p>Profile</p>
         </div>

         <div class="navigation" routerLink="/authentication/login" routerLinkActive="active"  *ngIf="checkRole('ANONYMOUS')">
            <img src="../../../../assets/images/users-mobile-profile.svg" alt="">
            <p>Profile</p>
         </div>


      </div>
   </div>
</section> -->
