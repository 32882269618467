import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';
import { StocksSuperInvestor, SuperInvestor } from 'src/app/models/superInvestor.model';

@Component({
  selector: 'app-super-investor-tabs',
  templateUrl: './super-investor-tabs.component.html',
  styleUrls: ['./super-investor-tabs.component.scss']
})
export class SuperInvestorTabsComponent implements OnInit, AfterViewInit {
  activeInvestorId!: string;
  @ViewChild('tabsContainer', { static: true }) tabsContainer!: ElementRef;

  @Input() tabs: SuperInvestor[] = [];
  @Input() paramTabs: StocksSuperInvestor[] = [];
  @Input() paramSymbol!: string;

  constructor(private route: ActivatedRoute, private renderer: Renderer2, private router: Router) { }

  ngOnInit(): void {
    this.route.url.subscribe((url) => {
      this.activeInvestorId = url[1].path;
    });
  }

  ngAfterViewInit(): void {
    timer(300).subscribe(() => {
      this.scrollToActiveTab();
    });
  }

  navigateTo(id: string) {
    this.router.navigate(['/super-investor-portfolio', id]).then(() => {
      this.activeInvestorId = id;
      timer(100).subscribe(() => {
        this.scrollToActiveTab();
      });
    });
  }

  navigateToSuperInvestorWithParam(id: string, symbol: string) {
    this.router.navigate(['/super-investor-portfolio', id], { queryParams: { stock: symbol } }).then(() => {
      this.activeInvestorId = id;
      timer(100).subscribe(() => {
        this.scrollToActiveTab();
      });
    });
  }

  onScroll(event: WheelEvent) {
    event.preventDefault();
    const element = this.tabsContainer.nativeElement;
    element.scrollLeft += event.deltaY * 2;
  }

  scrollToActiveTab() {
    const activeButton = document.getElementById(
      'tab-button-identifier-' + this.activeInvestorId
    );
    if (activeButton) {
      activeButton.scrollIntoView({
        behavior: 'auto', 
        block: 'nearest',  
        inline: 'center' 
      });
    }
  }

}

























// import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';

// @Component({
//   selector: 'app-super-investor-tabs',
//   templateUrl: './super-investor-tabs.component.html',
//   styleUrls: ['./super-investor-tabs.component.scss']
// })
// export class SuperInvestorTabsComponent implements OnInit, AfterViewInit, AfterViewChecked {
//   @ViewChild('tabsWrapper') tabsWrapper!: ElementRef;
//   scrollingHorizontally = true;
//   activeInvestorId!: string;
//   private hasScrolledToLink: boolean = false;

//   @Input() tabs: any[] = [];

//   constructor(private route: ActivatedRoute, private renderer: Renderer2) { }

//   ngOnInit(): void {
//     this.route.url.subscribe(url => {
//       this.activeInvestorId = url[1].path
//     })
//   }

//   ngAfterViewInit() {
//     // const currButton = document.getElementById(`tab-button-identifier-${this.activeInvestorId}`);
//     //   if (currButton) {
//     //     this.scrollToLink(this.activeInvestorId);
//     //     this.hasScrolledToLink = true; 
//     //   }
//   }

//   ngAfterViewChecked(): void {
//     if (this.activeInvestorId && !this.hasScrolledToLink) {
//       const currButton = document.getElementById(`tab-button-identifier-${this.activeInvestorId}`);
//       if (currButton) {
//         this.scrollToLink(this.activeInvestorId);
//         this.hasScrolledToLink = true; 
//       }
//     }
//   }

//   @HostListener('wheel', ['$event'])
//   onWheel(event: WheelEvent): void {
//     if (this.scrollingHorizontally) {
//       const scrollDirection = event.deltaY < 0 ? -1 : 1;
//       const scrollAmount = 70;

//       // Scroll horizontally
//       this.tabsWrapper.nativeElement.scrollLeft += scrollAmount * scrollDirection;
//       event.preventDefault();
     
//     } else {
//       // If scrolling vertically, prevent default behavior only when the mouse is inside the component
//       if (this.isMouseInsideComponent(event)) {
//         event.preventDefault();
//       }
//     }
//   }

//   @HostListener('mouseenter')
//   onMouseEnter(): void {
//     this.scrollingHorizontally = true;
//   }

//   @HostListener('mouseleave')
//   onMouseLeave(): void {
//     this.scrollingHorizontally = false;
//   }

//   private isMouseInsideComponent(event: MouseEvent): boolean {
//     const rect = this.tabsWrapper.nativeElement.getBoundingClientRect();
//     return event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom;
//   }

//   scrollToLink(tabId: string): void {
//     const currButton = document.getElementById(`tab-button-identifier-${tabId}`);
    
//     if (currButton) {
//       currButton.scrollIntoView({
//         behavior: 'auto',
//         block: 'nearest',
//         inline: 'center',
//       });
//     }
//   }

//   isActive(tabId: number): boolean {
//     const currentUrl = this.route.snapshot.url.join('/');
//     return currentUrl === `/cabinet/super-investor-portfolio/${tabId}`;
//   }

// }
