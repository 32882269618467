import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-my-brokerage-accounts',
  templateUrl: './my-brokerage-accounts.component.html',
  styleUrls: ['./my-brokerage-accounts.component.scss']
})
export class MyBrokerageAccountsComponent implements OnInit {
  @Input() accounts: any[] = [];
  @Output() selectedAccountChange: EventEmitter<any> = new EventEmitter();

  selectedOption: any = '';

  constructor() { }

  ngOnInit(): void {
    
  }

  onOptionChange(account: any) {
     this.selectedOption = account;
     this.selectedAccountChange.emit(account);
  }
}
