import { createReducer, on } from '@ngrx/store';
import * as SidebarActions from './sidebar.actions';

export interface SidebarState {
  isExpanded: boolean;
}

export const initialState: SidebarState = {
  isExpanded: true
};

export const sidebarReducer = createReducer(
  initialState,
  on(SidebarActions.toggleSidebar, (state) => ({
    ...state,
    isExpanded: !state.isExpanded
  })),
  on(SidebarActions.expandSidebar, (state) => ({
    ...state,
    isExpanded: true
  })),
  on(SidebarActions.collapseSidebar, (state) => ({
    ...state,
    isExpanded: false
  }))
);
