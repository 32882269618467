<div class="br-10 stock-table-container">
   <header class="d-flex align-items-center justify-content-between">
      <h3 class="table-title mb-0">{{ title }}</h3>
      <a class="text-decoration-none d-flex align-items-center gap-2 view-all-btn" [routerLink]="[redirectUrl]">
         <span>View All</span>
         <i class="bi bi-arrow-right arrow-icon"></i>
      </a>
   </header>

   <div class="px-2" *ngIf="loading">
      <ngx-skeleton-loader
         count="8" appearance="line" animation="progress"
         [theme]="{ height: '50px', width: '100%', 'border-radius': '8px'}">
      </ngx-skeleton-loader>
   </div>

   <div class="table-responsive" *ngIf="!loading">
      <table class="table mb-0">
         <thead>
            <tr>
               <th class="text-uppercase fw-normal" scope="col"></th>
               <th class="text-uppercase fw-normal" scope="col">Name</th>
               <th class="text-uppercase fw-normal text-end" scope="col">Price & 1D CHG</th>
               <th class="text-uppercase fw-normal text-center" scope="col">Compliance</th>
            </tr>
         </thead>
         <tbody>
            <ng-container *ngIf="data && data.length > 0">
               <tr *ngFor="let stock of filtereredStocks(); let i = index; trackBy: trackByStockId">
                  <td class="align-middle p-0">
                     <app-watchlist-button [stock]="stock"></app-watchlist-button>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center gap-2 text-decoration-none stock-image-and-name" routerLink="/stock/{{stock?.ticker}}">
                        <div class="stock-image-wrapper border flex-shrink-0 bg-light rounded-circle overflow-hidden">
                           <img [appLazyLoadImage]="stock?.logo" placeholder="/assets/images/dashboard/stock-lowres.png" [alt]="stock?.name" class="img-fluid" *ngIf="stock?.logo">
                           <div *ngIf="!stock?.logo" class="d-flex h-100 text-black align-items-center justify-content-center fs-5 fw-bold">
                              {{ stock?.name?.charAt(0)}}
                           </div>
                        </div>
                        <div class="d-block">
                           <h5 class="mb-0 line-clamp-1 stock-name">{{ stock?.name }}</h5>
                           <p class="mb-0 line-clamp-1 stock-symbol">{{ stock?.ticker }}</p>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-price-and-change" routerLink="/stock/{{stock?.ticker}}">
                        <div class="d-grid">
                           <ng-container *ngIf="tableId === 'most_active' || tableId === 'most_popular' || tableId === 'top_losers'">
                              <span class="stock-price text-end" *ngIf="stock.currentPrice; else noPrice">
                                 {{ stock.currency ? ( stock.currentPrice | sumPipe: stock.currency ) : stock.currentPrice }}
                              </span>
                              <ng-template #noPrice>-</ng-template>
                           </ng-container>

                           <ng-container *ngIf="tableId === 'top_gainers' || tableId === '52_week_high' || tableId === '52_week_low'">
                              <span class="stock-price text-end" *ngIf="stock.currentPrice; else noCurrentPrice">
                                 {{ stock.currency ? ( stock.currentPrice | sumPipe: stock.currency ) : stock.currentPrice }}
                              </span>
                              <ng-template #noCurrentPrice>-</ng-template>
                           </ng-container>

                           <div class="stock-change d-flex align-items-center justify-content-end gap-1" *ngIf="stock?.percentChange; else noPercentChange" [ngClass]="getPercentChangeClasses(stock)">
                              <i class="bi" [ngClass]="getIconClasses(stock)"></i>
                              <span> {{ stock?.percentChange | abs | number : "1.2-2" }}% </span>
                           </div>
                           <ng-template #noPercentChange>-</ng-template>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-compliance" routerLink="/stock/{{stock?.ticker}}">
                        <app-compliance-chip
                           [showCompliance]="i < 3"
                           [symbol]="stock?.symbol ?? stock?.ticker ?? stock?.id"
                           [complianceStatus]="stock?.sharia_compliance"
                           [complianceRanking]="stock?.ranking"
                           [complianceRankingV2]="stock?.ranking_v2"
                           >
                        </app-compliance-chip>
                     </a>
                  </td>
               </tr>
               <tr class="lock-overlay"  *ngIf="!isLoggedIn && data.length > 3">
                  <td colspan="4"class="align-middle">
                     <!-- [routerLink]="['/authentication/sso-login']" -->
                     <button (click)="accessControlService.accessDenied();" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid">
                        <span>Sign-up & Upgrade to Unlock!</span>
                     </button>
                  </td>
               </tr>
               <tr class="lock-overlay" *ngIf="isLoggedIn && !canReadTrendingStocks && data.length > 3">
                  <td colspan="4"class="align-middle">
                     <!-- [routerLink]="['/authentication/sso-login']" -->
                     <button (click)="accessControlService.accessDenied();" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid">
                        <span>Upgrade & Unlock!</span>
                     </button>
                  </td>
               </tr>
            </ng-container>
            <ng-container *ngIf="!loading && (!data || data.length === 0)">
               <tr class="nostocks-tr">
                  <td colspan="4" class="align-middle">
                     <p class="mb-0 d-flex justify-content-center align-items-center">No stocks available for the selected country</p>
                  </td>
               </tr>
            </ng-container>
         </tbody>
      </table>
    </div>
</div>
