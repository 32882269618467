import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PublicList, PublicListResponse, PublicListHolding } from 'src/app/models/public-list/public-lists.model';
import { PublicListsService } from 'src/app/shared/services/public-lists.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

@Component({
   selector: 'app-top-curated-lists',
   templateUrl: './top-curated-lists.component.html',
   styleUrls: ['./top-curated-lists.component.scss'],
})

export class TopCuratedListsComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   @Input() showMessage: boolean = false;
   @Input() sectionHeading: string = '';
   @Input() showSymbolsList: boolean = false
   @Input() symbol!: string
   listLoader!: boolean;
   holdingsLoader!: boolean;
   topLists!: PublicList[];
   logos: { [key: string]: string } = {};
   currentStockSymbol!: string

   constructor(
      private listService: PublicListsService,
      private typesenseService: TypesenseService,
      private route: ActivatedRoute
   ) {}

   ngOnInit() {
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
         this.currentStockSymbol = params['id']
         this.onRouteChange()
      });
   }

   makeSlug(list: PublicList): string {
      return list.name.replace(/\s+/g, '-').toLowerCase() + '-' + list.id
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async onRouteChange(): Promise<void> {
      await this.loadTop10Lists();
   }

   trackByFn(index: number, item: PublicList): string | number {
      return item.id;
   }

   hideMessage() {
      if (this.showMessage) this.showMessage = false;
   }

   async loadTop10Lists() {
      try {
         this.listLoader = true;
         let response;

         let symbols: string[] | undefined;

         if (this.showSymbolsList && this.currentStockSymbol) {
           symbols = [this.currentStockSymbol];
            response = await this.listService.getTop10Lists<PublicListResponse>(1, 5, 'followers_count', 'desc', symbols);
         } else {
            response = await this.listService.getTop10Lists<PublicListResponse>(1, 10, 'followers_count', 'desc');
         }


         if (response.status) {
            const responseLists = response.data.data;
            this.topLists = responseLists.filter(list => list.id.toString() !== this.currentStockSymbol);
            this.listLoader = false;

            for (const list of this.topLists) {
               const symbols = list.public_list_holdings.map((holding: PublicListHolding) => holding.symbol);
               await this.loadListStocksInfo(symbols, list);
            }
         }
      } catch (error) {
          console.error(error);
      } finally {
          this.listLoader = false;
      }
   }

   async loadListStocksInfo(symbols: string[], list: PublicList) {
      try {
         const Listholdings =
            await this.typesenseService.getStocksComplianceInfo(symbols); list.public_list_holdings.forEach((holding) => {
            const matchingHolding = Listholdings.find( (stock: PublicListHolding) => stock.symbol === holding.symbol );
            if (matchingHolding) {
               holding.logo_url = matchingHolding.logo;
               holding.holding_name = matchingHolding.name;
            }
         });
      } catch (error) {
         console.error(error);
      }
   }

   async loadListEtfsInfo(symbols: string[], list: PublicList) {
      try {
         const Listholdings = await this.typesenseService.getEtfsComplianceInfo(
            symbols
         );
         list.public_list_holdings.forEach((holding) => {
            const matchingHolding = Listholdings.find(
               (stock: PublicListHolding) => stock.symbol === holding.symbol
            );
            if (matchingHolding) {
               holding.logo_url = matchingHolding.logo;
               holding.holding_name = matchingHolding.name;
            }
         });
      } catch (error) {
         console.error(error);
      }
   }
}
