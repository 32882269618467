<ng-container *ngIf="loading">
   <div class="skeleton-wrapper bg-white">
      <div
         class="border-bottom px-3 pt-3 pb-2"
         *ngFor="let n of [1, 2, 3, 4, 5]"
      >
         <ngx-skeleton-loader
            count="1"
            appearance="line"
            animation="progress"
            [theme]="{ height: '60px', width: '100%', margin: '0px' }"
         ></ngx-skeleton-loader>
      </div>
      <div class="row p-3">
         <div class="col-6 pt-1">
            <ngx-skeleton-loader
               count="1"
               appearance="line"
               animation="progress"
               [theme]="{
                  height: '60px',
                  width: '100%',
                  margin: '0px',
                  'border-radius': '100px'
               }"
            ></ngx-skeleton-loader>
         </div>
         <div class="col-6 pt-1">
            <ngx-skeleton-loader
               count="1"
               appearance="line"
               animation="progress"
               [theme]="{
                  height: '60px',
                  width: '100%',
                  margin: '0px',
                  'border-radius': '100px'
               }"
            ></ngx-skeleton-loader>
         </div>
      </div>
   </div>
</ng-container>

<ng-container *ngIf="!loading">
   <!-- for stock screener start -->
   <div class="onboarding__filter__container" *ngIf="type === 'stock'">
      <div
         class="d-flex align-items-center justify-content-between border-bottom px-3 py-3 d-none d-lg-flex"
      >
         <h3
            class="mb-0 w-100 filter__heading d-flex align-items-center justify-content-between"
         >
            <div>
               <img
                  src="/assets/images/filter-icon.svg"
                  alt="Filter Icon"
                  class="filter-icon img-fluid me-2"
                  width="16"
               />
               <span>Filter By</span>
            </div>
            <div class="ms-auto">
               <p class="filter-stocks-count mb-0" *ngIf="stocksTotalDataCount">
                  {{ stocksTotalDataCount }} matching stocks
               </p>
               <p
                  class="filter-stocks-count mb-0"
                  *ngIf="!stocksTotalDataCount"
               >
                  {{ stocksTotalDataCount }} matching stocks
               </p>
            </div>
         </h3>
      </div>

      <div class="onboarding__filter__body">
         <div class="card mb-0">
            <div class="card-body mb-0" style="padding: 1rem">
               <div
                  class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
               >
                  <label
                     class="form-check-label w-100 checkbox-label"
                     for="showPrimary"
                  >
                     <img
                        src="/assets/images/show-primary.svg"
                        alt="flag icon"
                        class="accordion-icon img-fluid me-2"
                        width="24"
                     />
                     Show Only Primary Listing
                  </label>
                  <input
                     [(ngModel)]="showPrimary"
                     class="form-check-input filter-checkbox p-0 my-0 ms-0 me-0 shadow-none flex-shrink-0"
                     id="showPrimary"
                     name="showPrimary"
                     type="checkbox"
                     value=""
                     id="showPrimaryListing"
                  />
               </div>
            </div>
         </div>
         <ngb-accordion
            #acc="ngbAccordion"
            [closeOthers]="true"
            (panelChange)="beforeChange($event)"
         >
            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/flag-icon.svg"
                           alt="flag icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Country</span>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex flex-column align-items-start countries-radio checkbox-container"
                     *ngFor="let obj of countryList; index as i"
                  >
                     <span class="d-block mb-3 text-span" *ngIf="i === 0"
                        >Covered Markets</span
                     >
                     <span
                        class="d-block mb-3 text-span"
                        *ngIf="firstNotCoveredMarket === obj.key"
                        >All Markets</span
                     >
                     <div class="d-flex align-items-center">
                        <input
                           [(ngModel)]="selectedCountry"
                           (ngModelChange)="onCountryUpdate()"
                           class="form-check-input text-above filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                           name="countryList"
                           type="radio"
                           [value]="obj"
                           [id]="obj.display_value"
                        />
                        <label
                           class="form-check-label w-100 checkbox-label"
                           [for]="obj.display_value"
                        >
                           {{ obj.display_value }}
                        </label>
                     </div>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel [disabled]="!exchangeList?.length">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/stock-exchange-icon.svg"
                           alt="exchange icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Exchange</span>
                        <span
                           class="selected-count"
                           *ngIf="getListCount(exchangeList) > 0"
                           >[ +{{ getListCount(exchangeList) }} ]</span
                        >
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of exchangeList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="exchange"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                        (ngModelChange)="exChanged()"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/pie-chart-icon.svg"
                           alt="pie chart icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Sector</span>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of sectorList"
                  >
                     <input
                        [(ngModel)]="selectedSector"
                        (ngModelChange)="updateIndustryList()"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="sector"
                        type="radio"
                        [value]="obj"
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel [disabled]="!isIndustryEnabled()">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/industry-icon.svg"
                           alt="industry icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Industry</span>
                        <span
                           class="selected-count"
                           *ngIf="getListCount(industryList) > 0"
                           >[ +{{ getListCount(industryList) }} ]</span
                        >
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of industryList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="exchange"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                        (ngModelChange)="industryChanged()"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/analyst-ratings-icon.svg"
                           alt="pie chart icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Analyst's Rating</span>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of analystRatingsList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="analyst"
                        type="checkbox"
                        [value]=""
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel [disabled]="userRole !== 'PREMIUM'">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/shariah-compliance-icon.svg"
                           alt="shariah-compliance icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <div class="d-flex align-items-center">
                           <span class="btn-text">Compliance</span>
                           <span
                              class="for-premium ms-2"
                              *ngIf="userRole !== 'PREMIUM'"
                              >(For Premium members)</span
                           >
                        </div>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of complianceList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        (ngModelChange)="onComplianceChange(obj)"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="compliance"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel *ngIf="userRole !== 'PREMIUM'" id="not-premium">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center disabled justify-content-between"
                     placement="top"
                     ngbTooltip="Musaffa Premium Feature"
                     (click)="openPayWallModal()"
                  >
                     <div class="d-flex align-items-center">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="accordion-icon img-fluid me-2"
                           width="1.35rem"
                           height="1.35rem"
                           fill="#6bca8c"
                           viewBox="0 0 576 512"
                        >
                           <path
                              d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                           />
                        </svg>
                        <div class="d-flex align-items-center">
                           <span class="btn-text">Musaffa Halal Score</span>
                           <svg
                              viewBox="0 0 24 24"
                              class="ms-2"
                              fill="none"
                              width="1.5rem"
                              height="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                 id="SVGRepo_tracerCarrier"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                 <path
                                    d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 ></path>
                              </g>
                           </svg>
                        </div>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel
               *ngIf="userRole === 'PREMIUM'"
               [disabled]="!canEnableScore()"
            >
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="accordion-icon img-fluid me-2"
                           width="1.35rem"
                           height="1.35rem"
                           fill="#009000"
                           viewBox="0 0 576 512"
                        >
                           <path
                              d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                           />
                        </svg>
                        <div class="d-flex align-items-center">
                           <span class="btn-text">Musaffa Halal Score</span>
                           <svg
                              viewBox="0 0 24 24"
                              class="ms-2"
                              fill="none"
                              width="1.5rem"
                              height="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                              *ngIf="userRole !== 'PREMIUM'"
                           >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                 id="SVGRepo_tracerCarrier"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                 <path
                                    d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 ></path>
                              </g>
                           </svg>
                        </div>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of scoreList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="exchange"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel *ngIf="isMobile">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/musaffa-score-icon.svg"
                           alt="musaffa-score icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Sort by</span>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="
                        let obj of sortByList.slice(
                           0,
                           userRole !== 'PREMIUM' ? 5 : sortByList.length
                        )
                     "
                  >
                     <input
                        [(ngModel)]="selectedSort"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="sort_by"
                        type="radio"
                        [value]="obj"
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>
         </ngb-accordion>

         <div class="px-md-3 pb-md-3 btns-container">
            <button
               class="btn filter-mobile-btn reset-button"
               (click)="reset()"
            >
               <i class="fa-solid fa-rotate-left me-2"></i> Reset
            </button>

            <!-- <button class="btn filter-mobile-btn apply-button" (click)="redirectToSignUpPage()" *ngIf="userRole === 'ANONYMOUS'">
               Apply
            </button>
            <button class="btn filter-mobile-btn apply-button" *ngIf="userRole !== 'ANONYMOUS'" (click)="applyFilters(true)">
               Apply
            </button> -->
            <button
               class="btn filter-mobile-btn apply-button"
               (click)="applyFilters(true)"
            >
               Apply
            </button>
         </div>
      </div>
   </div>
   <!-- for stock screener end -->

   <!-- for etf screener start -->
   <div class="onboarding__filter__container" *ngIf="type === 'etf'">
      <div
         class="d-flex align-items-center justify-content-between border-bottom px-3 py-3 d-none d-lg-flex"
      >
         <h3
            class="mb-0 w-100 filter__heading d-flex align-items-center justify-content-between"
         >
            <div>
               <img
                  src="/assets/images/filter-icon.svg"
                  alt="Filter Icon"
                  class="filter-icon img-fluid me-2"
                  width="16"
               />
               <span>Filter By</span>
            </div>
            <div class="ms-auto">
               <p class="filter-stocks-count mb-0" *ngIf="etfTotalDataCount">
                  {{ etfTotalDataCount }} matching ETFs
               </p>
               <p class="filter-stocks-count mb-0" *ngIf="!etfTotalDataCount">
                  {{ etfTotalDataCount }} matching ETFs
               </p>
            </div>
         </h3>
         <!-- <button type="reset" class="clear__all__btn btn rounded-pill lh-1 py-2 px-3">
            <i class="fa-regular fa-circle-xmark me-1"></i> Clear All
         </button> -->
      </div>
      <div class="onboarding__filter__body">
         <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/flag-icon.svg"
                           alt="flag icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Country</span>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of countryList"
                  >
                     <input
                        [(ngModel)]="selectedCountry"
                        (ngModelChange)="onCountryUpdate()"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="countryList"
                        type="radio"
                        [value]="obj"
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel [disabled]="!exchangeList.length">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/stock-exchange-icon.svg"
                           alt="exchange icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Exchange</span>
                        <span
                           class="selected-count"
                           *ngIf="getListCount(exchangeList) > 0"
                           >[ +{{ getListCount(exchangeList) }} ]</span
                        >
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of exchangeList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="etfExchange"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                        (ngModelChange)="exChanged()"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel>
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/pie-chart-icon.svg"
                           alt="pie chart icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <span class="btn-text">Class</span>
                        <span
                           class="selected-count"
                           *ngIf="getListCount(etfClassList) > 0"
                           >[ +{{ getListCount(etfClassList) }} ]</span
                        >
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of etfClassList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="etfExchange"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                        (ngModelChange)="etfClassChanged()"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel [disabled]="userRole !== 'PREMIUM'">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <img
                           src="/assets/images/shariah-compliance-icon.svg"
                           alt="shariah-compliance icon"
                           class="accordion-icon img-fluid me-2"
                           width="24"
                        />
                        <div class="d-flex align-items-center">
                           <span class="btn-text">Compliance</span>
                           <span
                              class="for-premium ms-2"
                              *ngIf="userRole !== 'PREMIUM'"
                              >(For Premium members)</span
                           >
                        </div>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of complianceList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        (ngModelChange)="onComplianceChange(obj)"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="etfCompliance"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel *ngIf="userRole !== 'PREMIUM'" id="not-premium2">
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center disabled justify-content-between"
                     placement="top"
                     ngbTooltip="Musaffa Premium Feature"
                     (click)="openPayWallModal()"
                  >
                     <div class="d-flex align-items-center">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="accordion-icon img-fluid me-2"
                           width="1.35rem"
                           height="1.35rem"
                           fill="#6bca8c"
                           viewBox="0 0 576 512"
                        >
                           <path
                              d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                           />
                        </svg>
                        <div class="d-flex align-items-center">
                           <span class="btn-text">Musaffa Halal Score</span>
                           <svg
                              viewBox="0 0 24 24"
                              class="ms-2"
                              fill="none"
                              width="1.5rem"
                              height="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                 id="SVGRepo_tracerCarrier"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                 <path
                                    d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 ></path>
                              </g>
                           </svg>
                        </div>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
            </ngb-panel>

            <ngb-panel
               *ngIf="userRole === 'PREMIUM'"
               [disabled]="!canEnableScore()"
            >
               <ng-template ngbPanelTitle>
                  <div
                     class="d-flex align-items-center justify-content-between"
                  >
                     <div class="d-flex align-items-center">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="accordion-icon img-fluid me-2"
                           width="1.35rem"
                           height="1.35rem"
                           fill="#009000"
                           viewBox="0 0 576 512"
                        >
                           <path
                              d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                           />
                        </svg>
                        <div class="d-flex align-items-center">
                           <span class="btn-text">Musaffa Halal Score</span>
                           <svg
                              viewBox="0 0 24 24"
                              class="ms-2"
                              fill="none"
                              width="1.5rem"
                              height="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                              *ngIf="userRole !== 'PREMIUM'"
                           >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                 id="SVGRepo_tracerCarrier"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                 <path
                                    d="M12 14.5V16.5M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 ></path>
                              </g>
                           </svg>
                        </div>
                     </div>
                     <i class="fa-solid fa-chevron-down"></i>
                  </div>
               </ng-template>
               <ng-template ngbPanelContent>
                  <div
                     class="form-check p-0 mx-0 mt-0 d-flex align-items-center checkbox-container"
                     *ngFor="let obj of scoreList"
                  >
                     <input
                        [(ngModel)]="obj['checked']"
                        class="form-check-input filter-checkbox p-0 my-0 ms-0 me-3 shadow-none flex-shrink-0"
                        name="exchange"
                        type="checkbox"
                        value=""
                        [id]="obj.display_value"
                     />
                     <label
                        class="form-check-label w-100 checkbox-label"
                        [for]="obj.display_value"
                     >
                        {{ obj.display_value }}
                     </label>
                  </div>
               </ng-template>
            </ngb-panel>
         </ngb-accordion>

         <div class="px-md-3 pb-md-3 btns-container">
            <button
               class="btn filter-mobile-btn reset-button"
               (click)="reset()"
            >
               <i class="fa-solid fa-rotate-left me-2"></i> Reset
            </button>

            <!-- <button class="btn filter-mobile-btn apply-button" (click)="redirectToSignUpPage()" *ngIf="userRole === 'ANONYMOUS'">
                Apply
            </button> -->
            <!-- <button class="btn filter-mobile-btn apply-button" (click)="applyFilters(true)" *ngIf="userRole !== 'ANONYMOUS'">
                Apply
            </button> -->
            <button
               class="btn filter-mobile-btn apply-button"
               (click)="applyFilters(true)"
            >
               Apply
            </button>
         </div>
      </div>
   </div>
</ng-container>
