import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-less-than-tooltip',
  templateUrl: './less-than-tooltip.component.html',
  styleUrls: ['./less-than-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LessThanTooltipComponent implements OnInit {
  @Input() tooltipContent!: number;
  @Input() tooltipPosition: string = 'top';
  @Input() amount!: number;
  @Input() currency!: string;
  @Input() type: 'amount' | 'percentage' | 'number' = 'amount';
  @Input() amountStyle: { [key: string]: string } = {
    color: 'black',
    fontWeight: '400'
  };


  constructor() { }

  ngOnInit(): void {
   this.amount = Math.abs(this.amount);
  }

}
