import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/shared/services/firestore.service';
import { FirebaseRemoteConfigService } from 'src/app/shared/services/firebase-remote-config.service';
import { AuthService } from './auth.service';
import { TypesenseService } from './typesense.service';
import { SubscriptionService } from './subscription.service';

@Injectable({
   providedIn: 'root'
})
export class CollectionComplianceService {

   private collection_compliances: any = {
      etf: {},
      stocks: {}
   }

   constructor(
      private auth: AuthService,
      private typesenseService: TypesenseService,
      private firebaseRemoteConfig: FirebaseRemoteConfigService,
      private firestoreService: FirestoreService
   ) { }

   public async getComplianceInfo(symbol: string, type: string) {
      let compliance_info: any;
      switch (type) {
         case 'stocks':
            if (this.collection_compliances.stocks[symbol]) {
               compliance_info = this.collection_compliances.stocks[symbol]
            } else {
               compliance_info = await this.firestoreService.getStockComplianceInfo(symbol)
               this.collection_compliances.stocks[symbol] = compliance_info
            }
            break;
         case 'etf':
            if (this.collection_compliances.etf[symbol]) {
               compliance_info = this.collection_compliances.etf[symbol]
            } else {
               compliance_info = await this.firestoreService.etfComplianceInfo(symbol)
               this.collection_compliances.etf[symbol] = compliance_info
            }
            break;
         default:
            compliance_info = null
            break;
      }
      return compliance_info;
   }

   public async getAccessibleComplianceSymbols(symbols: string[], type: string) {
      const role = SubscriptionService.subscriptionData.getValue().role
      let finalSymbols = [];
      const isStockType = type === 'stocks';
      // console.log('role', role)
      if (role === 'PREMIUM') {
         finalSymbols = symbols
      } else {
         let allowedSymbolList = isStockType ? await this.firebaseRemoteConfig.getFreeStocksList() : await this.firebaseRemoteConfig.getFreeEtfList()
         // if(role === 'FREEMIUM') {
         //    allowedSymbolList = allowedSymbolList.concat(isStockType ? await this.firestoreService.getUserViewedStocks() : await this.firestoreService.getUserViewedEtf())
         // }
         finalSymbols = symbols.filter(x => allowedSymbolList.includes(x));
      }
      return finalSymbols;
   }

   // public async getAccessibleComplianceSymbols(symbols: string[], type: string) {
   //    const role = SubscriptionService.subscriptionData.getValue().role;
   //    let finalSymbols: string[] = [];

   //    if (role === 'PREMIUM') {
   //       finalSymbols = symbols;
   //    } else {
   //       if (type === 'stocks') {
   //          const allowedStockList = await this.firebaseRemoteConfig.getFreeStocksList();
   //          finalSymbols = symbols.filter(x => allowedStockList.includes(x));
   //       }
   //       else if (type === 'etfs') {
   //          const allowedEtfList = await this.firebaseRemoteConfig.getFreeEtfList();
   //          finalSymbols = symbols.filter(x => allowedEtfList.includes(x));
   //       }
   //       else if (type === 'all') { // Handle 'all' type
   //          const [allowedStockList, allowedEtfList] = await Promise.all([
   //             this.firebaseRemoteConfig.getFreeStocksList(),
   //             this.firebaseRemoteConfig.getFreeEtfList()
   //          ]);

   //          const stockSymbols = symbols.filter(x => allowedStockList.includes(x));
   //          const etfSymbols = symbols.filter(x => allowedEtfList.includes(x));

   //          finalSymbols = [...stockSymbols, ...etfSymbols]; // Merge both
   //       }
   //    }

   //    return finalSymbols;
   // }

   public async getAccessibleComplianceInfo(symbols: string[], type: string) {
      const role = this.auth.getRole()
      let ret: any = {}
      let finalSymbols = [];
      const isStockType = type === 'stocks';
      if (role === 'PREMIUM') {
         finalSymbols = symbols
      } else {
         let allowedSymbolList = isStockType ? await this.firebaseRemoteConfig.getFreeStocksList() : await this.firebaseRemoteConfig.getFreeEtfList()
         if (role === 'FREEMIUM') {
            allowedSymbolList = allowedSymbolList.concat(isStockType ? await this.firestoreService.getUserViewedStocks() : await this.firestoreService.getUserViewedEtf())
         }
         finalSymbols = symbols.filter(x => allowedSymbolList.includes(x));
      }
      ret = await this.typesenseService.getStocksFromSymbols(finalSymbols);
      // console.log(ret)

      for (let i in symbols) {
         if (!ret[symbols[i]]) {
            if (finalSymbols.includes(symbols[i])) {
               ret[symbols[i]] = {
                  status: 'NOT_UNDER_COVERAGE',
                  ranking: 0
               }
            } else {
               ret[symbols[i]] = {
                  status: 'LOCKED',
                  ranking: 0
               }
            }
         } else {
            ret[symbols[i]] = {
               status: ret[symbols[i]].complianceStatus,
               ranking: ret[symbols[i]].compliantRanking
            }
         }
      }
      return ret;
   }

   public async processComplianceForStocksEtfMap(stocksMap: any, type: string) {
      const role = this.auth.getRole()

      const isStockType = type === 'stocks';
      return stocksMap;
      // if(role === 'PREMIUM') {
      //    return stocksMap
      // } else {
      //    let allowedIsinList = isStockType ? await this.firebaseRemoteConfig.getFreeStocksList() : await this.firebaseRemoteConfig.getFreeEtfList()

      //    for (let i in stocksMap) {

      //       if(!allowedIsinList.includes(stocksMap[i].isin))
      //          stocksMap[i]['shariahCompliantStatus'] = 'LOCKED'
      //    }


      //    return stocksMap
      // }
   }
}
