<div class="modal-header bg-light border-0 d-flex py-3 align-items-center justify-content-between">
   <h5 class="mb-0 lh-1 fw-bold text-center">Purification Methodologies</h5>
   <button class="btn lh-1 p-0 shadow-none" (click)="activeModal.dismiss()">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 384 512">
         <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
      </svg>
   </button>
</div>
<div class="modal-body p-0">
   <div *ngFor="let method of methods" (click)="changeMethod(method.value)" class="p-3 method-card">
      <svg class="flex-shrink-0 empty-radio-svg" xmlns="http://www.w3.org/2000/svg" fill="grey" viewBox="0 0 512 512" *ngIf="method !== selectedPurificationMethod">
         <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/>
      </svg>
      <svg class="flex-shrink-0 empty-radio-svg" fill="#1bad4e" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="method === selectedPurificationMethod">
         <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/>
      </svg>
      <div>
         <h6 class="fw-bold text-capitalize">{{method.name}}</h6>
         <p class="mb-0 formula">{{method.description}}</p>
      </div>
   </div>
</div>
