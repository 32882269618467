<section class="content-padding portfolio-section">
   <ng-container *ngIf="!canHaveBrokerageAccounts">
      <div class="container portfolio-bg">
         <app-link-portfolio></app-link-portfolio>
      </div>
      <app-supported-brokerages-slider></app-supported-brokerages-slider>
   </ng-container>

   <ng-container *ngIf="canHaveBrokerageAccounts">

      <ng-container *ngIf="sectionLoader">
         <div class="container">
            <div class="br-10 d-grid gap-4 p-3 p-lg-4 portfolio-data-card">
               <span class="skeleton-loader w-50 py-3 rounded-3"></span>
               <div class="d-flex w-100 align-items-center gap-3 gap-lg-4">
                  <div class="flex-grow-1">
                     <span class="skeleton-loader rounded-3 mb-2 py-2 px-5"></span>
                     <span class="skeleton-loader rounded-3 py-3 px-5"></span>
                  </div>
                  <div class="vr"></div>
                  <div class="flex-grow-1">
                     <span class="skeleton-loader rounded-3 mb-2 py-2 px-5"></span>
                     <span class="skeleton-loader rounded-3 py-3 px-5"></span>
                  </div>
               </div>
               <span class="w-100 skeleton-loader rounded-3 pt-3 pb-1"></span>
            </div>

            <div class="d-grid gap-3">
               <span class="skeleton-loader w-50 py-3 rounded-3"></span>
               <div class="d-flex w-100 align-items-center gap-3 gap-lg-4">
                  <span class="flex-grow-1 skeleton-loader br-10 p-5"></span>
                  <span class="flex-grow-1 skeleton-loader br-10 p-5"></span>
               </div>
            </div>
         </div>
      </ng-container>

      <ng-container *ngIf="!sectionLoader">
         <ng-container *ngIf="!hasPortfolioAccounts; else linkAccount">
            <div class="container portfolio-bg">
               <app-link-portfolio></app-link-portfolio>
            </div>
            <app-supported-brokerages-slider></app-supported-brokerages-slider>
         </ng-container>

         <ng-template #linkAccount>
            <div class="container mx-auto portfolio-bg">
               <app-my-portfolio-info></app-my-portfolio-info>
            </div>
         </ng-template>
      </ng-container>

   </ng-container>
</section>
