import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import {
  Notification,
  NotificationResponse,
} from 'src/app/models/user-notification.model';
import { AppNotificationsService } from 'src/app/shared/services/app-notifications.service';
import { MusaffaRedirectService } from 'src/app/shared/services/musaffa-redirect.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';


@Component({
  selector: 'app-header-user-notifications',
  templateUrl: './header-user-notifications.component.html',
  styleUrls: ['./header-user-notifications.component.scss']
})
export class HeaderUserNotificationsComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  isMobile: boolean = false;
  userRole: string | null = null;
  notificationsLoader: boolean = false;
  isNotificationsMenuOpen: boolean = false;
  isEveryNotificationRead: boolean = false;
  allNotifications: Notification[] = [];
  unreadNotifications: Notification[] = [];

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private observer: BreakpointObserver,
    private notificationService: AppNotificationsService,
    private musaffaRedirectService: MusaffaRedirectService
  ) { }

  ngOnInit(): void {
    SubscriptionService.subscriptionData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: SubscriptionInfoModel) => {
        this.userRole = data.role;
        if (this.userRole !== 'ANONYMOUS') {
          this.getUserNotifications();
        }
      });

    this.observer
      .observe('(max-width: 991px)')
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.isMobile = result.matches;
      });

    this.renderer.listen('window', 'click', (event: Event) => {
      this.onDocumentClick(event);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openNotificationsDropdown() {
    this.isNotificationsMenuOpen = true;
  }

  closeNotificationsMenu() {
    this.isNotificationsMenuOpen = false;
  }

  onDocumentClick(event: Event): void {
    if (
      this.isNotificationsMenuOpen &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.closeNotificationsMenu();
    }
  }

  trackByNotificationId(index: number, notification: Notification) {
    return notification.id;
  }

  async getUserNotifications() {
    try {
      this.notificationsLoader = true;
      const resp =
        await this.notificationService.fetchUserNotofications<NotificationResponse>();
      if (resp.status) {
        this.allNotifications = resp.data;
        this.isEveryNotificationRead = this.allNotifications.some(
          (notification: Notification) => notification.is_read === false
        );
        // this.unreadNotifications = this.allNotifications.filter((notification : Notification) => !notification.is_read)
        this.notificationService.updateNotificationRead(
          this.isEveryNotificationRead
        );
      }
    } catch (error) {
      console.error(error);
      this.notificationsLoader = false;
    } finally {
      this.notificationsLoader = false;
    }
  }

  async onNotificationClick(notification: Notification) {
    try {
      const module = notification.notification_log.module;
      const record_id = notification.notification_log.record_id;
      this.musaffaRedirectService.goToPage(module, record_id);
      this.closeNotificationsMenu();
      await this.notificationService.markUserNotificationAsRead<{
        status: boolean;
        message: string;
      }>([notification.id]);
      this.getUserNotifications();
    } catch (error) {
      console.error(error);
    }
  }

  async markAllNotificationAsRead() {
    await this.notificationService.markAllNotificationAsRead();
    this.getUserNotifications();
    this.closeNotificationsMenu();
  }

}
