
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cutEtfPipe'
})
export class countryCutEtfPipe implements PipeTransform {

	transform(value: any) {
		let name;
		switch (value) {
			case 'United States of America (the)':
				name = 'U.S.A';
				break;
			case 'United Kingdom of Great Britain and Northern Ireland (the)':
				name = 'U.K';
				break;
			case 'Netherlands (the)':
				name = 'Netherlands';
				break;
			default:
				name = value;
				break;
		}
		return name;
	}

}
