import {
   Filter,
   FilterGroup,
   FilterCategory,
} from '../models/filter-data.model';

const dividendFrequencyOptions = {
   '0': 'Annually',
   '1': 'Monthly',
   '2': 'Quarterly',
   '3': 'Semi-Annually',
   '5': 'Bimonthly',
   '6': 'Trimesterly',
   '7': 'Weekly',
   '4': 'Other/Unknown',
};

export const etfFilterCategoryConfig: FilterCategory[] = [
   {
      name: 'musaffa_filtering',
      label: 'Musaffa Filtering',
      showMore: false,
      hideUnlock: false,
      hidePlanIcon: false,
   },
   {
      name: 'filters',
      label: 'Filters',
      showMore: true,
      hideUnlock: true,
      hidePlanIcon: true,
   },
];

export const etfFilterGroupsConfig: FilterGroup[] = [
   {
      name: 'musaffa_all',
      label: 'All',
      category: ['musaffa_filtering'],
   },
   {
      name: 'compliance',
      label: 'Compliance',
      category: ['musaffa_filtering'],
   },
   {
      name: 'financial_screening',
      label: 'Financial Screening',
      category: ['musaffa_filtering'],
   },
   // {
   //    name: 'purification_metrics',
   //    label: 'Purification Metrics',
   //    category: ['musaffa_filtering'],
   // },
   {
      name: 'filters_all',
      label: 'All',
      category: ['filters'],
   },
   {
      name: 'popular',
      label: 'Popular',
      category: ['filters'],
   },
   {
      name: 'general_information',
      label: 'General Information',
      category: ['filters'],
   },
   {
      name: 'strategy_composition',
      label: 'Strategy & Composition',
      category: ['filters'],
   },
   // {
   //    name: 'volume_trading_metrics',
   //    label: 'Volume & Trading Metrics',
   //    category: ['filters'],
   // },
   {
      name: 'financial_performance_cost',
      label: 'Financial Performance & Cost',
      category: ['filters'],
   },
   {
      name: 'market_cap_exposure',
      label: 'Market Cap Exposure',
      category: ['filters'],
   },
   // {
   //    name: 'sector_exposure',
   //    label: 'Sector Exposure',
   //    category: ['filters'],
   // },
   // {
   //    name: 'total_return',
   //    label: 'Total Return',
   //    category: ['filters'],
   // },
   {
      name: 'dividend',
      label: 'Dividends',
      category: ['filters'],
   },
];

export const etfFiltersConfig: Filter[] = [
   // Compliance Group Start
   {
      name: 'shariahCompliantStatus',
      group: ['musaffa_all', 'compliance'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown',
      multiple: true,
      label: 'Shariah Compliance',
      role: 'starter',
      cols: 1,
      placeholder: 'Select Compliance Status',
      options: [
         {
            value: 'COMPLIANT',
            label: 'Halal',
         },
         {
            value: 'QUESTIONABLE',
            label: 'Doubtful',
         },
         {
            value: 'NON_COMPLIANT',
            label: 'Not halal',
         },
      ],
   },
   {
      name: 'ranking_v2',
      group: ['musaffa_all', 'compliance'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown',
      multiple: true,
      label: 'Halal Rating',
      role: 'starter',
      cols: 1,
      placeholder: 'Select Rating',
      conditions: [
         {
            field: 'shariahCompliantStatus',
            value: ['COMPLIANT'],
         },
      ],
      options: [
         { value: "9", label: "A+" },
         { value: "8", label: "A" },
         { value: "7", label: "A-" },
         { value: "6", label: "B+" },
         { value: "5", label: "B" },
         { value: "4", label: "B-" },
         { value: "3", label: "C+" },
         { value: "2", label: "C" },
         { value: "1", label: "C-" },
       ]
   },

   // Financial Screening Group Start
   {
      name: 'businessCompliantRatio',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Halal Revenue %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '99',
               label: '99%',
            },
            {
               value: '98',
               label: '98%',
            },
            {
               value: '97',
               label: '97%',
            },
            {
               value: '96',
               label: '96%',
            },
            {
               value: '95.01',
               label: '95.01%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '10',
               label: '10%',
            },
         ],
         from: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '99',
               label: '99%',
            },
            {
               value: '98',
               label: '98%',
            },
            {
               value: '97',
               label: '97%',
            },
            {
               value: '96',
               label: '96%',
            },
            {
               value: '95.01',
               label: '95.01%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '10',
               label: '10%',
            },
         ],
      },
   },
   {
      name: 'businessNonCompliantRatio',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Not Halal Revenue %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
         from: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
      },
   },
   {
      name: 'businessQuestionableRatio',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Doubtful Revenue %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
         from: [
            {
               value: '0',
               label: '0%',
            },
            {
               value: '1',
               label: '1%',
            },
            {
               value: '2',
               label: '2%',
            },
            {
               value: '3',
               label: '3%',
            },
            {
               value: '4',
               label: '4%',
            },
            {
               value: '4.99',
               label: '4.99%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '30',
               label: '30%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '100',
               label: '100%',
            },
         ],
      },
   },
   {
      name: 'interestBearingAssetsRatio',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Interest-bearing Assets %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
         from: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
      },
   },
   {
      name: 'interestBearingDebtRatio',
      group: ['musaffa_all', 'financial_screening'],
      hidePlanIcon: true,
      hideUnlock: true,
      type: 'dropdown_range',
      label: 'Interest-bearing Debt %',
      role: 'starter',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         to: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
         from: [
            {
               value: '100',
               label: '100%',
            },
            {
               value: '90',
               label: '90%',
            },
            {
               value: '80',
               label: '80%',
            },
            {
               value: '70',
               label: '70%',
            },
            {
               value: '60',
               label: '60%',
            },
            {
               value: '50',
               label: '50%',
            },
            {
               value: '40',
               label: '40%',
            },
            {
               value: '29.99',
               label: '29.99%',
            },
            {
               value: '25',
               label: '25%',
            },
            {
               value: '20',
               label: '20%',
            },
            {
               value: '15',
               label: '15%',
            },
            {
               value: '10',
               label: '10%',
            },
            {
               value: '5',
               label: '5%',
            },
            {
               value: '0',
               label: '0%',
            },
         ],
      },
   },

   // Purification Metrics Group Start
   // {
   //    name: '',
   //    group: ['musaffa_all', 'purification_metrics'],
   //    hidePlanIcon: true,
   //    hideUnlock: true,
   //    type: 'dropdown_range',
   //    label: 'Purification %',
   //    role: 'starter',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       to: [
   //          {
   //             value: '100',
   //             label: '100%',
   //          },
   //          {
   //             value: '90',
   //             label: '90%',
   //          },
   //          {
   //             value: '80',
   //             label: '80%',
   //          },
   //          {
   //             value: '70',
   //             label: '70%',
   //          },
   //          {
   //             value: '60',
   //             label: '60%',
   //          },
   //          {
   //             value: '50',
   //             label: '50%',
   //          },
   //          {
   //             value: '40',
   //             label: '40%',
   //          },
   //          {
   //             value: '30',
   //             label: '30%',
   //          },
   //          {
   //             value: '20',
   //             label: '20%',
   //          },
   //          {
   //             value: '10',
   //             label: '10%',
   //          },
   //          {
   //             value: '4.99',
   //             label: '4.99%',
   //          },
   //          {
   //             value: '4',
   //             label: '4%',
   //          },
   //          {
   //             value: '3',
   //             label: '3%',
   //          },
   //          {
   //             value: '2',
   //             label: '2%',
   //          },
   //          {
   //             value: '1',
   //             label: '1%',
   //          },
   //          {
   //             value: '0',
   //             label: '0%',
   //          },
   //       ],
   //       from: [
   //          {
   //             value: '100',
   //             label: '100%',
   //          },
   //          {
   //             value: '90',
   //             label: '90%',
   //          },
   //          {
   //             value: '80',
   //             label: '80%',
   //          },
   //          {
   //             value: '70',
   //             label: '70%',
   //          },
   //          {
   //             value: '60',
   //             label: '60%',
   //          },
   //          {
   //             value: '50',
   //             label: '50%',
   //          },
   //          {
   //             value: '40',
   //             label: '40%',
   //          },
   //          {
   //             value: '30',
   //             label: '30%',
   //          },
   //          {
   //             value: '20',
   //             label: '20%',
   //          },
   //          {
   //             value: '10',
   //             label: '10%',
   //          },
   //          {
   //             value: '4.99',
   //             label: '4.99%',
   //          },
   //          {
   //             value: '4',
   //             label: '4%',
   //          },
   //          {
   //             value: '3',
   //             label: '3%',
   //          },
   //          {
   //             value: '2',
   //             label: '2%',
   //          },
   //          {
   //             value: '1',
   //             label: '1%',
   //          },
   //          {
   //             value: '0',
   //             label: '0%',
   //          },
   //       ],
   //    },
   // },
   // {
   //    name: '',
   //    group: ['musaffa_all', 'purification_metrics'],
   //    hidePlanIcon: true,
   //    hideUnlock: true,
   //    type: 'dropdown_range',
   //    label: 'Purification Per Share',
   //    role: 'starter',
   //    cols: 1,
   //    valueType: 'number',
   //    rangeOptions: {
   //       to: [
   //          {
   //             value: '5',
   //             label: '5',
   //          },
   //          {
   //             value: '4.5',
   //             label: '4.5',
   //          },
   //          {
   //             value: '4',
   //             label: '4',
   //          },
   //          {
   //             value: '3.5',
   //             label: '3.5',
   //          },
   //          {
   //             value: '3',
   //             label: '3',
   //          },
   //          {
   //             value: '2.5',
   //             label: '2.5',
   //          },
   //          {
   //             value: '2',
   //             label: '2',
   //          },
   //          {
   //             value: '1.5',
   //             label: '1.5',
   //          },
   //          {
   //             value: '1',
   //             label: '1',
   //          },
   //          {
   //             value: '0.5',
   //             label: '0.5',
   //          },
   //          {
   //             value: '0',
   //             label: '0',
   //          },
   //       ],
   //       from: [
   //          {
   //             value: '0',
   //             label: '0',
   //          },
   //          {
   //             value: '0.5',
   //             label: '0.5',
   //          },
   //          {
   //             value: '1',
   //             label: '1',
   //          },
   //          {
   //             value: '1.5',
   //             label: '1.5',
   //          },
   //          {
   //             value: '2',
   //             label: '2',
   //          },
   //          {
   //             value: '2.5',
   //             label: '2.5',
   //          },
   //          {
   //             value: '3',
   //             label: '3',
   //          },
   //          {
   //             value: '3.5',
   //             label: '3.5',
   //          },
   //          {
   //             value: '4',
   //             label: '4',
   //          },
   //          {
   //             value: '4.5',
   //             label: '4.5',
   //          },
   //          {
   //             value: '5',
   //             label: '5',
   //          },
   //       ],
   //    },
   // },

   // General Information Group Start
   {
      name: 'exchange',
      group: ['filters_all', 'general_information'],
      type: 'dropdown',
      multiple: true,
      label: 'Exchange',
      role: 'free',
      cols: 1,
      placeholder: 'Select Exchange',
      optionsFunction: 'getExchangeList',
      optionsFunctionParams: ['country'],
   },
   {
      name: 'assetClass',
      group: ['filters_all', 'general_information', 'popular'],
      type: 'dropdown',
      multiple: true,
      label: 'Asset Class',
      role: 'free',
      cols: 1,
      placeholder: 'Select Asset Class',
      options: [
         {
            value: 'Alternatives',
            label: 'Alternatives',
         },
         {
            value: 'Asset Allocation',
            label: 'Asset Allocation',
         },
         {
            value: 'Commodities',
            label: 'Commodities',
         },
         {
            value: 'Currency',
            label: 'Currency',
         },
         {
            value: 'Equity',
            label: 'Equity',
         },
         {
            value: 'Fixed Income',
            label: 'Fixed Income',
         },
      ],
   },
   // {
   //    name: 'is_etn',
   //    group: ['filters_all', 'general_information'],
   //    type: 'dropdown',
   //    multiple: true,
   //    label: 'Is ETN',
   //    role: 'free',
   //    cols: 1,
   //    placeholder: 'Select ETN',
   //    options: [
   //       {
   //          value: 'Yes',
   //          label: 'Yes',
   //       },
   //       {
   //          value: 'No',
   //          label: 'No',
   //       },
   //    ],
   // },
   // Inception Date MISSING

   // Strategy & Composition Group Start
   // {
   //    name: 'topTenHoldings',
   //    group: ['filters_all', 'strategy_composition'],
   //    type: 'dropdown',
   //    multiple: true,
   //    label: 'Top Ten Holdings',
   //    role: 'premium',
   //    cols: 1,
   //    placeholder: 'Select Top Ten Holdings',
   //    optionsFunction: 'getTopTenHoldingsList',
   //    optionsFunctionParams: [],

   // },
   {
      name: 'numberOfHoldings',
      group: ['filters_all', 'strategy_composition', 'popular'],
      type: 'dropdown_range',
      label: 'Holdings Count',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            {
               value: '10',
               label: '10',
            },
            {
               value: '50',
               label: '50',
            },
            {
               value: '100',
               label: '100',
            },
            {
               value: '150',
               label: '150',
            },
            {
               value: '200',
               label: '200',
            },
            {
               value: '300',
               label: '300',
            },
            {
               value: '400',
               label: '400',
            },
            {
               value: '500',
               label: '500',
            },
            {
               value: '1000',
               label: '1000',
            },
            {
               value: '5000',
               label: '5000',
            },
         ],
         to: [
            {
               value: '10',
               label: '10',
            },
            {
               value: '50',
               label: '50',
            },
            {
               value: '100',
               label: '100',
            },
            {
               value: '150',
               label: '150',
            },
            {
               value: '200',
               label: '200',
            },
            {
               value: '300',
               label: '300',
            },
            {
               value: '400',
               label: '400',
            },
            {
               value: '500',
               label: '500',
            },
            {
               value: '1000',
               label: '1000',
            },
            {
               value: '5000',
               label: '5000',
            },
         ],
      },
   },
   // {
   //    name: 'is_inverse',
   //    group: ['filters_all', 'strategy_composition'],
   //    type: 'dropdown',
   //    multiple: true,
   //    label: 'Inverse',
   //    role: 'free',
   //    cols: 1,
   //    placeholder: 'Select Inverse',
   //    options: [
   //       {
   //          value: 'Yes',
   //          label: 'Yes',
   //       },
   //       {
   //          value: 'No',
   //          label: 'No',
   //       },
   //    ],
   // },
   // {
   //    name: 'is_leveraged',
   //    group: ['filters_all', 'strategy_composition'],
   //    type: 'dropdown',
   //    multiple: true,
   //    label: 'Leveraged',
   //    role: 'free',
   //    cols: 1,
   //    placeholder: 'Select Leveraged',
   //    options: [
   //       {
   //          value: 'Yes',
   //          label: 'Yes',
   //       },
   //       {
   //          value: 'No',
   //          label: 'No',
   //       },
   //    ],
   // },
   // {
   //    name: 'leverage_factor',
   //    group: ['filters_all', 'strategy_composition'],
   //    type: 'dropdown_range',
   //    label: 'Leverage Factor',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'number',
   //    rangeOptions: {
   //       from: [
   //          {
   //             value: '-3',
   //             label: '-3',
   //          },
   //          {
   //             value: '-2',
   //             label: '-2',
   //          },
   //          {
   //             value: '-1',
   //             label: '-1',
   //          },
   //          {
   //             value: '1',
   //             label: '1',
   //          },
   //          {
   //             value: '2',
   //             label: '2',
   //          },
   //          {
   //             value: '3',
   //             label: '3',
   //          },
   //       ],
   //       to: [
   //          {
   //             value: '-3',
   //             label: '-3',
   //          },
   //          {
   //             value: '-2',
   //             label: '-2',
   //          },
   //          {
   //             value: '-1',
   //             label: '-1',
   //          },
   //          {
   //             value: '1',
   //             label: '1',
   //          },
   //          {
   //             value: '2',
   //             label: '2',
   //          },
   //          {
   //             value: '3',
   //             label: '3',
   //          },
   //       ],
   //    },
   // },

   // Valuation & Trading Metrics Group Start
   {
      name: 'currentPrice',
      group: ['filters_all', 'valuation_trading_metrics', 'popular'],
      type: 'dropdown_range',
      label: 'Price',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: 'open',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Open Price',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: 'PreviousClosePrice',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Previous Close',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: 'low',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Low Price',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: 'high',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'High Price',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: '52WeekLow',
      group: ['filters_all', 'valuation_trading_metrics', 'popular'],
      type: 'dropdown_range',
      label: '52-Week Low',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: '52WeekHigh',
      group: ['filters_all', 'valuation_trading_metrics', 'popular'],
      type: 'dropdown_range',
      label: '52-Week High',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
         to: [
            { value: '0', label: '-' },
            { value: '1', label: '1' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' },
            { value: '500', label: '500' },
            { value: '1000', label: '1,000' },
            { value: '2000', label: '2,000' },
            { value: '3000', label: '3,000' },
            { value: '4000', label: '4,000' },
            { value: '5000', label: '5,000' },
            { value: '10000', label: '10,000' },
            { value: '50000', label: '50,000' },
         ],
      },
   },
   {
      name: 'volume',
      group: ['filters_all', 'valuation_trading_metrics', 'popular'],
      type: 'dropdown_range',
      label: 'Avg. Volume',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '1000', label: '1K' },
            { value: '10000', label: '10K' },
            { value: '100000', label: '100K' },
            { value: '1000000', label: '1M' },
            { value: '10000000', label: '10M' },
            { value: '100000000', label: '100M' },
         ],
         to: [
            { value: '1000', label: '1K' },
            { value: '10000', label: '10K' },
            { value: '100000', label: '100K' },
            { value: '1000000', label: '1M' },
            { value: '10000000', label: '10M' },
            { value: '100000000', label: '100M' },
         ],
      },
   },
   {
      name: 'priceChange1DPercent',
      group: ['filters_all', 'valuation_trading_metrics', 'popular'],
      type: 'dropdown_range',
      label: 'Price Change (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
      },
   },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change 1W (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   {
      name: 'priceChange1MPercent',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Price Change 1M (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
      },
   },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change 3M (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change 6M (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change YTD (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   {
      name: 'priceChange1YPercent',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Price Change 1Y (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
      },
   },
   {
      name: 'priceChange3YPercent',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Price Change 3Y (%)',
      role: 'premium',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
      },
   },
   {
      name: 'priceChange5YPercent',
      group: ['filters_all', 'valuation_trading_metrics'],
      type: 'dropdown_range',
      label: 'Price Change 5Y (%)',
      role: 'premium',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
         to: [
            { value: '-100', label: '-100%' },
            { value: '-90', label: '-90%' },
            { value: '-80', label: '-80%' },
            { value: '-70', label: '-70%' },
            { value: '-60', label: '-60%' },
            { value: '-50', label: '-50%' },
            { value: '-40', label: '-40%' },
            { value: '-30', label: '-30%' },
            { value: '-20', label: '-20%' },
            { value: '-10', label: '-10%' },
            { value: '-5', label: '-5%' },
            { value: '0', label: '-' },
            { value: '5', label: '5%' },
            { value: '10', label: '10%' },
            { value: '20', label: '20%' },
            { value: '30', label: '30%' },
            { value: '40', label: '40%' },
            { value: '50', label: '50%' },
            { value: '60', label: '60%' },
            { value: '70', label: '70%' },
            { value: '80', label: '80%' },
            { value: '90', label: '90%' },
            { value: '100', label: '100%' },
         ],
      },
   },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change 10Y (%)',
   //    role: 'premium',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change 20Y (%)',
   //    role: 'premium',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'valuation_trading_metrics'],
   //    type: 'dropdown_range',
   //    label: 'Price Change All Time (%)',
   //    role: 'premium',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //       to: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' },
   //          { value: '0', label: '-' },
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' },
   //       ],
   //    },
   // },
   {
      name: 'aum',
      group: ['filters_all', 'financial_performance_cost', 'popular'],
      type: 'dropdown_range',
      label: 'AUM',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '0' },
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '500', label: '500M' },
            { value: '1000', label: '1B' },
            { value: '5000', label: '5B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' }
         ],
         to: [
            { value: '10', label: '10M' },
            { value: '50', label: '50M' },
            { value: '100', label: '100M' },
            { value: '500', label: '500M' },
            { value: '1000', label: '1B' },
            { value: '5000', label: '5B' },
            { value: '10000', label: '10B' },
            { value: '50000', label: '50B' },
            { value: '100000', label: '100B' }
         ]
      }
   },
   {
      name: 'nav',
      group: ['filters_all', 'financial_performance_cost'],
      type: 'dropdown_range',
      label: 'NAV',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '0' },
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '250', label: '250' },
            { value: '500', label: '500' }
         ],
         to: [
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '250', label: '250' },
            { value: '500', label: '500' },
            { value: '1000', label: '1000' }
         ]
      }
   },
   // {
   //    name: '',
   //    group: ['filters_all', 'financial_performance_cost'],
   //    type: 'dropdown_range',
   //    label: 'Market Cap',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'marketcap',
   //    rangeOptions: {
   //       from: [
   //          { value: '0', label: '0' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '500', label: '500M' },
   //          { value: '1000', label: '1B' },
   //          { value: '5000', label: '5B' },
   //          { value: '10000', label: '10B' }
   //       ],
   //       to: [
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '500', label: '500M' },
   //          { value: '1000', label: '1B' },
   //          { value: '5000', label: '5B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' }
   //       ]
   //    }
   // },
   {
      name: 'expense_ratio',
      group: ['filters_all', 'financial_performance_cost', 'popular'],
      type: 'dropdown_range',
      label: 'Expense Ratio (%)',
      role: 'premium',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '0', label: '0%' },
            { value: '0.1', label: '0.1%' },
            { value: '0.25', label: '0.25%' },
            { value: '0.5', label: '0.5%' },
            { value: '0.75', label: '0.75%' },
            { value: '1', label: '1%' },
            { value: '1.5', label: '1.5%' },
            { value: '2', label: '2%' }
         ],
         to: [
            { value: '0.1', label: '0.1%' },
            { value: '0.25', label: '0.25%' },
            { value: '0.5', label: '0.5%' },
            { value: '0.75', label: '0.75%' },
            { value: '1', label: '1%' },
            { value: '1.5', label: '1.5%' },
            { value: '2', label: '2%' },
            { value: '3', label: '3%' }
         ]
      }
   },
   {
      name: 'priceToEarnings',
      group: ['filters_all', 'financial_performance_cost'],
      type: 'dropdown_range',
      label: 'P/E Ratio',
      role: 'premium',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '0' },
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '40', label: '40' }
         ],
         to: [
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' },
            { value: '20', label: '20' },
            { value: '25', label: '25' },
            { value: '30', label: '30' },
            { value: '40', label: '40' },
            { value: '50', label: '50' }
         ]
      }
   },
   {
      name: 'priceToBook',
      group: ['filters_all', 'financial_performance_cost'],
      type: 'dropdown_range',
      label: 'P/B Ratio',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '0' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '7.5', label: '7.5' },
            { value: '10', label: '10' }
         ],
         to: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '7.5', label: '7.5' },
            { value: '10', label: '10' },
            { value: '15', label: '15' }
         ]
      }
   },
   // {
   //    name: 'etf_totalAssets',
   //    group: ['filters_all', 'financial_performance_cost'],
   //    type: 'dropdown_range',
   //    label: 'Total Assets',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'million',
   //    rangeOptions: {
   //       from: [
   //          { value: '0', label: '0' },
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '500', label: '500M' },
   //          { value: '1000', label: '1B' },
   //          { value: '5000', label: '5B' },
   //          { value: '10000', label: '10B' }
   //       ],
   //       to: [
   //          { value: '10', label: '10M' },
   //          { value: '50', label: '50M' },
   //          { value: '100', label: '100M' },
   //          { value: '500', label: '500M' },
   //          { value: '1000', label: '1B' },
   //          { value: '5000', label: '5B' },
   //          { value: '10000', label: '10B' },
   //          { value: '50000', label: '50B' }
   //       ]
   //    }
   // },

   // Market Cap Exposure Start
   {
      name: 'largecap_exposure',
      group: ['filters_all', 'market_cap_exposure'],
      type: 'dropdown_range',
      label: 'Large Cap Exposure (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ],
         to: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ]
      }
   },
   {
      name: 'megacap_exposure',
      group: ['filters_all', 'market_cap_exposure'],
      type: 'dropdown_range',
      label: 'Mega Cap Exposure (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ],
         to: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ]
      }
   },
   {
      name: 'midcap_exposure',
      group: ['filters_all', 'market_cap_exposure'],
      type: 'dropdown_range',
      label: 'Mid Cap Exposure (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ],
         to: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ]
      }
   },
   {
      name: 'smallcap_exposure',
      group: ['filters_all', 'market_cap_exposure'],
      type: 'dropdown_range',
      label: 'Small Cap Exposure (%)',
      role: 'free',
      cols: 1,
      valueType: 'percent',
      rangeOptions: {
         from: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ],
         to: [
            { value: '0', label: '0%' },
            { value: '10', label: '10%' },
            { value: '25', label: '25%' },
            { value: '50', label: '50%' },
            { value: '75', label: '75%' },
            { value: '90', label: '90%' },
            { value: '95', label: '95%' },
            { value: '100', label: '100%' }
         ]
      }
   },
   // {
   //    name: 'nanocap_exposure',
   //    group: ['filters_all', 'market_cap_exposure'],
   //    type: 'dropdown_range',
   //    label: 'Nano Cap Exposure (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '0', label: '0%' },
   //          { value: '10', label: '10%' },
   //          { value: '25', label: '25%' },
   //          { value: '50', label: '50%' },
   //          { value: '75', label: '75%' },
   //          { value: '90', label: '90%' },
   //          { value: '95', label: '95%' },
   //          { value: '100', label: '100%' }
   //       ],
   //       to: [
   //          { value: '0', label: '0%' },
   //          { value: '10', label: '10%' },
   //          { value: '25', label: '25%' },
   //          { value: '50', label: '50%' },
   //          { value: '75', label: '75%' },
   //          { value: '90', label: '90%' },
   //          { value: '95', label: '95%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 1W (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 1M (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 3M (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 6M (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return YTD (%)',
   //    role: 'free',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 1Y (%)',
   //    role: 'premium',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 3Y (%)',
   //    role: 'premium',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   // {
   //    name: '',
   //    group: ['filters_all', 'total_return'],
   //    type: 'dropdown_range',
   //    label: 'Total Return 5Y (%)',
   //    role: 'premium',
   //    cols: 1,
   //    valueType: 'percent',
   //    rangeOptions: {
   //       from: [
   //          { value: '-100', label: '-100%' },
   //          { value: '-90', label: '-90%' },
   //          { value: '-80', label: '-80%' },
   //          { value: '-70', label: '-70%' },
   //          { value: '-60', label: '-60%' },
   //          { value: '-50', label: '-50%' },
   //          { value: '-40', label: '-40%' },
   //          { value: '-30', label: '-30%' },
   //          { value: '-20', label: '-20%' },
   //          { value: '-10', label: '-10%' },
   //          { value: '-5', label: '-5%' }
   //       ],
   //       to: [
   //          { value: '5', label: '5%' },
   //          { value: '10', label: '10%' },
   //          { value: '20', label: '20%' },
   //          { value: '30', label: '30%' },
   //          { value: '40', label: '40%' },
   //          { value: '50', label: '50%' },
   //          { value: '60', label: '60%' },
   //          { value: '70', label: '70%' },
   //          { value: '80', label: '80%' },
   //          { value: '90', label: '90%' },
   //          { value: '100', label: '100%' }
   //       ]
   //    }
   // },
   {
      name: 'divident_amount',
      group: ['filters_all', 'dividend', 'popular'],
      type: 'dropdown_range',
      label: 'Dividend Amount',
      role: 'free',
      cols: 1,
      valueType: 'number',
      rangeOptions: {
         from: [
            { value: '0', label: '0' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ],
         to: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
         ]
      }
   },
   {
      name: 'divident_freq',
      group: ['filters_all', 'dividend'],
      type: 'dropdown',
      label: 'Dividend Payment Frequency',
      role: 'free',
      multiple: true,
      filterResultFormatFn: (value: string) => {
         console.log(value);
         return dividendFrequencyOptions[value as keyof typeof dividendFrequencyOptions];
      },
      cols: 1,
      options: [
         { value: '0', label: 'Annually' },
         { value: '1', label: 'Monthly' },
         { value: '2', label: 'Quarterly' },
         { value: '3', label: 'Semi-Annually' },
         { value: '5', label: 'Bimonthly' },
         { value: '6', label: 'Trimesterly' },
         { value: '7', label: 'Weekly' },
         { value: '4', label: 'Other/Unknown' },
      ]
   },
   // {
   //     name: 'calculated_investment_segment',
   //     label: 'Invesment Segment',
   //     ex: "MID_CAP , SMALL_CAP , LARGE_CAP"
   // },
   // {
   //     name: 'domicile',
   //     label: 'Domicile',
   //     ex: "US , CA , GE"
   // },
   // {
   //     name: 'beta',
   //     label: 'beta',
   //     ex: ""
   // }
];
