import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-forecast-price-data-section',
  templateUrl: './forecast-price-data-section.component.html',
  styleUrls: ['./forecast-price-data-section.component.scss']
})
export class ForecastPriceDataSectionComponent implements OnInit {
   @Input() heading!: string
   @Input() currentYear!: string
   @Input() currentValue!: string
   @Input() nextYear!: string
   @Input() highValue!: string
   @Input() medianValue!: string
   @Input() lowValue!: string
   @Input() currency!: string
   parseFloat = parseFloat

  constructor() { }

  ngOnInit(): void {
  }

}
