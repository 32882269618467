<ng-container [formGroup]="form">
   <div class="p-top" formGroupName="goldAndSilver">
      <div class="form-input-wrapper">
         <label for="gold" class="form-label mb-2">Enter the value of Gold you hold:</label>
         <div class="d-flex align-items-center form-control-container">
            <span class="d-inline-block lh-1 currency">{{ selectedCurrency }}</span>
            <input type="text" inputmode="numeric" autocomplete="off" class="form-control bg-transparent ps-0 shadow-none" formControlName="goldAmount" id="gold" placeholder="" onlyNumbersAndDot>
         </div>
      </div>
      <div class="form-input-wrapper">
         <label for="silver" class="form-label mb-2">Enter the value of Silver you hold:</label>
         <div class="d-flex align-items-center form-control-container">
            <span class="d-inline-block lh-1 currency">{{ selectedCurrency }}</span>
            <input type="text" inputmode="numeric" autocomplete="off" class="form-control bg-transparent ps-0 shadow-none" formControlName="silverAmount" id="silver" placeholder="" onlyNumbersAndDot>
         </div>
      </div>
      <div class="d-flex align-items-start gap-2 step-info-wrapper">
         <svg class="flex-shrink-0 mt-1" width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 15C10.7833 15 11.0208 14.9042 11.2125 14.7125C11.4042 14.5208 11.5 14.2833 11.5 14V10C11.5 9.71667 11.4042 9.47917 11.2125 9.2875C11.0208 9.09583 10.7833 9 10.5 9C10.2167 9 9.97917 9.09583 9.7875 9.2875C9.59583 9.47917 9.5 9.71667 9.5 10V14C9.5 14.2833 9.59583 14.5208 9.7875 14.7125C9.97917 14.9042 10.2167 15 10.5 15ZM10.5 7C10.7833 7 11.0208 6.90417 11.2125 6.7125C11.4042 6.52083 11.5 6.28333 11.5 6C11.5 5.71667 11.4042 5.47917 11.2125 5.2875C11.0208 5.09583 10.7833 5 10.5 5C10.2167 5 9.97917 5.09583 9.7875 5.2875C9.59583 5.47917 9.5 5.71667 9.5 6C9.5 6.28333 9.59583 6.52083 9.7875 6.7125C9.97917 6.90417 10.2167 7 10.5 7ZM10.5 20C9.11667 20 7.81667 19.7375 6.6 19.2125C5.38333 18.6875 4.325 17.975 3.425 17.075C2.525 16.175 1.8125 15.1167 1.2875 13.9C0.7625 12.6833 0.5 11.3833 0.5 10C0.5 8.61667 0.7625 7.31667 1.2875 6.1C1.8125 4.88333 2.525 3.825 3.425 2.925C4.325 2.025 5.38333 1.3125 6.6 0.7875C7.81667 0.2625 9.11667 0 10.5 0C11.8833 0 13.1833 0.2625 14.4 0.7875C15.6167 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61667 20.5 10C20.5 11.3833 20.2375 12.6833 19.7125 13.9C19.1875 15.1167 18.475 16.175 17.575 17.075C16.675 17.975 15.6167 18.6875 14.4 19.2125C13.1833 19.7375 11.8833 20 10.5 20ZM10.5 18C12.7333 18 14.625 17.225 16.175 15.675C17.725 14.125 18.5 12.2333 18.5 10C18.5 7.76667 17.725 5.875 16.175 4.325C14.625 2.775 12.7333 2 10.5 2C8.26667 2 6.375 2.775 4.825 4.325C3.275 5.875 2.5 7.76667 2.5 10C2.5 12.2333 3.275 14.125 4.825 15.675C6.375 17.225 8.26667 18 10.5 18Z" fill="#96999E"/>
         </svg>
         <p class="mb-0 step-info-text">
            You would need to weigh the Zakatable Gold/Silver and calculate the value as per their current rate in your city.
         </p>
      </div>
   </div>
</ng-container>

