<div class="reset-pasword" *ngIf="showlist">
    <div class="container">
        <div class="model-liner">
            <div class="reset-password-logo">
                <img src="../../../../assets/images/tick1.svg" alt="" class="d-block mx-auto">
            </div>
            <div class="reset-pasword-text">
                <h2>Password changed!</h2>
                <p>
                    Your password has been
                    changed successfully.
                </p>
                <p></p>
                <button type="button" class="btn" (click)="goBack()">Back</button>
            </div>

        </div>
    </div>
</div>