<div class="charts__main">
    <div class="chart">
        <!-- <canvas class="mobile-chart" height="200" baseChart #lineChart [datasets]="lineChartData"
            [labels]="lineChartLabels" [options]="linerChartOptions" [legend]="true" [chartType]="'line'"
            [plugins]="lineChartPlugins">
        </canvas> -->
    </div>
    <div class="chart__statistic">

    </div>
</div>