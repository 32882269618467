<button
   class="btn shadow-none country-btn rounded-circle"
   (click)="openDefaultCountryModal()"
   [disabled]="userRole === 'ANONYMOUS'"
>
   <span
      class="d-block btn-image rounded-circle"
      [ngClass]="'fi-' + selectedCountry?.toLowerCase()"
   ></span>
</button>

<ng-template #defaultCountryModalContent let-modal>
   <div class="modal-header bg-light border-0 py-2 pe-1 ps-3">
      <h5 class="modal-title mb-0 fw-semibold" id="modal-basic-title">
         Set Default Country
      </h5>
      <button
         type="button"
         class="btn lh-1 p-2 shadow-none"
         (click)="closeDefaultCountryModal()"
      >
         <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            viewBox="0 0 384 512"
         >
            <path
               d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
         </svg>
      </button>
   </div>
   <div class="modal-body p-0">
      <div class="p-3 bg-white search-bar-container">
         <label for="countrySearch" class="form-label visually-hidden"></label>
         <input
            type="search"
            class="form-control py-2 shadow-none"
            id="countrySearch"
            [(ngModel)]="searchCountry"
            (input)="onCountryInputChange()"
            autocomplete="off"
            placeholder="Search Country"
         />
      </div>
      <div
         class="btn-group btn-group-vertical w-100 rounded-0 btn-group-toggle"
         ngbRadioGroup
         name="country"
         [(ngModel)]="selectedCountry"
         (change)="onCountryChange()"
         *ngIf="filteredCountries.covered.length > 0"
      >
         <p class="text-center mb-0 fw-bold p-3">Covered Countries</p>
         <label
            ngbButtonLabel
            class="btn d-flex align-items-center sortby-btn m-0 text-start rounded-0 shadow-none"
            *ngFor="let country of filteredCountries.covered"
         >
            <input ngbButton type="radio" [value]="country.countryCode" />
            <span
               class="country-image border rounded-circle me-2"
               [ngClass]="'fi-' + country.countryCode.toLowerCase()"
            ></span>
            <span>{{ country.country }}</span>
         </label>
      </div>

      <div
         class="btn-group btn-group-vertical w-100 rounded-0 btn-group-toggle"
         ngbRadioGroup
         name="country"
         [(ngModel)]="selectedCountry"
         (change)="onCountryChange()"
         *ngIf="filteredCountries.other.length > 0"
      >
         <p class="text-center mb-0 fw-bold p-3">Other Countries</p>
         <label
            ngbButtonLabel
            class="btn d-flex align-items-center sortby-btn m-0 text-start rounded-0 shadow-none"
            *ngFor="let country of filteredCountries.other"
         >
            <input ngbButton type="radio" [value]="country.countryCode" />
            <span
               class="country-image border rounded-circle me-2"
               [ngClass]="'fi-' + country.countryCode.toLowerCase()"
            ></span>
            <span>{{ country.country }}</span>
         </label>
      </div>
      <p
         class="text-center mb-0 fw-bold p-3"
         *ngIf="
            !filteredCountries.covered.length && !filteredCountries.other.length
         "
      >
         Country not found
      </p>
   </div>
</ng-template>
