import { Component, Input, OnInit } from '@angular/core';
import { SuperInvestor } from 'src/app/models/superInvestor.model';

@Component({
  selector: 'app-super-investor-short-info-card',
  templateUrl: './super-investor-short-info-card.component.html',
  styleUrls: ['./super-investor-short-info-card.component.scss']
})

export class SuperInvestorShortInfoCardComponent implements OnInit {
  @Input() investor!: SuperInvestor
  @Input() highlightSearch: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
