<ng-container *ngIf="isMobile">
   <section class="sort-offcanvas" [id]="offcanvasId" [class.open]="isOpen">
      <div class="container px-3 pb-3 content-container">
         <h6 class="h4 fw-bold mb-3">Sort by</h6>
         <div class="d-flex flex-column gap-2">
            <p class="mb-0 fw-bold" (click)="onPriceSortAsc()">
               Price: Low to High
            </p>
            <p class="mb-0 fw-bold" (click)="onPriceSortDesc()">
               Price: High to Low
            </p>
            <p class="mb-0 fw-bold" (click)="onChangeSortAsc()" *ngIf="showChangeSort">
               Change: Low to High
            </p>
            <p class="mb-0 fw-bold" (click)="onChangeSortDesc()" *ngIf="showChangeSort">
               Change: High to Low
            </p>
            <p class="mb-0 fw-bold" (click)="onComplianceSortAsc()" *ngIf="userRole === 'PREMIUM'">
               Compliance: Low to High
            </p>
            <p class="mb-0 fw-bold" (click)="onComplianceSortDesc()" *ngIf="userRole === 'PREMIUM'">
               Compliance: High to Low
            </p>
            <p class="d-flex align-items-center mb-0" (click)="onSortReset()">
               <img src="/assets/images/reset-icon.svg" alt="reset icon" class="img-fluid reset-icon me-2">
               <span class="fw-bold">Reset</span>
            </p>
         </div>
      </div>
   </section>
   <div class="backdrop-offcanvas" [class.openBackdrop]="isOpen" (click)="onCloseOffcanvas()"></div>
</ng-container>