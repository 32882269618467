import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { FirebaseRemoteConfigService } from '../../services/firebase-remote-config.service';
import { EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TypesenseService } from '../../services/typesense.service';
import { MarketUtilsService } from '../../services/market-utils.service';
import { UserApiService } from '../../services/user-api.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PaywallPopupComponent } from '../paywall-popup/paywall-popup.component';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';

interface showRatingsColumnData {
   isAnalystRatingActive: boolean;
   isInitialized: boolean;
}

@Component({
   selector: 'app-screener-filter',
   templateUrl: './screener-filter.component.html',
   styleUrls: ['./screener-filter.component.scss'],
})
export class ScreenerFilterComponent implements OnInit {
   @Input() showStockScreener: boolean = false;
   @Input() showEtfScreener: boolean = false;
   @Output() applyFilter: EventEmitter<any> = new EventEmitter();
   @Output() dismiss: EventEmitter<any> = new EventEmitter();
   @Input() passedCountryCode: any = null;
   @Input() filterResult: any = null;
   @Input() stocksTotalDataCount: any;
   @Input() etfTotalDataCount: any;
   @Output() closeModel = new EventEmitter<void>();
   @Output() showRatingsColumn = new EventEmitter<showRatingsColumnData>();
   isAnalystRatingActive: boolean = false;

   @Input() type: string = '';

   private subscriptions: Subscription[];
   userRole: string | null = null;

   exchangeList: any;
   sectorList: any;
   industryList: any;
   complianceList: any;
   scoreList: any;
   countryList: any;

   selectedSector: any = '';
   selectedCompliance: any = null;
   selectedCountry: any;

   etfClassList: any;
   etfComplianceList: any;

   industryCount: any;
   exchangeCount: any;

   etfClassCount: any;

   countriesData: any;
   sectorData: any;
   assetClassData: any;
   loading = true;
   showPrimary = true;
   analystRatingsList: any;
   sortByList: any;
   selectedSort: any = '';
   isMobile = false;
   coveredCountries: any;
   notCoveredCountries: any;
   firstNotCoveredMarket: string = '';
   paywallPopup: any;
   isInitialized: boolean = false;

   constructor(
      public router: Router,
      public remoteService: FirebaseRemoteConfigService,
      private auth: AuthService,
      private typseSenseService: TypesenseService,
      private marketUtilsService: MarketUtilsService,
      private userApiService: UserApiService,
      private observer: BreakpointObserver,
      private modalService: NgbModal,
      private analyticsUtils: AnalyticsUtils
   ) {
      this.subscriptions = [];
   }

   checkRole(role: string): boolean {
      return this.auth.checkRole(role);
   }

   closeMobileFilterModal() {
      this.closeModel.emit();
   }

   redirectToSignUpPage() {
      if (this.isMobile) {
         this.closeMobileFilterModal();
      }
      this.router.navigate(['/authentication/sso-login']);
   }

   async getSectorList() {
      var list = await this.sectorData;

      var data = [];

      var allItem = {
         key: '',
         display_value: 'All',
      };
      data.push(allItem);

      for (const obj of list) {
         var item = {
            key: obj.id,
            display_value: obj.musaffaSector,
         };
         data.push(item);
      }
      return data;
   }

   isIndustryEnabled() {
      return this.selectedSector && this.selectedSector.key;
   }

   async getIndustryList() {
      var data = [];

      for (const obj of this.sectorData) {
         if (this.selectedSector.key == obj.id) {
            data = obj.musaffaIndustries;
         }
      }

      var arr = [];
      for (const obj of data) {
         var item = {
            key: obj,
            display_value: obj,
         };
         arr.push(item);
      }

      return arr;
   }

   async updateIndustryList() {
      this.industryList = await this.getIndustryList();
   }

   async onComplianceChange(currentObj: any) {
      if (currentObj?.isAll == true) {
         if (currentObj.checked) {
            this.complianceList.forEach((item: any) => {
               item.checked = true;
            });
         } else {
            this.complianceList.forEach((item: any) => {
               item.checked = false;
            });
         }
         this.complianceList = this.complianceList;
      } else {
         // if all selected other than 'All' then select all
         let allObj = this.complianceList.find(
            (item: any) => item.isAll == true
         );
         let index = this.complianceList.indexOf(allObj);

         let checkedCountOtherThanAll = this.complianceList
            .filter((item: any) => item?.isAll == null || item?.isAll == false)
            .filter((item: any) => item.checked).length;
         if (checkedCountOtherThanAll >= this.complianceList.length - 1) {
            allObj['checked'] = true;
            this.complianceList[index] = allObj;
         } else {
            allObj['checked'] = false;
            this.complianceList[index] = allObj;
         }
      }

      this.updateComplianceScoreList();
   }
   async updateComplianceScoreList() {
      if (this.canEnableScore()) {
         this.scoreList = await this.remoteService.getComplianceScoreData();
      } else {
         this.scoreList = [];
      }

      // if(this.canEnableEtfScore()) {
      //   this.etfScoreList = await this.remoteService.getComplianceScoreData()
      // } else {
      //   this.etfScoreList = []
      // }
   }

   async onETFComplianceChange(currentObj: any) {
      if (currentObj?.isAll == true) {
         if (currentObj.checked) {
            this.etfComplianceList.forEach((item: any) => {
               item.checked = true;
            });
         } else {
            this.etfComplianceList.forEach((item: any) => {
               item.checked = false;
            });
         }
         this.etfComplianceList = this.etfComplianceList;
      } else {
         // if all selected other than 'All' then select all
         let allObj = this.etfComplianceList.find(
            (item: any) => item.isAll == true
         );
         let index = this.etfComplianceList.indexOf(allObj);

         let checkedCountOtherThanAll = this.etfComplianceList
            .filter((item: any) => item?.isAll == null || item?.isAll == false)
            .filter((item: any) => item.checked).length;
         if (checkedCountOtherThanAll >= this.etfComplianceList.length - 1) {
            allObj['checked'] = true;
            this.etfComplianceList[index] = allObj;
         } else {
            allObj['checked'] = false;
            this.etfComplianceList[index] = allObj;
         }
      }

      this.updateComplianceScoreList();
   }

   ngOnInit(): void {
      // console.log(this.showRatingsColumn);

      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );

      this.observer.observe('(max-width: 992px)').subscribe((result) => {
         this.isMobile = result.matches;
      });

      this.fetchData().then(() => {
         this.initData().then(() => {
            this.prefillFilterData();
            this.applyFilters(false);
         });
      });
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   async fetchData() {
      try {
         this.loading = true;
         this.countriesData = await this.marketUtilsService.fetchCountries();
         let sectorObj = await this.typseSenseService.getSectorIndustries();
         this.sectorData = sectorObj.hits.map((obj: any) => obj.document);

         let asset = await this.typseSenseService.getEtfClass();
         this.assetClassData = asset.hits.map((obj: any) => obj.document);

         this.preProcessCountryList();
         this.loading = false;
      } catch (e) {
         console.log(e);
      } finally {
         this.loading = false;
      }
   }

   preProcessCountryList() {
      let dataList: any = [];
      var coveredKey = '';
      var enabledKey = '';

      if (this.type === 'stock') {
         coveredKey = 'stocksCovered';
         enabledKey = 'stockEnabled';
      } else {
         coveredKey = 'etfsCovered';
         enabledKey = 'etfEnabled';
      }

      var enabledCountries = this.countriesData.filter(
         (obj: any) => obj[enabledKey]
      );

      var coveredList = enabledCountries
         .filter((obj: any) => obj[coveredKey])
         .sort((a: any, b: any) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
      var notCoveredList = enabledCountries
         .filter((obj: any) => !obj[coveredKey])
         .sort((a: any, b: any) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
      dataList = coveredList.concat(notCoveredList);
      this.firstNotCoveredMarket = notCoveredList[0]?.countryCode;
      let data = [];
      for (var obj of dataList) {
         var item = {
            key: obj.countryCode,
            display_value: obj.country,
         };
         data.push(item);
      }
      this.countryList = data;
   }

   getStocksExcahangeData() {
      var currentCountryObj = this.countriesData.find(
         (obj: any) => obj.countryCode == this.selectedCountry.key
      );

      var exchangeArr = [];
      if (this.type === 'stock') {
         exchangeArr = currentCountryObj.stockExchanges;
      } else exchangeArr = currentCountryObj.etfExchanges;

      let data = [];
      for (var obj of exchangeArr) {
         var item = {
            key: obj,
            display_value: obj,
         };
         data.push(item);
      }

      return data;
   }

   // getAnalyst(data: any) {
   //   this.showRatingsColumn.emit(data)
   // }

   prefillFilterData() {
      if (this.filterResult != null) {
         var previousFilter = this.filterResult!;

         if (
            previousFilter.exchange != null &&
            previousFilter.exchange.length
         ) {
            this.exchangeList = previousFilter.exchange;
         }

         if (previousFilter.country != null) {
            this.selectedCountry = this.countryList.find((item: any) => {
               return item.key == previousFilter.countryValue;
            });
         }
         if (previousFilter.sector != null) {
            this.selectedSector = this.sectorList.find((item: any) => {
               return item.key == previousFilter.sectorValue;
            });
         }

         if (previousFilter.industryValue != null) {
            this.industryList = previousFilter.industry;
         }

         if (
            previousFilter.compliance != null &&
            previousFilter.compliance.length
         ) {
            this.complianceList = previousFilter.compliance;
         }

         if (previousFilter.analyst != null && previousFilter.analyst.length) {
            this.analystRatingsList = previousFilter.analyst;
         }

         if (previousFilter.score != null) {
            this.scoreList = previousFilter.score;
         }

         if (previousFilter.sortBy != null) {
            this.selectedSort = this.sortByList.find((item: any) => {
               return item.key == previousFilter.sortValue;
            });
         }

         if (
            previousFilter.etfClass != null &&
            previousFilter.etfClass.length
         ) {
            this.etfClassList = previousFilter.etfClass;
         }
      }
   }
   async getDefaultCountry(code: any = null) {
      var selectedCountry = this.countryList[0];

      if (this.type == 'stock') {
         if (this.auth.isLoggedIn()) {
            var data: any = await this.marketUtilsService.fetchCountries();
            var allowedCountryCodes = data
               .filter(
                  (obj: any) =>
                     obj.stockEnabled &&
                     obj.stocksCovered &&
                     obj.detailedProfileAvailable
               )
               .map((obj: any) => obj.countryCode);

            var userObj = await this.userApiService.fetchUserDetails();
            var userCountry = userObj.geo_ip_country;

            if (userCountry) {
               var found = allowedCountryCodes.find(
                  (item: any) => item == userCountry
               );

               if (found) {
                  var found = this.countryList.find(
                     (item: any) =>
                        item.key.toLowerCase() == userCountry.toLowerCase()
                  );
                  if (found) selectedCountry = found;
               }
            }
         }
      }
      return selectedCountry;
   }

   public getEtfAssetClassData() {
      let data = [];
      for (var obj of this.assetClassData) {
         var item = {
            key: obj.id,
            display_value: obj.name,
         };
         data.push(item);
      }

      return data;
   }

   async initData() {
      this.selectedCountry = await this.getDefaultCountry();
      this.exchangeList = this.getStocksExcahangeData();

      this.sectorList = await this.getSectorList();
      this.selectedSector = this.sectorList[0];
      this.updateIndustryList();
      this.complianceList = await this.remoteService.getComplianceData();
      this.selectedCompliance = null;
      this.updateComplianceScoreList();
      this.etfClassList = await this.getEtfAssetClassData();
      this.etfComplianceList = await this.remoteService.getComplianceData();
      this.analystRatingsList = await this.remoteService.getAnalystRatings();
      this.sortByList = await this.remoteService.getSortBy();
      this.selectedSort = this.sortByList[0];
      this.showPrimary = true;
      sessionStorage.setItem('sector-list', JSON.stringify(this.sectorList));
   }

   async onCountryUpdate() {
      this.exchangeList = this.getStocksExcahangeData();
   }
   applyFilters(btnPressed: any, isReset = false) {
      var filters: any = {
         exchange: this.exchangeList,
         country: this.selectedCountry,
         sector: this.selectedSector,
         industry: this.industryList,
         // compliance: this.selectedCompliance,
         compliance: this.complianceList,
         score: this.scoreList,
         etfClass: this.etfClassList,
         etfCompliance: this.etfComplianceList,
         analyst: this.analystRatingsList,
         sortBy: this.selectedSort,
         showPrimary: this.showPrimary,
      };

      if (filters.exchange) {
         filters['exchangeValue'] = filters.exchange
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
      }
      if (filters.country) {
         filters['countryValue'] = filters.country.key;
      }
      if (filters.sector) {
         filters['sectorValue'] = filters.sector.key;
      }
      if (filters.sortBy) {
         filters['sortValue'] = filters.sortBy.key;
      }
      if (filters.analyst) {
         filters['analystValue'] = filters.analyst
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
         this.isAnalystRatingActive = filters['analystValue'].length
            ? true
            : false;
         this.showRatingsColumn.emit({
            isAnalystRatingActive: this.isAnalystRatingActive,
            isInitialized: this.isInitialized,
         });
      }
      if (filters.industry) {
         filters['industryValue'] = filters.industry
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
      }
      if (filters.compliance) {
         // filters['complianceValue'] = filters.compliance.key
         filters['complianceValue'] = filters.compliance
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
      }
      if (filters.etfCompliance) {
         filters['etfComplianceValue'] = filters.etfCompliance
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
      }
      if (filters.score) {
         filters['scoreValue'] = filters.score
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
      }

      if (filters.etfClass) {
         filters['etfClassValue'] = filters.etfClass
            .filter((obj: any) => obj.checked)
            .map((obj: any) => obj.key);
      }
      filters['isInit'] = !btnPressed;
      filters['isReset'] = isReset;

      this.applyFilter.emit(filters);
      if (btnPressed) this.dismiss.emit();
      this.isInitialized = true;
   }

   async reset() {
      await this.initData();
      this.applyFilters(true, true);
   }

   getListCount(list: any) {
      if (!list) return 0;
      var count = 0;
      list.forEach((item: any) => {
         if (item['checked']) {
            count += 1;
         }
      });
      return count;
   }
   industryChanged() {
      this.industryCount = 0;
      this.industryList.forEach((item: any) => {
         if (item['checked']) {
            this.industryCount = this.industryCount + 1;
         }
      });
   }

   exChanged() {
      this.exchangeCount = 0;
      this.exchangeList.forEach((item: any) => {
         if (item['checked']) {
            this.exchangeCount = this.exchangeCount + 1;
         }
      });
   }

   etfClassChanged() {
      this.etfClassCount = 0;
      this.etfClassList.forEach((item: any) => {
         if (item['checked']) {
            this.etfClassCount = this.etfClassCount + 1;
         }
      });
   }

   canEnableScore() {
      if (this.userRole !== 'PREMIUM') return false;
      else {
         let isHalal = false;
         let isOther = false;
         if (!this.complianceList) return false;
         this.complianceList.map((item: any) => {
            if (item.key === 'COMPLIANT' && item.checked) isHalal = true;
            if (item.key !== 'COMPLIANT' && item.checked) isOther = true;
         });
         return isHalal && !isOther;
      }
   }

   public beforeChange($event: NgbPanelChangeEvent) {
      if (
         $event.panelId === 'not-premium' ||
         $event.panelId === 'not-premium2'
      ) {
         $event.preventDefault();
      }
   }

   openPayWallModal() {
      this.closeMobileFilterModal();
      this.analyticsUtils.feature_upgrade_tapped('halal_ratings_filter');
      if (this.userRole === 'ANONYMOUS') {
         this.router.navigate(['/authentication/sso-login']);
      } else {
         this.paywallPopup = this.modalService.open(PaywallPopupComponent, {
            centered: true,
            modalDialogClass: 'paywall-popup-modal',
            size: 'xl',
         });
      }
   }

   // screenerStockFilterTapped(){
   //   this.analyticsUtils.Screener_Stock_Filter_Tapped()
   // }

   // screenerEtfFilterTapped(){
   //   this.analyticsUtils.Screener_ETF_Filter_Tapped()
   // }

   // canEnableEtfScore(){
   //   if(!this.checkRole('PREMIUM') )
   //     return true
   //   else{
   //     let isHalal = false
   //     let isOther = false
   //     if(!this.etfComplianceList)
   //       return false
   //     this.etfComplianceList.map((item:any)=>{
   //       console.log(item);

   //       if(item.key === 'COMPLIANT' && item.checked)
   //         isHalal = true
   //       if(item.key !== 'COMPLIANT' && item.checked)
   //         isOther = true
   //     })
   //     return isHalal && !isOther
   //   }

   // }
}
