import { Component, OnInit } from '@angular/core';
import { CreateListPopupComponent } from '../create-list-popup/create-list-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { PublicListsService } from 'src/app/shared/services/public-lists.service';
import { TypesenseService } from 'src/app/shared/services/typesense.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import {
   PublicList,
   PublicListHolding,
   PublicListResponse,
} from 'src/app/models/public-list/public-lists.model';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Store } from '@ngrx/store';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { AccessControlService } from 'src/app/shared/services/access-control.service';

@Component({
   selector: 'app-lists-section',
   templateUrl: './lists-section.component.html',
   styleUrls: ['./lists-section.component.scss'],
})
export class ListsSectionComponent implements OnInit {
   private destroy$ = new Subject<void>();
   listLoader!: boolean;
   allLists: PublicList[] = [];
   followedLists: PublicList[] = [];
   createdLists: PublicList[] = [];
   totalItems!: number;
   pageNo: number = 1;
   perPage: number = 10;
   sortColumn: string = 'followers_count';
   sortOrder: 'asc' | 'desc' | '' = 'desc';
   listType: string = '';
   listName: string = '';
   userRole: string | null = null;
   isCreateCollectionShow: boolean = false;
   isPopupShowClass: boolean = false;
   isLoggedIn: boolean = false;
   canReadPublicList = false;
   canCreatePublicList = false;
   symbols: string[] = [];

   constructor(
      private modalService: NgbModal,
      private route: ActivatedRoute,
      private listService: PublicListsService,
      private typesenseService: TypesenseService,
      private authService: AuthService,
      private router: Router,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService
   ) {}

   ngOnInit(): void {
      // SubscriptionService.subscriptionData
      //    .pipe(takeUntil(this.destroy$))
      //    .subscribe((data: SubscriptionInfoModel) => {
      //       this.userRole = data.role;
      //    });

      this.permissionStore.select(({permissions}) => permissions.permissions).subscribe((permissions) => {
         this.isLoggedIn = !!this.authService.getUserId();
         this.canReadPublicList = permissions.public_user_collections;
         this.canCreatePublicList = permissions.public_user_collections_create;
      })

      this.listService.popupVisible$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
         this.isCreateCollectionShow = value;
      });

      this.route.queryParams
         .pipe(takeUntil(this.destroy$))
         .subscribe((params) => {
            this.listType = params['lists'] || 'all';
            this.pageNo = +params['page'] || 1;
            this.symbols = params['symbols'] ? params['symbols'].split(',') : []
            
            this.loadLists( this.pageNo, this.sortColumn, this.sortOrder, this.listName, this.symbols );
         });

      this.listService.listReloadObservable$
         .pipe(takeUntil(this.destroy$))
         .subscribe(() => {
            this.loadLists( this.pageNo, this.sortColumn, this.sortOrder, this.listName, this.symbols);
         });
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async loadLists( page: number, sortColumn: string, sortOrder: string, listName: string, symbols: string[] ): Promise<void> {
      try {
         this.listLoader = true;
         const userId = this.authService.getUserId();

         if (!userId) {
            if (this.listType === 'all') {
               const response = await this.listService.getAllLists<PublicListResponse>( page, this.perPage, sortColumn, sortOrder, listName, symbols );
               this.handleResponse(response, 'allLists');
            } else {
               // User is not logged in and trying to access followed or created lists
               this.followedLists = [];
               this.createdLists = [];
            }
         } else {
            // User is logged in, call the appropriate API based on listType
            if (this.listType === 'all') {
               const response = await this.listService.getAllLists<PublicListResponse>( page, this.perPage, sortColumn, sortOrder, listName, symbols );
               this.handleResponse(response, 'allLists');
            } else if (this.listType === 'followed') {
               const response = await this.listService.getMyFollowedLists<PublicListResponse>( page, this.perPage, sortColumn, sortOrder, listName, symbols );
               this.handleResponse(response, 'followedLists');
            } else if (this.listType === 'created') {
               const response = await this.listService.getMyCreatedLists<PublicListResponse>( userId, page, this.perPage, sortColumn, sortOrder, listName, symbols );
               this.handleResponse(response, 'createdLists');
            }
         }
      } catch (error) {
         console.error(error);
      } finally {
         this.listLoader = false;

         if (this.isCreateCollectionShow) {
            setTimeout(() => {
               this.isPopupShowClass = true;
            }, 1000);
         }
      }
   }

   handleResponse(
      response: PublicListResponse,
      listType: 'allLists' | 'followedLists' | 'createdLists'
   ) {
      if (response && response.status) {
         const data = response.data;
         this[listType] = data.data;
         this.totalItems = data.total;

         for (const list of this[listType]) {
            const symbols = list.public_list_holdings.map(
               (holding: PublicListHolding) => holding.symbol
            );
            this.loadListStocksInfo(symbols, list);
            this.loadListEtfsInfo(symbols, list);
         }
      }
   }

   async loadListStocksInfo(symbols: string[], list: PublicList) {
      try {
         const Listholdings =
            await this.typesenseService.getStocksComplianceInfo(symbols);
         list.public_list_holdings.forEach((holding) => {
            const matchingHolding = Listholdings.find(
               (stock: PublicListHolding) => stock.symbol === holding.symbol
            );
            if (matchingHolding) {
               holding.logo_url = matchingHolding.logo;
               holding.holding_name = matchingHolding.name;
            }
         });
      } catch (error) {
         console.error(error);
      }
   }

   async loadListEtfsInfo(symbols: string[], list: PublicList) {
      try {
         if(symbols.length === 0) return
         const Listholdings = await this.typesenseService.getEtfsComplianceInfo(
            symbols
         );
         list.public_list_holdings.forEach((holding) => {
            const matchingHolding = Listholdings.find(
               (stock: PublicListHolding) => stock.symbol === holding.symbol
            );
            if (matchingHolding) {
               holding.logo_url = matchingHolding.logo;
               holding.holding_name = matchingHolding.name;
            }
         });
      } catch (error) {
         console.error(error);
      }
   }

   sortBy(column: string, sortOrder?: string) {
      if (column !== this.sortColumn) {
         this.resetSorting();
      }

      this.sortColumn = column;

      if (sortOrder === 'asc') {
         this.sortOrder = 'asc';
      } else if (sortOrder === 'desc') {
         this.sortOrder = 'desc';
      } else {
         if (this.sortOrder === 'asc') {
            this.sortOrder = 'desc';
         } else if (this.sortOrder === 'desc') {
            this.sortOrder = '';
         } else {
            this.sortOrder = 'asc';
         }
      }

      this.pageNo = 1;
      this.loadLists( this.pageNo, this.sortColumn, this.sortOrder, this.listName, this.symbols );
   }

   resetSorting() {
      this.sortOrder = '';
      this.sortColumn = '';
   }

   searchBy(name: string) {
      if (this.listName !== name) {
         this.listName = name;
         this.pageNo = 1;
         this.loadLists( this.pageNo, this.sortColumn, this.sortOrder, this.listName, this.symbols );
      }
   }

   closeCreateCollection() {
      this.listService.hidePopup();
   }

   showCreateCollection() {
      this.listService.showPopup();
   }

   openCreateListPopup() {
      if(this.canCreatePublicList) {
         const modalRef = this.modalService.open(CreateListPopupComponent, {
            size: 'lg',
         });
         modalRef.componentInstance.data = {
            name: 'create-list',
         };
      } else {
         this.accessControlService.accessDenied();
      }
   }

   openListPopupActions() {
      if (this.userRole === 'ANONYMOUS') {
         this.router.navigate(['/authentication/login/']);
         return;
      }

      this.closeCreateCollection();
      this.openCreateListPopup();
   }
}
