import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-purification-card',
  templateUrl: './purification-card.component.html',
  styleUrls: ['./purification-card.component.scss']
})
export class PurificationCardComponent implements OnInit {
  @Input() stockName!: string;
  @Input() stockTicker!: string;
  @Input() complianceStatus!: string

  constructor() { }

  ngOnInit(): void {
  }

}
