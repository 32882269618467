import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LogOutFreeServices {

   LogOutFreeServices: EventEmitter<boolean>

   constructor() {
     this.LogOutFreeServices = new EventEmitter()
   }

   show() {
     this.LogOutFreeServices.emit(true)
   }

   hide() {
     this.LogOutFreeServices.emit(false)
   }


 }
