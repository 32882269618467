<div class="br-10 stock-table-container">
   <h3 class="table-title mb-0">{{ title }}</h3>

   <div class="px-2" *ngIf="loading">
      <ngx-skeleton-loader
         count="8" appearance="line" animation="progress"
         [theme]="{ height: '50px', width: '100%', 'border-radius': '8px'}">
      </ngx-skeleton-loader>
   </div>

   <div class="table-responsive" *ngIf="!loading">
      <table class="table mb-0">
         <thead>
            <tr>
               <th class="text-uppercase fw-normal" scope="col"></th>
               <th class="text-uppercase fw-normal" scope="col">Name</th>
               <th class="text-uppercase fw-normal text-end" scope="col">Price & 1D CHG</th>
               <th class="text-uppercase fw-normal text-center" scope="col">Compliance</th>
            </tr>
         </thead>
         <tbody>
            <ng-container *ngIf="data && data.length > 0">
               <tr *ngFor="let etf of filtereredStocks(); let i = index; trackBy: trackByStockId">
                  <td class="align-middle p-0">
                     <app-watchlist-button [etf]="etf"></app-watchlist-button>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center gap-2 text-decoration-none stock-image-and-name" routerLink="/etf/{{etf.symbol}}">
                        <div class="stock-image-wrapper border flex-shrink-0 bg-light rounded-circle overflow-hidden">
                           <img [appLazyLoadImage]="etf.logo" placeholder="/assets/images/dashboard/stock-lowres.png" [alt]="etf.name" class="img-fluid" *ngIf="etf.logo">
                           <div *ngIf="!etf.logo" class="d-flex h-100 text-black align-items-center justify-content-center fs-5 fw-bold">
                              {{ etf?.name?.charAt(0)}}
                           </div>
                        </div>
                        <div class="d-block">
                           <h5 class="mb-0 line-clamp-1 stock-name">{{ etf.name }}</h5>
                           <p class="mb-0 line-clamp-1 stock-symbol">{{ etf.symbol }}</p>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-price-and-change" routerLink="/etf/{{etf.symbol}}">
                        <div class="d-grid">
                           <span class="stock-price text-end">
                              {{ (etf.price ? etf.price : etf.lastPrice) | sumPipe: (etf.navCurrency || '') }}
                           </span>
                           <!-- <ng-template #nolastClosingPrice>-</ng-template> -->

                           <div class="stock-change d-flex align-items-center justify-content-end gap-1" *ngIf="etf.priceChangePercent; else noPercentChange" [ngClass]="getPercentChangeClasses(etf)">
                              <i class="bi" [ngClass]="getIconClasses(etf)"></i>
                              <span> {{ etf.priceChangePercent | abs | number : "1.2-2" }}% </span>
                           </div>
                           <ng-template #noPercentChange>-</ng-template>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle p-0">
                     <a class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-compliance" routerLink="/etf/{{etf.symbol}}">
                        <app-compliance-chip
                           [showCompliance]="i < 3"
                           [symbol]="etf.symbol"
                           [complianceStatus]="etf.shariahStates"
                           [complianceRanking]="etf.ranking"
                           [complianceRankingV2]="etf.ranking_v2">
                        </app-compliance-chip>
                     </a>
                  </td>
               </tr>
               <tr class="lock-overlay"  *ngIf="!isLoggedIn && data.length > 3">
                  <td colspan="4"class="align-middle">
                     <button (click)="accessControlService.accessDenied();" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid">
                        <span>Sign-up & Upgrade to Unlock!</span>
                     </button>
                  </td>
               </tr>
               <tr class="lock-overlay" *ngIf="isLoggedIn && !canReadTrendingStocks && data.length > 3">
                  <td colspan="4"class="align-middle">
                     <button (click)="accessControlService.accessDenied();" class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" >
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid">
                        <span>Upgrade & Unlock!</span>
                     </button>
                  </td>
               </tr>
               <!-- <tr class="lock-overlay" *ngIf="userRole === 'ANONYMOUS' && data.length > 3">
                  <td colspan="4"class="align-middle">
                     <button class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn" [routerLink]="['/authentication/sso-login']">
                        <img src="/assets/icons/lock-black.svg" alt="lock image" class="img-fluid">
                        <span>Sign up for free & unlock!</span>
                     </button>
                  </td>
               </tr> -->
            </ng-container>
            <ng-container *ngIf="!loading && (!data || data.length === 0)">
               <tr class="nostocks-tr">
                  <td colspan="4" class="align-middle">
                     <p class="mb-0 d-flex justify-content-center align-items-center">No stocks available for the selected country</p>
                  </td>
               </tr>
            </ng-container>
         </tbody>
      </table>
    </div>
</div>

