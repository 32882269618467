import {
   Component,
   ElementRef,
   Input,
   OnChanges,
   OnInit,
   ViewChild,
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { screaningMillionPipe } from '../../pipes/screaning-million.pipe';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
   selector: 'app-purification-options',
   templateUrl: './purification-options.component.html',
   styleUrls: ['./purification-options.component.scss'],
})
export class PurificationOptionsComponent implements OnInit, OnChanges {
   public currency!: string;
   public isCollapsed1 = true;
   public isCollapsed2 = true;
   public isCollapsed3 = true;
   @Input() data?: any;
   @Input() companyProfile?: any;
   active = 1;
   dividendData: any;
   interestData: any;
   notHalalData: any;

   private subscriptions: Subscription[];
   userRole: string | null = null;
   @Input() isSample: boolean = false;
   dividendIncomeText: string =
      'This purification calculation considers these aspects: CONSIDERS earnings from Haram Revenue. DOES NOT considers non-dividend stocks. DOES NOT considers the period of time shares are held. DOES NOT considers Interest Income.';
   interestIncomeText: string =
      'This purification calculation considers these aspects: CONSIDERS non-dividend stocks. CONSIDERS the period of time shares are held. Considers Interest Income. DOES NOT CONSIDER earnings from Haram Revenue';
   haramIncomeText: string =
      'This purification calculation considers these aspects: CONSIDERS non-dividend stocks. CONSIDERS the period of time shares are held. Considers Interest Income. CONSIDERS earnings from Haram Revenue';
   @ViewChild('wrapper1') wrapper1!: ElementRef;
   @ViewChild('wrapper2') wrapper2!: ElementRef;
   @ViewChild('wrapper3') wrapper3!: ElementRef;
   @Input() showSampleBtn: boolean = false;

   constructor(
      private apiService: ApiService,
      private screaningMillionPipe: screaningMillionPipe,
      private decimalPipe: DecimalPipe
   ) {
      this.subscriptions = [];
   }

   ngOnInit(): void {
      this.subscriptions.push(
         SubscriptionService.subscriptionData.subscribe(
            (data: SubscriptionInfoModel) => {
               this.userRole = data.role;
            }
         )
      );
      if (this.isSample) {
         this.isCollapsed1 = false;
         this.isCollapsed2 = false;
         this.isCollapsed3 = false;
      }
      this.init();
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   init() {
      this.processDividendData();
      this.processInterestData();
      this.processNotHalalData();
   }

   ngOnChanges(): void {
      this.init();
   }

   onMouseEnter(hoveredWrapper: string) {
      this.toggleVisibility(hoveredWrapper, 'hidden');
   }

   onMouseLeave() {
      this.showAllDivs();
   }

   toggleCollaps() {
      this.isCollapsed3 = !this.isCollapsed3;
   }

   toggleVisibility(hoveredWrapper: string, visibility: string) {
      if (hoveredWrapper === 'wrapper-1') {
         this.wrapper1.nativeElement.style.visibility = 'visible';
         this.wrapper2.nativeElement.style.visibility = visibility;
         this.wrapper3.nativeElement.style.visibility = visibility;
      } else if (hoveredWrapper === 'wrapper-3') {
         this.wrapper1.nativeElement.style.visibility = visibility;
         this.wrapper2.nativeElement.style.visibility = visibility;
         this.wrapper3.nativeElement.style.visibility = 'visible';
      }
   }

   showAllDivs() {
      this.wrapper1.nativeElement.style.visibility = 'visible';
      this.wrapper2.nativeElement.style.visibility = 'visible';
      this.wrapper3.nativeElement.style.visibility = 'visible';
   }

   async initDummyData() {
      // this.data = await this.apiService.getPurificationCalculation();
      // console.log(this.data);
      this.data = {
         buyDate: '2022-01-01',
         currency: 'USD',
         dividend: {
            nonCompliantRevenue: 61013000000.0,
            purification: [
               {
                  adjusted_amount: 0.22,
                  purificationAmount: 2.067139759788964e-12,
                  record_date: '2022-02-07',
               },
               {
                  adjusted_amount: 0.23,
                  purificationAmount: 2.1611006579611894e-12,
                  record_date: '2022-05-09',
               },
               {
                  adjusted_amount: 0.23,
                  purificationAmount: 2.1611006579611894e-12,
                  record_date: '2022-08-08',
               },
            ],
            purificationAmount: 6.389341075711343e-12,
            totalRevenue: 425709000000.0,
         },
         haram: {
            entireAccountingPeriod: 98,
            haramIncome: 61013000000.0,
            purificationAmount: 21.152000611579474,
            reportType: 'secondQuarter',
            totalSharesOutstanding: 16070800000.0,
         },
         interest: {
            entireAccountingPeriod: 98,
            interestIncome: 2072000000.0,
            purificationAmount: 0.718321427682505,
            reportType: 'secondQuarter',
            totalSharesOutstanding: 16070800000.0,
         },
         numberOfShares: 2,
         sellDate: '2022-10-01',
         sharesHeldDays: 273,
         symbol: 'AAPL',
      };
   }

   processDividendData() {
      var line1Val: any;
      var line3Val;
      try {
         var value =
            (this.data?.dividend.nonCompliantRevenue /
               this.data?.dividend.totalRevenue) *
            100;
         if (value == null || isNaN(value)) line1Val = '--';
         else line1Val = this.decimalPipe.transform(value, '1.2-2') + '%';
      } catch (e) {
         line1Val = '--';
      }

      try {
         var sum = 0;
         for (const obj of this.data.dividend.purification) {
            sum += obj.adjusted_amount;
         }

         line3Val = this.getPriceWithCurrency(sum);
      } catch (e) {
         line3Val = this.getPriceWithCurrency(null);
      }

      this.dividendData = {
         line1Val: line1Val,
         line2Val: this.data?.numberOfShares ?? '--',
         line3Val: line3Val,
         purification: this.getPriceWithCurrency(
            this.data?.dividend.purificationAmount
         ),
      };
   }

   processInterestData() {
      var line1Val;
      var line3Val: any;
      try {
         line1Val = this.getPriceWithCurrency(
            this.data?.interest.interestIncome /
               this.data?.interest.totalSharesOutstanding
         );
      } catch (e) {}
      try {
         line3Val = (
            this.data?.sharesHeldDays /
            this.data?.interest.entireAccountingPeriod
         ).toFixed(2);
         if (isNaN(line3Val)) line3Val = '--';
      } catch (e) {
         line3Val = '--';
      }
      this.interestData = {
         line1Val: line1Val,
         line2Val: this.data?.numberOfShares ?? '--',
         line3Val: line3Val,
         purification: this.getPriceWithCurrency(
            this.data?.interest.purificationAmount
         ),
      };
   }

   processNotHalalData() {
      var line1Val;
      var line3Val: any;

      try {
         line1Val = this.getPriceWithCurrency(
            this.data?.haram.haramIncome /
               this.data?.haram.totalSharesOutstanding
         );
      } catch (e) {}
      try {
         line3Val = (
            this.data?.sharesHeldDays / this.data?.haram.entireAccountingPeriod
         ).toFixed(2);
         if (isNaN(line3Val)) line3Val = '--';
      } catch (e) {}

      this.notHalalData = {
         line1Val: line1Val,
         line2Val: this.data?.numberOfShares ?? '--',
         line3Val: line3Val,
         purification: this.getPriceWithCurrency(
            this.data?.haram.purificationAmount
         ),
      };
   }

   getPriceWithCurrency(amount: any) {
      if (
         this.getCurrency() == null &&
         (amount == null || amount == undefined || isNaN(amount))
      )
         return '--';
      else if (amount == null || amount == undefined || isNaN(amount)) {
         return this.getCurrency() + ' --';
      } else
         return (
            this.getCurrency() +
            ' ' +
            this.decimalPipe.transform(amount, '1.2-2')
         );

      // return this.data?.currency +" "+this.screaningMillionPipe.transform(value)
   }

   getPrice(amount: any) {
      if (amount == null || isNaN(amount)) return '--';
      return (
         this.apiService.selectedCurrency +
         ' ' +
         this.decimalPipe.transform(amount, '1.2-2')
      );

      // return this.data?.currency +" "+this.screaningMillionPipe.transform(value)
   }

   getCurrency() {
      return this.data?.currency ?? '';
      return this.companyProfile?.currency;
   }

   getSelectedCurrency() {
      return this.apiService.selectedCurrency;
   }
}
