<header
   class="d-flex align-items-center flex-wrap gap-2 position-relative"
   *ngIf="!componentLoading && !(loading$ | async); else headerLoader"
>
   <h2
      class="mb-0 fw-600 d-flex align-items-center gap-3 select-account-dropdown-header"
   >
      <div
         class="d-flex align-items-center justify-content-between gap-3 w-100 cursor-pointer"
         (click)="toggleDropdown()"
      >
         <span>{{ (selectedAccount$ | async)?.name }}</span>
         <button class="btn fw-500 shadow-none rounded-3 view-portfolio-btn">
            <i class="fa fa-chevron-down arrow-icon"></i>
         </button>
      </div>

      <div
         class="dropdown-content"
         *ngIf="dropdownOpen"
         [@slideToggleAnimation]
      >
         <ul>
            <div class="accounts-list">
               <li
                  *ngFor="let account of accounts$ | async"
                  (click)="selectAccount(account.id)"
               >
                  <h6>{{ account.name }}</h6>
                  <!-- <span class="badge" [ngClass]="{'badge-linked': !account.is_manual, 'badge-manual': account.is_manual}"> -->
                  <p class="account-type" *ngIf="account?.is_sample">
                     Sample Account
                  </p>
                  <div class="account-type" *ngIf="!account?.is_sample">
                     <p
                        class="account-type"
                        *ngIf="
                           account?.brokerage_connection_status === 'CONNECTED'
                        "
                     >
                        {{ account?.is_manual ? "Manual" : "Brokerage Linked" }}
                     </p>
                     <p
                        class="account-type"
                        *ngIf="
                           account?.brokerage_connection_status === 'BROKEN'
                        "
                     >
                        Brokerage Connection Broken
                     </p>
                  </div>
                  <!-- </span> -->
                  <!-- <p>2 Portfolios</p> -->
               </li>
            </div>
            <li>
               <button class="btn_grey_add" (click)="openConnectionModal()">
                  Connect New Portfolio
               </button>
            </li>
            <li>
               <button class="btn_grey_add" (click)="addManually()">
                  Add Manually
               </button>
            </li>
         </ul>
      </div>
   </h2>

   <div
      placement="top"
      ngbTooltip="Sync Portfolio"
      (click)="syncCurrentPortfolio()"
      class="btn setting-btn rounded-circle shadow-none icon-sync-btn cursor-pointer"
      style="background-color: #f7f6f4"
      *ngIf="!hideSync"
   >
   <svg
         fill="#898888"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 512 512"
         [ngClass]="{ 'rotate-icon': syncLoader }"
   >
         <path
            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
         />
   </svg>
   </div>
   <div (click)="openPortfolioSetting()" class="cursor-pointer" *ngIf="!hideSettings">
      <svg
         class="setting-btn"
         viewBox="0 0 50 51"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <rect y="0.5" width="50" height="50" rx="25" fill="#F7F6F4" />
         <path
            d="M35.288 27.2144C35.3841 26.6413 35.4327 26.0667 35.4327 25.5002C35.4327 24.9336 35.3841 24.359 35.288 23.7858L36.717 21.9698C36.9899 21.623 37.0242 21.1451 36.8035 20.7629L35.005 17.6478C34.7844 17.2656 34.3534 17.0563 33.9165 17.1192L31.6199 17.4501C30.7232 16.7091 29.7307 16.133 28.6587 15.7313L27.8018 13.5888C27.6379 13.1793 27.2412 12.9106 26.7999 12.9106H23.2029C22.7616 12.9106 22.3649 13.1793 22.201 13.589L21.3441 15.7314C20.2722 16.1331 19.2797 16.7092 18.3829 17.4502L16.0862 17.1193C15.6494 17.0563 15.2184 17.2657 14.9978 17.6478L13.1993 20.763C12.9786 21.1451 13.0129 21.6231 13.2858 21.9698L14.7148 23.7859C14.6187 24.3591 14.5701 24.9337 14.5701 25.5002C14.5701 26.0666 14.6187 26.6412 14.7148 27.2144L13.2858 29.0305C13.013 29.3772 12.9786 29.8552 13.1994 30.2373L14.9979 33.3525C15.2184 33.7346 15.6493 33.9441 16.0862 33.881L18.3829 33.5501C19.2797 34.2912 20.2721 34.8673 21.3441 35.269L22.2011 37.4114C22.3649 37.8211 22.7616 38.0897 23.2029 38.0897H26.7999C27.2412 38.0897 27.6379 37.8211 27.8018 37.4114L28.6588 35.2689C29.7308 34.8673 30.7232 34.2911 31.62 33.55L33.9167 33.881C34.3533 33.9439 34.7845 33.7346 35.005 33.3524L36.8036 30.2373C37.0242 29.8551 36.9899 29.3772 36.7171 29.0304L35.288 27.2144ZM25.0014 30.536C22.2247 30.536 19.9656 28.2769 19.9656 25.5002C19.9656 22.7233 22.2247 20.4644 25.0014 20.4644C27.7782 20.4644 30.0372 22.7234 30.0372 25.5002C30.0372 28.277 27.7782 30.536 25.0014 30.536Z"
            fill="#898888"
         />
      </svg>
   </div>
   <button
      class="btn fw-500 btn__add__portfolio"
      (click)="openConnectionModal()"
      *ngIf="!hideAddPortfolio"
   >
      <i class="fa fa-plus arrow-icon"></i>
      <span>Add Portfolio</span>
   </button>
   <button
      [disabled]="reconnectLoader"
      *ngIf="accountDetails?.brokerage_connection_status === 'BROKEN'"
      (click)="reconnectBrokerage()"
      class="d-flex gap-2 align-item-center shadow-none mb-0 border-0 bg-transparent"
   >
      <svg
         *ngIf="!reconnectLoader"
         width="20"
         height="21"
         viewBox="0 0 20 21"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.3936 3.71039C12.5034 3.84527 11.7804 4.2804 11.5303 4.53045L10.5607 5.50013L15 9.9395L15.9697 8.9698C16.2197 8.7197 16.6548 7.99669 16.7897 7.10651C16.9186 6.25561 16.7652 5.32599 15.9697 4.53046C15.1741 3.73492 14.2445 3.58147 13.3936 3.71039ZM13.1689 2.22732C14.3805 2.04374 15.8259 2.26532 17.0303 3.4698C18.2348 4.67427 18.4564 6.11962 18.2728 7.33122C18.0952 8.5035 17.5303 9.5305 17.0303 10.0304L15.5303 11.5305C15.2374 11.8234 14.7626 11.8234 14.4697 11.5305L8.9697 6.03046C8.829 5.88981 8.75 5.69904 8.75 5.50013C8.75 5.30122 8.829 5.11045 8.9697 4.9698L10.4697 3.46981C10.9696 2.96982 11.9966 2.40493 13.1689 2.22732Z"
            fill="#E9291D"
         />
         <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.5303 0.969668C19.8232 1.26256 19.8232 1.73744 19.5303 2.03033L17.0303 4.53033C16.7374 4.82322 16.2626 4.82335 15.9697 4.53046C15.6768 4.23757 15.6768 3.76256 15.9697 3.46967L18.4697 0.969668C18.7626 0.676777 19.2374 0.676777 19.5303 0.969668Z"
            fill="#E9291D"
         />
         <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.99962 9.25C5.19853 9.25 5.3893 9.329 5.52995 9.4697L11.0299 14.9697C11.3228 15.2626 11.3228 15.7374 11.0299 16.0303L9.53 17.5303C9.03 18.0303 8.003 18.5952 6.83072 18.7728C5.61912 18.9564 4.17376 18.7348 2.96929 17.5303M2.96929 17.5303C1.76482 16.3259 1.54326 14.8805 1.72683 13.6689C1.90445 12.4966 2.46933 11.4697 2.96927 10.9697L4.46929 9.4697C4.60994 9.329 4.80071 9.25 4.99962 9.25M4.99962 11.0607L4.02997 12.0303C3.77991 12.2804 3.34478 13.0034 3.2099 13.8936C3.08098 14.7445 3.23442 15.6741 4.02995 16.4697C4.82548 17.2652 5.75512 17.4187 6.60601 17.2898C7.49622 17.1549 8.2192 16.7197 8.4693 16.4697L9.439 15.5L4.99962 11.0607Z"
            fill="#E9291D"
         />
         <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0303 11.9697C12.3232 12.2626 12.3232 12.7374 12.0303 13.0303L10.0303 15.0303C9.7374 15.3232 9.2626 15.3232 8.9697 15.0303C8.6768 14.7374 8.6768 14.2626 8.9697 13.9697L10.9697 11.9697C11.2626 11.6768 11.7374 11.6768 12.0303 11.9697Z"
            fill="#E9291D"
         />
         <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.02995 16.4697C4.32284 16.7626 4.32322 17.2374 4.03033 17.5303L1.53033 20.0303C1.23744 20.3232 0.762558 20.3232 0.469668 20.0303C0.176777 19.7374 0.176777 19.2626 0.469668 18.9697L2.96967 16.4697C3.26256 16.1768 3.73706 16.1768 4.02995 16.4697Z"
            fill="#E9291D"
         />
         <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.5303 8.46967C8.8232 8.7626 8.8232 9.2374 8.5303 9.5303L6.53033 11.5303C6.23744 11.8232 5.76256 11.8232 5.46967 11.5303C5.17678 11.2374 5.17678 10.7626 5.46967 10.4697L7.46967 8.46967C7.76256 8.17678 8.2374 8.17678 8.5303 8.46967Z"
            fill="#E9291D"
         />
      </svg>
      <span *ngIf="!reconnectLoader" class="reconnect-text"
         >Reconnection Required</span
      >
      <div
         style="
            border: 0.25em solid #e9291d;
            border-right-color: transparent;
            vertical-align: 0em;
            margin-top: 3px;
         "
         *ngIf="reconnectLoader"
         class="spinner-border spinner-border-sm red-loader"
         role="status"
      >
         <span class="visually-hidden">Loading...</span>
      </div>
      <span *ngIf="reconnectLoader" class="reconnect-text"
         >Reconnecting...</span
      >
   </button>
   <a *ngIf="showGoToAnalytics()" routerLink="/portfolio-dashboard" class="all_news_link desktop_only">Detailed Analytics</a>
</header>

<ng-container
   *ngIf="!componentLoading && !(loading$ | async); else valueLoader"
>
   <div class="portfolio__value" *ngIf="!hideValue">
      <div>
         Portfolio Value:
         <span>{{
            accountDetails?.portfolio_value
               | currency : accountDetails?.currency
         }}</span>
      </div>
      <span
         [ngClass]="{
            green_color: (accountDetails?.total_gain_in_amount ?? 0) >= 0,
            red_color: (accountDetails?.total_gain_in_amount ?? 0) < 0
         }"
      >
         {{ (accountDetails?.total_gain_in_amount ?? 0) > 0 ? "+" : "" }}
         {{
            accountDetails?.total_gain_in_amount
               | currency : accountDetails?.currency
         }}
         ({{ accountDetails?.total_gain_in_percent | number : "1.2-2" }}%)
      </span>
   </div>
   <p class="mb-0 mt-2 last-updated-text" *ngIf="!hideSync">
      <span class="fw-500">Last Updated: </span>
      <span>{{
         accountDetails?.last_sync_at
            ? (accountDetails?.last_sync_at | date : "medium")
            : "Never Synced"
      }}</span>
      <!-- · <button class="btn fw-500 text-decoration-underline lh-1 p-0 shadow-none" (click)="syncCurrentPortfolio()">
         {{ syncLoader ?'Syncing...' : 'Sync Now' }}
      </button> -->
   </p>
   <div *ngIf="hideValue || hideSync" class="py-2"></div>
</ng-container>

<ng-template #headerLoader>
   <div class="d-flex align-items-center gap-2">
      <ngx-skeleton-loader
         count="1"
         appearance="line"
         animation="progress"
         [theme]="{ height: '40px', width: '500px', margin: '0 0 10px 0' }"
      >
      </ngx-skeleton-loader>
   </div>
</ng-template>
<ng-template #valueLoader>
   <div class="d-flex align-items-center gap-2">
      <ngx-skeleton-loader
         count="1"
         appearance="line"
         animation="progress"
         [theme]="{ height: '28px', width: '450px', margin: '0px' }"
      >
      </ngx-skeleton-loader>
   </div>
</ng-template>
