<div class="otp-verification-container d-flex align-items-center gap-2">
  <!-- <input
    type="text"
    [value]="inputValue"
    (input)="onInputChange($event)"
    class="form-control"
    [attr.aria-label]="'Input field' + (isVerified ? ' (verified)' : '')"
  > -->

  <ng-container *ngIf="isVerified; else verifyButton">
    <span class="badge rounded-pill bg-light">
      <i class="bi bi-check-circle text-success"></i>
      <!-- Verified -->
    </span>
  </ng-container>

  <ng-template #verifyButton>
    <button [disabled]="disabled || isVerifying || isValidating" class="verify-otp-btn" (click)="sendOtpClick()"
      type="button">
      <span *ngIf="isVerifying || isValidating || isSending" class="spinner-border spinner-border-sm me-1"></span>
      <span *ngIf="!isVerifying && !isValidating && !isSending">Verify</span>
    </button>
  </ng-template>
</div>

<!-- OTP Verification Modal -->
<ng-template #otpModal let-modal>
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Verify {{ verificationType === 'email' ? 'Email' : 'Mobile No.' }}
    </h4>

  </div> -->
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  <div class="modal-body otp__modal__body">
    <h5>Enter the 4-digit code we sent you on <span>{{ verificationType === 'email' ? 'Email' : 'Mobile No.' }}</span>
    </h5>
    <p>Confirm your {{verificationType === 'email' ? 'email' : 'mobile number'}} <span>{{inputValue}}</span>. By
      checking that it is really you, we protect your
      account.</p>
    <div class="mb-3">
      <label for="otp-input" class="form-label">Enter the code</label>
      <!-- <input type="number" class="form-control" id="otp-input" [(ngModel)]="otpValue" placeholder="Enter OTP"
        maxlength="6" pattern="[0-9]*" inputmode="numeric"> -->
      <ng-otp-input [config]="otpConfig" (onInputChange)="onOtpChange($event)"></ng-otp-input>

      <div *ngIf="invalidOtp" class="invalid-feedback">Invalid OTP</div>
    </div>

    <div *ngIf="isResendDisabled" class="otp__timer">
      Resend <span>(after {{ timer }} sec)</span>
    </div>
    <div *ngIf="!isResendDisabled" class="otp__timer cursor-pointer" (click)="resendOtpClick()">Resend OTP</div>

  </div>
  <div class="modal_footer">
    <!-- <button
      type="button"
      class="btn btn-light"
      (click)="modal.dismiss()"
    >
      Cancel
    </button> -->
    <button type="button" class="modal-action-btn" (click)="verifyOtpClick()"
      [disabled]="otpValue.length !== otpConfig.length">
      <span *ngIf="isVerifying" class="spinner-border spinner-border-sm me-1"></span>
      <span *ngIf="!isVerifying">Verify OTP</span>
    </button>
  </div>
</ng-template>

<!-- OTP Verification Modal -->
<ng-template #emailExistsModal let-modal>
  <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button> -->
  <div class="modal-body email-exists__modal__body">
    <h5>
      You have already signed up earlier via this email.
    </h5>
    <p>You can login and access your Musaffa account now.</p>
    <div class="d-flex justify-content-center gap-2">
      <button type="button" class="email-exists-cancel-btn" (click)="modal.dismiss()">Cancel</button>
      <button type="button" class="modal-action-btn" (click)="loginClick()">Log in Now</button>
    </div>
  </div>
</ng-template>