import {
   AfterViewInit,
   Component,
   ElementRef,
   OnInit,
   ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
   selector: 'app-country-routerlink',
   templateUrl: './country-routerlink.component.html',
   styleUrls: ['./country-routerlink.component.scss'],
})
export class CountryRouterlinkComponent implements OnInit, AfterViewInit {
   @ViewChild('menu', { static: false }) menu: ElementRef | undefined;

   role:string|undefined;

   constructor(private router: Router, private auth:AuthService) {}

   ngAfterViewInit(): void {
      let index: number = 0;

      switch (this.router.url) {
         case '/cabinet/onboarding':
            index = 0;
            break;
         case '/cabinet/malaysia':
            index = 1;
            break;
         case '/cabinet/indonesia':
            index = 2;
            break;
         case '/cabinet/united-kingdom':
            index = 3;
            break;
         case '/cabinet/canada':
            index = 4;
            break;
         case '/cabinet/singapure':
            index = 5;
            break;
         default:
            break;
      }
      let offset = this.menu?.nativeElement?.children[index].offsetLeft;
      this.menu?.nativeElement.scrollTo({
         left: offset - 20,
         behavior: 'smooth',
      });
   }

   ngOnInit(): void {
      this.role = this.auth.getRole();
   }
}
