import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details-tab-btns',
  templateUrl: './details-tab-btns.component.html',
  styleUrls: ['./details-tab-btns.component.scss']
})
export class DetailsTabBtnsComponent implements OnInit {
  @Input() tabsType!: string;
  @Input() tabs: string[] = [];
  @Input() activeTab: number = parseInt(this.route.snapshot.queryParamMap.get('state') ?? "0");;
  @Output() onTabChange = new EventEmitter<number>();
  isMobile: boolean = false;

  @Input() stockOverviewTab: string = '';

  constructor(
    private route: ActivatedRoute, private observer: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.observer.observe('(max-width: 992px)').subscribe(result => {
      this.isMobile = result.matches
    });
  }

  setActiveTab(index: number) {
    this.activeTab = index
    this.onTabChange.emit(this.activeTab)
  }

  @HostBinding('class')

  get tabType(): string {
    if (this.tabsType === 'filled') {
      return 'filled-tabs-btns-container';
    } else if (this.tabsType === 'bordered') {
      return 'bordered-tabs-btns-container';
    } else {
      return ''; // Default class if not inside any specific container
    }
  }

}
