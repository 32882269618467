import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, take, takeUntil } from 'rxjs';
import { TypesenseService } from '../../services/typesense.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionService } from '../../services/subscription.service';
import { Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';
import { SortService } from '../../services/sort.service';
import { AccessControlService } from '../../services/access-control.service';
import { EtfDetailsService } from '../../services/etf-details.service';
@Component({
  selector: 'app-etf-holdings',
  templateUrl: './etf-holdings.component.html',
  styleUrls: ['./etf-holdings.component.scss']
})
export class EtfHoldingsComponent implements OnInit {
   private destroy$ = new Subject<void>();
   isMobile: boolean = false;
   page: number = 1
   pageSize: number = 10
   @Input() symbol!: string
   @Input() etfComplianceStatus!: string
   @Input() isManualSet: boolean = false;
   loader: boolean = false;
   canViewEtfComplianceReport: boolean = false;
   isLoggedIn: boolean = false;
   userRole: Role = null;
   etfs: any[] = []
   etfsCount: number = 0;
   filteredEtfs: any[] = [];
   selectedFilter: string = 'all';
   filterOptions = [
      { label: 'All', value: 'all' },
      { label: 'Halal', value: 'halal' },
      { label: 'Not Halal', value: 'not-halal' },
      { label: 'Doubtful', value: 'doubtful' },
      { label: 'Not Covered', value: 'not-covered' }
   ];

   sortColumn: string = '';
   sortOrder: 'asc' | 'desc' | '' = '';
   originalETFHoldings: any[] = [];

   constructor(
      private observer: BreakpointObserver,
      private typesenseService: TypesenseService,
      private authService: AuthService,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private sharedService: SharedService,
      private sortService: SortService,
      public accessControlService: AccessControlService,
      private etfDetailsService: EtfDetailsService
   ) { }

   ngOnInit() {
      this.observer
         .observe('(max-width: 992px)')
         .pipe(takeUntil(this.destroy$))
         .subscribe((result) => {
            this.isMobile = result.matches;
         });

      // SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
      //    this.userRole = data.newRole
      // })
      this.permissionStore.select(({permissions}) => permissions.permissions).subscribe(
         async (permissions) => {
            this.canViewEtfComplianceReport = permissions.etf_compliance_report;
            // this.canViewEtfComplianceReport = false;
         }
      )
      this.isLoggedIn = !!this.authService.getUserId();

      this.getEtfHoldings(this.symbol)
   }

   checkShowHoldingsDisclaimer() {
      if(this.etfComplianceStatus === 'COMPLIANT') {
         const noHoldingsDisclaimerList = this.etfDetailsService.getNoHoldingsDisclaimerList();
         return !noHoldingsDisclaimerList.includes(this.symbol);
      }
      return false;
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   async getEtfHoldings(symbol: string) {
      this.loader = true;

      try {
         const response = await this.typesenseService.etfHoldings(symbol).pipe(takeUntil(this.destroy$)).toPromise();
         this.etfs = response?.items;

         const symbols = this.etfs
            .map((holding) => holding.symbol)
            .filter((symbol) => symbol !== "-");

         if (symbols.length > 0) {
            const complianceInfo = await this.typesenseService.getStockFromSymbols(symbols).then((data: any) => {
               return Object.entries<{ [key: string]: any }>(data).map(
                  ([key, value]) => {
                     return ({
                        symbol: key,
                        isin: value.isin,
                        name: value.name,
                        logo: value.logo ? value.logo : '',
                        country: value.country,
                        complianceStatus: value.shariahCompliantStatus,
                        complianceRanking: value.compliantRanking,  // Existing property
                        ranking_v2: value.ranking_v2 ?? 0, // Add ranking_v2 here
                     });
                  }
               );
            });


            this.etfs = this.etfs.map(holding => {
               const compliance = complianceInfo.find(c => c.symbol === holding.symbol);
               return {
                  ...holding,
                  complianceStatus: compliance?.complianceStatus || 'NOT_UNDER_COVERAGE',
                  complianceRanking: compliance?.complianceRanking || 0,
                  complianceRankingV2: compliance?.ranking_v2 || 0, // Ensure ranking_v2 is assigned
                  logo: compliance?.logo || ''
               };
            });


            // console.log(this.etfs);
            // this.etfs = this.sortService.sortData(this.etfs, 'percent', 'desc');
            // this.originalETFHoldings = [...this.etfs];

         }

         // Filter and handle the ETF data
         this.filterEtfs(this.selectedFilter);
      } catch (error) {
         console.error(error);
      } finally {
         this.loader = false;
      }
   }

   filterEtfs(filterType: string) {
      this.selectedFilter = filterType;
      switch (filterType) {
         case 'halal':
            this.filteredEtfs = this.etfs.filter(etf => etf.complianceStatus === 'COMPLIANT');
            break;
         case 'not-halal':
            this.filteredEtfs = this.etfs.filter(etf => etf.complianceStatus === 'NON_COMPLIANT');
            break;
         case 'doubtful':
            this.filteredEtfs = this.etfs.filter(etf => etf.complianceStatus === 'QUESTIONABLE');
            break;
         case 'not-covered':
            this.filteredEtfs = this.etfs.filter(etf => etf.complianceStatus === 'NOT_UNDER_COVERAGE');
            break;
         case 'all':
         default:
            this.filteredEtfs = this.etfs;
            break;
      }

      this.originalETFHoldings = [...this.filteredEtfs];
      this.etfsCount = this.filteredEtfs.length;
      this.page = 1;
      this.onSort('percent', 'desc')
   }

   canSort() {
      return this.displayETFHoldings().length > 1 && this.canViewEtfComplianceReport && !this.isMobile
   }

   onSort(column: string, sortOrder?: string) {
      if(!this.isLoggedIn) return

      if (column !== this.sortColumn) {
         this.resetSorting();
      }

      this.sortColumn = column;

      if (sortOrder === 'asc') {
         this.filteredEtfs = this.sortService.sortData(this.filteredEtfs, column, 'asc');
         this.sortOrder = 'asc';
      } else if (sortOrder === 'desc') {
         this.filteredEtfs = this.sortService.sortData(this.filteredEtfs, column, 'desc');
         this.sortOrder = 'desc';
      } else {
         if (this.sortOrder === 'asc') {
            this.filteredEtfs = this.sortService.sortData(this.filteredEtfs, column, 'desc');
            this.sortOrder = 'desc';
         } else if (this.sortOrder === 'desc') {
            this.filteredEtfs = [...this.originalETFHoldings];
            this.sortOrder = '';
         } else {
            this.filteredEtfs = this.sortService.sortData(this.filteredEtfs, column, 'asc');
            this.sortOrder = 'asc';
         }
      }

   }

   resetSorting() {
      this.filteredEtfs = [...this.originalETFHoldings];
      this.sortOrder = '';
      this.sortColumn = '';
   }

   getColumnSortClasses(column: string) {
      return {
        'bi bi-sort-down-alt ms-2': this.sortColumn === column && this.sortOrder === 'asc',
        'bi bi-sort-up ms-2': this.sortColumn === column && this.sortOrder === 'desc',
        'ms-2': this.sortColumn === column && this.sortOrder === ''
      };
   }

   isSelected(filterType: string): boolean {
      return this.selectedFilter === filterType;
   }

   onPageChange() {
      window.scrollTo({ top: 0 });
   }

   getPercentChangeClasses(change: any) {
      return {
         positive: change >= 0,
         negative: change < 0,
      };
   }

   getIconClasses(change: any) {
      return {
         'bi-caret-up-fill positive': change >= 0,
         'bi-caret-down-fill negative': change < 0,
      };
   }

   openLoginPopup() {
      this.accessControlService.accessDenied();
      // this.authService.openAuthPopup()
   }

   openUpgradePopup() {
      this.accessControlService.accessDenied();
      // this.sharedService.openUpgradePopup()
   }

   displayETFHoldings() {
      return this.canViewEtfComplianceReport ? this.filteredEtfs : this.filteredEtfs.slice(0, 3);
   }
}
