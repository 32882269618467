<div>
    <header class="p-3 p-lg-4 d-flex align-items-center justify-content-between gap-2">
        <div class="d-flex align-items-center gap-2">
            <div class="image-wrapper d-flex align-items-center justify-content-center bg-light overflow-hidden rounded-circle">
                <img class="rounded-circle portfolio-image" [src]="selectedAccount?.brokerage_square_logo" *ngIf="selectedAccount?.brokerage_square_logo" />
                <span class="mb-0 no-logo-span text-uppercase fw-600" *ngIf="!selectedAccount?.brokerage_square_logo">{{ selectedAccount?.name?.charAt(0) }}</span>
            </div>
            <span class="account-name d-block line-clamp-1 text-capitalize fw-500">{{ selectedAccount?.name }}</span>
        </div>
        <div *ngIf="!selectedAccount?.is_sample" class="portfolio-type-chip rounded-3" [ngClass]="selectedAccount?.is_manual ? 'manual-chip' : 'automated-chip'">
            {{ selectedAccount?.is_manual ? 'MANUAL PORTFOLIO' : 'LINKED PORTFOLIO' }}
        </div>
        <div *ngIf="selectedAccount?.is_sample" class="portfolio-type-chip rounded-3">
            Sample Account
        </div>
    </header>


    <section class="portfolio-modal-body">
        <div class="d-flex align-items-center justify-content-between gap-3 py-3 portfolio-info">
            <h6 class="mb-0 fw-500">Account name</h6>
            <span class="fw-500 text-capitalize">{{ selectedAccount?.name }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between gap-3 py-3 portfolio-info">
            <h6 class="mb-0 fw-500">Account number</h6>
            <span class="fw-500 text-capitalize">{{ selectedPortfolioDetails?.number ? selectedPortfolioDetails?.number : '-'}}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between gap-3 py-3 portfolio-info">
            <h6 class="mb-0 fw-500">Total Portfolio Amount</h6>
            <span class="fw-700 text-capitalize portfolio-amount">{{ selectedPortfolioDetails?.total_current | sumPipe: (selectedPortfolioDetails?.currency || 'USD') }}</span>
        </div>
        <div class="d-flex align-items-center border-bottom-0 justify-content-between gap-3 py-3 portfolio-info">
            <h6 class="mb-0 fw-500">Last Synced On</h6>
            <span class="fw-500 text-capitalize sync-date">
                {{ selectedPortfolioDetails?.last_sync_at ? (selectedPortfolioDetails?.last_sync_at | date:'mediumDate' ) : 'Never Synced' }}
            </span>
        </div>

        <button
            *ngIf="selectedAccount?.is_manual"
            class="btn gradient-btn d-flex justify-content-center align-item-center gap-1 shadow-none rounded-3 w-100 fw-500 modal-filled-btn text-white"
            (click)="naviagetToAddHolding()"
        >
            <span>Update Holdings & Transactions</span>
            <i class="bi bi-arrow-right-short fs-5"></i>
        </button>

        <button
            *ngIf="!selectedAccount?.is_manual && !isTransactionAvailable"
            class="btn gradient-btn d-flex justify-content-center align-item-center gap-1 shadow-none rounded-3 w-100 fw-500 modal-filled-btn text-white"
            (click)="naviagetToAddHolding()"
            [disabled]="selectedAccount?.is_sample"
        >
            <span>Update Transactions</span>
            <i class="bi bi-arrow-right-short fs-5"></i>
        </button>

        <div class=" d-flex align-items-center justify-content-center gap-3 text-btn-wrapper">
            <button [disabled]="selectedAccount?.is_sample" class="btn shadow-none d-flex align-items-center gap-2 text-btn" (click)="syncPortfolio()">
                <div
                    style="border: 0.125rem solid #7c8086; border-right-color: transparent;"
                    *ngIf="syncPortfolioLoader"

                    class="spinner-border spinner-border-sm red-loader" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span>{{ syncPortfolioLoader?'Syncing...':'Sync Account' }}</span>
            </button>

            <div class="vr"></div>

            <ng-container *ngIf="!selectedAccount?.is_manual">
                <button [disabled]="selectedAccount?.is_sample" class="btn shadow-none text-btn" (click)="this.openConfirmation()">
                    Unlink Brokerage
                </button>
            </ng-container>

            <ng-container *ngIf="selectedAccount?.is_manual">
                <button [disabled]="selectedAccount?.is_sample" class="btn shadow-none text-btn" (click)="this.openConfirmation()">
                    Delete Account
                </button>
            </ng-container>



        </div>

    </section>
</div>




<!-- <section class="setting-container">
    <header class="d-flex align-items-center justify-content-between gap-2 portfolio-header">

        <div class="d-flex align-item-center gap-2">
            <img height="24" width="24" class="rounded-circle portfolio-image" [src]="selectedAccount?.brokerage_square_logo" *ngIf="selectedAccount?.brokerage_square_logo; else noLogo" />
            <p class="mb-0 account-name line-clamp-1" *ngIf="!selectedAccount?.is_manual">{{selectedAccount?.name}}</p>
        </div>
        <ng-template #noLogo>
            <div *ngIf="selectedAccount?.is_manual" class="manual-logo d-flex gap-2 align-items-center">
                <span class="logo-img bg-light d-flex align-items-center justify-content-center text-uppercase fw-600 rounded-circle flex-shrink-0">
                    <span>{{ selectedAccount?.name?.charAt(0) }}</span>
                </span>
                <span class="manual-text line-clamp-1">{{selectedAccount?.name}}</span>
            </div>
            <div *ngIf="!selectedAccount?.is_manual">
                <span class="logo-img bg-light text-uppercase fw-600 rounded-circle flex-shrink-0">{{ selectedAccount?.name?.charAt(0) }}</span>
            </div>
        </ng-template>
        <div *ngIf="!selectedAccount?.is_manual" class="automated-chip">LINKED PORTFOLIO</div>
        <div *ngIf="selectedAccount?.is_manual" class="manual-chip">MANUAL PORTFOLIO</div>
    </header>

    <article class="setting-body">
        <div class="d-flex align-items-center justify-content-between py-3 bottom-border">
            <p class="label-text mb-0">Account name</p>
            <p class="account-text  mb-0">{{selectedAccount?.name}}</p>
        </div>
        <div class="d-flex align-items-center justify-content-between py-3 bottom-border">
            <p class="label-text mb-0">Account number</p>
            <p class="account-text  mb-0"> {{ selectedPortfolioDetails?.number ? selectedPortfolioDetails?.number : '-'}}</p>
        </div>
        <div class="d-flex align-items-center justify-content-between py-3 bottom-border">
            <p class="label-text  mb-0">Total Portfolio Amount</p>
            <p class="account-text  mb-0 extra-bold">{{selectedPortfolioDetails?.total_current | sumPipe : (selectedPortfolioDetails.currency || '' )}}</p>
        </div>


        <div class="d-flex align-items-center justify-content-between py-3">
            <p class="label-text  mb-0">Last Synced On</p>
            <p class="label-text  mb-0">{{selectedPortfolioDetails?.last_sync_at ?
                (selectedPortfolioDetails?.last_sync_at | date:'mediumDate' )
                :'Never Synced'}}</p>
        </div>

        <button *ngIf="selectedAccount?.is_manual"
            class="btn gradient-btn mt-2 flex justify-content-center align-item-center gap-2 shadow-none rounded-3 w-100 py-2 px-4 px-lg-5 fw-500 text-white"
            (click)="naviagetToAddHolding()">
            <span>Update Holdings & Transactions</span>
        </button>

        <button *ngIf="!selectedAccount?.is_manual && !isTransactionAvailable"
            class="btn gradient-btn mt-2 flex justify-content-center align-item-center gap-2 shadow-none rounded-3 w-100 py-2 px-4 px-lg-5 fw-500 text-white"
            (click)="naviagetToAddHolding()">
            <span>Update Transactions</span>
        </button>
        <div class="mt-3 gap-3 d-flex justify-content-center align-item-center">
            <div class="d-flex align-item-center gap-1">
                <div style="border:0.25em solid #7c8086;border-right-color: transparent;vertical-align: 0em;margin-top: 3px;"
                    *ngIf="syncPortfolioLoader" class="spinner-border spinner-border-sm red-loader" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <p (click)="syncPortfolio()" class="mb-0 action-link">
                    {{syncPortfolioLoader?'Syncing...':'Sync Account'}}
                </p>
            </div>
            <div class="vr"></div>
            <div class="d-flex align-item-center gap-1" *ngIf="!selectedAccount?.is_manual">
                <p (click)="this.openConfirmation()" class="mb-0 action-link">
                    Unlink Brokerage
                </p>
            </div>
            <div class="d-flex align-item-center gap-1" *ngIf="selectedAccount?.is_manual">
                <p (click)="this.openConfirmation()" class="mb-0 action-link">
                    Delete Account
                </p>
            </div>
        </div>
    </article>
</section> -->
