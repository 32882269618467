<section>
   <!-- <app-new-header></app-new-header> -->
   <div class="main__error">
      <div class="container">
         <div class="pages__main">
            <div class="row">
               <div class="col-md-6">
                  <div class="pages__text ">
                     <h2>We are currently under maintenance</h2>
                     <p>The website is down for a bit of improvement right now. We will be online as soon as possible. Please check
                        again in a while. Thank you!</p>
            
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="pages__picture ">
                     <img src="../../../../assets/images/errors-img.svg" alt="">
                  </div>
               </div>
            </div>
       
          
         </div>
     
         <div class="pages__information ">
   
            <div class="links">
               <div class="link__main">
                  <a href="https://musaffa.com">
                     info@musaffa.com
                  </a>
               </div>
               <div class="link__apps">
                  <a href="https://www.linkedin.com/company/musaffa/mycompany/">
                     <i class="bi bi-linkedin"></i>
                  </a>
                  <a href="https://twitter.com/Musaffa_Fintech">
                     <i class="bi bi-twitter"></i>
                  </a>
                  <a href="https://www.facebook.com/musaffa.fintech">
                     <i class="bi bi-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/musaffa.fintech/">
                     <i class="bi bi-instagram"></i>
                  </a>
   
   
               </div>
   
   
            </div>
   
            <div class="information">
               <p>© 2021-2022 Musaffa | All rights reserved.</p>
            </div>
         </div>
      </div>
    
   </div>
</section>