<div class="overview__tab__content__row">
   <div class="overview__content__lcol">
       <app-portfolio-value [accountDetails]="accountDetails" [loading]="!!(accountDetailsLoading || (loading$ | async))"></app-portfolio-value>
       <app-mini-chart [smoothLine]="smoothLine" [selectedRange]="selectedRange" [loading]="!!(portfolioPerformanceLoading || (loading$ | async))" [chartData]="portfolioPerformanceData" [currency]="currency"></app-mini-chart>
   </div>

   <div class="overview__content__rcol px-3 pb-3 px-lg-4 pb-lg-4">
       <h5 class="text-black fw-600 mb-0">Pureness</h5>
       <app-pureness style="width: 100%;" [data]="purenessData" [loading]="!!(purenessLoading || (loading$ | async))"></app-pureness>
   </div>
