import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyToLabel'
})
export class KeyToLabelPipe implements PipeTransform {
  private mapping: { [key: string]: string } = {
    accountsPayable: 'Accounts Payable',
    accountsReceivables: 'Accounts Receivable',
    accruedLiability: 'Accrued Liabilities',
    accumulatedDepreciation: 'Accumulated Depreciation',
    additionalPaidInCapital: 'Additional Paid-In Capital',
    cash: 'Cash',
    cashEquivalents: 'Cash Equivalents',
    cashShortTermInvestments: 'Cash & Short-Term Investments',
    commonStock: 'Common Stock',
    currentAssets: 'Total Current Assets',
    currentLiabilities: 'Total Current Liabilities',
    currentPortionLongTermDebt: 'Current Portion of Long-Term Debt',
    inventory: 'Inventory',
    liabilitiesShareholdersEquity: 'Total Liabilities & Shareholders’ Equity',
    longTermDebt: 'Long-Term Debt',
    longTermInvestments: 'Long-Term Investments',
    minorityInterest: 'Minority Interest (Non-Controlling Interest)',
    netDebt: 'Net Debt',
    noteReceivableLongTerm: 'Long-Term Notes Receivable',
    otherAssets: 'Other Assets',
    otherCurrentAssets: 'Other Current Assets',
    otherCurrentliabilities: 'Other Current Liabilities',
    otherEquity: 'Other Equity',
    otherLiabilities: 'Other Liabilities',
    otherLongTermAssets: 'Other Long-Term Assets',
    otherReceivables: 'Other Receivables',
    propertyPlantEquipment: 'Property, Plant & Equipment',
    retainedEarnings: 'Retained Earnings',
    sharesOutstanding: 'Shares Outstanding',
    shortTermDebt: 'Short-Term Debt',
    shortTermInvestments: 'Short-Term Investments',
    tangibleBookValueperShare: 'Tangible Book Value per Share',
    totalAssets: 'Total Assets',
    totalDebt: 'Total Debt',
    totalEquity: "Total Shareholders' Equity",
    totalLiabilities: 'Total Liabilities',
    totalReceivables: 'Total Receivables',
    unrealizedProfitLossSecurity: 'Unrealized Gain (Loss) on Securities',
    goodwill: 'Goodwill',
    intangiblesAssets: 'Intangible Assets',
    costOfGoodsSold: 'Cost of Goods Sold',
    dilutedAverageSharesOutstanding: 'Average Shares Outstanding (Diluted)',
    dilutedEPS: 'Earnings Per Share (Diluted)',
    ebit: 'Earnings Before Interest and Taxes (EBIT)',
    grossIncome: 'Gross Profit',
    netIncome: 'Net Income',
    netIncomeAfterTaxes: 'Net Income After Taxes', // if you need a label, add it here
    pretaxIncome: 'Income Before Taxes',
    provisionforIncomeTaxes: 'Provision for Income Taxes',
    researchDevelopment: 'Research and Development (R&D) Expense',
    revenue: 'Total Revenue',
    sgaExpense: 'Selling, General & Administrative (SG&A) Expense',
    totalOperatingExpense: 'Total Operating Expenses',
    totalOtherIncomeExpenseNet: 'Total Other Income (Expense), Net',
    capex: 'Capital Expenditure',
    cashDividendsPaid: 'Payments for Dividends and Dividend Equivalents',
    cashTaxesPaid: 'Payments for Taxes',
    changeinCash: '', // if you need a label, add it here
    changesinWorkingCapital: 'Change in Working Capital',
    depreciationAmortization: 'Depreciation & Amortization',
    fcf: 'Free Cash Flow',
    issuanceReductionCapitalStock: '', // if needed
    issuanceReductionDebtNet: 'Net Issuance (Reduction) of Debt',
    netCashFinancingActivities: 'Net Cash from Financing Activities',
    netIncomeStartingLine: 'Net Income',
    netInvestingCashFlow: 'Net Cash from Investing Activities',
    netOperatingCashFlow: 'Net Cash from Operating Activities',
    otherFundsFinancingItems: 'Other Financing Cash Flow Items',
    otherFundsNonCashItems: 'Other Non-Cash Items',
    otherInvestingCashFlowItemsTotal: 'Other Investing Cash Flow Items',
    stockBasedCompensation: 'Stock-Based Compensation Expense',
    interestIncomeExpense: 'Interest Income (Expense), Net'
  };

  transform(value: string): string {
    // Return the mapped label if available, otherwise return the original key
    return this.mapping[value] || value;
  }
}
