import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';
import { CrowdfundingProgressbarResponse } from 'src/app/models/crowdfunding/progressbar.model';
import { TypesenseService } from 'src/app/shared/services/typesense.service';

@Component({
   selector: 'app-crowdfunding-banner',
   templateUrl: './crowdfunding-banner.component.html',
   styleUrls: ['./crowdfunding-banner.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrowdfundingBannerComponent implements OnInit {
   showCrowdfundingBanner = true;
   progressData$!: Observable<CrowdfundingProgressbarResponse>;
   loading$ = new BehaviorSubject<boolean>(true);

   constructor(
      private typesenseService: TypesenseService
   ) { }

   ngOnInit(): void {
      this.progressData$ = this.typesenseService.fetchCrowdfundingProgressData().pipe(
         finalize(() => this.loading$.next(false))
      );
      const storedVisibility = sessionStorage.getItem('crowdfundingBannerVisibility');
      this.showCrowdfundingBanner = storedVisibility !== 'false';
   }

   closeCrowdfundingbanner() {
      this.showCrowdfundingBanner = false;
      sessionStorage.setItem('crowdfundingBannerVisibility', 'false');
   }

}
