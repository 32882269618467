import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
   AbstractControl,
   FormBuilder,
   FormGroup,
   ValidationErrors,
   ValidatorFn,
   Validators,
} from '@angular/forms';
import { PublicListsService } from 'src/app/shared/services/public-lists.service';
import { CreateListResponse } from 'src/app/models/public-list.model';
import { ListDetails } from 'src/app/models/public-list/list-details.model';
import { ListHolding } from 'src/app/models/public-list/list-holdings.model';
import { Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
   selector: 'app-create-list-popup',
   templateUrl: './create-list-popup.component.html',
   styleUrls: ['./create-list-popup.component.scss'],
})
export class CreateListPopupComponent implements OnInit, OnDestroy {
   @Input() listDetails!: ListDetails;
   listHoldings: ListHolding[] = [];
   newListForm!: FormGroup;
   isListCreated: boolean = false;
   submitted: boolean = false;
   createButtonLoader: boolean = false;
   existingListNames: string[] = [];
   private destroy$ = new Subject<void>();
   userId!: string;
   userRole!: string | null;
   listNameExistsError!: boolean;
   errorMsg: string = '';

   constructor(
      public activeModal: NgbActiveModal,
      private fb: FormBuilder,
      private listService: PublicListsService,
      private toaster: ToastrService,
      private authService: AuthService
   ) {}

   get formControls() {
      return this.newListForm.controls;
   }

   ngOnInit() {
      this.newListForm = this.fb.group({
         listName: ['', [Validators.required]],
         stocks: [[], [this.chipValidator(true)]],
         description: ['', [Validators.required]],
      });

      this.newListForm
         .get('listName')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            if (typeof value === 'string') {
               this.newListForm
                  .get('listName')
                  ?.setValue(this.normalizeSpaces(value), { emitEvent: false });
            }
         });

      this.newListForm
         .get('listName')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe(() => {
            if (this.listNameExistsError) {
               this.listNameExistsError = false;
            }
         });

      this.newListForm
         .get('description')
         ?.valueChanges.pipe(takeUntil(this.destroy$))
         .subscribe((value) => {
            if (typeof value === 'string') {
               this.newListForm
                  .get('description')
                  ?.setValue(this.normalizeSpaces(value), { emitEvent: false });
            }
         });

      if (this.listDetails?.id) {
         this.loadListDetails();
      }
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   chipValidator(multiple: boolean): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
         const value = control.value;
         if (multiple && value.length < 1) {
            return {
               minChips: { requiredLength: 10, actualLength: value.length },
            };
         } else if (!multiple && value.length < 1) {
            return {
               minChips: { requiredLength: 1, actualLength: value.length },
            };
         }
         return null;
      };
   }

   emptyOrWhitespace(control: AbstractControl): ValidationErrors | null {
      const value = control.value as string;
      if (!value || value.trim() === '') {
         return { required: true };
      }
      return null;
   }

   normalizeSpaces(value: string): string {
      return value.replace(/\s{2,}/g, ' ');
   }

   async loadListDetails() {
      try {
         this.newListForm.patchValue({
            listName: this.listDetails.name,
            description: this.listDetails.description,
            stocks: this.listHoldings,
         });
      } catch (error) {
         console.log(error);
      }
   }

   async createList(): Promise<void> {
      this.submitted = true;
      this.createButtonLoader = true;

      try {
         if (this.newListForm.valid) {
            const name = this.newListForm.value.listName;
            const description = this.newListForm.value.description;
            const stocks = this.newListForm.value.stocks;

            let response;

            if (this.listDetails?.id) {
               response =
                  await this.listService.updatePublicList<CreateListResponse>(
                     this.listDetails.id as string,
                     name,
                     description,
                     stocks
                  );
            } else {
               response = await this.listService.createList<CreateListResponse>(
                  name,
                  description,
                  stocks
               );
            }

            if (response.status) {
               this.toaster.success(
                  this.listDetails?.id ? 'List updated' : 'List created'
               );
               this.createButtonLoader = false;
               this.activeModal.dismiss();
               this.listService.triggerListsReload();
            } else {
               // this.listNameExistsError = true;
               this.errorMsg = response.message;
               this.createButtonLoader = false;
            }
         } else {
            this.createButtonLoader = false;
         }
      } catch (error) {
         console.error('Error creating/updating list:', error);
         this.toaster.error('Something went wrong');
      } finally {
         this.createButtonLoader = false;
      }
   }

   preventEnterSubmit(event: KeyboardEvent) {
      if (event.key === 'Enter') {
         event.stopPropagation();
      }
   }
}
