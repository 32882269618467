import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaywallPopupComponent } from '../paywall-popup/paywall-popup.component';
import { TypesenseService } from '../../services/typesense.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, lastValueFrom } from 'rxjs';
import { SumPipe } from '../../pipes/sum.pipe';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import { AccessControlService } from '../../services/access-control.service';
@Component({
  selector: 'app-etf-sample-popup',
  templateUrl: './etf-sample-popup.component.html',
  styleUrls: ['./etf-sample-popup.component.scss']
})
export class EtfSamplePopupComponent implements OnInit {
  @Input() title: string = 'Shariah Compliance Report';
  @Input() stockName: string = 'SPDR S&P 500 ETF Trust';
  @Input() stockTicker: string = 'VTV';
  paywallModalRef: any;
  etfReportLoader: boolean = false

  private subscriptions: Subscription[] = [];
  id: string = ''
  etf: any ;
  fundamentals: any;
  userRole: string|null = null


  constructor(
    public etfSampleModal: NgbActiveModal,
    private modalService: NgbModal,
    private typesenseService: TypesenseService,
    private sumPipe: SumPipe,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    public accessControlService: AccessControlService
  ) {
    this.etf = {
      etfProfileInfo: {},
      compliance: {}
    };
    this.fundamentals = {};
  }

  ngOnInit(): void {
    this.getSampleEtfInfo()
    this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      this.userRole = data.role
    }))
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(item => {
        item.unsubscribe()
      })
    }
  }

  openLoginPopup() {
    // this.etfSampleModal.dismiss()
    this.authService.openAuthPopup()
    // this.router.navigate(['/authentication/sso-login'])
  }

  openPaywallModal() {
    this.sharedService.openUpgradePopup()
    // this.paywallModalRef = this.modalService.open(PaywallPopupComponent, {
    //   centered: true,
    //   modalDialogClass: 'paywall-popup-modal',
    //   size: 'xl'
    // });
  }

  async getSampleEtfInfo() {
    try {
      this.id = 'VTV';
      this.etfReportLoader = true;

      const etfProfileInfo = await lastValueFrom(this.typesenseService.etfGetInfo(this.id));
      this.etf.etfProfileInfo = etfProfileInfo;

      try {
        const etfComplianceInfo = await this.typesenseService.etfComplianceInfo(this.id);
        this.etf.compliance = etfComplianceInfo;
      } catch (error) {
        console.log(error);
      } finally {
        this.etfReportLoader = false;
      }
    } catch (error) {
      console.log(error);
      this.etfReportLoader = false;
    }
}

  // getSampleEtfInfo() {
  //   this.id = 'SPY'
  //   this.etfReportLoader = true
  //   const etfInfoSubscription = this.typesenseService.etfGetInfo(this.id).subscribe(
  //     async(data: any) => {
  //       this.etf.etfProfileInfo = data;
  //       try {
  //         this.etf.compliance = await this.typesenseService.etfComplianceInfo(this.id)
  //       } catch (error) {
  //         console.log(error);
  //       }
  //       this.etfReportLoader = false
  //     },
  //     (error:  HttpErrorResponse) => {
  //       this.etfReportLoader = false
  //     }
  //   )
  //   this.subscriptions.push(etfInfoSubscription)
  // }



}
