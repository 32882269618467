import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTrimLeadingSpaces]'
})
export class TrimLeadingSpacesDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement): void {
    const trimmedValue = target.value.replace(/^\s+/, '').replace(/\s\s+/g, ' ')

    if(trimmedValue !== target.value) {
      this.renderer.setProperty(target, 'value', trimmedValue)
    }
  }
 
}
