import { HttpErrorResponse } from '@angular/common/http';
import {
   Component,
   EventEmitter,
   HostBinding,
   Input,
   OnInit,
   Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { LoginApiResponse } from 'src/app/models/user/login.model';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoaderServices } from 'src/app/shared/services/loader.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserApiService } from 'src/app/shared/services/user-api.service';
import { loadWatchlistSymbols } from 'src/app/stores/watchlist-symbols/watchlist-symbols.actions';
import { environment } from 'src/environments/environment';
import { PricingService } from 'src/app/features/pricing/services/pricing.service';
@Component({
   selector: 'app-login-form',
   templateUrl: './login-form.component.html',
   styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
   userLoginForm!: FormGroup;
   showPassword: boolean = false;
   loginLoader: boolean = false;
   showOtpSection: boolean = false;
   errMsg: string = '';
   redirectURL!: string | null;
   showInput: boolean = false;
   nextResendDuration: number = environment.resendPinSeconds;
   seconds: number = this.nextResendDuration;
   showOTPLoader: boolean = false;
   resendCode: boolean = false;
   featurebase: string = '';
   sendCodeResponse: any;
   @Input() action: string | null = null;
   @Input() isInsidePopup: boolean = false;
   @Output() formChange: EventEmitter<string> = new EventEmitter();

   constructor(
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private pricingService: PricingService,
      // private loader: LoaderServices,
      private userApiService: UserApiService,
      public analyticsUtils: AnalyticsUtils,
      private router: Router,
      private auth: AuthService,
      private subscriptionService: SubscriptionService,
      private toaster: ToastrService,
      private store: Store,
      private sharedService: SharedService
   ) {}

   ngOnInit(): void {
      this.userLoginForm = this.fb.group({
         email: [
            '',
            [
               Validators.email,
               Validators.required,
               Validators.pattern(
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+([a-z]{1,5})\b/
               ),
            ],
         ],
         password: ['', [Validators.required]],
         code: ['', [this.otpValidator.bind(this)]],
      });
      if (!this.isInsidePopup) {
         if (this.auth.getRole().length && !this.auth.getUserId()) {
            this.redirectUser();
         }
      }
      this.analyticsUtils.LoginScreen_Opened();
   }

   otpValidator(control: FormControl) {
      if (
         this.showOtpSection &&
         (!control.value || control.value.trim().length < 4)
      ) {
         return { otpRequired: true };
      }
      return null;
   }

   async login() {
      if (!this.userLoginForm.valid) {
         return;
      }
      const email = this.userLoginForm.value.email;
      const password = this.userLoginForm.value.password;
      this.loginLoader = true;

      try {
         let resp = await this.userApiService.login<LoginApiResponse>(
            email,
            password
         );
         this.analyticsUtils.LoginScreen_Login_Tapped(email);

         if (resp.status) {
            if (resp.status_code === 'email_verified') {
               const token: string = resp.data.token;
               await this.auth.setToken(token);
               await this.userApiService.fetchUserDetailsAndSave();
               this.auth.setLoggedIn();
               this.store.dispatch(loadWatchlistSymbols());
               await this.subscriptionService.setUserSubscriptionInfo();
               const newRole = SubscriptionService.subscriptionData.getValue()?.newRole;
               if (this.action && (!newRole || newRole === 'free')) {
                  const actionTarget = this.action.split(':');
                  const action = actionTarget[0];
                  const target = actionTarget[1] ?? null;
                  if (action === 'open_stripe_payment') {
                     const si =
                        await this.subscriptionService.getStripeSubscriptionInfo();
                     if (si.status) {
                        if (si.items?.data?.[0]?.plan?.id !== target) {
                           const response: any =
                              await this.subscriptionService.getUpdatePaymentLink(
                                 target
                              );
                           if (response.is_upgrade) {
                              window.location.href = response.url;
                           } else {
                           }
                        } else {
                           window.location.href = (
                              await this.subscriptionService.getPaymentLink(
                                 target
                              )
                           ).url;
                        }
                     }
                  }
               } else {
                  const subInfo =
                     SubscriptionService.subscriptionData.getValue();
                  if (subInfo.role === 'FREEMIUM') {
                     // this.subscriptionService.openPaywallModal();
                     this.sharedService.openUpgradePopup();
                  }
               }
               this.loginLoader = false;
               this.analyticsUtils.LoginScreen_Login_Completed(email);
               // this.router.navigate(['/']);
               if (this.isInsidePopup) {
                  this.toaster.success('Logged In Successfully');
                  this.auth.closeAuthPopup();
                  window.location.reload();
               } else {
                  this.redirectUser();
               }
            } else {
               this.sendCodeResponse = await this.userApiService.sendOtpMail({
                  email,
               });
               this.resendCode = true;
               this.showOtpSection = true;
               this.userLoginForm.controls.code.setValue('');
               this.userLoginForm.controls.code.markAsUntouched();
               this.inputTimer();
               this.toaster.success('Verification Pin sent');
            }
         } else {
            this.errMsg = resp.message;
         }
      } catch (error) {
         if (error instanceof HttpErrorResponse) {
            if (this.action) {
               const actionTarget = this.action.split(':');
               const action = actionTarget[0];
               const target = actionTarget[1] ?? null;
               if (
                  error.url?.includes('user/subscription') &&
                  error.status === 400
               ) {
                  window.location.href = (
                     await this.subscriptionService.getPaymentLink(target)
                  ).url;
               } else {
                  console.log(error);
                  this.errMsg = 'Something went wrong';
                  this.analyticsUtils.LoginScreen_Login_Failed(
                     email,
                     this.errMsg
                  );
                  this.loginLoader = false;
               }
            } else {
               console.log(error);
               this.errMsg = 'Something went wrong';
               this.analyticsUtils.LoginScreen_Login_Failed(email, this.errMsg);
               this.loginLoader = false;
            }
         } else {
            console.log(error);
            this.errMsg = 'Something went wrong';
            this.analyticsUtils.LoginScreen_Login_Failed(email, this.errMsg);
            this.loginLoader = false;
         }
      } finally {
         this.loginLoader = false;
      }
   }

   async verifyOTP() {
      try {
         this.showOTPLoader = true;
         const identifier = this.sendCodeResponse.data;
         const activationCode = this.userLoginForm.value.code
            .trim()
            .replace(/\s/g, '');

         let request = {
            identifier: identifier,
            activationCode: activationCode,
         };

         let response: any = await this.userApiService.verifyOtp(request);
         if (response.status) {
            this.auth.setToken(response.data.token);
            await this.userApiService.fetchUserDetailsAndSave();
            this.auth.setLoggedIn();
            await this.subscriptionService.setUserSubscriptionInfo();
            const subInfo = SubscriptionService.subscriptionData.getValue();

            // if (subInfo.role === 'FREEMIUM') {
            //    this.subscriptionService.openPaywallModal();
            // }

            this.router.navigate(['/'], { replaceUrl: true });
            this.showOTPLoader = false;
         } else {
            this.toaster.error(response.message);
            this.userLoginForm.controls.code.setValue('');
            this.userLoginForm.controls.code.markAsUntouched();
            this.showOTPLoader = false;
         }
      } catch (error) {
         console.error(error);
         this.showOTPLoader = false;
      }
   }

   sleep(ms: number): Promise<void> {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

   private redirectUser() {

      this.route.queryParams.subscribe(params => {
         this.featurebase = params['featurebase'];
       });

       if (this.featurebase === '1') {
         this.loginLoader = true;
         this.pricingService.getJwtToken()
         .then(response => {
            const jwtToken = response?.jwt;
            if (jwtToken) {
               const redirectUrl = `https://musaffa.featurebase.app/api/v1/auth/access/jwt?jwt=${jwtToken}&return_to=https://musaffa.featurebase.app/en/widget?page=MainView`;
               window.location.href = redirectUrl;
            }
         })
         .catch(error => {
            console.error('Error fetching JWT Token:', error);
         });
         return;
      }

      this.redirectURL = localStorage.getItem('redirectUrl');

      if(this.redirectURL?.includes('?')) {
         this.redirectURL = "/"
      }

      if (this.redirectURL) {
         this.router.navigate([this.redirectURL]);
      } else {
         this.router.navigate(['/']);
      }
   }

   async resendMail() {
      const email = this.userLoginForm.value.email;
      this.sendCodeResponse = await this.userApiService.sendOtpMail({ email });
      this.inputTimer();
   }

   inputTimer() {
      this.showInput = true;
      let interval = setInterval(() => {
         if (this.seconds == 0) {
            clearInterval(interval);
            this.showInput = false;
            this.seconds = this.nextResendDuration;
            this.userLoginForm.controls.code.markAsUntouched();
         } else {
            this.seconds--;
         }
      }, 1000);
   }

   forgotPassword() {
      this.analyticsUtils.LoginScreen_ForgotPassword_Tapped();
      this.formChange.emit('forgotPassword');
   }

   navigateToForgotPasswordPage() {
      this.auth.closeAuthPopup();
      this.router.navigate(['/authentication/forgot-pasword']);
   }

   navigateToSignUpPage() {
      this.auth.closeAuthPopup();
      this.router.navigate(['/authentication/registration']);
   }

   signUp() {
      this.formChange.emit('signUp');
   }

   togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
   }

   @HostBinding('class')
   get popupStyling(): string {
      if (this.isInsidePopup) {
         return 'popup-styling';
      } else {
         return '';
      }
   }
}
