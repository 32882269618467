<article class="br-10" [ngClass]="isInsideBrokeragesPopup ? 'popup-padding' : ''">
   <div class="text-center text-lg-start ">
      <h3 class="text-black mb-3 fw-600">
         1,500+ more institutions now supported! <br>
         <!-- <span>Across US & Canada via<img src="/assets/images/plaid-logo.png" class="d-inline-block ms-2" alt="Plaid Logo"></span> -->
         <span>Across US & Canada</span>
      </h3>
      <p class="mb-0">
         Did not find your broker in the above list? Musaffa now supports connecting to more than 1500+ institutions in US and Canada via Plaid.
      </p>
   </div>
   <button type="button" class="btn mx-auto me-lg-0 text-white fw-500 rounded-3 shadow-none coming-soon-btn">
      Coming Soon...
   </button>
</article>

