import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { loadWatchlistSymbols } from 'src/app/stores/watchlist-symbols/watchlist-symbols.actions';
import { Store } from '@ngrx/store';
import { AddListsComponent } from 'src/app/cabinet/add-lists/add-lists.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CopyToWatchlistPopupComponent } from '../components/copy-to-watchlist-popup/copy-to-watchlist-popup.component';

@Injectable({
   providedIn: 'root',
})
export class WatchlistService {
   url: string = environment.apiUrl;
   private totalWatchlistCount = new BehaviorSubject<number>(0);
   watchListCount$ = this.totalWatchlistCount.asObservable();

   private addListsModalRef: NgbModalRef | undefined;
   private copyToWatchlistModalRef: NgbModalRef | undefined;

   constructor(
      private http: HttpClient,
      private store: Store,
      private modalService: NgbModal,
   ) { }

   fetchAllWatchlist() {
      return this.http.get(this.url + '/api/watchlists/fetch-all-watchlists');
   }

   setWatchlistCount(count: number) {
      this.totalWatchlistCount.next(count);
   }

   async deleteStockFromWatchlist(watchlist_id: string, symbol: string) {
      const response = await lastValueFrom(
         this.http.post(this.url + '/api/watchlists/delete-symbol', {
            watchlist_id: watchlist_id,
            symbol: symbol,
         })
      );
      this.store.dispatch(loadWatchlistSymbols());
      return response;
   }

   async addStockToWatchlist(
      watchlist_id: string,
      symbol: string,
      type: string
   ) {
      const response = await lastValueFrom(
         this.http.post(this.url + '/api/watchlists/add-symbol', {
            watchlist_id: watchlist_id,
            symbol: symbol,
            type: type,
         })
      );
      this.store.dispatch(loadWatchlistSymbols());
      return response;
   }

   async createWatchlist(name: string) {
      const response = await lastValueFrom(
         this.http.post(this.url + '/api/watchlists/create', {
            name: name,
         })
      );
      this.store.dispatch(loadWatchlistSymbols());
      return response;
   }

   async updateWatchlist(name: string, watchlist_id: string) {
      const response = await lastValueFrom(
         this.http.post(this.url + '/api/watchlists/update', {
            name: name,
            watchlist_id: watchlist_id,
         })
      );
      this.store.dispatch(loadWatchlistSymbols());
      return response;
   }

   async deleteWatchlist(watchlist_id: string) {
      const response = await lastValueFrom(
         this.http.post(this.url + '/api/watchlists/delete', {
            watchlist_id: watchlist_id,
         })
      );
      this.store.dispatch(loadWatchlistSymbols());
      return response;
   }

   async copyToWatchlist(investorId: any, name: string, onlyHalal: boolean) {
      return await lastValueFrom(
         this.http.post(
            this.url + `/api/super-investor/${investorId}/watchlist`,
            {
               name: name,
               only_halal: onlyHalal,
            }
         )
      );
   }

   async getCount() {
      return await lastValueFrom(
         this.http.get(this.url + `/api/watchlists/count`)
      );
   }

   openAddListsPopup(stock: any, etf: any) {
      this.addListsModalRef = this.modalService.open(AddListsComponent, {
         centered: true,
         // scrollable: true,
         backdropClass: 'global-modal-backdrop',
         windowClass: 'rounded-border-popup',
      });
      this.addListsModalRef.componentInstance.stock = stock;
      this.addListsModalRef.componentInstance.etfs = etf;
   }

   closeAddListsPopup() {
      if (this.addListsModalRef) {
         this.addListsModalRef.close();
      }
   }

   openCopyToWatchlistPopup(mainHeading: string, popupDescription: string, successHeading: string, successDescription: string) {
      this.copyToWatchlistModalRef = this.modalService.open(CopyToWatchlistPopupComponent, {
         centered: true,
         backdropClass: 'global-modal-backdrop',
         windowClass: 'rounded-border-popup',
         backdrop: 'static'
      });
      this.copyToWatchlistModalRef.componentInstance.mainHeading = mainHeading;
      this.copyToWatchlistModalRef.componentInstance.popupDescription = popupDescription;
      this.copyToWatchlistModalRef.componentInstance.successHeading = successHeading;
      this.copyToWatchlistModalRef.componentInstance.successDescription = successDescription;
   }

   closeCopyToWatchlistPopup() {
      if (this.copyToWatchlistModalRef) {
         this.copyToWatchlistModalRef.close();
      }
   }
}
