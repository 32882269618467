<button class="d-flex align-items-center justify-content-center gap-2 social-login-btn" (click)="googleSign()" [ngClass]="googleBtnStyling">
   <img src="/assets/icons/google-icon.svg" class="img-fluid" alt="Google icon">
   <span class="text-start">Continue with Google</span>
</button>

<!-- <div class="google__main">
    <button class="btn" type="button" (click)="googleSign()">
        <img src="../../../assets/images/google.svg" alt="">
        <span>Continue with Google</span>
    </button>
</div> -->
