<div class="login-form-container" [ngClass]="formStyling">

   <h3 class="form-heading">Forgot your password?</h3>
   <p class="mb-0 form-welcome-text">Enter your email below to reset it.</p>
   <form class="login-form d-grid gap-3" [formGroup]="forgotPasswordForm" (ngSubmit)="sendResetInstructions()">
      <div>
         <div class="d-flex align-items-center input-group-wrapper" [ngClass]="{'invalid': forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.invalid}">
            <svg class="input-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#A8A8A8" fill="none">
               <path d="m7 7.5 2.942 1.74c1.715 1.014 2.4 1.014 4.116 0L17 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M10.5 19.5a116 116 0 0 1-1.401-.027c-3.149-.079-4.723-.118-5.854-1.255-1.131-1.136-1.164-2.67-1.23-5.737a69 69 0 0 1 0-2.953c.066-3.067.099-4.6 1.23-5.737C4.376 2.655 5.95 2.616 9.099 2.537a115 115 0 0 1 5.802 0c3.149.079 4.723.118 5.854 1.254s1.164 2.67 1.23 5.737c.009.455.014.668.015.972" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M19 17a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0 0v.5a1.5 1.5 0 0 0 3 0V17a4.5 4.5 0 1 0-4.5 4.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <input type="email" id="email" name="email" class="input-field" placeholder="Enter Email Address" formControlName="email">
            <label for="email" class="input-label">Enter Email Address</label>
         </div>
         <small class="text-danger error-msg-text" *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.errors?.required">
            Email is required
         </small>
         <small class="text-danger error-msg-text" *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.errors?.pattern">
            Email is Invalid
         </small>
         <small class="text-danger error-msg-text" *ngIf="errorMessage.length > 0">
            {{ errorMessage }}
         </small>
      </div>

      <article class="alert alert-info d-flex align-items-center my-0 p-2 gap-2">
         <svg class="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22" color="#055160" fill="none">
            <path d="M2.5 12c0-4.478 0-6.718 1.391-8.109S7.521 2.5 12 2.5c4.478 0 6.718 0 8.109 1.391S21.5 7.521 21.5 12c0 4.478 0 6.718-1.391 8.109S16.479 21.5 12 21.5c-4.478 0-6.718 0-8.109-1.391S2.5 16.479 2.5 12Z" stroke="currentColor" stroke-width="2"/>
            <path d="M12 16h.009" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13V7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>
         <p class="mb-0">You will receive an email with a link to reset your password.</p>
      </article>

      <button type="submit" class="border-0 d-block login-btn gradient-bg-btn" [disabled]="!forgotPasswordForm.valid || resetLoader">
         <div class="spinner-border spinner-border-sm me-2" role="status" *ngIf="resetLoader">
            <span class="visually-hidden">Loading...</span>
         </div>
         <span>{{ resetLoader ? 'Sending...' : 'Send Reset Instructions' }}</span>
      </button>
   </form>

   <!-- <div class="d-flex align-items-center justify-content-between mx-auto flex-wrap not-account-para">
      <p class="mb-0">Don't have an account? <a class="text-decoration-underline d-inline-block" routerLink='/authentication/registration'>Sign up</a></p>
   </div>

   <div class="social-login-btns-wrapper">
      <button class="d-flex align-items-center justify-content-center gap-3 social-login-btn ">
         <svg width="30" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#a)">
               <path d="m7.149 18.13-1.045 3.898-3.816.08A14.9 14.9 0 0 1 .5 15c0-2.487.605-4.833 1.677-6.898h.001l3.398.623 1.488 3.377A8.9 8.9 0 0 0 6.584 15a8.9 8.9 0 0 0 .565 3.13" fill="#FBBB00"/>
               <path d="M30.238 12.198c.172.907.262 1.844.262 2.802 0 1.074-.113 2.121-.328 3.132a15 15 0 0 1-5.281 8.566v-.001l-4.28-.219-.607-3.781a8.94 8.94 0 0 0 3.847-4.565h-8.02v-5.934z" fill="#518EF8"/>
               <path d="M24.89 26.697A14.94 14.94 0 0 1 15.5 30c-5.713 0-10.68-3.193-13.213-7.892l4.861-3.979a8.92 8.92 0 0 0 12.856 4.568z" fill="#28B446"/><path d="m25.075 3.453-4.86 3.979a8.922 8.922 0 0 0-13.15 4.67l-4.886-4h-.001C4.674 3.288 9.703 0 15.5 0c3.64 0 6.976 1.296 9.574 3.453" fill="#F14336"/>
            </g>
            <defs>
               <clipPath id="a">
                  <path fill="#fff" d="M.5 0h30v30H.5z"/>
               </clipPath>
            </defs>
         </svg>
         <span>Continue with Google</span>
      </button>
      <button class="d-flex align-items-center justify-content-center gap-3 social-login-btn ">
         <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#a)" fill="#000">
               <path d="M20.625 0c-1.6.11-3.47 1.134-4.559 2.467-.993 1.21-1.81 3.006-1.492 4.752 1.747.054 3.553-.994 4.6-2.35.978-1.262 1.719-3.047 1.45-4.869"/>
               <path d="M26.944 10.065C25.41 8.14 23.25 7.022 21.212 7.022c-2.69 0-3.828 1.288-5.698 1.288-1.927 0-3.392-1.284-5.718-1.284-2.286 0-4.72 1.397-6.263 3.785-2.17 3.364-1.798 9.688 1.718 15.075 1.258 1.928 2.938 4.095 5.135 4.114 1.956.019 2.507-1.254 5.156-1.267 2.65-.015 3.152 1.284 5.104 1.263 2.2-.017 3.971-2.418 5.23-4.346.901-1.382 1.237-2.077 1.936-3.637-5.087-1.937-5.902-9.171-.868-11.948"/>
            </g>
            <defs>
               <clipPath id="a">
                  <path fill="#fff" d="M0 0h30v30H0z"/>
               </clipPath>
            </defs>
         </svg>
         <span>Continue with Apple</span>
      </button>
   </div> -->

</div>

