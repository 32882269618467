<!-- <section class="pt-4 pt-lg-5 bg-white">
   <div class="container text-center">
      <h3 class="fw-600 mb-3">As Featured In</h3>

      <div class="scroller" [attr.data-direction]="direction" [attr.data-speed]="speed">
         <ul role="list" class="d-flex align-items-center scroller__inner">
            <li class="d-flex align-items-center logos-list-item overflow-hidden"
               *ngFor="let feature of featuredInLogos; trackBy: trackByFn">
               <a [href]="feature.redirectUrl" target="_blank">
                  <img [src]="feature.imagePath" class="img-fluid" [alt]="feature.id">
               </a>
            </li>
         </ul>
      </div>

   </div>
</section> -->


<section class="bg-white as-features-section pt-4 pt-lg-5">
   <div class="container text-center">
      <h3 class="fw-600 mb-3">As Featured In</h3>
   </div>

   <div class="mt-4">
      <owl-carousel-o [options]="customOptions">
         <ng-container *ngFor="
               let feature of isMobile ? featuredInLogos2 : featuredInLogos;
               trackBy: trackByFn
            ">
            <ng-template carouselSlide>
               <div class="d-flex align-items-center justify-content-center" style="height: 60px">
                  <a [href]="feature.redirectUrl" target="_blank">
                     <img [src]="feature.imagePath" class="img-fluid" [alt]="feature.id" />
                  </a>
               </div>
            </ng-template>
         </ng-container>
      </owl-carousel-o>
   </div>
</section>