import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'reletedNewsChange' })

export class ReletedNewsChangePipe implements PipeTransform {
  transform(value: any[], showAll: boolean): any[] {
    if (showAll) {
      return value;
    } else {
      return value.slice(0,5)
    }
  }
}



