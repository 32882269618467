<ng-container *ngIf="loader">
   <ngx-skeleton-loader
      count="15"
      appearance="line"
      animation="progress"
      [theme]="{ height: '60px', width: '100%', margin: '0px 0px 8px 0px' }"
   >
   </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="!loader">
   <div class="px-3" *ngIf="isMobile">
      <div class="table-responsive mobile-table-wrapper">
         <header class="d-flex align-items-center flex-wrap p-3 p-lg-4 gap-2">
            <h4 class="mb-0 table-sec-heading">
               Holdings
               <span
                  class="text-secondary fs-6 d-inline-block fw-400"
                  *ngIf="etfsCount > 0"
                  >({{ etfsCount }})</span
               >
            </h4>
            <div
               class="d-flex align-items-center gap-3 overflowing-slider"
               role="group"
               aria-label="Filter ETFs"
               *ngIf="etfs.length > 0 && canViewEtfComplianceReport"
            >
               <button
                  *ngFor="let filter of filterOptions"
                  type="button"
                  class="btn filter-btn overflowing-slider-item text-nowrap shadow-none"
                  [ngClass]="isSelected(filter.value) ? 'active-filter' : ''"
                  (click)="filterEtfs(filter.value)"
               >
                  {{ filter.label }}
               </button>
            </div>
         </header>

         <div
            class="px-3 mb-3 mb-lg-4 px-lg-4"
            *ngIf="etfComplianceStatus === 'COMPLIANT'"
         >
            <p class="mb-0 p-2 p-lg-3 halal-etf-para">
               One of the holdings of this ETF fails the criteria of Core
               Business Activity (CBA) screening. As per the Shariah rules, even
               if 1 holding fails the CBA screening, the entire ETF is deemed as
               Not-Halal. Please, check the detailed report section to find the
               Core Business Activity (CBA) screening results of each holding.
            </p>
         </div>

         <table class="table mb-0">
            <thead>
               <tr>
                  <th class="text-uppercase fw-normal" scope="col"></th>
                  <th class="text-uppercase fw-normal" scope="col">Name</th>
                  <th class="text-uppercase fw-normal text-end" scope="col">
                     Weight
                  </th>
                  <th class="text-uppercase fw-normal text-center" scope="col">
                     Compliance
                  </th>
                  <th class="text-uppercase fw-normal text-center" scope="col">
                     Rating
                  </th>
               </tr>
            </thead>
            <tbody>
               <ng-container *ngIf="etfs.length > 0; else noStocks">
                  <tr
                     *ngFor="
                        let stock of displayETFHoldings();
                        let index;
                        as: i
                     "
                  >
                     <td class="align-middle p-0 star-td">
                        <app-watchlist-button
                           [stock]="stock"
                        ></app-watchlist-button>
                     </td>
                     <td class="align-middle p-0">
                        <a
                           class="d-flex align-items-center gap-2 text-decoration-none stock-image-and-name"
                           routerLink="/stock/{{ stock.symbol }}"
                        >
                           <div
                              class="stock-image-wrapper border flex-shrink-0 bg-light rounded-circle overflow-hidden"
                           >
                              <img
                                 [src]="stock.logo"
                                 [alt]=""
                                 class="img-fluid"
                                 *ngIf="stock.logo"
                              />
                              <div
                                 *ngIf="!stock.logo"
                                 class="d-flex h-100 text-black align-items-center justify-content-center fs-5 fw-bold"
                              >
                                 {{ stock.name.charAt(0) }}
                              </div>
                           </div>
                           <div class="d-block">
                              <h5 class="mb-0 line-clamp-1 stock-name">
                                 {{ stock.name }}
                              </h5>
                              <p class="mb-0 line-clamp-1 stock-symbol">
                                 {{ stock.symbol }}
                              </p>
                           </div>
                        </a>
                     </td>
                     <td class="align-middle p-0 price-and-change-td">
                        <a
                           class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-price-and-change"
                           routerLink="/stock/{{ stock.symbol }}"
                        >
                           <span
                              class="stock-price text-end"
                              *ngIf="stock.percent; else noPrice"
                           >
                              {{ stock.percent.toFixed(2) }}%
                           </span>
                           <ng-template #noPrice>-</ng-template>
                        </a>
                     </td>
                     <td class="align-middle p-0 compliance-td">
                        <a
                           class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-compliance mx-auto"
                           routerLink="/stock/{{ stock.symbol }}"
                        >
                           <app-compliance-chip
                              [symbol]="stock.isin"
                              [complianceStatus]="stock.complianceStatus"
                              [complianceRanking]="stock.complianceRanking"
                              [complianceRankingV2]="stock.complianceRankingV2"
                              [onlyCompliance]="true"
                              [preloaded]="true"
                           >
                           </app-compliance-chip>
                        </a>
                     </td>
                     <td class="align-middle p-0 compliance-td">
                        <a
                           class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-compliance"
                           routerLink="/stock/{{ stock.symbol }}"
                        >
                           <app-compliance-chip
                              [symbol]="stock.isin"
                              [complianceStatus]="stock.complianceStatus"
                              [complianceRanking]="stock.complianceRanking"
                              [complianceRankingV2]="stock.complianceRankingV2"
                              [onlyRanking]="true"
                              [preloaded]="true"
                           >
                           </app-compliance-chip>
                        </a>
                     </td>
                  </tr>
                  <tr class="lock-overlay" *ngIf="!isLoggedIn">
                     <td colspan="4" class="align-middle">
                        <button
                           (click)="openLoginPopup()"
                           class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn"
                        >
                           <img
                              src="/assets/icons/lock-black.svg"
                              alt="lock image"
                              class="img-fluid"
                           />
                           <!-- <span>Sign-up & Upgrade to Unlock!</span> -->
                           <span>Unlock</span>
                        </button>
                     </td>
                  </tr>
                  <tr
                     class="lock-overlay"
                     *ngIf="isLoggedIn && !canViewEtfComplianceReport"
                  >
                     <td colspan="4" class="align-middle">
                        <button
                           (click)="openUpgradePopup()"
                           class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn"
                        >
                           <img
                              src="/assets/icons/lock-black.svg"
                              alt="lock image"
                              class="img-fluid"
                           />
                           <!-- <span>Upgrade & Unlock!</span> -->
                           <span>Unlock</span>
                        </button>
                     </td>
                  </tr>
                  <tr class="bg-white no-data-tr" *ngIf="etfsCount === 0">
                     <td class="align-middle text-center p-3" colspan="6">
                        No Holdings available
                     </td>
                  </tr>
               </ng-container>
               <ng-template #noStocks>
                  <tr class="bg-white no-data-tr">
                     <td class="align-middle text-center p-3" colspan="6">
                        No Holdings available for this ETF
                     </td>
                  </tr>
               </ng-template>
            </tbody>
         </table>

         <div class="my-4" *ngIf="etfsCount > 0 && canViewEtfComplianceReport">
            <ngb-pagination
               class="d-flex justify-content-center"
               [collectionSize]="etfsCount"
               [(page)]="page"
               [pageSize]="pageSize"
               [maxSize]="3"
               (pageChange)="onPageChange()"
            >
            </ngb-pagination>
         </div>
      </div>
   </div>

   <div class="table-responsive desktop-table-wrapper" *ngIf="!isMobile">
      <header
         class="d-flex align-items-center p-3 p-lg-4 justify-content-between gap-3"
      >
         <h4 class="mb-0 table-sec-heading">
            Holdings
            <span
               class="text-secondary fs-5 d-inline-block fw-400"
               *ngIf="etfsCount > 0"
               >({{ etfsCount }})</span
            >
         </h4>
         <div
            class="d-flex align-items-center gap-3 overflowing-slider"
            role="group"
            aria-label="Filter ETFs"
            *ngIf="etfs.length > 0 && canViewEtfComplianceReport"
         >
            <button
               *ngFor="let filter of filterOptions"
               type="button"
               class="btn filter-btn overflowing-slider-item text-nowrap shadow-none"
               [ngClass]="isSelected(filter.value) ? 'active-filter' : ''"
               (click)="filterEtfs(filter.value)"
            >
               {{ filter.label }}
            </button>
         </div>
      </header>

      <div
         class="px-3 mb-3 mb-lg-4 px-lg-4"
         *ngIf="checkShowHoldingsDisclaimer()"
      >
         <p class="mb-0 p-2 p-lg-3 halal-etf-para">
            Few holdings of this ETF may be individually non-compliant, but the
            overall ETF is Halal based on the Core Business Activity used to
            screen the ETFs. This ETF screening methodology is approved by the
            Shariah advisors.
         </p>
      </div>

      <table class="table mb-0">
         <thead>
            <tr>
               <th class="text-uppercase fw-normal" scope="col"></th>
               <th class="text-uppercase fw-normal" scope="col">Name</th>
               <th
                  class="text-uppercase fw-normal text-end"
                  scope="col"
                  [class.colsort]="canSort()"
                  (click)="canSort() ? onSort('percent') : null"
               >
                  Weight
                  <i
                     [hidden]="!canSort()"
                     [ngClass]="getColumnSortClasses('percent')"
                  ></i>
               </th>
               <th
                  class="text-uppercase fw-normal text-center"
                  scope="col"
                  [class.colsort]="canSort() && selectedFilter === 'halal'"
                  (click)="
                     canSort() && selectedFilter === 'halal'
                        ? onSort('complianceRanking')
                        : null
                  "
               >
                  Compliance
                  <i
                     [hidden]="!canSort() && selectedFilter !== 'halal'"
                     [ngClass]="getColumnSortClasses('complianceRanking')"
                  ></i>
               </th>
               <th
                  class="text-uppercase fw-normal text-center"
                  scope="col"
                  [class.colsort]="canSort() && selectedFilter === 'halal'"
                  (click)="
                     canSort() && selectedFilter === 'halal'
                        ? onSort('complianceRanking')
                        : null
                  "
               >
                  Rating
                  <i
                     [hidden]="!canSort() && selectedFilter !== 'halal'"
                     [ngClass]="getColumnSortClasses('complianceRanking')"
                  ></i>
               </th>
            </tr>
         </thead>
         <tbody>
            <ng-container *ngIf="etfs.length > 0; else noStocks">
               <tr
                  *ngFor="
                     let stock of displayETFHoldings()
                        | slice : (page - 1) * pageSize : page * pageSize
                  "
               >
                  <td class="align-middle p-0 star-td">
                     <app-watchlist-button
                        [stock]="stock"
                     ></app-watchlist-button>
                  </td>
                  <td class="align-middle p-0">
                     <a
                        class="d-flex align-items-center gap-2 text-decoration-none stock-image-and-name"
                        routerLink="/stock/{{ stock.symbol }}"
                     >
                        <div
                           class="stock-image-wrapper border flex-shrink-0 bg-light rounded-circle overflow-hidden"
                        >
                           <img
                              [src]="stock.logo"
                              [alt]=""
                              class="img-fluid"
                              *ngIf="stock.logo"
                           />
                           <div
                              *ngIf="!stock.logo"
                              class="d-flex h-100 text-black align-items-center justify-content-center fs-5 fw-bold"
                           >
                              {{ stock.name.charAt(0) }}
                           </div>
                        </div>
                        <div class="d-block">
                           <h5 class="mb-0 line-clamp-1 stock-name">
                              {{ stock.name }}
                           </h5>
                           <p class="mb-0 line-clamp-1 stock-symbol">
                              {{ stock.symbol }}
                           </p>
                        </div>
                     </a>
                  </td>
                  <td class="align-middle p-0 price-td">
                     <a
                        class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-price"
                        routerLink="/stock/{{ stock.symbol }}"
                     >
                        <span
                           *ngIf="
                              stock.percent !== null &&
                                 stock.percent !== undefined;
                              else noPrice
                           "
                        >
                           {{ stock.percent.toFixed(2) }}%
                        </span>
                        <ng-template #noPrice>-</ng-template>
                     </a>
                  </td>
                  <td class="align-middle p-0 compliance-td">
                     <a
                        class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-compliance"
                        routerLink="/stock/{{ stock.symbol }}"
                     >
                        <app-compliance-chip
                           [symbol]="stock.isin"
                           [complianceStatus]="stock.complianceStatus"
                           [complianceRanking]="stock.complianceRanking"
                           [complianceRankingV2]="stock.complianceRankingV2"
                           [onlyCompliance]="true"
                           [preloaded]="true"
                        >
                        </app-compliance-chip>
                     </a>
                  </td>
                  <td class="align-middle p-0 compliance-td">
                     <a
                        class="d-flex align-items-center justify-content-end gap-2 text-decoration-none stock-compliance"
                        routerLink="/stock/{{ stock.symbol }}"
                     >
                        <app-compliance-chip
                           [symbol]="stock.isin"
                           [complianceStatus]="stock.complianceStatus"
                           [complianceRanking]="stock.complianceRanking"
                           [complianceRankingV2]="stock.complianceRankingV2"
                           [onlyRanking]="true"
                           [preloaded]="true"
                        >
                        </app-compliance-chip>
                     </a>
                  </td>
               </tr>
               <tr class="bg-white no-data-tr" *ngIf="etfsCount === 0">
                  <td class="align-middle text-center p-3" colspan="6">
                     No Holdings available
                  </td>
               </tr>
               <tr class="lock-overlay" *ngIf="!isLoggedIn">
                  <td colspan="4" class="align-middle">
                     <button
                        (click)="openLoginPopup()"
                        class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn"
                     >
                        <img
                           src="/assets/icons/lock-black.svg"
                           alt="lock image"
                           class="img-fluid"
                        />
                        <span>Sign-up & Upgrade to Unlock!</span>
                     </button>
                  </td>
               </tr>
               <tr
                  class="lock-overlay"
                  *ngIf="isLoggedIn && !canViewEtfComplianceReport"
               >
                  <td colspan="4" class="align-middle">
                     <button
                        (click)="openUpgradePopup()"
                        class="btn mx-auto shadow-none text-black rounded-3 d-flex align-items-center justify-content-center gap-2 lock-card-btn view-all-btn"
                     >
                        <img
                           src="/assets/icons/lock-black.svg"
                           alt="lock image"
                           class="img-fluid"
                        />
                        <span>Upgrade & Unlock!</span>
                     </button>
                  </td>
               </tr>
            </ng-container>
            <ng-template #noStocks>
               <tr class="bg-white no-data-tr">
                  <td class="align-middle text-center p-3" colspan="7">
                     No Holdings available for this ETF
                  </td>
               </tr>
            </ng-template>
         </tbody>
      </table>

      <div
         class="my-4 pe-3"
         *ngIf="etfsCount > 0 && canViewEtfComplianceReport"
      >
         <ngb-pagination
            class="d-flex justify-content-end"
            [collectionSize]="etfsCount"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="3"
            (pageChange)="onPageChange()"
         >
         </ngb-pagination>
      </div>
   </div>
</ng-container>
