import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Checkbox, FormValues } from 'src/app/models/zakat.model';

@Component({
  selector: 'app-liabilities',
  templateUrl: './liabilities.component.html',
  styleUrls: ['./liabilities.component.scss']
})
export class LiabilitiesComponent implements OnInit {
  form!: FormGroup;
  @Input() selectedInputs: Checkbox[]  = []
  inputFields: string | string[] = []
  selectedCurrency!: string | null

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.selectedCurrency = localStorage.getItem('selectedCurrency');
    if(this.selectedCurrency) {
      this.selectedCurrency = this.selectedCurrency?.replace(/^"|"$/g, '')
    }
    this.getLiabilityObject(this.selectedInputs)
    this.form = this.rootFormGroup.control as FormGroup;
  }

  getLiabilityObject(arr: Checkbox[]) {
    const obj = arr.find((obj:Checkbox) => obj.id === 'liability')
    
    if(obj?.inputs) {
      this.inputFields = obj?.inputs
    }
  }

}
