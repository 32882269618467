import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { AccessControlService } from "src/app/shared/services/access-control.service";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { FirestoreService } from "src/app/shared/services/firestore.service";
import { LoaderServices } from "src/app/shared/services/loader.service";
import { TypesenseService } from "src/app/shared/services/typesense.service";
import { WatchlistService } from "src/app/shared/services/watchlist.service";
import { PermissionsState } from "src/app/stores/access-control/access-control.reducers";

@Component({
   selector: 'app-add-lists',
   templateUrl: './add-lists.component.html',
   styleUrls: ['./add-lists.component.scss'],
})

export class AddListsComponent implements OnInit, OnDestroy {
   @Input() appearance: boolean = false;
   _stock: any;
   etf: any;
   submitLoading = false;
   fetchLoading = false;
   @Input() set stock(value: any) {
      this._stock = value;

      if (value) {
         this.getData();
      }
   }
   @Input() set etfs(value: any) {
      this.etf = value;

      if (value) {
         this.getData();
      }
   }

   private subscriptions: Subscription[] = [];
   public showList: boolean = false;
   public items: any[] = [];
   public itemInWatchlists: any[] = [];
   public page: number = 0;
   public inOnePage: number = 100;
   public id: string | undefined;
   public itemIncludedInAll: boolean = false;
   public watchlistItemsAccess: boolean = false;
   public maxWatchlistCount: number = 0;
   public maxWatchlistItemsCount: number = 0;

   constructor(
      public activeAddListModal: NgbActiveModal,
      private apiservise: ApiService,
      private authService: AuthService,
      private watchlistService: WatchlistService,
      private auth: AuthService,
      private typesenseService: TypesenseService,
      private toaster: ToastrService,
      private firestoreService: FirestoreService,
      private loader: LoaderServices,
      private permissionStore: Store<{ permissions: PermissionsState }>,
      private accessControlService: AccessControlService
   ) {}

   ngOnInit(): void {
      this.subscriptions.push(
         this.permissionStore
            .select(({ permissions }) => permissions.permissions)
            .subscribe((permissions) => {
               this.maxWatchlistCount = permissions.watchlist_count;
               this.maxWatchlistItemsCount = permissions.watchlist_items_count;
               this.watchlistItemsAccess = permissions.watchlist_items;
            })
      );
   }

   ngOnDestroy(): void {
      if (this.subscriptions && this.subscriptions.length > 0) {
         this.subscriptions.forEach((item) => {
            item.unsubscribe();
         });
      }
   }

   trackByFn(index: number, item: any): any {
      return item.id;
   }

   getData() {
      if (this.authService.getUserId()?.length) {
         this.fetchLoading = true;
         this.watchlistService.fetchAllWatchlist().subscribe((data: any) => {
            this.fetchLoading = false;
            this.items = data.data;
            this.items.forEach((item: any) => {
               item.checked = false;
            });
            this.getNotIncludedWatchlists(this.items);
         });
      }
   }

   checkRole(role: string) {
      return this.auth.checkRole(role);
   }

   createNewList() {
      this.showList = true;
   }

   getNotIncludedWatchlists(watchlists: any) {
      if (this._stock || this.etf) {
         let ticker: any;
         if (this._stock) {
            ticker = this._stock.ticker ?? this._stock.symbol;
         } else if (this.etf) {
            ticker = this.etf.symbol;
         }

         this.itemInWatchlists = watchlists
            .filter((watchlist: any) => {
               return watchlist.watchlist_symbols
                  .map((stock: any) => stock.symbol)
                  .some((stockSymbol: any) => stockSymbol === ticker);
            })
            .map((watchlist: any) => watchlist.id);
         this.items.forEach((item, index) => {
            this.items[index].checked = this.itemInWatchlists.includes(item.id);
         });

         this.itemIncludedInAll =
            watchlists.length === this.itemInWatchlists.length;
      } else {
         this.itemIncludedInAll = false;
      }
   }

   addLists() {
      this.appearance = false;
   }

   closeAddForm() {
      this.showList = false;
   }

   closeMe() {
      // this.showList = false;
      this.activeAddListModal.close()
      // this.close.emit();
   }

   public watchlistAdd() {
      if (this.items && this.items.length > 0) {
         let selectedWatchlists = this.items
            .filter(
               (item) =>
                  item.checked && !this.itemInWatchlists.includes(item.id)
            )
            .map((obj: any) => {
               obj.isAdd = true;
               return obj;
            });
         let unSelectedWatchlists = this.items
            .filter(
               (item) =>
                  !item.checked && this.itemInWatchlists.includes(item.id)
            )
            .map((obj: any) => {
               obj.isAdd = false;
               return obj;
            });
         let updatedWatchlists =
            selectedWatchlists.concat(unSelectedWatchlists);
         let updatedWatchlistsLenth = updatedWatchlists.length;

         if (updatedWatchlistsLenth > 0) {
            this.loader.show();
            this.submitLoading = true;
            updatedWatchlists.forEach((watchlist, index) => {
               if (this.etf) {
                  if (watchlist.checked) {
                     this.watchlistService
                        .addStockToWatchlist(
                           watchlist.id,
                           this.etf.symbol,
                           'etf'
                        )
                        .then(() => {
                           if (index == updatedWatchlistsLenth - 1) {
                              this.loader.hide();
                              this.submitLoading = false;
                              this.toaster.success('Watchlist updated');
                              this.closeMe();
                           }
                        })
                        .catch((error: HttpErrorResponse) => {
                           console.log(error);
                           this.loader.hide();
                           this.submitLoading = false;
                        });
                  } else {
                     this.watchlistService
                        .deleteStockFromWatchlist(watchlist.id, this.etf.symbol)
                        .then(() => {
                           if (index == updatedWatchlistsLenth - 1) {
                              this.loader.hide();
                              this.submitLoading = false;
                              this.toaster.success('Watchlist updated');
                              this.closeMe();
                           }
                        })
                        .catch((error: HttpErrorResponse) => {
                           console.log(error);
                           this.loader.hide();
                           this.submitLoading = false;
                        });
                  }
               } else {
                  if (watchlist.checked) {
                     this.watchlistService
                        .addStockToWatchlist(
                           watchlist.id,
                           this._stock.ticker ?? this._stock.symbol,
                           'stock'
                        )
                        .then(() => {
                           if (index == updatedWatchlistsLenth - 1) {
                              this.loader.hide();
                              this.submitLoading = false;
                              this.toaster.success('Watchlist updated');
                              this.closeMe();
                           }
                        })
                        .catch((error: HttpErrorResponse) => {
                           console.log(error);
                           this.loader.hide();
                           this.submitLoading = false;
                        });
                  } else {
                     this.watchlistService
                        .deleteStockFromWatchlist(
                           watchlist.id,
                           this._stock.ticker ?? this._stock.symbol
                        )
                        .then(() => {
                           if (index == updatedWatchlistsLenth - 1) {
                              this.loader.hide();
                              this.submitLoading = false;
                              this.toaster.success('Watchlist updated');
                              this.closeMe();
                           }
                        })
                        .catch((error: HttpErrorResponse) => {
                           console.log(error);
                           this.loader.hide();
                           this.submitLoading = false;
                        });
                  }
               }
            });
         } else {
            this.closeMe();
         }
      }
   }

   removeFromWatchlist() {}
   addToWatchlist() {}

   hasCheckedItem() {
      return this.items.filter((item) => item.checked).length > 0;
   }

}




// import { HttpErrorResponse } from '@angular/common/http';
// import {
//    Component,
//    EventEmitter,
//    Input,
//    OnDestroy,
//    OnInit,
//    Output,
// } from '@angular/core';
// import { Store } from '@ngrx/store';
// import { ToastrService } from 'ngx-toastr';
// import { Subscription } from 'rxjs';
// import { AccessControlService } from 'src/app/shared/services/access-control.service';
// import { ApiService } from 'src/app/shared/services/api.service';
// import { AuthService } from 'src/app/shared/services/auth.service';
// import { FirestoreService } from 'src/app/shared/services/firestore.service';
// import { LoaderServices } from 'src/app/shared/services/loader.service';
// import { TypesenseService } from 'src/app/shared/services/typesense.service';
// import { WatchlistService } from 'src/app/shared/services/watchlist.service';
// import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';

// @Component({
//    selector: 'app-add-lists',
//    templateUrl: './add-lists.component.html',
//    styleUrls: ['./add-lists.component.scss'],
// })
// export class AddListsComponent implements OnInit, OnDestroy {
//    @Input() appearance: boolean = false;
//    public _stock: any;
//    public etf: any;
//    submitLoading = false;
//    fetchLoading = false;
//    @Input() set stock(value: any) {
//       this._stock = value;

//       if (value) {
//          this.getData();
//       }
//    }
//    @Input() set etfs(value: any) {
//       this.etf = value;

//       if (value) {
//          this.getData();
//       }
//    }

//    @Output() close: EventEmitter<void> = new EventEmitter();

//    private subscriptions: Subscription[] = [];
//    public showList: boolean = false;
//    public items: any[] = [];
//    public itemInWatchlists: any[] = [];
//    public page: number = 0;
//    public inOnePage: number = 100;
//    public id: string | undefined;
//    public itemIncludedInAll: boolean = false;
//    public watchlistItemsAccess: boolean = false;
//    public maxWatchlistCount: number = 0;
//    public maxWatchlistItemsCount: number = 0;

//    constructor(
//       private apiservise: ApiService,
//       private authService: AuthService,
//       private watchlistService: WatchlistService,
//       private auth: AuthService,
//       private typesenseService: TypesenseService,
//       private toaster: ToastrService,
//       private firestoreService: FirestoreService,
//       private loader: LoaderServices,
//       private permissionStore: Store<{ permissions: PermissionsState }>,
//       private accessControlService: AccessControlService
//    ) {}

//    ngOnInit(): void {
//       this.subscriptions.push(
//          this.permissionStore
//             .select(({ permissions }) => permissions.permissions)
//             .subscribe((permissions) => {
//                this.maxWatchlistCount = permissions.watchlist_count;
//                this.maxWatchlistItemsCount = permissions.watchlist_items_count;
//                this.watchlistItemsAccess = permissions.watchlist_items;
//             })
//       );
//    }

//    ngOnDestroy(): void {
//       if (this.subscriptions && this.subscriptions.length > 0) {
//          this.subscriptions.forEach((item) => {
//             item.unsubscribe();
//          });
//       }
//    }

//    getData() {
//       if (this.authService.getUserId()?.length) {
//          this.fetchLoading = true;
//          this.watchlistService.fetchAllWatchlist().subscribe((data: any) => {
//             this.fetchLoading = false;
//             this.items = data.data;
//             this.items.forEach((item: any) => {
//                item.checked = false;
//             });
//             this.getNotIncludedWatchlists(this.items);
//          });
//       }

//    }

//    checkRole(role: string) {
//       return this.auth.checkRole(role);
//    }

//    createNewList() {
//       this.showList = true;

//    }

//    getNotIncludedWatchlists(watchlists: any) {
//       if (this._stock || this.etf) {
//          let ticker: any;
//          if (this._stock) {
//             ticker = this._stock.ticker ?? this._stock.symbol;
//          } else if (this.etf) {
//             ticker = this.etf.symbol;
//          }

//          this.itemInWatchlists = watchlists
//             .filter((watchlist: any) => {
//                return watchlist.watchlist_symbols
//                   .map((stock: any) => stock.symbol)
//                   .some((stockSymbol: any) => stockSymbol === ticker);
//             })
//             .map((watchlist: any) => watchlist.id);
//          this.items.forEach((item, index) => {
//             this.items[index].checked = this.itemInWatchlists.includes(item.id);
//          });

//          this.itemIncludedInAll =
//             watchlists.length === this.itemInWatchlists.length;
//       } else {
//          this.itemIncludedInAll = false;
//       }

//    }

//    addLists() {
//       this.appearance = false;
//    }

//    closeAddForm() {
//       this.showList = false;
//    }

//    closeMe() {
//       this.showList = false;
//       this.close.emit();
//    }

//    public watchlistAdd() {
//       if (this.items && this.items.length > 0) {
//          let selectedWatchlists = this.items
//             .filter(
//                (item) =>
//                   item.checked && !this.itemInWatchlists.includes(item.id)
//             )
//             .map((obj: any) => {
//                obj.isAdd = true;
//                return obj;
//             });
//          let unSelectedWatchlists = this.items
//             .filter(
//                (item) =>
//                   !item.checked && this.itemInWatchlists.includes(item.id)
//             )
//             .map((obj: any) => {
//                obj.isAdd = false;
//                return obj;
//             });
//          let updatedWatchlists =
//             selectedWatchlists.concat(unSelectedWatchlists);
//          let updatedWatchlistsLenth = updatedWatchlists.length;

//          if (updatedWatchlistsLenth > 0) {
//             this.loader.show();
//             this.submitLoading = true;
//             updatedWatchlists.forEach((watchlist, index) => {
//                if (this.etf) {
//                   if (watchlist.checked) {
//                      this.watchlistService
//                         .addStockToWatchlist(
//                            watchlist.id,
//                            this.etf.symbol,
//                            'etf'
//                         )
//                         .then(() => {
//                            if (index == updatedWatchlistsLenth - 1) {
//                               this.loader.hide();
//                               this.submitLoading = false;
//                               this.toaster.success('Watchlist updated');
//                               this.closeMe();
//                            }
//                         })
//                         .catch((error: HttpErrorResponse) => {
//                            console.log(error);
//                            this.loader.hide();
//                            this.submitLoading = false;
//                         });
//                   } else {
//                      this.watchlistService
//                         .deleteStockFromWatchlist(watchlist.id, this.etf.symbol)
//                         .then(() => {
//                            if (index == updatedWatchlistsLenth - 1) {
//                               this.loader.hide();
//                               this.submitLoading = false;
//                               this.toaster.success('Watchlist updated');
//                               this.closeMe();
//                            }
//                         })
//                         .catch((error: HttpErrorResponse) => {
//                            console.log(error);
//                            this.loader.hide();
//                            this.submitLoading = false;
//                         });
//                   }
//                } else {
//                   if (watchlist.checked) {
//                      this.watchlistService
//                         .addStockToWatchlist(
//                            watchlist.id,
//                            this._stock.ticker ?? this._stock.symbol,
//                            'stock'
//                         )
//                         .then(() => {
//                            if (index == updatedWatchlistsLenth - 1) {
//                               this.loader.hide();
//                               this.submitLoading = false;
//                               this.toaster.success('Watchlist updated');
//                               this.closeMe();
//                            }
//                         })
//                         .catch((error: HttpErrorResponse) => {
//                            console.log(error);
//                            this.loader.hide();
//                            this.submitLoading = false;
//                         });
//                   } else {
//                      this.watchlistService
//                         .deleteStockFromWatchlist(
//                            watchlist.id,
//                            this._stock.ticker ?? this._stock.symbol
//                         )
//                         .then(() => {
//                            if (index == updatedWatchlistsLenth - 1) {
//                               this.loader.hide();
//                               this.submitLoading = false;
//                               this.toaster.success('Watchlist updated');
//                               this.closeMe();
//                            }
//                         })
//                         .catch((error: HttpErrorResponse) => {
//                            console.log(error);
//                            this.loader.hide();
//                            this.submitLoading = false;
//                         });
//                   }
//                }
//             });
//          } else {
//             this.closeMe();
//          }
//       }
//    }

//    removeFromWatchlist() {}
//    addToWatchlist() {}

//    hasCheckedItem() {
//       return this.items.filter((item) => item.checked).length > 0;
//    }
// }
