<div class="purification-options-container px-3">
   <h4 class="options-heading mb-0" *ngIf="userRole === 'PREMIUM'">
      Purification Details
   </h4>
   <a
      routerLink="/cabinet/purifications-methods"
      class="text-decoration-none options-link"
      *ngIf="userRole === 'PREMIUM'"
   >
      How it works? &#8594;
   </a>

   <div class="bg-white" *ngIf="isSample">
      <p class="mb-2 points-text">
         Below result is calculated for the following data points:
      </p>
      <div class="d-flex align-items-center gap-2">
         <div class="date-text d-flex flex-column gap-1">
            <span class="d-block">Shares Qty</span>
            <span class="d-block">Purchase Date</span>
            <span class="d-block">Sell Date</span>
         </div>
         <div class="date-text d-flex flex-column gap-1">
            <span class="d-block">: &nbsp;50</span>
            <span class="d-block">: &nbsp;01st Sep 2023</span>
            <span class="d-block">: &nbsp;30th Sep 2023</span>
         </div>
      </div>
   </div>

   <!-- display for desktop, hide for mobile-->
   <div class="options-wrapper row d-none d-md-flex">
      <!-- <div class="col-4 px-0 option-box">
      <div class=" blank text-center py-2">&nbsp;</div>
      <div class="inner-box box-1 text-center">
        <div class="" *ngIf="isSample">
          <app-sample-bubble [bubbleContent]="dividendIncomeText" tooltipPosition="bottom"></app-sample-bubble>
        </div>
        <h5 class="box-heading">Dividend Purification</h5> -->
      <!-- <p class="mb-0 amount text-uppercase">
          <span class="d-none d-xl-inline">Your purification </span>
          <span>amount</span> // old commented
        </p> -->
      <!-- <p class="box-price">
          <span class="d-block text-center">{{ getCurrency() }}</span>
          <span class="d-block text-center desktop-currency-span">{{ getPrice(data?.dividend['purificationAmount']) }}</span>
        </p>
        <a  routerLink="/cabinet/purifications-methods" *ngIf="userRole === 'PREMIUM'" class="box-link d-none d-md-block text-decoration-none">What to do next &#8594;</a>
      </div>
    </div> -->

      <!-- <div class="col-4 px-0 option-box">
      <div class=" blank text-center py-2">&nbsp;</div>
      <div class="inner-box box-2 text-center">
        <div class="" *ngIf="isSample">
          <app-sample-bubble [bubbleContent]="interestIncomeText" tooltipPosition="bottom"></app-sample-bubble>
        </div>
        <h5 class="box-heading">Interest Income Purification</h5> -->
      <!-- <p class="mb-0 amount text-uppercase">
          <span class="d-none d-xl-inline">Your purification </span>
          <span>amount</span> //old commented
        </p> -->
      <!-- <p class="box-price">
          <span class="d-block text-center">{{ getCurrency() }}</span>
          <span class="d-block text-center desktop-currency-span">{{ getPrice(data?.interest['purificationAmount']) }}</span>
        </p>
        <a  routerLink="/cabinet/purifications-methods" *ngIf="userRole === 'PREMIUM'" class="box-link d-none d-md-block text-decoration-none">What to do next &#8594;</a>
      </div>
    </div> -->

      <div class="col-12 px-0 option-box option-box-3">
         <!-- <div class="comprensive text-center py-2">Most Comprehensive</div> -->
         <div class="inner-box box-3 text-center">
            <div class="" *ngIf="isSample">
               <app-sample-bubble
                  [bubbleContent]="haramIncomeText"
                  tooltipPosition="bottom"
               ></app-sample-bubble>
            </div>
            <h5 class="box-heading">Total Purification Amount</h5>
            <!-- <p class="mb-0 amount text-uppercase">
              <span class="d-none d-xl-inline">Your purification </span>
              <span>amount</span>
            </p> -->
            <p class="box-price">
               <span class="d-block text-center">{{ getCurrency() }}</span>
               <span class="d-block text-center desktop-currency-span">
                  <!-- {{ getPrice(data?.haram["purificationAmount"]) }} -->

                  {{ getPrice(data?.data?.purification_amt) }}
               </span>
            </p>
            <a
               routerLink="/cabinet/purifications-methods"
               *ngIf="userRole === 'PREMIUM'"
               class="box-link d-none d-md-block text-decoration-none"
               >What to do next &#8594;</a
            >
         </div>
      </div>
   </div>

   <!-- display for desktop, hide for mobile-->
   <div class="row d-none d-md-flex">
      <!-- <div class="col-12 col-md-4">
         <p>
            <button
               type="button"
               class="bg-light border rounded shadow-none w-100 d-flex align-items-center justify-content-between p-2 collapse-button"
               (click)="isCollapsed1 = !isCollapsed1"
               [attr.aria-expanded]="!isCollapsed1"
               aria-controls="collapseExample"
            >
               <span>Calculation</span>
               <i
                  class="fa-solid"
                  [ngClass]="
                     isCollapsed1 == true ? 'fa-chevron-down' : 'fa-chevron-up'
                  "
               ></i>
            </button>
         </p>
         <div
            #collapse="ngbCollapse"
            [(ngbCollapse)]="isCollapsed1"
            class="collapse-body"
         >
            <div class="card">
               <div class="card-body p-0">
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">
                        Not halal Revenue / Total Revenue
                     </p>
                     <span class="lh-1 ms-2">{{ dividendData?.line1Val }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">Shares Owned</p>
                     <span class="lh-1 ms-2">{{ dividendData?.line2Val }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 per-share ps-3">Dividends Per Share</p>
                     <span class="lh-1 ms-1">{{ dividendData?.line3Val }}</span>
                  </div>
                  <div
                     class="calc-amount d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 text-uppercase ps-3">
                        purification amount
                     </p>
                     <span class="lh-1 ms-2">{{
                        dividendData?.purification
                     }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 col-md-4">
         <p>
            <button
               type="button"
               class="bg-light border rounded shadow-none w-100 d-flex align-items-center justify-content-between p-2 collapse-button"
               (click)="isCollapsed2 = !isCollapsed2"
               [attr.aria-expanded]="!isCollapsed2"
               aria-controls="collapseExample2"
            >
               <span>Calculation</span>
               <i
                  class="fa-solid"
                  [ngClass]="
                     isCollapsed2 == true ? 'fa-chevron-down' : 'fa-chevron-up'
                  "
               ></i>
            </button>
         </p>
         <div
            #collapse1="ngbCollapse"
            [(ngbCollapse)]="isCollapsed2"
            class="collapse-body"
         >
            <div class="card">
               <div class="card-body p-0">
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">Interest Income Per Share</p>
                     <span class="lh-1 ms-2">{{ interestData?.line1Val }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">Shares Owned</p>
                     <span class="lh-1 ms-2">{{ interestData?.line2Val }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 per-share ps-3">
                        Number of days owned / Number of days in the entire
                        Accounting Period
                     </p>
                     <span class="lh-1 ms-2">{{ interestData?.line3Val }}</span>
                  </div>
                  <div
                     class="calc-amount d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 text-uppercase ps-3">
                        purification amount
                     </p>
                     <span class="lh-1 ms-2">{{
                        interestData?.purification
                     }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div> -->

      <div class="col-12 col-md-12">
         <p>
            <button
               type="button"
               class="bg-light border rounded shadow-none w-100 d-flex align-items-center justify-content-between p-2 collapse-button"
               [ngClass]="isCollapsed3 == true ? 'btn-up' : 'btn-down'"
               (click)="toggleCollaps()"
               aria-controls="collapseExample3"
            >
               <span>Calculation</span>
               <i
                  class="fa-solid"
                  [ngClass]="
                     isCollapsed3 == true ? 'fa-chevron-down' : 'fa-chevron-up'
                  "
               ></i>
            </button>
         </p>
         <div
            #collapse1="ngbCollapse"
            [(ngbCollapse)]="isCollapsed3"
            class="collapse-body"
         >
            <div class="card">
               <div class="card-body d-flex flex-column p-0">
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">Symbol</p>
                     <span class="lh-1 ms-2">{{ data?.data.symbol }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">No. of Shares</p>
                     <span class="lh-1 ms-2">{{
                        data?.data.no_of_shares
                     }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 per-share ps-3">Buy date</p>
                     <span class="lh-1 ms-2">{{ data?.data.from_date }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 text-uppercase ps-3">Sell date</p>
                     <span class="lh-1 ms-2">{{ data?.data.to_date }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">Shares held days</p>
                     <span class="lh-1 ms-2">{{
                        data?.data.shares_held_days
                     }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">Accounting Period</p>
                     <span class="lh-1 ms-2">{{
                        data?.data.accounting_period
                     }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">
                        Not Halal Income purification amount
                     </p>
                     <span class="lh-1 ms-2">{{
                        (data?.data.non_halal_income_purification_amt).toFixed(
                           2
                        ) | currency : getSelectedCurrency()
                     }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">
                        Non Compliant Dividend Purification Amount
                     </p>
                     <span class="lh-1 ms-2">{{
                        (data?.data.dividend_capital_gains_purification_amt).toFixed(
                           2
                        ) | currency : getSelectedCurrency()
                     }}</span>
                  </div>
                  <div
                     class="calc-1 d-flex bordered-div align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3">
                        Non Compliant Capital Gains Purification Amount
                     </p>
                     <span class="lh-1 ms-2">{{
                        (data?.data.not_halal_capital_gains_purification_amt).toFixed(
                           2
                        ) | currency : getSelectedCurrency()
                     }}</span>
                  </div>
                  <div
                     class="calc-1 bordered-div d-flex align-items-start justify-content-between"
                  >
                     <p class="mb-0 lh-1 ps-3 text-black">
                        <b>Total Purification Amount</b>
                     </p>
                     <b>
                        <span class="lh-1 ms-2">{{
                           (data?.data.purification_amt).toFixed(2)
                              | currency : getSelectedCurrency()
                        }}</span></b
                     >
                  </div>

                  <div
                     *ngIf="data && data.data.notes.length"
                     class="calc-1 bordered-div"
                  >
                     <p class="mb-1 lh-1 ps-3">
                        <b>Note</b>
                     </p>
                     <!-- <ol *ngIf="data && data.data.notes.length">
                        <li *ngFor="let item of data?.data.notes">
                           {{ item }}
                        </li>
                     </ol> -->
                     <div
                        class="mb-1 mt-3 lh-1 ps-3 mt-1"
                        *ngIf="data && data.data.notes.length"
                     >
                        <div
                           *ngFor="let item of data?.data.notes; index as i"
                           class="d-flex gap-2"
                        >
                           <span>{{ i + 1 }}.</span>
                           <p>{{ item }}</p>
                        </div>
                     </div>
                  </div>

                  <div class="calc-1">
                     <p class="mb-2 mt-2 lh-1 ps-3">
                        <b>Purification Formula</b>
                     </p>
                     <p class="ps-3 text-italic">
                        ( Not Halal Income / Total Outstanding shares) x (No. of
                        shares Held) x (No. of days shares held / Total No. of
                        days in the Entire Accounting Period )
                     </p>
                     <p class="ps-3">
                        Musaffa uses the above formula to calculate the
                        purification amounts for each quarter based on the
                        quarter/annual financial report
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- display for mobile, hide for desktop-->
   <div class="d-md-none mt-3">
      <ul
         ngbNav
         #nav="ngbNav"
         [(activeId)]="active"
         class="nav-tabs mobile-nav-tabs row border-bottom-0"
      >
         <!-- <li [ngbNavItem]="1" class="col-4 ps-3 pe-1">
            <a ngbNavLink class="nav-tab-link p-0">
               <div>
                  <div class="mobile-blank-div text-center py-1">&nbsp;</div>
                  <div class="mobile-inner-div mob-div-1 text-center">
                     <div
                        class="bubble-wrapper wrapper-1"
                        *ngIf="isSample"
                        (mouseenter)="onMouseEnter('wrapper-1')"
                        (mouseleave)="onMouseLeave()"
                        #wrapper1
                     >
                        <app-sample-bubble
                           [bubbleContent]="dividendIncomeText"
                           tooltipPosition="right"
                        ></app-sample-bubble>
                     </div>
                     <h6 class="mobile-tab-heading mb-3">
                        Dividend Purification
                     </h6>
                     <span class="text-uppercase mobile-amount-span d-block"
                        >Amount</span
                     >
                     // comment it
                     <p class="mobile-price-span mb-0">
                        <span class="d-block text-center">{{
                           getCurrency()
                        }}</span>
                        <span class="d-block text-center desktop-currency-span">
                           {{ getPrice(data?.haram["purificationAmount"]) }}  {{comment this line}}
                           {{
                              getPrice(
                                 data?.data?.non_halal_income_purification_amt
                              )
                           }}
                        </span>
                     </p>
                  </div>
               </div>
            </a>
            <ng-template ngbNavContent>
               <hr />
               <p>
                  <button
                     type="button"
                     class="rounded shadow-none w-100 d-flex align-items-center justify-content-between p-2 mob-collapse-button"
                     [ngClass]="isCollapsed1 == true ? 'btn-up' : 'btn-down'"
                     (click)="isCollapsed1 = !isCollapsed1"
                     [attr.aria-expanded]="!isCollapsed1"
                     aria-controls="collapseExample"
                  >
                     <span>Calculation</span>
                     <i
                        class="fa-solid"
                        [ngClass]="
                           isCollapsed1 == true
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                        "
                     ></i>
                  </button>
               </p>
               <div
                  #collapse="ngbCollapse"
                  [(ngbCollapse)]="isCollapsed1"
                  class="collapse-body"
               >
                  <div class="mob-collapse-card">
                     <div class="mob-collapse-body p-0">
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">
                              Not halal Revenue / Total Revenue
                           </p>
                           <span class="lh-1 ms-2">{{
                              dividendData?.line1Val
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">Shares Owned</p>
                           <span class="lh-1 ms-2">{{
                              dividendData?.line2Val
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 per-share ps-3">
                              Dividends Per Share
                           </p>
                           <span class="lh-1 ms-1">{{
                              dividendData?.line3Val
                           }}</span>
                        </div>
                        <div
                           class="calc-amount d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 text-uppercase ps-3">
                              purification amount
                           </p>
                           <span class="lh-1 ms-2">{{
                              dividendData?.purification
                           }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </ng-template>
         </li> -->

         <!-- <li [ngbNavItem]="2" class="col-4 px-2">
            <a class="nav-tab-link p-0" ngbNavLink>
               <div>
                  <div class="mobile-blank-div text-center py-1">&nbsp;</div>
                  <div class="mobile-inner-div mob-div-2 text-center">
                     <div
                        class="bubble-wrapper wrapper-2"
                        *ngIf="isSample"
                        #wrapper2
                     >
                        <app-sample-bubble
                           [bubbleContent]="interestIncomeText"
                           tooltipPosition="bottom"
                        ></app-sample-bubble>
                     </div>
                     <h6 class="mobile-tab-heading mb-3">
                        Interest Income Purification
                     </h6>
                     <span class="text-uppercase mobile-amount-span d-block">Amount</span>  {{comment this line}}
                     <p class="mobile-price-span mb-0">
                        <span class="d-block text-center">{{
                           getCurrency()
                        }}</span>
                        <span class="text-center mobile-purification-price">{{
                           getPrice(data?.interest.purificationAmount)
                        }}</span>
                     </p>
                  </div>
               </div>
            </a>
            <ng-template ngbNavContent>
               <hr />
               <p>
                  <button
                     type="button"
                     class="rounded shadow-none w-100 d-flex align-items-center justify-content-between p-2 mob-collapse-button"
                     [ngClass]="isCollapsed2 == true ? 'btn-up' : 'btn-down'"
                     (click)="isCollapsed2 = !isCollapsed2"
                     [attr.aria-expanded]="!isCollapsed2"
                     aria-controls="collapseExample"
                  >
                     <span>Calculation</span>
                     <i
                        class="fa-solid"
                        [ngClass]="
                           isCollapsed2 == true
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                        "
                     ></i>
                  </button>
               </p>
               <div
                  #collapse="ngbCollapse"
                  [(ngbCollapse)]="isCollapsed2"
                  class="collapse-body"
               >
                  <div class="mob-collapse-card">
                     <div class="mob-collapse-body p-0">
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">
                              Interest Income Per Share
                           </p>
                           <span class="lh-1 ms-2">{{
                              interestData?.line1Val
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">Shares Owned</p>
                           <span class="lh-1 ms-2">{{
                              interestData?.line2Val
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 per-share ps-3">
                              Number of days owned / Number of days in the
                              entire Accounting Period
                           </p>
                           <span class="lh-1 ms-2">{{
                              interestData?.line3Val
                           }}</span>
                        </div>
                        <div
                           class="calc-amount d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 text-uppercase ps-3">
                              purification amount
                           </p>
                           <span class="lh-1 ms-2">{{
                              interestData?.purification
                           }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </ng-template>
         </li> -->

         <li [ngbNavItem]="1" class="col-12 ps-1 pe-3">
            <a class="nav-tab-link active p-0" ngbNavLink>
               <div>
                  <!-- <div class="mobile-blank-div recommended text-center py-1">
                     Recommended
                  </div> -->
                  <div class="mobile-inner-div mob-div-3 text-center active">
                     <div
                        class="bubble-wrapper wrapper-3"
                        *ngIf="isSample"
                        (mouseenter)="onMouseEnter('wrapper-3')"
                        (mouseleave)="onMouseLeave()"
                        #wrapper3
                     >
                        <app-sample-bubble
                           [bubbleContent]="haramIncomeText"
                           tooltipPosition="left"
                        ></app-sample-bubble>
                     </div>
                     <h6 class="mobile-tab-heading mb-3">
                        Total Purification Amount
                     </h6>

                     <p class="mobile-price-span mb-0">
                        <span class="d-block text-center">{{
                           getCurrency()
                        }}</span>
                        <span class="text-center mobile-purification-price">
                           {{ getPrice(data?.data?.purification_amt) }}</span
                        >
                     </p>
                  </div>
               </div>
            </a>

            <ng-template ngbNavContent>
               <hr />
               <p>
                  <button
                     type="button"
                     class="rounded shadow-none w-100 d-flex align-items-center justify-content-between p-2 mob-collapse-button"
                     [ngClass]="isCollapsed3 == true ? 'btn-up' : 'btn-down'"
                     (click)="isCollapsed3 = !isCollapsed3"
                     [attr.aria-expanded]="!isCollapsed3"
                     aria-controls="collapseExample"
                  >
                     <span>Calculation</span>
                     <i
                        class="fa-solid"
                        [ngClass]="
                           isCollapsed3 == true
                              ? 'fa-chevron-down'
                              : 'fa-chevron-up'
                        "
                     ></i>
                  </button>
               </p>
               <div
                  #collapse="ngbCollapse"
                  [(ngbCollapse)]="isCollapsed3"
                  class="collapse-body"
               >
                  <div class="mob-collapse-card">
                     <div class="card-body d-flex flex-column p-0">
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">Symbol</p>
                           <span class="lh-1 ms-2">{{
                              data?.data.symbol
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">No. of Shares</p>
                           <span class="lh-1 ms-2">{{
                              data?.data.no_of_shares
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 per-share ps-3">Buy date</p>
                           <span class="lh-1 ms-2">{{
                              data?.data.from_date
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 text-uppercase ps-3">
                              Sell date
                           </p>
                           <span class="lh-1 ms-2">{{
                              data?.data.to_date
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">Shares held days</p>
                           <span class="lh-1 ms-2">{{
                              data?.data.shares_held_days
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">Accounting Period</p>
                           <span class="lh-1 ms-2">{{
                              data?.data.accounting_period
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">
                              Not Halal Income purification amount
                           </p>
                           <span class="lh-1 ms-2">{{
                              (data?.data.non_halal_income_purification_amt).toFixed(
                                 2
                              ) | currency : getSelectedCurrency()
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">
                              Non Compliant Dividend Purification Amount
                           </p>
                           <span class="lh-1 ms-2">{{
                              (data?.data.dividend_capital_gains_purification_amt).toFixed(
                                 2
                              ) | currency : getSelectedCurrency()
                           }}</span>
                        </div>
                        <div
                           class="calc-1 d-flex bordered-div align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3">
                              Non Compliant Capital Gains Purification Amount
                           </p>
                           <span class="lh-1 ms-2">{{
                              (data?.data.not_halal_capital_gains_purification_amt).toFixed(
                                 2
                              ) | currency : getSelectedCurrency()
                           }}</span>
                        </div>
                        <div
                           class="calc-1 bordered-div d-flex align-items-start justify-content-between"
                        >
                           <p class="mb-0 lh-1 ps-3 text-black">
                              <b>Total Purification Amount</b>
                           </p>
                           <b>
                              <span class="lh-1 ms-2">{{
                                 (data?.data.purification_amt).toFixed(2)
                                    | currency : getSelectedCurrency()
                              }}</span></b
                           >
                        </div>

                        <div
                           *ngIf="data && data.data.notes.length"
                           class="calc-1 bordered-div"
                        >
                           <p class="mb-1 lh-1 ps-3">
                              <b>Note</b>
                           </p>
                           <!-- <ol *ngIf="data && data.data.notes.length">
                              <li *ngFor="let item of data?.data.notes">
                                 {{ item }}
                              </li>
                           </ol> -->
                           <div
                              class="mb-1 mt-3 lh-1 ps-3 mt-1"
                              *ngIf="data && data.data.notes.length"
                           >
                              <div
                                 *ngFor="
                                    let item of data?.data.notes;
                                    index as i
                                 "
                                 class="d-flex gap-2"
                              >
                                 <span>{{ i + 1 }}.</span>
                                 <p>{{ item }}</p>
                              </div>
                           </div>
                        </div>

                        <div class="calc-1">
                           <p class="mb-2 mt-2 lh-1 ps-3">
                              <b>Purification Formula</b>
                           </p>
                           <p class="ps-3 text-italic">
                              ( Not Halal Income / Total Outstanding shares) x
                              (No. of shares Held) x (No. of days shares held /
                              Total No. of days in the Entire Accounting Period
                              )
                           </p>
                           <p class="ps-3">
                              Musaffa uses the above formula to calculate the
                              purification amounts for each quarter based on the
                              quarter/annual financial report
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </ng-template>
         </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

      <div class="pt-3" *ngIf="userRole === 'PREMIUM'">
         <a
            routerLink="/cabinet/purifications-methods"
            class="mobile-link rounded text-decoration-none text-white text-center d-block py-3"
         >
            What to do next? &#8594;
         </a>
      </div>
   </div>
</div>

<!-- <div class="row options-wrapper">
    <div class="col-4 ps-3 pe-1 px-lg-0">
      <input type="radio" class="hide-radio" name="purificationOptions" id="dividend">
      <label for="dividend" class="radio-label w-100 rounded-start">
        <div class=" blank text-center">&nbsp;</div>
        <div class="option-box box-1 text-center">
          <h5 class="radio-heading">Dividend Purification</h5>
          <p class="mb-0 amount text-uppercase">
            <span class="d-none d-xl-inline">Your purification </span>
            <span>amount</span>
          </p>
          <p class="radio-price">$ 1.08</p>
          <a href="#" class="radio-link d-none d-lg-block text-decoration-none">What to do next &#8594;</a>
        </div>
      </label>
    </div>

    <div class="col-4 px-2 px-lg-0">
      <input type="radio" class="hide-radio" name="purificationOptions" id="interestIncome">
      <label for="interestIncome" class="radio-label w-100">
        <div class=" blank text-center">&nbsp;</div>
        <div class="option-box box-2 text-center">
          <h5 class="radio-heading">Interest Income Purification</h5>
          <p class="mb-0 amount text-uppercase">
            <span class="d-none d-xl-inline">Your purification </span>
            <span>amount</span>
          </p>
          <p class="radio-price">$ 2.26</p>
          <a href="#" class="radio-link d-none d-lg-block text-decoration-none">What to do next &#8594;</a>
        </div>
      </label>
    </div>

    <div class="col-4 ps-1 pe-3 px-lg-0">
      <input type="radio" class="hide-radio" name="purificationOptions" id="notHalalIncome">
      <label for="notHalalIncome" class="radio-label w-100 rounded-end">
        <div class="comprensive text-center d-none d-lg-block">Most Comprehensive</div>
        <div class="comprensive text-center d-lg-none">Recommended</div>
        <div class="option-box box-3 text-center">
          <h5 class="radio-heading">Not Halal Income Purification</h5>
          <p class="mb-0 amount text-uppercase">
            <span class="d-none d-xl-inline">Your purification </span>
            <span>amount</span>
          </p>
          <p class="radio-price">$ 3.75</p>
          <a href="#" class="radio-link d-none d-lg-block text-decoration-none">What to do next &#8594;</a>
        </div>
      </label>
    </div>

  </div> -->
