<header class="d-flex align-items-center justify-content-between mb-3">
    <h6 class="text-uppercase mb-0 table-title">Market Indices</h6>
</header>
<div class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
    <!-- <div class="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
            <span class="blue-text">Track all markets on TradingView</span>
        </a>
    </div> -->
</div>
