import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {
  private subscriptions: Subscription[]
  userRole: string|null = null

  constructor() { 
    this.subscriptions = []
  }

  ngOnInit(): void {
    this.subscriptions.push(SubscriptionService.subscriptionData.subscribe((data: SubscriptionInfoModel) => {
      this.userRole = data.role
    }))
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
       this.subscriptions.forEach((item) => {
          item.unsubscribe();
       });
    }
  }

}
