
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryShortPipe'
})
export class countryShortPipe implements PipeTransform {

  transform(country: string): any {
    if (country) {

      // value = super.transform(value);
      // value = value.replace('$', '');

      let symbol;
      switch (country) {
        case "Afghanistan": symbol = "Afghanistan"; break;
        case "Argentina": symbol = "Argentina"; break;
        case "Australia": symbol = "Australia"; break;
        case "Austria": symbol = "Austria"; break;
        case "Azerbaijan": symbol = "Azerbaijan"; break;
        case "Bahamas (the)": symbol = "Bahamas"; break;
        case "Bahrain": symbol = "Bahrain"; break;
        case "Bangladesh": symbol = "Bangladesh"; break;
        case "Belarus": symbol = "Belarus"; break;
        case "Belgium": symbol = "Belgium"; break;
        case "Belize": symbol = "Belize"; break;
        case "Benin": symbol = "Benin"; break;
        case "Bermuda": symbol = "Bermuda"; break;
        case "Bonaire, Sint Eustatius and Saba": symbol = "BES Islands"; break;
        case "Botswana": symbol = "Botswana"; break;
        case "Bouvet Island": symbol = "Bouvet Island"; break;
        case "Brazil": symbol = "Brazil"; break;
        case "Brunei Darussalam": symbol = "Brunei"; break;
        case "Bulgaria": symbol = "Bulgaria"; break;
        case "Burkina Faso": symbol = "Burkina Faso"; break;
        case "Cambodia": symbol = "Cambodia"; break;
        case "Canada": symbol = "Canada"; break;
        case "Cayman Islands (the)": symbol = "Cayman Is."; break;
        case "Chile": symbol = "Chile"; break;
        case "China": symbol = "China"; break;
        case "Colombia": symbol = "Colombia"; break;
        case "Costa Rica": symbol = "Costa Rica"; break;
        case "Croatia": symbol = "Croatia"; break;
        case "Cyprus": symbol = "Cyprus"; break;
        case "Czechia": symbol = "Czechia"; break;
        case "Denmark": symbol = "Denmark"; break;
        case "Egypt": symbol = "Egypt"; break;
        case "Estonia": symbol = "Estonia"; break;
        case "Faroe Islands (the)": symbol = "Faroe Islands"; break;
        case "Finland": symbol = "Finland"; break;
        case "France": symbol = "France"; break;
        case "Gabon": symbol = "Gabon"; break;
        case "Georgia": symbol = "Georgia"; break;
        case "Germany": symbol = "Germany"; break;
        case "Gibraltar": symbol = "Gibraltar"; break;
        case "Greece": symbol = "Greece"; break;
        case "Guernsey": symbol = "Guernsey"; break;
        case "Hong Kong": symbol = "Hong Kong"; break;
        case "Hungary": symbol = "Hungary"; break;
        case "Iceland": symbol = "Iceland"; break;
        case "India": symbol = "India"; break;
        case "Indonesia": symbol = "Indonesia"; break;
        case "Iran (Islamic Republic of)": symbol = "Iran"; break;
        case "Ireland": symbol = "Ireland"; break;
        case "Isle of Man": symbol = "Isle of Man"; break;
        case "Israel": symbol = "Israel"; break;
        case "Italy": symbol = "Italy"; break;
        case "Japan": symbol = "Japan"; break;
        case "Jersey": symbol = "Jersey"; break;
        case "Jordan": symbol = "Jordan"; break;
        case "Kazakhstan": symbol = "Kazakhstan"; break;
        case "Kenya": symbol = "Kenya"; break;
        case "Korea (the Republic of)": symbol = "South Korea"; break;
        case "Kuwait": symbol = "Kuwait"; break;
        case "Liechtenstein": symbol = "Liechtenstein"; break;
        case "Lithuania": symbol = "Lithuania"; break;
        case "Luxembourg": symbol = "Luxembourg"; break;
        case "Macao": symbol = "Macao"; break;
        case "Malaysia": symbol = "Malaysia"; break;
        case "Maldives": symbol = "Maldives"; break;
        case "Malta": symbol = "Malta"; break;
        case "Marshall Islands (the)": symbol = "Marshall Is."; break;
        case "Mexico": symbol = "Mexico"; break;
        case "Monaco": symbol = "Monaco"; break;
        case "Mongolia": symbol = "Mongolia"; break;
        case "Morocco": symbol = "Morocco"; break;
        case "Netherlands (the)": symbol = "Netherlands"; break;
        case "New Zealand": symbol = "New Zealand"; break;
        case "Nigeria": symbol = "Nigeria"; break;
        case "Norway": symbol = "Norway"; break;
        case "Oman": symbol = "Oman"; break;
        case "Pakistan": symbol = "Pakistan"; break;
        case "Panama": symbol = "Panama"; break;
        case "Papua New Guinea": symbol = "PNG"; break;
        case "Peru": symbol = "Peru"; break;
        case "Philippines (the)": symbol = "Philippines"; break;
        case "Poland": symbol = "Poland"; break;
        case "Portugal": symbol = "Portugal "; break;
        case "Puerto Rico": symbol = "Puerto Rico"; break;
        case "Qatar": symbol = "Qatar"; break;
        case "Réunion": symbol = "Réunion"; break;
        case "Romania": symbol = "Romania"; break;
        case "Russian Federation (the)": symbol = "Russia "; break;
        case "Saint Barthélemy": symbol = "St. Barts"; break;
        case "Saudi Arabia": symbol = "Saudi Arabia"; break;
        case "Serbia": symbol = "Serbia"; break;
        case "Singapore": symbol = "Singapore"; break;
        case "Slovakia": symbol = "Slovakia"; break;
        case "Slovenia": symbol = "Slovenia"; break;
        case "South Africa": symbol = "South Africa"; break;
        case "Spain": symbol = "Spain"; break;
        case "Sri Lanka": symbol = "Sri Lanka"; break;
        case "Sweden": symbol = "Sweden"; break;
        case "Switzerland": symbol = "Switzerland"; break;
        case "Taiwan (Province of China)": symbol = "Taiwan"; break;
        case "Thailand": symbol = "Thailand"; break;
        case "Togo": symbol = "Togo"; break;
        case "Turkey": symbol = "Turkey"; break;
        case "Ukraine": symbol = "Ukraine"; break;
        case "United Arab Emirates (the)": symbol = "UAE"; break;
        case "United Kingdom of Great Britain and Northern Ireland (the)": symbol = "U.K"; break;
        case "United States of America (the)": symbol = "United States"; break;
        case "Uruguay": symbol = "Uruguay"; break;
        case "Viet Nam": symbol = "Vietnam"; break;
        case "Virgin Islands (British)": symbol = "BVI"; break;
        case "Virgin Islands (U.S.)": symbol = "USVI"; break;
        case "Zimbabwe": symbol = "Zimbabwe"; break;

        default:
          symbol = country;
          break;

      }

    

      return symbol;
    }
    else {
      return '--';
    }

  }

}
