import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AccessControlService } from 'src/app/shared/services/access-control.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

@Component({
  selector: 'app-watchlist-section',
  templateUrl: './watchlist-section.component.html',
  styleUrls: ['./watchlist-section.component.scss']
})
export class WatchlistSectionComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   userRole: string | null = null;

   constructor(
      private subscriptionService: SubscriptionService,
      private authService: AuthService,
      public accessControlService: AccessControlService
   ) { }

   ngOnInit(): void {
      SubscriptionService.subscriptionData.pipe(takeUntil(this.destroy$)).subscribe((data: SubscriptionInfoModel) => {
         this.userRole = data.role
      })
   }

   ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
   }

   openLoginPopup() {
      // this.authService.openAuthPopup()
      this.accessControlService.accessDenied();
   }

}
